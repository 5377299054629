
import { MedicalFile } from './customer'
import { DrugInstruction } from './drugInstruction'
import { propertyMap } from './modelmapper'
import { User } from './user'

export class MedicationReviewHeader {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('NextReviewDate')
    public NextReviewDate:Date
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('Status')
    public Status:string
    @propertyMap('ReviewerID')
    public ReviewerID:string
    @propertyMap('Reviewer')
    public Reviewer:User
    @propertyMap('ClosedAt')
    public ClosedAt:Date

    @propertyMap('ReviewDetails')
    public ReviewDetails: MedicationReviewDetails[]
    
    @propertyMap('ClosedById')
    public ClosedById:string
    @propertyMap('ClosedBy')
    public ClosedBy:User
    @propertyMap('PersonDoc')
    public PersonDoc: MedicalFile
    


    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.ReviewerID = data["ReviewerID"]
            this.Reviewer = data["Reviewer"]
            this.ClosedAt =  data["ClosedAt"]
            this.NextReviewDate =  data["NextReviewDate"]
            this.ReviewDetails = data["ReviewDetails"]
            if (this.ReviewDetails == undefined || this.ReviewDetails.length <=0 ) {
                this.ReviewDetails = []
            }
            this.ClosedById = data["ClosedById"]
            this.ClosedBy = data["ClosedBy"]
            this.Status = data["Status"]
            this.PersonDoc = data["PersonDoc"]
            
        }
    }


}


export class MedicationReviewDetails {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date

    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('Status')
    public Status:string
    @propertyMap('ReviewerID')
    public ReviewerID:string
    @propertyMap('Reviewer')
    public Reviewer:User
    @propertyMap('ClosedAt')
    public ClosedAt:Date

    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('RevDrugInstructionID')
    public RevDrugInstructionID:string
    @propertyMap('DrugInstruction')
    public DrugInstruction:DrugInstruction
    @propertyMap('PreviousStatus')
    public PreviousStatus:string
    @propertyMap('ChangedToStatus')
    public ChangedToStatus:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('IsClosed')
    public IsClosed:boolean
    @propertyMap('ReasonForChange')
    public ReasonForChange:string

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.ReviewerID = data["ReviewerID"]
            this.Reviewer = data["Reviewer"]
            this.ClosedAt =  data["ClosedAt"]
            this.PersonelID =  data["PersonelID"]
            this.RevDrugInstructionID =  data["RevDrugInstructionID"]
            this.DrugInstruction =  data["DrugInstruction"]
            this.PreviousStatus =  data["PreviousStatus"]
            this.ChangedToStatus =  data["ChangedToStatus"]
            this.IsClosed = data["IsClosed"]
            this.ReasonForChange = data["ReasonForChange"]
        }
    }


}





