import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, CanDeactivateGuard } from '../shared/guard/auth.guard';
import { DieticiananchorComponent } from './components/dieticiananchor/dieticiananchor.component';
import { DieticianassessmentComponent } from './components/dieticianassessment/dieticianassessment.component';
import { DieticianfollowupComponent } from './components/dieticianfollowup/dieticianfollowup.component';
import { DieticianassessmentsListComponent } from './components/dieticianassessments-list/dieticianassessments-list.component'
import { YearlyweightreportComponent } from './components/yearlyweightreport/yearlyweightreport.component';
import { DailyfoodintakeComponent } from './components/dailyfoodintake/dailyfoodintake.component';
import { DailyfoodintakelistComponent } from './components/dailyfoodintakelist/dailyfoodintakelist.component';
import { DieticianRecommendationsComponent } from './components/dietician-recommendations/dietician-recommendations.component';

const routes: Routes = [

  {
    path: 'caretexfile/:id/dieticianrecommendation',
    component: DieticiananchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: DieticianRecommendationsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

    ]
  },




  {
    path: 'caretexfile/:id/dailyfoodreport',
    component: DieticiananchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: DailyfoodintakelistComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'recordid:tabid',
        component: DailyfoodintakeComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':recordid',
        component: DailyfoodintakeComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      { 
        path: 'new', 
        component: DailyfoodintakeComponent, 
        canActivate: [AuthGuard],
        data: { mode: ['newpage']}
      }
    
    
    ]
  },

  {
  path: 'caretexfile/:id/dieticianfollowup',
  component: DieticiananchorComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full',
    },
    {
      path: 'list',
      component: DieticianfollowupComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: 'list/step/:stepid', 
      component: DieticianfollowupComponent, 
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: 'step/:stepid',
      component: DieticianfollowupComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },

    ]
},

{
  path: 'caretexfile/:id/dieticianassessment',
  component: DieticiananchorComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full',
    },
    {
      path: 'list',
      component: DieticianassessmentsListComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':recordid/step/:tabid',
      component: DieticianassessmentComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
    
    {
      path: ':recordid',
      component: DieticianassessmentComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
    { 
      path: 'new', 
      component: DieticianassessmentComponent, 
      canActivate: [AuthGuard],
      data: { mode: ['newpage']}
    }
  
  
  ]
},

{
  path: 'caretexfile/:id/yearlyweightreport',
  component: DieticiananchorComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full',
    },
    {
      path: 'list',
      component: YearlyweightreportComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },

  
  
  ]
}

,];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaretexDieticianRoutingModule { }
