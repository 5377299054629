import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DrugUom } from 'src/app/models/generalhousedsetups';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-druguoms',
  templateUrl: './druguoms.component.html',
  styleUrls: ['./druguoms.component.css']
})
export class DruguomsComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  drugUoms:DrugUom[]  = []
  currentRowIndex:number

  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) { super(medicalFileServive)}

  ngOnInit(): void {

    this.getDrugUomsFromDB()
  }

  getDrugUomsFromDB(){
    this.caretxSetupsProviderService.getDrugUoms().subscribe(
      data => {
        this.drugUoms = data
      },
      err => {
        this.drugUoms = []

      }
    ) 
  }
  updateDrugUom($event){

    this.caretxSetupsProviderService.CreateUpdateDrugUoms(this.drugUoms).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getDrugUomsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })  

  }


  BackToList(){
    this.router.navigate(['system_setups']);
  }

  addNewDrugUom(){
    var newDrugUom= new DrugUom()
    newDrugUom.New = true
    newDrugUom.editMode = true
    newDrugUom.CreatedAt = new Date()
    newDrugUom.UpdatedAt = new Date()
    newDrugUom.TenantID = this.authServive.LoggedInTenantID
    this.drugUoms.unshift(newDrugUom)
  }

  deleteRow(i){
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.drugUoms[i].New){
          this.caretxSetupsProviderService.deleteDrugUom(this.drugUoms[this.currentRowIndex]).subscribe({
            next: data => {
              this.drugUoms.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.drugUoms.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i){
    this.drugUoms[i].editMode = true
    this.drugUoms[i].Modified = true
  }

  cancelEditRow(i){
    this.drugUoms[i].editMode  = false;
    this.drugUoms[i].Modified = false
  }

  CheckIfDocumentIsDirty() {
    const statusesListChanged = this.drugUoms.some(row => {
      return row.New || row.editMode
    })
    return  statusesListChanged
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }


}
