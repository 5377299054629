import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CaretxNursingProviderService } from 'src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { ModalService } from 'src/app/modal';
import { CartexEvent, TabHistory } from 'src/app/models/genericTypes';
import { NurseExamination } from 'src/app/models/nurseexamination';
import { AdmissionsService } from 'src/app/services/admissions.service';
import { BridgeBuiltInNurseSentenses } from 'src/app/services/builtinsentenses';
import { AccessComponenetType, BridgeEnums, NurseAdmissionSteps, NurseTest, OptionObject } from 'src/app/services/enums';
import { MultipleoptionsselectionDialogComponent } from '../../CaretexModals/multipleoptionsselection-dialog/multipleoptionsselection-dialog.component';
import { environment } from 'src/environments/environment';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { NursePhysicalCheck, NursePhysicalStatus, NursePhysicalStatusDefaultText } from 'src/app/models/nursingphysicalstatuses';
import { ShowdefaulttextDialogComponent } from '../../CaretexModals/showdefaulttext-dialog/showdefaulttext-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-nursephysicalcheck',
  templateUrl: './nursephysicalcheck.component.html',
  styleUrls: ['./nursephysicalcheck.component.css']
})
export class NursephysicalcheckComponent implements OnInit {




  @Input() nurseExamination: NurseExamination
  @Input() IsClosed: boolean
  @Output() ModuleChanged = new EventEmitter<boolean>();
  @Input() events: Observable<CartexEvent>;
  @Input() AccessedComponent: AccessComponenetType
  @Input() DisplayBtnsRow: boolean = false
  @Output() updateNextTab = new EventEmitter<string>();
  @Output() NursePhysicalExaminationHandler = new EventEmitter<NurseExamination>();
  @Input() currentActiveStep: number;
  @Input() stepOrder: number;

  @Output() NewRecordHandler = new EventEmitter<void>();
  @Output() NewRecordLoadLastDataHandler = new EventEmitter<void>();
  @Output() ShowLastRecordHandler = new EventEmitter<void>();


  private eventsSubscription: Subscription;
  private nextTab: string


  id: string


  isDisabled: boolean
  CurrentStep: NurseTest = NurseTest.Main
  @ViewChild('mainform') mainFormElement: NgForm;
  zoomInModalData: any;
  dismissModal: boolean = false
  checks: NursePhysicalCheck[] = []


  constructor(
    private _ar: ActivatedRoute,
    public dialog: MatDialog,
    private _admissionsService: AdmissionsService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private router: Router,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private route: ActivatedRoute,
    private bridgeEnum: BridgeEnums,
    private modalService: ModalService,) {
    bridgeEnum = new BridgeEnums()

    this.nurseExamination = new NurseExamination()


  }

  ngOnInit(): void {
    this.isDisabled = (this.nurseExamination.Status == 'C')
    this.id = this.route.parent.snapshot.params['id']
    this.GetNursePhysicalCheck()
    if (this.events != null && this.events != undefined) {
      this.eventsSubscription = this.events.subscribe((data) => this.confirmUnSavedChanges(data));
    }
    // Check If there is an Opened Record // this is not correct need to load teh associated record only 
    if (this.AccessedComponent == AccessComponenetType.SubModule) {
    }
  }


  // Get All Nurse physical check

  GetNursePhysicalCheck() {
    this.caretxSetupsProviderService.GetNursePhysicalChecks().subscribe(
      data => {
        this.checks = data

      },
      err => {
        this.checks = []

      }
    )

  }

  getAllNursePhysicalCheckFromDB() {
    let status: string = "A"
    let filter = '(status=' + '\'' + status + '\'' + ')'

    this.caretxNursingProviderService.getAllNurseExaminations(this.id, filter).subscribe(
      data => {
        if (data.length > 0) {
          this.nurseExamination = new NurseExamination(data[0])
          this.NursePhysicalExaminationHandler.emit(this.nurseExamination)
        }
      },
      err => {
        console.log("Error", err)
      }
    )
  }

  builtInSentensesEventHander($event) {
  }

  selectTab(tabId: string) {
    this.CurrentStep = (tabId as NurseTest)
    if (this.CheckIfDocumentIsDirty())
      this.ModuleChanged.emit(true)
    else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
    }
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty
  }

  confirmUnSavedChanges(data: CartexEvent) {
    if ((data.EventData as unknown as TabHistory).CurrentTab == NurseAdmissionSteps.PhysicalTest) {
      this.nextTab = (data.EventData as unknown as TabHistory).NextTab
      this.ModuleChanged.emit(this.mainFormElement.dirty)
    }
  }

  ModuleTextChanged() {
    this.ModuleChanged.emit(true)
    this.NursePhysicalExaminationHandler.emit(this.nurseExamination)
  }



  // Dםונךק Z Eהקמא 





  insertSentences($event, type) {

    this.checks.forEach(element => {
      if (element.Code == type) {
        const dialogRef = this.dialog.open(ShowdefaulttextDialogComponent, {
          data: {
            title: element.Description,
            concatenatedStatusTxt: $event,
            selectedNursePhysicalCheck:element
          }
        });

        
        let tmpTxt = ""

        dialogRef.afterClosed().subscribe(result => {
          (result).forEach(item => {
            tmpTxt += item
            tmpTxt += "\n";
          })


          // Save Diagnoses
          if (result == undefined || result == null || result == "") return
          switch(type) {
            case "breathing":
              this.nurseExamination.BreathingExamination = "";
              this.nurseExamination.BreathingExamination = tmpTxt;
            break
            case "Mobility":
              this.nurseExamination.Mobility = "";
              this.nurseExamination.Mobility = tmpTxt;
            break
            case "transfer":
              this.nurseExamination.TransferExamination = "";
              this.nurseExamination.TransferExamination = tmpTxt;
            break
            case "washing":
              this.nurseExamination.WashingExamination = "";
              this.nurseExamination.WashingExamination = tmpTxt;
            break
            case "clothing":
              this.nurseExamination.ClothingExamination = "";
              this.nurseExamination.ClothingExamination = tmpTxt;
            break
            case "feeding":
              this.nurseExamination.FeedingExamination = "";
              this.nurseExamination.FeedingExamination = tmpTxt;
            break
            case "mouth":
              this.nurseExamination.MouthExamination = "";
              this.nurseExamination.MouthExamination = tmpTxt;
            break
            case "diates":
              this.nurseExamination.DiatesExamination = "";
              this.nurseExamination.DiatesExamination = tmpTxt;
            break
            case "apetite":
              this.nurseExamination.AppetiteExamination = "";
              this.nurseExamination.AppetiteExamination = tmpTxt;
            break
            case "bowel":
              this.nurseExamination.BowelExamination = "";
              this.nurseExamination.BowelExamination = tmpTxt;
            break
            case "urine":
              this.nurseExamination.UrineExamination = "";
              this.nurseExamination.UrineExamination = tmpTxt;
            break
            case "skin":
              this.nurseExamination.SkinExamination = "";
              this.nurseExamination.SkinExamination = tmpTxt;
            break
            case "eye":
              this.nurseExamination.EyeExamination = "";
              this.nurseExamination.EyeExamination = tmpTxt;
            break
            case "hearing":
              this.nurseExamination.EarsExamination = "";
              this.nurseExamination.EarsExamination = tmpTxt;
            break
            case "sleeping":
              this.nurseExamination.SleepExamination = "";
              this.nurseExamination.SleepExamination = tmpTxt;
            break
            case "pain":
              this.nurseExamination.PainExamination = "";
              this.nurseExamination.PainExamination = tmpTxt;
            break


          }

        });
      }
  });

  }
  insertBreathingExamSentens($event) {

  }
 

  zoomInField(title, fieldNgModel, preFilledData) {
    this.dismissModal = false
    this.zoomInModalData = { title, field: fieldNgModel, preFilled: (preFilledData || '') };

  }

  bindFieldData(responseData) {
    this.nurseExamination[this.zoomInModalData.field] = responseData;

  }

  DismissCtxTextareaModal() {
    this.dismissModal = true
  }


  NewRecord() {
    this.NewRecordHandler.emit()
  }

  NewRecordLoadLastData() {
    this.NewRecordLoadLastDataHandler.emit()
  }

  showLastRecord() {
    this.ShowLastRecordHandler.emit()
  }


  /******************* */

  showStatuses($event, category) {

    this.checks.forEach(element => {
        if (element.Code == category) {
          const dialogRef = this.dialog.open(MultipleoptionsselectionDialogComponent, {
            data: {
              title: element.Description,
              Type: "NursePhysicalStatus",
              Statuses: element.Statuses
            }
          });

          
          let tmpTxt = ""

          dialogRef.afterClosed().subscribe(result => {
            (result as NursePhysicalStatus[]).forEach(item => {
              tmpTxt += item.Status
              tmpTxt += "\n";
            })


            // Save Diagnoses
            if (result == undefined || result == null || result == "") return

              switch(category) {
                case "breathing":
                  this.nurseExamination.BreathingStatus = "";
                  this.nurseExamination.BreathingStatus = tmpTxt;
                break
                case "Mobility":
                  this.nurseExamination.MobilityStatus = "";
                  this.nurseExamination.MobilityStatus = tmpTxt;
                break
                case "transfer":
                  this.nurseExamination.TransferStatus = "";
                  this.nurseExamination.TransferStatus = tmpTxt;
                break
                case "washing":
                  this.nurseExamination.WashingStatus = "";
                  this.nurseExamination.WashingStatus = tmpTxt;
                break
                case "clothing":
                  this.nurseExamination.ClothingStatus = "";
                  this.nurseExamination.ClothingStatus = tmpTxt;
                break
                case "feeding":
                  this.nurseExamination.FeedingStatus = "";
                  this.nurseExamination.FeedingStatus = tmpTxt;
                break
                case "mouth":
                  this.nurseExamination.MouthStatus = "";
                  this.nurseExamination.MouthStatus = tmpTxt;
                break
                case "diates":
                  this.nurseExamination.DiatesStatus = "";
                  this.nurseExamination.DiatesStatus = tmpTxt;
                break
                case "apetite":
                  this.nurseExamination.AppetiteStatus = "";
                  this.nurseExamination.AppetiteStatus = tmpTxt;
                break
                case "bowel":
                  this.nurseExamination.BowelStatus = "";
                  this.nurseExamination.BowelStatus = tmpTxt;
                break
                case "urine":
                  this.nurseExamination.urinetatus = "";
                  this.nurseExamination.urinetatus = tmpTxt;
                break
                case "skin":
                  this.nurseExamination.SkinStatus = "";
                  this.nurseExamination.SkinStatus = tmpTxt;
                break
                case "eye":
                  this.nurseExamination.EyeStatus = "";
                  this.nurseExamination.EyeStatus = tmpTxt;
                break
                case "hearing":
                  this.nurseExamination.EarsStatus = "";
                  this.nurseExamination.EarsStatus = tmpTxt;
                break
                case "sleeping":
                  this.nurseExamination.SleepStatus = "";
                  this.nurseExamination.SleepStatus = tmpTxt;
                break
                case "pain":
                  this.nurseExamination.PainStatus = "";
                  this.nurseExamination.PainStatus = tmpTxt;
                break


              }
          });
        }
    });



  }
}
