import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PhysicalDiagnoses, PhysicalDiagnoses2, PhysicalTest } from 'src/app/models/physiotherapy';
import { AuthService } from 'src/app/services/auth.service';
import { physiotherapyDiagnosesSteps } from 'src/app/services/enums';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxPhsioTherapyProviderService } from '../Physiotherapty.data-provider.service';
import { CaretexEventTypes, CartexEvent, TabHistory } from 'src/app/models/genericTypes';

@Component({
  selector: 'app-physicaldiagnoses2',
  templateUrl: './physicaldiagnoses2.component.html',
  styleUrls: ['./physicaldiagnoses2.component.scss']
})
export class Physicaldiagnoses2Component extends CaretexComponent implements OnInit {


  @ViewChild('mainform') mainFormElement: NgForm;
  moduleName: string = "אבחון פיזיקאלי ראשוני"
  currentPhysicalDiagnoses: PhysicalDiagnoses2
  CurrentStep: physiotherapyDiagnosesSteps = physiotherapyDiagnosesSteps.General
  isRecordClosed: boolean
  personalId: string
  recordid: string
  step: number = 0
  substep: number = 0
  UpperLimbsRows: PhysicalTest[] = []
  show: string = "GeneralView"
  Answers: { id: string, name: string }[] = [
    { id: "F", name: "מלא" },
    { id: "P", name: "חלקי" },
    { id: "M", name: "חסר" }
  ]
  constructor(


    private _title: Title,
    private router: Router,
    private _ar: ActivatedRoute,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private phsiotheraptyService: CaretxPhsioTherapyProviderService,
    private route: ActivatedRoute,) {
    super(medicalFileService);
  }

  ngOnInit(): void {

    this.personalId = this.route.parent.snapshot.params['id']  //this._ar.snapshot.params['id'];
    this.recordid = this.route.snapshot.params.recordid
    this.CheckIfComponentIsDisabled(this.personalId)
    this.currentPhysicalDiagnoses = new PhysicalDiagnoses2()

    if (this.route.snapshot.params['tabid'] != "" && this.route.snapshot.params['tabid'] != undefined) {
      this.step = Number(this.route.snapshot.params['tabid']);


    }


    if (this.route.snapshot.params['subtabid'] != "" && this.route.snapshot.params['tabid'] != undefined) {
      this.substep = Number(this.route.snapshot.params['subtabid']);
    }

    
    this.getPhysicalDiagnosesFromDB()
  }



  getPhysicalDiagnosesFromDB() {
    let filter = ""
    let mode = "list"
    if (this.recordid != 'new')
      filter = '(id=' + '\'' + this.recordid + '\'' + ')'
    else {
      mode = "new"
    }
    this.phsiotheraptyService.getPhysicaldiagnosis2(this.personalId, filter, mode).subscribe((data: PhysicalDiagnoses2[]) => {
      if (data.length == 1) {
        this.currentPhysicalDiagnoses = new PhysicalDiagnoses2(data[0])
        if (this.recordid == 'new') {
          this.currentPhysicalDiagnoses.PersonelID = this.personalId
          this.currentPhysicalDiagnoses.TenantID = this.authServive.LoggedInTenantID
          this.currentPhysicalDiagnoses.CreatedById = this.authServive.LoggedInUser.UID
          this.currentPhysicalDiagnoses.CreatedAt = new Date()
        }
        if (this.currentPhysicalDiagnoses.Status == "C") {
          this.isRecordClosed = true
        } else
          this.isRecordClosed = false
      }
      else if (data.length == 0) {
        let errorMsg = "המערכת לא הצליחה לייצר רשומה חדשה"
        this.toastrService.Error("", errorMsg)
      }
      else {
        let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }



  SaveRecord() {
    if (this.recordid == 'new') {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    this.currentPhysicalDiagnoses.UpdatedAt = new Date()
    this.currentPhysicalDiagnoses.CreatedAt = this.currentPhysicalDiagnoses.UpdatedAt
    this.currentPhysicalDiagnoses.CreatedById = this.authServive.LoggedInUser.UID
    this.currentPhysicalDiagnoses.TenantID = this.authServive.LoggedInTenantID
    this.phsiotheraptyService.createPhysicalDiagnoses2(this.currentPhysicalDiagnoses).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentPhysicalDiagnoses = new PhysicalDiagnoses2(data)
        this.mainFormElement.reset(this.mainFormElement.value);

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
   

        this.router.navigateByUrl("caretexfile/" + this.personalId + "/physicalreport/" + this.currentPhysicalDiagnoses.ID  +"/step/" + this.step+"/childstep/" + this.substep)


      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    this.currentPhysicalDiagnoses.UpdatedAt = new Date()
    this.currentPhysicalDiagnoses.TenantID = this.authServive.LoggedInTenantID
    this.phsiotheraptyService.updatePhysicaldiagnosis2(this.currentPhysicalDiagnoses).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentPhysicalDiagnoses = new PhysicalDiagnoses2(data)
        this.mainFormElement.reset(this.mainFormElement.value);


        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
  

        this.router.navigateByUrl("caretexfile/" + this.personalId + "/physicalreport/" + this.currentPhysicalDiagnoses.ID +"/step/" + this.step+"/childstep/" + this.substep)



      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }


  lockRecord() {
    if (this.currentPhysicalDiagnoses.Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה לנעול רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תנעל',
        allowOutsideClick: false,
        allowEscapeKey: false,       
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DoLock()
        }
      })
    }
  }

  DoLock() {
    this.currentPhysicalDiagnoses.Status = "C"
    this.currentPhysicalDiagnoses.ClosedById = this.authServive.LoggedInUser.UID
    this.currentPhysicalDiagnoses.ClosedAt = new Date()
    this.DoUpdate()

  }


  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/physicalreport/list")
  }

  setStep(index: number) {
    this.step = index;
  }

  setsubStep(index: number) {
    this.substep = index;
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty ==null?false:this.mainFormElement.dirty
  }

  enableContent(type) {
    this.show = type;
  }

  

}

