import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseOmdan } from 'src/app/models/omdans';
import { AuthService } from 'src/app/services/auth.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CaretxNursingProviderService } from '../../../nursing-services/Nursing.data-provider.service';

@Component({
  selector: 'app-snaqlist',
  templateUrl: './snaqlist.component.html',
  styleUrls: ['./snaqlist.component.css']
})
export class SnaqlistComponent extends CaretexComponent implements OnInit {

  snaqOmdans:BaseOmdan[] =[]
  id: string

  moduleName:string="שאלוני הערכת הסיכון לתת תזונה"
  constructor( 
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private route: ActivatedRoute,
    private caretxNursingProviderService:CaretxNursingProviderService,
    private toastrService: ToasterService,
    private router: Router,
    ) { super(medicalFileService)}

  ngOnInit(): void {
    this._title.setTitle('Caretx |  הערכת הסיכון לתת תזונה');
     this.id = this.route.parent.snapshot.params['id'];
     this.CheckIfComponentIsDisabled(this.id) 
    this.getOmdanemFromDB()
  }
  

  
  getOmdanemFromDB() {
    let filter:string ="origin <> 'admission'" // Origin is not admission
    this.caretxNursingProviderService.getOmdanem(this.id,filter,"snaq").subscribe(
      data => this.snaqOmdans = data,
      err => {
        console.log("Error", err)
        this.snaqOmdans = []
      }
    )
  }


  OnSDrillToOmdan($event,snaqOmdan){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/snaq/" + snaqOmdan.ID)
  }

  AddNewSnaq($event){
    let filter = '(omdan_status=\'A\'  and origin <> \'admission\')'
    this.caretxNursingProviderService.getOmdanem(this.id,filter,"snaq").subscribe((data: BaseOmdan[]) => {
      if (data.length > 0){
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
      else{
        this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/snaq/new")
      }
    }); 
  }

  deleteEvent(i){

  }

  handleDeleteAlerMsg($event){

  }

  generateReport($event,event){
    
  }

  RetrunToList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem")
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }
}
