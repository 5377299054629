import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Attachment } from '../../../../../../../src/app/models/attachments';

import { DocViewerComponent } from '../../../../../../../src/app/caretx-shared-module/CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-bedsorepictrack',
  templateUrl: './bedsorepictrack.component.html',
  styleUrls: ['./bedsorepictrack.component.css']
})
export class BedsorepictrackComponent implements OnInit {

  @Input() attachments:Attachment[] = []
  @Input() Id: string
  @Output() downloadProcessDone = new EventEmitter<void>();
  currentRowIndex: number
  deleteSysMsgId: string
  deleteAlert: string
  alertTitle: string
  fileContent: any
  pdfContent: any

  constructor(
    
    private _title: Title,
    public dialog: MatDialog,
    private router: Router,
    private _ar: ActivatedRoute,) { }

  ngOnInit(): void {

    this._title.setTitle('Caretx |  אירועים חריגים');
  }


  downloadFile(file: Attachment) {
    const dialogRef = this.dialog.open(DocViewerComponent, {
      data: {
        Type: 'GF', // General File
        Id: this.Id,
        Attachment: file
      }
    });
  }





}
