
import { propertyMap } from './modelmapper'

// Need Server to send Payload in below structure
export class NursePhysicalCheck{

    @propertyMap('ID')
    ID: string 
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('Description')
    Description: string
    @propertyMap('Order')
    Order: number
    @propertyMap('Statuses')
    Statuses: NursePhysicalStatus[]
    @propertyMap('Code')
    Code: string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false


    constructor(data?: Object) {

        if (data != undefined && data != null) {
            this.ID = data["ID"] 
            this.Code = data["Code"] 
            this.PersonelID = data["PersonelID"]
            this.Description = data["Description"] 
            this.Order = data["Order"]  
            this.Statuses = data["Statuses"]  
        }
    }
}


export class NursePhysicalStatus {

    @propertyMap('ID')
    ID: string 
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('Status')
    Status: string
    @propertyMap('NursePhysicalCheckId')
    NursePhysicalCheckId: string
    @propertyMap('DefaultTexts')
    DefaultTexts: NursePhysicalStatusDefaultText[]
    
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false
    constructor(data?: Object) {

        if (data != undefined && data != null) {
            this.ID = data["ID"] 
            this.NursePhysicalCheckId = data["NursePhysicalCheckId"]
            this.PersonelID = data["PersonelID"]
            this.Status = data["Status"]
            this.DefaultTexts = data["DefaultTexts"]
            
        }
    }
}


export class NursePhysicalStatusDefaultText {

    @propertyMap('ID')
    ID: string 
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('DefaultText')
    DefaultText: string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false
    constructor(data?: Object) {

        if (data != undefined && data != null) {
            this.ID = data["ID"] 
            this.PersonelID = data["PersonelID"]
            this.DefaultText = data["DefaultText"]
           
            
        }
    }
}

