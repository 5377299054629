import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { CaretxSharedModulesProviderService } from 'src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { Attachment } from 'src/app/models/attachments';
import { CartexEvent } from 'src/app/models/genericTypes';
import { MedicalAdmission } from 'src/app/models/medicaladmission';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-externalmedicaladmission',
  templateUrl: './externalmedicaladmission.component.html',
  styleUrls: ['./externalmedicaladmission.component.css']
})


export class ExternalmedicaladmissionComponent extends CaretexComponent implements OnInit {

  currentMedicalAdmission: MedicalAdmission = new MedicalAdmission()
  showGeneratedPdf:boolean = false
  caretexEvent: CartexEvent = new CartexEvent()
  id: string
  admissionid: string
  moduleName: string = "קבלה רפואית"
  step: number = 0


  constructor(
    private _title: Title,
    private router: Router,
    public medicalFileService: MedicalFileProviderService,
    private route: ActivatedRoute,
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private _ar: ActivatedRoute,
    private toastrService: ToasterService,
    private authService: AuthService,
    private caretxSetupsProviderService: CaretxSetupsProviderService) {
    super(medicalFileService)
   
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex |טופס קבלה רפואית');
    if(this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'];
    this.admissionid = this.route.snapshot.params.admissionid
    this.CheckIfComponentIsDisabled(this.id)

    if (this.admissionid == 'new') {
      this.getNurseAdmissionFromDB("new")
    } else {
      this.getNurseAdmissionFromDB("record")
    }
  }


  getNurseAdmissionFromDB(mode: string) {
    let filter =""
    if (mode != "new") {
      filter = '(id=' + '\'' +   this.admissionid + '\'' + ')'
    }

    this.caretxMedicalProviderService.getMedicalAdmissions(this.id, filter, 1,mode).subscribe((data: MedicalAdmission[]) => {
      if (data.length == 1) {
        this.currentMedicalAdmission = new MedicalAdmission(data[0])
        if (this.currentMedicalAdmission.PdfFile != undefined) {
            this.downloadFile(this.currentMedicalAdmission.PdfFile )
        }
      }
    });
  }


  Save() {
    if (this.admissionid == 'new') {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }

  }

  DoSave() {
    this.currentMedicalAdmission.UpdatedAt = new Date()
    this.currentMedicalAdmission.PersonelID = this.id
    this.currentMedicalAdmission.CreatedAt = this.currentMedicalAdmission.UpdatedAt
    this.currentMedicalAdmission.RealmID = this.authService.LoggedInTenantID
    this.currentMedicalAdmission.Status="C"
    this.currentMedicalAdmission.ClosedById = this.authService.LoggedInUser.UID
    this.currentMedicalAdmission.Closed_at =  new Date()
    this.currentMedicalAdmission.IsExternalAdmission = true
    this.caretxMedicalProviderService.createNewMedicalAdmission(this.currentMedicalAdmission).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentMedicalAdmission = new MedicalAdmission(data)
        this.uploadAdmissionAsPdf(this.currentMedicalAdmission.ID)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    this.currentMedicalAdmission.UpdatedAt = new Date()
    this.currentMedicalAdmission.RealmID = this.authService.LoggedInTenantID
    this.caretxMedicalProviderService.updateMeidcalAdmission(this.currentMedicalAdmission).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentMedicalAdmission = new MedicalAdmission(data)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.medicalFileService.ResetCachedMedicalFile(this.id)
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialadmission/" + this.currentMedicalAdmission.ID + "/step/" + this.step)

      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  BackToList() {
    this.router.navigateByUrl("/caretexfile/" + this.id + "/medicaladmissions")
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }

  /******************************************************************************* */
  OpenFileDialog(){
    (document.querySelector('input[id="doc-input"]') as HTMLElement).click()
  }

  fileName:string
  DocToUpload: any = null;
  fileTitle:string
  file:any
  attachedFile: Attachment = new Attachment()
  
  prepareDocumentForUpload(files: FileList) {
    this.fileName = ""
    this.DocToUpload = null
    this.fileTitle = ""
    this.file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      this.fileName = this.file["name"]
      this.DocToUpload = reader.result
      this.fileTitle = this.file["name"]
    };
  }

  uploadAdmissionAsPdf(recordId:string) {
    this.attachedFile.CreatedAt = new Date()
    this.attachedFile.FileCategory ="medicaldmission"
    this.attachedFile.UpdatedAt = this.attachedFile.CreatedAt
    this.attachedFile.PersonelID = this.id
    this.attachedFile.FileDataBase64 = this.DocToUpload
    this.attachedFile.Description = "Imported As external Nurse Admission"
    this.attachedFile.FileName = this.fileName
    this.attachedFile.Uploaded_at = new Date()
    this.attachedFile.CreatedById = this.authService.LoggedInUser.UID
    this.attachedFile.CreatedBy = this.authService.LoggedInUser
    this.attachedFile.Uploaded_at = new Date()
    this.attachedFile.LinkedTo = recordId
    this.caretxSharedlService.uploadFile(this.attachedFile).subscribe({
      next: data => {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.medicalFileService.ResetCachedMedicalFile(this.id)
        this.router.navigateByUrl("caretexfile/" + this.id + "/externalmedicaladmission/" + recordId)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  @ViewChild('pdfViewer') pdfViewer;
  isPdfFile:boolean = false
  file223 :string = ""
  pdfContent:any

  downloadFile(file: Attachment) {
    let extension = (file.FileName).split('.').pop();
    let typeOfBlob = "application/octet-stream"
    switch (extension) {
      case "pdf":
        typeOfBlob = "application/pdf"
          this.isPdfFile = true
        break
      case "jpg":
        typeOfBlob = "image/jpg"
        this.isPdfFile = false
        break
      case "jpeg":
        typeOfBlob = "image/jpeg"
        this.isPdfFile = false
        break
      case "png":
        typeOfBlob = "image/png"
        this.isPdfFile = false
        break
      default:
        break
    }



    this.caretxSharedlService.downloadFile(this.id, file)
      .subscribe(data => {
        var file = new Blob([data], { type: typeOfBlob });
        


        this.pdfContent = URL.createObjectURL(file);
        if (this.isPdfFile){
          this.pdfViewer.pdfSrc = file
          this.showGeneratedPdf = true
          this.pdfViewer.refresh();
        }else{
        }


      }, error => {
        console.log(error);
        var errorAsString = "failed to download file"
        try {
          var enc = new TextDecoder("utf-8")
          var text = enc.decode(error.error)
          errorAsString = JSON.parse(text).error
        } catch (e) {

        }
      })
  }

}