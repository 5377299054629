import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretexComponent } from '../../../app/shared/CaretexComponent';
import { MedicalFileProviderService } from '../../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretxPhsioTherapyProviderService } from '../Physiotherapty.data-provider.service';
import { AuthService } from '../../../app/services/auth.service'
import { ToasterService } from '../../services/toastr.service';
import { PhysioAssessment } from '../../models/physiotherapy';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-physio-shorten-assessment',
  templateUrl: './physio-shorten-assessment.component.html',
  styleUrls: ['./physio-shorten-assessment.component.scss']
})
export class PhysioShortenAssessmentComponent extends CaretexComponent implements OnInit {
  @ViewChild('mainform') mainFormElement: NgForm;
  show: string = "Diagnoses"
  moduleName: string = "הערכת פיזותרפיה"
  currentAssessment: PhysioAssessment = new PhysioAssessment()
  LastAssessment: PhysioAssessment = undefined
  isRecordClosed: boolean = false
  recordid: string
  personalId: string
  step: number = 1
  substep: number = 1

  constructor(
    private _title: Title,
    private router: Router,
    private _ar: ActivatedRoute,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private phsiotheraptyService: CaretxPhsioTherapyProviderService,
    private route: ActivatedRoute) {
    super(medicalFileService);
  }

  ngOnInit(): void {
    this.personalId = this.route.parent.snapshot.params['id']  //this._ar.snapshot.params['id'];
    this.recordid = this.route.snapshot.params.recordid
    this.CheckIfComponentIsDisabled(this.personalId)

    if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
      this.step = Number(this.route.snapshot.params['stepid']);
    }
    if (this.route.snapshot.params['childstepid'] != "" && this.route.snapshot.params['childstepid'] != undefined) {
      this.substep = Number(this.route.snapshot.params['childstepid']);
    }

    if (this.recordid == "new") {
      this.getAssessmentsFromDB("new")
    } else {
      this.getAssessmentsFromDB("retrieve")
    }
  }


  getAssessmentsFromDB(mode: string) {
    let filter = ""
    if (mode == "retrieve") {
      filter = filter = '(id=' + '\'' + this.recordid + '\'' + ')'
    }
    this.phsiotheraptyService.getPhysioTherapyAssessment(this.personalId, filter, mode).subscribe((data: PhysioAssessment[]) => {
      if (data.length == 1) {
        this.currentAssessment = new PhysioAssessment(data[0])
        if (this.currentAssessment.ID == '00000000-0000-0000-0000-000000000000' && mode == "new") {
          this.currentAssessment.PersonelID = this.personalId
          this.currentAssessment.TenantID = this.authServive.LoggedInTenantID
          this.currentAssessment.LastUpdateById = this.authServive.LoggedInUser.UID
          this.currentAssessment.CreatedAt = new Date()
        }
        if (this.currentAssessment.Status == "C") {
          this.isRecordClosed = true
        } else
          this.isRecordClosed = false
      }
      else if (data.length == 0) {
        let errorMsg = "המערכת לא הצליחה לייצר רשומה חדשה"
        this.toastrService.Error("", errorMsg)
      }
      else {
        let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }

  NewRecord() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/phsiotherapyassessment/new")
  }

  SaveRecord() {
    if (this.currentAssessment.ID == "00000000-0000-0000-0000-000000000000") {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    this.currentAssessment.UpdatedAt = new Date()
    this.currentAssessment.CreatedAt = this.currentAssessment.UpdatedAt
    this.currentAssessment.LastUpdateById = this.authServive.LoggedInUser.UID
    this.currentAssessment.TenantID = this.authServive.LoggedInTenantID
    this.phsiotheraptyService.createPhysioTherapyAssessment(this.currentAssessment).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentAssessment = new PhysioAssessment(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.personalId + "/phsiotherapyassessment/" + this.currentAssessment.ID + "/step/" + this.step + "/childstep/" + this.substep)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    this.currentAssessment.UpdatedAt = new Date()
    this.currentAssessment.TenantID = this.authServive.LoggedInTenantID
    this.phsiotheraptyService.updatePhysioTherapyAssessment(this.currentAssessment).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentAssessment = new PhysioAssessment(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.personalId + "/phsiotherapyassessment/" + this.currentAssessment.ID + "/step/" + this.step + "/childstep/" + this.substep)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }


  setStep(index: number) {
    this.step = index;
  }

  setsubStep(index: number) {
    this.substep = index;
  }

  lockRecord() {
    if (this.currentAssessment.Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם את/ה רוצה לנעול רשומה זאת ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תנעל',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentAssessment.Status = 'C'
          this.currentAssessment.ClosedAt = new Date()
          this.currentAssessment.ClosedById = this.authServive.LoggedInUser.UID
          this.SaveRecord()
        }
      })
    }
  }

  CopyLastReportedFollowUp(id: string) {

    if (this.LastAssessment == undefined || this.LastAssessment == null) {
      // Bring Last Assessment from Server 
      let mode = "latest"
      this.phsiotheraptyService.getPhysioTherapyAssessment(this.personalId, "", mode).subscribe((data: PhysioAssessment[]) => {
        if (data.length == 1) {
          this.LastAssessment = new PhysioAssessment(data[0])
          this.DoCopyOfField(id)
        }
      });

    } else {
      this.DoCopyOfField(id)
    }

  }

  DoCopyOfField(id: string) {
    switch (id) {
      case "Diagnoses":
        this.currentAssessment.Diagnoses = this.LastAssessment.Diagnoses
        break
      case "Cooperation":
        this.currentAssessment.Cooperation = this.LastAssessment.Cooperation
        break
      case "Orientation":
        this.currentAssessment.Orientation = this.LastAssessment.Orientation
        break
      case "Function1":
        this.currentAssessment.Function1 = this.LastAssessment.Function1
        break
      case "Function2":
        this.currentAssessment.Function2 = this.LastAssessment.Function2
        break
      case "Function3":
        this.currentAssessment.Function3 = this.LastAssessment.Function3
        break
      case "Function4":
        this.currentAssessment.Function4 = this.LastAssessment.Function4
        break
      case "Function5":
        this.currentAssessment.Function5 = this.LastAssessment.Function5
        break
      case "Function6":
        this.currentAssessment.Function6 = this.LastAssessment.Function6
        break
      case "Function7":
        this.currentAssessment.Function7 = this.LastAssessment.Function7
        break
      case "Function8":
        this.currentAssessment.Function8 = this.LastAssessment.Function8
        break
      case "Function9":
        this.currentAssessment.Function9 = this.LastAssessment.Function9
        break
      case "Summary":
        this.currentAssessment.Summary = this.LastAssessment.Summary
        break
      case "Recommendation":
        this.currentAssessment.Recommendations = this.LastAssessment.Recommendations
        break
    }

  }

  ClearTextField(id: string) {

    switch (id) {
      case "Diagnoses":
        this.currentAssessment.Diagnoses = ""
        break
      case "Cooperation":
        this.currentAssessment.Cooperation = ""
        break
      case "Orientation":
        this.currentAssessment.Orientation = ""
        break
      case "Function1":
        this.currentAssessment.Function1 = ""
        break
      case "Function2":
        this.currentAssessment.Function2 = ""
        break
      case "Function3":
        this.currentAssessment.Function3 = ""
        break
      case "Function4":
        this.currentAssessment.Function4 = ""
        break
      case "Function5":
        this.currentAssessment.Function5 = ""
        break
      case "Function6":
        this.currentAssessment.Function6 = ""
        break
      case "Function7":
        this.currentAssessment.Function7 = ""
        break
      case "Function8":
        this.currentAssessment.Function8 = ""
        break
      case "Function9":
        this.currentAssessment.Function9 = ""
        break
      case "Summary":
        this.currentAssessment.Summary = ""
        break
      case "Recommendation":
        this.currentAssessment.Recommendations = ""
        break
    }
  }

  OpenAssessmentHandler($event) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/phsiotherapyassessment/" + $event)
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty == null ? false : this.mainFormElement.dirty
  }
}
