import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-nursefamilyconversationslist',
  templateUrl: './nursefamilyconversationslist.component.html',
  styleUrls: ['./nursefamilyconversationslist.component.css']
})
export class NursefamilyconversationslistComponent extends CaretexComponent  implements OnInit {

  origin :string = "nurse"
  personalId:string
  recordid:string


  constructor(    public medicalFileService: MedicalFileProviderService,    private router: Router,
    private route: ActivatedRoute) {
    super(medicalFileService)
  }

  ngOnInit(): void {
    if (this.route.parent != null)
      this.personalId = this.route.parent.snapshot.params['id'];
    this.recordid = this.route.snapshot.params.recordid

    this.CheckIfComponentIsDisabled(this.personalId)
  }


  

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }

}
