import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Drug } from 'src/app/models/drug';
import { AuthService } from 'src/app/services/auth.service';
import { BridgeEnums, OptionObject } from 'src/app/services/enums';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { DrugUom } from 'src/app/models/generalhousedsetups';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';

@Component({
  selector: 'app-drug-details',
  templateUrl: './drug-details.component.html',
  styleUrls: ['./drug-details.component.css']
})
export class DrugDetailsComponent extends CaretexComponent implements OnInit {
  @ViewChild('mainform') mainFormElement: NgForm;
  
  Drug: Drug 
  DrugDosageForms: OptionObject[]
  DoseUoms: DrugUom[] = []  
  id:string
  isNew:boolean
  TabId = "main"
  public addTagNowRef: (name) => void;

  constructor(
    private toastrService: ToasterService,
    private bridgeEnum: BridgeEnums,
    private authService: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private _ar: ActivatedRoute,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private caretxSharedlService: CaretxSharedModulesProviderService,) {
      super(medicalFileServive)
      bridgeEnum = new BridgeEnums()
    this.DrugDosageForms = bridgeEnum.DrugDosageForm
   // this.DoseUoms = bridgeEnum.DrugUOMs
   this.caretxSetupsProviderService.getDrugUoms().subscribe((data => {
    this.DoseUoms = data
  }))
  
    this.addTagNowRef = this.CreateNewUom.bind(this);
  }

  ngOnInit(): void {

    this.id = this._ar.snapshot.params['id'];
    if (this._ar.snapshot.data["mode"] != undefined) {
      if ((this._ar.snapshot.data["mode"][0]) == "newpage") {
      this.Drug = new Drug()
      this.isNew = true
      }

    } else {
      this.getDrugDetailsById()
    }
  }



  CreateNewUom(uomVal) {

    // Create New Uom 
    var newDrugUom = new DrugUom()
    newDrugUom.New = true
    newDrugUom.editMode = true
    newDrugUom.CreatedAt = new Date()
    newDrugUom.UpdatedAt = new Date()
    newDrugUom.TenantID = this.authService.LoggedInTenantID
    newDrugUom.Uom = uomVal

    let uoms: DrugUom[] = []
    uoms.push(newDrugUom)

    this.caretxSetupsProviderService.CreateUpdateDrugUoms(uoms).subscribe({
      next: data => {
        this.getDrugUomsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })

  }

  getDrugUomsFromDB() {
    this.caretxSetupsProviderService.getDrugUoms().subscribe(
      data => {
        this.DoseUoms = data
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      err => {
        this.DoseUoms = []

      }
    )
  }


  getDrugDetailsById(){
    let filter = "id='"+this.id + "'"
    this.caretxSharedlService.getMedicalDrugs(filter).subscribe(
      data => {
        if (data.length == 1) {
          this.Drug = new Drug(data[0])
        }else{
          let errorMsg = "הפעולה נכשלה ,נמצאו יותר מרשימה אחת"
          this.toastrService.Error("", errorMsg)
        }     
      },
      err => {
        let errorMsg = "הפעולה נכשלה"
        this.toastrService.Error("", errorMsg)
      }
    )
  }


  SaveNewDrug() {
      if (this.Drug.id == "" || this.Drug.id == null) {
        this.DoSave()
      }
      else {
        this.DoUpdate()
      }
 
  }

  DoSave() {
    this.Drug.CretedById = this.authService.LoggedInUser.UID
    this.caretxSharedlService.createNewMedicalDrug(this.Drug).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)  
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("drugslist/" +  this.Drug.id)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    this.caretxSharedlService.updateMedicalDrug(this.Drug).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)  
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("drugslist/" +  this.Drug.id)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }



  BackToList(){
    this.router.navigate(['drugslist']);
  }

  NewDrug(){
    this.router.navigate(['newmedicaldrug']);
  }
  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty 
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

}
