import { Component, OnInit } from '@angular/core';
import { CaretexMessage } from 'src/app/models/notificationMap';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';

@Component({
  selector: 'app-caretex-messages',
  templateUrl: './caretex-messages.component.html',
  styleUrls: ['./caretex-messages.component.scss']
})
export class CaretexMessagesComponent implements OnInit {
  allUsers: User[] = [];
  selectedUser: User 
  messages:CaretexMessage[] = []
  CurrentText:string = ""
  LoggedUID:string
  DefaultPhotoURL = "../../../../assets/images/user-avatar.png"
  
  constructor(
    public authService : AuthService, 
    private toastrService: ToasterService,
    private caretexSharedService: CaretxSharedModulesProviderService) { 
      this.LoggedUID = this.authService.LoggedInUser.UID
    }

  ngOnInit(): void {
    this. LoadAllUsers() 
  }


  LoadAllUsers() {
    this.authService.getUsers();

    this.authService.dataChange.subscribe((data) => {
      this.allUsers = data
    }, error => {
      console.log(error);
    }); 
  }


  selectUserForMessage(user){
    this.selectedUser = user
    // Bring All Messages 
    this.caretexSharedService.getMessages(this.selectedUser.UID).subscribe(
      data => this.messages = data,
      err => {
        this.messages = []

      }
    )
  }

  GetMessage() {
    this.caretexSharedService.getMessages(this.selectedUser.UID).subscribe(
      data => this.messages = data,
      err => {
        this.messages = []
      }
    )
  }

  SubmitNewMessage() {

    let message =  new CaretexMessage()
    message.FromUserId = this.authService.LoggedInUser.UID
    message.ToUserId = this.selectedUser.UID
    message.CreatedAt =  new Date() 
    message.TenantID = this.authService.LoggedInTenantID
    message.Message = this.CurrentText
    this.caretexSharedService.createNewMessage(message).subscribe({
      next: data => {
        this.CurrentText = ""
        this.GetMessage()
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  setDefaultPic(user){
    user.photoURL = "./../../../../assets/images/user-avatar.png"
  }
}
