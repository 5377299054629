import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalFile } from 'src/app/models/customer';

import { Departments, Funder, NursingStatus } from 'src/app/models/generalhousedsetups';
import { BrdgServiceResponse } from 'src/app/models/serverresponse';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-person-details-edit',
  templateUrl: './person-details-edit.component.html',
  styleUrls: ['./person-details-edit.component.css']
})
export class PersonDetailsEditComponent implements OnInit {
  medicalFile: MedicalFile
  departments: Departments[] = []
  nursingStatuses: NursingStatus[] = []
  funders: Funder[] = []

  id: string

  calcAge: Number
  serverResponse: BrdgServiceResponse
  isEditMode: boolean
  constructor(private _title: Title,
   // private _customersService: CustomerService,
    private medicalFileService:MedicalFileProviderService,
    private caretxSetupService :CaretxSetupsProviderService,
    private router: Router,
    private _ar: ActivatedRoute,
    private authServive : AuthService,
    private toastrService: ToasterService) {
    this.serverResponse = new BrdgServiceResponse()

    this.caretxSetupService.GetHouseDepartments().subscribe((data=>{
      this.departments = data
    }))

    this.caretxSetupService.GetHouseFunders().subscribe((data=>{
      this.funders = data
    }))

    this.caretxSetupService.GetNursingStatuses().subscribe((data=>{
      this.nursingStatuses = data
    }))



  }

  ngOnInit(): void {


    this._title.setTitle('Caretex|כרטיס דייר');
    this.id = this._ar.snapshot.params['id'];


    if (this.id == null || this.id == undefined) {
      this.isEditMode = false
      this.medicalFile = new MedicalFile()
    }
    else {
      this.isEditMode = true

      this.medicalFileService.getMedicalFile(this.id)

      this.medicalFileService.openedFile.subscribe(data =>{ 
      this.medicalFile = new MedicalFile(data)
      if (this.medicalFile.PersonelID == undefined) {
        this.medicalFile = null
      }  
    })

    }
  }




  returntoDetailsPage() {
    if(!this.isEditMode){
      this.router.navigate(['medfiles']);
    }
    else{
    this.router.navigateByUrl("/medicalfiles/" + this.medicalFile.PersonelID)
    }
  }

  saveDetails() {
    this.medicalFile.MigrationDate = new Date(this.medicalFile.MigrationDate)
    this.medicalFile.BirthDay = new Date(this.medicalFile.BirthDay)
    this.medicalFile.JoinedAt = new Date(this.medicalFile.JoinedAt)
    this.medicalFile.ReleasedAt = new Date(this.medicalFile.ReleasedAt)
    this.medicalFile.LastUpdated_at =  new Date()
    this.medicalFile.LastUpdatedById = this.authServive.LoggedInUser['UID']
    this.medicalFile.DepartmentAcceptanceDate = new Date(this.medicalFile.DepartmentAcceptanceDate)
    this.medicalFile.Status = null
    this.medicalFile.Department = null
    this.medicalFile.Funder = null

    if (this.id == null || this.id == undefined) {
      this.saveNewFile()
    }
    else {
      this.updateDetails()
    }

  }


  updateDetails() {
    this.medicalFileService.updateMedicalFile(this.medicalFile).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.medicalFileService.CurrentOpenedFile = this.medicalFile
        this.returntoDetailsPage()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  saveNewFile() {
    this.medicalFileService.newMedicalFile(this.medicalFile).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.medicalFileService.CurrentOpenedFile = this.medicalFile
        this.returntoDetailsPage()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })

  }

}
