import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MeasuresTypes } from '../../../../../../src/app/models/generalhousedsetups';
import { MeasureCodeListValues, PersonalMeasureCode } from '../../../../../../src/app/models/measure';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-essentialmeasuresdetails',
  templateUrl: './essentialmeasuresdetails.component.html',
  styleUrls: ['./essentialmeasuresdetails.component.css']
})
export class EssentialmeasuresdetailsComponent extends CaretexComponent implements OnInit {
  @ViewChild('mainform') mainFormElement: NgForm;
  id:string
  selectedMeasure: PersonalMeasureCode
  measuresTypes: MeasuresTypes[]
  valueTypes =
    [
      { "id": "N", "name": "ערך מספרי" },
      { "id": "S", "name": "ערך טקסט" },
      { "id": "DDL", "name": "רשימה" },
      { "id": "bloodpressure", "name": "ערך לחץ דם" }
    ];


    additionalvalueTypes =
    [
      { "id": "TXT", "name": "שדה טקסט" },
      { "id": "DDL", "name": "שדה רשימה" },
    ];


  
  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,) {  super(medicalFileServive)}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    if (this.id == "new") {
        this.selectedMeasure =  new PersonalMeasureCode()
        this.selectedMeasure.isNew = true
        this.selectedMeasure.AdditionalListValues = []
        this.selectedMeasure.ListValues = []
        this.selectedMeasure.TenantID = this.authServive.LoggedInTenantID
    }else {
      this.getMeasureByIDFromDB()
    }

    this.getMeasuresTypeFromDB()
  }

  getMeasuresTypeFromDB() {
    this.caretxSetupsProviderService.GetMeasuresTypes(this.authServive.LoggedInTenantID).subscribe(
      data => {
        this.measuresTypes  = data
      },
      err => {
        this.measuresTypes = []

      }
    )
  }

  getMeasureByIDFromDB() {
    let filter = '(id=' + '\'' + this.id + '\'' + ')'
    this.caretxSetupsProviderService.GetEssentialMeasures(filter).subscribe( (data: PersonalMeasureCode[]) => {

      if (data.length == 1) {
        this.selectedMeasure = data[0]    
        }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    })
  }

  updateMeasure($event) {
    if (this.selectedMeasure.IsGlobal || this.id == "new"){
     this.doCreateMeasure()
    }else{
      this.doUpdateMeasure()
    }
  }

  doUpdateMeasure() {
    this.caretxSetupsProviderService.UpdateEssentialMeasure(this.selectedMeasure).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.id = (data as PersonalMeasureCode).ID
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("essentialmeasures_settings/" + this.id)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  doCreateMeasure(){
    this.caretxSetupsProviderService.CreateEssentialMeasure(this.selectedMeasure).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.id = (data as PersonalMeasureCode).ID
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("essentialmeasures_settings/" + this.id)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  BackToList() {

    this.router.navigate(['essentialmeasures_settings']);
}

  deleteListValue(i) {
    if (this.selectedMeasure.ListValues != undefined) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false, 
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          if (!this.selectedMeasure.isNew) {
            this.caretxSetupsProviderService.DeleteListValue(this.selectedMeasure.ID,this.selectedMeasure.ListValues[i].ID).subscribe({
              next: data => {
                this.selectedMeasure.ListValues.splice(i, 1)
                this.toastrService.Sucesss("", this.toastrService.successMsg)
              },
              error: error => {
                this.toastrService.Error("", error.error)
              }
            })
          }
          else
          this.selectedMeasure.ListValues.splice(i, 1)
        }
      })
    }
  }

  addNewValue() {
    if (this.selectedMeasure.ListValues == undefined) {
      this.selectedMeasure.ListValues = []
    }
    var tmp = new MeasureCodeListValues()
    tmp.CreatedAt = tmp.UpdatedAt = new Date()
    tmp.Value = ""
    tmp.Type = "value"
    tmp.editMode = true
    tmp.TenantID = this.authServive.LoggedInTenantID

    this.selectedMeasure.ListValues.unshift(tmp)
  }

  addNewAdditionalValue(){
    if (this.selectedMeasure.AdditionalListValues == undefined) {
      this.selectedMeasure.AdditionalListValues = []
    }
    var tmp = new MeasureCodeListValues()
    tmp.CreatedAt = tmp.UpdatedAt = new Date()
    tmp.Value = ""
    tmp.Type = "Additional"
    tmp.editMode = true
    tmp.TenantID = this.authServive.LoggedInTenantID

    this.selectedMeasure.AdditionalListValues.unshift(tmp)
  }

  trackByIndex(index: number) { return index }

  editListValue(i){
    this.selectedMeasure.ListValues[i].editMode = true
  }

  cancelListValue(i){
    this.selectedMeasure.ListValues[i].editMode = false
  }

  editAdditionalListValue(i){
    this.selectedMeasure.AdditionalListValues[i].editMode = true
  }

  cancelAdditionalListValue(i){
    this.selectedMeasure.AdditionalListValues[i].editMode = false
  }


  

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const rowsChanged = this.mainFormElement.dirty
    return rowsChanged
  }



}
