import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { MedicalPersonelMeasuresProviderService } from '../../../../../src/app/caretx-doctors-module/doctor-services/medical-perosnelmeasures.data-provider.service';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MeasuresTypes } from '../../../../../src/app/models/generalhousedsetups';
import { PersonalMeasureCode, PersonalMeasures, PersonalMeasuresHeader } from '../../../../../src/app/models/measure';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { AccessComponenetType, BridgeEnums, NurseAdmissionSteps, OptionObject } from '../../../../../src/app/services/enums';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CartexEvent, TabHistory } from '../../../../../src/app/models/genericTypes';
import { FormBuilder, NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { CaretxMedicalProviderService } from '../../../caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { OriginModule } from '../../../services/enums';
import { DialogContentExampleDialog } from '../../CaretexModals/personelmeasuresresults/dialog-content-example-dialog';
import { CaretexComponent } from '../../../shared/CaretexComponent';
import { CaretxNursingProviderService } from '../../../caretx-nusrsing-module/nursing-services/Nursing.data-provider.service'
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-frequentmeauresmodule',
  templateUrl: './frequentmeauresmodule.component.html',
  styleUrls: ['./frequentmeauresmodule.component.scss']
})
export class FrequentmeauresmoduleComponent extends CaretexComponent implements OnInit {

  @Input() currentPersonelMeasureHeader: PersonalMeasuresHeader = new PersonalMeasuresHeader()
  @Input() IsResordClosed: boolean
  @Input() events: Observable<CartexEvent>;
  @Input() Origin: OriginModule;
  @Input() DisableMeasureTypeSelection: boolean = false
  @Input() MeasureHeaderId: string;
  @Input() Type: string
  @Input() showNewButton: boolean
  @Input() PersonalId: string
  @Input() IsFromCentralizedPage: boolean = false

  @Output() updateNextTab = new EventEmitter<string>();
  @Output() ModuleChanged = new EventEmitter<boolean>();
  @Output() ChangedPersonalMeasuresHeader = new EventEmitter<PersonalMeasuresHeader>();
  @Output() newPersonalMeasure = new EventEmitter<void>();


  //allPersonelMeasures: PersonalMeasures[]
  id: string
  isdocumentClosed: boolean
  measuresTypes: MeasuresTypes[] = []
  showPersonelMeasuresModal: boolean
  showTextModal: boolean
  personelMeasuresModalId: string
  textModalId: string
  personelMeasuresModalTarget: string
  textModalTarget: string
  currentSelectedMeasurecode: PersonalMeasureCode
  currentSelectedPersonelMeasur: PersonalMeasures
  currenctSelectedMeasureType: string
  UrinateMethods: OptionObject[]
  OxygenMethods: OptionObject[]
  value: boolean = false;
  childNotifier: Subject<PersonalMeasureCode> = new Subject<PersonalMeasureCode>();
  moduleName: string = "מדדים חיוניים"
  allMeasuresTypeId: string
  private eventsSubscription: Subscription;
  private nextTab: string
  @ViewChild('mainform') mainFormElement: NgForm;
  currentSelectedMeasureIndex: number
  mode: string


  constructor(
    private _fb: FormBuilder,
    private _title: Title,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private toastrService: ToasterService,
    private router: Router,
    private caretxNursingProviderService: CaretxNursingProviderService,
    public medicalFileService: MedicalFileProviderService,
    private route: ActivatedRoute,
    private medicalPersonelMeasuressService: MedicalPersonelMeasuresProviderService,
    public dialog: MatDialog,
    private medicalservice: CaretxMedicalProviderService,
    bridgeEnum: BridgeEnums,) {
    super(medicalFileService)
    this.UrinateMethods = bridgeEnum.UrinateMethods
    this.OxygenMethods = bridgeEnum.OxygenMethods
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex| מדדים חיוניים');
    this.showPersonelMeasuresModal = false

    if (!this.IsFromCentralizedPage) {
      if (this.route.parent != null)
        this.id = this.route.parent.snapshot.params['id'];
    }else {
      this.id  = this.PersonalId
    }


    if (this.Type == AccessComponenetType.SubModule && (this.Origin == OriginModule.NurseAdmission || this.Origin == OriginModule.MedicalAdmission)) {
      this.IsResordClosed = this.isdocumentClosed = (this.currentPersonelMeasureHeader.Status == "C")
      if (this.MeasureHeaderId != undefined && this.MeasureHeaderId != "" && this.MeasureHeaderId != "00000000-0000-0000-0000-000000000000") {
        this.DisableMeasureTypeSelection = true
        this.getPersonalMeasureHeaderById(this.MeasureHeaderId, "", "retrieve")
      }
      else {
        this.mode = "new"
        this.DisableMeasureTypeSelection = false
      }
    }

    else if (this.Type == AccessComponenetType.SubModule && (this.Origin == OriginModule.NurseFollowUp || this.Origin == OriginModule.MedicalFollowUp)) {
      if (this.MeasureHeaderId != undefined && this.MeasureHeaderId != "" && this.MeasureHeaderId != "00000000-0000-0000-0000-000000000000") {
        this.getPersonalMeasureHeaderById(this.MeasureHeaderId, "", "retrieve")
      } else {
        this.mode = "new"
        this.DisableMeasureTypeSelection = false
      }
    }

    else if (this.MeasureHeaderId == "new") {
      this.mode = "new"
      this.DisableMeasureTypeSelection = false

    } else {
      this.DisableMeasureTypeSelection = true
      this.getPersonalMeasureHeaderById(this.MeasureHeaderId, "", "retrieve")
    }


    this.getMeasuresTypeFromDB()
    // this.getPersonalMeasuresCodes()
    if (this.events != null && this.events != undefined) {
      this.eventsSubscription = this.events.subscribe((data) => this.confirmUnSavedChanges(data));
    }
  }


  requestNewMeasure() {
    this.newPersonalMeasure.emit()
  }
  getPersonalMeasureHeaderById(id: string, measureTypeId: string, mode: string) {
    let filter = '(id=' + '\'' + id + '\'' + ')'
    if (mode == "new") {
      filter = '(status =\'A\')'
    }
    let userid = this.authServive.LoggedInUser.UID
    this.caretxNursingProviderService.getAllPersonalMeasureHeaders(this.id, measureTypeId, userid, mode, filter).subscribe((data: PersonalMeasuresHeader[]) => {
      if (data.length == 1) {
        this.currentPersonelMeasureHeader = new PersonalMeasuresHeader(data[0])
        if (mode =="new"){
          this.currentPersonelMeasureHeader.PersonalMeasures.forEach((element,index)  => {
                if (element.PersonalMeasureCode.Type == "N"){
                  this.currentPersonelMeasureHeader.PersonalMeasures[index].NumericValue = undefined
                }
                if (element.PersonalMeasureCode.Type == "bloodpressure"){
                  this.currentPersonelMeasureHeader.PersonalMeasures[index].NumericValue = undefined
                  this.currentPersonelMeasureHeader.PersonalMeasures[index].AdditionalValue= undefined
                }

            });
					
			
        }

        this.IsResordClosed = this.isdocumentClosed = (this.currentPersonelMeasureHeader.Status == "C")
        // notify caller with currentPersonelMeasureHeader 
        this.ChangedPersonalMeasuresHeader.emit(this.currentPersonelMeasureHeader)
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });

  }

  getMeasuresTypeFromDB() {
    this.caretxSetupsProviderService.GetMeasuresTypes(this.authServive.LoggedInTenantID).subscribe(
      data => {
        this.measuresTypes = data
        for (let i = 0; i < this.measuresTypes.length; i++) {
          if (this.measuresTypes[i].Sequence == 1 && (this.MeasureHeaderId == "" || this.MeasureHeaderId == "new" || this.MeasureHeaderId == "00000000-0000-0000-0000-000000000000")) {
            this.allMeasuresTypeId = this.measuresTypes[i].ID
            this.getPersonalMeasureHeaderById(this.id, this.allMeasuresTypeId, this.mode)
            break
          }
        }
      },
      err => {
        this.measuresTypes = []

      }
    )
  }

  closeMeasureResultsModal() {
    this.showPersonelMeasuresModal = false
  }

  AddNewPersonelMeasure($event) {
  }

  backToPersonelMeasuresList() {
  }

  openMeasuresModal(measure: PersonalMeasures) {
    this.currentSelectedMeasurecode = measure.PersonalMeasureCode
    this.notifyChild()
    this.personelMeasuresModalId = 'measures-modal'
    this.personelMeasuresModalTarget = '#measuresModal'
    this.showPersonelMeasuresModal = true
  }

  openTextModal(measure: PersonalMeasures, index) {
    this.currentSelectedPersonelMeasur = measure
    this.currentSelectedMeasureIndex = index
    this.notifyChild()
    this.textModalId = 'text-modal'
    this.textModalTarget = '#textModal'
    this.showTextModal = true
  }

  closeTextModalModalHandler($event) {
    this.currentPersonelMeasureHeader.PersonalMeasures[this.currentSelectedMeasureIndex].AdditionalInfoTxt = $event
  }

  notifyChild() {
    this.value = !this.value;
    this.childNotifier.next(this.currentSelectedMeasurecode);
  }

  measureTypeChanged($event) {
    let measureTypeId = $event.currentTarget.value
    this.currenctSelectedMeasureType = measureTypeId
    this.getPersonalMeasureHeaderById(this.id, measureTypeId, this.mode)

    /*  if (this.Origin == OriginModule.MedicalAdmission || this.Origin == OriginModule.MedicalFollowUp || this.Origin == OriginModule.MedicalRelease) {
        this.showLastMeasure()
      } else {
        this.getPersonalMeasureHeaderById(this.id, measureTypeId, this.mode)
      }*/
  }

  checkValue(measure) {
    ///check if red

    if (measure.PersonalMeasureCode.Type == "N" && measure.PersonalMeasureCode.MustBeInInterval) {
      if (measure.NumericValue < measure.PersonalMeasureCode.LowerRedLimit || measure.NumericValue > measure.PersonalMeasureCode.UpperRedLimit) {
        Swal.fire({
          title: "הודעת מערכת",
          text: " ערך חייב להיות בין" + measure.PersonalMeasureCode.LowerRedLimit + " ל " + measure.PersonalMeasureCode.UpperRedLimit,
          icon: 'warning',
          confirmButtonText: 'הבנתי',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          measure.NumericValue = undefined
        })
      }else{
        measure.IsRed = false
      }
    } else {
      if (measure.PersonalMeasureCode.Type == "N" && measure.PersonalMeasureCode.HasRedLimit) {
        if (measure.NumericValue > measure.PersonalMeasureCode.UpperRedLimit || measure.NumericValue < measure.PersonalMeasureCode.LowerRedLimit)
          measure.IsRed = true
        else
          measure.IsRed = false
      }
  
    }

    let isDirty = this.mainFormElement.dirty == null ? false : this.mainFormElement.dirty
    this.ModuleChanged.emit(isDirty)
  }

  confirmUnSavedChanges(data: CartexEvent) {
    if ((data.EventData as unknown as TabHistory).CurrentTab == NurseAdmissionSteps.Measures) {
      this.nextTab = (data.EventData as unknown as TabHistory).NextTab
      let isDirty = this.mainFormElement.dirty == null ? false : this.mainFormElement.dirty
      this.ModuleChanged.emit(isDirty)
      this.updateNextTab.emit(this.nextTab)
    }
  }

  showLastMeasure() {
    if (this.currentPersonelMeasureHeader.MeasureType == "00000000-0000-0000-0000-000000000000") {
      Swal.fire({
        title: "הודעת מערכת",
        text: "את/ה צריך לבחור קטגורית בדיקה",
        icon: 'warning',
        confirmButtonText: 'סגור',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    } else {

      this.medicalservice.getPerosenelMeasuresByCategoryId(this.id, this.currenctSelectedMeasureType).subscribe(
        data => {
          this.currentPersonelMeasureHeader = new PersonalMeasuresHeader(data)
          this.IsResordClosed = true

          if (this.currentPersonelMeasureHeader.ID == "00000000-0000-0000-0000-000000000000") {
            Swal.fire({
              title: "הודעת מערכת",
              text: "לא נמצאו תוצאות מתאימות",
              icon: 'warning',
              confirmButtonText: 'סגור',
              allowOutsideClick: false,
              allowEscapeKey: false
            })
          }

        },
        err => {
          this.measuresTypes = []
        }
      )
    }
  }

  openDialog(measure: PersonalMeasures) {
    this.currentSelectedMeasurecode = measure.PersonalMeasureCode
    const dialogRef = this.dialog.open(DialogContentExampleDialog, {
      data: { measurecode: this.currentSelectedMeasurecode, personelid: this.id }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty == null ? false : this.mainFormElement.dirty
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

}

