
import { Diagnoses } from './diagnoses'
import { DrugInstruction } from './drugInstruction'
import { DrugSensitivity, PersonelSensitivity } from './drugsenstivity'
import { MedicalAdmission } from './medicaladmission'
import { propertyMap } from './modelmapper'
import { NurseAdmission } from './nurseadmission'
import { NurseCognitiveTest, NurseExamination } from './nurseexamination'


// Need Server to send Payload in below structure
export class NurseRelease {

    @propertyMap('ID')
    ID: string 
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('Status')
    Status: string
    @propertyMap('Background')
    Background: string
    @propertyMap('HosptilizedReason')
    HosptilizedReason: string
    @propertyMap('PreviouseDiseases')
    PreviouseDiseases: string
    @propertyMap('CurrentDiseases')
    CurrentDiseases: string
    @propertyMap('Habits')
    Habits: string
    @propertyMap('Hobbies')
    Hobbies: string
    
    
    @propertyMap('PersonSensitivity')
    public PersonSensitivity: PersonelSensitivity
    @propertyMap('PhysicalExamination')
    public PhysicalExamination: NurseExamination

    @propertyMap('Diagnoses')
    public Diagnoses: Diagnoses[]

 
    @propertyMap('CognitiveTest')
    public CognitiveTest: NurseCognitiveTest



    @propertyMap('DrugInstructions')
    public DrugInstructions:DrugInstruction[]

    @propertyMap('LastMedicalAdmission')
    public LastMedicalAdmission: MedicalAdmission



    @propertyMap('Summary')
    Summary: string


    @propertyMap('Recommendations')
    Recommendations: string

    @propertyMap('HospitalizationCourse')
    HospitalizationCourse: string
  

    constructor(data?: Object) {

        if (data != undefined && data != null) {
            this.ID = data["ID"] 

            this.Summary = data["Summary"] 
            this.Recommendations = data["Recommendations"] 
            this.HospitalizationCourse = data["HospitalizationCourse"] 

            this.PersonelID = data["PersonelID"]
            this.Status = data["Status"]
            this.Background = data["Background"]
            this.HosptilizedReason = data["HosptilizedReason"]
            this.PreviouseDiseases = data["PreviouseDiseases"]
            this.CurrentDiseases = data["CurrentDiseases"]
            this.PersonSensitivity = data["PersonSensitivity"]
            this.Habits = data["Habits"]
            this.Hobbies = data["Hobbies"]
            this.PhysicalExamination = data["PhysicalExamination"]
            this.Diagnoses = data["Diagnoses"]
            this.CognitiveTest = data["CognitiveTest"]
            this.DrugInstructions=[]
            data["DrugInstructions"].forEach(element => {
                this.DrugInstructions.push(new DrugInstruction(element,data["PersonelId"],data["RealmID"]))
            });
            this.LastMedicalAdmission = data["LastMedicalAdmission"]
            
        }
        else {
            this.PersonelID = undefined
            this.Status = 'A'
        }
    }
}
