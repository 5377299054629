import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DrugSensitivity } from '../../../../../src/app/models/drugsenstivity';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { MatStepper } from '@angular/material/stepper';
import { Drug } from '../../../../../src/app/models/drug';
import { DrugsService } from '../../../../../src/app/services/drugs.service';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { BridgeEnums, OptionObject } from '../../../../../src/app/services/enums';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { DrugUom } from '../../../../../src/app/models/generalhousedsetups';
import { NgForm } from '@angular/forms';
import { CaretxMedicalProviderService } from '../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sensitivity-dialog',
  templateUrl: './sensitivity-dialog.component.html',
  styleUrls: ['./sensitivity-dialog.component.css']
})
export class SensitivityDialogComponent implements OnInit {

  id: string
  NewRecord: boolean
  Disabled: boolean
  OriginID: string
  OriginType:string
  newMedication: Drug = new Drug()
  DrugSensitivity: DrugSensitivity
  currentSelectedStep: number = 0
  drugName: string
  Drugs: Drug[] = []
  dropdownSettings: any = {};
  public addTagNowRef: (name) => void;
  @ViewChild('gotonewdrugwindow') gotonewdrugwindow: ElementRef;
  DrugDosageForms: OptionObject[]
  DoseUoms: DrugUom[] = []
  @ViewChild('newmedicationform') newmedicationform: NgForm;
  @ViewChild('f') mainForm: NgForm;

 

  constructor(private dialogRef: MatDialogRef<SensitivityDialogComponent>,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private bridgeEnum: BridgeEnums,
    public medicalFileService: MedicalFileProviderService,
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private drugsService: DrugsService,
    private caretxSharedlService: CaretxSharedModulesProviderService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.addTagNowRef = this.CreateMedication.bind(this);
    this.DrugDosageForms = bridgeEnum.DrugDosageForm
    this.caretxSetupsProviderService.getDrugUoms().subscribe((data => {
      this.DoseUoms = data
    }))

  }

  ngOnInit(): void {
    this.getDrugs()
    this.id = this.data.personelid;
    this.Disabled = this.data.Disabled
    this.DrugSensitivity = this.data.DrugSensitivity
    this.NewRecord = this.data.NewRecord
    this.OriginID = this.data.OriginID
    this.OriginType = this.data.OriginType

    this.DrugSensitivity.OriginType = this.OriginType
    this.DrugSensitivity.OriginId = this.OriginID
    if (this.NewRecord){
      this.PrepareNewDrugSensitivity()
    }else{
      this.DrugSensitivity.New = false
      this.DrugSensitivity.Modified = true
    }
   
  }

  getDrugs() {
    this.drugsService.getDrugsList().subscribe((data: Drug[]) => {
      this.Drugs = data;
    });
  }

  submit() {

    if (!this.mainForm.dirty) {
      this.dialogRef.close(null);
    } 
    this.DrugSensitivity.DrugId = this.DrugSensitivity.Drug.id
    let drugSensitivities = [this.DrugSensitivity]
    this.caretxMedicalProviderService.createUpdateDrugSesitivities(this.id, drugSensitivities).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.medicalFileService.ResetCachedMedicalFile(this.id)
        this.dialogRef.close(data);
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }


PrepareNewDrugSensitivity() {
 // this.DrugSensitivity = new DrugSensitivity()
  this.DrugSensitivity.Drug = new Drug()
  this.DrugSensitivity.New = true
  this.DrugSensitivity.UpdatedAt = this.DrugSensitivity.CreatedAt =  new Date()
  this.DrugSensitivity.UserId  =this.authServive.LoggedInUser.UID
  this.DrugSensitivity.RealmID = this.authServive.LoggedInTenantID
  this.DrugSensitivity.Modified = false
  this.DrugSensitivity.PersonelID = this.id

}


  selectionChange($event) {
    this.currentSelectedStep = $event.selectedIndex
  }

  openCodesStep(stepper: MatStepper) {
    stepper.next();
  }

  openMainStep(stepper: MatStepper) {
    stepper.previous();
  }

  conevrtoDateTime($event) {
    return new Date($event)
  }

  onDrugSelect($event) {

  }

  CreateMedication(name: string) {
    this.newMedication.ShortTradeName = name
    this.gotonewdrugwindow.nativeElement.click()
  }

  GoToAddNewDrug(stepper) {
    stepper.next();
  }

  submitnewmedicationForm() {
    this.newmedicationform.onSubmit(undefined)
  }

  submitNewMedication(stepper) {
    this.AddNewMedication(stepper)
  }

  AddNewMedication(stepper) {
    let currentDate = new Date()
    let DateString = currentDate.getFullYear() + currentDate.getMonth() + currentDate.getDay()
    this.newMedication.DrugCode = "CMD_" + DateString + currentDate.getHours() + currentDate.getMinutes() + currentDate.getSeconds() + new Date().getUTCMilliseconds(); // CMD  = Customer Medical Drug
    this.newMedication.CretedById = this.authServive.LoggedInUser.UID
    this.caretxSharedlService.createNewMedicalDrug(this.newMedication).subscribe({
      next: data => {
        this.DrugSensitivity.Drug = new Drug(data)
        this.getDrugs()
        stepper.previous()
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

}
