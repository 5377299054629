import { Component, Input, OnInit } from '@angular/core';
import { BedSoreTracks } from 'src/app/models/bedsore';

@Component({
  selector: 'app-bed-sore-tracks-list',
  templateUrl: './bed-sore-tracks-list.component.html',
  styleUrls: ['./bed-sore-tracks-list.component.css']
})
export class BedSoreTracksListComponent implements OnInit {

  @Input() bedSoreTracks: BedSoreTracks[]
  
  constructor() { }

  ngOnInit(): void {
  }


  showRelatedImages(track){

  }
}
