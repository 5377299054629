import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonelHazards } from '../../../models/hazard';
import { ToasterService } from '../../../services/toastr.service';
import { CaretxMedicalProviderService } from '../../../caretx-doctors-module/doctor-services/Medical.data-provider.service';


@Component({
  selector: 'app-hazard-managment-dialog',
  templateUrl: './hazard-managment-dialog.component.html',
  styleUrls: ['./hazard-managment-dialog.component.css']
})
export class HazardManagementDialogComponent implements OnInit {

  id: string
  isNew: boolean
  isDisabled: boolean
  ReTakenTestDate: Date
  IsolationDenialDate: Date
  IsolationRemovalDate: Date
  currentHazard: PersonelHazards
  isForIsolation : boolean = false
  isForRetakeTest:boolean = false
  constructor(
    private dialogRef: MatDialogRef<HazardManagementDialogComponent>,

    private toastrService: ToasterService,
    private medicalProviderService: CaretxMedicalProviderService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;



  }

  ngOnInit(): void {

    this.id = this.data.personelid;
    this.isDisabled = this.data.IsDisabled
    this.currentHazard = this.data.currentHazard
    this.isNew = this.data.isNew

    this.isForIsolation = this.data.isForIsolation
    this.isForRetakeTest = this.data.isForRetakeTest

  }


  cancelReTakenTestDate(type) {
    if (type == "ReTakenTestDate" && this.isForRetakeTest) {
      this.IsReTakenTestDateSet = false
      this.currentHazard.IsReTakenTestDateSet = false
      this.ReTakenTestDate = undefined
      this.currentHazard.ReTakenTestDate = undefined
    }

    if (type == "IsolationDenialDate" && this.isForIsolation) {
      this.IsIsolationDenialDateSet = false
      this.currentHazard.IsIsolationDenialDateSet = false
      this.IsolationDenialDate = undefined
      this.currentHazard.IsolationDenialDate = undefined
    }


    if (type == "IsolationRemovalDate" && this.isForIsolation) {
      this.IsIsolationRemovalDateSet = false
      this.currentHazard.IsIsolationRemovalDateSet = false
      this.IsolationRemovalDate = undefined
      this.currentHazard.IsolationRemovalDate = undefined
    }
  }

  IsReTakenTestDateSet: boolean = false
  IsIsolationDenialDateSet: boolean = false
  IsIsolationRemovalDateSet: boolean = false

  updateDate(type, $event) {

    if (type == "ReTakenTestDate" && this.isForRetakeTest) {
      this.IsReTakenTestDateSet = true
      this.currentHazard.IsReTakenTestDateSet = true
      this.ReTakenTestDate = $event
      this.currentHazard.ReTakenTestDate = new Date(this.ReTakenTestDate)
    }

    if (type == "IsolationDenialDate" && this.isForIsolation) {
      this.IsIsolationDenialDateSet = true
      this.currentHazard.IsIsolationDenialDateSet = true
      this.IsolationDenialDate = $event
      this.currentHazard.IsolationDenialDate = new Date(this.IsolationDenialDate)
    }


    if (type == "IsolationRemovalDate" && this.isForIsolation) {
      this.IsIsolationRemovalDateSet = true
      this.currentHazard.IsIsolationRemovalDateSet = true
      this.IsolationRemovalDate = $event
      this.currentHazard.IsolationRemovalDate = new Date(this.IsolationRemovalDate)
    }



  }
  submit() {
    // Save First then Submit 
    if (!this.currentHazard.IsReTakenTestDateSet) {
      this.currentHazard.ReTakenTestDate = new Date('1970-01-01Z00:00:00:000')
    }
    if (!this.currentHazard.IsIsolationDenialDateSet) {
      this.currentHazard.IsolationDenialDate = new Date('1970-01-01Z00:00:00:000')
    }
    if (!this.currentHazard.IsIsolationRemovalDateSet) {
      this.currentHazard.IsolationRemovalDate = new Date('1970-01-01Z00:00:00:000')
    }
    if (this.isNew) {
      this.DoSave()
    } else {
      this.DoUpdate()
    }
  }

  DoUpdate() {
    this.medicalProviderService.updatePersonelHazard(this.currentHazard).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.dialogRef.close(this.currentHazard.ID);
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoSave() {
    this.medicalProviderService.createNewPersonelHazard(this.currentHazard).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentHazard = new PersonelHazards(data)
        this.dialogRef.close(this.currentHazard.ID);
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }
}
