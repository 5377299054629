import { User } from 'firebase'
import { Attachment } from './attachments'
import { propertyMap } from './modelmapper'



export class OccupationalTherapyFollowUp{
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID') //
    public PersonelID: string
    @propertyMap('RichText') //
    public RichText: string
    @propertyMap('Attachments') 
    Attachments: Attachment[]
    @propertyMap('Status') 
    Status: string
    @propertyMap('LastUpdateById')
    public LastUpdateById: string
    public LastUpdateBy: User
    @propertyMap('NextTrackDate')
    public NextTrackDate: Date
    @propertyMap('OccupationalbservationFiles')
    public OccupationalbservationFiles: Attachment[]

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.RichText = data["RichText"]
            this.Status = data["Status"]
            this.LastUpdateById = data["LastUpdateById"]
            this.LastUpdateBy = data["LastUpdateBy"]
            this.NextTrackDate = data["NextTrackDate"]
            this.OccupationalbservationFiles = data["OccupationalbservationFiles"]

        }
        else {
            this.Status = 'A'
        }
    }
}

// Need Server to send Payload in below structure
export class OccupationalTherapyAssessment {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID') //
    public PersonelID: string
    @propertyMap('ClosedById')
    public ClosedById: string
    @propertyMap('MotoriOpt1AR')
    public MotoriOpt1AR: string
    @propertyMap('MotoriOpt1AL')
    public MotoriOpt1AL: string
    @propertyMap('MotoriOpt1PR')
    public MotoriOpt1PR: string
    @propertyMap('MotoriOpt1PL')
    public MotoriOpt1PL: string
    @propertyMap('MotoriOpt2AR')
    public MotoriOpt2AR: string
    @propertyMap('MotoriOpt2AL')
    public MotoriOpt2AL: string
    @propertyMap('MotoriOpt2PR')
    public MotoriOpt2PR: string
    @propertyMap('MotoriOpt2PL')
    public MotoriOpt2PL: string
    @propertyMap('MotoriOpt3AR')
    public MotoriOpt3AR: string
    @propertyMap('MotoriOpt3AL')
    public MotoriOpt3AL: string
    @propertyMap('MotoriOpt3PR')
    public MotoriOpt3PR: string
    @propertyMap('MotoriOpt3PL')
    public MotoriOpt3PL: string
    @propertyMap('MotoriOpt4AR')
    public MotoriOpt4AR: string
    @propertyMap('MotoriOpt4AL')
    public MotoriOpt4AL: string
    @propertyMap('MotoriOpt4PR')
    public MotoriOpt4PR: string
    @propertyMap('MotoriOpt4PL')
    public MotoriOpt4PL: string
    @propertyMap('MotoriOpt5AR')
    public MotoriOpt5AR: string
    @propertyMap('MotoriOpt5AL')
    public MotoriOpt5AL: string
    @propertyMap('MotoriOpt5PR')
    public MotoriOpt5PR: string
    @propertyMap('MotoriOpt5PL')
    public MotoriOpt5PL: string
    @propertyMap('MotoriOpt6AR')
    public MotoriOpt6AR: string
    @propertyMap('MotoriOpt6AL')
    public MotoriOpt6AL: string
    @propertyMap('MotoriOpt6PR')
    public MotoriOpt6PR: string
    @propertyMap('MotoriOpt6PL')
    public MotoriOpt6PL: string

    @propertyMap('MotoriReason1')
    public MotoriReason1: string
    @propertyMap('MotoriReason2')
    public MotoriReason2: string
    @propertyMap('MotoriReason3')
    public MotoriReason3: string
    @propertyMap('MotoriReason4')
    public MotoriReason4: string
    @propertyMap('MotoriReason5')
    public MotoriReason5: string
    @propertyMap('MotoriReason6')
    public MotoriReason6: string
    @propertyMap('MotoriReason7')
    public MotoriReason7: string


    @propertyMap('IADLOpt1')
    public IADLOpt1: string
    @propertyMap('IADLOpt2')
    public IADLOpt2: string
    @propertyMap('IADLOpt3')
    public IADLOpt3: string
    @propertyMap('IADLOpt4')
    public IADLOpt4: string
    @propertyMap('IADLOpt5')
    public IADLOpt5: string

    
    @propertyMap('IADLReason1')
    public IADLReason1: string
    @propertyMap('IADLReason2')
    public IADLReason2: string
    @propertyMap('IADLReason3')
    public IADLReason3: string
    @propertyMap('IADLReason4')
    public IADLReason4: string
    @propertyMap('IADLReason5')
    public IADLReason5: string


    @propertyMap('BADLOpt1')
    public BADLOpt1: string
    @propertyMap('BADLOpt2')
    public BADLOpt2: string
    @propertyMap('BADLOpt3')
    public BADLOpt3: string
    @propertyMap('BADLOpt4')
    public BADLOpt4: string
    @propertyMap('BADLOpt5')
    public BADLOpt5: string
    @propertyMap('BADLOpt6')
    public BADLOpt6: string
    @propertyMap('BADLOpt7')
    public BADLOpt7: string
    @propertyMap('BADLOpt8')
    public BADLOpt8: string
    @propertyMap('BADLOpt9')
    public BADLOpt9: string

    @propertyMap('BADLReason1')
    public BADLReason1: string
    @propertyMap('BADLReason2')
    public BADLReason2: string
    @propertyMap('BADLReason3')
    public BADLReason3: string
    @propertyMap('BADLReason4')
    public BADLReason4: string
    @propertyMap('BADLReason5')
    public BADLReason5: string
    @propertyMap('BADLReason6')
    public BADLReason6: string
    @propertyMap('BADLReason7')
    public BADLReason7: string
    @propertyMap('BADLReason8')
    public BADLReason8: string
    @propertyMap('BADLReason9')
    public BADLReason9: string


    @propertyMap('PlacingOpt1')
    public PlacingOpt1: string
    @propertyMap('PlacingOpt2')
    public PlacingOpt2: string
    @propertyMap('PlacingOpt3')
    public PlacingOpt3: string

    @propertyMap('PlacingOpt1Comment')
    public PlacingOpt1Comment: string
    @propertyMap('PlacingOpt2Comment')
    public PlacingOpt2Comment: string
    @propertyMap('PlacingOpt3Comment')
    public PlacingOpt3Comment: string

    
    @propertyMap('CommunOpt1')
    public CommunOpt1: string
    @propertyMap('CommunOpt2')
    public CommunOpt2: string
    @propertyMap('CommunOpt3')
    public CommunOpt3: string
    @propertyMap('CommunOpt4')
    public CommunOpt4: string
    @propertyMap('CommunOpt5')
    public CommunOpt5: string
    @propertyMap('CommunReason1')
    public CommunReason1: string
    @propertyMap('CommunReason2')
    public CommunReason2: string
    @propertyMap('CommunReason3')
    public CommunReason3: string
    @propertyMap('CommunReason4')
    public CommunReason4: string
    @propertyMap('CommunReason5')
    public CommunReason5: string
    
    @propertyMap('TreatmentFreq')
    public TreatmentFreq: string
    @propertyMap('Explanations')
    public Explanations: string

    @propertyMap('MentalOpt1')
    public MentalOpt1: string
    @propertyMap('MentalOpt2')
    public MentalOpt2: string
    @propertyMap('MentalOpt3')
    public MentalOpt3: string
    @propertyMap('MentalOpt4')
    public MentalOpt4: string
    @propertyMap('MentalOpt5')
    public MentalOpt5: string
    @propertyMap('MentalReason1')
    public MentalReason1: string
    @propertyMap('MentalReason2')
    public MentalReason2: string
    @propertyMap('MentalReason3')
    public MentalReason3: string
    @propertyMap('MentalReason4')
    public MentalReason4: string
    @propertyMap('MentalReason5')
    public MentalReason5: string
    

    @propertyMap('MemoryOpt1')
    public MemoryOpt1: string
    @propertyMap('MemoryOpt2')
    public MemoryOpt2: string
    @propertyMap('MemoryOpt3')
    public MemoryOpt3: string
    @propertyMap('MemoryReason1')
    public MemoryReason1: string
    @propertyMap('MemoryReason2')
    public MemoryReason2: string
    @propertyMap('MemoryReason3')
    public MemoryReason3: string


    @propertyMap('PerceptionOpt1')
    public PerceptionOpt1: string
    @propertyMap('PerceptionOpt2')
    public PerceptionOpt2: string
    @propertyMap('PerceptionOpt3')
    public PerceptionOpt3: string
    @propertyMap('PerceptionOpt4')
    public PerceptionOpt4: string
    @propertyMap('PerceptionReason1')
    public PerceptionReason1: string
    @propertyMap('PerceptionReason2')
    public PerceptionReason2: string
    @propertyMap('PerceptionReason3')
    public PerceptionReason3: string
    @propertyMap('PerceptionReason4')
    public PerceptionReason4: string

    

    @propertyMap('PainRightOpt1')
    public PainRightOpt1: string
    @propertyMap('PainRightOpt2')
    public PainRightOpt2: string
    @propertyMap('PainRightOpt3')
    public PainRightOpt3: string

    @propertyMap('PainLeftOpt1')
    public PainLeftOpt1: string
    @propertyMap('PainLeftOpt2')
    public PainLeftOpt2: string
    @propertyMap('PainLeftOpt3')
    public PainLeftOpt3: string

    @propertyMap('PainReason1')
    public PainReason1: string
    @propertyMap('PainReason2')
    public PainReason2: string
    @propertyMap('PainReason3')
    public PainReason3: string
    @propertyMap('Diagnoses')
    public Diagnoses: string
    @propertyMap('Contraindications')
    public Contraindications: string
    @propertyMap('Vision')
    public Vision: string
    @propertyMap('Hearing')
    public Hearing: string
    @propertyMap('DominateHand')
    public DominateHand: string
    @propertyMap('FunctioningHand')
    public FunctioningHand: string
    @propertyMap('Mobility')
    public Mobility: string

    @propertyMap('IADL')
    public IADL: string
    @propertyMap('BADL')
    public BADL: string
    @propertyMap('AADL')
    public AADL: string
    @propertyMap('TreatmentPurpose')
    public TreatmentPurpose: string
    
    @propertyMap('RecommendedTreatment')
    public RecommendedTreatment: string
    @propertyMap('Accessories')
    public Accessories: string
    
    @propertyMap('CognitiveDiagnoses')
    public CognitiveDiagnoses: string
    @propertyMap('PraxicFunctions')
    public PraxicFunctions: string
    @propertyMap('PainSensation')
    public PainSensation: string
    @propertyMap('FormalDiagnoses')
    public FormalDiagnoses: string
    @propertyMap('AADLText')
    public AADLText: string

    @propertyMap('TonosRight')
    public TonosRight: string
    @propertyMap('TonosLeft')
    public TonosLeft: string
    @propertyMap('TonosComment')
    public TonosComment: string
    @propertyMap('PowerRight')
    public PowerRight: string
    @propertyMap('PowerLeft')
    public PowerLeft: string
    @propertyMap('PowerComment')
    public PowerComment: string
    @propertyMap('CoordinationLeft')
    public CoordinationLeft: string
    @propertyMap('CoordinationRight')
    public CoordinationRight: string
    @propertyMap('CoordinationComment')
    public CoordinationComment: string
    public ClosedBy: User
    @propertyMap('LastUpdateById')
    public LastUpdateById: string
    public LastUpdateBy: User
    @propertyMap('Status')
    Status: string
    @propertyMap('Closed_at')
    public Closed_at: Date
   
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.ClosedBy = data["ClosedBy"]
            this.ClosedById = data["ClosedById"]
            this.LastUpdateBy = data["LastUpdateBy"]
            this.LastUpdateById = data["LastUpdateById"]
            this.MotoriOpt1AR = data["MotoriOpt1AR"]
            this.MotoriOpt1AL = data["MotoriOpt1AL"]
            this.MotoriOpt1PR = data["MotoriOpt1PR"]
            this.MotoriOpt1PL = data["MotoriOpt1PL"]
            this.MotoriOpt2AR = data["MotoriOpt2AR"]
            this.MotoriOpt2AL = data["MotoriOpt2AL"]
            this.MotoriOpt2PR = data["MotoriOpt2PR"]
            this.MotoriOpt2PL = data["MotoriOpt2PL"]
            this.MotoriOpt3AR = data["MotoriOpt3AR"]
            this.MotoriOpt3AL = data["MotoriOpt3AL"]
            this.MotoriOpt3PR = data["MotoriOpt3PR"]
            this.MotoriOpt3PL = data["MotoriOpt3PL"]
            this.MotoriOpt4AR = data["MotoriOpt4AR"]
            this.MotoriOpt4AL = data["MotoriOpt4AL"]
            this.MotoriOpt4PR = data["MotoriOpt4PR"]
            this.MotoriOpt4PL = data["MotoriOpt43PL"]
            this.MotoriOpt5AR = data["MotoriOpt5AR"]
            this.MotoriOpt5AL = data["MotoriOpt5AL"]
            this.MotoriOpt5PR = data["MotoriOpt5PR"]
            this.MotoriOpt5PL = data["MotoriOpt5PL"]
            this.MotoriOpt6AR = data["MotoriOpt6AR"]
            this.MotoriOpt6AL = data["MotoriOpt6AL"]
            this.MotoriOpt6PR = data["MotoriOpt6PR"]
            this.MotoriOpt6PL = data["MotoriOpt6PL"]
            this.IADLOpt1 =  data["IADLOpt1"]
            this.IADLOpt2 =  data["IADLOpt2"]
            this.IADLOpt3 =  data["IADLOpt3"]
            this.IADLOpt4 =  data["IADLOpt4"]
            this.IADLOpt5 =  data["IADLOpt5"]
            this.BADLOpt1 =  data["BADLOpt1"]
            this.BADLOpt2 =  data["BADLOpt2"]
            this.BADLOpt3 =  data["BADLOpt3"]
            this.BADLOpt4 =  data["BADLOpt3"]
            this.BADLOpt5 =  data["BADLOpt5"]
            this.BADLOpt6 =  data["BADLOpt6"]
            this.BADLOpt7 =  data["BADLOpt7"]
            this.BADLOpt8 =  data["BADLOpt8"]
            this.BADLOpt9 =  data["BADLOpt9"]
            this.BADLReason1 =  data["BADLReason1"]
            this.BADLReason2 =  data["BADLReason2"]
            this.BADLReason3 =  data["BADLReason3"]
            this.BADLReason4 =  data["BADLReason4"]
            this.BADLReason5 =  data["BADLReason5"]
            this.BADLReason6 =  data["BADLReason6"]
            this.BADLReason7 =  data["BADLReason7"]
            this.BADLReason8 =  data["BADLReason8"]
            this.BADLReason9 =  data["BADLReason9"]
            this.IADLReason1 =  data["IADLReason1"]
            this.IADLReason2 =  data["IADLReason2"]
            this.IADLReason3 =  data["IADLReason3"]
            this.IADLReason4 =  data["IADLReason4"]
            this.IADLReason5 =  data["IADLReason5"]
            this.PlacingOpt1 = data["PlacingOpt1"]
            this.PlacingOpt2 = data["PlacingOpt2"]
            this.PlacingOpt3 = data["PlacingOpt3"]
            this.PlacingOpt1Comment = data["PlacingOpt1Comment"]
            this.PlacingOpt2Comment = data["PlacingOpt2Comment"]
            this.PlacingOpt3Comment = data["PlacingOpt3Comment"]
            this.CommunOpt1 = data["CommunOpt1"]
            this.CommunOpt2 = data["CommunOpt2"]
            this.CommunOpt3 = data["CommunOpt3"]
            this.CommunOpt4 = data["CommunOpt4"]
            this.CommunOpt5 = data["CommunOpt5"]
            this.CommunReason1 = data["CommunReason1"]
            this.CommunReason2 = data["CommunReason2"]
            this.CommunReason3 = data["CommunReason3"]
            this.CommunReason4 = data["CommunReason4"]
            this.CommunReason5 = data["CommunReason5"]
            this.MentalOpt1 = data["MentalOpt1"]
            this.MentalOpt2 = data["MentalOpt2"]
            this.MentalOpt3 = data["MentalOpt3"]
            this.MentalOpt4 = data["MentalOpt4"]
            this.MentalOpt5 = data["MentalOpt5"]
            this.MentalReason1 = data["MentalReason1"]
            this.MentalReason2 = data["MentalReason2"]
            this.MentalReason3 = data["MentalReason3"]
            this.MentalReason4 = data["MentalReason4"]
            this.MentalReason5 = data["MentalReason5"]
            this.MemoryOpt1 = data["MemoryOpt1"]
            this.MemoryOpt2 = data["MemoryOpt2"]
            this.MemoryOpt3 = data["MemoryOpt3"]
            this.MemoryReason1 = data["MemoryReason1"]
            this.MemoryReason2 = data["MemoryReason2"]
            this.MemoryReason3 = data["MemoryReason3"]
            this.PerceptionOpt1 = data["PerceptionOpt1"]
            this.PerceptionOpt2 = data["PerceptionOpt2"]
            this.PerceptionOpt3 = data["PerceptionOpt3"]
            this.PerceptionOpt4 = data["PerceptionOpt4"]
            this.PerceptionReason1 = data["PerceptionReason1"]
            this.PerceptionReason2 = data["PerceptionReason2"]
            this.PerceptionReason3 = data["PerceptionReason3"]
            this.PerceptionReason4 = data["PerceptionReason4"]
            this.PainRightOpt1 = data["PainRightOpt1"]
            this.PainRightOpt2 = data["PainRightOpt2"]
            this.PainRightOpt3 = data["PainRightOpt3"]
            this.PainLeftOpt1 = data["PainLeftOpt1"]
            this.PainLeftOpt2 = data["PainLeftOpt2"]
            this.PainLeftOpt3 = data["PainLeftOpt3"]
            this.PainReason1 = data["PainReason1"]
            this.PainReason2 = data["PainReason2"]
            this.PainReason3 = data["PainReason3"]
            this.Contraindications = data["Contraindications"]
            this.Diagnoses = data["Diagnoses"]
            this.Vision = data["Vision"]
            this.Hearing = data["Hearing"]
            this.Status = data["Status"]
            this.Closed_at = data["Closed_at"]
            this.DominateHand = data["DominateHand"]
            this.FunctioningHand = data["FunctioningHand"]
            this.Mobility = data["Mobility"]
            this.IADL = data["IADL"]
            this.BADL = data["BADL"]
            this.AADL = data["AADL"]
            this.TreatmentPurpose = data["TreatmentPurpose"]
            this.RecommendedTreatment = data["RecommendedTreatment"]
            this.TreatmentFreq = data["TreatmentFreq"]
            this.Explanations = data["Explanations"]
            this.Accessories = data["Accessories"]

            this.MotoriReason1 = data["MotoriReason1"]
            this.MotoriReason2 = data["MotoriReason2"]
            this.MotoriReason3 = data["MotoriReason3"]
            this.MotoriReason4 = data["MotoriReason4"]
            this.MotoriReason5 = data["MotoriReason5"]
            this.MotoriReason6 = data["MotoriReason6"]
            this.MotoriReason7 = data["MotoriReason7"]

            this.CognitiveDiagnoses = data["CognitiveDiagnoses"]
            this.PraxicFunctions = data["PraxicFunctions"]
            this.PainSensation = data["PainSensation"]
            this.FormalDiagnoses = data["FormalDiagnoses"]
            this.AADLText = data["AADLText"]

            this.TonosRight = data["TonosRight"]
            this.TonosLeft = data["TonosLeft"]
            this.TonosComment = data["TonosComment"]

            this.PowerRight = data["PowerRight"]
            this.PowerLeft = data["PowerLeft"]
            this.PowerComment = data["PowerComment"]

            this.CoordinationRight = data["CoordinationRight"]
            this.CoordinationLeft = data["CoordinationLeft"]
            this.CoordinationComment = data["CoordinationComment"]


            
        }
        else {
            this.CreatedAt = this.UpdatedAt = new Date()
            this.Status = "A"
        }
    }
}



