import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as _ from 'lodash';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { MedicalFile } from 'src/app/models/customer';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-archivedfiles-list',
  templateUrl: './archivedfiles-list.component.html',
  styleUrls: ['./archivedfiles-list.component.css']
})
export class ArchivedfilesListComponent implements OnInit {

  public displayedColumns = ['actions', 'personelID', 'firstname', 'lastname', 'email', 'phone'];
  dataSource: MedicalDataSource | null;
  //customerService: CustomerService | null;
  pageHeader: string;
  pageIcon: string;
  TabId = "medicalfiles"
  medicalFiles: MedicalFile[]
  medicalFilesOrigin: MedicalFile[];


  filteredData: MedicalFile[] = [];
  renderedData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];




  phone: string = '';
  index: number;
  id: number;


  constructor(
    private title: Title,
    private medicalFileService:MedicalFileProviderService,
    public httpClient: HttpClient,
    public dialog: MatDialog,
    private router: Router,
    private data: DataService,
    private  toastrService: ToasterService
  ) {


  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;

  ngOnInit(): void {

    this.title.setTitle('Caretex| ארכיון');
    this.data.isMedicalFileOpened = false
    this.pageHeader = 'Medical files ';
    this.pageIcon = 'fas fa-user';

    this.pageIcon = 'fas fa-user';
    this.loadData2();
   
  }




  refresh() {
    this.loadData();
  }

  public loadData() {
    this.dataSource = new MedicalDataSource(this.medicalFileService, this.paginator, this.sort);
    fromEvent(this.filter.nativeElement, 'keyup')
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }

  loadData2() {
    let filter = '(is_archived =\'Y\')'
    this.medicalFileService.getMedicalFiles("filter");
    this.medicalFileService.dataChange.subscribe((data) => {
      this.allFiles = data
    })

  }

  addNew() {
  }


  startEdit(medicalFile: MedicalFile) {
    this.medicalFileService.CurrentOpenedFile = medicalFile
    this.data.updateIsNotesShowed(false)
    this.router.navigateByUrl("/medicalfiles/" + medicalFile.PersonelID)
  }

  deleteItem(file: MedicalFile) {
    this.medicalFileService.CurrentOpenedFile = file
  }

  private refreshTable() {
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  addNewFile(){
    this.router.navigate(['newmeidcalfile']);
  }

}

export class MedicalDataSource extends DataSource<MedicalFile> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  filteredData: MedicalFile[] = [];
  renderedData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];

  constructor(
    private medicalFileService:MedicalFileProviderService,
    public _paginator: MatPaginator,
    public _sort: MatSort) {
    super();
    this._filterChange.subscribe(() => this._paginator.pageIndex = 0);
  }

  connect(): Observable<MedicalFile[]> {
    const displayDataChanges = [
      this.medicalFileService.dataChange,
      this._sort.sortChange,
      this._filterChange,
      this._paginator.page
    ];
    let filter = '(is_archived =\'Y\')'
    this.medicalFileService.getMedicalFiles(filter);

    return merge(displayDataChanges).pipe(map(() => {
      // Filter data
      this.allFiles = this.medicalFileService.data
      this.filteredData = this.medicalFileService.data.slice().filter((medicalFile: MedicalFile) => {

        const searchStr = (medicalFile.PersonelID + medicalFile.FirstName + medicalFile.LastName + medicalFile.Email + medicalFile.Phone).toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
      });

      // Sort filtered data
      const sortedData = this.sortData(this.filteredData.slice());

      // Grab the page's slice of the filtered sorted data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
      return this.renderedData;
    }
    ));
  }

  disconnect() { }

  /** Returns a sorted copy of the database data. */
  sortData(data: MedicalFile[]): MedicalFile[] {
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'PersonelID': [propertyA, propertyB] = [a.PersonelID, b.PersonelID]; break;
        case 'FirstName': [propertyA, propertyB] = [a.FirstName, b.FirstName]; break;
        case 'LastName': [propertyA, propertyB] = [a.LastName, b.LastName]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }

}
