import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationMap } from '../../../../src/app/models/notificationMap';
import { AuthService } from '../../../../src/app/services/auth.service';
import { DataService } from '../../../../src/app/services/data.service';
import { NotificationsSSEService } from "../../../../src/app/services/notifications-sse.service";
import { ToasterService } from '../../../../src/app/services/toastr.service';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})
export class LandingpageComponent implements OnInit {

  displayWelcominmgMsg: string = ""
  displayWelcominmgMsg2: string = "כניסה נרשמה בתאריך/שעה "
  loggedInUserName: string = ""
  notifications: NotificationMap[] = [];
  numberOfNotifications: number = 0
  loggedInAt: Date
  TabId:string = "main"

  actionItems: any = [
    { title: 'דוחות', itemsCount: '120', icon: 'landing-reports.svg', id: "reports" },
    { title: 'אינדיקס תרופות', itemsCount: '200', icon: 'landing-drugs.svg', id: "medications" },
    { title: 'מחלקות אשפוז', itemsCount: '100', icon: 'landing-healthcheck.svg', id: "files" },
    { title: 'סיעודי מרוכז', itemsCount: '20', icon: 'landing-cardix.svg', id: "nursinggeneral" },
    { title: 'ארכיון', itemsCount: '10', icon: 'landing-archives.svg', id: "archive" },
    { title: 'ניהול משתמשים', itemsCount: '234', icon: 'landing-meeting.svg', id: "usermangments" },
    { title: 'הגדרות מערכת', itemsCount: '18', icon: 'landing-settings.svg', id: "settings" },
    { title: 'Cardix', itemsCount: '20', icon: 'landing-cardix.svg', id: "cardix" },
    { title: 'מעקבים מתוכננים', itemsCount: '20', icon: 'landing-cardix.svg', id: "followups" },
    { title: 'בקרת תרופות עבור מטופלים', itemsCount: '20', icon: 'landing-cardix.svg', id: "medicationreviews" },
    { title: 'מרשמים רפואיים', itemsCount: '20', icon: 'landing-cardix.svg', id: "medicalprescriptions" },
    { title: 'מעקב הוראות תרופות לצוות סיעוד', itemsCount: '20', icon: 'landing-cardix.svg', id: "nursemedicationreviews" },
    { title: 'אישפוזים של דיירים מחוץ לבית', itemsCount: '20', icon: 'landing-cardix.svg', id: "hospitalizations" },
  ];



  /* todayTasks: any = [
     { title: 'מתן תרופות בוקר', isCompleted: false, dept: 'סיעודית א', color: 'green' },
     { title: 'לקיחת דמים', isCompleted: false, dept: 'סיעודית ב', color: 'red' },
 
   ];
 
   patientsData: any = [
     { name: 'גבראן עבד אלפתאח', gender: 'מחובר כעת', profession: 'מנהל מערכת', visitedAt: 'Nov-14-2022, 3:00 pm' },
 
   ];*/

  constructor(
    public router: Router,
    public dataservice: DataService,
    public httpClient: HttpClient,
    public notificationsService: NotificationsSSEService,
    private toastrService: ToasterService,
    private _title: Title,
    private authServive: AuthService,
  ) { }

  ngOnInit(): void {

    let now = new Date();
    this._title.setTitle('Caretex | דף בית');
    let isMorning = now.getHours() > 5 && now.getHours() <= 11;
    let isAfternoon = now.getHours() > 11 && now.getHours() <= 18;
    let isEvening = now.getHours() > 18 && now.getHours() <= 22;
    let isNight = now.getHours() > 22 || now.getHours() <= 5;

    if (isMorning) this.displayWelcominmgMsg = "בוקר טוב"
    if (isAfternoon) this.displayWelcominmgMsg = "אחר צהריים טובים"
    if (isEvening) this.displayWelcominmgMsg = "ערב טוב"
    if (isNight) this.displayWelcominmgMsg = "לילה טוב"

    this.loggedInUserName = this.authServive.LoggedInUser.displayName
    this.loggedInAt = JSON.parse(localStorage.getItem('LoggedInAt'));
    this.loadData()

  }

  onClick(id) {
    switch (id) {
      case "reports":
        this.router.navigate(['reports']);
        break
      case "medications":
        this.router.navigate(['drugslist']);
        break
      case "files":
        this.router.navigate(['medfiles']);
        break
      case "archive":
        this.router.navigate(['archivedfiles']);
        break
      case "usermangments":
        this.router.navigate(['users_management']);
        break
      case "settings":
        this.router.navigate(['system_setups']);
        break
      case "cardix":
        this.router.navigate(['cardix']);
        break
      case "followups":
        this.router.navigate(['followups']);
        break
      case "medicationreviews":
        this.router.navigate(['medicationreviews']);
        break
      case "medicalprescriptions":
        this.router.navigate(['medicalprescriptions']);
        break
      case "nursemedicationreviews":
        this.router.navigate(['nursemedicationreviews']);
        break

      case "hospitalizations":
        this.router.navigate(['hospitalizations']);
        break

        case "nursinggeneral":
          this.router.navigate(['nursinggeneral']);
          break


    }
  }




  loadData() {
    let filter = "is_opened = 'N'"
    this.notificationsService.getNotifications(this.authServive.LoggedInUser['UID'], this.authServive.LoggedInUser['role'], filter).subscribe((data: NotificationMap[]) => {
      this.notifications = []
      data.forEach(element => {
        this.notifications.unshift(new NotificationMap(element))
      });

      this.numberOfNotifications = this.notifications.length

    }, error => {
      console.log(error);
      this.toastrService.Error("", error)
    });
  }


  openMEdicalFilesList() {
    this.router.navigate(['medfiles']);
  }


  removeNotification($event) {
    let removedNotification: NotificationMap = $event
    removedNotification.IsOpened = true
    removedNotification.IsSeen = true
    removedNotification.TimeClosed = new Date()

    this.notificationsService.updateNotification(removedNotification).subscribe({
      next: data => {
        this.loadData()

      },
      error: error => {
        this.toastrService.Error("", error.error.error)
      }
    })

  }


}
