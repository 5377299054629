import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { Exceptionevent, ExceptionEventWitness } from '../../../../../src/app/models/exceptionEvent';
import { ConsciousnessState, Departments, EventBeds, EventLocation, EventType, Hospital, NursingStatus, WorkingShift } from '../../../../../src/app/models/generalhousedsetups';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { DialogResult, ExceptionEventSteps } from '../../../../../src/app/services/enums';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { CaretxNursingProviderService } from '../../../caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-exceptionalevent',
  templateUrl: './exceptionalevent.component.html',
  styleUrls: ['./exceptionalevent.component.css']
})
export class ExceptionaleventComponent extends CaretexComponent implements OnInit {

  @ViewChild('eventdatevalidator') evetDateValidator: ElementRef;
  @ViewChild('reportedtodoctoratvalidator') reportedToDoctorAtValidator: ElementRef;
  @ViewChild('reportedtonurseatvalidator') reportedToNurseAtValidator: ElementRef;
  @ViewChild('reportedtofamilyatvalidator') reportedToFamilyAtValidator: ElementRef;
  @ViewChild('nursecheckdatevalidator') nurseCheckDateValidator: ElementRef;
  @ViewChild('doctorcheckdatevalidator') doctorCheckDateValidator: ElementRef;
  @ViewChild('nursesignuter') nurseSignuture: ElementRef;
  @ViewChild('doctorsignuter') doctorSignuture: ElementRef;
  @ViewChild('doctorsmangerignuter') doctorsMangerignuter: ElementRef;

  @ViewChild('housemangerignuter') houseMangerignuter: ElementRef;
  @ViewChild('nusringmangerignuter') nusringMangerignuter: ElementRef;


  modalTarget: string
  id: string
  iseventClosed: boolean
  currentExceptionEvent: Exceptionevent
  departments: Departments[] = []
  hospitals: Hospital[] = []
  eventsTypes: EventType[] = []
  workShifts: WorkingShift[] = []
  nursingStatuses :NursingStatus[] = []

  eventLocations :EventLocation[] = []
  eventBeds :EventBeds[] = []
  consciousnessStates :ConsciousnessState[] = []


  CurrentStep: ExceptionEventSteps = ExceptionEventSteps.InitialDetails
  witnessRowIndex: number
  nurseSignTxt: string
  doctorSignTxt: string
  doctorsManagerSignTxt: string
  nursingManagerSignTxt: string
  houseManagerSignTxt: string
  alertTitle: string
  deleteAlert: string
  deleteSysMsgId: string
  title: string
  alertId: string
  alertBody: string
  OkBtn: boolean
  NoBtn: boolean
  CancelBtn: boolean
  YesBtn: boolean
  associatedTo: string
  moduleName:string = "אירוע חריג"
  @Output() witnessDeletionEvent = new EventEmitter<number>();
  BaseUrl = ""
  eventEditable: boolean = true
  zoomInModalData: any;
  dismissModal:boolean = false


  constructor(
    private caretxSetupService: CaretxSetupsProviderService,
    public medicalFileService: MedicalFileProviderService,
    private authService: AuthService,
    private router: Router,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,) {super(medicalFileService)
    this.caretxSetupService.GetHouseDepartments().subscribe((data => {
      this.departments = data
    }))

    this.caretxSetupService.GetHospitals(this.authService.LoggedInTenantID).subscribe((data => {
      this.hospitals = data
    }))

    this.caretxSetupService.GetWorkShifts().subscribe((data => {
      this.workShifts = data
    }))

    this.caretxSetupService.GetEventTypes().subscribe((data => {
      this.eventsTypes = data
    }))

    this.caretxSetupService.GetNursingStatuses().subscribe((data => {
      this.nursingStatuses = data
    }))

    this.caretxSetupService.GetEventLocations().subscribe((data => {
      this.eventLocations = data
    }))

    this.caretxSetupService.GetBedsTypes().subscribe((data => {
      this.eventBeds = data
    }))

    this.caretxSetupService.GetConsciousnessState().subscribe((data => {
      this.consciousnessStates = data
    }))

  }


  ngOnInit(): void {
    if (this.route.parent != undefined) {
    this.id = this.route.parent.snapshot.params['id'];
    this.BaseUrl = this.route.parent.snapshot.url[2].path
    }
    this.title = "התראת מערכת"
    this.alertId = "systemAlertModal"
    this.modalTarget = "#systemAlertModal"
    this.CheckIfComponentIsDisabled(this.id)
    if (this.route.snapshot.params.eventid == 'new') {
      this.currentExceptionEvent = new Exceptionevent()
      let dateOfNow: Date = new Date();
      this.currentExceptionEvent.EventDate = new Date()
      this.currentExceptionEvent.EventStatus = "A"
      this.currentExceptionEvent.PersonelID = this.id
      this.currentExceptionEvent.RealmID = this.authService.LoggedInTenantID
      this.currentExceptionEvent.EventReportedBy = this.authService.LoggedInUser.displayName
      this.currentExceptionEvent.Witnesses = []
    }
    else {
      this.getEventById(this.route.snapshot.params.eventid)
    }
  }

  selectTab(tabId: string) {
    this.CurrentStep = (tabId as ExceptionEventSteps)
  }

  getEventById(eventId: string) {
    let filter = '(id=' + '\'' + eventId + '\'' + ')'
    this.caretxNursingProviderService.getExceptionalEventsById(filter, this.id).subscribe((data: Exceptionevent[]) => {
      if (data.length == 1) {
        this.currentExceptionEvent = data[0]
        this.iseventClosed = (this.currentExceptionEvent.EventStatus == "C")
        this.buildNurseSignText()
        this.buildDoctorSignText()

        this.buildDoctorsManagerSignText()
        this.buildHouseManagerSignText()
        this.buildNursingManagerSignText()

      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }

  deleteWitnessRow(i) {
    this.witnessRowIndex = i
    if (!this.currentExceptionEvent.Witnesses[i].isNew) {
      this.alertId = "systemAlertModal"
      this.OkBtn = false
      this.NoBtn = true
      this.CancelBtn = false
      this.YesBtn = true
      this.associatedTo = "witnessdeletion"
      this.alertBody = "האם אתה בטוח שאתה רוצה למחוק רשימה זו?"
    }
    else {
      this.currentExceptionEvent.Witnesses.splice(this.witnessRowIndex, 1);
      this.alertId = ""
    }
  }

  editWitnessRow(i) {
    this.currentExceptionEvent.Witnesses[i].editMode = true;
  }

  cancelWitnessEditRow(i) {
    this.currentExceptionEvent.Witnesses[i].editMode = false;
  }

  addWitness() {
    var newWitness = new ExceptionEventWitness()
    newWitness.editMode = true
    newWitness.isNew = true
    newWitness.CreatedAt = new Date()
    newWitness.UpdatedAt = new Date()
    newWitness.Name = ''
    newWitness.Phone = ''
    newWitness.Address = ''
    newWitness.RealmID = this.authService.LoggedInTenantID
    this.currentExceptionEvent.Witnesses.unshift(newWitness)
  }

  handleDeleteAlerMsg($event) {
    let dialogResult: DialogResult = $event
    if (dialogResult == DialogResult.OK) {

      this.witnessDeletionEvent.emit(this.witnessRowIndex)


    }
  }

  SaveExceptionalEvent() {
    this.currentExceptionEvent.EventDate = new Date(this.currentExceptionEvent.EventDate)
    this.currentExceptionEvent.ReportedToDoctorAt = new Date(this.currentExceptionEvent.ReportedToDoctorAt)
    this.currentExceptionEvent.ReportedToNurseAt = new Date(this.currentExceptionEvent.ReportedToNurseAt)
    this.currentExceptionEvent.ReportedToFamilyAt = new Date(this.currentExceptionEvent.ReportedToDoctorAt)
    this.currentExceptionEvent.NurseCheckDate = new Date(this.currentExceptionEvent.NurseCheckDate)
    this.currentExceptionEvent.NurseCheckTime = new Date(this.currentExceptionEvent.NurseCheckTime)
    this.currentExceptionEvent.DoctorCheckDate = new Date(this.currentExceptionEvent.DoctorCheckDate)
    this.currentExceptionEvent.DoctorCheckTime = new Date(this.currentExceptionEvent.DoctorCheckTime)
    this.currentExceptionEvent.DoctorCheckSignOfDate = new Date(this.currentExceptionEvent.DoctorCheckSignOfDate)
    this.currentExceptionEvent.NurseCheckSignOfDate = new Date(this.currentExceptionEvent.NurseCheckSignOfDate)

    // this.currentExceptionEvent.Witnesses = this.witnesses

    if (this.currentExceptionEvent.ID == "" || this.currentExceptionEvent.ID == null) {
      this.DoSave()
    }
    else {
      this.DoUpdate()

    }

  }

  DoSave() {
    this.currentExceptionEvent.PersonelID = this.id

    this.caretxNursingProviderService.CreateExceptionalEvent(this.id, this.currentExceptionEvent).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentExceptionEvent = data as Exceptionevent
        if (this.currentExceptionEvent.EventStatus == 'C')
          this.iseventClosed = true
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    this.caretxNursingProviderService.UpdateExceptionalEvent(this.id, this.currentExceptionEvent).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentExceptionEvent = data as Exceptionevent
        if (this.currentExceptionEvent.EventStatus == 'C')
          this.iseventClosed = true

      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  buildNurseSignText() {
    if (this.currentExceptionEvent.IsSignedByNurse) {
      let today = new Date(this.currentExceptionEvent.NurseCheckSignOfDate)
      let nurseName = this.currentExceptionEvent.CheckedByNurse.displayName
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var Hour = today.getHours().toString()
      var Min = today.getMinutes().toString()

      var todayAsString = mm + '/' + dd + '/' + yyyy + " "
      var time = Hour + ":" + Min;

      this.nurseSignTxt = "נחתם עי " + nurseName + " בתאריך " + todayAsString + " " + " בשעה " + time
    }
  }

  buildDoctorSignText() {
    if (this.currentExceptionEvent.IsSignedByDoctor) {
      let today = new Date(this.currentExceptionEvent.DoctorCheckSignOfDate)
      let docName = this.currentExceptionEvent.CheckedByDoctor.displayName
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var Hour = today.getHours().toString()
      var Min = today.getMinutes().toString()

      var todayAsString = mm + '/' + dd + '/' + yyyy + " "
      var time = Hour + ":" + Min;

      this.doctorSignTxt = "נחתם עי " + docName + " בתאריך " + todayAsString + " " + " בשעה " + time
    }
  }

  buildDoctorsManagerSignText() {
    if (this.currentExceptionEvent.IsSignedByMedicalManager) {
      let today = new Date(this.currentExceptionEvent.MedicalManagerSignOfDate)
      let docName = this.currentExceptionEvent.SignedByMedicalManager.displayName
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var Hour = today.getHours().toString()
      var Min = today.getMinutes().toString()

      var todayAsString = mm + '/' + dd + '/' + yyyy + " "
      var time = Hour + ":" + Min;

      this.doctorsManagerSignTxt = "נחתם עי " + docName + " בתאריך " + todayAsString + " " + " בשעה " + time
    }
  }

  buildHouseManagerSignText() {
    if (this.currentExceptionEvent.IsSignedByHouseManager) {
      let today = new Date(this.currentExceptionEvent.HouseManagerSignOfDate)
      let docName = this.currentExceptionEvent.SignedByHouseManager.displayName
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var Hour = today.getHours().toString()
      var Min = today.getMinutes().toString()

      var todayAsString = mm + '/' + dd + '/' + yyyy + " "
      var time = Hour + ":" + Min;

      this.houseManagerSignTxt = "נחתם עי " + docName + " בתאריך " + todayAsString + " " + " בשעה " + time
    }
  }

  buildNursingManagerSignText() {
    if (this.currentExceptionEvent.IsSignedByNursingManager) {
      let today = new Date(this.currentExceptionEvent.NursingManagerSignOfDate)
      let docName = this.currentExceptionEvent.SignedByNursingManager.displayName
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var Hour = today.getHours().toString()
      var Min = today.getMinutes().toString()

      var todayAsString = mm + '/' + dd + '/' + yyyy + " "
      var time = Hour + ":" + Min;

      this.nursingManagerSignTxt = "נחתם עי " + docName + " בתאריך " + todayAsString + " " + " בשעה " + time
    }
  }

  checkDateNotMorethanAweekAgo(propertyName, $event) {
    var diff = new Date().getTime() - new Date($event).getTime();
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    let showMsg: boolean = false


    this.OkBtn = true
    this.NoBtn = false
    this.CancelBtn = false
    this.YesBtn = false
    this.associatedTo = "datevalidation"

    switch (propertyName) {
      case "EventDate":
        this.currentExceptionEvent.EventDate = $event
        break
      case "ReportedToDoctorAt":
        this.currentExceptionEvent.ReportedToDoctorAt = $event
        break
      case "ReportedToNurseAt":
        this.currentExceptionEvent.ReportedToNurseAt = $event
        break
      case "ReportedToFamilyAt":
        this.currentExceptionEvent.ReportedToFamilyAt = $event
        break
      case "NurseCheckTime":
        this.currentExceptionEvent.NurseCheckTime = $event
        break
      case "DoctorCheckTime":
        this.currentExceptionEvent.DoctorCheckTime = $event
        break

      default:
        break

    }


    if (diffDays > 7) {
      this.alertBody = "ערך לא חוקי, תאריך  יכול להיות מקסימום 7 ימים אחורה מעכשיו"
      showMsg = true;
    }
    if (diffDays < 0 || (new Date() < new Date($event))) {
      this.alertBody = "ערך לא חוקי, תאריך לא יכול להיות בעתיד"
      showMsg = true;
    }

    if (showMsg) {
      switch (propertyName) {
        case "EventDate":
          this.showUserMessage(this.alertBody)
          break
        case "ReportedToDoctorAt":
          this.currentExceptionEvent.ReportedToDoctorAt = new Date()
          this.reportedToDoctorAtValidator.nativeElement.click()
          break
        case "ReportedToNurseAt":
          this.currentExceptionEvent.ReportedToNurseAt = new Date()
          this.reportedToNurseAtValidator.nativeElement.click()
          break
        case "ReportedToFamilyAt":
          this.currentExceptionEvent.ReportedToFamilyAt = new Date()
          this.reportedToFamilyAtValidator.nativeElement.click()
          break
        case "NurseCheckTime":
          this.currentExceptionEvent.NurseCheckTime = new Date()
          this.nurseCheckDateValidator.nativeElement.click()
          break
        case "DoctorCheckTime":
          this.currentExceptionEvent.DoctorCheckTime = new Date()
          this.doctorCheckDateValidator.nativeElement.click()
          break

        default:
          break

      }
    }



  }

  showAlertMsg() {
    this.title = "התראת מערכת"
    this.alertId = "systemAlertModal"
    this.modalTarget = "#systemAlertModal"
  }

  showUserMessage(msg:string){
    Swal.fire({
      title: "הודעת מערכת",
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'סגור',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentExceptionEvent.EventDate = new Date()
      }
    })
  }
  


  handleAlert($event) {

    let dialogResult: DialogResult = $event
    if (dialogResult == DialogResult.OK) {

      if (this.associatedTo == "witnessdeletion") {
        this.caretxNursingProviderService.deleteExceptionalEventsWitness(this.id, this.currentExceptionEvent.Witnesses[this.witnessRowIndex]).subscribe({
          next: data => {
            this.currentExceptionEvent.Witnesses.splice(this.witnessRowIndex, 1);
            this.toastrService.Sucesss("", this.toastrService.successMsg)
          },
          error: error => {
            this.toastrService.Error("", error)
          }
        })
      }

      if (this.associatedTo == "nursesignning") {
        this.DoActualNurseSign()
        this.SaveExceptionalEvent()
      }

      if (this.associatedTo == "doctorsignning") {
        this.DoActualDoctorSign()
        this.SaveExceptionalEvent()
      }
      if (this.associatedTo == "doctorsmanagersignning") {
        this.DoActualDoctorsManagerSign()
        this.SaveExceptionalEvent()
      }

      if (this.associatedTo == "housemanagersignning") {
        this.DoActualHouseManagersign()
        this.SaveExceptionalEvent()
      }

      if (this.associatedTo == "nursingmanagersignning") {
        this.DoActualNursingManagersign()
        this.SaveExceptionalEvent()
      }

      if (this.associatedTo == "lockEvent") {
        this.FinishTheExceptionEvent()
      }
    }

  }

  NurseSigncheckCompletion() {
    this.OkBtn = false
    this.NoBtn = true
    this.CancelBtn = false
    this.YesBtn = true
    this.alertBody = "אחרי שאת/ה חותם דף זה לא תוכל אחר כך לשנותו . בטוח להמשיך?"
    this.associatedTo = "nursesignning"
    this.nurseSignuture.nativeElement.click()
  }

  DoctorSigncheckCompletion() {
    this.OkBtn = false
    this.NoBtn = true
    this.CancelBtn = false
    this.YesBtn = true
    this.alertBody = "אחרי שאת/ה חותם דף זה לא תוכל אחר כך לשנותו . בטוח להמשיך?"
    this.associatedTo = "doctorsignning"
    this.doctorSignuture.nativeElement.click()
  }

  DoctorManagerSigncheckCompletion() {
    this.OkBtn = false
    this.NoBtn = true
    this.CancelBtn = false
    this.YesBtn = true
    this.alertBody = "אחרי שאת/ה חותם דף זה לא תוכל אחר כך לשנותו . בטוח להמשיך?"
    this.associatedTo = "doctorsmanagersignning"
    this.doctorsMangerignuter.nativeElement.click()
  }

  HouseManagerSigncheckCompletion() {
    this.OkBtn = false
    this.NoBtn = true
    this.CancelBtn = false
    this.YesBtn = true
    this.alertBody = "אחרי שאת/ה חותם דף זה לא תוכל אחר כך לשנותו . בטוח להמשיך?"
    this.associatedTo = "housemanagersignning"
    this.houseMangerignuter.nativeElement.click()
  }

  NursingManagerSigncheckCompletion() {
    this.OkBtn = false
    this.NoBtn = true
    this.CancelBtn = false
    this.YesBtn = true
    this.alertBody = "אחרי שאת/ה חותם דף זה לא תוכל אחר כך לשנותו . בטוח להמשיך?"
    this.associatedTo = "nursingmanagersignning"
    this.nusringMangerignuter.nativeElement.click()
  }

  DoActualNurseSign() {
    var today = new Date();
    this.currentExceptionEvent.CheckedByNurseId = this.authService.LoggedInUser.UID
    this.currentExceptionEvent.NurseCheckSignOfDate = today
    this.currentExceptionEvent.IsSignedByNurse = true
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var Hour = today.getHours().toString()
    var Min = today.getMinutes().toString()

    var todayAsString = mm + '/' + dd + '/' + yyyy + " "
    var time = Hour + ":" + Min;

    this.nurseSignTxt = "נחתם עי " + this.authService.LoggedInUser.displayName + " בתאריך " + todayAsString + " " + " בשעה " + time
  }
  
  DoActualDoctorSign() {
    var today = new Date();
    this.currentExceptionEvent.CheckedByDoctorId = this.authService.LoggedInUser.UID
    this.currentExceptionEvent.DoctorCheckSignOfDate = today
    this.currentExceptionEvent.IsSignedByDoctor = true
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var Hour = today.getHours().toString()
    var Min = today.getMinutes().toString()

    var todayAsString = mm + '/' + dd + '/' + yyyy + " "
    var time = Hour + ":" + Min;

    this.doctorSignTxt = "נחתם עי " + this.authService.LoggedInUser.displayName + " בתאריך " + todayAsString + " " + " בשעה " + time
  }

  DoActualDoctorsManagerSign() {
    var today = new Date();
    this.currentExceptionEvent.SignedByMedicalManagerId = this.authService.LoggedInUser.UID
    this.currentExceptionEvent.MedicalManagerSignOfDate = today
    this.currentExceptionEvent.IsSignedByMedicalManager = true
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var Hour = today.getHours().toString()
    var Min = today.getMinutes().toString()

    var todayAsString = mm + '/' + dd + '/' + yyyy + " "
    var time = Hour + ":" + Min;

    this.doctorsManagerSignTxt = "נחתם עי " + this.authService.LoggedInUser.displayName + " בתאריך " + todayAsString + " " + " בשעה " + time
  }

  DoActualHouseManagersign() {
    var today = new Date();
    this.currentExceptionEvent.SignedByHouseManagerId = this.authService.LoggedInUser.UID
    this.currentExceptionEvent.HouseManagerSignOfDate = today
    this.currentExceptionEvent.IsSignedByHouseManager = true
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var Hour = today.getHours().toString()
    var Min = today.getMinutes().toString()

    var todayAsString = mm + '/' + dd + '/' + yyyy + " "
    var time = Hour + ":" + Min;

    this.houseManagerSignTxt = "נחתם עי " + this.authService.LoggedInUser.displayName + " בתאריך " + todayAsString + " " + " בשעה " + time
  }

  DoActualNursingManagersign() {
    var today = new Date();
    this.currentExceptionEvent.SignedByNursingManagerId = this.authService.LoggedInUser.UID
    this.currentExceptionEvent.NursingManagerSignOfDate = today
    this.currentExceptionEvent.IsSignedByNursingManager = true
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var Hour = today.getHours().toString()
    var Min = today.getMinutes().toString()

    var todayAsString = mm + '/' + dd + '/' + yyyy + " "
    var time = Hour + ":" + Min;

    this.nursingManagerSignTxt = "נחתם עי " + this.authService.LoggedInUser.displayName + " בתאריך " + todayAsString + " " + " בשעה " + time
  }

  LockExceptionalEvent() {
    this.alertId = "systemAlertModal"
    this.OkBtn = false
    this.NoBtn = true
    this.CancelBtn = false
    this.YesBtn = true
    this.associatedTo = "lockEvent"
    this.alertBody = "האם אתה בטוח שאתה רוצה לנעול רשימה זו?"
  }

  FinishTheExceptionEvent() {
    this.currentExceptionEvent.LockedById = this.authService.LoggedInUser.UID
    this.currentExceptionEvent.LockedDate = new Date()
    this.currentExceptionEvent.EventStatus = "C"

    this.SaveExceptionalEvent()
  }

  BackToList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/"+this.BaseUrl+"/list")
  }


  zoomInField(title, fieldNgModel, preFilledData) {
    this.dismissModal = false
    this.zoomInModalData = {title, field: fieldNgModel, preFilled: (preFilledData || '')};
   
  }



  bindFieldData(responseData) {
    this.currentExceptionEvent[this.zoomInModalData.field] = responseData;

  }

  DismissCtxTextareaModal(){
      this.dismissModal = true
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  
  CheckIfDocumentIsDirty(){
    return false;
  }


  showFreeTxtLocationField() {
    this.currentExceptionEvent.IsFreeTxtLocation = true
  }

  showLupLocationField() {
    this.currentExceptionEvent.IsFreeTxtLocation = false
  }
  showLupBedField() {
    this.currentExceptionEvent.IsFreeTxtBedType = false
  }
  showFreeTxtBedField(){
    this.currentExceptionEvent.IsFreeTxtBedType = true
  }

  showLupConsStateField() {
    this.currentExceptionEvent.IsFreeTxtConsState = false
  }

  showFreeTxtConsStateField (){
    this.currentExceptionEvent.IsFreeTxtConsState = true
  }
}

