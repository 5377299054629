
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaretxNursingProviderService } from '../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { ModalService } from '../../../../../src/app/modal/modal.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalFile } from '../../../../../src/app/models/customer';
import { Departments } from '../../../../../src/app/models/generalhousedsetups';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { BehaviorSubject, Observable, fromEvent, merge } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { map } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';




@Component({
  selector: 'app-patients-dialog',
  templateUrl: './patients-dialog.component.html',
  styleUrls: ['./patients-dialog.component.css']
})
export class PatientsDialogComponent implements OnInit {
  filteredData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];
  totalNumOfRecords: number
  dropdownSettings1 = {};
  departments: Departments[] = []
  selectedItems1 = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;

  dataSource: MedicalDataSource | null;
  
  constructor(
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private caretxSetupService: CaretxSetupsProviderService,
    private medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private modalService: ModalService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<PatientsDialogComponent>,
    private caretxNursingProviderService: CaretxNursingProviderService, @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;


    this.caretxSetupService.GetUserAllowedDepartments(this.authService.LoggedInUser.UID).subscribe((data => {
      this.departments = data
      // Foreach on departments
      this.selectedItems1 = JSON.parse(localStorage.getItem('user_selected_departments') || '{}');

      if (this.selectedItems1.length == 0) {

        if (this.departments.length > 0) {
          this.departments.forEach(element => {
            this.selectedItems1.push({ "DepartmentName": element.DepartmentName, "ID": element.ID })
          });
        } else {
        }
      }

    }))


  }


  ngOnInit(): void {
    this.loadData2()
  }

  refresh() {
    this.loadData();
  }
  public loadData() {
    this.dataSource = new MedicalDataSource(this.medicalFileService, this.paginator, this.sort);
    fromEvent(this.filter.nativeElement, 'keyup')
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      });
  }

  

  // Get All Patient 
  loadData2() {
    let filter = '(is_archived =\'N\')'
    this.medicalFileService.getMedicalFiles(filter);
    this.medicalFileService.dataChange.subscribe((data) => {
      this.allFiles = data
    })
  }


  SearchPatient(value:string) {

  }

  submit(){

  }


selectRecord(record) {
  this.dialogRef.close(record);

}


private refreshTable() {
  this.paginator._changePageSize(this.paginator.pageSize);
}



}

export class MedicalDataSource extends DataSource<MedicalFile> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  filteredData: MedicalFile[] = [];
  renderedData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];

  constructor(
    private medicalFileService: MedicalFileProviderService,
    public _paginator: MatPaginator,
    public _sort: MatSort) {
    super();
    // Reset to the first page when the user changes the filter.
    this._filterChange.subscribe(() => this._paginator.pageIndex = 0);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<MedicalFile[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this.medicalFileService.dataChange,
      this._sort.sortChange,
      this._filterChange,
      this._paginator.page
    ];

    this.medicalFileService.getMedicalFiles("");


    return merge(displayDataChanges).pipe(map(() => {
      // Filter data
      this.allFiles = this.medicalFileService.data
      this.filteredData = this.medicalFileService.data.slice().filter((medicalFile: MedicalFile) => {

        const searchStr = (medicalFile.PersonelID + medicalFile.FirstName + medicalFile.LastName + medicalFile.Email + medicalFile.Phone).toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
      });

      // Sort filtered data
      const sortedData = this.sortData(this.filteredData.slice());

      // Grab the page's slice of the filtered sorted data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
      return this.renderedData;
    }
    ));
  }

  disconnect() { }


  /** Returns a sorted copy of the database data. */
  sortData(data: MedicalFile[]): MedicalFile[] {
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'PersonelID': [propertyA, propertyB] = [a.PersonelID, b.PersonelID]; break;
        case 'FirstName': [propertyA, propertyB] = [a.FirstName, b.FirstName]; break;
        case 'LastName': [propertyA, propertyB] = [a.LastName, b.LastName]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}

