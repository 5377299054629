import { Component, OnInit } from '@angular/core';
import { AccessComponenetType } from  '../../../../../src/app/services/enums';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
 




@Component({
  selector: 'app-druginstructions',
  templateUrl: './druginstructions.component.html',
  styleUrls: ['./druginstructions.component.css']
})
export class DruginstructionsComponent  extends CaretexComponent  implements OnInit  {

  Type:string = AccessComponenetType.Module
  isDrugInstDirty:boolean = false
  constructor(public medicalFileServive: MedicalFileProviderService,) {
    super(medicalFileServive)
  }

 
 

  ngOnInit(): void {
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty(){
    return this.isDrugInstDirty;
  }

  DrugInstrchanged($event) {
    this.isDrugInstDirty = $event 
  }

}
