import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxMedicalProviderService } from '../../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { Consultatory } from '../../../../../../src/app/models/expertconsulatant';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-expert-consultant-details',
  templateUrl: './expert-consultant-details.component.html',
  styleUrls: ['./expert-consultant-details.component.css']
})
export class ExpertConsultantDetailsComponent extends CaretexComponent implements OnInit {
  @ViewChild('mainform') mainFormElement: NgForm;
  moduleName: string = "יועצים / מומחים - דוח יועץ מומחה"
  isrecordClosed: boolean = false
  currentOpenedRecord: Consultatory
  step: number

  constructor(private caretxMedicalProviderService: CaretxMedicalProviderService,
    private _title: Title,
    private router: Router,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authService: AuthService,
    private route: ActivatedRoute,) { super(medicalFileService) }

  ngOnInit(): void {
    this._title.setTitle('Caretex | טופס תיאום טיפול');
    if(this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.id)

       if (this.route.snapshot.params.consultatoryid == 'new') {
      this.getConsultatoryFormById(this.id, this.route.snapshot.params.consultatoryid, "new")
    } else {
      this.getConsultatoryFormById(this.id, this.route.snapshot.params.consultatoryid, "")
    }
  }

  getConsultatoryFormById(id: string, recordId: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + recordId + '\'' + ')'
    this.caretxMedicalProviderService.getConsultatory(id, filter,mode).subscribe((data: Consultatory[]) => {
      if (data.length == 1) {
        this.currentOpenedRecord = new Consultatory(data[0])
        this.isrecordClosed = (this.currentOpenedRecord.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }


  Save() {

    let yy: number = new Date().getFullYear()
    let mm: number = new Date().getMonth()
    let dd: number = new Date().getDate()

    let Hours:number =  Number(this.currentOpenedRecord.LeavingTime.toString().split(':')[0])
    let Minutes:number =  Number(this.currentOpenedRecord.LeavingTime.toString().split(':')[1])
    let time : Date = new Date(yy, mm, dd, Hours, Minutes, 0)
    this.currentOpenedRecord.LeavingTime = time

    let Hours2:number =  Number(this.currentOpenedRecord.ReturnTime.toString().split(':')[0])
    let Minutes2:number =  Number(this.currentOpenedRecord.ReturnTime.toString().split(':')[1])
    let time2 : Date = new Date(yy, mm, dd, Hours, Minutes, 0)
    this.currentOpenedRecord.ReturnTime = time2

    if (this.currentOpenedRecord.ID == "" || this.currentOpenedRecord.ID == null || this.currentOpenedRecord.ID == "00000000-0000-0000-0000-000000000000") {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }


  DoUpdate() {
    this.caretxMedicalProviderService.updateConsultatory(this.currentOpenedRecord).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentOpenedRecord = new Consultatory(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        if (this.currentOpenedRecord.Status == "C") {
          this.router.navigateByUrl("caretexfile/" + this.id + "/consultatory/" + this.currentOpenedRecord.ID  + "/locked") // to be changed to locked later
        } else {
          this.router.navigateByUrl("caretexfile/" + this.id + "/consultatory/" + this.currentOpenedRecord.ID )
        }

      },
      error: error => {
        this.toastrService.Error("", error.error.Message)
      }
    })
  }

  DoSave() {
    this.caretxMedicalProviderService.createNewConsultatory(this.currentOpenedRecord).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentOpenedRecord = new Consultatory(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }

        this.router.onSameUrlNavigation = 'reload';
        if (this.currentOpenedRecord.Status == "C") {
          this.router.navigateByUrl("caretexfile/" + this.id + "/consultatory/" + this.currentOpenedRecord.ID  + "/locked")
        } else {
          this.router.navigateByUrl("caretexfile/" + this.id + "/consultatory/" + this.currentOpenedRecord.ID )
        }
      },
      error: error => {
        this.toastrService.Error("", error.error.Message)
      }
    })
  }




  LockDocument() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם את/ה רוצה לנעול טופס ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תנעל',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentOpenedRecord.Status = "C"
        this.currentOpenedRecord.ClosedAt = new Date()
        this.currentOpenedRecord.ClosedById = this.authService.LoggedInUser.UID
        this.currentOpenedRecord.ClosedBy = this.authService.LoggedInUser
        this.Save()
      }
    })
  }

  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/consultatory/list")
  }




  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    let isDirty:boolean  = this.mainFormElement.dirty ==true ?true:false 
    return isDirty
  }

}
