import { Component, Inject, OnInit } from '@angular/core';
import { PaitentFamily } from '../../../models/socialregistration';
import { FamilyRelations } from '../../../models/generalhousedsetups';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { ToasterService } from '../../../services/toastr.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-familymemberdialog',
  templateUrl: './familymemberdialog.component.html',
  styleUrls: ['./familymemberdialog.component.css']
})
export class FamilymemberdialogComponent implements OnInit {


  id:string
  isNew:boolean
  isDisabled:boolean
  familyMember:PaitentFamily
  familyRelations: FamilyRelations[] = []
  
  constructor(
    private dialogRef: MatDialogRef<FamilymemberdialogComponent>,
    private caretxSetupService: CaretxSetupsProviderService,
    private toastrService: ToasterService,
    private caretxSharedlService: CaretxSharedModulesProviderService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
      this.caretxSetupService.GetFamilyRelations().subscribe((data => {
        this.familyRelations = data
      }))

      
     }

  ngOnInit(): void {

    this.id = this.data.personelid;
    this.familyMember = this.data.familyMember
    this.isDisabled = this.data.IsDisabled
    this.isNew= this.data.isNew
  }

  submit(){
    // Save First then Submit 
    if(this.isNew){
      this.DoSave()
    }else{
      this.DoUpdate()
    }

  
  }

  onRelationChange($event){

    for (let i = 0; i < this.familyRelations.length; i++) {
    if ( this.familyRelations[i].ID == this.familyMember.RelationId){
      this.familyMember.Relation = this.familyRelations[i]
      break
    }
    }


  }




  DoUpdate() {
    this.caretxSharedlService.updateFamilyMemebr(this.familyMember).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.dialogRef.close(this.familyMember);
      },
      error: error => {
        this.toastrService.Error("", error.error.error)
      }
    })
  }

  DoSave() {
    this.caretxSharedlService.createFamilyMemebr(this.familyMember).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.dialogRef.close(this.familyMember);
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }




}
