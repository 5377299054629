import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaretxSocialModuleRoutingModule } from './caretx-social-module-routing.module';
import { SocialRegistrationComponent } from './social-registration/social-registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../caretx-shared-module/shared-module.module';
import { BrowserModule } from '@angular/platform-browser';
import { PipesModule } from '../caretx-custompipes-module/custompipes.module';
import { ModalModule } from '../modal';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CaretxSocialProviderService } from './Social.data-provider.service';
import { SocialadmissionComponent } from './socialadmission/socialadmission.component';
import { SocialadmissionlistComponent } from './socialadmissionlist/socialadmissionlist.component';
import { SocialformsComponent } from './socialforms/socialforms.component';
import { SocialanchorComponent } from './socialanchor/socialanchor.component';
import { SocialobservationformComponent } from './socialobservationform/socialobservationform.component';
import { SocialtrackslistComponent } from './socialtrackslist/socialtrackslist.component';
import { SocialreleaseComponent } from './socialrelease/socialrelease.component';
import { SocialreleaselistComponent } from './socialreleaselist/socialreleaselist.component';
import { AnnualreportslistComponent } from './annualreportslist/annualreportslist.component';
import { AnnualreportComponent } from './annualreport/annualreport.component';
import { FamiliyvisitComponent } from './familiyvisits/familiyvisit/familiyvisit.component';
import { FamiliyvisitsComponent } from './familiyvisits/familiyvisits.component';
import { FamilyconversationsComponent } from './familyconversations/familyconversations.component';
import { FamilyconversationComponent } from './familyconversations/familyconversation/familyconversation.component';
import { PocketTrackingComponent } from './pocket-tracking/pocket-tracking.component';
import { PocketTracksListComponent } from './pocket-tracking/pocket-tracks-list/pocket-tracks-list.component';
import { SocialReportForHelpingOldmanComponent } from './social-report-for-helping-oldman/social-report-for-helping-oldman'
import { MatExpansionModule } from '@angular/material/expansion';
import { ExternalsocialadmissionComponent } from './socialadmission/externalsocialadmission/externalsocialadmission.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  declarations: [
    SocialRegistrationComponent,
    SocialadmissionComponent,
    SocialadmissionlistComponent,
    SocialformsComponent,
    SocialanchorComponent,
    SocialobservationformComponent,
    SocialtrackslistComponent,
    SocialreleaseComponent,
    SocialreleaselistComponent,
    AnnualreportslistComponent,
    AnnualreportComponent,
    FamiliyvisitComponent,
    FamiliyvisitsComponent,
    FamilyconversationsComponent,
    FamilyconversationComponent,
    PocketTrackingComponent,
    PocketTracksListComponent,
    SocialReportForHelpingOldmanComponent,
    ExternalsocialadmissionComponent,
    
  ],
  imports: [
    CommonModule,
    CaretxSocialModuleRoutingModule,
    CommonModule,
    FormsModule,
    SharedModule,
    BrowserModule,
    ReactiveFormsModule,
    PipesModule,
    ModalModule,
    NgMultiSelectDropDownModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    PdfJsViewerModule,
 
  ],
  providers: [
    CaretxSocialProviderService
   ],
})
export class CaretxSocialModuleModule { }
