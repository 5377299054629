import { propertyMap } from './modelmapper'
import { User } from './user'



export class OtherSensitivity {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('RealmID')
    public RealmID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('Description')
    public Description: string
    @propertyMap('UserId')
    public UserId: string
    public User: User
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean



    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.RealmID = data["RealmID"]
            this.PersonelID = data["PersonelID"]
            this.Description = data["Description"]
            this.UserId = data["UserId"]
            this.User = data["User"]
            this.New = false
            this.Modified = false

        }
        else {
           
            this.Description = ""
            this.New = true
            this.Modified = false
            this.CreatedAt = new Date()
            this.UpdatedAt =  new Date()

        }
    }


}