import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxNursingProviderService } from '../../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { BaseOmdan } from '../../../../../../src/app/models/omdans';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';

@Component({
  selector: 'app-clock-exam-list',
  templateUrl: './clock-exam-list.component.html',
  styleUrls: ['./clock-exam-list.component.css']
})
export class ClockExamListComponent extends CaretexComponent  implements OnInit {

  clockExams:BaseOmdan[] =[]
  id: string
  moduleName:string="מבחני שעון"
  
  constructor( 
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private caretxNursingProviderService:CaretxNursingProviderService,
    private toastrService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,) { super(medicalFileService)}


    ngOnInit(): void {
      this._title.setTitle('Caretx | מבחני שעון');
      this.id = this.route.parent.snapshot.params['id'];
      this.CheckIfComponentIsDisabled(this.id)
      this.getOmdanemFromDB()
    }

  
    getOmdanemFromDB() {

      this.caretxNursingProviderService.getOmdanem(this.id,"","omdanclock").subscribe(
        data => this.clockExams = data,
        err => {
          this.clockExams = []
        }
      )
    }

    OnSDrillToOmdan($event,miniMentalExam :BaseOmdan){
      this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams/omdanclock/" + miniMentalExam.ID)
    }


    AddNewExam($event){
      let filter = '(omdan_status=\'A\')'
      this.caretxNursingProviderService.getOmdanem(this.id,filter,"omdanclock").subscribe((data: BaseOmdan[]) => {
        if (data.length > 0){
          let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
          this.toastrService.Error("", errorMsg)
        }
        else{
          this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams/omdanclock/new")
        }
      }); 
    }


    canDeactivate() {
      this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
    }
  
    CheckIfDocumentIsDirty(){
      return false;
    }

    deleteEvent(i){

    }

    generateReport($event,event){
    
    }

    RetrunToList(){
      this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams")
    }

}
