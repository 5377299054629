import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CaretxMedicalProviderService } from '../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { Drug } from '../../../../../src/app/models/drug';
import { DrugSensitivity, PersonelSensitivity } from '../../../../../src/app/models/drugsenstivity';
import { CartexEvent, TabHistory } from '../../../../../src/app/models/genericTypes';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { MedicalAdmissionSteps, NurseAdmissionSteps, Sensitivities } from '../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { SensitivityDialogComponent } from '../../CaretexModals/sensitivity-dialog/sensitivity-dialog.component';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-sensitivities',
  templateUrl: './sensitivities.component.html',
  styleUrls: ['./sensitivities.component.css']
})
export class SensitivitiesComponent implements OnInit {

  @Input() PersonelSensitivity: PersonelSensitivity
  @Input() IsResordClosed: boolean
  personalId: string
  unkownsensitivityStatus: { id: string; statusdescr: string }[] = [
    { id: "H", statusdescr: "ידוע לרגישות" },
    { id: "U", statusdescr: "לא ידוע לרגישות" },
  ];
  CurrentStep: Sensitivities = Sensitivities.DrugSensitivities
  currentRowIndex: number
  @Input() events: Observable<CartexEvent>;
  @Output() updateNextTab = new EventEmitter<string>();
  @Output() ModuleChanged = new EventEmitter<boolean>();
  @Output() DoRefresh = new EventEmitter<boolean>();

  @Input() OriginType: boolean
  @Input() OriginID: boolean



  private eventsSubscription: Subscription;
  private nextTab: string
  drugModalLookup: string = ""
  currentRowNumClickedModal: number
  @ViewChild('othersensitivityform') othersensitivityform: NgForm;
  @ViewChild('hasSensitivityForm') hasSensitivityForm: NgForm;


  constructor(private caretxMedicalProviderService: CaretxMedicalProviderService,
    private route: ActivatedRoute,
    public dialog: MatDialog, 
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,) { }

  ngOnInit(): void {
    if (this.route.parent != null)
    this.personalId = this.route.parent.snapshot.params['id'];
    if (this.events != null && this.events != undefined) {
      this.eventsSubscription = this.events.subscribe((data) => this.confirmUnSavedChanges(data));
    }
    // this.UpdateOtherAsModified()

  }


  SensitivityModeChanged() {
    this.caretxMedicalProviderService.getPersoenlSesitivity(this.personalId).
      subscribe({
        next: data => {
          let tmpPersonelSensitivity: PersonelSensitivity
          tmpPersonelSensitivity = new PersonelSensitivity(data)
          this.PersonelSensitivity.OtherSensitivity = tmpPersonelSensitivity.OtherSensitivity
          this.PersonelSensitivity.DrugSensitivity = tmpPersonelSensitivity.DrugSensitivity
        },
        error: error => {
          this.toastrService.Error("", error.error.message)
        }
      })
  }

  selectTab(tabId: string) {
    this.CurrentStep = (tabId as Sensitivities)
  }

  deleteRow(i) {
    this.currentRowIndex = i
    if (!this.PersonelSensitivity.DrugSensitivity[i].isNew) {

      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: "בטל"
        
      }).then((result) => {
        if (result.isConfirmed) {
          this.handleDeleteAlerMsg()
        }
      })
    }
    else {
      this.PersonelSensitivity.DrugSensitivity.splice(this.currentRowIndex, 1);
    }
  }

  handleDeleteAlerMsg() {
    let filter = '(id=' + '\'' + this.PersonelSensitivity.DrugSensitivity[this.currentRowIndex].ID + '\'' + ')'

    this.caretxMedicalProviderService.deletetDrugSensitivities(this.personalId, filter).subscribe({
      next: data => {
        this.PersonelSensitivity.DrugSensitivity.splice(this.currentRowIndex, 1);
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.DoRefresh.emit(true)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })

  }



  addNewDrugSens() {
    var newDrugSens = new DrugSensitivity()
    newDrugSens.UserId = this.authServive.LoggedInUser.UID
    newDrugSens.RealmID = this.authServive.LoggedInTenantID
    newDrugSens.User = this.authServive.LoggedInUser
    newDrugSens.User.UID = newDrugSens.UserId
    newDrugSens.PersonelID = this.personalId
    newDrugSens.editMode = newDrugSens.isNew = true;
    newDrugSens.CreatedAt = new Date()
    newDrugSens.Drug = new Drug()
    newDrugSens.New = true
    newDrugSens.Modified = false
    const dialogRef = this.dialog.open(SensitivityDialogComponent, {
      data: {
        DrugSensitivity: newDrugSens,
        personelid: this.personalId,
        Disabled: this.IsResordClosed,
        NewRecord:true,
        OriginType:this.OriginType,
        OriginID:this.OriginID,


      }
    });
    dialogRef.beforeClosed().subscribe(result => {
      this.DoRefresh.emit()
   });
    dialogRef.afterClosed().subscribe(result => {
       this.getDrugSensitivities()

    });
  }

  
  getDrugSensitivities() {
    this.caretxMedicalProviderService.getDrugSensitivities(this.personalId,"").
      subscribe({
        next: data => {
          this.PersonelSensitivity.DrugSensitivity = data
          this.medicalFileService.ResetCachedMedicalFile(this.personalId)
        },
        error: error => {
          this.toastrService.Error("", error.error.message)
        }
      })
  }


  drugEventHander($event) {
    let selectedDrug: Drug = $event
    //check if Drug already exists 
    const drugExists = this.PersonelSensitivity.DrugSensitivity.some(row => {
      return selectedDrug.id == row.Drug.id
    })

    if (!drugExists) {
      this.PersonelSensitivity.DrugSensitivity[this.currentRowNumClickedModal].Drug = selectedDrug
      this.PersonelSensitivity.DrugSensitivity[this.currentRowNumClickedModal].DrugId = selectedDrug.id
    }
    else {
      Swal.fire({
        title: "הודעת מערכת",
        text: "התרופה שבחרתה כבר נמצאת ברשימה!",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'סגור',
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.PersonelSensitivity.DrugSensitivity.splice(this.currentRowIndex, 1);
        }
      })
    }
  }

  openDrugModal(i) {
    //  this.showModal = true
    this.currentRowNumClickedModal = i
  }

  confirmUnSavedChanges(data: CartexEvent) {
    if (data.EventData != undefined) {
      if ((data.EventData as unknown as TabHistory).CurrentTab == MedicalAdmissionSteps.Sensitivities
        || (data.EventData as unknown as TabHistory).CurrentTab == NurseAdmissionSteps.Sensitivity) {
        this.nextTab = (data.EventData as unknown as TabHistory).NextTab
      }
    }
    let found: boolean = false
    let newOrUpdatedRow: boolean = false
    if (this.PersonelSensitivity.DrugSensitivity != undefined && this.PersonelSensitivity.DrugSensitivity != null) {
      found = this.PersonelSensitivity.DrugSensitivity.some(r => r.DrugId == undefined || r.DrugId == "" || r.DrugId == null)
      newOrUpdatedRow = this.PersonelSensitivity.DrugSensitivity.some(r => r.New || r.Modified)

    } 
    if (!found) {
      let othersensitivityformChanged: boolean = false
      if (this.othersensitivityform != undefined && this.othersensitivityform != null) {
        othersensitivityformChanged = this.othersensitivityform.dirty == null ? false : this.othersensitivityform.dirty
      }
      let isDirty = newOrUpdatedRow || othersensitivityformChanged || this.hasSensitivityForm.dirty == null ? false : this.hasSensitivityForm.dirty
      this.ModuleChanged.emit(isDirty)
      this.updateNextTab.emit(this.nextTab)
    }

    else {
      this.showAlertMsgOnDrugMissing()
    }


  }



  showAlertMsgOnDrugMissing() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "אתה צריך לבחור תרופה !",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'סגור',
      allowOutsideClick: false,
      allowEscapeKey: false
    })
  }

  UpdateOtherAsModified() {
    this.PersonelSensitivity.OtherSensitivity.RealmID = this.authServive.LoggedInTenantID
    this.PersonelSensitivity.OtherSensitivity.PersonelID = this.personalId
    this.PersonelSensitivity.OtherSensitivity.UpdatedAt = new Date()


    if (this.PersonelSensitivity.OtherSensitivity.ID == "00000000-0000-0000-0000-000000000000") {
      this.PersonelSensitivity.OtherSensitivity.New = true
      this.PersonelSensitivity.OtherSensitivity.Modified = false
    } else {
      this.PersonelSensitivity.OtherSensitivity.New = false
      this.PersonelSensitivity.OtherSensitivity.Modified = true
    }

  }


  openDialog(drugSensitivity,i){

    const dialogRef = this.dialog.open(SensitivityDialogComponent, {
      data: {
        DrugSensitivity: drugSensitivity,
        personelid: this.personalId,
        Disabled: this.IsResordClosed,
        NewRecord:false,
        OriginType:this.OriginType,
        OriginID:this.OriginID,
      }
    });
 

    dialogRef.afterClosed().subscribe(result => {
      // Refresh parent
      this.DoRefresh.emit(true)
    });


  }
}
