import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { CaretxSharedModulesProviderService } from '../../../../../src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { Attachment } from '../../../../../src/app/models/attachments';
import { MedicalFile } from '../../../../../src/app/models/customer';
import { Departments, FamilyRelations, Funder, NursingStatus, Referrer } from '../../../../../src/app/models/generalhousedsetups';
import { CaretexEventTypes, CartexEvent, TabHistory } from '../../../../../src/app/models/genericTypes';
import { PhysicalExamination } from '../../../../../src/app/models/physicalexamination';
import { PaitentFamily } from '../../../../../src/app/models/socialregistration';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { FileCategory, MainCardSteps, OptionObject } from '../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { MedicalFileProviderService } from '../../medicalfile-services/medicalfile.data-provider.service';
import { Subject } from 'rxjs';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { AttachmentDialogComponent } from 'src/app/caretx-shared-module/CaretexModals/attachment-dialog/attachment-dialog.component';
import { DocViewerComponent } from 'src/app/caretx-shared-module/CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { FamilymemberdialogComponent } from 'src/app/caretx-shared-module/CaretexModals/familymemberdialog/familymemberdialog.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-medicalfile',
  templateUrl: './medicalfile.component.html',
  styleUrls: ['./medicalfile.component.scss']
})
export class MedicalfileComponent extends CaretexComponent implements OnInit, OnDestroy {
  show = 'settings';
  @ViewChild('showAttachForm') showAttachForm: ElementRef;
  id: string
  medicalFile: MedicalFile
  calcAge: Number = 90
  fullName: string
  showNotes: boolean
  currentDateTime: Date
  PhysicalExaminations: PhysicalExamination[] = []
  departments: Departments[] = []
  referrers: Referrer[] = []
  nursingStatuses: NursingStatus[] = []
  funders: Funder[] = []
  isEditMode: boolean = true
  isNew: boolean = false
  @ViewChild('f') mainFormElement: NgForm;
  CurrentStep: MainCardSteps = MainCardSteps.Card
  familyRelations: FamilyRelations[] = []
  tabHistory: TabHistory = new TabHistory()
  caretexEvent: CartexEvent = new CartexEvent()
  currentRowIndex: number
  FormValidated: boolean = false;
  profileImgUrl: any = 'assets/images/user-avatar.png';
  profileImgTemp: any = 'assets/images/user-avatar.png';
  profileImgFile: any;
  showWebcam: boolean = false
  fileCategory: string = 'F'
  pdfContent: any
  LoggedInUser: string
  FileCategoryList: OptionObject[] = [{ id: 'socialid', name: 'תעודת זהות' },]
  bodyController: any;
  subject = new Subject<boolean>();
  justRout: boolean
  sidenavWidth = 4;
  TabId = "medicalfiles"
  constructor(private _title: Title,
    // private _customersService: CustomerService,
    public medicalFileService: MedicalFileProviderService,
    private caretxSetupService: CaretxSetupsProviderService,

    private router: Router,
    private route: ActivatedRoute,
    private _ar: ActivatedRoute,
    public dialog: MatDialog,
    private sharedServices: CaretxSharedModulesProviderService,
    private authServive: AuthService,
    private toastrService: ToasterService) {

    super(medicalFileService)
    this.caretxSetupService.GetHouseDepartments().subscribe((data => {
      this.departments = data
    }))

    this.caretxSetupService.GetReferrers().subscribe((data => {
      this.referrers = data
    }))



    this.caretxSetupService.GetHouseFunders().subscribe((data => {
      this.funders = data
    }))

    this.caretxSetupService.GetFamilyRelations().subscribe((data => {
      this.familyRelations = data
    }))


    this.caretxSetupService.GetNursingStatuses().subscribe((data => {
      this.nursingStatuses = data
    }))
  }


  ngOnInit(): void {
    this._title.setTitle('Caretex |כרטיס מטופל');
    this.LoggedInUser = this.authServive.LoggedInUser.UID
    this.id = this._ar.snapshot.params['id'];
    if (this.route.snapshot.params['tabid'] != "" && this.route.snapshot.params['tabid'] != undefined) {
      this.show = (this.route.snapshot.params['tabid'])
    }


    if (this._ar.snapshot.data["mode"] != undefined) {
      if ((this._ar.snapshot.data["mode"][0]) == "newpage") {
        this.isNew = true
        this.isEditMode = true
        this.medicalFile = new MedicalFile()
        this.medicalFile.JoinedAt = new Date()
        this.medicalFile.ImagePath = "../../../../assets/images/user-avatar.png"
        this.medicalFile.DepartmentAcceptanceDate = this.medicalFile.JoinedAt
      }

    } else {
      this.getMEdicalFilefromDB()
    }

    this.bodyController = document.getElementsByTagName('body')[0];
    this.bodyController.classList.add('overflow-hidden');
  }
  increase() {
		this.sidenavWidth = 15;
		console.log('increase sidenav width');
	}
	decrease() {
		this.sidenavWidth = 4;
		console.log('decrease sidenav width');
	}
  ngOnDestroy(): void {
    this.bodyController.classList.remove('overflow-hidden');
  }


  getMEdicalFilefromDB() {
    this.medicalFileService.getMedicalFile(this.id).subscribe(data => {
      this.medicalFile = new MedicalFile(data)
      this.medicalFileService.CurrentOpenedFile = this.medicalFile
      this.fullName = this.medicalFile.FirstName + " " + this.medicalFile.LastName
      this.showNotes = true
      // this.isNew = false
      this.currentDateTime = new Date();
      if (this.medicalFile.ImagePath == "" || this.medicalFile.ImagePath == null || this.medicalFile.ImagePath == undefined) {
        this.profileImgTemp = this.profileImgUrl;
      } else {
        this.profileImgTemp = this.medicalFile.ImagePath;
      }
    })
  }



  //Bring MedicalFile
  routeToEditPage() {
    this.isEditMode = true
    this.isNew = false
    if (this._ar.snapshot.data["mode"] != undefined) {
      if ((this._ar.snapshot.data["mode"][0]) == "newpage")
        this.isNew = true
    }
  }

  returntoDetailsPage() {
    this.isEditMode = false

    this.medicalFile.Family.forEach(element => {
      element.editMode = false;
      element.New = false
      element.Modified = false
    });

  }

  returntoFilesList() {
    this.router.navigate(['medfiles']);
  }

  saveDetails() {
    this.medicalFile.MigrationDate = new Date(this.medicalFile.MigrationDate)
    this.medicalFile.BirthDay = new Date(this.medicalFile.BirthDay)
    this.medicalFile.JoinedAt = new Date(this.medicalFile.JoinedAt)
    this.medicalFile.ReleasedAt = new Date(this.medicalFile.ReleasedAt)
    this.medicalFile.LastUpdated_at = new Date()
    this.medicalFile.LastUpdatedById = this.authServive.LoggedInUser.UID
    this.medicalFile.DepartmentAcceptanceDate = new Date(this.medicalFile.DepartmentAcceptanceDate)
    this.medicalFile.ReferrerId = this.medicalFile.Referrer.ID

    if (this.id == null || this.id == undefined) {
      this.saveNewFile()
    }
    else {
      this.updateDetails()
    }
  }

  updateDetails() {
    this.medicalFileService.updateMedicalFile(this.medicalFile).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.medicalFileService.CurrentOpenedFile = this.medicalFile
        this.isNew = false
        this.endRowTableEditableMode()
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.medicalFileService.resetCachedMedicalFile(this.medicalFile.PersonelID)
        // this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("/medicalfiles/" + this.medicalFile.PersonelID + "/" + this.show)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  ValidateDepartmentFields() {
    if ( (this.medicalFile.DepartmentId != "00000000-0000-0000-0000-000000000000" && this.medicalFile.DepartmentId != undefined) && 
    ( this.medicalFile.StatusId !=  "00000000-0000-0000-0000-000000000000" && this.medicalFile.StatusId != undefined)  &&
        ( this.medicalFile.FunderId !=  "00000000-0000-0000-0000-000000000000" && this.medicalFile.FunderId != undefined) ) {
      return true
    }
    return false
  }

  Save() {

    console.log('MEDICAL - File____Save-Func', this.medicalFile);

    if (!this.mainFormElement.valid) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "צריך למלאות כל שדות החובה",
        icon: 'warning',

        confirmButtonText: 'סגור',
        allowOutsideClick: false,
        allowEscapeKey: false
      })

    } else if (!this.ValidateDepartmentFields()) {
      // Set Focuse on Departmetn and show message to fill mandatory fields
      this.show = 'department'
      this.FormValidated = true
      Swal.fire({
        title: "הודעת מערכת",
        text: "צריך למלאות כל שדות החובה",
        icon: 'warning',
        confirmButtonText: 'סגור',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    } else {
      this.mainFormElement.onSubmit(undefined);
    }

  }


  saveNewFile() {
    this.medicalFileService.newMedicalFile(this.medicalFile).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.medicalFileService.CurrentOpenedFile = this.medicalFile
        this.isNew = false
        this.endRowTableEditableMode()
        this.mainFormElement.reset(this.mainFormElement.value);

        this.medicalFileService.ResetCachedMedicalFile(this.medicalFile.PersonelID)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("/medicalfiles/" + this.medicalFile.PersonelID + "/" + this.show)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }


  endRowTableEditableMode() {
    if (this.medicalFile.Family != undefined && this.medicalFile.Family != null) {
      this.medicalFile.Family.forEach(element => {
        element.editMode = false
      });
    }

  }

  sendToArchive() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם להעביר את התיק לארכיון ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תעביר',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed || result.isDenied) {
        if (result.isConfirmed) this.medicalFile.IsArchived = true
        this.saveDetails()
      }
    })
  }


  selectTab(tabId: string) {

    this.tabHistory.CurrentTab = this.CurrentStep
    this.tabHistory.NextTab = tabId
    this.caretexEvent.EventType = CaretexEventTypes.Tab
    this.caretexEvent.EventData = this.tabHistory


    this.CurrentStep = (tabId as MainCardSteps)
    if (this.CheckIfDocumentIsDirty())
      this.saveDetails()
    else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }

    }
  }


  addNewFamilyMember() {
    var newFamilyMember = new PaitentFamily()
    newFamilyMember.PersonelID = this.id
    newFamilyMember.TenantID = this.authServive.LoggedInTenantID
    newFamilyMember.editMode = true;
    newFamilyMember.CreatedAt = new Date()
    newFamilyMember.New = true
    newFamilyMember.Modified = false
    // this.medicalFile.Family.unshift(newFamilyMember)
    const dialogRef = this.dialog.open(FamilymemberdialogComponent, {
      data: {
        id: this.id,
        isNew:true,
        familyMember: newFamilyMember,
        isDisabled: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getFamilyMemmbers()
    });


  }

  deleteFamilyRow(i) {
    this.currentRowIndex = i
    if (!this.medicalFile.Family[i].New) {

      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DeleteFamilyRow()
        }
      })
    }
    else {
      this.medicalFile.Family.splice(this.currentRowIndex, 1);
    }
  }


  DeleteFamilyRow() {


    this.medicalFileService.deleteFamilyMember(this.id, this.medicalFile.Family[this.currentRowIndex]).subscribe({
      next: data => {
        this.medicalFile.Family.splice(this.currentRowIndex, 1);
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  editFamilyRow(i) {
    /* this.medicalFile.Family[i].editMode = true;
     this.medicalFile.Family[i].New = false
     this.medicalFile.Family[i].Modified = true*/


    // this.medicalFile.Family.unshift(newFamilyMember)
    const dialogRef = this.dialog.open(FamilymemberdialogComponent, {
      data: {
        id: this.id,
        isNew:false,
        familyMember: this.medicalFile.Family[i],
        isDisabled: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
     // let familymember: PaitentFamily = result
    this.getFamilyMemmbers()
    });
  }


  getFamilyMemmbers(){
    this.sharedServices.getFamilyMemebrs(this.id,null).subscribe(
      data => {
        this.medicalFile.Family = data
      },
      err => {
        console.log("Error", err)
        this.medicalFile.Family = []

      }
    )
  }


  GurdianChange(isGurdian: string) {
    if (isGurdian == "N") {
      this.medicalFile.GuardianAddress = ""
      this.medicalFile.GuardianName = ""
      this.medicalFile.GuardianPhone = ""
      this.medicalFile.GuardianPhone2 = ""
    }
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty == null ? false : this.mainFormElement.dirty
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }


  fileUpload(event) {
    if (event.target.files.length > 0) {
      const imgType = event.target.files[0].type;
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.profileImgTemp = reader.result;
        console.log('FILE FOUND...', this.profileImgTemp);
      }
      const file = event.target.files[0];
      this.profileImgFile = file
    }
  }

  saveUserImage() {
    this.medicalFileService.uploadImage(this.id, this.profileImgFile).subscribe(
      data => {
        this.medicalFileService.ResetCachedMedicalFile(this.id)
        this.medicalFile.ImagePath = data as string
        this.profileImgFile = '';
      }
    );
  }

  onDiscardProfileImg() {
    this.profileImgFile = '';
    if (this.medicalFile.ImagePath == "" || this.medicalFile.ImagePath == null || this.medicalFile.ImagePath == undefined) {
      this.profileImgTemp = this.profileImgUrl;
    } else {
      this.profileImgTemp = this.medicalFile.ImagePath;
    }
  }

  enableContent(type) {
    this.show = type;
  }

  BackToList() {
    if (this.mainFormElement.dirty) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "אתה עומד לעזוב דף זה בלי לשמור שינויים , להמשיך",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: "בטל",
        confirmButtonText: 'כן,תמשיך',
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.mainFormElement.reset(this.mainFormElement.value);
          this.router.navigate(['medfiles']);
        }
      })
    } else {
      this.router.navigate(['medfiles']);
    }

  }

  onChangeReferrer($event) {

    for (let referr of this.referrers) {
      if (referr.ID == $event) {
        this.medicalFile.Referrer = referr
        break
      }
    }

  }


  onChangeRelation(index) {
    for (let rel of this.familyRelations) {
      if (rel.ID == this.medicalFile.Family[index].RelationId) {
        if (rel.IsOtherVal) {
          this.medicalFile.Family[index].IsOtherVal = true
          break
        }
      }
    }
  }

  setRelationlist(i) {
    this.medicalFile.Family[i].IsOtherVal = false
  }

  showSocialIDCard() {
    let cardIdfound: boolean = false
    for (let i = 0; i < this.medicalFile.AttachedFiles.length; i++) {
      if (this.medicalFile.AttachedFiles[i].FileCategory == FileCategory.SocialIDFile) {
        cardIdfound = true
        this.downloadFile(this.medicalFile.AttachedFiles[i])
        break
      }

      if (!cardIdfound) {

        this.showAttachForm.nativeElement.click()
      }
    }
  }

  AttachSocialCard() {
    // Check If socialid exists otherwise Uploaded it

    this.FileCategoryList = [{ id: 'socialid', name: 'תעודת זהות' },]
    const dialogRef = this.dialog.open(AttachmentDialogComponent, {
      data: {
        UserId: this.LoggedInUser,
        FileCategoryList: this.FileCategoryList,
        Id: this.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Save Diagnoses
      if (result == undefined || result == null || result == "") return
      this.updateRefreshedObservFiles()
    });
  }

  checkSocialIdFile() {
    this.medicalFile.AttachedFiles = null
    let filter = "file_category='socialid'"
    this.sharedServices.getAttachments(this.id, filter).subscribe(
      data => { 
              if (data.length > 0 ) {
                this.downloadFile(data[0])
              }else {
                  this.AttachSocialCard()
              }
      },
      err => {
        this.medicalFile.AttachedFiles = []
      }
    )
  }


  downloadFile(file: Attachment) {
    const dialogRef = this.dialog.open(DocViewerComponent, {
      data: {
        Type: 'GF', // General File
        Id: this.id,
        Attachment: file
      }
    });
  }


  AttachFile() {
    this.FileCategoryList = [{ id: 'general', name: 'מסמכים כללים' },]
    const dialogRef = this.dialog.open(AttachmentDialogComponent, {
      data: {
        UserId: this.LoggedInUser,
        FileCategoryList: this.FileCategoryList,
        Id: this.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Save Diagnoses
      if (result == undefined || result == null || result == "") return
      this.updateRefreshedObservFiles()
    });
  }

  updateRefreshedObservFiles() {
    this.medicalFile.AttachedFiles = null
    let filter = "file_category='general'"
    this.sharedServices.getAttachments(this.id, filter).subscribe(
      data => this.medicalFile.AttachedFiles = data,
      err => {
        this.medicalFile.AttachedFiles = []
      }
    )
  }

  /******************** Lock Medical File******************************************** */
  LockMedicalFile() {
    this.router.navigateByUrl("/medicalfilelock/" +  this.id )

  }
}
