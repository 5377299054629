import { Component, Inject, OnInit } from '@angular/core';

import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { Drug } from '../../../../../src/app/models/drug';
import { PainLevel, PainLocation, PainMitigatingFactor, PainNature, PainSymptoms, Paintreatment } from '../../../../../src/app/models/generalhousedsetups';
import { NursePhysicalStatus } from '../../../../../src/app/models/nursingphysicalstatuses';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-multipleoptionsselection-dialog',
  templateUrl: './multipleoptionsselection-dialog.component.html',
  styleUrls: ['./multipleoptionsselection-dialog.component.css']
})
export class MultipleoptionsselectionDialogComponent implements OnInit {
  Statuses: NursePhysicalStatus[];



  Type: string = "NursePhysicalStatus"
  title: string
  selectedList: NursePhysicalStatus[] = []
  constructor(private caretxSharedlService: CaretxSharedModulesProviderService, private caretxSetupsProviderService: CaretxSetupsProviderService, private dialogRef: MatDialogRef<MultipleoptionsselectionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { dialogRef.disableClose = true; }

  ngOnInit(): void {

    this.title = this.data.title;
    this.Type = this.data.Type;
    this.Statuses = this.data.Statuses;

    switch (this.Type) {
      case "NursePhysicalStatus":
        break
      case "PainLevels":
        this.GetAllPainLevelCodes()
        break
      case "PainLocation":
        this.GetAllPainLocationCodes()
        break
      case "PainMitigatingFactor":
        this.GetAllPainMitigatingFactorCodes()
        break
      case "PainSymptoms":
        this.GetAllPainSymptomsCodes()
        break
      case "PainNature":
        this.GetAllPainNatureCodes()
        break
      case "Paintreatment":
        this.GetAllPaintreatmentCodes()
        break
      case "Drugs":
        this.GetAllPainDrugsCodes()
        break

    }

  }

  submit() {

    switch (this.Type) {
      case "NursePhysicalStatus":
        this.dialogRef.close(this.selectedList)
        break
      case "PainLevels":
        this.dialogRef.close(this.selectedPainLevels)
        break
      case "PainLocation":
        this.dialogRef.close(this.selectedPainLocations)
        break
      case "PainMitigatingFactor":
        this.dialogRef.close(this.selectedPainMitigatingFactor)
        break
      case "PainSymptoms":
        this.dialogRef.close(this.selectedPainSymptoms)
        break
      case "PainNature":
        this.dialogRef.close(this.selectedPainNatures)
        break

      case "Paintreatment":
        this.dialogRef.close(this.selectedPaintreatments)
        break

      case "Drugs":
        this.dialogRef.close(this.selectedPaintDrugs)
        break
    }
  }




  PainNatures: PainNature[] = []
  selectedPainNatures: PainNature[] = []
  GetAllPainNatureCodes() {
    this.caretxSetupsProviderService.getPainNature().subscribe(
      data => {
        this.PainNatures = data
      },
      err => {
        console.log("Error", err)
        this.PainNatures = []

      }
    )
  }

  Paintreatments: Paintreatment[] = []
  selectedPaintreatments: Paintreatment[] = []
  GetAllPaintreatmentCodes() {
    this.caretxSetupsProviderService.getPaintreatment().subscribe(
      data => {
        this.Paintreatments = data
      },
      err => {
        console.log("Error", err)
        this.Paintreatments = []

      }
    )
  }


  PaintDrugs: Drug[] = []
  selectedPaintDrugs: Drug[] = []
  GetAllPainDrugsCodes() {
    this.caretxSharedlService.getMedicalDrugs("").subscribe(
      data => this.PaintDrugs = data,
      err => {
        this.PaintDrugs = []
      }
    )
  }




  PainLevels: PainLevel[] = []
  selectedPainLevels: PainLevel[] = []
  GetAllPainLevelCodes() {
    this.caretxSetupsProviderService.getPainLevel().subscribe(
      data => {
        this.PainLevels = data
      },
      err => {
        console.log("Error", err)
        this.PainLevels = []

      }
    )
  }

  PainLocations: PainLocation[] = []
  selectedPainLocations: PainLocation[] = []
  GetAllPainLocationCodes() {
    this.caretxSetupsProviderService.getPainLocation().subscribe(
      data => {
        this.PainLocations = data
      },
      err => {
        console.log("Error", err)
        this.PainLocations = []

      }
    )
  }

  PainMitigatingFactors: PainMitigatingFactor[] = []
  selectedPainMitigatingFactor: PainMitigatingFactor[] = []
  GetAllPainMitigatingFactorCodes() {
    this.caretxSetupsProviderService.getPainMitigatingFactor().subscribe(
      data => {
        this.PainMitigatingFactors = data
      },
      err => {
        console.log("Error", err)
        this.PainMitigatingFactors = []

      }
    )
  }


  PainSymptoms: PainSymptoms[] = []
  selectedPainSymptoms: PainSymptoms[] = []
  GetAllPainSymptomsCodes() {
    this.caretxSetupsProviderService.getPainSymptoms().subscribe(
      data => {
        this.PainSymptoms = data
      },
      err => {
        console.log("Error", err)
        this.PainSymptoms = []

      }
    )
  }



  onStatusSelectionChanged(item, $event) {
    switch (this.Type) {
      case "NursePhysicalStatus":


        if ($event.checked) {
          this.selectedList.push(item)
        } else {

          this.selectedList.forEach((temp, index) => {
            if (temp.Status == item.Status) {
              this.selectedList.splice(index, 1)
            }
          })
        }
        break
      case "PainLevels":
        if ($event.checked) {
          this.selectedPainLevels.push(item)
        } else {

          this.selectedPainLevels.forEach((temp, index) => {
            if (temp.PainLevel == item.PainLevel) {
              this.selectedPainLevels.splice(index, 1)
            }
          })
        }
        break
      case "PainLocation":
        if ($event.checked) {
          this.selectedPainLocations.push(item)
        } else {

          this.selectedPainLocations.forEach((temp, index) => {
            if (temp.PainLocation == item.PainLocation) {
              this.selectedPainLocations.splice(index, 1)
            }
          })
        }
        break
      case "PainMitigatingFactor":
        if ($event.checked) {
          this.selectedPainMitigatingFactor.push(item)
        } else {

          this.selectedPainMitigatingFactor.forEach((temp, index) => {
            if (temp.PainMitigatingFactor == item.PainMitigatingFactor) {
              this.selectedPainMitigatingFactor.splice(index, 1)
            }
          })
        }
        break
      case "PainSymptoms":
        if ($event.checked) {
          this.selectedPainSymptoms.push(item)
        } else {

          this.selectedPainSymptoms.forEach((temp, index) => {
            if (temp.PainSymptoms == item.PainSymptoms) {
              this.selectedPainSymptoms.splice(index, 1)
            }
          })
        }
        break

        case "PainNature":
          if ($event.checked) {
            this.selectedPainNatures.push(item)
          } else {
  
            this.selectedPainNatures.forEach((temp, index) => {
              if (temp.PainNature == item.PainNature) {
                this.selectedPainNatures.splice(index, 1)
              }
            })
          }
          break


          case "Paintreatment":
            if ($event.checked) {
              this.selectedPaintreatments.push(item)
            } else {
    
              this.selectedPaintreatments.forEach((temp, index) => {
                if (temp.Paintreatment == item.Paintreatment) {
                  this.selectedPaintreatments.splice(index, 1)
                }
              })
            }
            break

            case "Drugs":
              if ($event.checked) {
                this.selectedPaintDrugs.push(item)
              } else {
      
                this.selectedPaintDrugs.forEach((temp, index) => {
                  if (temp.id == item.id) {
                    this.selectedPaintDrugs.splice(index, 1)
                  }
                })
              }
              break



    }









  }

}
