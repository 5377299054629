

import { Diagnoses } from './diagnoses'
import { DrugInstruction } from './drugInstruction'
import { propertyMap } from './modelmapper'
import { User } from './user'




export class HospitalReferral {
    @propertyMap('ID')
    public ID:string
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('Status')
    public Status: string
    @propertyMap('ReferralDate')
    public ReferralDate: Date
    @propertyMap('DoctorID')
    public DoctorID:string
    @propertyMap('Doctor')
    public Doctor:User
    @propertyMap('ReferralTo')
    public ReferralTo: string
    @propertyMap('Guardian')
    public Guardian: string
    @propertyMap('GuardianPhone')
    public GuardianPhone: string
    @propertyMap('ReferralReason')
    public ReferralReason: string
    @propertyMap('Comments')
    public Comments: string
    @propertyMap('ClosedById')
    public ClosedById: string
    @propertyMap('ClosedBy')
    public ClosedBy: User
    @propertyMap('ClosedAt')
    public ClosedAt:Date

    @propertyMap('Diagnoses')
    public Diagnoses: Diagnoses[]
    @propertyMap('DrugInstructions')
    public DrugInstructions: DrugInstruction[]


    constructor(data?: any) {
        if (data != null) {
            this.ID = data.ID   
            this.PersonelID = data.PersonelID
            this.CreatedAt = data.CreatedAt
            this.UpdatedAt = data.UpdatedAt 
            this.Status = data.Status
            this.TenantID = data.TenantID
            this.ReferralDate = data.ReferralDate
            this.ReferralTo = data.ReferralTo
            this.Guardian = data.Guardian
            this.GuardianPhone = data.GuardianPhone
            this.ReferralReason =data.ReferralReason
            this.Comments =data.Comments
            this.ClosedById = data.ClosedById
            this.ClosedAt = data.ClosedAt
            this.ClosedBy = data.ClosedBy
            this.DrugInstructions = data["DrugInstructions"]
            this.Diagnoses = data["Diagnoses"]
            this.DoctorID =data.DoctorID
            this. Doctor =data. Doctor
       


        }
        else {
            this.Status = "A"
            this.Doctor =  new User()

        }
    }



}