import { Component, OnInit, ViewChild } from '@angular/core';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { jsPDF } from "jspdf";
import { ReportingService } from '../../../services/reporting.service'
import { MedicalFile } from '../../../../../src/app/models/customer';
import { CaretxMedicalProviderService } from '../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { DrugInstruction } from '../../../../../src/app/models/drugInstruction';
import { MedicalprescriptionDialogComponent } from '../../CaretexModals/medicalprescription-dialog/medicalprescription-dialog.component';
import { MedicalPrescriptionHeader, MedicalPrescriptionLine } from '../../../models/medical-prescriptions';
import { AuthService } from '../../../services/auth.service';
import { Drug } from '../../../models/drug';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { MatStepper } from '@angular/material/stepper';
import { Departments } from 'src/app/models/generalhousedsetups';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MatDialog } from '@angular/material/dialog';

type Items1 = {
  DepartmentName: string;
  ID: string
};


@Component({
  selector: 'app-medical-prescriptions',
  templateUrl: './medical-prescriptions.component.html',
  styleUrls: ['./medical-prescriptions.component.scss']
})
export class MedicalPrescriptionsComponent extends CaretexComponent implements OnInit {


  PatientDrugInstruction: DrugInstruction[] = []
  IsResordClosed: boolean = false
  NewRecord: boolean = false
  medicalPrescription: MedicalPrescriptionHeader = new MedicalPrescriptionHeader()
  enableAddPrescriptionBtn: boolean = false
  base64File: Blob
  @ViewChild('pdfViewer')  pdfViewer;
  @ViewChild("stepper", { static: false }) stepper: MatStepper;
  recordid: string
  DefaultPhotoURL: string = "../../assets/images/user-avatar.png"
  TabId = "main"
  pdfContent: any
  pageType : string = ""
  title1 : string = "הפקת מרשמים עבור מטופלים"
  selectedPatient :boolean = false
  title:string = ""
  departments: Departments[] = []
  selectedItems1:Items1[] = [];
  dropdownSettings1 = {};
  filteredData: MedicalFile[] = [];
  renderedData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];
  totalNumOfRecords: number
  showDepMandatoryLbl: boolean = false
  selectedMedicalFile: MedicalFile= new MedicalFile()

  constructor(
    private caretxMedicalService: CaretxMedicalProviderService,
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private caretxSetupService: CaretxSetupsProviderService,
    private toastrService: ToasterService,
    public medicalFileService: MedicalFileProviderService,
    public dialog: MatDialog,
    private _ar: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public reportingService: ReportingService

  ) { super(medicalFileService) 
  
    this.caretxSetupService.GetUserAllowedDepartments(this.authService.LoggedInUser.UID).subscribe(data => {
      this.departments = data
      this.selectedItems1 = JSON.parse(localStorage.getItem('user_selected_departments') || '{}');
      if (this.selectedItems1.length == 0) {
        if (this.departments.length > 0) {
          this.departments.forEach(element => {
            this.selectedItems1.push({ "DepartmentName": element.DepartmentName, "ID": element.ID })
          });
        } else {
        }
      }
    })
  
  
  }

  ngOnInit(): void {
    this.id = this._ar.snapshot.params['id'];
    this.recordid = this.route.snapshot.params.recordid

    if (this.recordid != "new" && this.recordid != "" && this.recordid != undefined) {
      this.generateMedicalPrescriptionByID()

    } else {
      this.generateNewPrescription()
    }
    this.loadData2("")
    this.doInitialization()
  }


  doInitialization() {
    // Get LabCategories :
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: "ID",
      textField: "DepartmentName",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }

  loadData2(filter: string) {
    this.medicalFileService.getMedicalFiles(filter);
    this.medicalFileService.dataChange.subscribe((data) => {
      this.filteredData = this.allFiles = data
      this.filteredData.forEach(element => {
        element.FullName = element.FirstName + " " + element.LastName
      });
      this.totalNumOfRecords = this.allFiles.length
    })
  }

  filterPaitents($event) {
    let filter :string  =  $event.currentTarget.value
    this.filteredData = this.allFiles
      this.allFiles = this.medicalFileService.data
      this.filteredData = this.medicalFileService.data.slice().filter((medicalFile: MedicalFile) => {
        const searchStr = (medicalFile.PersonelID + medicalFile.FirstName + medicalFile.LastName ).toLowerCase();
        return searchStr.indexOf(filter.toLowerCase()) !== -1;
      });
  }
  // Retrieves MedicalPrescriptions 
  generateMedicalPrescriptionByID() {
    this.caretxSharedlService.getMedicalPrescription(this.recordid).subscribe(
      data => {
        if (data.length == 1) {
          this.medicalPrescription = new MedicalPrescriptionHeader(data[0])
          this.title = "מרשם תרופות עבור " + this.medicalPrescription.PersonDoc.FirstName + " " + this.medicalPrescription.PersonDoc.LastName
          if (this.medicalPrescription.Status == "C"){
            this.enableAddPrescriptionBtn = false
          }
          this.selectedMedicalFile = this.medicalPrescription.PersonDoc
          var doc = new jsPDF()
          this.SetHeader(doc)
          this.medicalPrescription.PrescriptionLines.forEach((line, index) => {
            this.setRow(doc, line, index)
          })
          this.setFooter(doc)
          let file: Blob = doc.output('blob')
          this.pdfContent = URL.createObjectURL(file);
          this.pdfViewer.pdfSrc = file
          this.pdfViewer.refresh();
          this.openPDFViewer()
        }
        else {
          let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
          this.toastrService.Error("", errorMsg)
        }
      },
      err => {
        this.medicalPrescription = new MedicalPrescriptionHeader()
        let errorMsg = "שחזור רישימה לא הצלח"
        this.toastrService.Error("", errorMsg)
      }
    )

  }

  generateNewPrescription() {
    this.medicalPrescription = new MedicalPrescriptionHeader()
    this.title = this.title1
    this.medicalPrescription.CreatedAt = new Date()
    this.medicalPrescription.UpdatedAt = this.medicalPrescription.CreatedAt
    this.medicalPrescription.TenantID = this.authService.LoggedInTenantID
    this.medicalPrescription.DoctorId = this.authService.LoggedInUser.UID
    this.enableAddPrescriptionBtn = false
    this.medicalPrescription.PrescriptionLines = []
  }

  NewPrescription() {
    this.router.navigateByUrl("medicalprescriptions")
  }


  OpenPatientsDialog() {
    this.pageType = "Operation"
    this.selectedPatient = true
  
  /*  const dialogRef = this.dialog.open(PatientsDialogComponent, {
      data: {

      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == null) return
      else {
        this.selectedFile = result
        this.medicalPrescription.PersonelID = this.selectedFile.PersonelID
        this.title =  "מרשם תרופות עבור " + this.selectedFile.FirstName + " " + this.selectedFile.LastName
        this.enableAddPrescriptionBtn = true
        // this.RetreivePatientMedicalDrugInstr("")

      }

    });*/

  }

  AddNewDrugToReport() {
    var newMedicalPrescription = new MedicalPrescriptionLine()
    newMedicalPrescription.New = true
    newMedicalPrescription.Modified = false
    newMedicalPrescription.DoctorId = this.authService.LoggedInTenantID
    newMedicalPrescription.TenantID = this.authService.LoggedInTenantID
    newMedicalPrescription.PersonelID = this.selectedMedicalFile.PersonelID
    newMedicalPrescription.CreatedAt = new Date()
    newMedicalPrescription.CreatedById = this.authService.LoggedInUser.UID
    newMedicalPrescription.Drug = new Drug()


    const dialogRef = this.dialog.open(MedicalprescriptionDialogComponent, {
      data: {
        MedicalPrescriptionRecord: newMedicalPrescription,
        Disabled: this.IsResordClosed,
        NewRecord: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == null || result == "") return
      else {
        this.medicalPrescription.PrescriptionLines.push(result)
      }

    });

  }

  deleteRow(i) {
    this.medicalPrescription.PrescriptionLines.splice(i,1);
  }

  OpenLineRecord(drugInst: MedicalPrescriptionLine) {
    drugInst.New = false
    drugInst.Modified = true
    const dialogRef = this.dialog.open(MedicalprescriptionDialogComponent, {
      data: {
        MedicalPrescriptionRecord: drugInst,
        Disabled: this.IsResordClosed,
        NewRecord: false
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == null || result == "") return
      else {
        // Check if Exists then Replace it else add it to collection

        if (drugInst.Modified) {
          for (let i = 0; i < this.medicalPrescription.PrescriptionLines.length; i++) {
            if (drugInst.ID == this.medicalPrescription.PrescriptionLines[i].ID) {
              this.medicalPrescription.PrescriptionLines[i] = drugInst
            }
          }

        } else {
          if (drugInst.New) {
            this.medicalPrescription.PrescriptionLines.push(result)
          }
        }


      }

    });
  }

  RetreivePatientMedicalDrugInstr(filter: string) {

    this.caretxMedicalService.getDrugInstrucs(this.selectedMedicalFile.PersonelID, filter).subscribe(
      data => {
        this.PatientDrugInstruction = data

      },
      err => {
        this.PatientDrugInstruction = []
      }
    )

  }

  Print() {
    this.medicalPrescription.Status = "C"
    if (this.medicalPrescription.ID == "00000000-0000-0000-0000-000000000000" || this.medicalPrescription.ID == "" || this.medicalPrescription.ID == undefined) {
      this.DoSave()
    } else {
      this.DoUpdate()
    }

  }

  DoSave() {
    this.caretxSharedlService.createMedicalPrescription(this.medicalPrescription).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.medicalPrescription = new MedicalPrescriptionHeader(data)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.medicalPrescription = new MedicalPrescriptionHeader(data)
        this.router.navigateByUrl("medicalprescriptions/" + this.medicalPrescription.ID)

      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  DoUpdate() {
    this.caretxSharedlService.updateMedicalPrescription(this.medicalPrescription).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.medicalPrescription = new MedicalPrescriptionHeader(data)
        this.router.navigateByUrl("medicalprescriptions/" + this.medicalPrescription.ID)
       

      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  openPDFViewer() {
    this.stepper.selectedIndex = 1;
  }

  SetHeader(doc: jsPDF) {
    this.reportingService.AddFontDavidLibreRegular(doc);

    doc.setR2L(true)
    doc.setFontSize(12);
    let maxWidth = 40;
    let text02 = 'מס זהות:';
    let lines = ''

    var imgData = this.reportingService.GetTenantLogoInBase64()

    doc.addImage(imgData, 'JPEG', 175, 5, 25, 25);
    let x1 = 10
    let y1 = 40
    let x2 = 205
    let y2 = 40

    doc.setDrawColor(0, 0, 0);


    doc.line(x1, y1, x2, y2)
    doc.text(lines, 180, 20, { align: 'right' });

    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 195, 35, { align: 'right' });

    doc.setR2L(false)
    text02 = this.selectedMedicalFile.PersonelID;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 170, 35, { align: 'right' });
    //doc.rect(10, 30, 190, 20);

    doc.setR2L(true)
    text02 = this.selectedMedicalFile.FirstName + ' ' + this.selectedMedicalFile.LastName;
    maxWidth = 50;
    doc.setFontSize(12);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 120, 35, { align: 'right' });


    text02 = 'גיל:';
    maxWidth = 20;
    doc.setFontSize(12);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 60, 35, { align: 'right' });

    doc.setR2L(false)
    text02 = this.calculateAge().toString();
    maxWidth = 20;
    doc.setFontSize(12);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 50, 35, { align: 'right' });

    doc.setR2L(true)
    text02 = 'מין:';
    maxWidth = 20;
    doc.setFontSize(12);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 35, 35, { align: 'right' });

    text02 = this.selectedMedicalFile.Gender == "male" ? "ז" : "נ";
    maxWidth = 20;
    doc.setFontSize(12);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 25, 35, { align: 'right' });



    text02 = 'אור להורים - בית אבות';
    maxWidth = 50;
    doc.setFontSize(14);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 130, 15, { align: 'right' });


    text02 = 'מרשם תרופות';
    maxWidth = 50;
    doc.setFontSize(12);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 115, 20, { align: 'right' });




  }

  setRow(doc: jsPDF, row: MedicalPrescriptionLine, rowNum: number) {
    // TROFOT

    doc.setFontSize(12);
    let maxWidth = 40;
    let text02 = 'מס זהות:';
    let lines = ''

    let secondRowHeight = 60 + (rowNum * 25)
    let firstRowHeight = 50 + (rowNum * 25)



    doc.setR2L(false)
    text02 = row.Drug.ShortTradeName;
    maxWidth = 200;
    doc.setFontSize(11);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 10, firstRowHeight, { align: 'left' });



    doc.setR2L(false)
    text02 = row.Dose.toString();
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 185, secondRowHeight, { align: 'right' });


    doc.setR2L(true)
    // TROFOT
    text02 = row.DrugForm.Uom
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 178, secondRowHeight, { align: 'right' });




    text02 = "x"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 163, secondRowHeight, { align: 'right' });


    text02 = row.Frequency.toString();;
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 160, secondRowHeight, { align: 'right' });

    doc.setR2L(true)
    text02 = "ביום";
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 156, secondRowHeight, { align: 'right' });



    text02 = "למשך";
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 55, secondRowHeight, { align: 'right' });

    doc.setR2L(false)
    text02 = row.ForPeriodOf.toString();
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 45, secondRowHeight, { align: 'right' });

    doc.setR2L(true)

    text02 = "יום";
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 40, secondRowHeight, { align: 'right' });




    text02 = "סה\"\כ";
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 25, secondRowHeight, { align: 'right' });

    doc.setR2L(false)
    text02 = row.QtyToOrder.toString();
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 15, secondRowHeight, { align: 'right' });

    doc.setR2L(true)
    text02 = "יח";
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 10, secondRowHeight, { align: 'right' });

    let x1 = 5
    let y1 = secondRowHeight + 5
    let x2 = 200
    let y2 = secondRowHeight + 5

    doc.line(x1, y1, x2, y2)
  }

  setFooter(doc: jsPDF) {


    let footerHeight = doc.internal.pageSize.height - 70


    let x1 = 5
    let y1 = footerHeight
    let x2 = 200
    let y2 = footerHeight

    doc.line(x1, y1, x2, y2)


    doc.setR2L(true)
    let footerFirtRow = footerHeight + 10

    let text02 = "שם הרופא:"
    let maxWidth = 30;
    let lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 185, footerFirtRow, { align: 'right' });

    text02 = "ד\"\ר בסאם עבד אלפתאח"
    maxWidth = 50;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 165, footerFirtRow, { align: 'right' });


    text02 = "טלפון:"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 100, footerFirtRow, { align: 'right' });

    doc.setR2L(false)
    text02 = "04-8789555"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 75, footerFirtRow, { align: 'right' });

    doc.setR2L(true)

    let footersecondRow = footerHeight + 18
    text02 = "תחום:"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 185, footersecondRow, { align: 'right' });


    text02 = "רפואה גריאטרית"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 170, footersecondRow, { align: 'right' });


    text02 = "כתובת"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 100, footersecondRow, { align: 'right' });


    text02 = "גלגוליה רח 123"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 75, footersecondRow, { align: 'right' });




    doc.setR2L(false)
    var str = "Page " + doc.getCurrentPageInfo().pageNumber + " of " + (doc.internal.pages.length - 1);
    doc.setFontSize(10);// optional
    doc.text(str, 20, doc.internal.pageSize.height - 10);




  }

  calculateAge() {
    const bdate = new Date(this.selectedMedicalFile.BirthDay);
    const timeDiff = Math.abs(Date.now() - bdate.getTime());
    //Used Math.floor instead of Math.ceil
    //so 26 years and 140 days would be considered as 26, not 27.
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
  }

  closeSideNavePatient(medicalFile: MedicalFile) {
    this.selectedMedicalFile = medicalFile
    this.medicalPrescription.PersonelID = this.selectedMedicalFile.PersonelID
    this.selectedPatient = false
    this.enableAddPrescriptionBtn = true
    this.RetreivePatientMedicalDrugInstr("")
  }


  onDepartmentSelect($event) {
    this.filteredData = []
    this.showDepMandatoryLbl = false
    this.prepareFilterToSearchMedicalFiles()
  }

  onItemDeSelect($event) {
    this.filteredData = []
    if (this.selectedItems1.length == 0) {
      this.filteredData = []
      this.showDepMandatoryLbl = true
    }
    this.prepareFilterToSearchMedicalFiles()
  }

  onSelectingAllDepartments(items: any) {
    this.selectedItems1 = items
    this.showDepMandatoryLbl = false
    this.filteredData = this.allFiles
    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));
  }

  onDeSelectAll(items: any) {
    this.selectedItems1 = []
    this.filteredData = []
    this.showDepMandatoryLbl = true
    this.prepareFilterToSearchMedicalFiles()
  }

  prepareFilterToSearchMedicalFiles() {
    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));

    if (this.selectedItems1.length > 0) {
      this.allFiles.forEach(file => {
        const found = this.selectedItems1.some(x => x.ID === file.DepartmentId)
        if (found) this.filteredData.push(file)

      });
    }

  }



}
