
import { Diagnoses } from './diagnoses'
import { DrugInstruction } from './drugInstruction'
import { DrugSensitivity } from './drugsenstivity'
import { PersonalMeasuresHeader } from './measure'
import { MedicalAdmission } from './medicaladmission'
import { propertyMap } from './modelmapper'
import { PhysicalExamination } from './physicalexamination'
import { User } from './user'

// Need Server to send Payload in below structure
export class MedicalRelease {

    clientId: string
    @propertyMap('ID')
    ID: string 
    @propertyMap('RealmID')
    RealmID: string
    @propertyMap('PersonelId')
    PersonelId: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('ReasonForAdmission')
    ReasonForAdmission: string
    @propertyMap('CurrentDisease')
    CurrentDisease: string
    @propertyMap('BackGroundDisease')
    BackGroundDisease: string
    @propertyMap('HospitalizationProcess')
    HospitalizationProcess: string
    @propertyMap('MedicalRecommendations')
    MedicalRecommendations: string
    @propertyMap('Diagnoses')
    public Diagnoses: Diagnoses[]
    @propertyMap('PhysicalExamination')
    public PhysicalExamination: PhysicalExamination
    @propertyMap('PersonDrugSensitivity')
    public PersonDrugSensitivity: DrugSensitivity[]
    @propertyMap('DrugInstructions')
    public DrugInstructions:DrugInstruction[]
    @propertyMap('PersonalMeasuresHeader')
    public PersonalMeasuresHeader: PersonalMeasuresHeader
    @propertyMap('ContinueTherapy')
    public ContinueTherapy: boolean
    @propertyMap('ContinueTherapyComments')
    public ContinueTherapyComments: string
    @propertyMap('DoctorSummary')
    public DoctorSummary: string
    @propertyMap('Status')
    Status: string
    @propertyMap('Closed_at')
    public Closed_at: Date
    @propertyMap('ClosedById')
    public ClosedById: string
    @propertyMap('ClosedBy')
    public ClosedBy: User
    ShowDetails: boolean
    @propertyMap('LastMedicalAdmission')
    public LastMedicalAdmission: MedicalAdmission


    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelId = data["PersonelId"]
            this.RealmID = data["RealmID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.ReasonForAdmission = data["ReasonForAdmission"]
            this.CurrentDisease = data["CurrentDisease"]
            this.BackGroundDisease = data["BackGroundDisease"]
            this.HospitalizationProcess = data["HospitalizationProcess"]
            this.MedicalRecommendations = data["MedicalRecommendations"]
            this.ShowDetails = false
            this.Status = data["Status"]
            this.Diagnoses = data["Diagnoses"]
            this.PersonDrugSensitivity = data["PersonDrugSensitivity"]
            this.DrugInstructions=[]
            data["DrugInstructions"].forEach(element => {
                this.DrugInstructions.push(new DrugInstruction(element,data["PersonelId"],data["RealmID"]))
            });
            this.PersonalMeasuresHeader = data["PersonalMeasuresHeader"]
            this.PhysicalExamination = new PhysicalExamination(data["PhysicalExamination"])
            this.Closed_at   = data["Closed_at"]
            this.ClosedById   = data["ClosedById"]
            this.ClosedBy   = data["ClosedBy"]
            this.ContinueTherapy   = data["ContinueTherapy"]
            this.ContinueTherapyComments   = data["ContinueTherapyComments"]
            this.DoctorSummary   = data["DoctorSummary"]
            this.LastMedicalAdmission = data["LastMedicalAdmission"]

        }
        else {
            this.ShowDetails = false;
            this.clientId = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            this.CreatedAt = new Date()
            this.UpdatedAt = new Date()
            this.ReasonForAdmission = ""
            this.CurrentDisease = ""
            this.BackGroundDisease = ""
            this.HospitalizationProcess = ""
            this.MedicalRecommendations = ""
            this.ShowDetails = false
            this.Status = 'A'
            this.Diagnoses = []
            this.PersonDrugSensitivity = []
            this.PersonalMeasuresHeader = new PersonalMeasuresHeader()
            this.PersonalMeasuresHeader.PersonalMeasures = []
            this.DrugInstructions = []
            this.PhysicalExamination = new PhysicalExamination()
            this.ContinueTherapy   = false
            this.ContinueTherapyComments   = ""
            this.DoctorSummary   = ""
        }
    }
}
