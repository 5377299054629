import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Diagnoses } from '../../../../../../app/models/diagnoses';
import { MedicalDiagnoses } from '../../../../../../app/models/drug';
import { DiagnosesTypes } from '../../../../../../app/models/generalhousedsetups';
import { CartexEvent, TabHistory } from '../../../../../../app/models/genericTypes';
import { AuthService } from '../../../../../../app/services/auth.service';
import { DataService } from '../../../../../../app/services/data.service';
import { MedicalReleaseSteps } from '../../../../../../app/services/enums' //'src/app/services/enums';
import Swal from 'sweetalert2';
import { PersonaldiagnosesDialogComponent } from '../../../../../../../src/app/caretx-shared-module/CaretexModals/personaldiagnoses-dialog/personaldiagnoses-dialog.component';
import { MedicalDiagnosesProviderService } from '../../../../doctor-services/medical-diagnoses.data-provider.service';
import { ToasterService } from '../../../../../services/toastr.service';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-medicaldiagnoses',
  templateUrl: './medicaldiagnoses.component.html',
  styleUrls: ['./medicaldiagnoses.component.css']
})
export class MedicaldiagnosesComponent implements OnInit, OnDestroy {
  @ViewChild('mandatoryvalues') missingMandatoryValues: ElementRef;

  personelId: string
  @Input() DiagnosesList: Diagnoses[] = []

  @Output() diagnosesAdditionEvent = new EventEmitter<Diagnoses[]>();
  @Output() stopDiagnose = new EventEmitter<Diagnoses>();
  @Output() updateNextTab = new EventEmitter<string>();
  @Output() diagnosesDeletionEvent = new EventEmitter<number>();
  @Input() events: Observable<CartexEvent>;
  @Input() ShowActions: boolean = true;
  @Input() IsClosed: boolean = false;
  @Input() IsSubModule: boolean = false;
  @Input() OriginId: string ;
  @Input() showTitle: boolean = true
  @Input() GrayHeader: boolean = false
  
  DiagnosesTypes: DiagnosesTypes[] = []
  admissionId: string

  currentRowNumClickedModal: number
  private eventsSubscription: Subscription;
  private nextTab: string


  currentRowIndex: number

  constructor(private authServive: AuthService,
    public dialog: MatDialog,
    private dataservice: DataService,
    private toastrService: ToasterService,
    private medicalDiagnosesService: MedicalDiagnosesProviderService,
    private route: ActivatedRoute,
    private _ar: ActivatedRoute,) {
    this.DiagnosesTypes = this.dataservice.DiagnosesTypes
  }

  ngOnInit(): void {
    if (this.route.parent != null && this.route.parent != undefined) {
      this.personelId = this.route.parent.snapshot.params['id']
    }
    this.admissionId = this._ar.snapshot.params['admissionid'];
    if (this.events != undefined) {
      this.eventsSubscription = this.events.subscribe((data) => this.confirmUnSavedChanges(data));
    }

  }

  diagnosesEventHandler($event) {
    let medicalDiagnose: MedicalDiagnoses = new MedicalDiagnoses($event)
    this.DiagnosesList[this.currentRowNumClickedModal].MedicalDiagnoses = medicalDiagnose
  }



  deleteRow(i) {
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteDiagnosesLine(this.DiagnosesList[this.currentRowIndex])
      }
    })

  }

  deleteDiagnosesLine(diagnoses: Diagnoses) {
    this.medicalDiagnosesService.deletePeronelDiagnoses(this.personelId, diagnoses.ID).subscribe({
      next: data => {
        this.DiagnosesList.splice(this.currentRowIndex, 1);
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }


  ngOnDestroy() {
    this.diagnosesAdditionEvent.emit(this.DiagnosesList)
  }

  showAlertMsgOnMissingValues() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "אתה צריך למלאות כל השדות חובה",
      icon: 'error',
      showCancelButton: false,
      confirmButtonText: 'בסדר',
      allowOutsideClick: false,
      allowEscapeKey: false
    })
  }

  confirmUnSavedChanges(data: CartexEvent) {
    if ((data.EventData as unknown as TabHistory).CurrentTab == MedicalReleaseSteps.Diagnoses) {
      this.nextTab = (data.EventData as unknown as TabHistory).NextTab
      const result = this.DiagnosesList.some(row => {
        return (row.DiagnosesType.Id == null || row.DiagnosesType.Id == undefined || row.MedicalDiagnoses.ID == undefined || row.MedicalDiagnoses.ID
          == null)
      })
      if (result) {
        this.showAlertMsgOnMissingValues()
      }
      else {
        this.updateNextTab.emit(this.nextTab)
      }
    }
  }

  /**************************************************** */
  openDialog(diagnose?: Diagnoses, index?: number) {


    if (this.IsSubModule && this.OriginId == "new") {

      Swal.fire({
        title: "הודעת מערכת",
        text: "לפני שתוכל להכניס אבחנות אתה צריך לשמור קבלה קודם !",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'סגור',
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
    }else {


      var currDiagnose = new Diagnoses()
      if (diagnose == null || diagnose == undefined) {
        currDiagnose.UpdatedAt = new Date()
        currDiagnose.DoctorId = this.authServive.LoggedInUser.UID
        currDiagnose.Doctor = this.authServive.LoggedInUser
        currDiagnose.Doctor.UID = currDiagnose.DoctorId
        currDiagnose.RealmID = this.authServive.LoggedInTenantID
        currDiagnose.PersonelID = this.personelId
        currDiagnose.editMode = currDiagnose.isNew = true;
        currDiagnose.Type = "C"
        currDiagnose.MedicalDiagnoses = new MedicalDiagnoses()
        if (this.IsSubModule){
          currDiagnose.LinkedTo  = this.OriginId
          currDiagnose.CreatedFrom = "admission"
        }
        currDiagnose.Status = "A"
        currDiagnose.Modified = false
        currDiagnose.New = true
      } else {
  
      }
  
      //this.DiagnosesList.unshift(currDiagnose)
      const dialogRef = this.dialog.open(PersonaldiagnosesDialogComponent, {
        data: {
          newDiagnoses: (diagnose == null || diagnose == undefined) ? currDiagnose : diagnose,
          TypesOfDiagnoses: this.DiagnosesTypes,
          personelid: this.personelId,
          ActionAllowed: this.ShowActions,
          IsDisabled: (diagnose == null || diagnose == undefined) ? false : true,
          IsFromAdmission: this.IsSubModule,
          AdmissionId : this.OriginId
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        // Save Diagnoses
        if (result == undefined || result == null || result == "") return
        this.DiagnosesList = result
        this.diagnosesAdditionEvent.emit(this.DiagnosesList)
      });
      
    }


  }
}
