import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModuleRoutingModule } from './shared-module-routing.module';
import { FooterComponent } from './layouts/footer/footer.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { DefaultheaderComponent } from './layouts/defaultheader/defaultheader.component';
import { SideNavbarComponent } from './layouts/side-navbar/side-navbar.component';
import { AdminPanelHeaderComponent } from './layouts/admin-panel-header/admin-panel-header.component';
import { NotificationsComponent } from '../components/Common/notifications/notifications.component';
import { MainSideNavbarComponent } from './layouts/main-side-navbar/main-side-navbar.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from '../modal';
import { DisableDirective } from './CartexDirectives/DisableDirective';
import { SystemAlertComponent } from './CaretexModals/systemalert/systemalert.component';
import { WebcamModule } from 'ngx-webcam';
//import { PersonelmeasuresresultsComponent } from './CaretexModals/personelmeasuresresults/personelmeasuresresults.component';
import { FrequentMeasuresComponent } from './CaretexSharedComponents/frequent-measures/frequent-measures.component';
import { UserselectorformComponent } from './CaretexModals/userselectorform/userselectorform.component';
import { CaretxSharedModulesProviderService } from './sharedmodule.data-provider.services';
import { FileinfointitleComponent } from './CaretexSharedComponents/fileinfointitle/fileinfointitle.component';
import { TextareaAutoresizeDirective } from './caretex-directives/textarea-autoresize.directive';
import { PipesModule } from '../caretx-custompipes-module/custompipes.module';
import { PersonrlLabResultsComponent } from './CaretexModals/personrl-lab-results/personrl-lab-results.component';
import { NewDrugFormComponent } from './CaretexModals/new-drug-form/new-drug-form.component';
import { HouseDepartmentsComponent } from './CaretexModals/house-departments/house-departments.component';
import { NurseinstructionselectionmodalComponent } from './CaretexModals/nurseinstructionselectionmodal/nurseinstructionselectionmodal.component';
import { DrugDetailsComponent } from './CaretexSharedComponents/drug-details/drug-details.component';
import { DrugslistComponent } from './CaretexSharedComponents/drugslist/drugslist.component';
import { SensitivitiesComponent } from './CaretexSharedComponents/sensitivities/sensitivities.component';
import { DrugslookupComponent } from './CaretexModals/drugslookup/drugslookup.component';
import { FrequentmeauresmoduleComponent } from './CaretexSharedComponents/frequentmeauresmodule/frequentmeauresmodule.component';
import { NursephysicalcheckComponent } from './CaretexSharedComponents/nursephysicalcheck/nursephysicalcheck.component';
import { BuiltinsentencesComponent } from './CaretexSharedComponents/builtinsentences/builtinsentences.component';
import { CognitivetestcontrolComponent } from './CaretexSharedComponents/cognitivetestcontrol/cognitivetestcontrol.component';
import { CaretextextmodalComponent } from './CaretexModals/caretextextmodal/caretextextmodal.component'

import { PhysicalexaminationComponent } from './CaretexSharedComponents/physicalexamination/physicalexamination.component';
import { MedicalFileInfoComponent } from './CaretexSharedComponents/medical-file-info/medical-file-info.component';
import { LatestlabtestresultsComponent } from './CaretexSharedComponents/latestlabtestresults/latestlabtestresults.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ExceptionaleventslistComponent } from './CaretexSharedComponents/exceptionaleventslist/exceptionaleventslist.component'
import { ExceptionaleventComponent } from './CaretexSharedComponents/exceptionalevent/exceptionalevent.component'
import { ShareddailyfoodreportlistComponent } from './CaretexSharedComponents/shareddailyfoodreportlist/shareddailyfoodreportlist.component';
import { ShareddailyfoodreportComponent } from './CaretexSharedComponents/shareddailyfoodreport/shareddailyfoodreport.component';
import { CaretexMessagesComponent } from './CaretexModals/caretex-messages/caretex-messages.component';
import { CaretexListSearchBarComponent } from './CaretexSharedComponents/caretex-list-search-bar/caretex-list-search-bar.component';
import { GeneralsensitivitybannerComponent } from './CaretexSharedComponents/generalsensitivitybanner/generalsensitivitybanner.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PersondiagnoseslookupComponent } from './CaretexModals/persondiagnoseslookup/persondiagnoseslookup.component';
import { NextfollowupbannerComponent } from './CaretexSharedComponents/nextfollowupbanner/nextfollowupbanner.component';
import { FollowupsofalldepartmentsComponent } from './CaretexSharedComponents/followupsofalldepartments/followupsofalldepartments.component';
import { CtxTextareaComponent } from './ctx-textarea/ctx-textarea.component';
import { CaretexMessageComponent } from './CaretexSharedComponents/caretex-message/caretex-message.component'
import { DialogContentExampleDialog } from './CaretexModals/personelmeasuresresults/dialog-content-example-dialog'
import { PersonaldiagnosesDialogComponent } from './CaretexModals/personaldiagnoses-dialog/personaldiagnoses-dialog.component'
import { DiagnoseslookupComponent } from './CaretexModals/diagnoseslookup/diagnoseslookup.component'
import { HelperitemDialogComponent } from './CaretexModals/helperitem-dialog/helperitem-dialog.component'
import { UserselectionDialogComponent } from './CaretexModals/userselection-dialog/userselection-dialog.component'
import { DocViewerComponent } from './CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { AttachmentDialogComponent } from './CaretexModals/attachment-dialog/attachment-dialog.component';
import { FamilymemberdialogComponent } from './CaretexModals/familymemberdialog/familymemberdialog.component';
import { DrugInstructionActionDialogComponent } from './CaretexModals/drug-instruction-action-dialog/drug-instruction-action-dialog.component';
import { CameraDialogComponent } from './CaretexModals/camera-dialog/camera-dialog.component';
import { CtxNotificationMessageComponent } from './CaretexSharedComponents/ctx-notification-message/ctx-notification-message.component';
import { SensitivityDialogComponent } from './CaretexModals/sensitivity-dialog/sensitivity-dialog.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PatientsMedicationReviewComponent } from './CaretexSharedComponents/patients-medication-review/patients-medication-reviews.component';
import { PatientsMedicationReviewDetailComponent } from './CaretexSharedComponents/patients-medication-review/patients-medication-review-detail/patients-medication-review-detail.component';
import { PatientsListComponent } from './CaretexSharedComponents/patients-medication-review/patients-list/patients-list.component';
import { MedicalPrescriptionsComponent } from './CaretexSharedComponents/medical-prescriptions/medical-prescriptions.component';
import { PatientsDialogComponent } from './CaretexModals/patients-dialog/patients-dialog.component';
import { MedicalprescriptionDialogComponent } from './CaretexModals/medicalprescription-dialog/medicalprescription-dialog.component';
import { PhysicalexaminationlockedComponent } from './CaretexSharedComponents/physicalexamination/physicalexaminationlocked/physicalexaminationlocked.component';
import { ExceptionaleventlockedComponent } from './CaretexSharedComponents/exceptionaleventlocked/exceptionaleventlocked.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { CaretexTextareaDialogComponent } from './CaretexModals/caretex-textarea-dialog/caretex-textarea-dialog.component';
import { NursingMedicalTasksalertBannerComponent } from './CaretexSharedComponents/nursing-medical-tasksalert-banner/nursing-medical-tasksalert-banner.component';
import { NursemedicationreviewsComponent } from './CaretexSharedComponents/nursemedicationreviews/nursemedicationreviews.component';
import { HospitalizationslistComponent } from './CaretexSharedComponents/hospitalizationslist/hospitalizationslist.component';
import { HospitalizationDetailsComponent } from './CaretexSharedComponents/hospitalization-details/hospitalization-details.component';
import { PainMeasureDialogComponent } from './CaretexModals/pain-measure-dialog/pain-measure-dialog.component';
import { ReportslandingpageComponent } from './CaretexReports/reportslandingpage/reportslandingpage.component';
import { CaretexReport1Component } from './CaretexReports/reportslandingpage/caretex-report1/caretex-report1.component';
import { DieticianDialogFollowupsubjectComponent } from './CaretexModals/dietician-dialog-followupsubject/dietician-dialog-followupsubject.component';
import { ReadonlylabresultsComponent } from './CaretexSharedComponents/readonlylabresults/readonlylabresults.component';
import { BmiMeasureDialogComponent } from './CaretexModals/bmi-measure-dialog/bmi-measure-dialog.component';
import { DieticiankneeheightDialogComponent } from './CaretexModals/dieticiankneeheight-dialog/dieticiankneeheight-dialog.component';
import { MultipleoptionsselectionDialogComponent } from './CaretexModals/multipleoptionsselection-dialog/multipleoptionsselection-dialog.component';
import { ShowdefaulttextDialogComponent } from './CaretexModals/showdefaulttext-dialog/showdefaulttext-dialog.component';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NurseDialogFollowupsubjectComponent } from './CaretexModals/nurse-dialog-followupsubject/nurse-dialog-followupsubject.component';
import { FamilytalkslistComponent } from './CaretexSharedComponents/familytalkslist/familytalkslist.component';
import { FamilytalkdetailsComponent } from './CaretexSharedComponents/familytalkdetails/familytalkdetails.component';
import { HazardManagementDialogComponent } from './CaretexModals/hazard-managment-dialog/hazard-managment-dialog.component';
import { HazardDetialsPageComponent } from './CaretexSharedComponents/hazard-detials-page/hazard-detials-page.component';
import { VaccinationmodalComponent } from './CaretexModals/vaccinationmodal/vaccinationmodal.component';
import { FamilyvisitslistComponent } from './CaretexSharedComponents/familyvisitslist/familyvisitslist.component';
import { FamilyvisitdetailsComponent } from './CaretexSharedComponents/familyvisitdetails/familyvisitdetails.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MedicationInstructionsDialogComponent } from './CaretexModals/medication-instructions-dialog/medication-instructions-dialog.component';
import {MatChipsModule} from '@angular/material/chips';
import { HistoryofOmdanPainComponent } from './CaretexModals/historyof-omdan-pain/historyof-omdan-pain.component';


@NgModule({
    declarations: [
        SystemAlertComponent,
        DisableDirective,
        HeaderComponent,
        FooterComponent,
        LandingpageComponent,
        DefaultheaderComponent,
        SideNavbarComponent,
        AdminPanelHeaderComponent,
        NotificationsComponent,
        MainSideNavbarComponent,
        FrequentMeasuresComponent,
        UserselectorformComponent,
        FileinfointitleComponent,
        TextareaAutoresizeDirective,
        PersonrlLabResultsComponent,
        NewDrugFormComponent,
        HouseDepartmentsComponent,
        NurseinstructionselectionmodalComponent,
        DrugDetailsComponent,
        DrugslistComponent,
        SensitivitiesComponent,
        DrugslookupComponent,
        FrequentmeauresmoduleComponent,
        NursephysicalcheckComponent,
        BuiltinsentencesComponent,
        CognitivetestcontrolComponent,
        CaretextextmodalComponent,
        PhysicalexaminationComponent,
        MedicalFileInfoComponent,
        LatestlabtestresultsComponent,
        ExceptionaleventslistComponent,
        ExceptionaleventComponent,
        ShareddailyfoodreportlistComponent,
        ShareddailyfoodreportComponent,
        CaretexMessagesComponent,
        CaretexListSearchBarComponent,
        GeneralsensitivitybannerComponent,
        PersondiagnoseslookupComponent,
        NextfollowupbannerComponent,
        FollowupsofalldepartmentsComponent,
        CtxTextareaComponent,
        CaretexMessageComponent,
        DialogContentExampleDialog,
        PersonaldiagnosesDialogComponent,
        DiagnoseslookupComponent,
        HelperitemDialogComponent,
        UserselectionDialogComponent,
        DocViewerComponent,
        CtxNotificationMessageComponent,
        AttachmentDialogComponent,
        FamilymemberdialogComponent,
        DrugInstructionActionDialogComponent,
        CameraDialogComponent,
        SensitivityDialogComponent,
        PatientsMedicationReviewComponent,
        PatientsMedicationReviewDetailComponent,
        PatientsListComponent,
        MedicalPrescriptionsComponent,
        PatientsDialogComponent,
        MedicalprescriptionDialogComponent,
        PhysicalexaminationlockedComponent,
        ExceptionaleventlockedComponent,
        CaretexTextareaDialogComponent,
        NursingMedicalTasksalertBannerComponent,
        NursemedicationreviewsComponent,
        HospitalizationslistComponent,
        HospitalizationDetailsComponent,
        PainMeasureDialogComponent,
        ReportslandingpageComponent,
        CaretexReport1Component,
        DieticianDialogFollowupsubjectComponent,
        ReadonlylabresultsComponent,
        BmiMeasureDialogComponent,
        DieticiankneeheightDialogComponent,
        MultipleoptionsselectionDialogComponent,
        ShowdefaulttextDialogComponent,
        NurseDialogFollowupsubjectComponent,
        FamilytalkdetailsComponent,
        FamilytalkslistComponent,
        HazardManagementDialogComponent,
        HazardDetialsPageComponent,
        VaccinationmodalComponent,
        FamilyvisitslistComponent,
        FamilyvisitdetailsComponent,
        MedicationInstructionsDialogComponent,
        HistoryofOmdanPainComponent,
     
    ],
    imports: [
        CommonModule,
        FormsModule,
        ModalModule,
        PipesModule,
        WebcamModule,
        SharedModuleRoutingModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        NgMultiSelectDropDownModule,
        MatDatepickerModule,
        MatTableModule,
        MatIconModule,
        MatSidenavModule,
        MatToolbarModule,
        MatPaginatorModule,
        MatSortModule,
        MatButtonModule,
        MatSelectModule,
        MatDialogModule,
        MatInputModule,
        MatMenuModule,
        MatListModule,
        MatExpansionModule,
        MatStepperModule,
        PdfJsViewerModule,
        NgSelectModule,
        NgxSummernoteModule,
        MatCheckboxModule,
        MatRadioModule,
        MatChipsModule
       // MatDialogTitle,
       // MatDialogContent,
       // MatDialogActions,
      //  MatDialogClose,
       // MatDialog

    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        LandingpageComponent,
        DefaultheaderComponent,
        SideNavbarComponent,
        AdminPanelHeaderComponent,
        NotificationsComponent,
        MainSideNavbarComponent,
        DisableDirective,
        SystemAlertComponent,
        WebcamModule,
        FrequentMeasuresComponent,
        UserselectorformComponent,
        FileinfointitleComponent,
        TextareaAutoresizeDirective,
        PersonrlLabResultsComponent,
        NewDrugFormComponent,
        HouseDepartmentsComponent,
        NurseinstructionselectionmodalComponent,
        SensitivitiesComponent,
        DrugslookupComponent,
        FrequentmeauresmoduleComponent,
        NursephysicalcheckComponent,
        BuiltinsentencesComponent,
        CognitivetestcontrolComponent,
        CaretextextmodalComponent,
        PhysicalexaminationComponent,
        MedicalFileInfoComponent,
        MatDatepickerModule,
        LatestlabtestresultsComponent,
        ExceptionaleventslistComponent,
        ExceptionaleventComponent,
        ShareddailyfoodreportlistComponent,
        ShareddailyfoodreportComponent,
        CaretexListSearchBarComponent,
        GeneralsensitivitybannerComponent,
        PersondiagnoseslookupComponent,
        NextfollowupbannerComponent,
        HazardDetialsPageComponent,
        CtxTextareaComponent,
        DialogContentExampleDialog,
        PersonaldiagnosesDialogComponent,
        DiagnoseslookupComponent,
        UserselectionDialogComponent,
        DocViewerComponent,
        PainMeasureDialogComponent,
        AttachmentDialogComponent,
        FamilymemberdialogComponent,
        DrugInstructionActionDialogComponent,
        CameraDialogComponent,
        CaretexMessagesComponent,
        CaretexMessageComponent,
        CtxNotificationMessageComponent,
        SensitivityDialogComponent,
        PatientsDialogComponent,
        MedicalprescriptionDialogComponent,
        PhysicalexaminationlockedComponent,
        ExceptionaleventlockedComponent,
        CaretexTextareaDialogComponent,
        NursingMedicalTasksalertBannerComponent,
        DieticianDialogFollowupsubjectComponent,
        ReadonlylabresultsComponent,
        BmiMeasureDialogComponent,
        DieticiankneeheightDialogComponent,
        MultipleoptionsselectionDialogComponent,
        ShowdefaulttextDialogComponent,
        NurseDialogFollowupsubjectComponent,
        FamilytalkdetailsComponent,
        FamilytalkslistComponent,
        HazardManagementDialogComponent,
        VaccinationmodalComponent,
        FamilyvisitslistComponent,
        FamilyvisitdetailsComponent,
        MedicationInstructionsDialogComponent,
        HistoryofOmdanPainComponent
    ],
    providers: [CaretxSharedModulesProviderService],
    entryComponents: [HistoryofOmdanPainComponent,MedicationInstructionsDialogComponent,VaccinationmodalComponent,HazardManagementDialogComponent,NurseDialogFollowupsubjectComponent,ShowdefaulttextDialogComponent,MultipleoptionsselectionDialogComponent,DieticiankneeheightDialogComponent,  BmiMeasureDialogComponent,DieticianDialogFollowupsubjectComponent,PainMeasureDialogComponent,CaretexTextareaDialogComponent, MedicalprescriptionDialogComponent,PatientsDialogComponent,DocViewerComponent,SensitivityDialogComponent,CameraDialogComponent,DrugInstructionActionDialogComponent,FamilymemberdialogComponent,AttachmentDialogComponent, DialogContentExampleDialog,PersonaldiagnosesDialogComponent,HelperitemDialogComponent,UserselectionDialogComponent],
})
export class SharedModule { }
