import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationMap } from 'src/app/models/notificationMap';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-ctx-notification-message',
  templateUrl: './ctx-notification-message.component.html',
  styleUrls: ['./ctx-notification-message.component.css']
})
export class CtxNotificationMessageComponent implements OnInit {
// notifications: NotificationMap[] = [];
@Input() Notification : NotificationMap
@Output() removeNotificationEvent = new EventEmitter<NotificationMap>();


constructor( 
  private toastrService: ToasterService,  
  private authService: AuthService,) 
  { }

ngOnInit(): void {
}


removeNotification() {
    this.removeNotificationEvent.emit(this.Notification)
}
/* loadData() {
  let filter = "is_opened = 'N'"
  this.notificationsService.getNotifications(this.authService.LoggedInUser['UID'], this.authService.LoggedInUser['role'], filter).subscribe((data: NotificationMap[]) => {
    this.notifications = []
    data.forEach(element => {
      this.notifications.unshift(new NotificationMap(element))
    });



  }, error => {
    console.log(error);
    this.toastrService.Error("", error)
  });
}*/



}
