import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ModalService } from 'src/app/modal/modal.service';
import { DialogResult } from 'src/app/services/enums';


@Component({
  selector: 'app-commentwindow',
  templateUrl: './commentwindow.component.html',
  styleUrls: ['./commentwindow.component.css']
})
export class CommentwindowComponent implements OnInit {

  commentTxt: string
  hideModal:boolean = true;
  isEmpty:boolean

  @Input() title:string

  @Output() commentDialogEvent = new EventEmitter<EmitCommentObj>();
  @Output() commentTextEvent = new EventEmitter<string>();

  constructor(private modalService: ModalService) { }

  ngOnInit(): void {

  }

  submitCommentModal(dialogResult: DialogResult, id: string, $event) {

    this.isEmpty = false;
    if (dialogResult == DialogResult.OK) {
      if (this.commentTxt == "" || this.commentTxt == undefined) {
        //set style border to Red
        this.isEmpty = true;
        this.hideModal = false;
        return
      }
      else {
        let emitObj = new EmitCommentObj()
        emitObj.comment = this.commentTxt
        emitObj.result = dialogResult
        this.commentDialogEvent.emit(emitObj)
       // this.commentTextEvent.emit(this.commentTxt)

      }
    }
    this.hideModal = true;
    this.commentTxt = ""
  }


  onTextchange(){
    this.isEmpty = false;
  }
  public get dialogResults(): typeof DialogResult {
    return DialogResult;
  }


}

export class EmitCommentObj {
  comment :string
  result :DialogResult
}