import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, CanDeactivateGuard } from '../shared/guard/auth.guard';
import { BedsoresComponent } from './Components/bedsores/bedsores.component';
import { BhoseListComponent } from './examslandingpage/exams/bhose-list/bhose-list.component';
import { BohseComponent } from './examslandingpage/exams/bohse/bohse.component';
import { ExceptionEventsListComponent } from './Components/exception-events-list/exception-events-list.component';
import { FimComponent } from './examslandingpage/exams/fim/fim.component';
import { FimlistComponent } from './examslandingpage/exams/fimlist/fimlist.component';
import { NortonComponent } from './examslandingpage/exams/norton/norton.component';
import { NortonlistComponent } from './examslandingpage/exams/nortonlist/nortonlist.component';
import { NurseLabOrdersComponent } from './Components/nurse-lab-orders/nurse-lab-orders.component';
import { Omdan2Component } from './examslandingpage/exams/omdan2/omdan2.component';
import { Omdan2listComponent } from './examslandingpage/exams/omdan2list/omdan2list.component';
import { Omdan4Component } from './examslandingpage/exams/omdan4/omdan4.component';
import { Omdan4listComponent } from './examslandingpage/exams/omdan4list/omdan4list.component';
import { Omdan5Component } from './examslandingpage/exams/omdan5/omdan5.component';
import { Omdan5listComponent } from './examslandingpage/exams/omdan5list/omdan5list.component';
import { PersonalMeasuresComponent } from './Components/personalmeasureslist/personal-measures/personal-measures.component';
import { SnaqComponent } from './examslandingpage/exams/snaqlist/snaq/snaq.component';
import { SnaqlistComponent } from './examslandingpage/exams/snaqlist/snaqlist.component';
import { ExamslandingpageComponent } from './examslandingpage/examslandingpage.component';
import { NurseadmissionComponent } from './Components/nursingadmissionlist/nurseadmission/nurseadmission.component';
import { BhosesubmodelComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/bhosesubmodel/bhosesubmodel.component';
import { NursingadmissionlistComponent } from './Components/nursingadmissionlist/nursingadmissionlist.component';
import { PersonalmeasureslistComponent } from './Components/personalmeasureslist/personalmeasureslist.component';
import { NurseexaminationslistComponent } from './Components/nurseexaminationslist/nurseexaminationslist.component';
import { NursuryphysicalexaminationComponent } from './Components/nurseexaminationslist/nursuryphysicalexamination/nursuryphysicalexamination.component';
import { BedSoresListComponent } from './Components/bedsores/bed-sores-list/bed-sores-list.component';
import { NurseanchorComponent } from './nurseanchor/nurseanchor.component';
import { NursetasksComponent } from './Components/nursetasks/nursetasks.component';
import { NursingreleaseComponent } from './Components/nursingrelease/nursingrelease.component';
import {  CognitiveTestsComponent } from './Components/cognitice-tests/cognitice-tests.component';
import { NursecognitivetestslistComponent } from './Components/nursecognitivetestslist/nursecognitivetestslist.component';
import { NursingfollowupComponent } from './Components/nursingfollowuplist/nursingfollowup/nursingfollowup.component';
import { ExceptionaleventComponent } from '../caretx-shared-module/CaretexSharedComponents/exceptionalevent/exceptionalevent.component';
import { NursedailyfoodreportlistComponent } from './Components/nursedailyfoodreportlist/nursedailyfoodreportlist.component';
import { NursedailyfoodreportComponent } from './Components/nursedailyfoodreport/nursedailyfoodreport.component';
import { ExceptionaleventlockedComponent } from '../caretx-shared-module/CaretexSharedComponents/exceptionaleventlocked/exceptionaleventlocked.component';
import { NurseingAdmissionLockedComponent } from './Components/nursingadmissionlist/nurseing-admission-locked/nurseing-admission-locked.component';
import { NursingmedicationsComponent } from './Components/nursingmedications/nursingmedications.component';
import { DiabeticFootTestComponent } from './examslandingpage/exams/diabetic-foot-test/diabetic-foot-test.component';
import { DiabetictestslistComponent } from './examslandingpage/exams/diabetictestslist/diabetictestslist.component';
import { ExternalnurseadmissionComponent } from './Components/nursingadmissionlist/nurseadmission/externalnurseadmission/externalnurseadmission.component';
import { CenrtalizednusrsingfollowupComponent } from './Components/CaretexNusrsingGeneral/cenrtalizednusrsingfollowup/cenrtalizednusrsingfollowup.component';
import { OmdanVasFpsPainListComponent } from './examslandingpage/exams/omdan-vas-fps-pain-list/omdan-vas-fps-pain-list.component';
import { OmdanVasFpsPainComponent } from './examslandingpage/exams/omdan-vas-fps-pain/omdan-vas-fps-pain.component';
import { VaccinationsListComponent } from './Components/vaccinations-list/vaccinations-list.component';
import { NursingFamilyVisitsListComponent } from './Components/nusrsingfamilyvistslist/nursingfamilyvisitslist.component';
import { FamilyvisitdetailsComponent } from '../caretx-shared-module/CaretexSharedComponents/familyvisitdetails/familyvisitdetails.component';
import { FamilytalkdetailsComponent } from '../caretx-shared-module/CaretexSharedComponents/familytalkdetails/familytalkdetails.component';
import { NursefamilyconversationslistComponent } from './Components/nursefamilyconversationslist/nursefamilyconversationslist.component';
import { NursereleaselistComponent } from './Components/nursereleaselist/nursereleaselist.component';

const routes: Routes = [

  {
    path: 'caretexfile/:id/nursefoodreport',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: NursedailyfoodreportlistComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'recordid:tabid',
        component: NursedailyfoodreportComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':recordid',
        component: NursedailyfoodreportComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
    ]
  },

  {
    path: 'caretexfile/:id/nurseadmission',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: NursingadmissionlistComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },


      {
        path: ':admissionid/step/:tabid', 
        component: NurseadmissionComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },


      {
        path: ':admissionid',
        component: NurseadmissionComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

      {
        path: ':admissionid/locked',
        component: NurseingAdmissionLockedComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },


    ]

  },


  { 
    path: 'caretexfile/:id/externalnurseadmission', 
    component: NurseanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: NursingadmissionlistComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':admissionid', 
        component: ExternalnurseadmissionComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
    ]
  },




  {
    path: 'caretexfile/:id/nurseingfollowup',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: NursingfollowupComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'list/step/:stepid', 
        component: NursingfollowupComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'step/:stepid',
        component: NursingfollowupComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'step/:stepid/:measureheaderid',
        component: NursingfollowupComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

    ]

  },

  {
    path: 'caretexfile/:id/nurserelease',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: NursereleaselistComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':releaseid',
        component: NursingreleaseComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

    ]

  },

  {
    path: 'caretexfile/:id/nursetasks',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: NursetasksComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },


    ]

  },

  {
    path: 'caretexfile/:id/cognitivetest',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: NursecognitivetestslistComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':recordid',
        component: CognitiveTestsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

    ]

  },

  {
    path: 'caretexfile/:id/personelmeasures',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: PersonalmeasureslistComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':headerid',
        component: PersonalMeasuresComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

    ]

  },

  {
    path: 'caretexfile/:id/eventnursing',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: ExceptionEventsListComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':eventid',
        component: ExceptionaleventComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':eventid/locked',
        component: ExceptionaleventlockedComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

    ]
  },

  {
    path: 'caretexfile/:id/nurselaborders',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: NurseLabOrdersComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      }
    ]
  },

  {
    path: 'caretexfile/:id/omdanem',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: ExamslandingpageComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

      {
        path: 'bohse/list',
        component: BhoseListComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

      {
        path: 'bohse/:bhoseid', 
        component: BohseComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
     },
      {
        path: 'subhose/:bhoseid',
        component: BhosesubmodelComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'omdan2/list',
        component: Omdan2listComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'omdan2/:omdanid',
        component: Omdan2Component,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'snaq/list',
        component: SnaqlistComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'snaq/:snaqid',
        component: SnaqComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'fim/list',
        component: FimlistComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'fim/:fimid',
        component: FimComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'omdan5/list',
        component: Omdan5listComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'omdan5/:omdan5id',
        component: Omdan5Component,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'norton/list',
        component: NortonlistComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'norton/:nortonid',
        component: NortonComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'omdan4/list',
        component: Omdan4listComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'omdan4/:omdan4id',
        component: Omdan4Component,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },{
        path: 'omdan4/list',
        component: Omdan4listComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'omdan4/:omdan4id',
        component: Omdan4Component,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },


      {
        path: 'diabetictests/list',
        component: DiabetictestslistComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'diabetictests/:diabetictestid',
        component: DiabeticFootTestComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      }
    ]
  },
 
  {
    path: 'caretexfile/:id/bedsores',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: BedSoresListComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':bedsoreid',
        component: BedsoresComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

      {
        path: ':bedsoreid/step/:stepid', 
        component: BedsoresComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

    ]

  },

  {
    path: 'caretexfile/:id/physicalnursecheck',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: NurseexaminationslistComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':checkid',
        component: NursuryphysicalexaminationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

    ]

  },


  {
    path: 'caretexfile/:id/nurseingmedication',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: NursingmedicationsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },


    

    ]

  },



  { path: 'nursinggeneral', component: CenrtalizednusrsingfollowupComponent, canActivate: [AuthGuard] },
  { path: 'nursinggeneral/followup', component: CenrtalizednusrsingfollowupComponent, canActivate: [AuthGuard] },





  {
    path: 'caretexfile/:id/vasfps',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: OmdanVasFpsPainListComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':vasfpsid',
        component: OmdanVasFpsPainComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
    ]

  },


  {
    path: 'caretexfile/:id/vaccines',
    component: NurseanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: VaccinationsListComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

    ]

  },






  { 
    path: 'caretexfile/:id/nursefamilyvisit', 
    component: NurseanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: NursingFamilyVisitsListComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':visitid', 
        component: FamilyvisitdetailsComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':visitid/step/:stepid', 
        component: FamilyvisitdetailsComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
 
    ]
  },


  { 
    path: 'caretexfile/:id/nursefamilymeeting', 
    component: NurseanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: NursefamilyconversationslistComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':conversationid', 
        component: FamilytalkdetailsComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':conversationid/step/:stepid', 
        component: FamilytalkdetailsComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
 
    ]
  },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NusrsingModuleRoutingModule { }
