import { Component, OnInit } from '@angular/core';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';


@Component({
  selector: 'app-dailyfoodintake',
  templateUrl: './dailyfoodintake.component.html',
  styleUrls: ['./dailyfoodintake.component.css']
})
export class DailyfoodintakeComponent extends CaretexComponent  implements OnInit {

  constructor( public medicalFileService: MedicalFileProviderService, ) { super(medicalFileService);}

  ngOnInit(): void {

  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }
}
