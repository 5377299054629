import { Component, OnInit } from '@angular/core';
import { sideNaveGroups } from 'src/app/services/enums';
import { CommonInterface } from 'src/app/shared/SharedInterface';

@Component({
  selector: 'app-physiotherapyanchor',
  templateUrl: './physiotherapyanchor.component.html',
  styleUrls: ['./physiotherapyanchor.component.css']
})
export class PhysiotherapyanchorComponent implements OnInit,CommonInterface {
  TabId:string = "medicalfiles"
  constructor() { }
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Physiotherapy;

  ngOnInit(): void {
  }

}