import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DieticianFollowUp } from '../../../../models/DieticianFollowup';
import { PagenationObject } from   '../../../../../../src/app/models/pagenation'  /* 'src/app/models/pagenation'*/;



@Component({
  selector: 'app-historicfollowups',
  templateUrl: './historicfollowups.component.html',
  styleUrls: ['./historicfollowups.component.css']
})
export class HistoricfollowupsComponent implements OnInit {

  @Input() HistoricRecords: DieticianFollowUp[]
  @Input() Pagenation: PagenationObject
  @Output() PageNumSelectionEvent = new EventEmitter<string>();
  public editorDisabled = true;


  public config: any = {
    airMode: false,
    tabDisable: true,

    height: '200px',

    toolbar: [

    ],

  };


    /*Pagination*//////////////////
    currentPage: number = 1
    pages: number[]
    page1: number = 1
    page2: number = 2
    page3: number = 3
    selectedPage: number = -1
    pageSize: number = 5
    pageCount: number = 1
  
    ///////////////////////////////

  constructor() {
  }
  ngOnInit(): void {
    this.pageCount = Math.ceil(this.Pagenation.TotalNumOfPages / this.pageSize)
    if (this.pageCount == 0) {
      this.pageCount = 1
    }
    this.pages = Array.from({ length: this.pageCount }, (_, i) => i + 1)
    this.selectedPage = 1
  }

  SelectPage($event) {
    this.selectedPage = $event
    this.PageNumSelectionEvent.emit(this.selectedPage.toString())
  }
  PrevPage() {
    
      if (this.selectedPage == this.pages[0]) {

        if (this.pages[0] - 1 >= 1) {
          for (let i = 0; i < this.pages.length; i++) {
            this.pages[i] -=1
           }
           this.selectedPage = this.pages[0]
        }
      } else if(this.selectedPage > this.pages[0]) {
        this.selectedPage -= 1
      }
   
    this.PageNumSelectionEvent.emit(this.selectedPage.toString())

  }
  NextPage() {
    if (this.pageCount > 3) {
      if (this.selectedPage == this.pages[2]) {

        if (this.pages[2] + 1 <= this.pageCount) {
          for (let i = 0; i < this.pages.length; i++) {
            this.pages[i] +=1
           }
           this.selectedPage = this.pages[2]
        }
      } else if(this.selectedPage < this.pages[2]) {
        this.selectedPage += 1
      }
    }else{
      if (this.selectedPage < this.pageCount){
        this.selectedPage += 1
      }
    }
    this.PageNumSelectionEvent.emit(this.selectedPage.toString())
  }


}
