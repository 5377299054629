import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { SocialPeriodReport } from '../../../app/models/socialPeriodReport';
import { AuthService } from '../../../app/services/auth.service';
import { SocialAdmissionSteps } from '../../../app/services/enums';
import { ToasterService } from '../../../app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSocialProviderService } from '../Social.data-provider.service';
import { CaretexComponent } from '../../../app/shared/CaretexComponent';
import { CartexEvent, TabHistory } from '../../models/genericTypes';

@Component({
  selector: 'app-annualreport',
  templateUrl: './annualreport.component.html',
  styleUrls: ['./annualreport.component.css']
})
export class AnnualreportComponent extends CaretexComponent implements OnInit {
  id: string
  Annualid: string
  @ViewChild('mainForm') mainFormElement: NgForm;
  //CurrentStep: SocialAdmissionSteps = SocialAdmissionSteps.History
  // canSocialPagesBeEditted: boolean
  isrecordClosed: boolean
  moduleName: string = "דוח סוציאלי שנתי"
  tabHistory: TabHistory = new TabHistory()
  caretexEvent: CartexEvent = new CartexEvent()
  step: number = 0

  currentSocialPeriodReport: SocialPeriodReport = new SocialPeriodReport()
  constructor(public medicalFileService: MedicalFileProviderService,
    private _title: Title,
    private router: Router,
    private route: ActivatedRoute,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private _ar: ActivatedRoute,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private caretxSetupsProviderService: CaretxSetupsProviderService) { super(medicalFileService) }

  ngOnInit(): void {
    this._title.setTitle('Caretex |דוח סוציאלי תקופתי');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
    this.Annualid = this.route.snapshot.params.reportid

    if (this.route.snapshot.params['tabid'] != "" && this.route.snapshot.params['tabid'] != undefined) {
      this.step = Number(this.route.snapshot.params['tabid']);

    }

    if (this.Annualid == 'new') {
      this.currentSocialPeriodReport = new SocialPeriodReport()
      this.currentSocialPeriodReport.PersonelID = this.id
      this.currentSocialPeriodReport.Status = "A"
      this.isrecordClosed = false
      this.currentSocialPeriodReport.CreatedById = this.authServive.LoggedInUser.UID

    }
    else {
      this.getSocialAnnualRepFromDB()
    }
  }

  getSocialAnnualRepFromDB() {
    let filter = '(id=' + '\'' + this.Annualid + '\'' + ')'
    this.caretxSocialProviderService.getSocialAnnual(this.id, filter).subscribe((data: SocialPeriodReport[]) => {
      if (data.length == 1) {
        this.currentSocialPeriodReport = new SocialPeriodReport(data[0])
        if (this.currentSocialPeriodReport.Status == "C") {
          this.isrecordClosed = true
        } else
          this.isrecordClosed = false
      }
      else if (data.length == 0) {
        let errorMsg = "המערכת לא הצליחה לייצר רשומה חדשה"
        this.toastrService.Error("", errorMsg)
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }


  setStep(index: number) {
    this.step = index;
  }
 /* selectTab(tabId: string) {
    if (this.CheckIfDocumentIsDirty()) {
      this.Save()
    } else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
    }
    this.CurrentStep = (tabId as SocialAdmissionSteps)
  }*/

  LockDocument() {
    if (this.currentSocialPeriodReport.Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה לנעול רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תנעל',
        allowOutsideClick: false,
        allowEscapeKey: false,       
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DoLock()
        }
      })
    }
  }

  DoLock() {
    this.currentSocialPeriodReport.Status = "C"
    this.currentSocialPeriodReport.ClosedById = this.authServive.LoggedInUser.UID
    this.currentSocialPeriodReport.Closed_at = new Date()
    this.DoUpdate()
  }

  Save() {
    if (this.Annualid == 'new') {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    this.currentSocialPeriodReport.UpdatedAt = new Date()
    this.currentSocialPeriodReport.CreatedAt = this.currentSocialPeriodReport.UpdatedAt
    this.currentSocialPeriodReport.CreatedById = this.authServive.LoggedInUser.UID
    this.currentSocialPeriodReport.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.createSocialAnnual(this.currentSocialPeriodReport).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentSocialPeriodReport = new SocialPeriodReport(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialperiodicalreport/" + this.currentSocialPeriodReport.ID + "/step/" + this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    this.currentSocialPeriodReport.UpdatedAt = new Date()
    this.currentSocialPeriodReport.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.UpdateSocialAnnual(this.currentSocialPeriodReport).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentSocialPeriodReport = new SocialPeriodReport(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialperiodicalreport/" + this.currentSocialPeriodReport.ID + "/step/" + this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  BackToList() {
    this.router.navigateByUrl("/caretexfile/" + this.id + "/socialperiodicalreport")
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
}


