import { Component, OnInit } from '@angular/core';
import { MeasuresTypes } from '../../../models/generalhousedsetups';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { CaretexComponent } from '../../../shared/CaretexComponent';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../services/toastr.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-essential-metrics-types',
  templateUrl: './essential-metrics-types.component.html',
  styleUrls: ['./essential-metrics-types.component.css']
})
export class EssentialMetricsTypesComponent extends CaretexComponent implements OnInit {

 
  TabId:string = "main"
  measuresTypes:MeasuresTypes[]  = []
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {   super(medicalFileServive)}

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getMeasuresTypeFromDB()
  }



  getMeasuresTypeFromDB() {
    this.caretxSetupsProviderService.GetMeasuresTypes(this.authServive.LoggedInTenantID).subscribe(
      data => {
        this.measuresTypes = data
      },
      err => {
        this.measuresTypes = []

      }
    )
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  
  CheckIfDocumentIsDirty() {
    const eventTypesLisChanged = this.measuresTypes.some(row => {
      return row.New || row.Modified
    })
    return  eventTypesLisChanged
  }


  BackToList(){
    this.router.navigate(['system_setups']);
  }

  addNewEvent(){
    var newEventType = new MeasuresTypes()
    newEventType.New = true
    newEventType.editMode = true
    newEventType.CreatedAt = new Date()
    newEventType.UpdatedAt = new Date()
    newEventType.TenantID = this.authServive.LoggedInTenantID
    this.measuresTypes.unshift(newEventType)
  }

  deleteRow(i){
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.measuresTypes[i].New){
          this.caretxSetupsProviderService.DeleteEventType( this.measuresTypes[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.measuresTypes.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
              this.getMeasuresTypeFromDB()
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.measuresTypes.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i){
    this.measuresTypes[i].editMode = true
    this.measuresTypes[i].Modified = true
  }
  cancelEditRow(i){
    this.measuresTypes[i].editMode  = false;
    this.measuresTypes[i].Modified = false
  }

  updateEvent($event){
    this.caretxSetupsProviderService.CreateMeasuresTypes(this.measuresTypes).subscribe({
     next: data => {

       this.toastrService.Sucesss("", this.toastrService.successMsg)
       this.getMeasuresTypeFromDB()
     },
     error: error => {
       this.toastrService.Error("", this.toastrService.errorMsg)
     }
   })  
 }

}
