import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSocialProviderService } from 'src/app/caretx-social-module/Social.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalFile } from 'src/app/models/customer';
import { FamilyConversation } from 'src/app/models/socialfamilyvisits';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-familytalkdetails',
  templateUrl: './familytalkdetails.component.html',
  styleUrls: ['./familytalkdetails.component.css']
})
export class FamilytalkdetailsComponent extends CaretexComponent implements OnInit {
  isrecordClosed: boolean
  currentFamilyConveration: FamilyConversation = new FamilyConversation()
  id: string
  conversationid: string
  @ViewChild('mainform') mainFormElement: NgForm;
  step: number = 0

  @Output() backToCentralPage = new EventEmitter<void>();
  @Input() PersonalId: string
  @Input() IsFromCentralizedPage: boolean = false
  @Input() SelectedFile: MedicalFile
  @Input() RecordId: string
  Origin:string
  

  moduleName: string = "שיחה עם משפחה" 
  constructor(private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private router: Router,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,
    private authServive: AuthService) {
    super(medicalFileService)
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex |שיחה עם משפחה');
    if (this.IsFromCentralizedPage) {
      this.id = this.PersonalId
      this.conversationid = this.RecordId
    }else {
   
      if(this.route.parent != null)
        this.id = this.route.parent.snapshot.params['id'];
      this.conversationid = this.route.snapshot.params.conversationid
      if (this.route.parent.snapshot.url[2].path == "nursefamilymeeting") this.Origin = "nurse"
      if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
        this.step = Number(this.route.snapshot.params['stepid']);
      }
      
    }

    this.CheckIfComponentIsDisabled(this.id)

    if ( this.conversationid == 'new' ||  this.conversationid == "") {
      this.getConversationFromDB(this.id,  this.conversationid, "new")

    } else {
      this.getConversationFromDB(this.id,  this.conversationid, "")

    }
  }



  getConversationFromDB(id: string, visitid: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + visitid + '\'' + ')'

    this.caretxSocialProviderService.getFamilyConverstions(this.id, filter, mode).subscribe((data: FamilyConversation[]) => {
      if (data.length == 1) {
        this.currentFamilyConveration = new FamilyConversation(data[0])

        if (this.conversationid == 'new' || this.conversationid == "") {
          this.currentFamilyConveration.PersonelID = this.id
          this.currentFamilyConveration.Status = "A"
          this.currentFamilyConveration.CreatedById = this.authServive.LoggedInUser.UID
        }
        if (this.currentFamilyConveration.Status == "C") {
          this.isrecordClosed = true

        } else
          this.isrecordClosed = false
      }
      else if (data.length == 0) {
        let errorMsg = "המערכת לא הצליחה לייצר רשומה חדשה"
        this.toastrService.Error("", errorMsg)
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }


  setStep(index: number) {
    this.step = index;
  }



  BackToList() {


    if(this.Origin == "nurse") {
      this.router.navigateByUrl("/caretexfile/" + this.id + "/nursefamilymeeting")
    }else{
      this.router.navigateByUrl("/caretexfile/" + this.id + "/family_conversation")
    }



  }

  LockDocument() {
    if (this.currentFamilyConveration.Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למעול רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תנעל',
        allowOutsideClick: false,
        allowEscapeKey: false,       
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DoLock()
        }
      })
    }
  }

  DoLock() {
    this.currentFamilyConveration.Status = "C"
    this.currentFamilyConveration.ClosedById = this.authServive.LoggedInUser.UID
    this.currentFamilyConveration.ClosedAt = new Date()
    this.DoUpdate()

  }

  Save() {
    this.currentFamilyConveration.TalkDate = new Date(this.currentFamilyConveration.TalkDate)
    // convert Time to datetime

    let yy: number = new Date().getFullYear()
    let mm: number = new Date().getMonth()
    let dd: number = new Date().getDate()

    let Hours:number =  Number(this.currentFamilyConveration.TalkTime.toString().split(':')[0])
    let Minutes:number =  Number(this.currentFamilyConveration.TalkTime.toString().split(':')[1])
    let time : Date = new Date(yy, mm, dd, Hours, Minutes, 0)
    this.currentFamilyConveration.TalkTime = time

    if (this.conversationid == 'new' || this.conversationid == "") {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    this.currentFamilyConveration.UpdatedAt = new Date()
    this.currentFamilyConveration.CreatedAt = this.currentFamilyConveration.UpdatedAt
    this.currentFamilyConveration.CreatedById = this.authServive.LoggedInUser.UID
    this.currentFamilyConveration.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.createFamilyConverstion(this.currentFamilyConveration).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentFamilyConveration = new FamilyConversation(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
       if (!this.IsFromCentralizedPage) {
        this.router.onSameUrlNavigation = 'reload';

        if(this.Origin == "nurse") {
          this.router.navigateByUrl("caretexfile/" + this.id + "/nursefamilymeeting/" + this.currentFamilyConveration.ID+"/step/"+this.step)
        }else{
          this.router.navigateByUrl("caretexfile/" + this.id + "/family_conversation/" + this.currentFamilyConveration.ID+"/step/"+this.step)
        }

       }
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    this.currentFamilyConveration.UpdatedAt = new Date()
    this.currentFamilyConveration.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.UpdateFamilyConverstion(this.currentFamilyConveration).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentFamilyConveration = new FamilyConversation(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        if (!this.IsFromCentralizedPage) {
        this.router.onSameUrlNavigation = 'reload';
        if(this.Origin == "nurse") {
          this.router.navigateByUrl("caretexfile/" + this.id + "/nursefamilymeeting/" + this.currentFamilyConveration.ID+"/step/"+this.step)
        }else{
          this.router.navigateByUrl("caretexfile/" + this.id + "/family_conversation/" + this.currentFamilyConveration.ID+"/step/"+this.step)
        }
        }
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return  this.mainFormElement.dirty == null?false:this.mainFormElement.dirty
  }

  ReturnToCentralPage() {
    this.backToCentralPage.emit()
  }

}
