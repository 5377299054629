import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


@Injectable()
export class DrugsService {
  constructor(private httpClient: HttpClient) {}

  getDrugsList() {
    return this.httpClient.get(environment.gateway + '/drugs');
  }


  getDiagnosesList() {
    return this.httpClient.get(environment.gateway + '/diagnoses');
  }

}