import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { Departments } from '../../../../../src/app/models/generalhousedsetups';
import { SocialTitle, User, UserDepartmentsPermession, UserPermession } from '../../../../../src/app/models/user';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { UserInfoTabs } from '../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-userinfo',
  templateUrl: './userinfo.component.html',
  styleUrls: ['userinfo.component.css', 'userinfo.component.scss']
})
export class UserinfoComponent implements OnInit {
  @ViewChild('Userform1') Userform1: NgForm;
  SelectedUser: User
  filteredPermessions: UserPermession[] = []
  filteredPermessionsForSearch : UserPermession[] = []
  departments: Departments[]
  socialTitles: SocialTitle[]
  userId: string
  editPageInfo: boolean = false
  CurrentStep: UserInfoTabs = UserInfoTabs.UserProfile
  currentDepPermRowIndex: number
  showDepartmentModal: boolean = false;
  enableEdit = false;
  formLoader = false;
  IsNew = false
  profileImgUrl: any = 'assets/images/user-avatar.png';
  profileImgTemp: any = 'assets/images/user-avatar.png';
  profileImgFile: any;
  currentSelectedCategory:string = ""
  newProfilePicSubject: Subject<void> = new Subject<void>();
  TabId:string ="main"

  constructor(
    private router: Router,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private toastrService: ToasterService,
    private _ar: ActivatedRoute,) { }

  ngOnInit(): void {
    this.userId = this._ar.snapshot.params['id'];
  if (this.userId !='new'){
    this.getUserInfo()
  }else {
    this.SelectedUser = new User()
    this.enableEdit = true
    this.IsNew = true
    this.SelectedUser.Title =  new SocialTitle()
    this.SelectedUser.Title.SocialTitle=""
  }

    this.getDepartmentsFromDB()
    this.getSocialTitlesFromDB()
  }



  onSearchChange(searchValue: string): void {  
    this.filteredPermessions  = this.filteredPermessionsForSearch
    this.filteredPermessions = this.filteredPermessionsForSearch.filter(
      row => row.CaretexComponent.ComponentName.includes(searchValue));
  
  }

  getUserInfo() {
    let filter = '(uid=' + '\'' + this.userId + '\'' + ')'
    this.authServive.getUsers2(filter).subscribe((data: User[]) => {
      if (data.length == 1) {
        this.SelectedUser = new User(data[0])
        if (this.SelectedUser.photoURL == "" || this.SelectedUser.photoURL == null || this.SelectedUser.photoURL == undefined) {
          // this.SelectedUser.photoURL = "../../../../assets/images/user-avatar.png"
          this.profileImgTemp = this.profileImgUrl;
        } else {
          this.profileImgTemp = this.SelectedUser.photoURL;
        }

        this.filteredPermessions = this.SelectedUser.UserPermessions.sort((a, b) =>
          (a.CaretexComponent.Sequence < b.CaretexComponent.Sequence ? -1 : 1))


      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }

  getDepartmentsFromDB() {

    this.caretxSetupsProviderService.GetHouseDepartments().subscribe(
      data => {
        this.departments = data
      },
      err => {
        console.log("Error", err)
        this.departments = []

      }
    )
  }

  getSocialTitlesFromDB() {
    this.caretxSetupsProviderService.getSocialTitles().subscribe(
      data => {
        this.socialTitles = data
      },
      err => {
        console.log("Error", err)
        this.socialTitles = []

      }
    )
  }

  selectTab(tabId: string) {
    if (tabId == "B") this.filteredPermessions = []
    this.CurrentStep = (tabId as UserInfoTabs)
  }

  submitForm() {
    this.Userform1.onSubmit(new Event("general"));
  }

  saveUserProfile() {
    this.formLoader = true;
    if (this.IsNew) {
      this.SelectedUser.displayName = this.SelectedUser.firstName +' ' + this.SelectedUser.lastName

      this.authServive.createNewUserInTenant(this.SelectedUser).subscribe(data => {
        // do something, if upload success
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.IsNew= false
        this.SelectedUser = new User(data)
   
        this.filteredPermessions = this.SelectedUser.UserPermessions.sort((a, b) =>
          (a.CaretexComponent.Sequence < b.CaretexComponent.Sequence ? -1 : 1)
        );
        this.DoFilter()
  
      }, error => {
        this.formLoader = false;
        this.toastrService.Error("", error.error)
      });


    }else{
      this.authServive.UpdateUser(this.SelectedUser).subscribe({
        next: data => {
          this.formLoader = false;
          this.toastrService.Sucesss("", this.toastrService.successMsg)
          this.SelectedUser = new User(data)
     
          this.filteredPermessions = this.SelectedUser.UserPermessions.sort((a, b) =>
            (a.CaretexComponent.Sequence < b.CaretexComponent.Sequence ? -1 : 1)
          );
          this.DoFilter()
        },
        error: error => {
          this.formLoader = false;
          this.toastrService.Error("", error.error)
        }
      })
    }

  }

  cancelEditRow(i) {
    this.filteredPermessions[i].editMode = false
  }

  editRow(i) {
    this.filteredPermessions[i].editMode = true
  }

  EditPage() {
    this.editPageInfo = true
  }

  onCatgChange($event) {
    this.currentSelectedCategory = $event.target.value
    this. DoFilter()

  }

  DoFilter() {
  this.filteredPermessionsForSearch =  this.filteredPermessions = this.SelectedUser.UserPermessions.filter(
      row => row.CaretexComponent.ComponentGroup === this.currentSelectedCategory);
  }

  editUser() {
  }

  saveUser() {
    this.SelectedUser.BirthDay = new Date(this.SelectedUser.BirthDay)
    this.SelectedUser.JoinedWorkDate = new Date()
    this.saveUserProfile()
  }

  deleteDepartmentPermRow(i) {
    this.currentDepPermRowIndex = i
    if (!this.SelectedUser.DepartmentsPermessions[i].New) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        cancelButtonText: 'לְבַטֵל',
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.DeleteUserDepartmentPermessiom()

        }
      })
    }
    else {
      this.SelectedUser.DepartmentsPermessions.splice(this.currentDepPermRowIndex, 1);
    }
  }

  DeleteUserDepartmentPermessiom() {
    this.authServive.DeleteUserDepPermession(this.SelectedUser.UID, this.SelectedUser.DepartmentsPermessions[this.currentDepPermRowIndex].ID).subscribe({
      next: data => {
        this.SelectedUser.DepartmentsPermessions.splice(this.currentDepPermRowIndex, 1);
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }

  departmentEvent($event) {

    let selectedDepartments: Departments[] = $event
    selectedDepartments.forEach(element => {
      if (!this.checkIfListContainsDepartment(element.ID)) {
        var tmpdepartmentPermession = new UserDepartmentsPermession()
        tmpdepartmentPermession.CreatedAt = new Date()
        tmpdepartmentPermession.TenantID = this.SelectedUser.tenantID
        tmpdepartmentPermession.UserId = this.SelectedUser.UID
        tmpdepartmentPermession.Modified = true
        tmpdepartmentPermession.New = true
        tmpdepartmentPermession.DepartmentId = element.ID
        tmpdepartmentPermession.Department = element
        this.SelectedUser.DepartmentsPermessions.push(tmpdepartmentPermession)
      }


    });
    this.showDepartmentModal = false
  }

  checkIfListContainsDepartment(departmentId: string): boolean {
    const result = this.SelectedUser.DepartmentsPermessions.some(x => x.Department.ID === departmentId)
    return result
  }

  showMe() {
    this.showDepartmentModal = true
  }

  fileUpload(event) {
    if (event.target.files.length > 0) {
      const imgType = event.target.files[0].type;
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.profileImgTemp = reader.result;
      }
      const file = event.target.files[0];
      this.profileImgFile = file
    }
  }

  saveUserImage() {
    this.authServive.uploadImage(this.SelectedUser.UID, this.profileImgFile).subscribe(
      data => {
        this.profileImgFile = '';
        // Refresh thr current user if he already logged in 
          if (this.authServive.LoggedInUser.UID == this.SelectedUser.UID) {
           // let filter :string = '(uid=' + '\'' + this.SelectedUser.UID + '\'' + ')'
            this.SelectedUser.photoURL = data as string
            // Get User with updated photo URL 

            localStorage.setItem("user", JSON.stringify(this.SelectedUser))
           
            this.newProfilePicSubject.next();
          }
      }
    );
  
  }

  onDiscardProfileImg() {
    this.profileImgFile = '';
    if (this.SelectedUser.photoURL == "" || this.SelectedUser.photoURL == null || this.SelectedUser.photoURL == undefined) {
      this.profileImgTemp = this.profileImgUrl;
    } else {
      this.profileImgTemp = this.SelectedUser.photoURL;
    }
  }

  BackToList() {
    this.router.navigate(['users_management']);
  }


}
