import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DailyMealReport } from '../../../models/Dieticianfoodintake';
import { DieticianDataProviderService } from '../../../caretex-dietician/Dietician-services/dietician.data-provider.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { FormControl, FormGroup } from '@angular/forms';
import { MedicalFile } from 'src/app/models/customer';



@Component({
  selector: 'app-shareddailyfoodreportlist',
  templateUrl: './shareddailyfoodreportlist.component.html',
  styleUrls: ['./shareddailyfoodreportlist.component.css']
})
export class ShareddailyfoodreportlistComponent extends CaretexComponent implements OnInit {

  moduleName:string = "תיעוד צריכת מזון יומית "
  reports:DailyMealReport[] = []
  currentRowIndex:number
  personalId:string
  BaseUrl:string
  @Input() ForNursing : boolean 
  @Input() PersonalId: string
  @Input() IsFromCentralizedPage: boolean = false
  @Input() SelectedFile: MedicalFile


  
  formData : any[] 
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), //, [Validators.required, Validators.email]),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0

  RecordStatus = [{id:"A" , desc:"רשומה פתוחה"},{id:"C" , desc:"רשומה נעולה"}]

  constructor(private _title: Title,
    private router: Router,
    private caretxDieticianProviderService: DieticianDataProviderService,
    public medicalFileService: MedicalFileProviderService,
    private route: ActivatedRoute) { super(medicalFileService); }

  ngOnInit(): void {
    this._title.setTitle('Caretx |  תיעוד צריכת מזון יומית');

    if (this.IsFromCentralizedPage) {
      this.personalId = this.PersonalId
    }else {
      if (this.route.parent != null){
        this.personalId = this.route.parent.snapshot.params['id'];
        this.BaseUrl = this.route.parent.snapshot.url[2].path
      }
    }





    this.CheckIfComponentIsDisabled(this.personalId)
    this.getDailyFoodReportsromDB("")
    this.formData=  [
      {"formcontrolname":"status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
      {"formcontrolname":"created_at_from","label":"מתאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"created_at_to","label":"עד תאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_from","label":"תאריך נעילה מ" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_to","label":"תאריך נעילה עד" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},

    ]
  }


  getDailyFoodReportsromDB(filter:string) {  
    this.caretxDieticianProviderService.getDieticianMealsReport(this.personalId , filter, "list").subscribe(
      data => {
        this.reports = data
        this.totalNumOfRecords = data.length
      },
      err => {
        this.reports = []
      }
    )
  }

  SearchWithFilter($event) {
    this.getDailyFoodReportsromDB($event)
  }

  AddNewRecord($event){
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/"+this.BaseUrl+"/new")
  }

  OnSDrillToFoodReportDetail($event,record){
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/"+this.BaseUrl+"/" + record.ID)
  }

  deleteRow(i){

  }

  generateReport($event,record){
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  
  CheckIfDocumentIsDirty(){
    return false;
  }
  
}
