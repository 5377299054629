import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BMIMeasure, DieticianFollowUp } from 'src/app/models/DieticianFollowup';
import { DieticianAssessment } from 'src/app/models/dieticianassessment';
import { ModelMapper } from 'src/app/models/modelmapper';
import { environment } from 'src/environments/environment';
import { DailyMealReport, Meal } from 'src/app/models/Dieticianfoodintake';
import { DieticianRecommendation } from 'src/app/models/dietaryRecommendations';
import { PersonalMeasures } from 'src/app/models/measure';


@Injectable()
export class DieticianDataProviderService {

  constructor(private httpClient: HttpClient) { }


  getDieticianFollowUps(PersonelID: string , filter:string ,mode:string , page :string): Observable<DieticianFollowUp[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

      const headerDict = {
        'Mode': mode,
        'Page':page,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
    return this.httpClient.get(environment.gateway + '/dieticianfollowup/' + PersonelID + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DieticianFollowUp).map(item)
        })

      }),catchError(this.errorHandler));
  }
  getTotalOfDieticianFollowups(PersonelID: string): Observable<number> {
    return this.httpClient.get(environment.gateway + '/dieticianfollowup/' + PersonelID +'/count').pipe(
      map((response: any) => {
        return response
      }), catchError(this.errorHandler));
  }

  createNewDieticianFollowUp(newNurseFollowup: DieticianFollowUp) {
    return this.httpClient.post(environment.gateway + '/dieticianfollowup/' + newNurseFollowup.PersonelID , newNurseFollowup)
  }
  updateDieticianFollowUp(NurseFollowup: DieticianFollowUp) {
    return this.httpClient.put(environment.gateway + '/nursefollowup/' + NurseFollowup.PersonelID, NurseFollowup )
  }
  deleteDieticianFollowUp(filter:string , personelId:string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/nursefollowup/' + personelId + filterQuery)
  }

  createNewDieticianAssessment(newAssessment: DieticianAssessment) {
    return this.httpClient.post(environment.gateway + '/dieticianassessment/' + newAssessment.PersonelID , newAssessment)
  }
  getDieticianAssessment(PersonelID: string , filter:string ,mode:string , page :string): Observable<DieticianAssessment[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

      const headerDict = {
        'Mode': mode,
        'Page':page,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
    return this.httpClient.get(environment.gateway + '/dieticianassessment/' + PersonelID + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DieticianAssessment).map(item)
        })

      }),catchError(this.errorHandler));
  }
  deleteDieticianAssessment(personelId:string,filter:string ) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/dieticianassessment/' + personelId + filterQuery)
  }

  updateDieticianAssessment(assessment: DieticianAssessment) {
    return this.httpClient.put(environment.gateway + '/dieticianassessment/' + assessment.PersonelID, assessment )
  }
  getYearlyBmiMeasures(PersonelID: string , year:string): Observable<BMIMeasure[]> {
    return this.httpClient.get(environment.gateway + '/bmimeasures/' + PersonelID +"/year/"+year).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(BMIMeasure).map(item)
        })

      }),catchError(this.errorHandler));
  }
  getMonthlyBmiMeasures(PersonelID: string , year:string , month:string): Observable<BMIMeasure[]> {
    return this.httpClient.get(environment.gateway + '/bmimeasures/' + PersonelID +"/year/"+year +"/month/" + month).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(BMIMeasure).map(item)
        })

      }),catchError(this.errorHandler));
  }


  getDieticianMealsReport(PersonelID: string  , filter:string ,mode:string ): Observable<DailyMealReport[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

      const headerDict = {
        'Mode': mode,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };

    return this.httpClient.get(environment.gateway + '/dieticianmeal/' + PersonelID  + filterQuery,requestOptions ).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Meal).map(item)
        })

      }),catchError(this.errorHandler));
  }
  createDieticianMealsReport( mealsReport: DailyMealReport) {
    return this.httpClient.post(environment.gateway + '/dieticianmeal/' + mealsReport.PersonelID , mealsReport)
  }
  updateDieticianMealsReport(mealsReport: DailyMealReport) {
    return this.httpClient.put(environment.gateway + '/dieticianmeal/' + mealsReport.PersonelID, mealsReport )
  }


  createDieticianRecommendation( recommendation: DieticianRecommendation) {
    return this.httpClient.post(environment.gateway + '/dieticianrecommendation/' + recommendation.PersonelID , recommendation)
  }

  getDieticianRecommendation(PersonelID: string , filter:string ,mode:string ): Observable<DieticianRecommendation[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

      const headerDict = {
        'Mode': mode
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
    return this.httpClient.get(environment.gateway + '/dieticianrecommendation/' + PersonelID + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DieticianRecommendation).map(item)
        })

      }),catchError(this.errorHandler));
  }


  updateDieticianRecommendation(recommendation: DieticianRecommendation) {
    return this.httpClient.put(environment.gateway + '/dieticianrecommendation/' + recommendation.PersonelID, recommendation )
  }

  deleteDieticianRecommendation(personelId:string,recordId:string ) {

    return this.httpClient.delete(environment.gateway + '/dieticianrecommendation/' + personelId  + "/record/"+ recordId)
  }









  getPersonMeasures(PersonelID: string , code:string): Observable<PersonalMeasures> {
    return this.httpClient.get(environment.gateway + '/personmeasures/' + PersonelID +'/code/' + code).pipe(
      map((response: any) => {
        return response
      }), catchError(this.errorHandler));
  }




  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }
}
