import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NurseAdmission } from '../../../../../src/app/models/nurseadmission';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxNursingProviderService } from '../../nursing-services/Nursing.data-provider.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-nursingadmissionlist',
  templateUrl: './nursingadmissionlist.component.html',
  styleUrls: ['./nursingadmissionlist.component.css']
})
export class NursingadmissionlistComponent extends CaretexComponent implements OnInit {
  admissions: NurseAdmission[] = []
  id: string
  currentRowIndex: number
  formData: any[]
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0
  RecordStatus = [{ id: "A", desc: "קבלה פתוחה" }, { id: "C", desc: "קבלה סגורה" }]
  moduleName: string = "קבלות סיעודית"

  constructor(private caretxNursingProviderService: CaretxNursingProviderService,
    private toastrService: ToasterService,
    public medicalFileService: MedicalFileProviderService,
    private _title: Title,
    private router: Router,
    private route: ActivatedRoute) { super(medicalFileService) }

  ngOnInit(): void {
    this._title.setTitle('Caretx |  אירועים חריגים');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
    this.getAdmissionListFromDB("")
    this.formData = [
      { "formcontrolname": "status", "label": "סטטוס רשימה", "type": "multi", "value": JSON.stringify(this.RecordStatus), "selectid": "id", "selectval": "desc" },
      { "formcontrolname": "created_at_from", "label": "מתאריך", "type": "date", "value": "", "selectid": "", "selectval": "" },
      { "formcontrolname": "created_at_to", "label": "עד תאריך", "type": "date", "value": "", "selectid": "", "selectval": "" },
      { "formcontrolname": "closed_at_from", "label": "תאריך נעילה מ", "type": "date", "value": "", "selectid": "", "selectval": "" },
      { "formcontrolname": "closed_at_to", "label": "תאריך נעילה עד", "type": "date", "value": "", "selectid": "", "selectval": "" },

    ]
  }

  getAdmissionListFromDB(filter: string) {
    this.caretxNursingProviderService.getNurseAdmissions(this.id, filter,"list").subscribe(
      data =>{
        this.admissions = data,
        this.totalNumOfRecords = data.length
      }, 
      err => {
        this.admissions = []
      }
    )
  }

  SearchWithFilter($event) {
    this.getAdmissionListFromDB($event)
  }

  AddNewNurseAdmission($event) {
    // check at server if there are still  opened Events
    let filter = '(status=\'A\')'
    this.caretxNursingProviderService.getNurseAdmissions(this.id, filter, "list").subscribe((data: NurseAdmission[]) => {
      if (data.length > 0) {
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
      else {
        this.router.navigateByUrl("caretexfile/" + this.id + "/nurseadmission/new")
      }

    });
  }

  deleteRow(i) {
    this.currentRowIndex = i
    if (this.admissions[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAdmission(this.admissions[this.currentRowIndex])
        }
      })
    }
  }

  deleteAdmission(admission: NurseAdmission) {
    let filter = '(id=' + '\'' + admission.ID + '\'' + ')'
    this.caretxNursingProviderService.deleteNurseAdmission(filter, this.id).subscribe({
      next: data => {
        this.getAdmissionListFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  OnSDrillToAdmissionDetails($event, admission) {

    if (admission.IsExternalAdmission){
      this.router.navigateByUrl("caretexfile/" + this.id + "/externalnurseadmission/" + admission.ID)
    }else{
      if (admission.Status == "C")
      this.router.navigateByUrl("caretexfile/" + this.id + "/nurseadmission/" + admission.ID + "/locked")
    else
      this.router.navigateByUrl("caretexfile/" + this.id + "/nurseadmission/" + admission.ID)
    }




  }

  generateReport($event, admission) {

  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false;
  }

  AddExistingAdmissionAsPDF(){

    let filter = '(status=\'A\')'
    this.caretxNursingProviderService.getNurseAdmissions(this.id, filter, "list").subscribe((data: NurseAdmission[]) => {
      if (data.length > 0) {
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
      else { 
        this.router.navigateByUrl("caretexfile/" + this.id + "/externalnurseadmission/new")
      }
    });



  }
  
}
