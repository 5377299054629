import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BedSore } from 'src/app/models/bedsore';
import { Exceptionevent, ExceptionEventWitness } from 'src/app/models/exceptionEvent';
import { PersonalMeasuresHeader } from 'src/app/models/measure';
import { ModelMapper } from 'src/app/models/modelmapper';
import { NurseAdmission, NurseFollowUp } from 'src/app/models/nurseadmission';
import { NurseCognitiveTest, NurseExamination } from 'src/app/models/nurseexamination';
import { NurseTask } from 'src/app/models/nurseinstruction';
import { NurseRelease } from 'src/app/models/nurserelease';
import { BaseOmdan, OmdanQuestions } from 'src/app/models/omdans';
import { environment } from 'src/environments/environment';
import { BedSoreTracks } from 'src/app/models/bedsore';
import { Attachment } from 'src/app/models/attachments';
import { DiabeticFootTest, DiabeticFootTestHeader } from 'src/app/models/DiabeticFootTest';
import { VasFpsPainExam } from 'src/app/models/OmdanPain';
import { PersonalVaccination } from 'src/app/models/Vaccinations';


@Injectable()
export class CaretxNursingProviderService {

  constructor(private httpClient: HttpClient) {
  }





  CreatePersonalVaccinations(personelId: string, record: PersonalVaccination) {
    return this.httpClient.post(environment.gateway + '/personalvaccinations/' + personelId, record)
  }

  UpdatePersonalVaccinations(personelId: string, record: PersonalVaccination) {
    return this.httpClient.put(environment.gateway + '/personalvaccinations/' + personelId, record)
  }

  getPersonalVaccinations(PersonelID: string): Observable<PersonalVaccination[]> {
    return this.httpClient.get(environment.gateway + '/personalvaccinations/' + PersonelID ).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PersonalVaccination).map(item)
        })
      }), catchError(this.errorHandler));
  }


  deletePersonalVaccinations(personelId, record: PersonalVaccination) {
    return this.httpClient.delete(environment.gateway + '/personalvaccinations/' + personelId + '/record/' + record.ID )
  }





  CreateExceptionalEvent(personelId: string, exceptionalevent: Exceptionevent) {
    return this.httpClient.post(environment.gateway + '/exceptionalEvents/' + personelId, exceptionalevent)
  }

  UpdateExceptionalEvent(personelId: string, exceptionalevent: Exceptionevent) {
    return this.httpClient.put(environment.gateway + '/exceptionalEvents/' + personelId, exceptionalevent)
  }

  getExceptionalEvents(PersonelID: string): Observable<Exceptionevent[]> {
    return this.httpClient.get(environment.gateway + '/exceptionalEvents/' + PersonelID + '/events').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Exceptionevent).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getExceptionalEventsById(filter: string, PersonelID: string): Observable<Exceptionevent[]> {
    return this.httpClient.get(environment.gateway + '/exceptionalEvents/' + PersonelID + '/events?filter=' + filter).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Exceptionevent).map(item)
        })
        // return new ModelMapper(Exceptionevent).map(response)

      }), catchError(this.errorHandler));
  }

  deleteExceptionalEventsWitness(personelId, witnesse: ExceptionEventWitness) {
    return this.httpClient.delete(environment.gateway + '/exceptionalEvents/' + personelId + '/events/' +
      witnesse.ExceptioneventId + '/witness/' + witnesse.ID)
  }

  deletExceptionalEvent(filter, exceptionalevent: Exceptionevent) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/exceptionalEvents/' + exceptionalevent.PersonelID + filterQuery)
  }

  getOmdanQuestions(PersonelID: string, filter: string): Observable<OmdanQuestions[]> {
    return this.httpClient.get(environment.gateway + '/omdan/' + PersonelID + '/questions?filter=' + filter).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Exceptionevent).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getOmdanem(PersonelID: string, filter: string, omdanCode: string): Observable<BaseOmdan[]> {
    return this.httpClient.get(environment.gateway + '/omdan/' + PersonelID + '/' + omdanCode + '?filter=' + filter).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(BaseOmdan).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateOmdan(omdan: BaseOmdan, filter: string) {
    return this.httpClient.post(environment.gateway + '/omdan/' + omdan.PersonelID + "?filter=" + filter, omdan)
  }

  UpdateOmdan(omdan: BaseOmdan, filter: string) {
    return this.httpClient.put(environment.gateway + '/omdan/' + omdan.PersonelID + "?filter=" + filter, omdan)
  }

  getNurseAdmissions(PersonelID: string, filter: string, mode: string): Observable<NurseAdmission[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    const headerDict = {
      'Mode': mode,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.httpClient.get(environment.gateway + '/nurseadmission/' + PersonelID + filterQuery, requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseAdmission).map(item)
        })

      }), catchError(this.errorHandler));
  }

  createNewNurseAdmission(newNurseAdmission: NurseAdmission) {
    return this.httpClient.post(environment.gateway + '/nurseadmission/' + newNurseAdmission.PersonelID, newNurseAdmission)
  }

  updateurseAdmission(nurseAdmission: NurseAdmission) {
    return this.httpClient.put(environment.gateway + '/nurseadmission/' + nurseAdmission.PersonelID, nurseAdmission)
  }

  deleteNurseAdmission(filter: string, personelId: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/nurseadmission/' + personelId + filterQuery)
  }

  GetBedSores(PersonelID: string, filter: string, mode: string): Observable<BedSore[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    const headerDict = {
      'Mode': mode,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.httpClient.get(environment.gateway + '/bedsore/' + PersonelID + filterQuery, requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(BedSore).map(item)
        })

      }), catchError(this.errorHandler));
  }

  getBedSoreTracks(PersonelID: string, filter: string, mode: string, page: string): Observable<BedSoreTracks[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

    const headerDict = {
      'Mode': mode,
      'Page': page,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.httpClient.get(environment.gateway + '/bedsoretracks/' + PersonelID + filterQuery, requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(BedSoreTracks).map(item)
        })

      }), catchError(this.errorHandler));
  }

  updateBedSoreTracks(bedSoreTrack: BedSoreTracks) {
    return this.httpClient.put(environment.gateway + '/bedsoretracks/' + bedSoreTrack.PersonelID, bedSoreTrack)
  }

  getBedSoreImages(PersonelID: string): Observable<Attachment[]> {

    return this.httpClient.get(environment.gateway + '/bedsore/' + PersonelID + "/" + "attachments").pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Attachment).map(item)
        })

      }), catchError(this.errorHandler));
  }

  CreateBedSore(bedSore: BedSore) {
    return this.httpClient.post(environment.gateway + '/bedsore/' + bedSore.PersonelID, bedSore)
  }

  updateBedSore(bedsore: BedSore) {
    return this.httpClient.put(environment.gateway + '/bedsore/' + bedsore.PersonelID, bedsore)
  }

  deleteBedSore(filter: string, personelId: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

    return this.httpClient.delete(environment.gateway + '/bedsore/' + personelId + filterQuery)
  }

  deleteBedSoreHelperItem(itemid: string, personelId: string) {
    return this.httpClient.delete(environment.gateway + '/bedsore/' + personelId + "/helperitem/" + itemid)
  }


  getNurseFollowUps(PersonelID: string, filter: string, mode: string, page: string): Observable<NurseFollowUp[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

    const headerDict = {
      'Mode': mode,
      'Page': page,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.httpClient.get(environment.gateway + '/nursefollowup/' + PersonelID + filterQuery, requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseFollowUp).map(item)
        })

      }), catchError(this.errorHandler));
  }




  
  
  getNurseFollowUpsForReport( departments:any[],startDate: Date, endDate: Date ): Observable<NurseFollowUp[]> {

    let filterQuery = "";
    let ids= []
    let startDateTxt  = this.dateAsYYYYMMDDHHNNSS(new Date(startDate))
    let endDateTxt  = this.dateAsYYYYMMDDHHNNSS(new Date(endDate))
     

    departments.forEach(element => {
      ids.push("'" + element.ID + "'")
    });

    filterQuery = '?ids[]=' + ids + "&fromdate="+startDateTxt +"&todate="+endDateTxt

    return this.httpClient.get(environment.gateway + '/nursefollowupreport' + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseFollowUp).map(item)
        })
      }), catchError(this.errorHandler));
  }




  




  getTotalOfNurseFollowups(PersonelID: string): Observable<number> {
    return this.httpClient.get(environment.gateway + '/nursefollowup/' + PersonelID + '/count').pipe(
      map((response: any) => {
        return response
      }), catchError(this.errorHandler));
  }



  getTotalBedSoreTracksFromDB(PersonelID: string, bedsoreId: string): Observable<number> {


    return this.httpClient.get(environment.gateway + '/bedsoretracks/' + PersonelID + '/count/' + bedsoreId).pipe(
      map((response: any) => {
        return response
      }), catchError(this.errorHandler));
  }



  createNewNurseFollowUp(newNurseFollowup: NurseFollowUp) {
    return this.httpClient.post(environment.gateway + '/nursefollowup/' + newNurseFollowup.PersonelID, newNurseFollowup)
  }

  updateNurseFollowUp(NurseFollowup: NurseFollowUp) {
    return this.httpClient.put(environment.gateway + '/nursefollowup/' + NurseFollowup.PersonelID, NurseFollowup)
  }

  deleteNurseFollowUp(filter: string, personelId: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/nursefollowup/' + personelId + filterQuery)
  }



  getDiabeticFootTests(PersonelID: string, filter: string): Observable<DiabeticFootTestHeader[]> {
    return this.httpClient.get(environment.gateway + '/diabetictest/' + PersonelID  + '?filter=' + filter).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DiabeticFootTestHeader).map(item)
        })
      }), catchError(this.errorHandler));
  }



  CreateDiabeticFootTest(omdan: DiabeticFootTestHeader) {
    return this.httpClient.post(environment.gateway + '/diabetictest/' + omdan.PersonelID , omdan)
  }

  UpdateDiabeticFootTest(omdan: DiabeticFootTestHeader) {
    return this.httpClient.put(environment.gateway + '/diabetictest/' + omdan.PersonelID , omdan)
  }



  /**************************************************************************************** */
  getAllPersonalMeasureHeaders(id: string, measureTypeId: string, userid: string, mode: string, filter: string): Observable<PersonalMeasuresHeader[]> {
    let filterQuery = "";
    if (filter != null && filter !="")
      filterQuery = '?filter=' + filter
    const headerDict = {
      'Mode': mode,
      'UserId': userid,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    if (measureTypeId =="") {
      return this.httpClient.get(environment.gateway + '/essentialmetrics/' + id  + filterQuery, requestOptions).pipe(
        map((response: any) => {
          return response.map(item => {
            return new ModelMapper(PersonalMeasuresHeader).map(item)
          })
        }), catchError(this.errorHandler));

    }else{
      return this.httpClient.get(environment.gateway + '/essentialmetrics/' + id + '/type/' + measureTypeId + filterQuery, requestOptions).pipe(
        map((response: any) => {
          return response.map(item => {
            return new ModelMapper(PersonalMeasuresHeader).map(item)
          })
        }), catchError(this.errorHandler));
    }


  }

  getPerosenelMeasuresByCode(PersonelID: string, measureCodeId: string): Observable<PersonalMeasuresHeader[]> {
    return this.httpClient.get(environment.gateway + '/personelmeasures/' + PersonelID + '/' + measureCodeId).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PersonalMeasuresHeader).map(item)
        })
      }), catchError(this.errorHandler));
  }

  createPersonelMeasure(personeMeasureHeader: PersonalMeasuresHeader) {
    return this.httpClient.post(environment.gateway + '/personelmeasures/' + personeMeasureHeader.PersonelID, personeMeasureHeader)
  }

  updatePersonelMeasure(personeMeasureHeader: PersonalMeasuresHeader) {
    return this.httpClient.put(environment.gateway + '/personelmeasures/' + personeMeasureHeader.PersonelID, personeMeasureHeader)
  }

  deletePersonelMeasure(personeMeasureHeader: PersonalMeasuresHeader) {
    return this.httpClient.delete(environment.gateway + '/personelmeasures/' + personeMeasureHeader.PersonelID + '/measures/' + personeMeasureHeader.ID)
  }

  /***************************************************************************************** */
  getAllNurseExaminations(id: string, filter: string): Observable<NurseExamination[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

    return this.httpClient.get(environment.gateway + '/nurseexaminations/' + id + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseExamination).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getLatestNurseExamination(id: string, filter: string): Observable<NurseExamination[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/nurseexaminations/' + id + "/record" + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseExamination).map(item)
        })
      }), catchError(this.errorHandler));
  }


  mergeNurseExamination(id: string): Observable<NurseExamination[]> {

    return this.httpClient.get(environment.gateway + '/nurseexaminations/' + id + "/merge").pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseExamination).map(item)
        })
      }), catchError(this.errorHandler));
  }



  updateNurseExamination(nurseExamination: NurseExamination) {
    return this.httpClient.put(environment.gateway + '/nurseexaminations/' + nurseExamination.PersonelID, nurseExamination)
  }

  createNurseExamination(nurseExamination: NurseExamination) {
    return this.httpClient.post(environment.gateway + '/nurseexaminations/' + nurseExamination.PersonelID, nurseExamination)
  }

  deleteNurseExamination(id: string, filter: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/nurseexaminations/' + id + filterQuery)


  }



  getAllNurseCognitiveTests(id: string, filter: string): Observable<NurseCognitiveTest[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

    return this.httpClient.get(environment.gateway + '/nursecognitivetests/' + id + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseCognitiveTest).map(item)
        })
      }), catchError(this.errorHandler));
  }


  getLatestCognitiveTest(id: string, filter: string): Observable<NurseCognitiveTest[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/nursecognitivetests/' + id + "/record" + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseCognitiveTest).map(item)
        })
      }), catchError(this.errorHandler));
  }


  mergeCognitiveTest(id: string): Observable<NurseCognitiveTest[]> {

    return this.httpClient.get(environment.gateway + '/nursecognitivetests/' + id + "/merge").pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseCognitiveTest).map(item)
        })
      }), catchError(this.errorHandler));
  }



  updateNurseCognitiveTest(cognitiveTest: NurseCognitiveTest) {
    return this.httpClient.put(environment.gateway + '/nursecognitivetests/' + cognitiveTest.PersonelID, cognitiveTest)
  }

  createNurseCognitiveTest(cognitiveTest: NurseCognitiveTest) {
    return this.httpClient.post(environment.gateway + '/nursecognitivetests/' + cognitiveTest.PersonelID, cognitiveTest)
  }

  deleteNurseCognitiveTest(id: string, filter: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/nursecognitivetests/' + id + filterQuery)
  }





  getNurseTasks(id: string, filter: string, mode: string): Observable<NurseTask[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = filter

    const headerDict = {
      'Mode': mode,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.httpClient.get(environment.gateway + '/nursetasks/' + id + filterQuery, requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseTask).map(item)
        })
      }), catchError(this.errorHandler));
  }

  updateNurseTask(nurseTask: NurseTask) {
    return this.httpClient.put(environment.gateway + '/nursetasks/' + nurseTask.PersonelID, nurseTask)
  }



  createNewNurseRelease(newNurseRelease: NurseRelease) {
    return this.httpClient.post(environment.gateway + '/nurserelease/' + newNurseRelease.PersonelID, newNurseRelease)
  }

  deleteNurseRelease(id: string, recordId: string) {

    return this.httpClient.delete(environment.gateway + '/nurserelease/' + id + "/record/" +recordId)
  }

  getNurseRelease(id: string, filter: string, mode: string): Observable<NurseRelease[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

    const headerDict = {
      'Mode': mode,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.httpClient.get(environment.gateway + '/nurserelease/' + id + filterQuery, requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseRelease).map(item)
        })
      }), catchError(this.errorHandler));
  }




  getVasFpsPainOmdanem(PersonelID: string, filter: string , mode:string): Observable<VasFpsPainExam[]> {

    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

    const headerDict = {
      'Mode': mode,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };


    return this.httpClient.get(environment.gateway + '/vasfpspainomdan/' + PersonelID  + filterQuery, requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(VasFpsPainExam).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateVasFpsPainOmdan(omdan: VasFpsPainExam, filter: string) {
    return this.httpClient.post(environment.gateway + '/vasfpspainomdan/' + omdan.PersonelID , omdan)
  }


  deleteVasFpsPainOmdan(id: string, recordId: string) {

    return this.httpClient.delete(environment.gateway + '/vasfpspainomdan/' + id + "/record/" +recordId)
  }


  updateVasFpsPainOmdan(record: VasFpsPainExam) {
    return this.httpClient.put(environment.gateway + '/vasfpspainomdan/' + record.PersonelID, record)
  }




  /***************************************************************************************** */
  dateAsYYYYMMDDHHNNSS(date): string {
    return date.getFullYear()
      + '-' + this.leftpad(date.getMonth() + 1, 2)
      + '-' + this.leftpad(date.getDate(), 2)
      + ' ' + this.leftpad(date.getHours(), 2)
      + ':' + this.leftpad(date.getMinutes(), 2)
      + ':' + this.leftpad(date.getSeconds(), 2);
  }
  leftpad(val, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength)
      + String(val)).slice(String(val).length);
  }
  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }

}

