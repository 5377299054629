import { MedicalFile } from './customer'
import { FamilyRelations, SocialPocketTrackingCode } from './generalhousedsetups'
import { propertyMap } from './modelmapper'
import { User } from './user'


// Need Server to send Payload in below structure
export class PocketTrackingRecord {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('PocketTrackingCodeId')
    public PocketTrackingCodeId: string
    @propertyMap('PocketTrackingCode')
    public PocketTrackingCode: SocialPocketTrackingCode
    @propertyMap('Amount')
    public Amount:number
    @propertyMap('Comment')
    public Comment:string
    


    @propertyMap('PocketTrackingHeaderId')
    public PocketTrackingHeaderId: string

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PocketTrackingCodeId = data["PocketTrackingCodeId"]
            this.PocketTrackingCode = data["PocketTrackingCode"]
            this.Amount = data["Amount"]
            this.Comment = data["Comment"]
            this.PocketTrackingHeaderId = data["PocketTrackingHeaderId"]
        }
        else {
            this.CreatedAt = this.UpdatedAt = new Date()
        }
    }
}

export class PocketTracking {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('DateOfDeposit')
    public DateOfDeposit: Date
    @propertyMap('Deposit')
    public Deposit: number
    @propertyMap('PocketTrackingRecords')
    public PocketTrackingRecords: PocketTrackingRecord[]
    @propertyMap('Status')
    public Status: string
    @propertyMap('ClosedAt')
    public ClosedAt: Date
    @propertyMap('ClosedById')
    public ClosedById: string
    @propertyMap('ClosedBy')
    public ClosedBy: User
    @propertyMap('CreatedById')
    public CreatedById: string
    @propertyMap('CreatedBy')
    public CreatedBy: User

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.DateOfDeposit = data["DateOfDeposit"]
            this.Deposit = data["Deposit"]
            this.PocketTrackingRecords =[]
            if (data["PocketTrackingRecords"] != null && data["PocketTrackingRecords"] != undefined){
                data["PocketTrackingRecords"].forEach(element => {
                    this.PocketTrackingRecords.push(new PocketTrackingRecord(element))
                });
            }
            this.Status = data["Status"]
            this.ClosedAt = data["ClosedAt"]
            this.ClosedById = data["ClosedById"]
            this.ClosedBy = data["ClosedBy"]
            this.CreatedById = data["CreatedById"]
            this.CreatedBy = data["CreatedBy"]

        }
        else {
            this.CreatedAt = this.UpdatedAt = new Date()
        }
    }
}
