import { Component, ViewChild } from '@angular/core';

import { Subscription } from 'rxjs/internal/Subscription';
import { AuthService } from './services/auth.service';
import { sideNaveGroups } from './services/enums';
import { MedicalFileProviderService } from '../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AppService } from './services/app.service';


export let browserRefresh = false;
@Component({
  providers: [],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  subscription: Subscription;
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Medical;
  id: string
  IsLoggedIn: boolean = false

  idleState = 'Not started.';
  timedOut = false;
  //lastPing?: Date = null;
  title = 'angular-idle-timeout';

  public modalRef: BsModalRef;
  @ViewChild('childModal', { static: false }) childModal: ModalDirective;

  private routesSubscription: Subscription;
  private routesSubscription2: Subscription;
  private routesSubscription3: Subscription;
  private routesSubscription4: Subscription;
  private routesSubscription5: Subscription;



  constructor(
    public auth: AuthService,
    private router: Router,
   // private dialogRef: MatDialog,
    private appService: AppService,
    public medicalFileServive: MedicalFileProviderService,
   // private idle: Idle,
    // private keepalive: Keepalive
    ) {

    if (this.auth.isLoggedIn) {
      this.IsLoggedIn = true

    }

   /* this.auth.userLoggedInOut.subscribe((flag) => {

      if (flag || this.IsLoggedIn) {
        this.subscribe()
      } else {
        this.unsubscribe()
      }

    })*/




  }

/*

  reset() {
    this.idle.setIdle(900); // 15 Minutes
    this.idle.setTimeout(20); // logout in 2
    this.idle.watch();

   // this.idleState = 'Started.';
    this.timedOut = false;


  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  stay() {
    this.childModal.hide();
    this.reset();
  }

  logout() {
    this.childModal.hide();
    this.appService.setUserLoggedIn(false);
    localStorage.setItem('forcelogout', JSON.stringify(true));
    this.dialogRef.closeAll();
    this.CloseSweetAlertDialogs() 
    this.auth.SignOut()
    this.unsubscribe()

  }


  // unsubscribe 
  unsubscribe() {
    if (this.routesSubscription) {
      this.routesSubscription.unsubscribe();
    }

    if (this.routesSubscription2) {
      this.routesSubscription2.unsubscribe();
    }
    if (this.routesSubscription3) {
      this.routesSubscription3.unsubscribe();
    }
    if (this.routesSubscription4) {
      this.routesSubscription4.unsubscribe();
    }
    if (this.routesSubscription5) {
      this.routesSubscription5.unsubscribe();
    }


  }

  subscribe() {
    this.idle.setIdle(900); // 15 Minutes
    this.idle.setTimeout(5); // logout in 20 sec
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.routesSubscription = this.idle.onIdleEnd.subscribe(() => {
     // this.idleState = 'No longer idle.'

      this.reset();
    });

    this.routesSubscription2 = this.idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logout()
    });

    this.routesSubscription3 = this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'את/ה לא פעיל כבר יותר מ 10 דק'
      // console.log(this.idleState);
      this.childModal.show();
    });

    this.routesSubscription4 = this.idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'את/ה עומד להתנתק מהמערכת באופן אוטומטי תוך ' + countdown + ' שניות!'

    });

    // sets the ping interval to 15 seconds
    this.keepalive.interval(15);

    this.routesSubscription5 = this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.reset();
    this.appService.setUserLoggedIn(true)
  }


  CloseSweetAlertDialogs() {
    const sweetAlertCancel = document.querySelector('.swal2-cancel') as HTMLElement;

    if (sweetAlertCancel) {
      sweetAlertCancel.click(); //only if cancel button exists
    }

    const sweetAlertConfirm = document.querySelector('.swal2-confirm') as HTMLElement;

    if (sweetAlertConfirm) {
      sweetAlertConfirm.click(); //if cancel doesn't exist , confirm is the equivalent for Ok button
    }

   
    
  }
  */
}