import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CaretxNursingProviderService } from '../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CartexEvent, TabHistory } from '../../../../../src/app/models/genericTypes';
import { NurseCognitiveTest } from '../../../../../src/app/models/nurseexamination';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { AccessComponenetType, NurseAdmissionSteps, NurseTest } from '../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';

@Component({
  selector: 'app-cognitivetestcontrol',
  templateUrl: './cognitivetestcontrol.component.html',
  styleUrls: ['./cognitivetestcontrol.component.css']
})
export class CognitivetestcontrolComponent extends CaretexComponent implements OnInit {
  @ViewChild('mainform') mainFormElement: NgForm;
  @Input()  nurseCognitiveTest: NurseCognitiveTest
  @Input() AccessedComponent: AccessComponenetType
  @Output() ModuleChanged = new EventEmitter<boolean>();
  @Input() events: Observable<CartexEvent>;
  @Input() currentActiveStep: number;
  @Input() stepOrder: number;
  @Output() updateNextTab = new EventEmitter<string>();
  @Output() NurseCognitiveTestHandler = new EventEmitter<NurseCognitiveTest>();

  @Input()  DisplayBtnsRow:boolean = false
  @Output() NewRecordHandler = new EventEmitter<void>();
  @Output() NewRecordLoadLastDataHandler = new EventEmitter<void>();
  @Output() ShowLastRecordHandler = new EventEmitter<void>();

  isdocumentClosed: boolean
  saveProcessCompleted: boolean
  personalId: string
  recordid: string
  CurrentStep : NurseTest = NurseTest.Main
  zoomInModalData: any;
  dismissModal:boolean = false
  private eventsSubscription: Subscription;
  private nextTab: string

  
  constructor( private caretxNursingProviderService: CaretxNursingProviderService,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,

    private router: Router,
    private route: ActivatedRoute) { super(medicalFileService) }

  ngOnInit(): void {
    if(this.route.parent!=null)
    this.personalId = this.route.parent.snapshot.params['id'];
    this.recordid = this.route.snapshot.params.recordid
    this.isdocumentClosed = (this.nurseCognitiveTest.Status == 'C')
    if (this.events != null && this.events != undefined) {
      this.eventsSubscription = this.events.subscribe((data) => this.confirmUnSavedChanges(data));
    }

        // Check If there is an Opened Record 
        if (this.AccessedComponent == AccessComponenetType.SubModule){
       //   this.getAllNurseCognitiveTestsFromDB()
        }
  }



  getAllNurseCognitiveTestsFromDB(){
    let status: string = "A"
    let filter = '(status=' + '\'' + status + '\'' + ')'

    this.caretxNursingProviderService.getAllNurseCognitiveTests(this.personalId, filter).subscribe(
      data => {
        if (data.length > 0) {
          this.nurseCognitiveTest = new  NurseCognitiveTest(data[0])
          this.NurseCognitiveTestHandler.emit(this.nurseCognitiveTest)
        }
      },
      err => {
        console.log("Error", err)
      }
    )
  }

  confirmUnSavedChanges(data: CartexEvent) {
    if ((data.EventData as unknown as TabHistory).CurrentTab == NurseAdmissionSteps.CognitiveTest) {
      this.nextTab = (data.EventData as unknown as TabHistory).NextTab
      this.ModuleChanged.emit(this.mainFormElement.dirty == null?false:this.mainFormElement.dirty)
    }
  }

  ModuleTextChanged(){
    this.ModuleChanged.emit(this.mainFormElement.dirty == null?false:this.mainFormElement.dirty)
  }

  insertReadingWritingSentens($event) {
  }

  insertOriantationSentens($event) {
  }

  insertContactEnvironmentSenten($event) {
  }

  insertEmotionalState($event){
    
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty == null?false:this.mainFormElement.dirty
  }

  selectTab(tabId: string) {

    this.CurrentStep = (tabId as NurseTest)
    if (this.CheckIfDocumentIsDirty())
      this.ModuleChanged.emit(true)
    else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
    }

  }
  zoomInField(title, fieldNgModel, preFilledData) {
    this.dismissModal = false
    this.zoomInModalData = {title, field: fieldNgModel, preFilled: (preFilledData || '')};
   
  }



  bindFieldData(responseData) {
    this.nurseCognitiveTest[this.zoomInModalData.field] = responseData;

  }

  DismissCtxTextareaModal(){
      this.dismissModal = true
  }

  NewRecord(){
    this.NewRecordHandler.emit()
  }

  NewRecordLoadLastData(){
    this.NewRecordLoadLastDataHandler.emit()
  }

  showLastRecord(){
    this.ShowLastRecordHandler.emit()
  }
  
}
