import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FormBuilder, Validators } from '@angular/forms';

import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sign-in-verify',
  templateUrl: './sign-in-verify.component.html'
})
export class SignInVerifyComponent implements OnInit {
  submitted = false;
  requestLoader = false;
  errorMessage:string
  config = {
    allowNumbersOnly: true,
    length: 4,
    placeholder: '',
    disableAutoFocus: false,
  };

  loginForm = this.fb.group({
    pin: ['', [Validators.minLength(4), Validators.required]],
  });

  get l() {
    return this.loginForm.controls;
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public authService: AuthService
  ) { }

  ngOnInit() { }

  onLogin(): void {
    this.requestLoader = true;
    this.submitted = true;
    const formValues = {
      pin: this.loginForm.value.pin,
    };

    setTimeout(() => {
      this.requestLoader = false;
      // localStorage.setItem('isLoggedIn', 'true');
      // this.router.navigateByUrl('/main');
    }, 2000);
  }

  onOtpChange(otp: any) {
    console.log('OTP___', otp);

  }

}
