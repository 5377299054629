import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonelDocument } from 'src/app/models/personeldocument';
import { CustomerService } from 'src/app/services/customer.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-personel-documents',
  templateUrl: './personel-documents.component.html',
  styleUrls: ['./personel-documents.component.css']
})
export class PersonelDocumentsComponent implements OnInit {



  id: string
  pdfContent: any
  personelDocuments: PersonelDocument[] = []
  newPersonelDocument: PersonelDocument
  DocToUpload: File = null;
  fileName: string

  constructor(private _title: Title,
    private _customersService: CustomerService,
    private router: Router,
    private _ar: ActivatedRoute,
    private toastrService: ToasterService) {
    this.newPersonelDocument = new PersonelDocument()
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex|מסמכים');
    this.id = this._ar.snapshot.params['id'];
    this.newPersonelDocument.PersonelID = this.id
    this.getAttachmentsById()
  }






  getAttachmentsById() {
    this._customersService.getAttachments(this.id).subscribe(
      data => this.personelDocuments = data,
      err => {
        console.log("Error", err)
        this.personelDocuments = []

      }
    )
  }



  openFile() {
    (document.querySelector('input[id="doc-input"]') as HTMLElement).click()
  }


  prepareDocumentForUpload(files: FileList) {
    this.DocToUpload = files.item(0);
    this.fileName = this.DocToUpload["name"]
  }



  downloadDocument(personelDocument: PersonelDocument) {
    this._customersService.getPdfDocument(personelDocument)
      .subscribe(data => {
        var file = new Blob([data], { type: 'application/pdf' });
        this.pdfContent = URL.createObjectURL(file);
       window.open(this.pdfContent);
     //  (window.URL.createObjectURL(new Blob([data])));
        this.getAttachmentsById()
      }, error => {
        console.log(error);
        var errorAsString = "failed to  download file"
        try {
          var enc = new TextDecoder("utf-8")
          var text = enc.decode(error.error)
          errorAsString = JSON.parse(text).error
        } catch (e) {

        }

        this.toastrService.Error("", errorAsString)
      })

  }

  uploadDocument() {

    this._customersService.uploadFile(this.DocToUpload, this.newPersonelDocument).subscribe(data => {
      // do something, if upload success
      this.getAttachmentsById()
      this.toastrService.Sucesss("", this.toastrService.successMsg)

    }, error => {
      console.log(error);
      this.toastrService.Error("", error)
    });



  }


  closeAttachmentWindow() {

  }

}
