import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CardixTask, HistoricCardixTask, ReportCardixTask } from '../../models/Cardix';
import { ModelMapper } from '../../models/modelmapper';
import { AuthService } from '../../services/auth.service';


@Injectable()
export class CardixDataProviderService {

  constructor(private httpClient: HttpClient, private authServive: AuthService,) { }

  getCompletedMediciensTasks(personelid: string, filter: string, fromDate: Date, ToDate: Date, doseMode: string): Observable<HistoricCardixTask[]> {

    let filterQuery = "";
    if (filter != null || filter != "")
      filterQuery = '?filter=' + filter
    const headerDict = {
      'fromdate': this.dateAsYYYYMMDDHHNNSS(fromDate),
      'todate': this.dateAsYYYYMMDDHHNNSS(ToDate),
      'dosemode': doseMode,
      'personelid': personelid
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.httpClient.get(environment.gateway + '/historiccardixtasks' + filterQuery, requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(HistoricCardixTask).map(item)
        })
      }), catchError(this.errorHandler));
  }


  getMediciensTasks(personelid: string, filter: string, fromDate: Date, ToDate: Date, doseMode: string , sortMode:string): Observable<CardixTask[]> {

    let filterQuery = "";
    if (filter != null || filter != "")
      filterQuery = '?filter=' + filter
    /* if (limit != null)
       filterQuery = filterQuery + '&limit=' + limit.toString()*/

    const headerDict = {
      'fromdate': this.dateAsYYYYMMDDHHNNSS(fromDate),
      'todate': this.dateAsYYYYMMDDHHNNSS(ToDate),
      'dosemode': doseMode,
      'personelid': personelid,
      'sort':sortMode
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };



    return this.httpClient.get(environment.gateway + '/cardixtasks' + filterQuery, requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(CardixTask).map(item)
        })
      }), catchError(this.errorHandler));
  }


  dateAsYYYYMMDDHHNNSS(date): string {
    return date.getFullYear()
      + '-' + this.leftpad(date.getMonth() + 1, 2)
      + '-' + this.leftpad(date.getDate(), 2)
      + ' ' + this.leftpad(date.getHours(), 2)
      + ':' + this.leftpad(date.getMinutes(), 2)
      + ':' + this.leftpad(date.getSeconds(), 2);
  }

  leftpad(val, resultLength = 2, leftpadChar = '0'): string {
    return (String(leftpadChar).repeat(resultLength)
      + String(val)).slice(String(val).length);
  }




  updateCardixTask(task: CardixTask) {
    return this.httpClient.put(environment.gateway + '/cardixtasks/' + task.PersonelID, task)
  }


  getTotalCardixTasks(startDate: Date, endDate: Date): Observable<number> {
    const headerDict = {
      'startdate': this.dateAsYYYYMMDDHHNNSS(startDate),
      'enddate': this.dateAsYYYYMMDDHHNNSS(endDate)
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.httpClient.get(environment.gateway + '/countcardixtasks', requestOptions).pipe(
      map((response: any) => {
        return response
      }), catchError(this.errorHandler));
  }

  getHistoricalCardixTasks(startDate: Date, endDate: Date , patients:any[] , drugs:any[],medicineForm:string,hmo:string): Observable<ReportCardixTask[]> {

    let filterQuery = "";
    let startDateTxt  = this.dateAsYYYYMMDDHHNNSS(startDate)
    let endDateTxt  = this.dateAsYYYYMMDDHHNNSS(endDate)
    let ids= []
    let ids2= []
     
    patients.forEach(element => {
      ids.push("'" + element.PersonelID + "'")
    });

    drugs.forEach(element => {
      ids2.push("'" + element.id + "'")
    });


    filterQuery = '?ids[]=' + ids + "&fromdate="+startDateTxt +"&todate="+endDateTxt + "&ids2[]="+ids2 +"&medicinform="+medicineForm+"&hmo="+hmo

    return this.httpClient.get(environment.gateway + '/reportcardixtasks' + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(ReportCardixTask).map(item)
        })
      }), catchError(this.errorHandler));
  }



/******************************************************************************************************************** */


  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError(error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      return throwError(error.error);
    }
    // return an observable with a user-facing error message


    // return of([]);
  }

}
