import { Component, OnInit } from '@angular/core';
import {  DiabeticFootTestHeader } from '../../../../../../src/app/models/DiabeticFootTest';
import { Title } from '@angular/platform-browser';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import { CaretxNursingProviderService } from '../../../nursing-services/Nursing.data-provider.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-diabetictestslist',
  templateUrl: './diabetictestslist.component.html',
  styleUrls: ['./diabetictestslist.component.css']
})
export class DiabetictestslistComponent extends CaretexComponent  implements OnInit {

  moduleName:string = "מעקבי כף רגל סכרתית"

  diabeticTests:DiabeticFootTestHeader[] = []
  constructor( 
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private caretxNursingProviderService:CaretxNursingProviderService,
    private toastrService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,) { super(medicalFileService)}

  ngOnInit(): void {
    this._title.setTitle('Caretx | מבחני FIM');
    if(this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
    this.getTestsFromDB()

  }

  getTestsFromDB(){
    let filter:string ="origin <> 'admission'" // Origin is not admission
    this.caretxNursingProviderService.getDiabeticFootTests(this.id,filter).subscribe(
      data => this.diabeticTests = data,
      err => {
        this.diabeticTests = []
      }
    )
  }

  AddNewDiabeticTest($event){
    let filter = '(status=\'A\')'
    this.caretxNursingProviderService.getDiabeticFootTests(this.id,filter).subscribe((data: DiabeticFootTestHeader[]) => {
      if (data.length > 0){
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
       this.toastrService.Error("", errorMsg)
      }
     else{
        this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/diabetictests/new")
      }
    }); 
  }

  RetrunToList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem")
  }

  OnSDrillToOmdan($event,test){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/diabetictests/" + test.ID)
  }

  generateReport($event,test){

  }

  deleteEvent(i){

  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }

  
}
