import { propertyMap } from './modelmapper'
import { User } from './user'

export class NurseExamination {

    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('RealmID')
    public RealmID: string
    @propertyMap('BreathingExamination')
    public BreathingExamination: string
    @propertyMap('BreathingStatus')
    BreathingStatus: string
    @propertyMap('Mobility')
    Mobility: string
    @propertyMap('MobilityStatus')
    MobilityStatus: string
    @propertyMap('TransferExamination')
    TransferExamination: string
    @propertyMap('TransferStatus')
    TransferStatus: string
    @propertyMap('WashingExamination')
    WashingExamination: string
    @propertyMap('WashingStatus')
    WashingStatus: string
    @propertyMap('ClothingExamination')
    ClothingExamination: string
    @propertyMap('ClothingStatus')
    ClothingStatus: string
    @propertyMap('FeedingExamination')
    FeedingExamination: string
    @propertyMap('FeedingStatus')
    FeedingStatus: string
    @propertyMap('MouthExamination')
    MouthExamination: string
    @propertyMap('MouthSStatus')
    MouthStatus: string
    @propertyMap('DiatesExamination')
    DiatesExamination: string
    @propertyMap('DiatesStatus')
    DiatesStatus: string
    @propertyMap('AppetiteExamination')
    AppetiteExamination: string
    @propertyMap('AppetiteStatus')
    AppetiteStatus: string
    @propertyMap('BowelExamination')
    BowelExamination: string
    @propertyMap('BowelStatus')
    BowelStatus: string
    @propertyMap('UrineExamination')
    UrineExamination: string
    @propertyMap('urineStatus')
    urinetatus: string
    @propertyMap('SkinExamination')
    SkinExamination: string
    @propertyMap('SkinStatus')
    SkinStatus: string
    @propertyMap('EyeExamination')
    EyeExamination: string
    @propertyMap('WashingStatus')
    EyeStatus: string
    @propertyMap('EarsExamination')
    EarsExamination: string
    @propertyMap('EarsStatus')
    EarsStatus: string
    @propertyMap('SleepExamination')
    SleepExamination: string
    @propertyMap('SleepStatus')
    SleepStatus: string
    @propertyMap('PainExamination')
    PainExamination: string
    @propertyMap('PainStatus')
    PainStatus: string
    @propertyMap('Status')
    public Status: string
    @propertyMap('LockedById')
    public LockedById: string
    @propertyMap('LockedBy')
    public LockedBy: User
    @propertyMap('LockedDate')
    public LockedDate: Date
    @propertyMap('Summary')
    public Summary: string
    @propertyMap('LastUpdatedBy')
    public LastUpdatedBy: string
    @propertyMap('LastUpdatedById')
    public LastUpdatedById: string

    constructor(data?: any) {

        if (data != null) {
            this.ID = data.ID
            this.PersonelID = data.PersonelID
            this.Status = data.Status
            this.RealmID = data.RealmID
            this.BreathingExamination = data.BreathingExamination
            this.Mobility = data.Mobility
            this.TransferExamination = data.TransferExamination
            this.WashingExamination = data.WashingExamination
            this.ClothingExamination = data.ClothingExamination
            this.FeedingExamination = data.FeedingExamination
            this.MouthExamination = data.MouthExamination
            this.DiatesExamination = data.DiatesExamination
            this.AppetiteExamination = data.AppetiteExamination
            this.BowelExamination = data.BowelExamination
            this.UrineExamination = data.UrineExamination
            this.SkinExamination = data.SkinExamination
            this.EyeExamination = data.EyeExamination
            this.EarsExamination = data.EarsExamination
            this.SleepExamination = data.SleepExamination
            this.PainExamination = data.PainExamination
            this.Summary = data.Summary
            this.CreatedAt = data.CreatedAt
            this.UpdatedAt = data.UpdatedAt
            this.LockedById = data.LockedAt
            this.LockedDate = data.LockedDate
            this.LockedBy = data.LockedBy
            this.LastUpdatedBy = data.LastUpdatedBy
            this.LastUpdatedById = data.LastUpdatedById
        }
        else {
            this.PersonelID = undefined
            this.Status = "A"
            this.BreathingExamination = ""
            this.Mobility = ""
            this.TransferExamination = ""
            this.WashingExamination = ""
            this.ClothingExamination = ""
            this.FeedingExamination = ""
            this.MouthExamination = ""
            this.DiatesExamination = ""
            this.AppetiteExamination = ""
            this.BowelExamination = ""
            this.UrineExamination = ""
            this.SkinExamination = ""
            this.EyeExamination = ""
            this.EarsExamination = ""
            this.SleepExamination = ""
            this.PainExamination = ""
            this.Summary = ""
        }
    }

}

export class NurseCognitiveTest {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('Readingwriting')
    public Readingwriting: string
    @propertyMap('Oriantation')
    Oriantation: string
    @propertyMap('ContactEnvironment')
    ContactEnvironment: string
    @propertyMap('Status')
    public Status: string
    @propertyMap('LockedById')
    public LockedById: string
    @propertyMap('LockedBy')
    public LockedBy: User
    @propertyMap('LockedAt')
    public LockedAt: Date
    @propertyMap('Summary')
    public Summary: string
    @propertyMap('EmotionalState')
    public EmotionalState: string
    @propertyMap('LastUpdatedById')
    public LastUpdatedById: string
    @propertyMap('LastUpdatedBy')
    public LastUpdatedBy: User

  
    
    constructor(data?: Object) {

        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.Status = data["Status"]
            this.Readingwriting = data["Readingwriting"]
            this.Oriantation = data["Oriantation"]
            this.ContactEnvironment = data["ContactEnvironment"]
            this.LockedById = data["LockedById"]
            this.LockedBy = data["LockedBy"]
            this.LockedAt = data["LockedAt"]
            this.LastUpdatedById = data["LastUpdatedById"]
            this.LastUpdatedBy = data["LastUpdatedBy"]
            this.Summary = data["Summary"]
            this.EmotionalState = data["EmotionalState"]


        }
        else {
            this.PersonelID = undefined
            this.Status = 'A'
        }
    }


}