import { MedicalFile } from './customer'
import { FamilyRelations } from './generalhousedsetups'
import { propertyMap } from './modelmapper'


// Need Server to send Payload in below structure
export class SocialRegistrationDoc {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('Habbits')
    public Habbits: string
    @propertyMap('Needs')
    public Needs: string
    @propertyMap('Problems')
    public Problems: string
    @propertyMap('FamilyLivesWithPaitent')
    public FamilyLivesWithPaitent: PaitentFamily[]

    @propertyMap('FamilyDoesNotLiveWithPaitent')
    public FamilyDoesNotLiveWithPaitent: PaitentFamily[]

    @propertyMap('FamilyThatCanBecontacted')
    public FamilyThatCanBecontacted: PaitentFamily[]

    @propertyMap('PersonDoc')
    public PersonDoc: MedicalFile
    @propertyMap('Hospitlizedreason')
	Hospitlizedreason: string

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.Habbits = data["Habbits"]
            this.Needs = data["Needs"]
            this.Problems = data["Problems"]
            this.FamilyLivesWithPaitent = data["FamilyLivesWithPaitent"]
            this.FamilyDoesNotLiveWithPaitent = data["FamilyDoesNotLiveWithPaitent"]
            this.FamilyThatCanBecontacted = data["FamilyThatCanBecontacted"]
            this.PersonelID = data["PersonelID"]
            this.PersonDoc = data["PersonDoc"]
            this.Hospitlizedreason = data["Hospitlizedreason"]
            
        }
        else {
            this.CreatedAt = this.UpdatedAt = new Date()
            this.Habbits = ""
            this.Needs = ""
            this.Problems = ""
            this.FamilyThatCanBecontacted = []
            this.FamilyLivesWithPaitent = []
            this.FamilyDoesNotLiveWithPaitent = []
        }
    }
}


export class PaitentFamily {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('FullName')
    public FullName: string
    @propertyMap('Address')
    public Address: string
    @propertyMap('RelationId')
    public RelationId: string
    @propertyMap('Relation')
    public Relation: FamilyRelations
    @propertyMap('Phone')
    public Phone: string
    @propertyMap('LivesWithPatient')
    public LivesWithPatient: boolean

    @propertyMap('CanBeContacted')
    public CanBeContacted: boolean

    @propertyMap('Modified')
    public Modified: boolean
    @propertyMap('New')
    public New: boolean

    @propertyMap('OtherFamilyRelation')
    public OtherFamilyRelation: string
    @propertyMap('IsOtherVal')
    public IsOtherVal: boolean

    isLocked: boolean
    editMode: boolean

    constructor() {
    }
}
