import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, CanDeactivateGuard } from '../shared/guard/auth.guard';
import { OccupationAssessmentListComponent } from './Components/occupation-assessment-list/occupation-assessment-list.component';
import { OccupationAssessmentComponent } from './Components/occupation-assessment/occupation-assessment.component';
import { OccupationalTherapyFollowUpsComponent } from './Components/occupational-therapy-follow-ups/occupational-therapy-follow-ups.component';
import { OccupationanchorComponentComponent } from './Components/occupationanchor-component/occupationanchor-component.component';

const routes: Routes = [


  {
    path: 'caretexfile/:id/occupationaltherapyfollowup',
    component: OccupationanchorComponentComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: OccupationalTherapyFollowUpsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

      {
        path: 'list/step/:stepid', 
        component: OccupationalTherapyFollowUpsComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'step/:stepid',
        component: OccupationalTherapyFollowUpsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },



    ]

  },



  {
    path: 'caretexfile/:id/occupationaltherapyassessment',
    component: OccupationanchorComponentComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: OccupationAssessmentListComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
 

      {
        path: ':recordid/:tabid', 
        component: OccupationAssessmentComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':recordid/step/:stepid', 
        component: OccupationAssessmentComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },


      {
        path: ':recordid',
        component: OccupationAssessmentComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      { 
        path: 'new', 
        component: OccupationAssessmentComponent, 
        canActivate: [AuthGuard],
        data: { mode: ['newpage']}
      }
    ]
  } ,];
  

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})



export class CaretexOccupationalTherapyRoutingModule { 



}
