import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { NursePhysicalStatus } from 'src/app/models/nursingphysicalstatuses';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-nursephysicalstatuses-details',
  templateUrl: './nursephysicalstatuses-details.component.html',
  styleUrls: ['./nursephysicalstatuses-details.component.css']
})
export class NursephysicalstatusesDetailsComponent extends CaretexComponent implements OnInit {

  nursePhysicalStatuses: NursePhysicalStatus[] = []
  selectedNursePhysicalStatus: NursePhysicalStatus
  id: string
  TabId:string = "main"

  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) { super(medicalFileServive) }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getMeasuresFromDB()
  }



  getMeasuresFromDB() {

    let filter = '(nurse_physical_check_id=' + '\'' + this.id + '\'' + ')'

    this.caretxSetupsProviderService.GetNursePhysicalCheckStatus(filter).subscribe(
      data => {
        this.nursePhysicalStatuses = data

      },
      err => {
        this.nursePhysicalStatuses = []

      }
    )
  }


  selectStatus(i) {
    this.selectedNursePhysicalStatus = this.nursePhysicalStatuses[i]
  }

  updateStatus($event) {
  }


  BackToList() {
    this.router.navigate(['physicalnursechecks_settings']);
  }

  addNewStatus() {
    this.router.navigateByUrl("/physicalnursechecks_settings/" + this.id + "/status/new")
  }

  deleteRow(i) {

  }
  editRow(i) {
    this.router.navigateByUrl("/physicalnursechecks_settings/" + this.id + "/status/" + this.nursePhysicalStatuses[i].ID)
  }

  cancelEditRow(i) {

  }

  addNewText(){}

  canDeactivate() {

    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    /*  const hospitalsLisChanged = this.measures.some(row => {
        return row.isNew || row.editMode
      })
      return hospitalsLisChanged*/
    return false
  }


}
