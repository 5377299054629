import { Component, OnInit } from '@angular/core';
import { EventLocation } from 'src/app/models/generalhousedsetups';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-exceptionaleventlocation',
  templateUrl: './exceptionaleventlocation.component.html',
  styleUrls: ['./exceptionaleventlocation.component.css']
})
export class ExceptionaleventlocationComponent extends CaretexComponent implements OnInit {


  TabId:string = "main"
  eventLocations:EventLocation[]  = []
  currentRowIndex:number

  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {   super(medicalFileServive)}

  ngOnInit(): void {
    this.getEventLocationsFromDB()
  }


  getEventLocationsFromDB() {
    this.caretxSetupsProviderService.GetEventLocations().subscribe(
      data => {
        this.eventLocations = data
      },
      err => {
        console.log("Error", err)
        this.eventLocations = []

      }
    )
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  
  CheckIfDocumentIsDirty() {
    const eventTypesLisChanged = this.eventLocations.some(row => {
      return row.New || row.Modified
    })
    return  eventTypesLisChanged
  }


  BackToList(){
    this.router.navigate(['system_setups']);
  }

  addNewEvent(){
    var newEventLocations = new EventLocation()
    newEventLocations.New = true
    newEventLocations.editMode = true
    newEventLocations.CreatedAt = new Date()
    newEventLocations.UpdatedAt = new Date()
    newEventLocations.RealmID = this.authServive.LoggedInTenantID
    this.eventLocations.unshift(newEventLocations)
  }

  deleteRow(i){
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.eventLocations[i].New){
          this.caretxSetupsProviderService.DeleteEventLocation( this.eventLocations[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.eventLocations.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
              this.getEventLocationsFromDB()
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.eventLocations.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i){
    this.eventLocations[i].editMode = true
    this.eventLocations[i].Modified = true
  }
  cancelEditRow(i){
    this.eventLocations[i].editMode  = false;
    this.eventLocations[i].Modified = false
  }

  updateEvent($event){
    this.caretxSetupsProviderService.CreateUpdateEventLocation (this.eventLocations).subscribe({
     next: data => {

       this.toastrService.Sucesss("", this.toastrService.successMsg)
       this.getEventLocationsFromDB()
     },
     error: error => {
       this.toastrService.Error("", this.toastrService.errorMsg)
     }
   })  
 }

}
