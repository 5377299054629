import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaretexOccupationalTherapyRoutingModule } from './caretex-occupational-therapy-routing.module';
import { OccupationAssessmentComponent } from './Components/occupation-assessment/occupation-assessment.component';
import { OccupationAssessmentListComponent } from './Components/occupation-assessment-list/occupation-assessment-list.component';
import { OccupationanchorComponentComponent } from './Components/occupationanchor-component/occupationanchor-component.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../caretx-shared-module/shared-module.module';
import { DoctorsModule } from '../caretx-doctors-module/doctors.module';
import { PipesModule } from '../caretx-custompipes-module/custompipes.module';
import { BrowserModule } from '@angular/platform-browser';
import { ModalModule } from '../modal';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OccupationalDataProviderService } from './ModuleServices/occupational.data-provider.service';
import { OccupationalTherapyFollowUpsComponent } from './Components/occupational-therapy-follow-ups/occupational-therapy-follow-ups.component';
import { HistoricfollowupsComponent } from './Components/occupational-therapy-follow-ups/historicfollowups/historicfollowups.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxSummernoteModule } from 'ngx-summernote';

@NgModule({
  declarations: [   
     OccupationAssessmentComponent,
    OccupationAssessmentListComponent,
    OccupationanchorComponentComponent,
    OccupationalTherapyFollowUpsComponent,
    HistoricfollowupsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    DoctorsModule,
    BrowserModule,
    ReactiveFormsModule,
    PipesModule,
    ModalModule,
    MatExpansionModule,
    NgMultiSelectDropDownModule,
    CaretexOccupationalTherapyRoutingModule,
    NgxSummernoteModule
  ],
  providers: [OccupationalDataProviderService]
})
export class CaretexOccupationalTherapyModule { }
