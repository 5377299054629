import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DrugInstruction } from  '../../../../../src/app/models/drugInstruction';
import { Title } from '@angular/platform-browser';
import { InstrStatus, BridgeEnums,  OptionObject, CaretexOperation, sideNaveGroups } from '../../../../../src/app/services/enums';
import { ModalService } from '../../../../../src/app/modal/modal.service';
import { CaretxMedicalProviderService } from '../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { CartexReport } from '../../../../../src/app/models/personeldocument';
import { ReportingService } from '../../../../../src/app/services/reporting.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DocViewerComponent } from '../../../../../src/app/caretx-shared-module/CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-nursingmedications',
  templateUrl: './nursingmedications.component.html',
  styleUrls: ['./nursingmedications.component.css']
})
export class NursingmedicationsComponent extends CaretexComponent  implements OnInit {

  id: string
  pdfContent: any
  instrstatus: InstrStatus
  currentDrugInstr: DrugInstruction
  popUpWindowTitle: string
  statusToSearch: string
  instructionStatuses: OptionObject[]
  filteredDrugInstruction: DrugInstruction[]
  lastkeydown2: number = 0;
  modalTarget: string
  childCaretexModule = "DruginstructionsComponent"
  currentCaretexModule = "DruginstrListComponent"
  moduleName:string = "רשימת הוראות תרופות"
  currentRowIndex:number
  totalNumOfRecords: Number = 0
  RecordStatus = [
    {id:"A" , desc:"הוראה פעילה"},
    {id:"C" , desc:"הוראה מבוטלת"},
    {id:"D" , desc:"הוראה פתוחה"}
  ]

  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    instr_status: new FormControl(''), 

  });
  formData : any[] 




  
  constructor(
    private _title: Title,
    private caretxMedicalService: CaretxMedicalProviderService,
    private modalService: ModalService,
    private reportsService:ReportingService,
    private authServive: AuthService,
    private router: Router,
    private toastrService: ToasterService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private _ar: ActivatedRoute,
    public medicalFileServive: MedicalFileProviderService,
    private bridgeEnum: BridgeEnums) {
      super(medicalFileServive)
    bridgeEnum = new BridgeEnums()

    this.instructionStatuses = bridgeEnum.InstructionStatuses
  }
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Medical;

  ngOnInit(): void {
    this._title.setTitle('Caretex|הוראות תרופות');
    if(this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'] 
    this.formData=  [

      {"formcontrolname":"instr_status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
      {"formcontrolname":"created_at_from","label":"מתאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"created_at_to","label":"עד תאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
    ]

    this.CheckIfComponentIsDisabled(this.id)
    this.getDrugInstrFromDB("")
  }

  AddNewDrugInstr($event) {
    if (this.authServive.isOperationAllowed(CaretexOperation.Create, this.childCaretexModule))
      this.router.navigateByUrl("caretexfile/" + this.id + "/druginstrs/new")
  }


  getStatusClassForColor(instrStatus: string) {
    switch (instrStatus) {
      case "A":
        return "active-status"
        break;
      case "D":
        return "done-btn-clicked"
        break;
      case "S":
        return "stop-btn-clicked"
        break;
      case "C":
        return "cancel-btn-clicked"
        break;
      default:
        break;
    }

  }

  BuildRateStringToDisplay(drugInst: DrugInstruction): string {
    let text: string = ''
    let drugFreqUomDescr: string = this.bridgeEnum.getName(drugInst.frequencyUom, this.bridgeEnum.DrugFreqUoms)
    let drugFreq: string = this.bridgeEnum.getName(drugInst.frequency, this.bridgeEnum.DrugFreqs)

    switch (drugInst.frequencyUom) {
      case "D":
        if (drugInst.daysToSkip == undefined)
          text = drugFreq + " " + drugFreqUomDescr + " כל יום "
        else
          text = drugFreq + " " + drugFreqUomDescr + " כל " + drugInst.daysToSkip + "ימים"
        break

      case "P":
        if (drugInst.maxInday == undefined)
          text = "לפי צורך"
        else
          text = " לפי צורך מקסימום " + drugInst.maxInday + " ביום"
        break
      default:
        break
    }

    return text

  }


  doctorNotes(DrugInstr: DrugInstruction) {
    if (DrugInstr.doctorNotes == "" || DrugInstr.doctorNotes == null || DrugInstr.doctorNotes == undefined)
      return "אין"
    else
      return DrugInstr.doctorNotes
  }

  OnShowDetailsBtnClick($event, drugInst) {
  }




  getDrugInstrFromDB(filter:string) {
    this.caretxMedicalService.getDrugInstrucs(this.id, filter).subscribe(
      data => {
        this.filteredDrugInstruction = data
        this.totalNumOfRecords = data.length
      },
      err => {
        this.filteredDrugInstruction = []
      }
    )
  }

  SearchWithFilter($event){
    this.getDrugInstrFromDB($event)
  }

  getUserIdsSecondtWay($event) {

    let userId = (<HTMLInputElement>document.getElementById('dynamicUserIdsSecondWay')).value;
    this.filteredDrugInstruction = [];
    if (userId.length > 0) {
      if ($event.timeStamp - this.lastkeydown2 > 200) {
      }
    }
    else if (userId.length == 0) {
    }
  }

  searchFromArray(arr: DrugInstruction[], regex) {
    let matches = []
    matches = arr.filter(x =>
      (x.Drug.DrugEngName.toLowerCase().indexOf(regex.toLowerCase()) !== -1) ||
      (x.Drug.DrugHebName.toLowerCase().indexOf(regex.toLowerCase()) !== -1)
    );
    return matches;
  };


  GenerateDrugInstructionReport(drugInst){
    var careTexReport =  new CartexReport()
    careTexReport.PersonelID = this.id
    careTexReport.ReportName = "drug"
    careTexReport.ReportType ="medicaldrugreport"
    careTexReport.ReportParam = drugInst.ID
    careTexReport.GeneratedBy  = this.authServive.LoggedInUser
    careTexReport.GeneratedOn =  new Date()
    const dialogRef = this.dialog.open(DocViewerComponent, {
      data: {
        Type:'CR',
        PdfReport: careTexReport
      }
    });




  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  
  CheckIfDocumentIsDirty(){
    return false;
  }

}
