import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-dieticiankneeheight-dialog',
  templateUrl: './dieticiankneeheight-dialog.component.html',
  styleUrls: ['./dieticiankneeheight-dialog.component.css']
})
export class DieticiankneeheightDialogComponent implements OnInit {

  below65:boolean = true
  isMale:boolean = true
  constructor(
    private dialogRef: MatDialogRef<DieticiankneeheightDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true;
  }

  ngOnInit(): void {

    this.below65 = this.data.below65
    this.isMale = this.data.isMale



  }

  submit() {

  }


  selectRow($event) {
    let val1 = $event.currentTarget.children[1].children[0].innerText
    let val2 = $event.currentTarget.children[0].children[0].innerText
    this.dialogRef.close(val1+":"+val2);
  }

}
