import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { AuthService } from 'src/app/services/auth.service';
import { ReportingService } from 'src/app/services/reporting.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxNursingProviderService } from '../../nursing-services/Nursing.data-provider.service';
import { NurseRelease } from 'src/app/models/nurserelease';

@Component({
  selector: 'app-nursereleaselist',
  templateUrl: './nursereleaselist.component.html',
  styleUrls: ['./nursereleaselist.component.css']
})
export class NursereleaselistComponent extends CaretexComponent implements OnInit {
  id: string
  nurseReleases: NurseRelease[] = []
  currentRowIndex: number
  moduleName: string = "שחרורים סיעודיים"
  formData: any[]
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0

  RecordStatus = [{ id: "A", desc: "שחרור פעיל" }, { id: "C", desc: "שחרור נעול" }]


  constructor(private _title: Title,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private router: Router,
    private authService: AuthService,
    private reportingService: ReportingService,
    private toastrService: ToasterService,
    public medicalFileServive: MedicalFileProviderService,
    private route: ActivatedRoute,) { super(medicalFileServive) }


  ngOnInit(): void {
    this._title.setTitle('Caretx | שחרור סיעודי');
    this.id = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.id)
    this.getNurseReleasesFromDB("")
    this.formData = [
      { "formcontrolname": "status", "label": "סטטוס רשימה", "type": "multi", "value": JSON.stringify(this.RecordStatus), "selectid": "id", "selectval": "desc" },
      { "formcontrolname": "created_at_from", "label": "מתאריך", "type": "date", "value": "", "selectid": "", "selectval": "" },
      { "formcontrolname": "created_at_to", "label": "עד תאריך", "type": "date", "value": "", "selectid": "", "selectval": "" },
      { "formcontrolname": "closed_at_from", "label": "תאריך נעילה מ", "type": "date", "value": "", "selectid": "", "selectval": "" },
      { "formcontrolname": "closed_at_to", "label": "תאריך נעילה עד", "type": "date", "value": "", "selectid": "", "selectval": "" },

    ]
  }


  getNurseReleasesFromDB(filter: string) {

    this.caretxNursingProviderService.getNurseRelease(this.id, filter, "list").subscribe(
      data => {
        this.nurseReleases = data
        this.totalNumOfRecords = data.length
      },
      err => {
        this.nurseReleases = []
      }
    )
  }

  SearchWithFilter($event) {
    this.getNurseReleasesFromDB($event)
  }
  AddNewRelease($event) {

    let filter = '(status=\'A\')'
    this.caretxNursingProviderService.getNurseRelease(this.id, filter, "list").subscribe({
      next: data => {
        if (data.length > 0) {
          let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
          this.toastrService.Error("", errorMsg)
        }
        else {
          this.router.navigateByUrl("caretexfile/" + this.id + "/nurserelease/new")
        }
      },
      error: error => {

        Swal.fire({
          title: "הודעת מערכת",
          text: error.message,
          icon: 'warning',
          confirmButtonText: 'סגור',
          allowOutsideClick: false,
          allowEscapeKey: false
        })



      }
    })
  }

  deleteRow(i) {
    this.currentRowIndex = i
    if (this.nurseReleases[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRelease(this.nurseReleases[this.currentRowIndex])
        }
      })
    }
  }

  OnSDrillToRelease($event, nurseRelease) {
    this.router.navigateByUrl("caretexfile/" + this.id + "/nurserelease/" + nurseRelease.ID)
  }
  generateReport($event, medicalRelease) {

  }

  deleteRelease(release: NurseRelease) {
    this.caretxNursingProviderService.deleteNurseRelease(this.id, release.ID).subscribe({
      next: data => {
        this.getNurseReleasesFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty() {
    return false;
  }

}
