import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { PocketTracking } from '../../../models/socialpockettrack';
import { CaretxSocialProviderService } from '../../Social.data-provider.service';




@Component({
  selector: 'app-pocket-tracks-list',
  templateUrl: './pocket-tracks-list.component.html',
  styleUrls: ['./pocket-tracks-list.component.css']
})
export class PocketTracksListComponent extends CaretexComponent  implements OnInit {

  pocketTracks:PocketTracking[] = []
  id:string
  moduleName:string = "רשימת מעקבי דמי כיס"
  currentRowIndex:number
  formData : any[] 
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), //, [Validators.required, Validators.email]),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0

  RecordStatus = [{id:"A" , desc:"רשומה פעילה"},{id:"C" , desc:"רשומה נעולה"}]

  constructor(private _title: Title,
    public  medicalFileService: MedicalFileProviderService,
    private router: Router,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,
    private authServive: AuthService) {
      super(medicalFileService)



  }

  ngOnInit(): void {
    this._title.setTitle('Caretex |ביקור משפחה');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id) 
    this.getPocketTracksFromDB("")
    this.formData=  [
      {"formcontrolname":"status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
      {"formcontrolname":"created_at_from","label":"מתאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"created_at_to","label":"עד תאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_from","label":"תאריך נעילה מ" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_to","label":"תאריך נעילה עד" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},

    ]
  }


  getPocketTracksFromDB(filter:string) {
    this.caretxSocialProviderService.getPocketTracks(this.id,filter , "list").subscribe(
      data => { this.pocketTracks = data
        this.totalNumOfRecords = data.length},
      err => {
        this.pocketTracks = []
      }
    )
  }

  SearchWithFilter($event) {
    this.getPocketTracksFromDB($event)
  }

  AddNewPocketTrack($event){
    this.router.navigateByUrl("caretexfile/" + this.id + "/pocket_track/new")
  }

  deleteRow(i){
    this.currentRowIndex = i
    if (this.pocketTracks[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,       
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletePocketTrack(this.pocketTracks[this.currentRowIndex])
        }
      })
    }
  }


  deletePocketTrack(track: PocketTracking) {
    let filter = '(id=' + '\'' + track.ID + '\'' + ')'
    this.caretxSocialProviderService.deletePocketTrack( filter,this.id).subscribe({
      next: data => {
        this.getPocketTracksFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }


  OnSDrillToPocketTrack($event,visit){
    this.router.navigateByUrl("caretexfile/" + this.id + "/pocket_track/" + visit.ID)
  }

  generateReport($event,visit){
    
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false;
  }
}
