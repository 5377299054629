import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MedicalPersonelMeasuresProviderService } from '../../../../../../src/app/caretx-doctors-module/doctor-services/medical-perosnelmeasures.data-provider.service';
import { CaretxNursingProviderService } from '../../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexEventTypes, CartexEvent, TabHistory } from '../../../../../../src/app/models/genericTypes';
import { NurseFollowUp } from '../../../../../../src/app/models/nurseadmission';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { AccessComponenetType, NurseFollowUpSteps, OptionObject, OriginModule } from '../../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import { PagenationObject } from '../../../../../../src/app/models/pagenation';
import { Attachment } from '../../../../../../src/app/models/attachments';
import { CaretxSharedModulesProviderService } from '../../../../../../src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { AttachmentDialogComponent } from '../../../../../../src/app/caretx-shared-module/CaretexModals/attachment-dialog/attachment-dialog.component';
import { CameraDialogComponent } from '../../../../../../src/app/caretx-shared-module/CaretexModals/camera-dialog/camera-dialog.component';
import { NurseCognitiveTest, NurseExamination } from '../../../../../../src/app/models/nurseexamination';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-nursingfollowup',
  templateUrl: './nursingfollowup.component.html',
  styleUrls: ['./nursingfollowup.component.css']
})
export class NursingfollowupComponent extends CaretexComponent implements OnInit {

  AccessedComponent: AccessComponenetType = AccessComponenetType.SubModule
  personalId: string
  DrugModalId: string
  AdmissionType: string = "N"
  medicationtype:string=""
  currentNurseFollowUp: NurseFollowUp
  CurrentStep: NurseFollowUpSteps = NurseFollowUpSteps.Main
  isFollowUpClosed: boolean = false
  moduleName: string = "מעקב סיעודי"
  FileCategoryList: OptionObject[] = [{ id: 'nursingobsrvfile', name: 'מסמכי מעקב סיעודי' },]
  LoggedInUser: string
  isSubModule: boolean = true
  BackgroundControlMsg: string
  HabitsControlMsg: string
  HobbiesControlMsg: string
  saveProcessCompleted: boolean = true
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  Type: string = AccessComponenetType.SubModule
  hideMainButtons: boolean = false
  admissionSaved: boolean = false
  nurseExaminationModuleChanged: boolean = false
  personelSensitivityModuleChanged: boolean = false
  personelMeasuresModuleChanged: boolean = false
  nurseCognitiveModuleChanged: boolean = false
  tabHistory: TabHistory = new TabHistory()
  caretexEvent: CartexEvent = new CartexEvent()
  @ViewChild('sectionAForm') sectionAForm: NgForm;
  pagenationObject: PagenationObject
  step: number = 0
  followups: NurseFollowUp[]
  pdfContent: any
  hasAlerts: boolean = false
  public config: any  = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
        ['misc', [ 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  }
  alertTxt: string = "אין התראות / הודעות חשובות"
  alertTxt2: string = "יש התראות / הודעות חשובות"

  Origin: OriginModule = OriginModule.NurseFollowUp;
  lastMeasureHeaderID:string 

  disableMeasureSelection:boolean = false
  constructor(
    private caretxNursingProviderService: CaretxNursingProviderService,
    private medicalPersonelMeasuressService: MedicalPersonelMeasuresProviderService,

    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    public dialog: MatDialog,
    private authServive: AuthService,
    private router: Router,
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private route: ActivatedRoute) {
    super(medicalFileService)
    this.LoggedInUser = this.authServive.LoggedInUser.UID
    this.isSubModule = true
  }

  ngOnInit(): void {

    if (this.route.parent != null)
      this.personalId = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.personalId)
    if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
      this.step = Number(this.route.snapshot.params['stepid']);
      if (this.step ==5){
        this.lastMeasureHeaderID = this.route.snapshot.params['measureheaderid'];
        if (this.lastMeasureHeaderID != "" && this.lastMeasureHeaderID != undefined && this.lastMeasureHeaderID != "00000000-0000-0000-0000-000000000000") {
          this.disableMeasureSelection = true
        }
      }
    }
    // Get Total Number of records :
    this.getTotalNumOfFollowUpsFromDB()
    this.getNurseFollowUpById(this.personalId, "", "new")
    this.getFollowUpListFromDB("1")
  }

  getFollowUpListFromDB(page: string) {
    this.caretxNursingProviderService.getNurseFollowUps(this.personalId, "", "list", page).subscribe((data: NurseFollowUp[]) => {
      if (data.length > 0) {
        this.followups = data

      }
      else {
        this.followups = []
      }
    });
  }

  refreshHistoryRecords() {
    this.getFollowUpListFromDB("1")
  }


  getTotalNumOfFollowUpsFromDB() {
    this.caretxNursingProviderService.getTotalOfNurseFollowups(this.personalId).subscribe((data: number) => {
      this.pagenationObject = new PagenationObject(data)
    });
  }

  getNurseFollowUpById(id: string, followupid: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + followupid + '\'' + ')'
    this.caretxNursingProviderService.getNurseFollowUps(id, filter, mode, "1").subscribe((data: NurseFollowUp[]) => {
      if (data.length == 1) {
        this.currentNurseFollowUp = new NurseFollowUp(data[0])
        if (this.currentNurseFollowUp.Notifications != undefined){
          this.hasAlerts = this.currentNurseFollowUp.Notifications.length > 0 ? true : false
          this.alertTxt = this.currentNurseFollowUp.Notifications.length > 0 ? "יש התראות / הודעות חשובות" : "אין התראות / הודעות חשובות"
        }
        this.isFollowUpClosed = (this.currentNurseFollowUp.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  SaveAdmission() {
    this.currentNurseFollowUp.LastUpdateById = this.authServive.LoggedInUser.UID
    this.currentNurseFollowUp.NextTrackDate = new Date(this.currentNurseFollowUp.NextTrackDate)
    if (this.currentNurseFollowUp.PersonalMeasuresHeader != undefined && this.currentNurseFollowUp.PersonalMeasuresHeader != null) {
      this.currentNurseFollowUp.PersonalMeasuresHeader.LockedById  = this.authServive.LoggedInUser.UID
      this.currentNurseFollowUp.PersonalMeasuresHeader.LockedDate =  new Date()
      this.currentNurseFollowUp.PersonalMeasuresHeader.Status = "C"
      this.disableMeasureSelection = true
    }
    this.DoSave()
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    editor.isReadOnly = this.isFollowUpClosed
  }

  HandlePagenation($event) {
    this.getFollowUpListFromDB($event)
  }

  DoSave() {


    this.caretxNursingProviderService.createNewNurseFollowUp(this.currentNurseFollowUp).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentNurseFollowUp = data as NurseFollowUp
        if (this.currentNurseFollowUp.Status == 'C')
          this.isFollowUpClosed = true
        this.saveProcessCompleted = true;
        this.lastMeasureHeaderID = this.currentNurseFollowUp.PersonalMeasuresHeader.ID
        this.medicalFileService.ResetCachedMedicalFile(this.personalId)
        this.resetModuleAfterSave()
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        if (this.step == 5 ) {
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/nurseingfollowup/step/" + this.step + "/" + this.lastMeasureHeaderID )
        }else{
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/nurseingfollowup/step/" + this.step)
        }
        
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
        this.saveProcessCompleted = true;
      }
    })
  }

  setStep(index: number) {
    this.step = index;

  }

  selectTab(tabId: string) {
    this.tabHistory.CurrentTab = this.CurrentStep
    this.tabHistory.NextTab = tabId
    this.caretexEvent.EventType = CaretexEventTypes.Tab
    this.caretexEvent.EventData = this.tabHistory

    if (this.CurrentStep == NurseFollowUpSteps.PhysicalTest ||
      this.CurrentStep == NurseFollowUpSteps.Sensitivity ||
      this.CurrentStep == NurseFollowUpSteps.Measures ||
      this.CurrentStep == NurseFollowUpSteps.CognitiveTest
    )
      this.eventsSubject.next(this.caretexEvent);

    if (this.CheckIfDocumentIsDirty())
      this.SaveAdmission()
    else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.CurrentStep = (tabId as NurseFollowUpSteps)
    }
  }

  updatePersonalMedicalMeasures($event) {
    this.currentNurseFollowUp.PersonalMeasuresHeader.PersonalMeasures = $event
  }


  RefreshPersonalMeasuresHeader($event) {
    this.currentNurseFollowUp.PersonalMeasuresHeader = $event
  }

  updatePhysicalCheck($event) {
    this.currentNurseFollowUp.NurseExamination = $event
  }

  updateCognitiveCheck($event) {
    this.currentNurseFollowUp.NurseCognitiveTest = $event
  }

  /***********************************Sub Modules Change Events*********************/

  newPersonalMEasure() {
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/nurseingfollowup/step/" + this.step)


     
  }




  nursePhysicalcheckChnaged($event) {
    this.nurseExaminationModuleChanged = $event
  }

  personelSensitivityChanged($event) {
    this.personelSensitivityModuleChanged = $event
  }

  personelMeasuresChanged($event) {
    this.personelMeasuresModuleChanged = $event
  }

  nursecognitivecheckChnaged($event) {
    this.nurseCognitiveModuleChanged = $event
  }

  CheckIfDocumentIsDirty() {
    return this.nurseExaminationModuleChanged
      || this.personelSensitivityModuleChanged
      || this.personelMeasuresModuleChanged
      || this.nurseCognitiveModuleChanged
      || this.sectionAForm.dirty
  }

  updateNextTabFromSensitivity($event) {
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  resetModuleAfterSave() {
    this.nurseExaminationModuleChanged = false
    this.personelSensitivityModuleChanged = false
    this.personelMeasuresModuleChanged = false
    this.nurseCognitiveModuleChanged = false
    this.sectionAForm.reset(this.sectionAForm.value);
  }

  updateRefreshedObservFiles() {
    let filter: string = "file_category = 'nursingobsrvfile'"
    this.caretxSharedlService.getAttachments(this.personalId, filter).subscribe((data: Attachment[]) => {
      if (data.length > 0) {
        this.currentNurseFollowUp.NurseObservationFiles = data
      }
      else {
      }
    });
  }

  pictureCaptureHandler($event) {
    var date = new Date();
    var fileTitle =
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      ("00" + date.getDate()).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);

    var uuid2 = new Date().getTime().toString() + ".jpeg";

    this.uploadFile($event._imageAsDataUrl, uuid2, fileTitle)

  }

  uploadFile(fileData: any, fileName: string, fileTitle: string) {
    let newObservationFile = new Attachment()
    newObservationFile.CreatedAt = new Date()
    newObservationFile.PersonelID = this.personalId
    newObservationFile.CreatedById = this.authServive.LoggedInUser.UID
    newObservationFile.FileDataBase64 = fileData
    newObservationFile.FileName = fileName
    newObservationFile.FileCategory = "nursingobsrvfile"
    this.caretxSharedlService.uploadFile(newObservationFile).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.updateRefreshedObservFiles()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })

  }

  ClearTextField() {
    this.currentNurseFollowUp.RichText = ""
  }

  AttachFile() {
    const dialogRef = this.dialog.open(AttachmentDialogComponent, {
      data: {
        UserId: this.LoggedInUser,
        FileCategoryList: this.FileCategoryList,
        Id: this.personalId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Save Diagnoses
      if (result == undefined || result == null || result == "") return
      this.updateRefreshedObservFiles()
    });
  }

  ShowWebcam() {
    const dialogRef = this.dialog.open(CameraDialogComponent, {
      data: {
        FileCategory: this.FileCategoryList[0].id,
        Id: this.personalId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateRefreshedObservFiles()
    });

  }

  CopyLastReportedFollowUp() {
    if (this.followups.length > 0) {
      this.currentNurseFollowUp.RichText = this.followups[0].RichText
    } else {
      Swal.fire({
        title: "הודעת מערכת",
        text: "לא נמצאו דיווחים קודמים",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    }
  }

  removeNotification($event) {

  }

  NewRecord($event) {

    Swal.fire({
      title: 'לידיעתך',
      text: "פעולה זאת תיצור רשומה חדשה או תציג רשומה פתוחה אחרונה,אתה עלול לאבד שינויים שכבר עשיתה , רוצה להמשיך? ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'המשך',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        let filter = '(status=\'A\')'
        this.caretxNursingProviderService.getLatestNurseExamination(this.personalId, filter).subscribe((data: NurseExamination[]) => {
          if (data.length == 1) {
            this.currentNurseFollowUp.NurseExamination = data[0]
            this.currentNurseFollowUp.NurseExamination.ID = null
            this.currentNurseFollowUp.NurseExamination.CreatedAt = new Date()
            this.currentNurseFollowUp.NurseExamination.UpdatedAt = this.currentNurseFollowUp.NurseExamination.CreatedAt
            this.currentNurseFollowUp.NurseExamination.RealmID = this.authServive.LoggedInTenantID
            this.currentNurseFollowUp.NurseExamination.PersonelID = this.personalId
            this.currentNurseFollowUp.NurseExamination.Status = 'A'
          }
          else if (data.length > 1) {
            let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
            this.toastrService.Error("", errorMsg)
          }
          else {
            this.currentNurseFollowUp.NurseExamination = new NurseExamination()
            this.currentNurseFollowUp.NurseExamination.CreatedAt = new Date()
            this.currentNurseFollowUp.NurseExamination.UpdatedAt = this.currentNurseFollowUp.NurseExamination.CreatedAt
            this.currentNurseFollowUp.NurseExamination.RealmID = this.authServive.LoggedInTenantID
            this.currentNurseFollowUp.NurseExamination.PersonelID = this.personalId
            this.currentNurseFollowUp.NurseExamination.Status = 'A'
          }
        });
      }
    })
  }

  ShowLastRecord($event) {
    Swal.fire({
      title: 'לידיעתך',
      text: "פעולה זאת תציג רשומה נעולה אחרונה,אתה עלול לאבד שינויים שכבר עשיתה , רוצה להמשיך? ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'המשך',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        // Load last locked Record
        let filter = '(status=\'C\')'
        this.caretxNursingProviderService.getLatestNurseExamination(this.personalId, filter).subscribe((data: NurseExamination[]) => {
          if (data.length == 1) {
            this.currentNurseFollowUp.NurseExamination = data[0]

          }
          else if (data.length > 1) {
            let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
            this.toastrService.Error("", errorMsg)
          }
          else {
            let errorMsg = "לא נמצאו רשומות מתאימות"
            this.toastrService.Error("", errorMsg)
          }

        });
      }
    })
  }

  NewRecordLoadLastData($event) {
    Swal.fire({
      title: 'לידיעתך',
      text: "פעולה זאת תיצור רשומה חדשה או תעתיק רשומה נעולה אחרונה,אתה עלול לאבד שינויים שכבר עשיתה , רוצה להמשיך? ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'המשך',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {

        // Load last locked Record
        this.caretxNursingProviderService.mergeNurseExamination(this.personalId).subscribe((data: NurseExamination[]) => {
          if (data.length == 1) {
            this.currentNurseFollowUp.NurseExamination = data[0]
          }
          else if (data.length > 1) {
            let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
            this.toastrService.Error("", errorMsg)
          }
          else {
            let errorMsg = "לא נמצאו רשומות מתאימות"
            this.toastrService.Error("", errorMsg)
          }
        });
      }
    })
  }

  NewCognitiveRecord($event) {
    Swal.fire({
      title: 'לידיעתך',
      text: "פעולה זאת תיצור רשומה חדשה ואתה עלול לאבד שינויים שכבר עשיתה , רוצה להשיך? ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'המשך',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        let filter = '(status=\'A\')'
        this.caretxNursingProviderService.getLatestCognitiveTest(this.personalId, filter).subscribe((data: NurseCognitiveTest[]) => {
          if (data.length == 1) {
            this.currentNurseFollowUp.NurseCognitiveTest = data[0]
            this.currentNurseFollowUp.NurseCognitiveTest.ID = null
            this.currentNurseFollowUp.NurseCognitiveTest.CreatedAt = new Date()
            this.currentNurseFollowUp.NurseCognitiveTest.UpdatedAt = this.currentNurseFollowUp.NurseCognitiveTest.CreatedAt
            this.currentNurseFollowUp.NurseCognitiveTest.TenantID = this.authServive.LoggedInTenantID
            this.currentNurseFollowUp.NurseCognitiveTest.PersonelID = this.personalId
            this.currentNurseFollowUp.NurseCognitiveTest.Status = 'A'
          }
          else if (data.length > 1) {
            let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
            this.toastrService.Error("", errorMsg)
          }
          else {
            this.currentNurseFollowUp.NurseCognitiveTest = new NurseCognitiveTest()
            this.currentNurseFollowUp.NurseCognitiveTest.CreatedAt = new Date()
            this.currentNurseFollowUp.NurseCognitiveTest.UpdatedAt = this.currentNurseFollowUp.NurseCognitiveTest.CreatedAt
            this.currentNurseFollowUp.NurseCognitiveTest.TenantID = this.authServive.LoggedInTenantID
            this.currentNurseFollowUp.NurseCognitiveTest.PersonelID = this.personalId
            this.currentNurseFollowUp.NurseCognitiveTest.Status = 'A'
          }
        });
      }
    })



  }

  ShowLastCognitiveRecord($event) {
    Swal.fire({
      title: 'לידיעתך',
      text: "פעולה זאת תציג רשומה נעולה אחרונה,אתה עלול לאבד שינויים שכבר עשיתה , רוצה להשיך? ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'המשך',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        // Load last locked Record
        let filter = '(status=\'C\')'
        this.caretxNursingProviderService.getLatestCognitiveTest(this.personalId, filter).subscribe((data: NurseCognitiveTest[]) => {
          if (data.length == 1) {
            this.currentNurseFollowUp.NurseCognitiveTest = data[0]
          }
          else if (data.length > 1) {
            let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
            this.toastrService.Error("", errorMsg)
          }
          else {
            let errorMsg = "לא נמצאו רשומות מתאימות"
            this.toastrService.Error("", errorMsg)
          }
        });
      }
    })
  }

  NewCognitiveRecordLoadLastData($event) {
    Swal.fire({
      title: 'לידיעתך',
      text: "פעולה זאת תיצור רשומה חדשה או תעתיק רשומה נעולה אחרונה,אתה עלול לאבד שינויים שכבר עשיתה , רוצה להשיך? ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'המשך',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        // Load last locked Record
        this.caretxNursingProviderService.mergeCognitiveTest(this.personalId).subscribe((data: NurseCognitiveTest[]) => {
          if (data.length == 1) {
            this.currentNurseFollowUp.NurseCognitiveTest = data[0]
          }
          else if (data.length > 1) {
            let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
            this.toastrService.Error("", errorMsg)
          }
          else {
            let errorMsg = "לא נמצאו רשומות מתאימות"
            this.toastrService.Error("", errorMsg)
          }
        });
      }
    })
  }

  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  isPdfFile:boolean = false
  file223 :string = ""

  downloadFile(file: Attachment) {
    let extension = (file.FileName).split('.').pop();
    let typeOfBlob = "application/octet-stream"
    switch (extension) {
      case "pdf":
        typeOfBlob = "application/pdf"
          this.isPdfFile = true
        break
      case "jpg":
        typeOfBlob = "image/jpg"
        this.isPdfFile = false
        break
      case "jpeg":
        typeOfBlob = "image/jpeg"
        this.isPdfFile = false
        break
      case "png":
        typeOfBlob = "image/png"
        this.isPdfFile = false
        break
      default:
        break
    }
    this.caretxSharedlService.downloadFile(this.personalId, file)
      .subscribe(data => {
        var file = new Blob([data], { type: typeOfBlob });
        this.pdfContent = URL.createObjectURL(file);
        if (this.isPdfFile){
          this.pdfViewer.pdfSrc = file
          this.showGeneratedPdf = true
          this.pdfViewer.refresh();
        }else{
        }
      }, error => {
        console.log(error);
        var errorAsString = "failed to download file"
        try {
          var enc = new TextDecoder("utf-8")
          var text = enc.decode(error.error)
          errorAsString = JSON.parse(text).error
        } catch (e) {
        }
      })
  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }

}

