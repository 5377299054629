export class BrdgServiceResponse{
    isError: boolean
    isOpSucceeded: boolean
    errorMsg: string


    resetResponse(){
        this.isError = false
        this.isOpSucceeded= false
        this.errorMsg= ""

    }

    success(){
        this.isError = false
        this.isOpSucceeded = true
        this.errorMsg = "נשמרה בהצלחה"
    }

    failure(){
        this.isError = true
        this.isOpSucceeded = false
        this.errorMsg = "רשומה לא נשמרה בהצלחה ,נסה עוד פעם"
    }
}