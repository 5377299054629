import { Component, OnInit } from '@angular/core';
import { Hospitalization } from '../../../models/hospitalization';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';


@Component({
  selector: 'app-hospitalizationslist',
  templateUrl: './hospitalizationslist.component.html',
  styleUrls: ['./hospitalizationslist.component.css']
})
export class HospitalizationslistComponent implements OnInit {

  hospitalizations: Hospitalization[] = []
  totalNumOfRecords:number
  TabId = "main"
  constructor(private router: Router,
    private caretxSharedService: CaretxSharedModulesProviderService,
    private _title: Title,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this._title.setTitle('Caretex | אישפוזי חוץ');
    this.getHospitalizationFromDB()

  }

  getHospitalizationFromDB() {
    this.caretxSharedService.getHospitalizationOrders("" ,"list").subscribe(
      data => {
        this.hospitalizations = data
        this.totalNumOfRecords = data.length},
      err => {
        this.hospitalizations = []
      }
    )
  }
  newHospitalization() {
    this.router.navigate(['hospitalizations/new']);
  }

  startEdit(hospitalization) {
    this.router.navigate(['hospitalizations/'+hospitalization.ID]);
  }




}
