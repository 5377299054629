import { User } from 'firebase'
import { MedicalFile } from './customer'

import { propertyMap } from './modelmapper'
import { PaitentFamily } from './socialregistration'




// Need Server to send Payload in below structure
export class SocialHelpReport {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID') //
    public PersonelID: string


    @propertyMap('CaretakerName') 
    CaretakerName:string
    @propertyMap('CaretakerAddress') 
    CaretakerAddress:string
    @propertyMap('CaretakerFamily') 
    CaretakerFamily:string
    @propertyMap('CaretakerHPhone') 
    CaretakerHPhone:string
    @propertyMap('CaretakerWPhone') 
    CaretakerWPhone:string
    @propertyMap('CaretakerOPhone') 
    CaretakerOPhone:string
    @propertyMap('CaretakerRelation') 
    CaretakerRelation:string
    @propertyMap('CaretakerLawTitle') 
    CaretakerLawTitle:string


    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User
    @propertyMap('Status')
    Status: string
    @propertyMap('Closed_at')
    public Closed_at: Date
    @propertyMap('PersonDoc')
    public PersonDoc: MedicalFile
    @propertyMap('FamilyOfPaitent')
    public FamilyOfPaitent: PaitentFamily[]
    @propertyMap('CompensationFromWork') //
    public CompensationFromWork: boolean
    @propertyMap('ExternalAlloweness') //
    public ExternalAlloweness: boolean
    @propertyMap('InsuranceAlloweness') //
    public InsuranceAlloweness: boolean
    @propertyMap('AllowanceReceiver') //
    public AllowanceReceiver: string
   @propertyMap('OtherIncomeResources') //
    public OtherIncomeResources: string
    @propertyMap('GeneralBackground') //
    public GeneralBackground: string
    @propertyMap('MedicalBackground') //
    public MedicalBackground: string
    @propertyMap('FunctionalStatus') //
    public FunctionalStatus: string
    @propertyMap('HousingCondition') //
    public HousingCondition: string
    @propertyMap('FamilyRelations') //
    public FamilyRelations: string
    @propertyMap('CommunityServices') //
    public CommunityServices: string
    @propertyMap('MainReason') //
    public MainReason: string
    @propertyMap('CandidatePosition') //
    public CandidatePosition: string
    @propertyMap('FamilyPosition') //
    public FamilyPosition: string
    @propertyMap('Priority1') //
    public Priority1: string
    @propertyMap('Priority2') //
    public Priority2: string
    @propertyMap('Priority3') //
    public Priority3: string
    @propertyMap('PlannedTreatment') //
    public PlannedTreatment: string
    @propertyMap('ReferringFactor') //
    public ReferringFactor: string
    @propertyMap('RecommendedDept') //
    public RecommendedDept: string
    
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.CaretakerName = data["CaretakerName"]
            this.CaretakerAddress = data["CaretakerAddress"]
            this.CaretakerFamily = data["CaretakerFamily"]
            this.CaretakerHPhone = data["CaretakerHPhone"]
            this.CaretakerWPhone = data["CaretakerWPhone"]
            this.CaretakerOPhone = data["CaretakerOPhone"]
            this.CaretakerRelation = data["CaretakerRelation"]
            this. CaretakerLawTitle = data["CaretakerLawTitle"]
            this.FamilyOfPaitent = data["FamilyOfPaitent"]
            this.PersonDoc = data["PersonDoc"]
            this.ClosedBy = data["ClosedBy"]
            this.ClosedById = data["ClosedById"]
            this.CreatedBy = data["CreatedBy"]
            this.CreatedById = data["CreatedById"]
            this.Status = data["Status"]
            this.Closed_at = data["Closed_at"]
            this.CompensationFromWork = data["CompensationFromWork"]
            this.ExternalAlloweness = data["ExternalAlloweness"]
            this.InsuranceAlloweness = data["InsuranceAlloweness"]
            this.AllowanceReceiver = data["AllowanceReceiver"]
            this.OtherIncomeResources = data["OtherIncomeResources"]
            this.GeneralBackground = data["GeneralBackground"]
            this.MedicalBackground = data["MedicalBackground"]
            this.FunctionalStatus = data["FunctionalStatus"]
            this.HousingCondition = data["HousingCondition"]
            this.FamilyRelations = data["FamilyRelations"]
            this.CommunityServices = data["CommunityServices"]
            this.MainReason = data["MainReason"]
            this.CandidatePosition = data["CandidatePosition"]
            this.FamilyPosition = data["FamilyPosition"]
            this.Priority1 = data["Priority1"]
            this.Priority2 = data["Priority2"]
            this.Priority3 = data["Priority3"]
            this.PlannedTreatment = data["PlannedTreatment"]
            this.ReferringFactor = data["ReferringFactor"]
            this.RecommendedDept = data["RecommendedDept"]
            
        }
        else {
            this.CreatedAt = this.UpdatedAt = new Date()
            this.Status = "A"
        }
    }
}



