import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ModelMapper } from 'src/app/models/modelmapper';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { PhysicalDiagnoses, PhysicalDiagnoses2, PhysioAssessment, PhysioTherapyFollowUp } from '../models/physiotherapy';



@Injectable()
export class CaretxPhsioTherapyProviderService {

  constructor(private httpClient: HttpClient, private authServive: AuthService,) {
  }

 


  getPhysicaldiagnosis(personelId: string, filter: string ,mode:string): Observable<PhysicalDiagnoses[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

      const headerDict = {
        'Mode': mode,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      
    return this.httpClient.get(environment.gateway + '/physicaldiagnosis/' + personelId + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PhysicalDiagnoses).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getPhysicaldiagnosis2(personelId: string, filter: string ,mode:string): Observable<PhysicalDiagnoses2[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

      const headerDict = {
        'Mode': mode,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      
    return this.httpClient.get(environment.gateway + '/physicaldiagnosis2/' + personelId + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PhysicalDiagnoses2).map(item)
        })
      }), catchError(this.errorHandler));
  }


  createPhysicalDiagnoses(physicalDiagnoses: PhysicalDiagnoses) {
    return this.httpClient.post(environment.gateway + '/physicaldiagnosis/' + physicalDiagnoses.PersonelID, physicalDiagnoses)
  }
  createPhysicalDiagnoses2(physicalDiagnoses: PhysicalDiagnoses2) {
    return this.httpClient.post(environment.gateway + '/physicaldiagnosis2/' + physicalDiagnoses.PersonelID, physicalDiagnoses)
  }

  updatePhysicaldiagnosis(physicalDiagnose : PhysicalDiagnoses) {
    return this.httpClient.put(environment.gateway + '/physicaldiagnosis/' + physicalDiagnose.PersonelID, physicalDiagnose)
  }

  updatePhysicaldiagnosis2(physicalDiagnose : PhysicalDiagnoses2) {
    return this.httpClient.put(environment.gateway + '/physicaldiagnosis2/' + physicalDiagnose.PersonelID, physicalDiagnose)
  }


  getPhysioTherapyFollowUps(PersonelID: string , filter:string ,mode:string , page :string): Observable<PhysioTherapyFollowUp[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

      const headerDict = {
        'Mode': mode,
        'Page':page,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
    return this.httpClient.get(environment.gateway + '/physiotherapyfollowup/' + PersonelID + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PhysioTherapyFollowUp).map(item)
        })

      }),catchError(this.errorHandler));
  }

  getTotalOfPhysioTherapyFollowups(PersonelID: string): Observable<number> {
    return this.httpClient.get(environment.gateway + '/physiotherapyfollowup/' + PersonelID +'/count').pipe(
      map((response: any) => {
        return response
      }), catchError(this.errorHandler));
  }


  createNewPhysioTherapyFollowUp(followup: PhysioTherapyFollowUp) {
    return this.httpClient.post(environment.gateway + '/physiotherapyfollowup/' + followup.PersonelID , followup)
  }





  createPhysioTherapyAssessment(assessment: PhysioAssessment) {
    return this.httpClient.post(environment.gateway + '/physiotherapyassessment/' + assessment.PersonelID, assessment)
  }

  updatePhysioTherapyAssessment(assessment: PhysioAssessment) {
    return this.httpClient.put(environment.gateway + '/physiotherapyassessment/' + assessment.PersonelID, assessment)
  }

  getPhysioTherapyAssessment(personelId: string, filter: string ,mode:string): Observable<PhysioAssessment[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

      const headerDict = {
        'Mode': mode,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      
    return this.httpClient.get(environment.gateway + '/physiotherapyassessment/' + personelId + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PhysioAssessment).map(item)
        })
      }), catchError(this.errorHandler));
  }




  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }

}