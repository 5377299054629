import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nursing-general-landing-page',
  templateUrl: './nursing-general-landing-page.component.html',
  styleUrls: ['./nursing-general-landing-page.component.css']
})
export class NursingGeneralLandingPageComponent implements OnInit {

  TabId:string = "main"
  constructor(public router: Router) { }

  menuItems = [
    {
      category: '', links: [
        { text: 'מעקב סיעודי', url: 'nursinggeneral/followup' },
        { text: 'דוח חיסונים', url: 'nursinggeneral/vaccinations' },
      ]
    },


  ]

  ngOnInit(): void {
  }
}
