import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretxSharedModulesProviderService } from '../../../../caretx-shared-module/sharedmodule.data-provider.services';
import { CaretxSetupsProviderService } from '../../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFile } from '../../../../../../src/app/models/customer';
import { Departments, NursingStatus } from '../../../../../../src/app/models/generalhousedsetups';
import { NgForm } from '@angular/forms';
import { CaretxNursingProviderService } from '../../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { NurseFollowUp } from '../../../../../../src/app/models/nurseadmission';
import { Attachment } from '../../../../../../src/app/models/attachments';
import { DocViewerComponent } from '../../../../caretx-shared-module/CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { AttachmentDialogComponent } from '../../../../caretx-shared-module/CaretexModals/attachment-dialog/attachment-dialog.component';
import { PersonalMeasures, PersonalMeasuresHeader } from '../../../../../../src/app/models/measure';
import { LabCheck, LabCheckHeader } from '../../../../../../src/app/models/testlabchecks';
import { PhysioTherapyFollowUp } from '../../../../../../src/app/models/physiotherapy';
import { OccupationalTherapyFollowUp } from '../../../../../../src/app/models/occupationalTherapy';
import { MedicalObservation } from '../../../../../../src/app/models/Observations';
import { PagenationObject } from '../../../../../../src/app/models/pagenation';
import { BridgeEnums, OptionObject } from '../../../../../../src/app/services/enums';
import { OccupationalDataProviderService } from '../../../../../../src/app/caretex-occupational-therapy/ModuleServices/occupational.data-provider.service';
import { CaretxMedicalProviderService } from '../../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { DieticianDataProviderService } from '../../../../../../src/app/caretex-dietician/Dietician-services/dietician.data-provider.service';
import { MedicalDiagnosesProviderService } from '../../../../../../src/app/caretx-doctors-module/doctor-services/medical-diagnoses.data-provider.service';
import { CaretxPhsioTherapyProviderService } from '../../../../../../src/app/cartex-physiotherapy-module/Physiotherapty.data-provider.service';
import { DrugInstruction } from '../../../../../../src/app/models/drugInstruction';
import { Diagnoses } from '../../../../../../src/app/models/diagnoses';
import { BedSore } from '../../../../../../src/app/models/bedsore';
import { Observable, Subject, of } from 'rxjs';
import { CartexEvent } from '../../../../../../src/app/models/genericTypes';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import { NurseDialogFollowupsubjectComponent } from '../../../../../../src/app/caretx-shared-module/CaretexModals/nurse-dialog-followupsubject/nurse-dialog-followupsubject.component';
import { BaseOmdan } from '../../../../../../src/app/models/omdans';
import Swal from 'sweetalert2';
import { DieticianFollowUp } from '../../../../../../src/app/models/DieticianFollowup';
import { DailyMealReport } from '../../../../../../src/app/models/Dieticianfoodintake';
import { CaretxSocialProviderService } from '../../../../../../src/app/caretx-social-module/Social.data-provider.service';
import { FamilyConversation, FamilyVisit } from '../../../../../../src/app/models/socialfamilyvisits';
import { PersonelHazards } from '../../../../../../src/app/models/hazard';
import { VasFpsPainExam } from '../../../../../../src/app/models/OmdanPain';
import { MatDialog } from '@angular/material/dialog';
import { DieticianRecommendation } from 'src/app/models/dietaryRecommendations';

type Items1 = {
  DepartmentName: string;
  ID: string
};

@Component({
  selector: 'app-cenrtalizednusrsingfollowup',
  templateUrl: './cenrtalizednusrsingfollowup.component.html',
  styleUrls: ['./cenrtalizednusrsingfollowup.component.scss']
})

export class CenrtalizednusrsingfollowupComponent extends CaretexComponent implements OnInit {

  show = 'tab1';
  TabId = "main"

  moduleName: string = "מעקב סיעודי"
  FileCategoryList: OptionObject[] = [{ id: 'dieticianobsrvfile', name: 'מסמכי מעקב סיעודיים' },]
  currentNurseFollowUp: NurseFollowUp
  followups: NurseFollowUp[] = []
  pagenationObject: PagenationObject
  personalId: string
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  saveProcessCompleted: boolean
  @ViewChild('mainForm') mainForm: NgForm;
  LabCodeResults: LabCheck[]
  LastCreatinineVal: string = "-"
  HeightMeasure: PersonalMeasures
  WeightMeasure: PersonalMeasures
  pdfContent: any
  LoggedInUser: string
  selectedHistoricReport: DieticianFollowUp

  /**************New Params************* */
  newRecord: boolean = false
  public editorDisabled = true;
  medicationtype: string = ""
  drugInstructions: DrugInstruction[] = []
  diagnoses: Diagnoses[] = []
  personMeasureHeaders: PersonalMeasuresHeader[] = []
  checkHeaders: LabCheckHeader[];
  showMeasuresDetails: boolean = false
  showLabResult: boolean = false
  currentPersonelMeasureHeader: PersonalMeasuresHeader
  CurrentLabResultId: string
  bedSores: BedSore[] = []
  expandDieticianHistoricReport: boolean = false
  selectedMedicalHistoricReport: MedicalObservation
  expandMedicalHistoricReport: boolean = false
  expandNursingHistoricReport: boolean = false
  selectedNursingHistoricReport: NurseFollowUp
  expandPhsioHistoricReport: boolean = false
  selectedPhysioHistoricReport: PhysioTherapyFollowUp
  expandOccupationalHistoricReport: boolean = false
  selectedOccupationalHistoricReport: OccupationalTherapyFollowUp
  selectedMedicalFile: MedicalFile
  selectedItems1: Items1[] = [];
  dropdownSettings1 = {};
  DefaultPhotoURL: string = "../../../assets/images/user-avatar.png"
  selectedPatient: boolean
  filteredData: MedicalFile[] = [];
  renderedData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];
  totalNumOfRecords: number
  departments: Departments[] = []
  Omdanem: BaseOmdan[] = []
  vasfpsOmdanem:VasFpsPainExam[] =[]
  hazards: PersonelHazards[] = []
  familyVisits: FamilyVisit[] = []
  selectedOmdanType: string
  selectedOmdan: BaseOmdan
  IsMedicalFileSelected: boolean = false
  OmdanId: string = ""
  public config: any = {
    airMode: false,
    tabDisable: true,
    height: '50px',
    toolbar: [
    ],
  };
  public config2: any = {
    airMode: false,
    tabDisable: true,
    height: '500px',
    toolbar: [
    ],
  };


  ExpandNurseInstructionAdd: boolean = false


  showInfoPerDepartment:boolean = false
  startDate:Date
  endDate:Date

  /************************************* */

  constructor(
    private _title: Title,
    private router: Router,
    private _ar: ActivatedRoute,
    private bridgeEnum: BridgeEnums,
    public dialog: MatDialog,
    private caretxOccupationalProviderService: OccupationalDataProviderService,

    private caretxSharedlService: CaretxSharedModulesProviderService,
    private caretxMedicalService: CaretxMedicalProviderService,
    private caretxDieticianProviderService: DieticianDataProviderService,
    private caretxSocialProviderService: CaretxSocialProviderService,
    public medicalFileService: MedicalFileProviderService,
    private medicalDiagnosesService: MedicalDiagnosesProviderService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private phsiotheraptyService: CaretxPhsioTherapyProviderService,
    private caretxSetupService: CaretxSetupsProviderService,
    private toastrService: ToasterService,
    private authService: AuthService,
    private route: ActivatedRoute,) {
    super(medicalFileService);
    this.LoggedInUser = this.authService.LoggedInUser.UID


    this.caretxSetupService.GetUserAllowedDepartments(this.authService.LoggedInUser.UID).subscribe(data => {
      this.departments = data
      this.selectedItems1 = JSON.parse(localStorage.getItem('user_selected_departments') || '{}');
      if (this.selectedItems1.length == 0) {
        if (this.departments.length > 0) {
          this.departments.forEach(element => {
            this.selectedItems1.push({ "DepartmentName": element.DepartmentName, "ID": element.ID })
          });
        } else {
        }
      }
    })

    this.selectedMedicalFile = new MedicalFile()
    this.selectedMedicalFile.FirstName = "עדיין לא נבחר"
    this.selectedMedicalFile.LastName = "."
    this.selectedMedicalFile.PersonelID = "-"
    this.selectedMedicalFile.ImagePath = "../../assets/images/user-avatar.png"
    this.selectedMedicalFile.Department = new Departments()
    this.selectedMedicalFile.Department.DepartmentName = "עדיין לא נבחר"
    this.selectedMedicalFile.Status = new NursingStatus()


  }

  ngOnInit(): void {
    this.personalId = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.personalId)
    this.IsMedicalFileSelected = false
    this.loadData2("")
    this.doInitialization()
  }


  doInitialization() {
    // Get LabCategories :
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: "ID",
      textField: "DepartmentName",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }

  loadData2(filter: string) {
    this.medicalFileService.getMedicalFiles(filter);
    this.medicalFileService.dataChange.subscribe((data) => {
      this.filteredData = this.allFiles = data
      this.filteredData.forEach(element => {
        element.FullName = element.FirstName + " " + element.LastName
      });
      this.totalNumOfRecords = this.allFiles.length
    })
  }

  getFamilyVisitsFromDB(filter: string) {
    this.caretxSocialProviderService.getFamilyVisits(this.personalId, filter, "list").subscribe(
      data => {
        this.familyVisits = data
        this.totalNumOfRecords = data.length
      },
      err => {
        this.familyVisits = []
      }
    )
  }

  getPersonelHazardsFromDB(filter: string) {

    this.caretxMedicalService.getPersonelHazard(this.personalId, filter).subscribe(
      data => {
        this.hazards = data

      },
      err => {
        this.hazards = []
      }
    )
  }

  getNurseFollowUpById(id: string, followupid: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + followupid + '\'' + ')'
    this.caretxNursingProviderService.getNurseFollowUps(id, filter, mode, "1").subscribe((data: NurseFollowUp[]) => {
      if (data.length == 1) {
        this.currentNurseFollowUp = new NurseFollowUp(data[0])
        if (this.currentNurseFollowUp.Notifications != undefined) {
        }

      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  openNewFollowUpReport() {
    const dialogRef = this.dialog.open(NurseDialogFollowupsubjectComponent, {
      data: {
        id: this.personalId,
        dieticianFollowUp: this.currentNurseFollowUp,
        isDisabled: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined || result == null || result == "") return
      this.newRecord = true
    });
  }

  cancelNewFollowUpReport() {
    this.newRecord = false
  }

  familyConversatinos: FamilyConversation[] = []
  getFamilyConversationsFromDB(filter: string) {
    this.caretxSocialProviderService.getFamilyConverstions(this.personalId, filter, "list").subscribe(
      data => {
        this.familyConversatinos = data
        this.totalNumOfRecords = data.length
      },
      err => {
        this.familyConversatinos = []
      }
    )
  }

  getFollowUpListFromDB(page: string) {
    this.caretxNursingProviderService.getNurseFollowUps(this.personalId, "", "list", page).subscribe((data: NurseFollowUp[]) => {
      if (data.length > 0) {
        this.followups = data
      }
      else {
        this.followups = []
      }
    });
  }

  getTotalNumOfFollowUpsFromDB() {
    this.caretxDieticianProviderService.getTotalOfDieticianFollowups(this.personalId).subscribe((data: number) => {
      this.pagenationObject = new PagenationObject(data)
    });
  }

  getDrugInstrFromDB(filter: string) {
    this.caretxMedicalService.getDrugInstrucs(this.personalId, filter).subscribe(
      data => {
        this.drugInstructions = data
      },
      err => {
        this.drugInstructions = []
      }
    )
  }

  getPersonalMeasureHeadersFromDB(filter: string) {
    this.caretxNursingProviderService.getAllPersonalMeasureHeaders(this.personalId, "all", "", "list", filter).subscribe(
      data => {
        this.personMeasureHeaders = data
      },
      err => {
        this.personMeasureHeaders = []

      }
    )
  }

  getPersonalDiagnosesFromDB() {
    this.medicalDiagnosesService.getPersonelDiagnoses(this.personalId).subscribe(
      data => {
        this.diagnoses = data
      },
      err => {
        this.diagnoses = []

      }
    )
  }

  getBedSoresFromDB(filter: string) {
    this.caretxNursingProviderService.GetBedSores(this.personalId, filter, "list").subscribe(
      data => {
        this.bedSores = data
      },
      err => {
        this.bedSores = []

      }
    )
  }

  getLaboratortCheckHeaders() {
    this.caretxMedicalService.getLaboratoryResultsHeaders(this.personalId, null).subscribe(
      data => {
        this.checkHeaders = data
      },
      err => {
        console.log("Error", err)
        this.checkHeaders = []
      }
    )
  }

  medicalFollowups: MedicalObservation[] = []
  getMedicalFollowUpListFromDB(page: string) {
    this.caretxMedicalService.getMedicalObservations("", this.personalId, "list", page).subscribe((data: MedicalObservation[]) => {
      if (data.length > 0) {
        this.medicalFollowups = data
      }
      else {
        this.medicalFollowups = []
      }
    });
  }

  Occupationalfollowups: OccupationalTherapyFollowUp[] = []
  getOccupationalFollowUpListFromDB(page: string) {
    this.caretxOccupationalProviderService.getOccupationalFollowUps(this.personalId, "", "list", page).subscribe((data: OccupationalTherapyFollowUp[]) => {
      if (data.length > 0) {
        this.Occupationalfollowups = data
      }
      else {
        this.Occupationalfollowups = []
      }
    });
  }

  getNurseFollowUpListFromDB(page: string) {
    this.caretxNursingProviderService.getNurseFollowUps(this.personalId, "", "list", page).subscribe((data: NurseFollowUp[]) => {
      if (data.length > 0) {
        this.followups = data

      }
      else {
        this.followups = []
      }
    });
  }

  phsiotheraptyFollowups: PhysioTherapyFollowUp[] = []
  getPhysioFollowUpListFromDB(page: string) {
    this.phsiotheraptyService.getPhysioTherapyFollowUps(this.personalId, "", "list", page).subscribe((data: PhysioTherapyFollowUp[]) => {
      if (data.length > 0) {
        this.phsiotheraptyFollowups = data
      }
      else {
        this.phsiotheraptyFollowups = []
      }
    });
  }


  recommendations: DieticianRecommendation[] = []
  getDieticianRecommendations() {
    let filter = ""
    this.caretxDieticianProviderService.getDieticianRecommendation(this.personalId, filter, "").subscribe(
      data => this.recommendations = data,
      err => {
        this.recommendations = []
      }
    )
  }

  SaveFollowUp() {
    this.currentNurseFollowUp.LastUpdateById = this.authService.LoggedInUser.UID
    this.currentNurseFollowUp.UpdatedAt = this.currentNurseFollowUp.CreatedAt
    this.DoSave()
  }

  DoSave() {

    this.caretxNursingProviderService.createNewNurseFollowUp(this.currentNurseFollowUp).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentNurseFollowUp = data as NurseFollowUp

        this.saveProcessCompleted = true;
        this.medicalFileService.ResetCachedMedicalFile(this.personalId)
        this.resetModuleAfterSave()
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.getNurseFollowUpListFromDB("1")
        this.newRecord = false

      },
      error: error => {
        this.toastrService.Error("", error.error.message)
        this.saveProcessCompleted = true;
      }
    })
  }

  HandlePagenation($event) {
    this.getFollowUpListFromDB($event)
  }

  CheckIfDocumentIsDirty() {

    if (!this.isRowExpanded && this.newRecord)
      return this.mainForm.dirty
    else
      return false
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  resetModuleAfterSave() {
    this.mainForm.reset(this.mainForm.value);

  }

  // get CREATININE values
  getLastXMeasuresOfMeasureCodeY() {
    let testName: string = "CREATININE"
    let filter = '(test_name=' + '\'' + testName + '\'' + ')'
    this.caretxMedicalService.getLabCodeResults(this.personalId, filter).subscribe(
      (data: LabCheck[]) => {
        this.LabCodeResults = []


        data.forEach(labResult => {
          this.LabCodeResults.push(new LabCheck(labResult))
        })

        if (this.LabCodeResults.length > 0) {
          this.LastCreatinineVal = this.LabCodeResults[0].TestValue.toString() + " " + this.LabCodeResults[0].LabTemplate.Uom
        }

      },
      err => {
        console.log("Error", err)
        this.LabCodeResults = []

      }
    )
  }

  CopyLastReportedFollowUp() {
    if (this.followups.length > 0) {
      this.currentNurseFollowUp.RichText = this.followups[0].RichText
    }
  }

  ClearTextField() {
    this.currentNurseFollowUp.RichText = ""
  }

  AttachFile() {
    const dialogRef = this.dialog.open(AttachmentDialogComponent, {
      data: {
        UserId: this.LoggedInUser,
        FileCategoryList: this.FileCategoryList,
        Id: this.personalId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Save Diagnoses
      if (result == undefined || result == null || result == "") return
      this.updateRefreshedObservFiles()
    });
  }

  updateRefreshedObservFiles() {
    let filter: string = "file_category = 'nurseobsrvfile'"
    this.caretxSharedlService.getAttachments(this.personalId, filter).subscribe((data: Attachment[]) => {
      if (data.length > 0) {
        this.currentNurseFollowUp.NurseObservationFiles = data
      }
      else {
        // this.followups = []
      }
    });
  }

  downloadFile(file: Attachment) {
    const dialogRef = this.dialog.open(DocViewerComponent, {
      data: {
        Type: 'GF', // General File
        Id: this.personalId,
        Attachment: file
      }
    });
  }

  enableContent(type) {
    if (this.IsMedicalFileSelected) {
      switch (type) {
        case "tab1":
          this.getDrugInstrFromDB("")
          break
        case "tab2":
          this.getPersonalDiagnosesFromDB()
          break
        case "tab3":
          this.getPersonalMeasureHeadersFromDB("")
          break
        case "tab3.1":

          break
        case "tab4":
          this.getBedSoresFromDB("")
          break
        case "tab5":
          this.getLaboratortCheckHeaders()
          break
        case "tab6":
          this.getNurseFollowUpListFromDB("1")
          break
        case "tab7":
          this.getMedicalFollowUpListFromDB("1")
          break
        case "tab8":
          break
        case "tab9":
          this.getPhysioFollowUpListFromDB("1")
          break
        case "tab10":
          this.getOccupationalFollowUpListFromDB("1")
          break
        case "tab11":
          this.getOmdanemFromDB()
          break
        case "tab12":
          this.getDailyFoodReportsromDB("")
          break
        case "tab14":
          break
        case "tab13":
          this.getFamilyConversationsFromDB("")
          break

        case "tab15":
          this.getPersonelHazardsFromDB("")
          break
        case "tab16":
          this.getFamilyVisitsFromDB("")
          break
          case "tab17":
          this.getDieticianRecommendations()
          break


      }
      this.show = type;
    }


  }

  onOImdanTypeChange($event) {
    this.selectedOmdanType = $event.currentTarget.value
    this.getOmdanemFromDB()
  }

  getOmdanemFromDB() {
    let filter: string = "origin <> 'admission'" // Origin is not admission
    if (this.selectedOmdanType == undefined || this.selectedOmdanType == "") {
      Swal.fire({
        title: "הודעת מערכת",
        text: "צריך לבחור סוג מבחן !",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'סגור',
        allowOutsideClick: false,
        allowEscapeKey: false,

      })
    } else {

      if (this.selectedOmdanType != "pain") {
        this.caretxNursingProviderService.getOmdanem(this.personalId, filter, this.selectedOmdanType).subscribe(
          data => this.Omdanem = data,
          err => {
            this.Omdanem = []
          }
        )
      }else {
        this.caretxNursingProviderService.getVasFpsPainOmdanem(this.personalId,"","").subscribe(
          data => this.vasfpsOmdanem = data,
          err => {
            this.vasfpsOmdanem = []
          }
        )

      }

    }

  }

  OnSDrillToLabResult($event, checkHeader) {
    this.CurrentLabResultId = checkHeader.ID
    this.showLabResult = true
  }

  closeLabRecord() {
    this.showLabResult = false
  }

  OnSDrillToeventDetails($event, measureHeader) {
    this.currentPersonelMeasureHeader = measureHeader
    this.showMeasuresDetails = true
  }

  closeMeasureRecord() {
    this.showMeasuresDetails = false
  }

  closeExpandedDocument() {
    switch (this.show) {
      case "tab1":
        this.expandNursingHistoricReport = false
        break
      case "tab6":
        this.expandNursingHistoricReport = false
        break
      case "tab7":
        this.expandMedicalHistoricReport = false
        break
      case "tab9":
        this.expandPhsioHistoricReport = false
        break
      case "tab10":
        this.expandOccupationalHistoricReport = false
        break
      case "tab11":
        this.expandOmdan = false
        break

    }
    this.expandDieticianHistoricReport = false
    this.isRowExpanded = false
    this.showInNewPage = false
    this.ExpandBedSore = false
    this.ExpandfoodReport = false
    this.ExpandNurseInstructionAdd = false
    this.ExpandFamilyTalk = false
    this.ExpandFamilyVisit = false
    this.ExpandHazard = false
    this.ExpandPainMonitoring = false
    if (this.show == "tab11") {
      this.getOmdanemFromDB()
    }
    if (this.show == "tab12") {
      this.getDailyFoodReportsromDB("")
    }

    if (this.show == "tab13") {
      this.getFamilyConversationsFromDB("")
    }

    if (this.show == "tab15") {
      this.getPersonelHazardsFromDB("")
    }
    if (this.show == "tab16") {
      this.getFamilyVisitsFromDB("")
    }


  }

  isRowExpanded: boolean = false
  showInNewPage: boolean = false

  expandHistoricReportRow(type, record) {

    switch (type) {
      case "dietician":
        this.selectedHistoricReport = record
        this.expandDieticianHistoricReport = true
        break
      case "nurse":
        this.selectedNursingHistoricReport = record
        this.expandNursingHistoricReport = true

        break
      case "medical":
        this.selectedMedicalHistoricReport = record
        this.expandMedicalHistoricReport = true

        break
      case "phsio":
        this.selectedPhysioHistoricReport = record
        this.expandPhsioHistoricReport = true

        break
      case "Occupational":
        this.selectedOccupationalHistoricReport = record
        this.expandOccupationalHistoricReport = true

        break
    }
    this.isRowExpanded = true
    this.showInNewPage = false

  }

  /**************************** */

  OpenDepartmentSideMenue() {
    this.selectedPatient = true
    this.showInfoPerDepartment = false
  }

  OpenDepartmentSideMenue2() {
    this.selectedPatient = true
    this.showInfoPerDepartment = true
    this.endDate = new Date();
    this.startDate= new Date(new Date().setDate(this.endDate.getDate() - 1));

  }

  showDepMandatoryLbl: boolean = false
  selectedMedicalFile$: Observable<MedicalFile>;
  onDepartmentSelect($event) {
    this.filteredData = []
    this.showDepMandatoryLbl = false
    this.prepareFilterToSearchMedicalFiles()
  }

  onItemDeSelect($event) {
    this.filteredData = []
    if (this.selectedItems1.length == 0) {
      this.filteredData = []
      this.showDepMandatoryLbl = true
    }
    this.prepareFilterToSearchMedicalFiles()
  }

  onSelectingAllDepartments(items: any) {
    this.selectedItems1 = items
    this.showDepMandatoryLbl = false
    this.filteredData = this.allFiles
    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));
  }

  onDeSelectAll(items: any) {
    this.selectedItems1 = []
    this.filteredData = []
    this.showDepMandatoryLbl = true
    this.prepareFilterToSearchMedicalFiles()
  }

  prepareFilterToSearchMedicalFiles() {
    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));

    if (this.selectedItems1.length > 0) {
      this.allFiles.forEach(file => {
        const found = this.selectedItems1.some(x => x.ID === file.DepartmentId)
        if (found) this.filteredData.push(file)

      });
    }
  }

  closeSideNavePatient(medicalFile: MedicalFile) {
    this.selectedMedicalFile = medicalFile
    this.personalId = this.selectedMedicalFile.PersonelID
    this.selectedMedicalFile$ = of(this.selectedMedicalFile)
    this.medicalFileService.currentSelectedFile = this.selectedMedicalFile
    this.selectedPatient = false
    this.IsMedicalFileSelected = true
    this.getTotalNumOfFollowUpsFromDB()
    this.getNurseFollowUpById(this.personalId, "", "new")
    this.getNurseFollowUpListFromDB("1")


    this.enableContent(this.show)

  }


  getNurseFollowUpListReportFromDB() {
    this.caretxNursingProviderService.getNurseFollowUpsForReport(this.selectedItems1 , this.startDate , this.endDate).subscribe((data: NurseFollowUp[]) => {
      if (data.length > 0) {
        this.followups = data

      }
      else {
        this.followups = []
      }
    });
  }
  

  closeSideNavBar() {
    this.selectedPatient = false
    if (this.showInfoPerDepartment ) {
        this.getNurseFollowUpListReportFromDB() 
    }
  }




  /****************** */
  expandOmdan: boolean = false
  OnSDrillToOmdan($event, omdan) {
    this.selectedOmdan = omdan
    this.expandOmdan = true
    this.isRowExpanded = true
    this.showInNewPage = true
    this.OmdanId = this.selectedOmdan.ID
  }

  generateReport($event, bhoseOmdan) {
  }

  deleteEvent(i) {
  }

  NewOmdan() {
    this.show = "tab11"
    this.isRowExpanded = true
    this.showInNewPage = true
    this.expandOmdan = true
    this.OmdanId = ""
  }

  /*****************BedSore/**************/
  ExpandBedSore: boolean = false
  BedSoreId: string
  OpenBedsoreRecord(bedsore) {
    this.BedSoreId = bedsore.ID
    this.ExpandBedSore = true
    this.isRowExpanded = true
    this.showInNewPage = true

  }
  NewBedSore() {
    this.BedSoreId = ""
    this.ExpandBedSore = true
    this.isRowExpanded = true
    this.showInNewPage = true
  }

  /***************************************** */
  reports: DailyMealReport[] = []
  foodReportId: string = ""
  getDailyFoodReportsromDB(filter: string) {
    this.caretxDieticianProviderService.getDieticianMealsReport(this.personalId, filter, "list").subscribe(
      data => {
        this.reports = data
        this.totalNumOfRecords = data.length
      },
      err => {
        this.reports = []
      }
    )
  }
  ExpandfoodReport: boolean = false
  OnSDrillToFoodReportDetail($event, record) {
    this.foodReportId = record.ID
    this.ExpandfoodReport = true
    this.isRowExpanded = true
    this.showInNewPage = true
  }
  generateFoodReportDetail($event, record) {

  }

  /***************************************************** */

  NewNurseInstruction() {
    this.ExpandNurseInstructionAdd = true
    this.isRowExpanded = true
    this.showInNewPage = true
  }

  /******************************************************************** */
  FamilyTalkId: string = ""
  ExpandFamilyTalk: boolean = false
  OnSDrillToConversation($event, conversation) {
    this.FamilyTalkId = conversation.ID
    this.ExpandFamilyTalk = true
    this.isRowExpanded = true
    this.showInNewPage = true
  }

  NewFamilyTalk() {
    this.ExpandFamilyTalk = true
    this.isRowExpanded = true
    this.showInNewPage = true
    this.FamilyTalkId = "new"
  }
  generateFamilyTalkDetailReport($event, record) {

  }

  /************************************************************************ */

  ExpandHazard: boolean = false
  HazardId: string = ""
  OnSDrillToHazard($event, hazard) {
    this.HazardId = hazard.ID
    this.ExpandHazard = true
    this.isRowExpanded = true
    this.showInNewPage = true
  }


  NewHazard() {
    this.HazardId = "new"
    this.ExpandHazard = true
    this.isRowExpanded = true
    this.showInNewPage = true
  }
  generateHazardReport($event, record) {

  }

  /******************************************************************************* */

  FamilyVisitId: string = ""
  ExpandFamilyVisit: boolean = false
  OnSDrillToVisit($event, visit) {
    this.FamilyVisitId = visit.ID
    this.ExpandFamilyVisit = true
    this.isRowExpanded = true
    this.showInNewPage = true
  }

  NewFamilyVisit() {
    this.ExpandFamilyVisit = true
    this.isRowExpanded = true
    this.showInNewPage = true
    this.FamilyVisitId = "new"
  }
  generateFamilyVisitDetailReport($event, record) {

  }


  filterPaitents($event) {
    let filter: string = $event.currentTarget.value
    this.filteredData = this.allFiles
    this.allFiles = this.medicalFileService.data
    this.filteredData = this.medicalFileService.data.slice().filter((medicalFile: MedicalFile) => {
      const searchStr = (medicalFile.PersonelID + medicalFile.FirstName + medicalFile.LastName).toLowerCase();
      return searchStr.indexOf(filter.toLowerCase()) !== -1;
    });
  }

  /************************************************************************* */

  ExpandPainMonitoring :boolean = false


  OpenPainReport() {
    this.ExpandPainMonitoring = true
    this.isRowExpanded = true
    this.showInNewPage = true
  }

}
