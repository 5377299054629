import { Component, Inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { DrugInstruction } from 'src/app/models/drugInstruction';
import { ToasterService } from 'src/app/services/toastr.service';


export interface ChipColor {
  name: string;
  color: ThemePalette;
}



@Component({
  selector: 'app-medication-instructions-dialog',
  templateUrl: './medication-instructions-dialog.component.html',
  styleUrls: ['./medication-instructions-dialog.component.css']
})
export class MedicationInstructionsDialogComponent {


  filteredDrugInstruction: DrugInstruction[]
  id: string
  status: string
  selectAll: boolean = false

  availableColors: ChipColor[] = [

    { name: 'בחר', color: 'primary' },

  ];


  constructor(
    private _title: Title,
    private toastrService: ToasterService,
    private dialogRef: MatDialogRef<MedicationInstructionsDialogComponent>,
    private caretxMedicalService: CaretxMedicalProviderService, @Inject(MAT_DIALOG_DATA) public data: any
  ) {


  }



  ngOnInit(): void {
    this._title.setTitle('Caretex|הוראות תרופות');
    this.id = this.data.id;
    this.status = this.data.status


    this.getDrugInstrFromDB("")
    this.dialogRef.updateSize('720px', '700px')
  }


  getDrugInstrFromDB(filter: string) {
    this.caretxMedicalService.getDrugInstrucs(this.id, filter).subscribe(
      data => {
        this.filteredDrugInstruction = data
      },
      err => {
        this.filteredDrugInstruction = []
      }
    )
  }

  submit() {


    this.caretxMedicalService.batchUpdateDrugInstr(this.id, this.filteredDrugInstruction).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.dialogRef.close(this.filteredDrugInstruction)

      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
        this.dialogRef.close(this.filteredDrugInstruction)
      }
    })

  }

  markAsSelected(drugInst, index) {
    this.filteredDrugInstruction[index].Modified = true
    this.filteredDrugInstruction[index].AllowOnRelease = !this.filteredDrugInstruction[index].AllowOnRelease
  }

  selectAllRows() {
    this.selectAll = !this.selectAll
    this.filteredDrugInstruction.forEach((value, index) => {
      this.filteredDrugInstruction[index].Modified = true
      if ( this.selectAll){

        this.filteredDrugInstruction[index].AllowOnRelease = true
      }else {
        
      this.filteredDrugInstruction[index].AllowOnRelease = false
      }

    });
  }
}
