import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../../../../src//app/shared/CaretexComponent';

@Component({
  selector: 'app-bohse',
  templateUrl: './bohse.component.html',
  styleUrls: ['./bohse.component.css']
})
export class BohseComponent extends CaretexComponent  implements OnInit {
  isSubModule:boolean = false
  id:string 
  constructor(  
    private route: ActivatedRoute,
    private _title: Title, 
    public medicalFileService: MedicalFileProviderService,) 
    {
      super(medicalFileService)

  }

  ngOnInit(): void {
    this._title.setTitle('Caretx |  אומדני פה');
    if(this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
  }

  // Get File 
  
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }
}
