import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxMedicalProviderService } from '../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { CartexReport } from '../../../../../src/app/models/personeldocument';
import { LabRequests } from '../../../../../src/app/models/testlabchecks';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ReportingService } from '../../../../../src/app/services/reporting.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';


@Component({
  selector: 'app-nurse-lab-orders',
  templateUrl: './nurse-lab-orders.component.html',
  styleUrls: ['./nurse-lab-orders.component.css']
})
export class NurseLabOrdersComponent extends CaretexComponent implements OnInit {

  labRequests: LabRequests[]
  id: string
  pdfContent: any
  alertTitle: string
  deleteAlert: string
  deleteSysMsgId: string
  currentRowIndex: number
  modalTarget: string
  moduleName: string = "בקשות למעבדה"
  constructor(
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private router: Router,
    private authServive: AuthService,
    private toastrService: ToasterService,
    private route: ActivatedRoute,
    private reportingService: ReportingService,
    private labService: CaretxMedicalProviderService,
  ) { super(medicalFileService) }

  ngOnInit(): void {
    this._title.setTitle('Caretex |  הוראות מעבדה');
    this.alertTitle = "הודעת מערכת"
    if(this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
    this.getLaboratortRequests()
  }

  getLaboratortRequests() {
    this.labService.getLaboratoryRequests(this.id).subscribe(
      data => {
        this.labRequests = data
      },
      err => {
        console.log("Error", err)
        this.labRequests = []
      }
    )
  }

  OnSDrillToLaboratoryRequest($event, labRequest: LabRequests) {
    this.router.navigate(["/caretexfile/" + this.id + "/laboratory/orders/" + labRequest.ID], { queryParams: { filter: 'view' } })
  }

  handleDeleteAlerMsg($event) {
  }

  OnDone($event, labrequest: LabRequests) {
    labrequest.RequestStatus = "D"
    labrequest.ExecutedById = this.authServive.LoggedInUser.UID
    this.labService.updateLaboratoryRequestById(this.id, labrequest).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getLaboratortRequests()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  OnAssign($event, labrequest: LabRequests) {
    labrequest.RequestStatus = "S"
    labrequest.ExecutedById = this.authServive.LoggedInUser.UID
    this.labService.updateLaboratoryRequestById(this.id, labrequest).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getLaboratortRequests()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }


  
  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  isPdfFile:boolean = false
  file223 :string = ""

  generateReport($event,labrequest:LabRequests) {
    var careTexReport =  new CartexReport()
    careTexReport.PersonelID = this.id
    careTexReport.ReportName = "LabRequest"
    careTexReport.ReportType ="laboratory"
    careTexReport.ReportParam = labrequest.ID
    careTexReport.GeneratedBy  = this.authServive.LoggedInUser
    careTexReport.GeneratedOn =  new Date()

    this.reportingService.generateReport(this.id, careTexReport)
    .subscribe(data => {
      var file = new Blob([data], { type: 'application/pdf' });
      this.pdfContent = URL.createObjectURL(file);
      this.pdfViewer.pdfSrc = file
      this.showGeneratedPdf = true
      this.pdfViewer.refresh();

    }, error => {
      console.log(error);
      var errorAsString = "failed to download file"
      try {
        var enc = new TextDecoder("utf-8")
        var text = enc.decode(error.error)
        errorAsString = JSON.parse(text).error
      } catch (e) {

      }
    })
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }


}
