import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxNursingProviderService } from '../../../../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { BaseOmdan, OmdanPossibleAnswer, OmdanQuestionAnswer, OmdanQuestions } from '../../../../../../../../src/app/models/omdans';
import { AuthService } from '../../../../../../../../src/app/services/auth.service';
import {  NurseAdmissionSteps } from '../../../../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { MedicalFile } from 'src/app/models/customer';

@Component({
  selector: 'app-bhosesubmodel',
  templateUrl: './bhosesubmodel.component.html',
  styleUrls: ['./bhosesubmodel.component.css']
})
export class BhosesubmodelComponent implements OnInit {
  @Input() IsSubModule: boolean
  @Input() omdanBase: BaseOmdan
  @Input() OmdanId: string
  @Output() backToOmdanemListEvent = new EventEmitter<NurseAdmissionSteps>();
  @Output() personalBhoseExamEvent = new EventEmitter<BaseOmdan>()
  @Output() ModuleChanged = new EventEmitter<boolean>();
  @Output() backToOmdanemList = new EventEmitter<void>();
  @Output() backToCentralPage = new EventEmitter<void>();
  @Input() PersonalId: string
  @Input() IsFromCentralizedPage: boolean = false
  @Input() SelectedFile: MedicalFile

  modalTarget: string
  omdanName: string = "bohse"
  _doCheckBeforeSave: boolean
  questions: OmdanQuestions[] = []

  id: string
  isomdanClosed: boolean = false;
  omdanScore: number = 0
  scorepercentage: number = 0
  moduleName: string = "אומדן פה"
  @ViewChild('mainform') mainFormElement: NgForm;

  constructor( 

    private authServive: AuthService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToasterService,) {
  }

  ngOnInit(): void {
    if (this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'];


    if (this.IsFromCentralizedPage) {
      this.id = this.PersonalId
    }else {
      this.OmdanId = this.route.snapshot.params.bhoseid 
    }


    // check ifNew Omdan 
    if (this.OmdanId == null || this.OmdanId == "" || this.OmdanId == "00000000-0000-0000-0000-000000000000" || this.OmdanId =="new"){
      if (this.IsSubModule) {
        this.newOmdan("admission")
      }else {
        this.newOmdan("list")
      }
    }else {
      this.getOmdanFromDB(this.OmdanId)
    }


  }



  newOmdan(origin: string) {
    this.omdanBase = new BaseOmdan()
    let dateOfNow: Date = new Date();
    this.omdanBase.CreatedAt = dateOfNow
    this.omdanBase.UpdatedAt = dateOfNow
    this.omdanBase.OmdanStatus = "A"
    this.omdanBase.PersonelID = this.id
    this.omdanBase.Origin = origin
    this.omdanBase.RealmID = this.authServive.LoggedInTenantID
    this.getOmdanQuestions()
    this.personalBhoseExamEvent.emit(this.omdanBase)
  }


  getOmdanFromDB(omdanId: string) {
    let filter = '(id=' + '\'' + omdanId + '\'' + ')'
    this.caretxNursingProviderService.getOmdanem(this.id, filter, "bhose").subscribe((data: BaseOmdan[]) => {
      if (data.length == 1) {
        this.omdanBase = data[0]
        this.isomdanClosed = (this.omdanBase.OmdanStatus == "C")
        this.omdanScore = this.omdanBase.Score
        this.scorepercentage = Math.round(((this.omdanScore * 100) / 20))
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }



  getOmdanQuestions() {
    let filter = '(omdan_type=' + '\'' + this.omdanName + '\'' + ')'
    this.caretxNursingProviderService.getOmdanQuestions(this.id, filter).subscribe((data: OmdanQuestions[]) => {
      this.questions = data
      this.generateSetOfQuestionsAnswers()
    });
  }

  generateSetOfQuestionsAnswers() {
    let index: number = 0
    this.omdanBase.OmdanQuestionAnswers = []
    this.questions.forEach(element => {

      var obj = new OmdanQuestionAnswer()
      obj.Question = element
      obj.QuestionId = element.ID
      obj.CreatedAt = new Date()
      obj.UpdatedAt = new Date()
      obj.Answer = new OmdanPossibleAnswer()

      this.omdanBase.OmdanQuestionAnswers.push(obj);
      ++index;
    });
  }

  SaveOmdan() {
    if (this.omdanBase.ID == "" || this.omdanBase.ID == null || this.omdanBase.ID == undefined) {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {

    this.caretxNursingProviderService.CreateOmdan(this.omdanBase, "bhose").subscribe({
      next: data => {
        this.omdanBase = (data as BaseOmdan)
        this.isomdanClosed = (this.omdanBase.OmdanStatus == "C")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.calculateScore()

        if (!this.IsSubModule) {
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          if (!this.IsFromCentralizedPage){
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/bohse/" + this.omdanBase.ID )
          }
        }



      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  DoUpdate() {
    this.caretxNursingProviderService.UpdateOmdan(this.omdanBase, "bhose").subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.omdanBase = data as BaseOmdan
        if (this.omdanBase.OmdanStatus == 'C')
          this.isomdanClosed = true
        this.calculateScore()

        if (!this.IsSubModule) {
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          if (!this.IsFromCentralizedPage){
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/bohse/" + this.omdanBase.ID )
          }
        }

      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  onChange($event, questionAnswer) {
    questionAnswer.Question.PossibleAnswers.forEach(possibleAnswer => {
      if (possibleAnswer.ID == $event) {
        questionAnswer.Answer = possibleAnswer
        questionAnswer.AnswerId = possibleAnswer.ID
      }
    });
    this.calculateScore()


    // Synch To Parent . 
    this.notifyParentModule()
  }

  notifyParentModule() {
    this.personalBhoseExamEvent.emit(this.omdanBase)
    this.ModuleChanged.emit(this.mainFormElement.dirty==true?true:false)
  }

  calculateScore() {
    if (this.omdanBase != undefined && this.omdanBase.OmdanQuestionAnswers != undefined) {
      this.omdanScore = 0;
      this.omdanBase.OmdanQuestionAnswers.forEach(answer => {
        if (answer.Answer.AnswerValue != undefined && answer.Answer.AnswerValue != null)
          this.omdanScore += answer.Answer.AnswerValue
      });
      this.omdanBase.Score = this.omdanScore
      this.scorepercentage = Math.round(((this.omdanScore * 100) / 20))
    }
  }

  CloseOmdan() {
    if (this.checkIfAllQuestionsAnswered()) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "לא עניתה על כל השאלות ?",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    }
    else {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה לנעול מבחן זה ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תנעל',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.omdanBase.OmdanStatus = "C"
          this.omdanBase.LockedById = this.authServive.LoggedInUser.UID
          this.omdanBase.LockedDate = new Date()
          this.SaveOmdan()
        }
      })
    }
  }

  // Check if Omdan questions are answered 
  checkIfAllQuestionsAnswered() {
    const res = this.omdanBase.CheckIfAllQuestionsAnswered()
    return res;
  }

  RetrunToList() {
    if (this.IsSubModule)
      this.backToOmdanemListEvent.emit(NurseAdmissionSteps.Tests)
    else {
      this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/bohse/list")
    }
  }


  /*++++++++++++++++++++Handlers when used as Sub module ++++++++++++++++++++++++++++++*/

  BackToMainList_Sub() {
    this.backToOmdanemList
  }
  ReturnToCentralPage() {
    this.backToCentralPage.emit()
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/


}

