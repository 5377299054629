import { propertyMap } from './modelmapper'
import { User } from './user'



export class Instruction {

    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('InstructionName')
    public InstructionName:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
   public editMode :boolean = false

    constructor() {
    
       
    }


}



export class NurseInstruction {

    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('InstructionCodeId')
    public InstructionCodeId:string
    @propertyMap('InstructionCode')
    public InstructionCode:Instruction
    @propertyMap('InstructionType')
    public InstructionType:string
    @propertyMap('Frequency')
    public Frequency:Number
    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User

    @propertyMap('Time1')
    public Time1:Date
    @propertyMap('Time2')
    public Time2:Date
    @propertyMap('Time3')
    public Time3:Date
    @propertyMap('Time4')
    public Time4:Date
    @propertyMap('Time5')
    public Time5:Date

    @propertyMap('FrequencyUom')
    public FrequencyUom:string
    @propertyMap('InstrStartDate')
    public InstrStartDate:Date
    @propertyMap('InstrStopDate')
    public InstrStopDate:Date
    @propertyMap('AssignedToId')
    public AssignedToId: string
    public AssignedTo: User
    @propertyMap('Status')
    Status: string
    @propertyMap('AdditionalComments')
    AdditionalComments: string
    constructor() {
        this.PersonelID = undefined
       
    }


}


export class NurseTask {

    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('InstructionCodeId')
    public InstructionCodeId:string
    @propertyMap('InstructionCode')
    public InstructionCode:Instruction
    @propertyMap('InstructionType')
    public InstructionType:string
   
    @propertyMap('ResolveComment')
    public ResolveComment:string
    @propertyMap('DoctorComments')
    public DoctorComments:string
    @propertyMap('DueDate')
    public DueDate:Date
    @propertyMap('ResolvedAt')
    public ResolvedAt:Date
    @propertyMap('AssignedToId')
    public AssignedToId: string
    public AssignedTo: User
    @propertyMap('LoggedById')
    public LoggedById: string
    public LoggedBy: User
    @propertyMap('Status')
    Status: string

    constructor() {
        this.PersonelID = undefined
       
    }


}