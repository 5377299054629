// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  gateway:'https://caretex.me:8080',
  firebase: {
    apiKey: "AIzaSyAZascg6_Vdrb9SOM14DQALQz1Acm8YMEU",
    authDomain: "bridge-web-core-295315.firebaseapp.com",
    databaseURL: "https://bridge-web-core-295315.firebaseio.com",
    projectId: "bridge-web-core-295315",
    storageBucket: "bridge-web-core-295315.appspot.com",
    messagingSenderId: "937190457715",
    appId: "1:937190457715:web:81bdc21b6be493ec540506",
    measurementId: "G-J3LH3JLBJ8"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
