import { Injectable } from '@angular/core';

export interface OptionObject {
  id: string;
  name: string;
}



export enum FileCategory{
 BedSoreFile="bedsore",
 MedicalFile ="medicalfile",
 SocialIDFile ="socialid",
 MedicalObservationFile ="medicalobsrvfile",
 GeneralFile = "general",


}




export enum MealType{
  Morning ="1",
  Noon="2",
  Evening="3",
  Intermediate ="4",

}




export enum OriginModule{
  MedicalFollowUp ="1",
  MedicalAdmission="2",
  MedicalRelease="3",
  NurseFollowUp ="4",
  NurseAdmission="5",
  NurseRelease="6"
}



export enum AccessComponenetType{
  Module ="1",
  SubModule="2"
}

export enum DialogResult{
  OK = "1",
  Cancel ="2",
  Close = "3",
  Submit = "4"
}

export enum InstrStatus {
  Active = "A",
  SignedByDoctor = "SD",
  Cancelled = "C",
  NotReady = "D",
  Stopped = "S",
  Rejected = "R",
  
}

export enum MedicalAdmissionSteps {
  Problems = "A",
  Diagnoses = "B",
  PermenantDrugs = "C",
  PermenantDrugDetails = "C1",
  Sensitivities = "D",
  Measures = "E",
  PhysicalCheck = "F",
  Summary ="J",
  Plan = "K"
}

export enum physiotherapyDiagnosesSteps {
  General = "A",
  Check = "B",
  Summary = "C",
  Treatment = "D",
}

export enum physiotherapyAssessmentSteps {
  General = "A",
  History = "B",
 

}



export enum OccupationalTherapySteps {
  Generic = "A",
  PhysicalStatus = "B",
  Functioning1 = "C",
  Functioning2= "D",
  TreatmentPlan = "E",
  BADL = "F",
}

export enum OccupationalFollowupSteps {
  Current = "A",
  Histroy = "B",
  Files = "C",
  
}


export enum PhysioTherapyFollowupSteps {
  Current = "A",
  Diagnoses = "B",
  DrugInstructions = "C",
  Files = "D",
  
}




export enum DieticianAssessmentSteps {
  Problems = "A",
  Diagnoses = "B",
  PermenantDrugs = "C",
  Sensitivities = "D",
  Measures = "E",
  PhysicalCheck = "F",
  Summary ="G",
  Recommendations ="H",

}

export enum DieticianFollowupSteps {
  Current = "A",
  History = "B",
  BMI = "C",
  Sensitivity = "D",
 
}


export enum MainCardSteps {
  Card = "A",
  Family = "B",

}

export enum NurseTest {
  Main = "A",
  Summary = "B",

}

export enum MedicalObservationSteps {
  Current = "A",
  Diagnoses = "B",
  PhysicalCheck = "C",
  Sensitivities = "D",
  Measures = "E",
  Attachments = "F",
  Plan = "G"
}

export enum SocialRegistrationSteps {
  PersonDetails = "A",
  Family = "B",
  General = "C",
  Problem = "D",
}

export enum NoticeSteps {
  PersonDetails = "A",
  Diseases = "B",
  Drugs = "C",
  Hospitlization = "D",
  Reason = "E",
}

export enum SocialAdmissionSteps {
  History = "A",
  General = "B",
  Reasons = "C",
  Functioninig = "D",
  Social = "E",
  Diagnoses = "F",
  Risks = "G",
  Summary = "H",
  Plan = "I"
}

export enum SocialHelpReportcodes{
  General = "A",
  Family = "B",
  Income = "C",
  Evaluation = "D",
  Arrangment = "E",
  FollowUp = "F",
  General2 = "G",

}






export enum SocialTrackSteps {
  General = "A",
  Requests = "B",
  Problems = "C",
  Plan = "D",
  
}

export enum ExceptionEventSteps {
  InitialDetails = "A",
  eventDetails = "B",
  ReportedTo = "C",
  Witnesses = "D",
  NurseCheck = "E",
  DoctorCheck = "F",
  conclusion ="J",
  ReportingAsVialonce = "K",
  Signs = "L"
}

export enum BedsoreSteps  {
  BedSoreDetails = "A",
  BedSoreTrack = "B",
  BedSorePic = "C",
  BedSoreTreatments = "D",
  BedSoreInstru = "E",
  
}

export enum NurseInstructionEnum {
  Create = "A",
  List = "B",

}

export enum NurseTasksEnum {
  Active = "A",
  Archived = "B",
}

export enum MedicalReleaseSteps {
  BackGround = "A",
  Diagnoses = "B",
  PermenantDrugs = "C",
  PermenantDrugDetails = "C1",

  Hospitlization = "F",
  Measures = "D",
  Recommindations = "E"
}


export enum NurseAdmissionSteps {
  BackGround = "A",
  Sensitivity="B",
  Measures = "C",
  Tests = "D",
  PhysicalTest = "E",
  SychTest = "F",
  Summary = "G",
  Plan ="H",
  BhoseExam="D1",
  FimExam="D2",
  NortonExam="D3",
  Omdan2Exam="D4",
  Omdan4Exam="D5",
  Omdan5Exam="D6",
  SnaqExam="D7",
  DiabeticTest="D8",
  CognitiveTest="I",
  Diagnoses="J",
  Drugs="K",
}

export enum NurseFollowUpSteps {
  Main = "A",
  Sensitivity="B",
  Measures = "C",
  Tests = "D",
  PhysicalTest = "E",
  BhoseExam="D1",
  FimExam="D2",
  NortonExam="D3",
  Omdan2Exam="D4",
  Omdan4Exam="D5",
  Omdan5Exam="D6",
  SnaqExam="D7",
  CognitiveTest="F",
  OldReports="G",
}

export enum NurseReleaseSteps {

  BackGround = "A",
  Diagnoses = "B",
  PermenantDrugs = "C",
  PermenantDrugDetails = "C1",

  Hospitlization = "F",
  Measures = "D",
  Recommindations = "E"

  
 /* BackGround = "A",
  Sensitivity="B",
  Habits = "C",
  Diagnooses = "D",
  PhysicalTest = "E",
  CognitiveTest = "F",
  Summary = "G",
  Plan ="H",*/


}

export enum UserRoles {
  Doctor = "doctor",
  Nurse="nurse",
  NurseSupervisor = "snurse",
  DoctorSupervisor = "sdoctor",
  HouseManager = "hm",
  Admin = "admin",
  ScocialWorker = "sw",
  physiotherapy ="phys",
  Dietitian = "di"
}
export enum ClockHours {
  Time01 = "00:00",
  Time02="01:00",
  Time03 = "01:00",
  Time04 = "01:00",
  Time05 = "01:00",
  Time06 = "01:00",
  Time07 = "01:00",
  Time08 = "01:00",
  Time09 = "01:00",
  Time10 = "01:00",
  Time11 = "01:00",
  Time12 = "01:00",
  Time13 = "01:00",
  Time14 = "01:00",
  Time15 = "01:00",
  Time16 = "01:00",
  Time17 = "01:00",
  Time18 = "01:00",
  Time19 = "01:00",
  Time20 = "01:00",
  Time21 = "01:00",
  Time22 = "01:00",
  Time23 = "01:00",
  Time24 = "01:00",
  Time25 = "01:00",
}

export enum sideNaveGroups {
  Medical="medical",
  Nursing="nursing",
  Social="social",
  Ditanet="ditanet",
  Physiotherapy ="physiotherapy"
}
export enum Sensitivities {
  DrugSensitivities = "A",
  GeneralSensitivity = "B",
}

export enum UserInfoTabs {
  UserProfile = "A",
  UserPermessions = "B",
  UserDepartments = "C",
}


export enum CaretexOperation {
  Create = "A",
  Update = "B",
  Delete = "C",
  GenerateReport ="D",
  NavigateTo = "E"

}



/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
@Injectable()
export class CaretexDefaultValues {
  public AttachmentsCategories: OptionObject[] 

  public constructor() {
    this.AttachmentsCategories=[
      {id:'bedsore' , name:'פצעי לחץ'},
      {id:'general' , name:'כללי'},
      {id:'socialid' , name:'תעודת זהות'},
      {id:'medicalobsrvfile' , name:'מעקב רפואי'},

    ]

  }

}
/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/




@Injectable()
export class BridgeEnums {

   public DrugUOMs: OptionObject[] // יחידות מדידה לכמות תרופה
   public DrugFreqs: OptionObject[]
   public DrugFreqUoms: OptionObject[]
   public DrugInstTypes: OptionObject[] // הוראה קבועה זמנית SOS ...
   public DrugTypes: OptionObject[]
   public DrugDosageForm: OptionObject[] // matan
   public InstructionStatuses: OptionObject[] // matan
   public ExaminationStatus: OptionObject[] 
   public MobilityStatuses:OptionObject[] 
   public UrinateMethods:OptionObject[]
   public OxygenMethods:OptionObject[]
   public TransfersStatuses:OptionObject[]
   public WashingStatuses:OptionObject[]
   public ClothingStatuses:OptionObject[]
   public FeedingStatuses:OptionObject[]
   public MouthStatuses:OptionObject[]
   public DiatesStatuses:OptionObject[]
   public AppetiteStatuses:OptionObject[]
   public BowelStatuses:OptionObject[]
   public urineStatuses:OptionObject[]
   public SkinStatuses:OptionObject[]
   public EyeStatuses:OptionObject[]
   public EarsStatuses:OptionObject[]
   public SleepStatuses:OptionObject[]
   public PainStatuses:OptionObject[]
   public ReadWriteStatuses:OptionObject[]
   public OrintationStatuses:OptionObject[]
   public EnvAwarnessStatuses:OptionObject[]
   public EmotionalStatuses:OptionObject[]
   public AssessmentReasons:OptionObject[]
   public Hmos:OptionObject[]

  public constructor() {



    this.Hmos=[
      {id:'clalit' , name:'כללית'},
      {id:'macabi' , name:'מכבי'},
      {id:'leumit' , name:'לאומית'},
      {id:'meohedet' , name:'מאוחדת'},
      {id:'other' , name:'אחר'}
    ]


    this.AssessmentReasons=[
      {id:'R1' , name:'מטופל חדש'},
      {id:'R2' , name:'מעקב חודשי'},

      {id:'N' , name:''}
    ]



    this.EmotionalStatuses=[
      {id:'P1' , name:'רגוע ושקט'},
      {id:'P2' , name:'משתף פעולה'},
      {id:'F1' , name:'לא משתף פעולה'},
      {id:'F2' , name:'אינו רגוע ושקט'},
      {id:'N' , name:''}
    ]
    this.EnvAwarnessStatuses=[
      {id:'P1' , name:'מדבר ברור'},
      {id:'P2' , name:'דיבור שוטף'},
      {id:'F' , name:'לא מתמצא לא במקום ולא בזמן'},
      {id:'N' , name:''}
    ]
    this.OrintationStatuses =[
      {id:'P' , name:'מתמצא במקום ובזמן'},
      {id:'F' , name:'לא מתמצא לא במקום ולא בזמן'},
      {id:'N' , name:''}
    ]

   this.ReadWriteStatuses=[
    {id:'P' , name:'כותב וקורא'},
    {id:'F' , name:'לא יכול לכתוב ולקרוא'},
    {id:'N' , name:''}
   ]

    this.ClothingStatuses=[
      {id:'P' , name:'לא זקוק לעזרה'},
      {id:'F' , name:'זקוק לעזרה'},
      {id:'N' , name:''}
    ]

    this.FeedingStatuses=[
      {id:'P' , name:'לא זקוק לעזרה'},
      {id:'F' , name:'זקוק לזונדה'},
      {id:'N' , name:''}
    ]

    this.MouthStatuses=[
      {id:'P' , name:'תקין'},
      {id:'F' , name:'משתמש בתוחבות מלאות'},
      {id:'N' , name:''}
    ]



    this.BowelStatuses=[
      {id:'P' , name:'לא זקוק לעזרה'},
      {id:'F' , name:'אינו שולט ומשתמש במכנסונים'},
      {id:'N' , name:''}
    ]



    this.urineStatuses=[
      {id:'P' , name:'לא זקוק לעזרה'},
      {id:'F' , name:'אינו שולט על סוגר השתן'},
      {id:'N' , name:''}
    ]


    this.SkinStatuses=[
      {id:'P' , name:'תקין'},
      {id:'F' , name:'יבש או מגורה'},
      {id:'N' , name:''}
    ]



      this.EyeStatuses=[
      {id:'P' , name:'תקין'},
      {id:'F' , name:'זקוק למשקפיים'},
      {id:'N' , name:''}
    ]


    this.EarsStatuses=[
      {id:'P' , name:'שומע סביר'},
      {id:'F1' , name:'יש לו קשה לשמע'},
      {id:'F2' , name:'לא שומע'},
      {id:'N' , name:''}
    ]

    
    this.SleepStatuses=[
      {id:'P' , name:'ישן כל הלילה'},
      {id:'F' , name:'עם הפרעות שינה'},
      {id:'N' , name:''}
    ]

       
    this.PainStatuses=[
      {id:'P' , name:'אינו סובל מכאב'},
      {id:'F' , name:'סובל מכאב'},
      {id:'N' , name:''}
    ]

    this.WashingStatuses=[
      {id:'P' , name:'לא זקוק לעזרה'},
      {id:'F' , name:'זקוק לעזרה ברחיצה'},
      {id:'N' , name:''}
    ]
    this.TransfersStatuses=[
      {id:'P' , name:'לא זקוק לעזרה'},
      {id:'F' , name:'זקוק לעזרה'},
      {id:'N' , name:''}
    ]

    this.MobilityStatuses=[
      {id:'A' , name:'מרותק למיטה וזקוק לשינוי תנוחה'},
      {id:'B' , name:'מרותק לכסא גלגלים'},
      {id:'B' , name:'תנועה חופשית'}
    ]

    this.OxygenMethods = [
      {id:'E' , name:''},
      {id:'A' , name:'אוויר חדר'},
      {id:'B' , name:'משקפי חמצן'},
    ]
    this.UrinateMethods = [
      {id:'E' , name:''},
      {id:'A' , name:'טבעי'},
    ]

    this.ExaminationStatus=[
      {id:'N' , name:''},
      {id:'P' , name:'תקין'},
      {id:'F' , name:'לא תקין'},
    ]

    this.DrugInstTypes = [
      { id: 'F', name: 'תרופה קבועה' },
      { id: 'W', name: 'טיפול זמני' }
    ]
    this.DrugUOMs = [
      { id: 'cc', name: 'cc(ml)' },
      { id: 'mg', name: 'mg' },
      { id: 'T', name: 'טבליות' },
      { id: 'D', name: 'טיפות' },
      { id: 'C', name: 'משחה/חיצוני' },
    ]

    this.DrugFreqs = [
      { id: 'x1', name: 'פעם אחת' },
      { id: 'x2', name: 'פעמים' },
      { id: 'x3', name: '3 פעמים' },
      { id: 'x4', name: '4 פעמים' },
      { id: 'x5', name: '5 פעמים' },


    ]


    this.DrugFreqUoms = [
      { id: 'D', name: 'יומי' },
     { id: 'W', name: 'שבועי' },
      { id: 'M', name: 'חודשי' },
      { id: 'P', name: 'לפי צורך' }
    ];


    this.DrugTypes = [
      { id: 'T', name: 'כדורים' },
      { id: 'IV', name: 'תוך וורידי' },
      { id: 'S', name: 'משחה' },
      { id: 'D', name: 'טיפות' }
    ];


    this.DrugDosageForm = [
        {id: 'sc', name: 'S.C'},
        {id: 'im', name: 'I.M'},
        {id: 'iv', name: 'I.V'},
        {id: 'po', name: 'P.O'},
        {id: 'inh', name: 'INH'},
        {id: 'pr', name: 'P.R'},
        {id: 'sl', name: 'S.L'},
        {id: 'ic', name: 'I.C'},
    ]

    this.InstructionStatuses= [
      {id: 'A', name: 'הוראות פעילות'},
      {id: 'C', name: 'הוראות שבוטלו'},
      {id: 'D', name: 'הוראות שבוצעו'},
      {id: 'S', name: 'הוראות שהופסקו'},
  ]

  }


  public getName(id: string, enumObj: OptionObject[]): string {

    let result: OptionObject
    result = enumObj.find(x => x.id == id)
    if (result != undefined)
      return result.name
    else
      return ''
  }


}



