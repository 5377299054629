import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-lablandingpage',
  templateUrl: './lablandingpage.component.html',
  styleUrls: ['./lablandingpage.component.scss']
})
export class LablandingpageComponent implements OnInit  {
  personalId :string;
  moduleName = 'מעבדה';
  constructor( private _title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private _ar: ActivatedRoute,) { }


  ngOnInit(): void {
    this._title.setTitle('Caretex |מעבדה');
    this.personalId = this.route.parent.snapshot.params['id'] 
  }


  LabRequestsList(){
    this.router.navigateByUrl("/caretexfile/" +this.personalId + '/laboratory/orders') //"/laboratoryrequests")
  }

  makeLabRequest(){
    this.router.navigateByUrl("/caretexfile/" +this.personalId + "/laboratory/orders/new")

  }

  openLabResults(){
    this.router.navigateByUrl("/caretexfile/" +this.personalId + "/laboratory/results")
  }



}
