import { User } from 'firebase'
import { MedicalFile } from './customer'
import { propertyMap } from './modelmapper'




// Need Server to send Payload in below structure
export class SocialRelease {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID') //
    public PersonelID: string
    @propertyMap('ReleasedTo') //
    public ReleasedTo: string
    @propertyMap('ReasonForAdmission') //
    public ReasonForAdmission: string
    @propertyMap('FunctionalStatus') //
    public FunctionalStatus: string
    @propertyMap('FunctionalStatusRelease') //
    public FunctionalStatusRelease: string
    @propertyMap('FormalProcess') //
    public FormalProcess: string
    @propertyMap('Familyreleations') //
    public Familyreleations: string
    @propertyMap('EmotionalstatInrelease') //
    public EmotionalstatInrelease: string
    @propertyMap('Recommendations') //
    public Recommendations: string
    @propertyMap('Option1') //
    public Option1: boolean
    @propertyMap('Option2') //
    public Option2: boolean
    @propertyMap('Option3') //
    public Option3: boolean
    @propertyMap('Option4') //
    public Option4: boolean
    @propertyMap('Option5') //
    public Option5: boolean
    @propertyMap('Option6') //
    public Option6: boolean
    @propertyMap('Option7') //
    public Option7: boolean
    @propertyMap('Option8') //
    public Option8: boolean
    @propertyMap('OtherOption') //
    public OtherOption: string
    @propertyMap('PersonDoc')
    public PersonDoc: MedicalFile
    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('Status')
    Status: string
    @propertyMap('Closed_at')
    public Closed_at: Date

    
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.PersonelID = data["PersonelID"]
            this.ReleasedTo = data["ReleasedTo"]
            this.ReasonForAdmission = data["ReasonForAdmission"]
            this.FunctionalStatus = data["FunctionalStatus"]
            this.FormalProcess = data["FormalProcess"]
            this.EmotionalstatInrelease = data["EmotionalstatInrelease"]
            this.FunctionalStatusRelease = data["FunctionalStatusRelease"]
            this.Familyreleations = data["Familyreleations"]
            this.Recommendations = data["Recommendations"]
            this.Option1 = data["Option1"]
            this.Option2 = data["Option2"]
            this.Option3 = data["Option3"]
            this.Option4 = data["Option4"]
            this.Option5 = data["Option5"]
            this.Option6 = data["Option6"]
            this.Option7 = data["Option7"]
            this.Option8 = data["Option8"]
            this.OtherOption = data["OtherOption"]
            this.CreatedBy = data["CreatedBy"]
            this.CreatedById = data["CreatedById"]
            this.Status = data["Status"]
            this.PersonDoc = data["PersonDoc"]
            this.Closed_at = data["Closed_at"]
        }
        else {
            this.CreatedAt = this.UpdatedAt = new Date()
            this.Status = "A"
        }
    }
}



