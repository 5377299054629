import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MedicalFile } from '../models/customer';
import { Departments, DiagnosesTypes, Funder, NursingStatus } from '../models/generalhousedsetups';
import { PersonalMeasureCode } from '../models/measure';

import { ModelMapper } from '../models/modelmapper';
import { LabCheckHeader, LabOrderCategories } from '../models/testlabchecks';


@Injectable()
export class DataService {

  public medicalFile: MedicalFile;
  public CurrentLabCheckHeader: LabCheckHeader
  public isMedicalFileOpened: boolean = false
  public isNotesShowed: boolean
  public isMedicalFilesSelected: boolean = false
  public isAlertsSelected: boolean = false
  public isNotificationsSelected: boolean = false
  public isMessagesSelected: boolean = false
  public HouseDepartments: Departments[] = []
  public NursingStatuses:NursingStatus[] = []
  public DiagnosesTypes:DiagnosesTypes[]= []
  public PersonalMeasureCodes:PersonalMeasureCode[] =[]
 // public LaboratoryTypes:LabType[] = []
  public Funders:Funder[] = []

  labTypesChange: BehaviorSubject<LabOrderCategories[]> = new BehaviorSubject<LabOrderCategories[]>([]);
 



  public constructor(private httpClient: HttpClient) {

    this.getMeasreCodes().subscribe((data)=>{
      this.PersonalMeasureCodes = data
    })
  
    this.getHouseDepartments().subscribe((data) => {
      this.HouseDepartments = data
    })

    this.getNursingStatuses().subscribe((data) => {
      this.NursingStatuses = data
    })

    this.getFunders().subscribe((data) => {
      this.Funders = data
    })

    this.getDiagnosesTypes().subscribe((data) => {
      this.DiagnosesTypes = data
    })
  }




  getSharedMEdicalFile() {
    return this.medicalFile
  }

  updateSharedMedicalFile(data) {
    //Statements 
    this.medicalFile = data
  }

  getIsNotesShowed() {
    return this.isNotesShowed
  }

  updateIsNotesShowed(data) {
    //Statements 
    this.isNotesShowed = data
  }

  // Get Departments:
  getHouseDepartments(): Observable<Departments[]> {
    return this.httpClient.get(environment.gateway + '/housedepartments').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Departments).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getMeasreCodes(): Observable<PersonalMeasureCode[]> {
    return this.httpClient.get(environment.gateway + '/measurescodes').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PersonalMeasureCode).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getNursingStatuses(): Observable<NursingStatus[]> {
    return this.httpClient.get(environment.gateway + '/nursingstatuses').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NursingStatus).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getFunders(): Observable<Funder[]> {
    return this.httpClient.get(environment.gateway + '/funders').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Funder).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getDiagnosesTypes(): Observable<DiagnosesTypes[]> {
    return this.httpClient.get(environment.gateway + '/diagnosestypes').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DiagnosesTypes).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getLabTypes(): Observable<LabOrderCategories[]> {
    return this.httpClient.get(environment.gateway + '/labtypes').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(LabOrderCategories).map(item)
        })
      }), catchError(this.errorHandler));
  }

  setCurrentSavedLabcheck(labCheckHeader:LabCheckHeader){
    this.CurrentLabCheckHeader = labCheckHeader
  }



  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }




  getLabTypes2(hmo:string): void {
    this.httpClient.get<LabOrderCategories[]>(environment.gateway + '/labcategories/'+hmo).subscribe(data => {
      this.labTypesChange.next(data);
    },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      });
  }








}