import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NurseAdmission } from 'src/app/models/nurseadmission';
import { BrdgServiceResponse } from 'src/app/models/serverresponse';
import { AdmissionsService } from 'src/app/services/admissions.service';
import { NurseAdmissionSteps } from 'src/app/services/enums';


@Component({
  selector: 'app-nursingplan',
  templateUrl: './nursingplan.component.html',
  styleUrls: ['../../nurseadmission.component.css', './nursingplan.component.css']
})
export class NursingplanComponent implements OnInit {

  serverResponse: BrdgServiceResponse
  NusringPlanChangeMessage: string
  @Input() personalId: string
  @Input() currentNurseAdmission: NurseAdmission

  @Input() set tabChanged(tabSelected: string) {
    if ( (tabSelected as NurseAdmissionSteps)  == NurseAdmissionSteps.Plan)
    this.NusringPlanChangeMessage = this.buildNursingPlanControlMessage()
  }



  @Output() serverResponseEvent = new EventEmitter<BrdgServiceResponse>();

  constructor(private _admissionsService: AdmissionsService,
    private router: Router,
    private _ar: ActivatedRoute,) {
    this.serverResponse = new BrdgServiceResponse()
  }


  ngOnInit(): void {

    this.NusringPlanChangeMessage = this.buildNursingPlanControlMessage()
    this.currentNurseAdmission = this._admissionsService.getSharedNurseAdmission()
  }



  saveFullAdmission() {

    this._admissionsService.createNewNurseAdmission(this.currentNurseAdmission).subscribe({
      next: data => {
        this.currentNurseAdmission = (data as NurseAdmission)
        this._admissionsService.updateSharedData(this.currentNurseAdmission)
        this._admissionsService.resetNurseAdmissionChangedFlags()
        this.NusringPlanChangeMessage = this.buildNursingPlanControlMessage()
        this.serverResponse.success()
        this.serverResponseEvent.emit(this.serverResponse)
      },
      error: error => {
        this.serverResponse.failure()
        this.serverResponseEvent.emit(this.serverResponse)
      }
    })
  }


  saveNusringPlan() {
    this.serverResponse.resetResponse()
    this._admissionsService.checkNurseAdmissionChangedFields()
    this.currentNurseAdmission = this._admissionsService.getSharedNurseAdmission()
    if (this.currentNurseAdmission.ID == undefined) {
      this.saveFullAdmission()

    }
    else {
      this._admissionsService.updateNurseAdmission(this.currentNurseAdmission, "habits").subscribe({
        next: data => {
          this._admissionsService.updateSharedData(this.currentNurseAdmission)
          this._admissionsService.resetNurseAdmissionChangedFlags()
          this.NusringPlanChangeMessage = this.buildNursingPlanControlMessage()
          this.serverResponse.success()
          this.serverResponseEvent.emit(this.serverResponse)
        },
        error: error => {
          this.serverResponse.failure()
          this.serverResponseEvent.emit(this.serverResponse)
        }
      })

    }

  }


  buildNursingPlanControlMessage() {
    this.currentNurseAdmission = this._admissionsService.getSharedNurseAdmission()
    if (this.currentNurseAdmission.ID == undefined ||
      this.currentNurseAdmission.NursingPlanLastUpdated_at == undefined ||
      this.currentNurseAdmission.NursingPlanLastUpdated_at.toString() == "0001-01-01T00:00:00Z") return ""
    let datepipe = new DatePipe('en-US')
    let updatedDate = datepipe.transform(this.currentNurseAdmission.NursingPlanLastUpdated_at, 'dd/MM/yyyy');
    let updatedTime = datepipe.transform(this.currentNurseAdmission.NursingPlanLastUpdated_at, 'HH:mm');

   // return "עודכן עי " + this.currentNurseAdmission.HobbiesLastUpdatedBy.displayName + " בתאריך " + updatedDate + " בשעה " + updatedTime
  }


  NursePlantextchnaged($event) {
    this.currentNurseAdmission.nursingPlanModified = true
    this._admissionsService.updateSharedData(this.currentNurseAdmission)

  }

}
