import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { PhysicalExamination } from 'src/app/models/physicalexamination';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-physicalexaminationlocked',
  templateUrl: './physicalexaminationlocked.component.html',
  styleUrls: ['./physicalexaminationlocked.component.css']
})
export class PhysicalexaminationlockedComponent implements OnInit {

  id: string;
  @Input() physicalExamination: PhysicalExamination
  constructor(
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    if (this.route.parent != null && this.route.parent != undefined) {
      this.id = this.route.parent.snapshot.params['id']
    }

   

  }

  getAllPhysicalCheckFromDB(id: string, recordId: string) {

    let filter = '(id=' + '\'' + recordId + '\'' + ')'

    this.caretxMedicalProviderService.getExamination(this.id, filter).subscribe(
      data => {
        if (data.length > 0) {
          this.physicalExamination = new PhysicalExamination(data[0])
        }
      },
      err => {
        console.log("Error", err)
      }
    )
  }







}
