import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-snaq',
  templateUrl: './snaq.component.html',
  styleUrls: ['./snaq.component.css']
})
export class SnaqComponent extends CaretexComponent implements OnInit {
  isSubModule:boolean = false
  constructor(  public medicalFileService: MedicalFileProviderService,private route: ActivatedRoute) {
    super(medicalFileService)
  }

  ngOnInit(): void {
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id) 
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }
}
