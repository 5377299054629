import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CartexReport } from '../../../../src/app/models/personeldocument';
import { AuthService } from '../../../../src/app/services/auth.service';
import { MedicalFileProviderService } from '../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../src/app/shared/CaretexComponent';
import { CaretxSharedModulesProviderService } from '../../../../src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { DocViewerComponent } from '../../../../src/app/caretx-shared-module/CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-socialforms',
  templateUrl: './socialforms.component.html',
  styleUrls: ['./socialforms.component.css']
})
export class SocialformsComponent extends CaretexComponent implements OnInit {
  id: string
  pdfContent: any
  moduleName:string = "טפסים להורדה"
  constructor(
    private authServive: AuthService,
    private _title: Title,
    private route: ActivatedRoute,
    public medicalFileService: MedicalFileProviderService,
    public dialog: MatDialog,
    private toastrService: ToasterService,
    private sharedServices: CaretxSharedModulesProviderService,
    private _ar: ActivatedRoute,) { super(medicalFileService)}

  ngOnInit(): void {
    this._title.setTitle('Caretex |טופס קבלה סוציאלית');
    if(this.route.parent !=null)
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id) 
  }

  openFamilyStatosfaction(){
    var careTexReport =  new CartexReport()
    careTexReport.PersonelID = this.id
    careTexReport.ReportName = "Satis"
    careTexReport.ReportType ="family_satisfaction"
    careTexReport.ReportParam = ""
    careTexReport.GeneratedBy  = this.authServive.LoggedInUser
    careTexReport.GeneratedOn =  new Date()


    const dialogRef = this.dialog.open(DocViewerComponent, {
      data: {
        Type:'CR',
        PdfReport: careTexReport
      
      }
    });

    /*this.reportsService.generateReport(this.id,careTexReport)
      .subscribe(data => {
        var file = new Blob([data], { type: 'application/pdf' });
        this.pdfContent = URL.createObjectURL(file);
        window.open(this.pdfContent);
  
      }, error => {
        console.log(error);
        var errorAsString = "failed to  download file"
        try {
          var enc = new TextDecoder("utf-8")
          var text = enc.decode(error.error)
          errorAsString = JSON.parse(text).error
        } catch (e) {
  
        }
        this.toastrService.Error("", errorAsString)
      })*/
  }


  downloadGardianRequest(){
    this.DoCaretexDownload("request_to_appoint_guardian_form-6.pdf")
  }
  downloadForm1(){
     this.DoCaretexDownload("questionnairestatementofhospitalizedchild.pdf")
   
  }
  downloadform2(){
   
    this.DoCaretexDownload("questionnairestatementofhospitalized.pdf")
  }
  downloadform3(){
    this.DoCaretexDownload("palliativecare.pdf")

  }
  downloadform4(){
    this.DoCaretexDownload("complaintform.pdf")
  }


  DoCaretexDownload( fileName:string) {
    const dialogRef = this.dialog.open(DocViewerComponent, {
      data: {
        Type: 'CF',
        Id:this.id,
        FileName: fileName
      }
    });

  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false;
  }
}
