
import { Attachment } from './attachments'
import { BedSore } from './bedsore'
import { Diagnoses } from './diagnoses'
import { DrugInstruction } from './drugInstruction'
import { propertyMap } from './modelmapper'
import { User } from './user'

export class PhysicalDiagnoses {
    @propertyMap('ID')
    ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('FuncOp1')
    FuncOp1: boolean
    @propertyMap('FuncOp2')
    FuncOp2: boolean
    @propertyMap('FuncOp3')
    FuncOp3: boolean
    @propertyMap('FuncOp4')
    FuncOp4: boolean
    @propertyMap('FuncOp5')
    FuncOp5: boolean
    @propertyMap('LastPlace')
    LastPlace: string
    @propertyMap('MedicalLabs')
    MedicalLabs: string
    @propertyMap('MedicalPhotos')
    MedicalPhotos: string
    @propertyMap('MedicalImaging')
    MedicalImaging: string
    @propertyMap('Seconderydiagnoses')
    Seconderydiagnoses: string
    @propertyMap('Maindiagnoses')
    Maindiagnoses: string
    @propertyMap('SocialBackGround')
    SocialBackGround: string
    @propertyMap('VerbalComm')
    VerbalComm: string
    @propertyMap('VerbalCommComments')
    VerbalCommComments: string
    @propertyMap('NonVerbalCommComments')
    NonVerbalCommComments: string
    @propertyMap('NonVerbalComm')
    NonVerbalComm: string
    @propertyMap('Sight')
    Sight: string
    @propertyMap('SightComments')
    SightComments: string
    @propertyMap('HearingComments')
    HearingComments: string
    @propertyMap('Hearing')
    Hearing: string
    @propertyMap('TimeOrientationComments')
    TimeOrientationComments: string
    @propertyMap('TimeOrientation')
    TimeOrientation: string
    @propertyMap('PlaceOrientation')
    PlaceOrientation: string
    @propertyMap('PlaceOrientationComments')
    PlaceOrientationComments: string
    @propertyMap('CooperationComments')
    CooperationComments: string
    @propertyMap('Cooperation')
    Cooperation: string
    @propertyMap('AdlOp1')
    AdlOp1: string
    @propertyMap('AdlOp1Comment')
    AdlOp1Comment: string
    @propertyMap('AdlOp2')
    AdlOp2: string
    @propertyMap('AdlOp2Comment')
    AdlOp2Comment: string
    @propertyMap('AdlOp3')
    AdlOp3: string
    @propertyMap('AdlOp3Comment')
    AdlOp3Comment: string
    @propertyMap('AdlOp4')
    AdlOp4: string
    @propertyMap('AdlOp4Comment')
    AdlOp4Comment: string
    @propertyMap('AdlOp5')
    AdlOp5: string
    @propertyMap('AdlOp5Comment')
    AdlOp5Comment: string
    @propertyMap('MobOp1')
    MobOp1: string
    @propertyMap('MobOp1Comment')
    MobOp1Comment: string
    @propertyMap('MobOp2')
    MobOp2: string
    @propertyMap('MobOp2Comment')
    MobOp2Comment: string
    @propertyMap('MobOp3')
    MobOp3: string
    @propertyMap('MobOp3Comment')
    MobOp3Comment: string
    @propertyMap('MobOp4')
    MobOp4: string
    @propertyMap('MobOp4Comment')
    MobOp4Comment: string
    @propertyMap('MobOp5')
    MobOp5: string
    @propertyMap('MobOp5Comment')
    MobOp5Comment: string
    @propertyMap('MobOp6')
    MobOp6: string
    @propertyMap('MobOp6Comment')
    MobOp6Comment: string
    @propertyMap('MobOp7')
    MobOp7: string
    @propertyMap('MobOp7Comment')
    MobOp7Comment: string
    @propertyMap('MobOp8')
    MobOp8: string
    @propertyMap('MobOp8Comment')
    MobOp8Comment: string
    @propertyMap('MobOp9')
    MobOp9: string
    @propertyMap('MobOp9Comment')
    MobOp9Comment: string
    @propertyMap('MobOp10')
    MobOp10: string
    @propertyMap('MobOp10Comment')
    MobOp10Comment: string
    @propertyMap('MobOp11')
    MobOp11: string
    @propertyMap('MobOp11Comment')
    MobOp11Comment: string
    @propertyMap('TestMode1')
    TestMode1: string
    @propertyMap('TestMode2')
    TestMode2: string
    @propertyMap('TonosOp1RT')
    TonosOp1RT: boolean
    @propertyMap('TonosOp1LT')
    TonosOp1LT: boolean
    @propertyMap('TonosOp1Status')
    TonosOp1Status: String
    @propertyMap('TonosOp1Comment')
    TonosOp1Comment: String
    @propertyMap('TonosOp2RT')
    TonosOp2RT: boolean
    @propertyMap('TonosOp2LT')
    TonosOp2LT: boolean
    @propertyMap('TonosOp2Status')
    TonosOp2Status: String
    @propertyMap('TonosOp2Comment')
    TonosOp2Comment: String
    @propertyMap('TonosOp3RT')
    TonosOp3RT: boolean
    @propertyMap('TonosOp3LT')
    TonosOp3LT: boolean
    @propertyMap('TonosOp3Status')
    TonosOp3Status: String
    @propertyMap('TonosOp3Comment')
    TonosOp3Comment: String
    @propertyMap('SFOp1RT')
    SFOp1RT: boolean
    @propertyMap('SFOp1LT')
    SFOp1LT: boolean
    @propertyMap('SFOp1Status')
    SFOp1Status: String
    @propertyMap('SFOp1Comment')
    SFOp1Comment: String
    @propertyMap('SFOp2RT')
    SFOp2RT: boolean
    @propertyMap('SFOp2LT')
    SFOp2LT: boolean
    @propertyMap('SFOp2Status')
    SFOp2Status: String
    @propertyMap('SFOp2Comment')
    SFOp2Comment: String
    @propertyMap('DFOp1RT')
    DFOp1RT: boolean
    @propertyMap('DFOp1LT')
    DFOp1LT: boolean
    @propertyMap('DFOp1Status')
    DFOp1Status: String
    @propertyMap('DFOp1Comment')
    DFOp1Comment: String
    @propertyMap('DFOp2RT')
    DFOp2RT: boolean
    @propertyMap('DFOp2LT')
    DFOp2LT: boolean
    @propertyMap('DFOp2Status')
    DFOp2Status: String
    @propertyMap('DFOp2Comment')
    DFOp2Comment: String
    @propertyMap('PainLevel')
    PainLevel: String
    @propertyMap('PainPosition')
    PainPosition: String
    @propertyMap('Neglect')
    Neglect: String
    @propertyMap('EquOp1')
    EquOp1: String
    @propertyMap('EquOp2')
    EquOp2: String
    @propertyMap('EquOp3')
    EquOp3: String
    @propertyMap('EquOp1Comment')
    EquOp1Comment: String
    @propertyMap('EquOp2Comment')
    EquOp2Comment: String
    @propertyMap('EquOp3Comment')
    EquOp3Comment: String
    @propertyMap('PostureSetMode')
    PostureSetMode: String
    @propertyMap('PostureStdMode')
    PostureStdMode: String
    @propertyMap('PostureWlkMode')
    PostureWlkMode: String
    @propertyMap('Summary')
    Summary: String
    @propertyMap('Decision')
    Decision: String
    @propertyMap('Reasons')
    Reasons: String
    @propertyMap('TreatmentGoals')
    TreatmentGoals: String
    @propertyMap('TreatmentType')
    TreatmentType: String
    @propertyMap('TreatedByOpt1')
    TreatedByOpt1: boolean
    @propertyMap('TreatedByOpt1Comment')
    TreatedByOpt1Comment: string
    @propertyMap('TreatedByOpt2')
    TreatedByOpt2: boolean
    @propertyMap('TreatedByOpt2Comment')
    TreatedByOpt2Comment: string
    @propertyMap('TreatedByOpt3')
    TreatedByOpt3: boolean
    @propertyMap('TreatedByOpt3Comment')
    TreatedByOpt3Comment: string
    @propertyMap('TreatmentDetails')
    TreatmentDetails: string
    @propertyMap('EquipmentNeeds')
    EquipmentNeeds: string
    @propertyMap('Frequency')
    Frequency: string
    @propertyMap('DocOpt1')
    DocOpt1: boolean
    @propertyMap('DocOpt2')
    DocOpt2: boolean
    @propertyMap('Status')
    Status: string
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('ClosedAt')
    ClosedAt: Date
    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User
    @propertyMap('Comment1')
    public Comment1: string
    @propertyMap('Comment2')
    public Comment2: string
    @propertyMap('Comment3')
    public Comment3: string
    @propertyMap('Comment4')
    public Comment4: string
    @propertyMap('Comment5')
    public Comment5: string
    @propertyMap('Comment6')
    public Comment6: string
    @propertyMap('Comment7')
    public Comment7: string
    @propertyMap('Comment8')
    public Comment8: string
    @propertyMap('Comment9')
    public Comment9: string
    @propertyMap('Comment10')
    public Comment10: string

    @propertyMap('BreathMethod')
    public BreathMethod: string
    @propertyMap('OxygenUse')
    public OxygenUse: string
    @propertyMap('IsNight')
    public IsNight: boolean
    @propertyMap('IsDay')
    public IsDay: boolean
    @propertyMap('Splint')
    public Splint: string
    @propertyMap('Drain')
    public Drain: string
    @propertyMap('Period')
    public Period: number
    
    
    @propertyMap('BodySize')
    public BodySize: string
    @propertyMap('Skinstat')
    public Skinstat: string
    @propertyMap('Bedsore')
    public Bedsore: string
    @propertyMap('Deformation')
    public Deformation: string
    @propertyMap('Bloat')
    public Bloat: string
    @propertyMap('Sitting')
    public Sitting: string
    @propertyMap('Mobility')
    public Mobility: string
    @propertyMap('SittingStatus')
    public SittingStatus: string

   
  

    
    /********************************* */




    constructor(data?: Object) {
        if (data != undefined && data != null) {


            this.FuncOp1 = data["FuncOp1"]
            this.FuncOp2 = data["FuncOp2"]
            this.FuncOp3 = data["FuncOp3"]
            this.FuncOp4 = data["FuncOp4"]
            this.FuncOp5 = data["FuncOp5"]

            this.Comment1 = data["Comment1"]

            this.LastPlace = data["LastPlace"]
            this.MedicalLabs = data["MedicalLabs"]
            this.MedicalPhotos = data["MedicalPhotos"]
            this.MedicalImaging = data["MedicalImaging"]
            this.Seconderydiagnoses = data["Seconderydiagnoses"]
            this.SocialBackGround = data["SocialBackGround"]
            this.Maindiagnoses = data["Maindiagnoses"]
            this.VerbalComm = data["VerbalComm"]
            this.VerbalCommComments = data["VerbalCommComments"]
            this.NonVerbalCommComments = data["NonVerbalCommComments"]
            this.NonVerbalComm = data["NonVerbalComm"]
            this.SightComments = data["SightComments"]
            this.Sight = data["Sight"]
            this.HearingComments = data["HearingComments"]
            this.Hearing = data["Hearing"]
            this.TimeOrientationComments = data["TimeOrientationComments"]
            this.TimeOrientation = data["TimeOrientation"]
            this.PlaceOrientationComments = data["PlaceOrientationComments"]
            this.PlaceOrientation = data["PlaceOrientation"]
            this.CooperationComments = data["CooperationComments"]
            this.Cooperation = data["Cooperation"]
            this.AdlOp1 = data["AdlOp1"]
            this.AdlOp2 = data["AdlOp2"]
            this.AdlOp3 = data["AdlOp3"]
            this.AdlOp4 = data["AdlOp4"]
            this.AdlOp5= data["AdlOp5"]

            this.AdlOp1Comment = data["AdlOp1Comment"]
            this.AdlOp2Comment = data["AdlOp2Comment"]
            this.AdlOp3Comment = data["AdlOp3Comment"]
            this.AdlOp4Comment = data["AdlOp4Comment"]
            this.AdlOp5Comment= data["AdlOp5Comment"]
            this.MobOp1 = data["MobOp1"]
            this.MobOp2 = data["MobOp2"]
            this.MobOp3 = data["MobOp3"]
            this.MobOp4 = data["MobOp4"]
            this.MobOp5= data["MobOp5"]
            this.MobOp6= data["MobOp6"]
            this.MobOp7= data["MobOp7"]
            this.MobOp8= data["MobOp8"]
            this.MobOp9= data["MobOp9"]
            this.MobOp10= data["MobOp10"]
            this.MobOp11= data["MobOp11"]
     
            this.MobOp1Comment = data["MobOp1Comment"]
            this.MobOp2Comment = data["MobOp2Comment"]
            this.MobOp3Comment = data["MobOp3Comment"]
            this.MobOp4Comment = data["MobOp4Comment"]
            this.MobOp5Comment= data["MobOp5Comment"]
            this.MobOp6Comment = data["MobOp6Comment"]
            this.MobOp7Comment = data["MobOp7Comment"]
            this.MobOp8Comment = data["MobOp8Comment"]
            this.MobOp9Comment = data["MobOp9Comment"]
            this.MobOp10Comment= data["MobOp10Comment"]
            this.MobOp11Comment= data["MobOp11Comment"]
           

            this.TestMode1= data["TestMode1"]
            this.TestMode2= data["TestMode2"]
            this.TonosOp1RT= data["TonosOp1RT"]
            this.TonosOp1LT= data["TonosOp1LT"]
            this.TonosOp1Status= data["TonosOp1Status"]
            this.TonosOp1Comment=data["TonosOp1Comment"]
            this.TonosOp2RT= data["TonosOp2RT"]
            this.TonosOp2LT= data["TonosOp2LT"]
            this.TonosOp2Status= data["TonosOp2Status"]
            this.TonosOp2Comment=data["TonosOp2Comment"]
            this.TonosOp3RT= data["TonosOp3RT"]
            this.TonosOp3LT= data["TonosOp3LT"]
            this.TonosOp3Status= data["TonosOp3Status"]
            this.TonosOp3Comment=data["TonosOp3Comment"]
            this.SFOp1RT= data["SFOp1RT"]
            this.SFOp1LT= data["SFOp1LT"]
            this.SFOp1Status= data["SFOp1Status"]
            this.SFOp1Comment=data["SFOp1Comment"]
            this.SFOp2RT= data["SFOp2RT"]
            this.SFOp2LT= data["SFOp2LT"]
            this.SFOp2Status= data["SFOp2Status"]
            this.SFOp2Comment=data["SFOp2Comment"]
            this.DFOp1RT= data["DFOp1RT"]
            this.DFOp1LT= data["DFOp1LT"]
            this.DFOp1Status= data["DFOp1Status"]
            this.DFOp1Comment=data["DFOp1Comment"]
            this.DFOp2RT= data["DFOp2RT"]
            this.DFOp2LT= data["DFOp2LT"]
            this.DFOp2Status= data["DFOp2Status"]
            this.DFOp2Comment=data["DFOp2Comment"]
            this.PainLevel= data["PainLevel"]
            this.PainPosition=data["PainPosition"]
            this.Neglect=data["Neglect"]
            this.EquOp1=data["EquOp1"]
            this.EquOp2=data["EquOp2"]
            this.EquOp3=data["EquOp3"]
            this.EquOp1Comment=data["EquOp1Comment"]
            this.EquOp2Comment=data["EquOp2Comment"]
            this.EquOp3Comment=data["EquOp3Comment"]
            this.PostureSetMode=data["PostureSetMode"]
            this.PostureStdMode=data["PostureStdMode"]
            this.PostureWlkMode=data["PostureWlkMode"]
            this.Summary=data["Summary"]
            this.Decision=data["Decision"]
            this.Reasons=data["Reasons"]
            this.TreatmentGoals=data["TreatmentGoals"]
            this.TreatmentType=data["TreatmentType"]
            this.TreatedByOpt1=data["TreatedByOpt1"]
            this.TreatedByOpt2=data["TreatedByOpt2"]
            this.TreatedByOpt3=data["TreatedByOpt3"]
            this.TreatedByOpt1Comment=data["TreatedByOpt1Comment"]
            this.TreatedByOpt2Comment=data["TreatedByOpt2Comment"]
            this.TreatedByOpt3Comment=data["TreatedByOpt3Comment"]
            this.TreatmentDetails=data["TreatmentDetails"]
            this.EquipmentNeeds=data["EquipmentNeeds"]
            this.Frequency=data["Frequency"]
            this.DocOpt1=data["DocOpt1"]
            this.DocOpt2=data["DocOpt2"]
            this.ID = data["ID"]
            this.ClosedById = data["ClosedById"]
            this.PersonelID = data["PersonelID"]
            this.Status = data["Status"]
            this.ClosedAt = data["ClosedAt"]
            this.CreatedById = data["CreatedById"]
            this.CreatedBy = data["CreatedBy"]

            this.Comment1 = data["Comment1"]
            this.Comment2 = data["Comment2"]
            this.Comment3 = data["Comment3"]
            this.Comment4 = data["Comment4"]
            this.Comment5 = data["Comment5"]
            this.Comment6 = data["Comment6"]
            this.Comment7 = data["Comment7"]
            this.Comment8 = data["Comment8"]
            this.Comment9 = data["Comment9"]
            this.Comment10 = data["Comment10"]
            this.BodySize = data["BodySize"]
            this.Skinstat = data["Skinstat"]
            this.Bedsore = data["Bedsore"]
            this.Deformation = data["Deformation"]
            this.Bloat = data["Bloat"]
            this.Sitting = data["Sitting"]
            this.Mobility = data["Mobility"]
            this.SittingStatus = data["SittingStatus"]

            this.BreathMethod = data["BreathMethod"]
            this.OxygenUse = data["OxygenUse"]
            this.IsDay = data["IsDay"]
            this.IsNight = data["IsNight"]
            this.Splint = data["Splint"]
            this.Drain = data["Drain"]
            this.Period = data["Period"]


   


            
        }
        else {
            this.ClosedById = undefined
            this.PersonelID = ""
   
            this.Maindiagnoses = ""
          
            this.Seconderydiagnoses = ""
   
            this.SocialBackGround = ""
            this.Status = 'A'
            this.ClosedAt = undefined
            this.VerbalComm = ""
            this.VerbalCommComments = ""
            this.NonVerbalCommComments = ""
            this.NonVerbalComm = ""
            this.SightComments = ""
            this.Sight = ""
            this.HearingComments = ""
            this.Hearing = ""
            this.TimeOrientationComments = ""
            this.TimeOrientation = ""
            this.PlaceOrientationComments = ""
            this.PlaceOrientation = ""
            this.CooperationComments = ""
            this.Cooperation = ""


         

        }
    }





}
// TSHOSH NEFESH
export class PhysicalDiagnoses2 {
    @propertyMap('ID')
    ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('FuncOp1')
    FuncOp1: boolean
    @propertyMap('FuncOp2')
    FuncOp2: boolean
    @propertyMap('FuncOp3')
    FuncOp3: boolean
    @propertyMap('LastPlaceOp1')
    LastPlaceOp1: string
    @propertyMap('LastPlaceOp2')
    LastPlaceOp2: string
    @propertyMap('LastPlaceOp3')
    LastPlaceOp3: string
    @propertyMap('LastPlaceOp4')
    LastPlaceOp4: string
    @propertyMap('LastPlaceOp5')
    LastPlaceOp5: string
    @propertyMap('LastPlace')
    LastPlace: string
    @propertyMap('MedicalLabs')
    MedicalLabs: string
    @propertyMap('MedicalPhotos')
    MedicalPhotos: string
    @propertyMap('MedicalImaging')
    MedicalImaging: string
    @propertyMap('Seconderydiagnoses')
    Seconderydiagnoses: string
    @propertyMap('Maindiagnoses')
    Maindiagnoses: string
    @propertyMap('SocialBackGround')
    SocialBackGround: string
    @propertyMap('VerbalComm')
    VerbalComm: string
    @propertyMap('VerbalCommComments')
    VerbalCommComments: string
    @propertyMap('NonVerbalCommComments')
    NonVerbalCommComments: string
    @propertyMap('NonVerbalComm')
    NonVerbalComm: string
    @propertyMap('Sight')
    Sight: string
    @propertyMap('SightComments')
    SightComments: string
    @propertyMap('HearingComments')
    HearingComments: string
    @propertyMap('Hearing')
    Hearing: string
    @propertyMap('TimeOrientationComments')
    TimeOrientationComments: string
    @propertyMap('TimeOrientation')
    TimeOrientation: string
    @propertyMap('PlaceOrientation')
    PlaceOrientation: string
    @propertyMap('PlaceOrientationComments')
    PlaceOrientationComments: string
    @propertyMap('CooperationComments')
    CooperationComments: string
    @propertyMap('Cooperation')
    Cooperation: string
    @propertyMap('VigilanceComments')
    VigilanceComments: string
    @propertyMap('Vigilance')
    Vigilance: string
    @propertyMap('AdlOp1')
    AdlOp1: string
    @propertyMap('AdlOp1Comment')
    AdlOp1Comment: string
    @propertyMap('AdlOp2')
    AdlOp2: string
    @propertyMap('AdlOp2Comment')
    AdlOp2Comment: string
    @propertyMap('AdlOp3')
    AdlOp3: string
    @propertyMap('AdlOp3Comment')
    AdlOp3Comment: string
    @propertyMap('AdlOp4')
    AdlOp4: string
    @propertyMap('AdlOp4Comment')
    AdlOp4Comment: string
    @propertyMap('AdlOp5')
    AdlOp5: string
    @propertyMap('AdlOp5Comment')
    AdlOp5Comment: string
    @propertyMap('AdlOp6')
    AdlOp6: string
    @propertyMap('AdlOp6Comment')
    AdlOp6Comment: string
    @propertyMap('AdlOp7')
    AdlOp7: string
    @propertyMap('AdlOp7Comment')
    AdlOp7Comment: string
    @propertyMap('AdlOp8')
    AdlOp8: string
    @propertyMap('AdlOp8Comment')
    AdlOp8Comment: string
    @propertyMap('AdlOp9')
    AdlOp9: string
    @propertyMap('AdlOp9Comment')
    AdlOp9Comment: string
    @propertyMap('AdlOp10')
    AdlOp10: string
    @propertyMap('AdlOp10Comment')
    AdlOp10Comment: string
    @propertyMap('PainLevel')
    PainLevel: string
   
    @propertyMap('PainPosition')
    PainPosition: String
    @propertyMap('Neglect')
    Neglect: String
    @propertyMap('EquOp1')
    EquOp1: String
    @propertyMap('EquOp2')
    EquOp2: String
    @propertyMap('EquOp3')
    EquOp3: String
    @propertyMap('EquOp4')
    EquOp4: String
    @propertyMap('EquOp5')
    EquOp5: String
    @propertyMap('EquOp1Comment')
    EquOp1Comment: String
    @propertyMap('EquOp2Comment')
    EquOp2Comment: String
    @propertyMap('EquOp3Comment')
    EquOp3Comment: String
    @propertyMap('EquOp4Comment')
    EquOp4Comment: String
    @propertyMap('EquOp5Comment')
    EquOp5Comment: String
    @propertyMap('Summary')
    Summary: String
    @propertyMap('Decision')
    Decision: String
    @propertyMap('Reasons')
    Reasons: String
    @propertyMap('TreatmentGoals')
    TreatmentGoals: String
    @propertyMap('TreatmentType')
    TreatmentType: String
    @propertyMap('TreatedByOpt1')
    TreatedByOpt1: boolean
    @propertyMap('TreatedByOpt2')
    TreatedByOpt2: boolean
    @propertyMap('TreatedByOpt3')
    TreatedByOpt3: boolean
    @propertyMap('TreatedByOpt4')
    TreatedByOpt4: boolean
    @propertyMap('TreatmentDetails')
    TreatmentDetails: string
    @propertyMap('EquipmentNeeds')
    EquipmentNeeds: string
    @propertyMap('Frequency')
    Frequency: string
    @propertyMap('DocOpt1')
    DocOpt1: boolean
    @propertyMap('DocOpt2')
    DocOpt2: boolean
    @propertyMap('Status')
    Status: string
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('ClosedAt')
    ClosedAt: Date
    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User
    @propertyMap('Comment1')
    public Comment1: string
    @propertyMap('Comment2')
    public Comment2: string
    @propertyMap('DrainComment')
    public DrainComment: string
    @propertyMap('SkinComment')
    public SkinComment: string
    @propertyMap('DeforComment')
    public DeforComment: string
    @propertyMap('BloatComment')
    public BloatComment: string
    @propertyMap('SetPattern')
    public SetPattern: string
    @propertyMap('StandPattern')
    public StandPattern: string
    @propertyMap('Comment10')
    public Comment10: string
    @propertyMap('BreathMethod')
    public BreathMethod: string
    @propertyMap('OxygenUse')
    public OxygenUse: string
    @propertyMap('IsNight')
    public IsNight: boolean
    @propertyMap('IsDay')
    public IsDay: boolean
    @propertyMap('Splint')
    public Splint: string
    @propertyMap('Drain')
    public Drain: string
    @propertyMap('Period')
    public Period: number
    @propertyMap('BodySize')
    public BodySize: string
    @propertyMap('Skinstat')
    public Skinstat: string
    @propertyMap('Bedsore')
    public Bedsore: string
    @propertyMap('Deformation')
    public Deformation: string
    @propertyMap('Bloat')
    public Bloat: string
    @propertyMap('Sitting')
    public Sitting: string
    @propertyMap('Mobility')
    public Mobility: string
    @propertyMap('SittingStatus')
    public SittingStatus: string
    @propertyMap('UpLimbRtOp1')
    public UpLimbRtOp1: string
    @propertyMap('UpLimbLtOp1')
    public UpLimbLtOp1: string
    @propertyMap('UpLimbLtOp1Txt')
    public UpLimbLtOp1Txt: string
    @propertyMap('UpLimbRtOp2')
    public UpLimbRtOp2: string
    @propertyMap('UpLimbLtOp2')
    public UpLimbLtOp2: string
    @propertyMap('UpLimbLtOp2Txt')
    public UpLimbLtOp2Txt: string
    @propertyMap('UpLimbRtOp3')
    public UpLimbRtOp3: string
    @propertyMap('UpLimbLtOp3')
    public UpLimbLtOp3: string
    @propertyMap('UpLimbLtOp3Txt')
    public UpLimbLtOp3Txt: string
    @propertyMap('BtmLimbRtOp1')
    public BtmLimbRtOp1: string
    @propertyMap('BtmLimbLtOp1')
    public BtmLimbLtOp1: string
    @propertyMap('BtmLimbLtOp1Txt')
    public BtmLimbLtOp1Txt: string
    @propertyMap('BtmLimbRtOp2')
    public BtmLimbRtOp2: string
    @propertyMap('BtmLimbLtOp2')
    public BtmLimbLtOp2: string
    @propertyMap('BtmLimbLtOp2Txt')
    public BtmLimbLtOp2Txt: string
    @propertyMap('BtmLimbRtOp3')
    public BtmLimbRtOp3: string
    @propertyMap('BtmLimbLtOp3')
    public BtmLimbLtOp3: string
    @propertyMap('BtmLimbLtOp3Txt')
    public BtmLimbLtOp3Txt: string
    @propertyMap('BtmLimbRtOp4')
    public BtmLimbRtOp4: string
    @propertyMap('BtmLimbLtOp4')
    public BtmLimbLtOp4: string
    @propertyMap('BtmLimbLtOp4Txt')
    public BtmLimbLtOp4Txt: string
    @propertyMap('ActiveMovRtOp1')
    public ActiveMovRtOp1: string
    @propertyMap('ActiveMovLtOp1')
    public ActiveMovLtOp1: string
    @propertyMap('ActiveMovOp1Txt')
    public ActiveMovOp1Txt: string
    @propertyMap('ActiveMovRtOp2')
    public ActiveMovRtOp2: string
    @propertyMap('ActiveMovLtOp2')
    public ActiveMovLtOp2: string
    @propertyMap('ActiveMovOp2Txt')
    public ActiveMovOp2Txt: string
    @propertyMap('ActiveMovRtOp3')
    public ActiveMovRtOp3: string
    @propertyMap('ActiveMovLtOp3')
    public ActiveMovLtOp3: string
    @propertyMap('ActiveMovOp3Txt')
    public ActiveMovOp3Txt: string
    @propertyMap('ActiveMovRtOp4')
    public ActiveMovRtOp4: string
    @propertyMap('ActiveMovLtOp4')
    public ActiveMovLtOp4: string
    @propertyMap('ActiveMovOp4Txt')
    public ActiveMovOp4Txt: string
    @propertyMap('ActiveMovRtOp5')
    public ActiveMovRtOp5: string
    @propertyMap('ActiveMovLtOp5')
    public ActiveMovLtOp5: string
    @propertyMap('ActiveMovOp5Txt')
    public ActiveMovOp5Txt: string
    @propertyMap('ActiveMovRtOp6')
    public ActiveMovRtOp6: string
    @propertyMap('ActiveMovLtOp6')
    public ActiveMovLtOp6: string
    @propertyMap('ActiveMovOp6Txt')
    public ActiveMovOp6Txt: string
    @propertyMap('VerbalReaction')
    public VerbalReaction: string
    @propertyMap('VoiceReaction')
    public VoiceReaction: string
    @propertyMap('WalkOp1')
    public WalkOp1: boolean
    @propertyMap('WalkOp2')
    public WalkOp2: boolean
    @propertyMap('WalkComment1')
    public WalkComment1: string
    @propertyMap('WalkComment2')
    public WalkComment2: string
    @propertyMap('WalkComment3')
    public WalkComment3: string
    @propertyMap('WalkComment4')
    public WalkComment4: string
    @propertyMap('FallRisk')
    public FallRisk: string
    @propertyMap('DomainOp1')
    public DomainOp1: boolean
    @propertyMap('DomainOp2')
    public DomainOp2: boolean
    @propertyMap('DomainOp3')
    public DomainOp3: boolean
    @propertyMap('DomainOp4')
    public DomainOp4: boolean
    @propertyMap('DomainOp5')
    public DomainOp5: boolean
    @propertyMap('GuideOp1')
    public GuideOp1: boolean
    @propertyMap('GuideOp2')
    public GuideOp2: boolean
    @propertyMap('Restless')
    public Restless: string

    
    

    /********************************* */




    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.FuncOp1 = data["FuncOp1"]
            this.FuncOp2 = data["FuncOp2"]
            this.FuncOp3 = data["FuncOp3"]
            this.LastPlace = data["LastPlace"]
            this.MedicalLabs = data["MedicalLabs"]
            this.MedicalPhotos = data["MedicalPhotos"]
            this.MedicalImaging = data["MedicalImaging"]
            this.Seconderydiagnoses = data["Seconderydiagnoses"]
            this.SocialBackGround = data["SocialBackGround"]
            this.Maindiagnoses = data["Maindiagnoses"]
            this.VerbalComm = data["VerbalComm"]
            this.VerbalCommComments = data["VerbalCommComments"]
            this.NonVerbalCommComments = data["NonVerbalCommComments"]
            this.NonVerbalComm = data["NonVerbalComm"]
            this.SightComments = data["SightComments"]
            this.Sight = data["Sight"]
            this.HearingComments = data["HearingComments"]
            this.Hearing = data["Hearing"]
            this.TimeOrientationComments = data["TimeOrientationComments"]
            this.TimeOrientation = data["TimeOrientation"]
            this.PlaceOrientationComments = data["PlaceOrientationComments"]
            this.PlaceOrientation = data["PlaceOrientation"]
            this.CooperationComments = data["CooperationComments"]
            this.Cooperation = data["Cooperation"]
            this.AdlOp1 = data["AdlOp1"]
            this.AdlOp2 = data["AdlOp2"]
            this.AdlOp3 = data["AdlOp3"]
            this.AdlOp4 = data["AdlOp4"]
            this.AdlOp5= data["AdlOp5"]
            this.AdlOp6= data["AdlOp6"]
            this.AdlOp7= data["AdlOp7"]
            this.AdlOp8= data["AdlOp8"]
            this.AdlOp9= data["AdlOp9"]
            this.AdlOp10= data["AdlOp10"]
            this.AdlOp1Comment = data["AdlOp1Comment"]
            this.AdlOp2Comment = data["AdlOp2Comment"]
            this.AdlOp3Comment = data["AdlOp3Comment"]
            this.AdlOp4Comment = data["AdlOp4Comment"]
            this.AdlOp5Comment= data["AdlOp5Comment"]
            this.AdlOp6Comment= data["AdlOp6Comment"]
            this.AdlOp7Comment= data["AdlOp7Comment"]
            this.AdlOp8Comment= data["AdlOp8Comment"]
            this.AdlOp9Comment= data["AdlOp9Comment"]
            this.AdlOp10Comment= data["AdlOp10Comment"]
            this.UpLimbRtOp1 = data["UpLimbRtOp1"]
            this.UpLimbLtOp1 = data["UpLimbLtOp1"]
            this.UpLimbLtOp1Txt = data["UpLimbLtOp1Txt"]
            this.UpLimbRtOp2 = data["UpLimbRtOp2"]
            this.UpLimbLtOp2 = data["UpLimbLtOp2"]
            this.UpLimbLtOp2Txt = data["UpLimbLtOp2Txt"]
            this.UpLimbRtOp3 = data["UpLimbRtOp3"]
            this.UpLimbLtOp3 = data["UpLimbLtOp3"]
            this.UpLimbLtOp3Txt = data["UpLimbLtOp3Txt"]
            this.LastPlaceOp1 = data["LastPlaceOp1"]
            this.LastPlaceOp2 = data["LastPlaceOp2"]
            this.LastPlaceOp3 = data["LastPlaceOp3"]
            this.LastPlaceOp4 = data["LastPlaceOp4"]
            this.LastPlaceOp5 = data["LastPlaceOp5"]
            this.BtmLimbRtOp1 = data["BtmLimbRtOp1"]
            this.BtmLimbLtOp1 = data["BtmLimbLtOp1"]
            this.BtmLimbLtOp1Txt = data["BtmLimbLtOp1Txt"]
            this.BtmLimbRtOp2 = data["BtmLimbRtOp2"]
            this.BtmLimbLtOp2 = data["BtmLimbLtOp2"]
            this.BtmLimbLtOp2Txt = data["BtmLimbLtOp2Txt"]
            this.BtmLimbRtOp3 = data["BtmLimbRtOp3"]
            this.BtmLimbLtOp3 = data["BtmLimbLtOp3"]
            this.BtmLimbLtOp3Txt = data["BtmLimbLtOp3Txt"]
            this.BtmLimbRtOp4 = data["BtmLimbRtOp4"]
            this.BtmLimbLtOp4 = data["BtmLimbLtOp4"]
            this.BtmLimbLtOp4Txt = data["BtmLimbLtOp4Txt"]
            this.VerbalReaction = data["VerbalReaction"]
            this.VoiceReaction = data["VoiceReaction"]
            this.FallRisk = data["FallRisk"]
            this.ActiveMovRtOp1 = data["ActiveMovRtOp1"]
            this.ActiveMovLtOp1 = data["ActiveMovLtOp1"]
            this.ActiveMovOp1Txt = data["ActiveMovOp1Txt"]
            this.ActiveMovRtOp2 = data["ActiveMovRtOp2"]
            this.ActiveMovLtOp2 = data["ActiveMovLtOp2"]
            this.ActiveMovOp2Txt = data["ActiveMovOp2Txt"]
            this.ActiveMovRtOp3 = data["ActiveMovRtOp3"]
            this.ActiveMovLtOp3 = data["ActiveMovLtOp3"]
            this.ActiveMovOp3Txt = data["ActiveMovOp3Txt"]
            this.ActiveMovRtOp4 = data["ActiveMovRtOp4"]
            this.ActiveMovLtOp4 = data["ActiveMovLtOp4"]
            this.ActiveMovOp4Txt = data["ActiveMovOp4Txt"]
            this.ActiveMovRtOp5 = data["ActiveMovRtOp5"]
            this.ActiveMovLtOp5 = data["ActiveMovLtOp5"]
            this.ActiveMovOp5Txt = data["ActiveMovOp5Txt"]
            this.ActiveMovRtOp6 = data["ActiveMovRtOp6"]
            this.ActiveMovLtOp6 = data["ActiveMovLtOp6"]
            this.ActiveMovOp6Txt = data["ActiveMovOp6Txt"]
            this.PainLevel= data["PainLevel"]


            this.PainPosition=data["PainPosition"]
            this.Neglect=data["Neglect"]
            this.EquOp1=data["EquOp1"]
            this.EquOp2=data["EquOp2"]
            this.EquOp3=data["EquOp3"]
            this.EquOp4=data["EquOp4"]
            this.EquOp5=data["EquOp5"]
            this.EquOp1Comment=data["EquOp1Comment"]
            this.EquOp2Comment=data["EquOp2Comment"]
            this.EquOp3Comment=data["EquOp3Comment"]
            this.EquOp4Comment=data["EquOp4Comment"]
            this.EquOp5Comment=data["EquOp5Comment"]
            this.WalkOp1=data["WalkOp1"]
            this.WalkOp2=data["WalkOp2"]
            this.WalkComment1=data["WalkComment1"]
            this.WalkComment2=data["WalkComment2"]
            this.WalkComment3=data["WalkComment3"]
            this.WalkComment4=data["WalkComment4"]
            this.Summary=data["Summary"]
            this.Decision=data["Decision"]
            this.Reasons=data["Reasons"]
            this.TreatmentGoals=data["TreatmentGoals"]
            this.TreatmentType=data["TreatmentType"]
            this.TreatedByOpt1=data["TreatedByOpt1"]
            this.TreatedByOpt2=data["TreatedByOpt2"]
            this.TreatedByOpt3=data["TreatedByOpt3"]
            this.TreatedByOpt4=data["TreatedByOpt4"]
            this.TreatmentDetails=data["TreatmentDetails"]
            this.EquipmentNeeds=data["EquipmentNeeds"]
            this.Frequency=data["Frequency"]
            this.DocOpt1=data["DocOpt1"]
            this.DocOpt2=data["DocOpt2"]
            this.ID = data["ID"]
            this.ClosedById = data["ClosedById"]
            this.PersonelID = data["PersonelID"]
            this.Status = data["Status"]
            this.ClosedAt = data["ClosedAt"]
            this.CreatedById = data["CreatedById"]
            this.CreatedBy = data["CreatedBy"]
            this.Comment1 = data["Comment1"]
            this.Comment2 = data["Comment2"]
            this.DrainComment = data["DrainComment"]
            this.SkinComment = data["SkinComment"]
            this.DeforComment = data["DeforComment"]
            this.BloatComment = data["BloatComment"]
            this.SetPattern = data["SetPattern"]
            this.StandPattern = data["StandPattern"]
            this.Comment10 = data["Comment10"]
            this.BodySize = data["BodySize"]
            this.Skinstat = data["Skinstat"]
            this.Bedsore = data["Bedsore"]
            this.Deformation = data["Deformation"]
            this.Bloat = data["Bloat"]
            this.Sitting = data["Sitting"]
            this.Mobility = data["Mobility"]
            this.SittingStatus = data["SittingStatus"]
            this.BreathMethod = data["BreathMethod"]
            this.OxygenUse = data["OxygenUse"]
            this.IsDay = data["IsDay"]
            this.IsNight = data["IsNight"]
            this.Splint = data["Splint"]
            this.Drain = data["Drain"]
            this.Period = data["Period"]
            this.DomainOp1 = data["DomainOp1"]
            this.DomainOp2 = data["DomainOp2"]
            this.DomainOp3 = data["DomainOp3"]
            this.DomainOp4 = data["DomainOp4"]
            this.DomainOp5 = data["DomainOp5"]
            this.GuideOp1 = data["GuideOp1"]
            this.GuideOp2 = data["GuideOp2"]
            this.VigilanceComments = data["VigilanceComments"]
            this.Vigilance = data["Vigilance"]
            this.Restless = data["Restless"]  
        }
        else {
            this.ClosedById = undefined
            this.PersonelID = ""
            this.Maindiagnoses = ""
            this.Seconderydiagnoses = ""
            this.SocialBackGround = ""
            this.Status = 'A'
            this.ClosedAt = undefined
            this.VerbalComm = ""
            this.VerbalCommComments = ""
            this.NonVerbalCommComments = ""
            this.NonVerbalComm = ""
            this.SightComments = ""
            this.Sight = ""
            this.HearingComments = ""
            this.Hearing = ""
            this.TimeOrientationComments = ""
            this.TimeOrientation = ""
            this.PlaceOrientationComments = ""
            this.PlaceOrientation = ""
            this.CooperationComments = ""
            this.Cooperation = ""
        }
    }
}



export class PhysioTherapyFollowUp {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID') //
    public PersonelID: string
    @propertyMap('RichText') //
    public RichText: string
    @propertyMap('Attachments')
    Attachments: Attachment[]
    @propertyMap('Status')
    Status: string
    @propertyMap('LastUpdateById')
    public LastUpdateById: string
    public LastUpdateBy: User

    @propertyMap('Diagnoses')
    Diagnoses: Diagnoses[]
    @propertyMap('DrugInstructions')
    DrugInstructions: DrugInstruction[]
    @propertyMap('BedSores')
    BedSores: BedSore[]

    @propertyMap('PhysioObservationFiles')
    PhysioObservationFiles: Attachment[]


    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.RichText = data["RichText"]
            this.Status = data["Status"]
            this.LastUpdateById = data["LastUpdateById"]
            this.LastUpdateBy = data["LastUpdateBy"]
            this.Diagnoses = data["Diagnoses"]
            this.DrugInstructions = data["DrugInstructions"]
            this.BedSores = data["BedSores"]
            this.PhysioObservationFiles = data["PhysioObservationFiles"]

        }
        else {
            this.Status = 'A'
        }
    }
}
export class PhysicalTest{
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID') 
    public PersonelID: string
    @propertyMap('Joint') //
    public Joint: string
    @propertyMap('Movement')
    public Movement: string
    @propertyMap('RTLT') 
    public RTLT: boolean

    @propertyMap('Side')
    public Side: string
    @propertyMap('Status') 
    public Status: string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false

}


export class PhysioAssessment{
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID') 
    public PersonelID: string
    @propertyMap('Function1') 
    public Function1: string
    @propertyMap('Function2') 
    public Function2: string
    @propertyMap('Function3') 
    public Function3: string
    @propertyMap('Function4') 
    public Function4: string
    @propertyMap('Function5') 
    public Function5: string
    @propertyMap('Function6') 
    public Function6: string
    @propertyMap('Function7') 
    public Function7: string
    @propertyMap('Function8') 
    public Function8: string
    @propertyMap('Function9') 
    public Function9: string
    @propertyMap('Summary') 
    public Summary: string
    @propertyMap('Recommendations') 
    public Recommendations: string
    @propertyMap('Diagnoses') 
    public Diagnoses: string
    @propertyMap('Cooperation') 
    public Cooperation: string
    @propertyMap('Orientation') 
    public Orientation: string
    @propertyMap('Status') 
    public Status: string
    @propertyMap('LastUpdateById')
    public LastUpdateById: string
    public LastUpdateBy: User
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('ClosedAt')
    ClosedAt: Date

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.Function1 = data["Function1"]
            this.Function2 = data["Function2"]
            this.Function3 = data["Function3"]
            this.Function4 = data["Function4"]
            this.Function5 = data["Function5"]
            this.Function6 = data["Function6"]
            this.Function7 = data["Function7"]
            this.Function8 = data["Function8"]
            this.Function9 = data["Function9"]
            this.Summary = data["Summary"]
            this.Recommendations = data["Recommendations"]
            this.Diagnoses = data["Diagnoses"]
            this.Cooperation = data["Cooperation"]
            this.Orientation = data["Orientation"]
            this.Status = data["Status"]
            this.ClosedById = data["ClosedById"]
            this.ClosedAt = data["ClosedAt"]
            this.LastUpdateById = data["LastUpdateById"]
            this.LastUpdateBy = data["LastUpdateBy"]

        }
        else {
            this.Status = 'A'
            this.CreatedAt = new Date()
        }
    }

}
