import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { HazardType, PersonelHazards } from '../../../../app/models/hazard';
import { sideNaveGroups } from '../../../../app/services/enums';
import { MedicalFileProviderService } from '../../../../app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../app/services/toastr.service';
import { CaretexComponent } from '../../../../app/shared/CaretexComponent';
import { CommonInterface } from '../../../../app/shared/SharedInterface';
import Swal from 'sweetalert2';
import { CaretxMedicalProviderService } from '../../doctor-services/Medical.data-provider.service';
import { FormControl, FormGroup } from '@angular/forms';
import { CaretxSetupsProviderService } from '../../../../app/caretx-setups-module/setup-services/setups.data-provider.service';

@Component({
  selector: 'app-hazardslist',
  templateUrl: './hazardslist.component.html',
  styleUrls: ['./hazardslist.component.css']
})
export class HazardslistComponent extends CaretexComponent implements OnInit,CommonInterface {
  
  hazardsTypes: HazardType[] 
  hazards: PersonelHazards[] = []
  currentRowIndex: number
  id: string
  moduleName:string = "רשימת זיהומים"
  totalNumOfRecords: Number = 0



RecordStatus = [{id:"A" , desc:"רשומה פתוחה"},{id:"C" , desc:"רשומה נעולה"}]


searchForm = new FormGroup({
  created_at_from: new FormControl(''),
  created_at_to: new FormControl(''),
  status: new FormControl(''), //, [Validators.required, Validators.email]),
  closed_at_from: new FormControl(''),
  closed_at_to: new FormControl(''),
  hazard_type_id: new FormControl(''),
});



formData : any[] 




  constructor(
    private router: Router,
    private medicalProviderService: CaretxMedicalProviderService,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    public medicalFileService: MedicalFileProviderService,
    private _title: Title,
    private toastrService: ToasterService,
    private route: ActivatedRoute,) { 
      
      super(medicalFileService)



    }
  SideNaveGroup: sideNaveGroups=sideNaveGroups.Medical;

  ngOnInit(): void {
    this._title.setTitle('Caretx | זיהומים');
    this.id = this.route.parent.snapshot.params['id'] 
    this.CheckIfComponentIsDisabled(this.id)
    this.getPersonelHazardsFromDB("")

    this.caretxSetupsProviderService.GetHazardTypes().subscribe((data => {
      this.hazardsTypes = data
      this.formData=  [
        {"formcontrolname":"hazard_type_id","label":"סוג זיהום" ,"type":"multi" , "value":JSON.stringify(this.hazardsTypes) ,"selectid":"ID" , "selectval":"Type"},
        {"formcontrolname":"status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},

        {"formcontrolname":"created_at_from","label":"מתאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
        {"formcontrolname":"created_at_to","label":"עד תאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
        {"formcontrolname":"closed_at_from","label":"תאריך נעילה מ" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
        {"formcontrolname":"closed_at_to","label":"תאריך נעילה עד" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},

      ]
    }))



  }

  getPersonelHazardsFromDB(filter:string) {

    this.medicalProviderService.getPersonelHazard(this.id, filter).subscribe(
      data => {
        this.hazards = data
        this.totalNumOfRecords = data.length
      },
      err => {
        this.hazards = []
      }
    )
  }

  AddNewHazard($event) {
    this.router.navigateByUrl("caretexfile/" + this.id + "/hazards/new")
  }
  OnSDrillToHazardDetail($event, hazard) {
    this.router.navigateByUrl("caretexfile/" + this.id + "/hazards/" + hazard.ID)
  }
  generateReport($event, hazard) {

  }
  deleteRow(i) {
    this.currentRowIndex = i
    if (!(this.hazards[i].Status == "C")) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteHazard(this.hazards[this.currentRowIndex])
        }
      })

    }
  }

  deleteHazard(hazard: PersonelHazards) {
    let filter = '(id=' + '\'' + hazard.ID + '\'' + ')'
    this.medicalProviderService.deletePersonelHazard(this.id, filter).subscribe({
      next: data => {
        this.getPersonelHazardsFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }

  SearchWithFilter($event){
    this.getPersonelHazardsFromDB($event)
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty(){
    return false;
  }

}
