import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { MedicalDiagnoses } from 'src/app/models/drug';
import { ModelMapper } from 'src/app/models/modelmapper';
import { Tenant } from 'src/app/models/tenant';
import { AdhesionPlace, Ambulance, BedSoreLocation, BedSoreSide, BedSoreStatus, BedSoreType, ConsciousnessState, ContaminationSource, Departments, DietarySupplement, DietaryUomCode, DrinkForm, DrugUom, EatingManner, EatingStatus, EventBeds, EventLocation, EventType, FamilyRelations, FeedForm, FoodAddition, Funder, HodgeTest, Hospital, HospitalRoom, HospitalizationTeam, InfectionStatus, IsolationType, MealCode, MeasuresTypes, MedicalFileStatus, MenuCode, NursingStatus, NutritionalStatus, PainLevel, PainLocation, PainMitigatingFactor, PainNature, PainSymptoms, Paintreatment, PcrTest, Referrer, ResistanceType, SocialPocketTrackingCode, SocialProvider, SwallowStatus, TeethStatus, Texture, Vaccinations, WorkingShift } from 'src/app/models/generalhousedsetups';
import { PersonalMeasureCode } from 'src/app/models/measure';
import { Hazard, HazardRisk, HazardType } from 'src/app/models/hazard';
import { LabOrderCategories, LabOrderCheck, LabTemplate } from 'src/app/models/testlabchecks';
import { InstrStatus } from 'src/app/services/enums';
import { Instruction } from 'src/app/models/nurseinstruction';
import { WeightStatus } from '../../models/generalhousedsetups';
import { TeekDrugInstruction } from 'src/app/models/drugInstruction';
import { SocialTitle } from 'src/app/models/user';
import { Attachment } from 'src/app/models/attachments';
import { NursePhysicalCheck, NursePhysicalStatus } from 'src/app/models/nursingphysicalstatuses';


@Injectable()
export class CaretxSetupsProviderService {
  public medicalDiagnoses: MedicalDiagnoses[] = []

  constructor(private httpClient: HttpClient) {
  }


  uploadHouseLogo(image) {
    const formData: FormData = new FormData();
    formData.append('Image', image, image["name"]);
    return this.httpClient.post(environment.gateway + '/houselogo', formData)
  }



  getVaccinations(): Observable<Vaccinations[]> {
    return this.httpClient.get(environment.gateway + '/vaccinations').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Vaccinations).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateUpdateVaccinations(records: Vaccinations[]) {
    return this.httpClient.post(environment.gateway + '/vaccinations', records)
  }

  deleteVaccinations(record :Vaccinations) {
    return this.httpClient.delete(environment.gateway + '/vaccinations/' + record.ID) 
  }






  getPainLevel(): Observable<PainLevel[]> {
    return this.httpClient.get(environment.gateway + '/painlevel').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PainLevel).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateUpdatePainLevel(painlevels: PainLevel[]) {
    return this.httpClient.post(environment.gateway + '/painlevel', painlevels)
  }

  deletePainLevel(painlevel :PainLevel) {
    return this.httpClient.delete(environment.gateway + '/painlevel/' + painlevel.ID) 
  }


  getPainLocation(): Observable<PainLocation[]> {
    return this.httpClient.get(environment.gateway + '/painlocation').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PainLocation).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateUpdatePainLocation(records: PainLocation[]) {
    return this.httpClient.post(environment.gateway + '/painlocation', records)
  }

  deletePainLocation(record :PainLocation) {
    return this.httpClient.delete(environment.gateway + '/painlocation/' + record.ID) 
  }


  getPainMitigatingFactor(): Observable<PainMitigatingFactor[]> {
    return this.httpClient.get(environment.gateway + '/painmitigatingfactors').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PainMitigatingFactor).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateUpdatePainMitigatingFactor(records: PainMitigatingFactor[]) {
    return this.httpClient.post(environment.gateway + '/painmitigatingfactors', records)
  }

  deletePainMitigatingFactor(record :PainMitigatingFactor) {
    return this.httpClient.delete(environment.gateway + '/painmitigatingfactors/' + record.ID) 
  }



  getPainSymptoms(): Observable<PainSymptoms[]> {
    return this.httpClient.get(environment.gateway + '/painsymptoms').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PainSymptoms).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateUpdatePainSymptoms(records: PainSymptoms[]) {
    return this.httpClient.post(environment.gateway + '/painsymptoms', records)
  }

  deletePainSymptoms(record :PainSymptoms) {
    return this.httpClient.delete(environment.gateway + '/painsymptoms/' + record.ID) 
  }


  getPainNature(): Observable<PainNature[]> {
    return this.httpClient.get(environment.gateway + '/painnature').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PainNature).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateUpdatePainNature(records: PainNature[]) {
    return this.httpClient.post(environment.gateway + '/painnature', records)
  }

  deletePainNature(record :PainNature) {
    return this.httpClient.delete(environment.gateway + '/painnature/' + record.ID) 
  }



  getPaintreatment(): Observable<Paintreatment[]> {
    return this.httpClient.get(environment.gateway + '/paintreatments').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Paintreatment).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateUpdatePaintreatment(records: Paintreatment[]) {
    return this.httpClient.post(environment.gateway + '/paintreatments', records)
  }

  deletePaintreatment(record :Paintreatment) {
    return this.httpClient.delete(environment.gateway + '/paintreatments/' + record.ID) 
  }





  
  getAmbulanceProviders(): Observable<Ambulance[]> {
    return this.httpClient.get(environment.gateway + '/ambulanceprovider').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Ambulance).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateUpdateAmbulanceProviders(additions: Ambulance[]) {
    return this.httpClient.post(environment.gateway + '/ambulanceprovider', additions)
  }

  deleteAmbulanceProvider(addition : Ambulance) {
    return this.httpClient.delete(environment.gateway + '/ambulanceprovider/' + addition.ID) 
  }



  


  getFoodAdditions(): Observable<FoodAddition[]> {
    return this.httpClient.get(environment.gateway + '/foodaddition').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(FoodAddition).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateUpdateFoodAddition(additions: FoodAddition[]) {
    return this.httpClient.post(environment.gateway + '/foodaddition', additions)
  }

  deleteFoodAddition(addition : FoodAddition) {
    return this.httpClient.delete(environment.gateway + '/foodaddition/' + addition.ID) 
  }



  getDietarySupplement(): Observable<DietarySupplement[]> {
    return this.httpClient.get(environment.gateway + '/dietarysupplement').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DietarySupplement).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateUpdateDietarySupplement(supplements: DietarySupplement[]) {
    return this.httpClient.post(environment.gateway + '/dietarysupplement', supplements)
  }

  deleteDietarySupplement(supplement : DietarySupplement) {
    return this.httpClient.delete(environment.gateway + '/dietarysupplement/' + supplement.ID) 
  }



  getSocialTitles(): Observable<SocialTitle[]> {
    return this.httpClient.get(environment.gateway + '/socialtitles').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(SocialTitle).map(item)
        })
      }), catchError(this.errorHandler));
  }




  getDietaryEatingManner(): Observable<EatingManner[]> {
    return this.httpClient.get(environment.gateway + '/dietaryeatingmanneres').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(EatingManner).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getDietaryTextures(): Observable<Texture[]> {
    return this.httpClient.get(environment.gateway + '/dietarytextures').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Texture).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getDietaryMenuCode(): Observable<MenuCode[]> {
    return this.httpClient.get(environment.gateway + '/dietarymenucode').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(MenuCode).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getDietaryUomCodes(): Observable<DietaryUomCode[]> {
    return this.httpClient.get(environment.gateway + '/dietaryuomcodes').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DietaryUomCode).map(item)
        })
      }), catchError(this.errorHandler));
  }

  getDietaryMealCodes(): Observable<MealCode[]> {
    return this.httpClient.get(environment.gateway + '/dietarymealcodes').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(MealCode).map(item)
        })
      }), catchError(this.errorHandler));
  }









  GetTenantInfoById(tenantId: string): Observable<Tenant> {
    return this.httpClient.get(environment.gateway + '/tenants/' + tenantId).pipe(
      map((response: any) => {
        return new ModelMapper(Tenant).map(response)
      }), catchError(this.errorHandler));
  }
  UpdateTenantInfoById(tenant: Tenant) {
    return this.httpClient.put(environment.gateway + '/tenants/' + tenant.tenantID, tenant)
  }




  GetUserAllowedDepartments(userId:string): Observable<Departments[]> {
    return this.httpClient.get(environment.gateway + '/users/'+userId+'/departments').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Departments).map(item)
        })
      }), catchError(this.errorHandler));
  }



  GetReferrers(): Observable<Referrer[]> {
    return this.httpClient.get(environment.gateway + '/referrers').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Referrer).map(item)
        })
      }), catchError(this.errorHandler));
  }
  CreateReferrers(newReferrers: Referrer[]) {
    return this.httpClient.post(environment.gateway + '/referrers', newReferrers)
  }

  DeleteReferrers( ReferredId: string) {
    return this.httpClient.delete(environment.gateway + '/referrers/' + ReferredId)
  }

  GetHouseDepartments(): Observable<Departments[]> {
    return this.httpClient.get(environment.gateway + '/housedepartments').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Departments).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteDepartment( departmenId: string) {
    return this.httpClient.delete(environment.gateway + '/housedepartments/' + departmenId)
  }
  CreateUpdateDepartment(newDepartments: Departments[]) {
    return this.httpClient.post(environment.gateway + '/housedepartments', newDepartments)
  }

  GetHouseFunders(): Observable<Funder[]> {
    return this.httpClient.get(environment.gateway + '/funders' ).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Funder).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteFunder( funderId: string) {
    return this.httpClient.delete(environment.gateway + '/funders/' + funderId)
  }
  CreateUpdateFunder( newFunders: Funder[]) {
    return this.httpClient.post(environment.gateway + '/funders', newFunders)
  }

  GetWeightStatuses(tenantId: string): Observable<WeightStatus[]> {
    return this.httpClient.get(environment.gateway + '/weightstatus').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(WeightStatus).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteWeightStatuses(tenantId: string, statusId: string) {
    return this.httpClient.delete(environment.gateway + '/weightstatus/'  + statusId)
  }
  CreateUpdateWeightStatuses(tenantId: string, statuses: WeightStatus[]) {
    return this.httpClient.post(environment.gateway + '/weightstatus' , statuses)
  }



  GetDrinkForms(tenantId: string): Observable<DrinkForm[]> {
    return this.httpClient.get(environment.gateway + '/drinkform').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DrinkForm).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteDrinkForms(tenantId: string, statusId: string) {
    return this.httpClient.delete(environment.gateway + '/drinkform/'  + statusId)
  }
  CreateDrinkForms(tenantId: string, statuses: DrinkForm[]) {
    return this.httpClient.post(environment.gateway + '/drinkform' , statuses)
  }


  GetNutritionalStatus(tenantId: string): Observable<NutritionalStatus[]> {
    return this.httpClient.get(environment.gateway + '/nutritionalstatus').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NutritionalStatus).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteNutritionalStatus(tenantId: string, statusId: string) {
    return this.httpClient.delete(environment.gateway + '/nutritionalstatus/'  + statusId)
  }
  CreateNutritionalStatus(tenantId: string, statuses: NutritionalStatus[]) {
    return this.httpClient.post(environment.gateway + '/nutritionalstatus' , statuses)
  }



  GetFeedForms(tenantId: string): Observable<FeedForm[]> {
    return this.httpClient.get(environment.gateway + '/feedform').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(FeedForm).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteFeedForms(tenantId: string, statusId: string) {
    return this.httpClient.delete(environment.gateway + '/feedform/'  + statusId)
  }
  CreateFeedForms(tenantId: string, statuses: FeedForm[]) {
    return this.httpClient.post(environment.gateway + '/feedform' , statuses)
  }


  GetSwallowStatus(tenantId: string): Observable<SwallowStatus[]> {
    return this.httpClient.get(environment.gateway + '/swallowstatus').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(SwallowStatus).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteSwallowStatus(tenantId: string, statusId: string) {
    return this.httpClient.delete(environment.gateway + '/swallowstatus/'  + statusId)
  }
  CreateSwallowStatus(tenantId: string, statuses: SwallowStatus[]) {
    return this.httpClient.post(environment.gateway + '/swallowstatus' , statuses)
  }


  GetTeethStatus(tenantId: string): Observable<TeethStatus[]> {
    return this.httpClient.get(environment.gateway + '/teethstatus').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(TeethStatus).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteTeethStatus(tenantId: string, statusId: string) {
    return this.httpClient.delete(environment.gateway + '/teethstatus/'  + statusId)
  }
  CreateTeethStatus(tenantId: string, statuses: TeethStatus[]) {
    return this.httpClient.post(environment.gateway + '/teethstatus' , statuses)
  }



  GetEatingStatus(tenantId: string): Observable<EatingManner[]> {
    return this.httpClient.get(environment.gateway + '/eatingstatus').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(EatingManner).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteEatingStatus(tenantId: string, statusId: string) {
    return this.httpClient.delete(environment.gateway + '/eatingstatus/'  + statusId)
  }
  CreateEatingStatus(tenantId: string, statuses: EatingManner[]) {
    return this.httpClient.post(environment.gateway + '/eatingstatus' , statuses)
  }




  GetHospitals(tenantId: string): Observable<Hospital[]> {
    return this.httpClient.get(environment.gateway + '/hospitals/' + tenantId).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Hospital).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteHospital(tenantId: string, hospitalId: string) {
    return this.httpClient.delete(environment.gateway + '/hospitals/' + tenantId + '/hospital/' + hospitalId)
  }
  CreateUpdateHospital(tenantId: string, newHospitals: Hospital[]) {
    return this.httpClient.post(environment.gateway + '/hospitals/' + tenantId, newHospitals)
  }





  GetEssentialMeasures(filter: string): Observable<PersonalMeasureCode[]> {
    let filterQuery = "";
    if (filter != null)
    filterQuery = '?filter=' + filter

    return this.httpClient.get(environment.gateway + '/measurescodes'+ filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PersonalMeasureCode).map(item)
        })
      }), catchError(this.errorHandler));
  }




  CreateUpdateEssentialMeasures(essentialMeasures: PersonalMeasureCode[]) {
    return this.httpClient.post(environment.gateway + '/measurescodes', essentialMeasures)
  }

  CreateEssentialMeasure(essentialMeasure: PersonalMeasureCode) {
    return this.httpClient.post(environment.gateway + '/measurescodes', essentialMeasure)
  }

  UpdateEssentialMeasure(essentialMeasure: PersonalMeasureCode) {
    return this.httpClient.put(environment.gateway + '/measurescodes', essentialMeasure)
  }




  DeleteEssentialMeasure(measureId: string) {
    return this.httpClient.delete(environment.gateway + '/measurescodes/' + measureId)
  }


  DeleteListValue(measureId: string , valid:string) {
    return this.httpClient.delete(environment.gateway + '/measurescodes/' + measureId + '/ListValues/'+valid)
  }





  GetNursingStatuses(): Observable<NursingStatus[]> {
    return this.httpClient.get(environment.gateway + '/nursingstatuses').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NursingStatus).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteNursingStatuses(statusId: string) {
    return this.httpClient.delete(environment.gateway + '/nursingstatuses/' + statusId)
  }
  CreateUpdateNursingStatuses(newNursingStatuses: NursingStatus[]) {
    return this.httpClient.post(environment.gateway + '/nursingstatuses', newNursingStatuses)
  }

  GetBedSoreTypes(tenantId: string): Observable<BedSoreType[]> {
    return this.httpClient.get(environment.gateway + '/bedsoretypes').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(BedSoreType).map(item)
        })
      }), catchError(this.errorHandler));
  }
  CreateBedSoreTypes(tenantId: string, bedsoretypes: BedSoreType[]) {
    return this.httpClient.post(environment.gateway + '/bedsoretypes', bedsoretypes)
  }
  DeleteBedSoreType(tenantId: string, bedsoretypeid: string) {
    return this.httpClient.delete(environment.gateway + '/bedsoretypes/' + bedsoretypeid)
  }

  GetBedSoreLocations(tenantId: string): Observable<BedSoreLocation[]> {
    return this.httpClient.get(environment.gateway + '/bedsorelocations').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(BedSoreLocation).map(item)
        })
      }), catchError(this.errorHandler));
  }
  CreateBedSoreLocations(tenantId: string, bedsoretypes: BedSoreLocation[]) {
    return this.httpClient.post(environment.gateway + '/bedsorelocations', bedsoretypes)
  }
  DeleteBedSoreLocation(tenantId: string, bedsorelocationid: string) {
    return this.httpClient.delete(environment.gateway + '/bedsorelocations/' + bedsorelocationid)
  }

  GetBedSoreStatuses(): Observable<BedSoreStatus[]> {
    return this.httpClient.get(environment.gateway + '/bedsorestatuses').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(BedSoreStatus).map(item)
        })
      }), catchError(this.errorHandler));
  }
  CreateBedSoreStatuses(tenantId: string, bedsoretypes: BedSoreStatus[]) {
    return this.httpClient.post(environment.gateway + '/bedsorestatuses', bedsoretypes)
  }
  DeleteBedSoreStatus(tenantId: string, bedsorestatusid: string) {
    return this.httpClient.delete(environment.gateway + '/bedsorestatuses/' + bedsorestatusid)
  }

  GetBedSoreSides(tenantId: string): Observable<BedSoreSide[]> {
    return this.httpClient.get(environment.gateway + '/bedsoresides').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(BedSoreSide).map(item)
        })
      }), catchError(this.errorHandler));
  }
  CreateBedSoreSides(tenantId: string, bedsoretypes: BedSoreSide[]) {
    return this.httpClient.post(environment.gateway + '/bedsoresides', bedsoretypes)
  }
  DeleteBedSoreSide(tenantId: string, bedsoresideid: string) {
    return this.httpClient.delete(environment.gateway + '/bedsoresides/' + bedsoresideid)
  }


  GetHazardTypes(): Observable<HazardType[]> {
    return this.httpClient.get(environment.gateway + '/hazardtypes').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(HazardType).map(item)
        })
      }), catchError(this.errorHandler));
  }
  CreateHazardTypes(hazardTypes: HazardType[]) {
    return this.httpClient.post(environment.gateway + '/hazardtypes', hazardTypes)
  }
  DeleteHazardType(hazardTypeid: string) {
    return this.httpClient.delete(environment.gateway + '/hazardtypes/' + hazardTypeid)
  }



  GetHazards(): Observable<Hazard[]> {
    return this.httpClient.get(environment.gateway + '/hazard').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Hazard).map(item)
        })
      }), catchError(this.errorHandler));
  }
  CreateHazards(hazards: Hazard[]) {
    return this.httpClient.post(environment.gateway + '/hazard', hazards)
  }
  DeleteHazard(hazardid: string) {
    return this.httpClient.delete(environment.gateway + '/hazard/' + hazardid)
  }



  GetHazardRisks(): Observable<HazardRisk[]> {
    return this.httpClient.get(environment.gateway + '/hazardrisk').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(HazardRisk).map(item)
        })
      }), catchError(this.errorHandler));
  }
  CreateHazardRisks(hazardRisks: HazardRisk[]) {
    return this.httpClient.post(environment.gateway + '/hazardrisk', hazardRisks)
  }
  DeleteHazardRisk(hazardriskid: string) {
    return this.httpClient.delete(environment.gateway + '/hazardrisk/' + hazardriskid)
  }



  GetLabTypes(hmo: string): Observable<LabOrderCategories[]> {
    return this.httpClient.get(environment.gateway + '/labtypes/' + hmo).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(LabOrderCategories).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateLabTypes(labTypes: LabOrderCategories[]) {
    return this.httpClient.post(environment.gateway + '/labtypes', labTypes)
  }

  GetLabTemplate(labctaegory: string, hmo: string): Observable<LabTemplate[]> {
    return this.httpClient.get(environment.gateway + '/laboratorytemplates/' + hmo + "/" + labctaegory).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(LabTemplate).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateLabTemplates(labstemplates: LabTemplate[]) {
    return this.httpClient.post(environment.gateway + '/laboratorytemplates', labstemplates)
  }



  // Get Lab Order Categories
  GetCategoryLabs(hmo: string, filter: string): Observable<LabOrderCategories[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

    return this.httpClient.get(environment.gateway + '/labcategories/' + hmo + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(LabOrderCategories).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateUpdateCategoryLabs(labCategories: LabOrderCategories[]) {
    return this.httpClient.post(environment.gateway + '/labcategories', labCategories)
  }
  // Get Lab Templates 
  GetLabTemplates(hmo: string, primarycode: string): Observable<LabTemplate[]> {
    return this.httpClient.get(environment.gateway + '/labtemplates/' + hmo + "/" + primarycode).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(LabTemplate).map(item)
        })
      }), catchError(this.errorHandler));
  }

  AddLabToCategory(labstoCategory: LabOrderCheck[]) {
    return this.httpClient.post(environment.gateway + '/laborders', labstoCategory)
  }

  AddTemplateToLab(templates: LabTemplate[]) {
    return this.httpClient.post(environment.gateway + '/labtemplates', templates)
  }


  // Get Family Relations
  GetFamilyRelations(): Observable<FamilyRelations[]> {
    return this.httpClient.get(environment.gateway + '/familyrelations').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(FamilyRelations).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteFamilyRelation(relationid: string) {
    return this.httpClient.delete(environment.gateway + '/familyrelations/' + relationid)
  }
  CreateFamilyRelation(relations: FamilyRelations[]) {
    return this.httpClient.post(environment.gateway + '/familyrelations', relations)
  }


  // Get Social Providers
  GetSocialProviders(): Observable<SocialProvider[]> {
    return this.httpClient.get(environment.gateway + '/socialprovider').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(SocialProvider).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteSocialProviders(relationid: string) {
    return this.httpClient.delete(environment.gateway + '/socialprovider/' + relationid)
  }
  CreateSocialProviders(relations: SocialProvider[]) {
    return this.httpClient.post(environment.gateway + '/socialprovider', relations)
  }


  DeleteInstruction(instructionid: string) {
    return this.httpClient.delete(environment.gateway + '/nurseinstructionsetup/' + instructionid)
  }
  CreateInstruction(instructions: Instruction[]) {
    return this.httpClient.post(environment.gateway + '/nurseinstructionsetup', instructions)
  }
  GetInstruction(): Observable<Instruction[]> {
    return this.httpClient.get(environment.gateway + '/nurseinstructionsetup').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Instruction).map(item)
        })
      }), catchError(this.errorHandler));
  }



  GetMeasuresTypes(tenantId: string): Observable<MeasuresTypes[]> {
    return this.httpClient.get(environment.gateway + '/measuretypes').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(MeasuresTypes).map(item)
        })
      }), catchError(this.errorHandler));
  }




  CreateMeasuresTypes(measureTypes: MeasuresTypes[]) {
    return this.httpClient.post(environment.gateway + '/measuretypes', measureTypes)
  }




  GetPocketCodes(tenantId: string): Observable<SocialPocketTrackingCode[]> {
    return this.httpClient.get(environment.gateway + '/pocketcode').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(SocialPocketTrackingCode).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeletePocketCodes(tenantId: string, codeId: string) {
    return this.httpClient.delete(environment.gateway + '/pocketcode/' + codeId)
  }
  CreateUpdatePocketCodes(tenantId: string, codes: SocialPocketTrackingCode[]) {
    return this.httpClient.post(environment.gateway + '/pocketcode', codes)
  }





  getDrugUoms(): Observable<DrugUom[]> {
    return this.httpClient.get(environment.gateway + '/druguom').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DrugUom).map(item)
        })
      }), catchError(this.errorHandler));
  }

  CreateUpdateDrugUoms(additions: DrugUom[]) {
    return this.httpClient.post(environment.gateway + '/druguom', additions)
  }

  deleteDrugUom(uom : DrugUom) {
    return this.httpClient.delete(environment.gateway + '/druguom/' + uom.ID) 
  }



  GetBedsTypes(): Observable<EventBeds[]> {
    return this.httpClient.get(environment.gateway + '/eventbeds').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(EventBeds).map(item)
        })
      }), catchError(this.errorHandler));
  }


  DeleteBedType(eventBedId: string) {
    return this.httpClient.delete(environment.gateway + '/eventbeds/' + eventBedId)
  }

  CreateUpdateBedTypes(newEventBeds: EventBeds[]) {
    return this.httpClient.post(environment.gateway + '/eventbeds', newEventBeds)
  }

  GetConsciousnessState(): Observable<ConsciousnessState[]> {
    return this.httpClient.get(environment.gateway + '/consciousnessstate').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(ConsciousnessState).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteConsciousnessState(ConsciousnessStateId: string) {
    return this.httpClient.delete(environment.gateway + '/consciousnessstate/' + ConsciousnessStateId)
  }

  CreateUpdateConsciousnessState(newStates: ConsciousnessState[]) {
    return this.httpClient.post(environment.gateway + '/consciousnessstate', newStates)
  }

  DeleteEventLocation( eventTLocationId: string) {
    return this.httpClient.delete(environment.gateway + '/eventlocations/' + eventTLocationId)
  }

  CreateUpdateEventLocation(newEventLocations: EventLocation[]) {
    return this.httpClient.post(environment.gateway + '/eventlocations', newEventLocations)
  }



  GetEventLocations(): Observable<EventLocation[]> {
    return this.httpClient.get(environment.gateway + '/eventlocations').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(EventLocation).map(item)
        })
      }), catchError(this.errorHandler));
  }



  GetEventTypes(): Observable<EventType[]> {
    return this.httpClient.get(environment.gateway + '/eventtypes').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(EventType).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteEventType( eventTypeId: string) {
    return this.httpClient.delete(environment.gateway + '/eventtypes/' + eventTypeId)
  }
  CreateUpdateEventType(newEventTypes: EventType[]) {
    return this.httpClient.post(environment.gateway + '/eventtypes', newEventTypes)
  }


  GetWorkShifts(): Observable<WorkingShift[]> {
    return this.httpClient.get(environment.gateway + '/workshift').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(WorkingShift).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteWorkShift( shiftId: string) {
    return this.httpClient.delete(environment.gateway + '/workshift/' + shiftId)
  }
  CreateUpdateWorkShift(newWorkingShifts: WorkingShift[]) {
    return this.httpClient.post(environment.gateway + '/workshift', newWorkingShifts)
  }




  GetMedicalStatuses(): Observable<MedicalFileStatus[]> {
    return this.httpClient.get(environment.gateway + '/fielstatus' ).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(MedicalFileStatus).map(item)
        })
      }), catchError(this.errorHandler));
  }
  DeleteMedicalStatus( funderId: string) {
    return this.httpClient.delete(environment.gateway + '/fielstatus/' + funderId)
  }
  CreateUpdateMedicalStatus( newFunders: MedicalFileStatus[]) {
    return this.httpClient.post(environment.gateway + '/fielstatus', newFunders)
  }




  GetNursePhysicalChecks(): Observable<NursePhysicalCheck[]> {
    return this.httpClient.get(environment.gateway + '/nursephysicalchecks' ).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NursePhysicalCheck).map(item)
        })
      }), catchError(this.errorHandler));
  }


  GetNursePhysicalCheckStatus(filter:string): Observable<NursePhysicalStatus[]> {
    let filterQuery = "";
    if (filter != null)
    filterQuery = '?filter=' + filter

    return this.httpClient.get(environment.gateway + '/nursephysicalcheckstatus'+filterQuery ).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NursePhysicalStatus).map(item)
        })
      }), catchError(this.errorHandler));
  }



  CreateUpdateNursePhysicalCheckStatus(statuses: NursePhysicalStatus[]) {
    return this.httpClient.post(environment.gateway + '/nursephysicalcheckstatus', statuses)
  }



/************************* */
GetAdhesionPlaces(): Observable<AdhesionPlace[]> {
  return this.httpClient.get(environment.gateway + '/adhesionplace' ).pipe(
    map((response: any) => {
      return response.map(item => {
        return new ModelMapper(AdhesionPlace).map(item)
      })
    }), catchError(this.errorHandler));
}




CreateUpdateAdhesionPlace(statuses: AdhesionPlace[]) {
  return this.httpClient.post(environment.gateway + '/adhesionplace', statuses)
}


deleteAdhesionPlace( recordId: string) {
  return this.httpClient.delete(environment.gateway + '/adhesionplace/' + recordId)
}



GetContaminationSource(): Observable<ContaminationSource[]> {
  return this.httpClient.get(environment.gateway + '/contaminationsource' ).pipe(
    map((response: any) => {
      return response.map(item => {
        return new ModelMapper(ContaminationSource).map(item)
      })
    }), catchError(this.errorHandler));
}


CreateUpdateContaminationSource(records: ContaminationSource[]) {
  return this.httpClient.post(environment.gateway + '/contaminationsource', records)
}


deleteContaminationSource( recordId: string) {
  return this.httpClient.delete(environment.gateway + '/contaminationsource/' + recordId)
}



GetInfectionStatus(): Observable<InfectionStatus[]> {
  return this.httpClient.get(environment.gateway + '/infectionstatus' ).pipe(
    map((response: any) => {
      return response.map(item => {
        return new ModelMapper(InfectionStatus).map(item)
      })
    }), catchError(this.errorHandler));
}


CreateUpdateInfectionStatus(records: InfectionStatus[]) {
  return this.httpClient.post(environment.gateway + '/infectionstatus', records)
}


deleteInfectionStatus( recordId: string) {
  return this.httpClient.delete(environment.gateway + '/infectionstatus/' + recordId)
}



GetIsolationTypes(): Observable<IsolationType[]> {
  return this.httpClient.get(environment.gateway + '/isolationtype' ).pipe(
    map((response: any) => {
      return response.map(item => {
        return new ModelMapper(IsolationType).map(item)
      })
    }), catchError(this.errorHandler));
}


CreateUpdateIsolationTypes(records: IsolationType[]) {
  return this.httpClient.post(environment.gateway + '/isolationtype', records)
}


deleteIsolationTypes( recordId: string) {
  return this.httpClient.delete(environment.gateway + '/isolationtype/' + recordId)
}




GetResistanceTypes(): Observable<ResistanceType[]> {
  return this.httpClient.get(environment.gateway + '/resistancetype' ).pipe(
    map((response: any) => {
      return response.map(item => {
        return new ModelMapper(ResistanceType).map(item)
      })
    }), catchError(this.errorHandler));
}


CreateUpdateResistanceTypes(records: ResistanceType[]) {
  return this.httpClient.post(environment.gateway + '/resistancetype', records)
}


deleteResistanceTypes( recordId: string) {
  return this.httpClient.delete(environment.gateway + '/resistancetype/' + recordId)
}



/************************** */
GetHodgeTest(): Observable<HodgeTest[]> {
  return this.httpClient.get(environment.gateway + '/hodgetest' ).pipe(
    map((response: any) => {
      return response.map(item => {
        return new ModelMapper(HodgeTest).map(item)
      })
    }), catchError(this.errorHandler));
}


CreateUpdateHodgeTest(records: HodgeTest[]) {
  return this.httpClient.post(environment.gateway + '/hodgetest', records)
}


deleteHodgeTest( recordId: string) {
  return this.httpClient.delete(environment.gateway + '/hodgetest/' + recordId)
}


/************************** */
GetHospitalRooms(): Observable<HospitalRoom[]> {
  return this.httpClient.get(environment.gateway + '/hospitalroom' ).pipe(
    map((response: any) => {
      return response.map(item => {
        return new ModelMapper(HospitalRoom).map(item)
      })
    }), catchError(this.errorHandler));
}


CreateUpdateHospitalRooms(records: HospitalRoom[]) {
  return this.httpClient.post(environment.gateway + '/hospitalroom', records)
}


deleteHospitalRooms( recordId: string) {
  return this.httpClient.delete(environment.gateway + '/hospitalroom/' + recordId)
}
/********************************** */
GetHospitalizationTeams(): Observable<HospitalizationTeam[]> {
  return this.httpClient.get(environment.gateway + '/hospitalizationteam' ).pipe(
    map((response: any) => {
      return response.map(item => {
        return new ModelMapper(HospitalizationTeam).map(item)
      })
    }), catchError(this.errorHandler));
}


CreateUpdateHospitalizationTeams(records: HospitalizationTeam[]) {
  return this.httpClient.post(environment.gateway + '/hospitalizationteam', records)
}


deleteHospitalizationTeams(recordId: string) {
  return this.httpClient.delete(environment.gateway + '/hospitalizationteam/' + recordId)
}

/******************************** */
GetPCRTests(): Observable<PcrTest[]> {
  return this.httpClient.get(environment.gateway + '/pcrtest' ).pipe(
    map((response: any) => {
      return response.map(item => {
        return new ModelMapper(PcrTest).map(item)
      })
    }), catchError(this.errorHandler));
}


CreateUpdatePCRTests(records: PcrTest[]) {
  return this.httpClient.post(environment.gateway + '/pcrtest', records)
}


deletePCRTests(recordId: string) {
  return this.httpClient.delete(environment.gateway + '/pcrtest/' + recordId)
}
/******************************** */
  static handleError(error: any) {
    const _errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(_errMsg);
    return Observable.throw(_errMsg);
  }


  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }
}


