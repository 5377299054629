import { Component, OnInit, ViewChild } from '@angular/core';
import { MedicalFile } from '../../../../../src/app/models/customer';
import { Ambulance, Departments, Hospital } from '../../../../../src/app/models/generalhousedsetups';
import { Hospitalization } from '../../../models/hospitalization';
import { AuthService } from '../../../services/auth.service';
import { CaretxSetupsProviderService } from '../../../caretx-setups-module/setup-services/setups.data-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../../services/toastr.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { Title } from '@angular/platform-browser';
import { MedicalFileProviderService } from '../../../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';

type Items1 = {
  DepartmentName: string;
  ID: string
};



@Component({
  selector: 'app-hospitalization-details',
  templateUrl: './hospitalization-details.component.html',
  styleUrls: ['./hospitalization-details.component.scss']
})



export class HospitalizationDetailsComponent implements OnInit {
  showGeneratedPdf: boolean = false

  TabId = "main"
  isNew: boolean = false
  selectedItems2 = [];
  dropdownSettings2 = {};
  patientFilter: string = ""
  medicalDrugsFilter: string = ""
  showDepMandatoryLbl: boolean = false
  currentHospitalization: Hospitalization
  hospitals: Hospital[] = []
  Ambulances: Ambulance[] = []
  selectedMedicalFile: MedicalFile
  isrecordClosed: boolean
  recordid: string
  selectedItems1: Items1[] = [];
  dropdownSettings1 = {};
  DefaultPhotoURL: string = "../../../assets/images/user-avatar.png"
  selectedPatient: boolean
  filteredData: MedicalFile[] = [];
  renderedData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];
  totalNumOfRecords: number
  departments: Departments[] = []
  @ViewChild('mainform') mainFormElement: NgForm;


  constructor(
    private _title: Title,
    private authService: AuthService,
    private route: ActivatedRoute,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private router: Router,
    private caretxSharedService: CaretxSharedModulesProviderService,
    private caretxSetupService: CaretxSetupsProviderService,) {

    this.caretxSetupService.GetUserAllowedDepartments(this.authService.LoggedInUser.UID).subscribe(data => {
      this.departments = data
      this.selectedItems1 = JSON.parse(localStorage.getItem('user_selected_departments') || '{}');
      if (this.selectedItems1.length == 0) {
        if (this.departments.length > 0) {
          this.departments.forEach(element => {
            this.selectedItems1.push({ "DepartmentName": element.DepartmentName, "ID": element.ID })
          });
        } else {
        }
      }
    })
    this.caretxSetupService.GetHospitals(this.authService.LoggedInTenantID).subscribe((data => {
      this.hospitals = data
    }))
    this.caretxSetupService.getAmbulanceProviders().subscribe((data => {
      this.Ambulances = data
    }))
    this.selectedMedicalFile = new MedicalFile()
    this.selectedMedicalFile.FirstName = "עדיין לא נבחר"
    this.selectedMedicalFile.LastName = "."
    this.selectedMedicalFile.PersonelID = "-"
    this.selectedMedicalFile.ImagePath = "../../assets/images/user-avatar.png"
    this.selectedMedicalFile.Department = new Departments()
    this.selectedMedicalFile.Department.DepartmentName = "עדיין לא נבחר"
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex | טופס תיאום טיפול');
    if (this.route.parent != null)
      this.recordid = this.route.snapshot.params.recordid
    if (this.recordid == 'new') {
      this.getHospitalizationOrderById(this.route.snapshot.params.recordid, "new")
    } else {
      this.getHospitalizationOrderById(this.route.snapshot.params.recordid, "details")
    }
    this.loadData2("")
    this.doInitialization()
  }


  doInitialization() {
    // Get LabCategories :
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: "ID",
      textField: "DepartmentName",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }

  loadData2(filter: string) {
    this.medicalFileService.getMedicalFiles(filter);
    this.medicalFileService.dataChange.subscribe((data) => {
      this.filteredData = this.allFiles = data
      this.filteredData.forEach(element => {
        element.FullName = element.FirstName + " " + element.LastName
      });
      this.totalNumOfRecords = this.allFiles.length
    })
  }

  getHospitalizationOrderById(recordId: string, mode: string) {
    this.caretxSharedService.getHospitalizationOrders(recordId, mode).subscribe(data => {
      if (data.length == 1) {
        this.currentHospitalization = new Hospitalization(data[0])
        this.selectedMedicalFile = this.currentHospitalization.PersonDoc
        this.isrecordClosed = (this.currentHospitalization.Status == "C")
        if (recordId == "new") {
          this.isNew = true
          this.currentHospitalization.HospitalId = undefined
          this.currentHospitalization.AmbulanceId = undefined
          this.currentHospitalization.DepartureDate = undefined
          this.currentHospitalization.DepartureTime = undefined
          this.currentHospitalization.ReturnDate = undefined
          this.currentHospitalization.ReturnTime = undefined
          this.currentHospitalization.TransportMethod = undefined

        } else {
          this.isNew = false
        }

        let diffInDays = this.differenceInDays(new Date(), this.currentHospitalization.DepartureDate)
        if (diffInDays <= 0) {
          this.isrecordClosed = true
        }


      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }


  closeSideNavePatient(medicalFile: MedicalFile) {
    this.selectedMedicalFile = medicalFile
    this.selectedPatient = false

  }


  updateTimeField(fieldName, $event) {
    let tmpDate: Date = new Date()
    if (this.currentHospitalization.DepartureDate != undefined)
      tmpDate = new Date(this.currentHospitalization.DepartureDate)

    let yy: number = tmpDate.getFullYear()
    let mm: number = tmpDate.getMonth()
    let dd: number = tmpDate.getDate()

    let Hours: number = Number($event.split(':')[0])
    let Minutes: number = Number($event.split(':')[1])
    let time: Date = new Date(yy, mm, dd, Hours, Minutes, 0)
    if (fieldName = 'DepartureTime')
      this.currentHospitalization.DepartureTime = time
    else if (fieldName = 'ReturnTime')
      this.currentHospitalization.ReturnTime = time
  }

  submitForm() {
    this.mainFormElement.onSubmit(new Event("", undefined));
  }

  Save() {
    // Check if user has selected a patient 
    if (this.selectedMedicalFile.PersonelID == "") {
      Swal.fire({
        title: "הודעת מערכת",
        text: "צריך לבחור דייר !",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'סגור',
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
         
        }
      })
    }else{
      this.currentHospitalization.TenantID = this.authService.LoggedInTenantID
      this.currentHospitalization.PersonelID = this.selectedMedicalFile.PersonelID
      this.currentHospitalization.DepartureDate = new Date(this.currentHospitalization.DepartureDate)
      this.currentHospitalization.DepartureTime = new Date(this.currentHospitalization.DepartureTime)
      this.currentHospitalization.ReturnDate = new Date(this.currentHospitalization.ReturnDate)
      this.currentHospitalization.ReturnTime = new Date(this.currentHospitalization.ReturnTime)

      if (this.currentHospitalization.ID == "" || this.currentHospitalization.ID == null || this.currentHospitalization.ID == "00000000-0000-0000-0000-000000000000") {
        this.DoSave()
      }
      else {
        this.DoUpdate()
      }
    }
  }



  DoUpdate() {

    this.caretxSharedService.updateHospitalizationOrder(this.currentHospitalization).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentHospitalization = new Hospitalization(data)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        if (this.currentHospitalization.Status == "C") {
          this.router.navigateByUrl("hospitalizations/" + this.currentHospitalization.ID + "/locked") // to be changed to locked later
        } else {
          this.router.navigateByUrl("hospitalizations/" + this.currentHospitalization.ID)
        }

      },
      error: error => {
        this.toastrService.Error("", error.error.Message)
      }
    })
  }

  DoSave() {

    this.caretxSharedService.createHospitalizationOrder(this.currentHospitalization).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentHospitalization = new Hospitalization(data)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }

        this.router.onSameUrlNavigation = 'reload';
        if (this.currentHospitalization.Status == "C") {
          this.router.navigateByUrl("hospitalizations/" + this.currentHospitalization.ID + "/locked")
        } else {
          this.router.navigateByUrl("hospitalizations/" + this.currentHospitalization.ID)
        }
      },
      error: error => {
        this.toastrService.Error("", error.error.Message)
      }
    })
  }


  BackToList() {
    this.router.navigate(['hospitalizations']);
  }

  OpenSideNav() {
    if (this.isNew)
      this.selectedPatient = true
  }

  printReport() {

  }

  closePdfDocument() {

  }

  OpenOperationPage() {

  }


  onDepartmentSelect($event) {
    this.filteredData = []
    this.showDepMandatoryLbl = false
    this.prepareFilterToSearchMedicalFiles()
  }

  onItemDeSelect($event) {
    this.filteredData = []
    if (this.selectedItems1.length == 0) {
      this.filteredData = []
      this.showDepMandatoryLbl = true
    }
    this.prepareFilterToSearchMedicalFiles()
  }

  onSelectingAllDepartments(items: any) {
    this.selectedItems1 = items
    this.showDepMandatoryLbl = false
    this.filteredData = this.allFiles
    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));
  }

  onDeSelectAll(items: any) {
    this.selectedItems1 = []
    this.filteredData = []
    this.showDepMandatoryLbl = true
    this.prepareFilterToSearchMedicalFiles()
  }
  prepareFilterToSearchMedicalFiles() {
    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));

    if (this.selectedItems1.length > 0) {
      this.allFiles.forEach(file => {
        const found = this.selectedItems1.some(x => x.ID === file.DepartmentId)
        if (found) this.filteredData.push(file)

      });
    }

  }



  differenceInDays(date1: Date, date2: Date): number {
    const oneDay = 24 * 60 * 60 * 1000;
    const diffInTime = (new Date(date2)).getTime() - (new Date(date1)).getTime();
    return Math.round(diffInTime / oneDay);
  }



}
