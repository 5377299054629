import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { AuthService } from './auth.service';
import { Observable } from "rxjs";

@Injectable()
export class CaretexInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.auth.LoggedInUser == null)
    {
      return next.handle(request)
    }
    let uid = this.auth.LoggedInUser['UID']
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${uid}`,
        "tenantID":  `${this.auth.LoggedInTenantID}`,
        "userid": `${this.auth.LoggedInUser.UID}`,

        
      }
       

    });
    
    return next.handle(request);
  }
}