import { MedicalDiagnoses } from './drug'
import { DiagnosesTypes } from './generalhousedsetups'
import { propertyMap } from './modelmapper'
import { User } from './user'

export class Diagnoses {

  @propertyMap('ID')
  ID:string
 // @propertyMap('AdmissionId')
 // AdmissionId:number
  @propertyMap('PersonelID')
  PersonelID:string
  @propertyMap('RealmID')
  RealmID:string
  @propertyMap('CreatedAt')
  CreatedAt: Date
  @propertyMap('UpdatedAt')
  UpdatedAt: Date
  @propertyMap('Type')
  Type:string
  @propertyMap('DoctorId')
  public DoctorId:string
  @propertyMap('Doctor')
  public Doctor:User
  @propertyMap('MedicalDiagnosesCode')
  MedicalDiagnosesCode:string
  @propertyMap('MedicalDiagnoses')
  public MedicalDiagnoses:MedicalDiagnoses
  @propertyMap('TypeCode')
  public TypeCode:string
  @propertyMap('DiagnosesType')
  public DiagnosesType:DiagnosesTypes
  @propertyMap('Modified')
  public Modified:boolean
  @propertyMap('New')
  public New:boolean
  @propertyMap('Status')
  public Status:string

  clientId:string
  isNew:boolean
  isLocked:boolean
  editMode:boolean
  @propertyMap('LinkedTo')
  LinkedTo:string
  @propertyMap('CreatedFrom')
  public CreatedFrom:string
  @propertyMap('IsLocked')
  public IsLocked:boolean
  
    constructor() {
      this.clientId =  Math.floor(Math.random() * 16).toString(16).toUpperCase();
      this.CreatedAt =  new Date()
      this.UpdatedAt = new Date()
      this.isNew = false;
      this.isLocked = false;
      this.editMode = false;
  }

}