import { Component, Inject, OnInit } from '@angular/core';
import { DieticianFollowUp } from '../../../../../src/app/models/DieticianFollowup';
import {
  MAT_DIALOG_DATA,

  MatDialogRef,

} from '@angular/material/dialog';

@Component({
  selector: 'app-dietician-dialog-followupsubject',
  templateUrl: './dietician-dialog-followupsubject.component.html',
  styleUrls: ['./dietician-dialog-followupsubject.component.css'],


})
export class DieticianDialogFollowupsubjectComponent implements OnInit {

  id: string
  isNew: boolean
  isDisabled: boolean
  dieticianFollowUp: DieticianFollowUp
  LocalCreatedAt: Date

  constructor(
    private dialogRef: MatDialogRef<DieticianDialogFollowupsubjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {

    this.id = this.data.personelid;
    this.dieticianFollowUp = this.data.dieticianFollowUp
    this.isDisabled = this.data.IsDisabled
    this.dieticianFollowUp.CreatedAt = new Date()
    this.LocalCreatedAt = new Date()
    this.dieticianFollowUp.FollowUpSubject = ""
  }

  updateTimeField($event) {
    var newarr = $event.split(":");

    let x1: number
    let x2: number

    x1 = new Date(this.dieticianFollowUp.CreatedAt).setHours(newarr[0])
    this.dieticianFollowUp.CreatedAt = new Date(x1)
    x2 = new Date(this.dieticianFollowUp.CreatedAt).setMinutes(newarr[1])
    this.dieticianFollowUp.CreatedAt = new Date(x2)

  }

  submit() {

    this.dialogRef.close(this.dieticianFollowUp);
  }
}
