

import { MedicalFile } from './customer'
import { Ambulance, Hospital } from './generalhousedsetups'
import { propertyMap } from './modelmapper'
import { User } from './user'




export class Hospitalization {
    @propertyMap('ID')
    public ID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('PersonDoc')
    public PersonDoc: MedicalFile
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('Status')
    public Status: string
    @propertyMap('DepartureDate')
    public DepartureDate: Date
    @propertyMap('DepartureTime')
    public DepartureTime: Date
    @propertyMap('ReturnDate')
    public ReturnDate: Date
    @propertyMap('ReturnTime')
    public ReturnTime: Date
    @propertyMap('Companion')
    public Companion: string
    @propertyMap('CompanionPhone')
    public CompanionPhone: string
    @propertyMap('TransportMethod')
    public TransportMethod: string
    @propertyMap('AmbulanceId')
    public AmbulanceId: string
    @propertyMap('Ambulance')
    public Ambulance: Ambulance
    @propertyMap('HospitalId')
    public HospitalId: string
    @propertyMap('Hospital')
    public Hospital: Hospital
    @propertyMap('ClosedById')
    public ClosedById: string
    @propertyMap('ClosedBy')
    public ClosedBy: User
    @propertyMap('ClosedAt')
    public ClosedAt: Date


   @propertyMap('DateBackSet')
    public DateBackSet: Date
    @propertyMap('AmbulanceContactPhone1')
    public AmbulanceContactPhone1: string

    


    constructor(data?: any) {
        if (data != null) {
            this.ID = data.ID
            this.DateBackSet = data.DateBackSet
            this.PersonelID = data.PersonelID
            this.CreatedAt = data.CreatedAt
            this.UpdatedAt = data.UpdatedAt
            this.Status = data.Status
            this.TenantID = data.TenantID
            this.ClosedById = data.ClosedById
            this.ClosedAt = data.ClosedAt
            this.ClosedBy = data.ClosedBy
            this.AmbulanceId = data.AmbulanceId
            this.Ambulance = data.Ambulance
            this.TransportMethod = data.TransportMethod
            this.Companion = data.Companion
            this.CompanionPhone = data.CompanionPhone
            this.ReturnDate = data.ReturnDate
            this.DepartureDate = data.DepartureDate
            this.HospitalId = data.HospitalId
            this.Hospital = data.Hospital
            this.AmbulanceContactPhone1 = data.AmbulanceContactPhone1
            this.ReturnTime = data.ReturnTime
            this.DepartureTime = data.DepartureTime
            this.PersonDoc = data.PersonDoc

        }
        else {
            this.Status = "A"
        }
    }



}