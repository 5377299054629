import { Component, OnInit } from '@angular/core';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';

@Component({
  selector: 'app-nursedailyfoodreportlist',
  templateUrl: './nursedailyfoodreportlist.component.html',
  styleUrls: ['./nursedailyfoodreportlist.component.css']
})
export class NursedailyfoodreportlistComponent extends CaretexComponent implements OnInit {

  constructor(public medicalFileServive: MedicalFileProviderService,) { super(medicalFileServive)}

  ngOnInit(): void {
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }


}
