import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxMedicalProviderService } from '../../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { CaretxSetupsProviderService } from '../../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { CaretxSharedModulesProviderService } from '../../../../../../src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicationReviewHeader } from '../../../../../../src/app/models/MedicationsReview';
import { MedicalFile } from '../../../../../../src/app/models/customer';
import { DrugInstruction } from '../../../../../../src/app/models/drugInstruction';
import { Departments } from '../../../../../../src/app/models/generalhousedsetups';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretexTextareaDialogComponent } from 'src/app/caretx-shared-module/CaretexModals/caretex-textarea-dialog/caretex-textarea-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-patients-medication-review-detail',
  templateUrl: './patients-medication-review-detail.component.html',
  styleUrls: ['./patients-medication-review-detail.component.css']
})
export class PatientsMedicationReviewDetailComponent extends CaretexComponent implements OnInit {

  filteredData: MedicalFile[] = [];
  renderedData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];
  totalNumOfRecords: number
  departments: Departments[] = []
  selectedItems1 = [];
  formData: any[]
  DrugInstructionList: DrugInstruction[] = []
  CurrentMedicationReview: MedicationReviewHeader
  selectedPatientID: string
  personalId: string
  reviewId: string
  title1: string = "מעקב תרופות עבור מטופל:"
  title: string = ""
  TabId = "main"
  constructor(
    public medicalFileServive: MedicalFileProviderService,
    private _title: Title,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private toastrService: ToasterService,
    private authService: AuthService,
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private router: Router,
  ) { super(medicalFileServive) }


  ngOnInit(): void {
    this._title.setTitle('Caretx| בקרת תרופות');
    if (this.route.parent != null)
      this.personalId = this.route.snapshot.params['id']
    this.reviewId = this.route.snapshot.params.reviewid
    this.CheckIfComponentIsDisabled(this.personalId)

    if (this.reviewId == 'new') {
      this.CreatePatientReview()
    } else {
      this.GetPatientReview("")
    }
  }

  CreatePatientReview() {
    this.caretxSharedlService.getPatientMedicationReview("", this.personalId, "new").subscribe(
      data => {
        this.CurrentMedicationReview = new MedicationReviewHeader(data[0])
        this.CurrentMedicationReview.Reviewer = this.authService.LoggedInUser
        this.CurrentMedicationReview.ReviewerID = this.authService.LoggedInUser.UID
        this.title = "מעקב תרופות עבור מטופל: " + this.CurrentMedicationReview.PersonDoc.FirstName + " " + this.CurrentMedicationReview.PersonDoc.LastName
      },

      err => {
        this.toastrService.Error("", "יצירת רשימה חדשה נכשלה !")
      }
    )
  }

  GetPatientReview(mode: string) {
    let filter: string = '(id=' + '\'' + this.reviewId + '\'' + ')'
    this.caretxSharedlService.getPatientMedicationReview(filter, this.personalId, mode).subscribe(
      data => {
        this.totalNumOfRecords = data.length
        if (this.totalNumOfRecords == 1) {
          this.CurrentMedicationReview = new MedicationReviewHeader(data[0])
          this.title = "מעקב תרופות עבור מטופל: " + this.CurrentMedicationReview.PersonDoc.FirstName + " " + this.CurrentMedicationReview.PersonDoc.LastName
        } else {
          // what to do when retrieved more than 1
        }
      },
      err => {
        this.DrugInstructionList = []
      }
    )
  }

  BackToList() {
    this.router.navigateByUrl(this.personalId + '/pmreviews')
  }

  CheckIfDocumentIsDirty() {
    return false
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  LockRecord() {
    this.CurrentMedicationReview.Status = "C"
    this.CurrentMedicationReview.ClosedAt = new Date()
    this.CurrentMedicationReview.ClosedById = this.authService.LoggedInUser.UID

    this.caretxSharedlService.updatePatientMedicationReview(this.personalId, this.CurrentMedicationReview).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'ignore'; // Change from 'reload' to 'igore' to avoid page refresh on save

        if (this.CurrentMedicationReview.Status == "C") {
          this.router.navigateByUrl(+ this.personalId + "/pmreviewxref/" + this.CurrentMedicationReview.ID)
        } else {
          this.router.navigateByUrl(+ this.personalId + "/pmreviewxref/" + this.CurrentMedicationReview.ID)
        }

      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })



  }

  SaveReview() {
    this.caretxSharedlService.createPatientMedicationReview(this.personalId, this.CurrentMedicationReview).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.CurrentMedicationReview = new MedicationReviewHeader(data)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'ignore';

        if (this.CurrentMedicationReview.Status == "C") {
          this.router.navigateByUrl(+ this.personalId + "/pmreviewxref/" + this.CurrentMedicationReview.ID)
        } else {
          this.router.navigateByUrl(+ this.personalId + "/pmreviewxref/" + this.CurrentMedicationReview.ID)
        }


      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  StopInstruction(index) {

    if (this.reviewId == 'new') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "צריך לשמור את המסך קודם כל!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'שמור מסמך',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'אל תשמור'
      }).then((result) => {
        if (result.isConfirmed) {
          this.SaveReview()
        }
      })
    } else {

      // Show Dialog
      this.ShowUserTextDialog(index)


    }
  }


  ShowUserTextDialog(instrIndex:number) {
    // Need to be changed 
    const dialogRef = this.dialog.open(CaretexTextareaDialogComponent, {
      data: {
        Disabled: this.CurrentMedicationReview.Status == 'C' || this.CurrentMedicationReview.ReviewDetails[instrIndex].IsClosed,
        UserText: this.CurrentMedicationReview.ReviewDetails[instrIndex].ReasonForChange,
        Title:"סיבת שינוי סטטוס הוראה",
        TextFieldName:"סיבת שינויי סטטוס"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!this.CurrentMedicationReview.ReviewDetails[instrIndex].IsClosed && this.CurrentMedicationReview.Status != 'C'){
        this.CurrentMedicationReview.ReviewDetails[instrIndex].ReasonForChange = result
        this.CurrentMedicationReview.ReviewDetails[instrIndex].DrugInstruction.instrStatus = "S"
        this.CurrentMedicationReview.ReviewDetails[instrIndex].ChangedToStatus = "S"
        this.CurrentMedicationReview.ReviewDetails[instrIndex].PreviousStatus = "A"
        this.CurrentMedicationReview.ReviewDetails[instrIndex].ReviewerID = this.authService.LoggedInUser.UID
        this.CurrentMedicationReview.ReviewDetails[instrIndex].IsClosed = true
        this.CurrentMedicationReview.ReviewDetails[instrIndex].Modified = true
        this.caretxSharedlService.updatePatientMedicationReview(this.personalId, this.CurrentMedicationReview).subscribe({
          next: data => {
            this.toastrService.Sucesss("", this.toastrService.successMsg)
            this.GetPatientReview("")
  
          },
          error: error => {
            this.toastrService.Error("", error.error)
          }
        })
      }
    });
  }




}
