import { Injectable, NgZone } from "@angular/core";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { NotificationMap } from '../models/notificationMap';

@Injectable({
  providedIn: "root"
})
export class NotificationsSSEService {


  dataChange: BehaviorSubject<NotificationMap[]> = new BehaviorSubject<NotificationMap[]>([]);

  constructor(private _zone: NgZone,private httpClient: HttpClient) {}
  getServerSentEvent(url: string): Observable<any> {
    return new Observable(observer => {
      const eventSource = this.getEventSource(url);
      eventSource.onmessage = event => {
        this._zone.run(() => {
          observer.next(event);
        });
      };
      eventSource.onerror = error => {
        this._zone.run(() => {
          observer.error(error);
        });
      };
    });
  }
   private getEventSource(url: string): EventSource {
    return new EventSource(url);
  }

  get data(): NotificationMap[] {
    return this.dataChange.value;
  }

  
  getNotifications(userID: string, role: string,filter:string) {

    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/notifications/' + userID + '/role/' + role + filterQuery);
  }

 AddNewNotification(notification: NotificationMap) {
    return this.httpClient.post(environment.gateway + '/notification',notification);
  }
  

  updateNotification(notification: NotificationMap) {
    return this.httpClient.put(environment.gateway + '/notification', notification)
  }




  /****************************************************************************************** */
  /** Error Handling method */

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }
}
