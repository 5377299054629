import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CaretxNursingProviderService } from 'src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { NurseFollowUp } from 'src/app/models/nurseadmission';
import { PagenationObject } from 'src/app/models/pagenation';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-historicfollowups',
  templateUrl: './historicfollowups.component.html',
  styleUrls: ['./historicfollowups.component.css']
})
export class HistoricfollowupsComponent implements OnInit {

  @Input() HistoricRecords: NurseFollowUp[] 
  @Input() Pagenation: PagenationObject
  @Output() PageNumSelectionEvent = new EventEmitter<string>();
  @Output() refreshHistoryRecords = new EventEmitter<void>();


  public editorDisabled = true;
  originTxt:string
  public config: any = {
    airMode: false,
    tabDisable: true,
    height: '200px',
    toolbar: [
      ['misc', [ 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']]
        ],
      fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']

  };


    /*Pagination*//////////////////
    currentPage: number = 1
    pages: number[]
    page1: number = 1
    page2: number = 2
    page3: number = 3
    selectedPage: number = -1
    pageSize: number = 5
    pageCount: number = 1
  
    ///////////////////////////////

  constructor(
    private caretxNursingProviderService: CaretxNursingProviderService,
    private authServive: AuthService,
    private toastrService: ToasterService,) {
  }
  ngOnInit(): void {
    this.pageCount = Math.ceil(this.Pagenation.TotalNumOfPages / this.pageSize)
    if (this.pageCount == 0) {
      this.pageCount = 1
    }
    this.pages = Array.from({ length: this.pageCount }, (_, i) => i + 1)
    this.selectedPage = 1
  }

  onReady2(editor2) {
    editor2.ui.getEditableElement().parentElement.insertBefore(
      editor2.ui.view.toolbar.element,
      editor2.ui.getEditableElement()
    );
    editor2.isReadOnly = true
  }

  SelectPage($event) {
    this.selectedPage = $event
    this.PageNumSelectionEvent.emit(this.selectedPage.toString())
  }

  PrevPage() {
      if (this.selectedPage == this.pages[0]) {

        if (this.pages[0] - 1 >= 1) {
          for (let i = 0; i < this.pages.length; i++) {
            this.pages[i] -=1
           }
           this.selectedPage = this.pages[0]
        }
      } else if(this.selectedPage > this.pages[0]) {
        this.selectedPage -= 1
      }
   
    this.PageNumSelectionEvent.emit(this.selectedPage.toString())
  }

  NextPage() {
    if (this.pageCount > 3) {
      if (this.selectedPage == this.pages[2]) {

        if (this.pages[2] + 1 <= this.pageCount) {
          for (let i = 0; i < this.pages.length; i++) {
            this.pages[i] +=1
           }
           this.selectedPage = this.pages[2]
        }
      } else if(this.selectedPage < this.pages[2]) {
        this.selectedPage += 1
      }
    }else{
      if (this.selectedPage < this.pageCount){
        this.selectedPage += 1
      }
    }
    this.PageNumSelectionEvent.emit(this.selectedPage.toString())
  }

  OpenTxtForEditAndSave(oldReport:NurseFollowUp) {
    // only teh owner of the track can allow update

    if (oldReport.LastUpdateById == this.authServive.LoggedInUser.UID) {
      oldReport.Disabled = true
      oldReport.OpenedForEdit = true
      this.originTxt = oldReport.RichText
     }else {
      Swal.fire({
        title: "הודעת מערכת",
        text: "אין לך הרשאה לפתוח רשומה לעדכון",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
     }
    
  }

  CancelUpdate(oldReport){
    oldReport.RichText= this.originTxt 
    oldReport.OpenedForEdit = false
    oldReport.Disabled = false
  }

  UpdateRecord(oldReport){
    
    this.caretxNursingProviderService.updateNurseFollowUp(oldReport).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        oldReport.OpenedForEdit = false
        this.refreshHistoryRecords.emit()

      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })


  }

}
