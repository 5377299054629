import { Attachment } from './attachments'
import { BedSoreLocation, BedSoreSide, BedSoreStatus, BedSoreType, Departments } from './generalhousedsetups'
import { propertyMap } from './modelmapper'
import { User } from './user'

export class BedSore {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('BedSoreTypeId')
    public BedSoreTypeId:string
    public BedSoreType:BedSoreType
    @propertyMap('BedSoreLocationId')
    public BedSoreLocationId:string
    public BedSoreLocation:BedSoreLocation
    @propertyMap('BedSoreSideId')
    public BedSoreSideId:string
    public BedSoreSide:BedSoreSide
    @propertyMap('BedSoreDate')
    public BedSoreDate:Date 
    @propertyMap('BedSoreDetailedLocation')
    public BedSoreDetailedLocation:string
    @propertyMap('BedSoreStatusId')
    public BedSoreStatusId:string
    public BedSoreStatus:BedSoreStatus
    @propertyMap('BedSoreComment')
    public BedSoreComment:string
    @propertyMap('Status')
    public Status:string
    @propertyMap('LockedById')
    public LockedById:string
    @propertyMap('LockedBy')
    public LockedBy:User
    @propertyMap('LockedDate')
    public LockedDate:Date
    public LastUpdatedById:string
    @propertyMap('LastUpdatedById')
    public LastUpdatedBy:User
    @propertyMap('BedSoreTracks')
    public BedSoreTracks :BedSoreTracks[]
    @propertyMap('BedSoreHelpItems')
    public BedSoreHelpItems :BedSoreHelpItem[]
    @propertyMap('BedSoreTreatments')
    public BedSoreTreatments :BedSoreTreatment[]
    @propertyMap('BedSoreImages')
    public BedSoreImages :Attachment[]
    public isNew :boolean = false
    public editMode :boolean = false

    @propertyMap('FreeTxtSide')
    public FreeTxtSide:string
    @propertyMap('IsFreeTxtSide')
    public IsFreeTxtSide:boolean
    



    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.BedSoreTypeId = data["BedSoreTypeId"]
            this.BedSoreSideId = data["BedSoreSideId"]
            this.BedSoreLocationId = data["BedSoreLocationId"]
            this.BedSoreDate = data["BedSoreDate"]
            this.BedSoreDetailedLocation = data["BedSoreDetailedLocation"]
            this.BedSoreStatusId = data["BedSoreStatusId"]
            this.BedSoreComment = data["BedSoreComment"]
            this.BedSoreTracks = data["BedSoreTracks"]
            this.BedSoreImages = data["BedSoreImages"]
            this.BedSoreTreatments = data["BedSoreTreatments"]
            this.BedSoreHelpItems = data["BedSoreHelpItems"]
            this.Status = data["Status"]
            this.LockedById = data["LockedById"]
            this.LockedDate = data["LockedDate"]
            this.BedSoreType = data["BedSoreType"]
            this.BedSoreLocation = data["BedSoreLocation"]
            this.BedSoreSide = data["BedSoreSide"]
            this.FreeTxtSide = data["FreeTxtSide"]
            this.IsFreeTxtSide = data["IsFreeTxtSide"]
        }
        else {
            this.PersonelID = ""
            this.TenantID = ""
            this.CreatedAt = new Date()
            this.UpdatedAt = new Date()
            this.BedSoreTypeId = ""
            this.BedSoreSideId = ""
            this.BedSoreDate = new Date()
            this.BedSoreDetailedLocation = ""
            this.BedSoreLocationId = ""
            this.BedSoreSideId = ""
            this.BedSoreTracks = []
            this.BedSoreImages =[]
            this.BedSoreTreatments =[]
            this.BedSoreHelpItems=[]
            this.BedSoreComment = ""
            this.Status = ""
            this.LockedById = ""
            this.FreeTxtSide = ""
            this.IsFreeTxtSide = false
           
        }
    }


}

export class BedSoreTracks {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('BedSoreID')
    public BedSoreID:string
    @propertyMap('BedSoreTrackTxt')
    public BedSoreTrackTxt:string
    @propertyMap('Status')
    public Status:string
    @propertyMap('TrackedById')
    public TrackedById:string
    @propertyMap('TrackedBy')
    public TrackedBy:User
    @propertyMap('TrackedDate')
    public TrackedDate:Date
    public isNew :boolean = false
    public editMode :boolean = false

    public Disabled:boolean = true
    public OpenedForEdit:boolean = false
}

export class BedSoreHelpItem {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('ItemName')
    public ItemName: string
    @propertyMap('Usage')
    public Usage: string
    @propertyMap('LastUpdatedById')
    public LastUpdatedById: string
    @propertyMap('LastUpdatedBy')
    public LastUpdatedBy: User
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    editMode:boolean

    constructor(data?: Object) {

        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAtD"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.ItemName = data["ItemName"]
            this.Usage = data["Usage"]
            this.LastUpdatedById = data["LastUpdatedById"]
            this.LastUpdatedBy = data["LastUpdatedBy"]
        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
          
        }
    }



}

export class BedSoreTreatment {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('TreatmentTxt')
    public TreatmentTxt: string
    @propertyMap('Status')
    public Status: string
    @propertyMap('LastUpdatedById')
    public LastUpdatedById: string
    @propertyMap('LastUpdatedBy')
    public LastUpdatedBy: User


    constructor(data?: Object) {

        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAtD"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.TreatmentTxt = data["TreatmentTxt"]
            this.Status = data["Status"]
            this.LastUpdatedById = data["LastUpdatedById"]
            this.LastUpdatedBy = data["LastUpdatedBy"]
        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
        }
    }



}

