import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ModelMapper } from '../models/modelmapper';
import { MedicalFile } from '../models/customer';
import { BehaviorSubject } from 'rxjs';
import { DrugInstruction, InstructionStatusObj } from '../models/drugInstruction';
import { MedicalAdmission } from '../models/medicaladmission';
import { DrugSensitivity } from '../models/drugsenstivity';
import { OtherSensitivity } from '../models/othersensitivity';
import { TeekNote } from '../models/Teeknote';
import { PersonelDocument } from '../models/personeldocument';
import { MedicalRelease } from '../models/MedicalRelease';

import { AuthService } from '../services/auth.service';



@Injectable()
export class CustomerService {

  public currentSelectedFile: MedicalFile
  dataChange: BehaviorSubject<MedicalFile[]> = new BehaviorSubject<MedicalFile[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  openedFile: BehaviorSubject<MedicalFile> = new BehaviorSubject<MedicalFile>(new MedicalFile());

  constructor(private httpClient: HttpClient,
    private authService: AuthService) {


  }




  /************************* */

  getAttachments(id: string): Observable<PersonelDocument[]> {
    return this.httpClient.get(environment.gateway + '/attachments/' + id).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PersonelDocument).map(item)
        })
      }), catchError(this.errorHandler));
  }

  uploadProfilePic(fileToUpload) {
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.httpClient.post(environment.gateway + "/profilepic", formData)

  }

  uploadFile(fileToUpload, metadata) {
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    formData.append('filecategory', metadata.FileCategory);
    formData.append('filedescription', metadata.Description);
    return this.httpClient.post(environment.gateway + "/attachments/" + metadata.PersonelID + "/upload", formData)

  }

  getPdfDocument(metadata): Observable<any> {



    /* const headers = { 
       'Accept-Language': '*',
       'filename': metadata.FileName}*/

    return this.httpClient
      .get(environment.gateway + "/attachments/" + metadata.PersonelID + "/download/" + metadata.AttachmentId, {
        //  headers,
        responseType: "arraybuffer" //tried with 'blob'
      });
  }

  /************************************************************************* */

  /** CRUD METHODS */



  /*************************************************************************************** */

  /*
  getDrugInstrs(id: string, filter: string): Observable<DrugInstruction[]> {
    return this.httpClient.get(environment.gateway + '/clients/' + id + '/drugsinstructions/' + filter).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DrugInstruction).map(item)
        })
      }), catchError(this.errorHandler));
  }

*/

  getOtherSensitivity(id: string): Observable<OtherSensitivity> {
    return this.httpClient.get(environment.gateway + '/clients/' + id + '/sensitivities').pipe(
      map((response: any) => {

        return new ModelMapper(DrugSensitivity).map(response)

      }), catchError(this.errorHandler));
  }

/*
  getDrugInstrById(instrId: string, teekId: string): Observable<DrugInstruction> {
    return this.httpClient.get(environment.gateway + '/clients/' + teekId + '/druginstr/' + instrId).pipe(
      map((response: any) => {
        return new ModelMapper(DrugInstruction).map(response)

      }), catchError(this.errorHandler));
  }
*/






 
  createOtherSesitivity(OtherSensitivity: OtherSensitivity) {
    return this.httpClient.post(environment.gateway + '/clients/' + OtherSensitivity.PersonelID + '/sensitivities', OtherSensitivity)
  }



 
  /*
  updateDrugStatus(instrId: string, teekId: string, status: string, comment: string): Observable<DrugInstruction> {
    let statusObj = new InstructionStatusObj()
    statusObj.Status = status
    statusObj.Comment = comment

    return this.httpClient.put(environment.gateway + '/clients/' + teekId + '/updatedruginstrstatus/' + instrId, statusObj).pipe(
      map((response: any) => {
        return new ModelMapper(DrugInstruction).map(response)

      }), catchError(this.errorHandler));
  }*/

  updateOtherSensitivity(otherSensitivity: OtherSensitivity): Observable<OtherSensitivity> {
    return this.httpClient.put(environment.gateway + '/clients/' + otherSensitivity.PersonelID + '/sensitivities', otherSensitivity).pipe(
      map((response: any) => {
        return new ModelMapper(OtherSensitivity).map(response)

      }), catchError(this.errorHandler));
  }

  /**Physical Examinations */
 


  newMedicalFile(medicalFile: MedicalFile) {
    return this.httpClient.post(environment.gateway + '/addMedicalFile/' + this.authService.LoggedInUser["UID"], medicalFile)
  }

  /**********************Physical Examinations **************************************/

 

  /**********************************Medical Observation******************************** */
  


  /***********************************PersonalMeasures************************************* */


  /**************************************************************************************** */

/*************************************Medical Release************************************** */

/***************************************Medical Diagnose*********************************** */


/****************************************************************************************** */
  /** Error Handling method */

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }




  getCustomer(id: string) {
    return this.httpClient.get(environment.gateway + '/clients/' + id);
  }

  getTeekDrugs(id: string) {
    return this.httpClient.get(environment.gateway + '/teek/' + id + '/drugsinstructions');
  }

  getTeekNotesById(id: string) {
    return this.httpClient.get(environment.gateway + '/filenotes/' + id );
  }

  getUserNotesById(id: string) {
    return this.httpClient.get(environment.gateway + '/usernotes/' + id );
  }
  deleteTeekNoteById(note: TeekNote) {
    return this.httpClient.put(environment.gateway + '/clients/' + note.PersonelID + '/teeknote/' + note.NoteId, note);
  }


  addTeekNotesById(note: TeekNote) {
    return this.httpClient.post(environment.gateway + '/filenotes/' + note.PersonelID , note);
  }





  /************ Medical Admissions ***/
  getMedicalAdmisions(id: string, filter: string): Observable<MedicalAdmission[]> {
    return this.httpClient.get(environment.gateway + '/clients/' + id + '/medicaladmissions/' + filter).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DrugInstruction).map(item)
        })
      }), catchError(this.errorHandler));
  }

  /********************************* */


}



