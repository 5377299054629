import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DrugsService } from 'src/app/services/drugs.service';
import { Drug } from 'src/app/models/drug';
import { ModalService } from 'src/app/modal/modal.service';


@Component({
  selector: 'app-drugslookup',
  templateUrl: './drugslookup.component.html',
  styleUrls: ['./drugslookup.component.css']
})
export class DrugslookupComponent implements OnInit {

  drugId:string
  engDrugName:string
  heBDrugName:string
  drugIndecation:string
  indecationFound:boolean
  hideModal:boolean = true;


  @Input() drugIDForSearch: string
  @Input() drugNameForSearch: string
  @Input()  DrugModalId:string

  @Output() drugEvent =  new EventEmitter<Drug>();



  Drugs: Drug[] = []
  FilteredDrugs: Drug[] = []
  constructor(private drugsService: DrugsService,
    private modalService: ModalService) { }



  ngOnInit(): void {

    this.getDrugs()
  }


  getDrugs() {
    this.drugsService.getDrugsList().subscribe((data: Drug[]) => {
      this.FilteredDrugs =this.Drugs = data;
    });
  }



  
  closeModal() {
    this.hideModal = true;
  }

  submitDrugModal(drug: Drug,  $event) {

  
    if (this.drugIndecation != "" && this.drugIndecation != undefined)
      this.indecationFound = true;


      this.drugEvent.emit(drug)

      this.closeModal()
  }


  SearchDrug($event) {
    let searchStr:string = $event
    if (searchStr == ""){
      this.FilteredDrugs = this.Drugs
    }else{
      this.FilteredDrugs = this.Drugs.slice().filter((drug: Drug) => {
        return (drug.ShortTradeName.toLowerCase()).indexOf(searchStr.toLowerCase()) !== -1;
      });
    }

  }
}
