import { Component, EventEmitter,  Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { CaretxSharedModulesProviderService } from '../../../../../src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { ModalService } from '../../../../../src/app/modal/modal.service';
import { MedicalDiagnoses } from '../../../../../src/app/models/drug';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { MedicalDiagnosesProviderService } from '../../../caretx-doctors-module/doctor-services/medical-diagnoses.data-provider.service';

@Component({
  selector: 'app-diagnoseslookup',
  templateUrl: './diagnoseslookup.component.html',
  styleUrls: ['./diagnoseslookup.component.css']
})

export class DiagnoseslookupComponent implements OnInit {


  private _searchTxt: string
  indecationFound: boolean
  hideModal: boolean = true;
  @ViewChild('myModalClose') modalClose;
  @Input() callerId: string
  @Output() diagnosesEvent = new EventEmitter<MedicalDiagnoses>();
  @Output() closeDiagnosesModalEvent = new EventEmitter<void>();

  observablefilteredDiagnoses: Observable<MedicalDiagnoses[]>
  filteredDiagnoses: MedicalDiagnoses[]
  MedicalDiagnoses: MedicalDiagnoses[]
  constructor(
    private caretxSharedlService: CaretxSharedModulesProviderService, 
    private medicalDiagnosesProviderService: MedicalDiagnosesProviderService,
    private toastrService: ToasterService,
    private modalService: ModalService) { }

  myControl = new FormControl();

  ngOnInit(): void {
    this.getDiagnoses()
  }

  getDiagnoses() {
    this.medicalDiagnosesProviderService.medicalDiagnoses= []
    this.medicalDiagnosesProviderService.fetchDiagnoses().subscribe(data => {
      this.MedicalDiagnoses = this.medicalDiagnosesProviderService.medicalDiagnoses//data
      if (this.MedicalDiagnoses != undefined && this.MedicalDiagnoses != null)
        this.filteredDiagnoses = this.MedicalDiagnoses.slice(0, 100)
    })
  }

  closeModal() {
    this.hideModal = true;
    this.closeDiagnosesModalEvent.emit()
    this.modalClose.nativeElement.click();
  }

  submitDiagnosesModal(diagnoses: MedicalDiagnoses, $event) {
    this.diagnosesEvent.emit(diagnoses)
    this.closeModal()
  }

  filterDiagnosesList(searchTxt: string) {
    return this.medicalDiagnosesProviderService.serachDiagnoses(searchTxt)
  }

  SearchDiagnoses($event) {
    this._searchTxt = $event
    if (  this._searchTxt == ""){
      this.filteredDiagnoses = this.MedicalDiagnoses
    }else{
      this.filteredDiagnoses = this.MedicalDiagnoses.slice().filter((record: MedicalDiagnoses) => {
        return (record.ShortDescription.toLowerCase()).indexOf(  this._searchTxt.toLowerCase()) !== -1;
      });
    }
  }
  
  AddNewDiagnoses() {
      var newDiagnoses =  new MedicalDiagnoses();
      newDiagnoses.IsGlobal = false
      newDiagnoses.New = true
      newDiagnoses.ShortDescription =  this._searchTxt
    this.caretxSharedlService.createNewDiagnoses(newDiagnoses).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.diagnosesEvent.emit(new MedicalDiagnoses(data))
        this.modalClose.nativeElement.click();
        this.getDiagnoses()
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  checkIfDiagnosesExistsInDB() {
    
  }
}
