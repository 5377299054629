import { Component, Inject, OnInit } from '@angular/core';
import { CaretxMedicalProviderService } from '../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { BMIMeasure } from '../../../../../src/app/models/DieticianFollowup';
import { PersonalMeasures } from '../../../../../src/app/models/measure';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import {
  MAT_DIALOG_DATA,

  MatDialogRef,

} from '@angular/material/dialog';

@Component({
  selector: 'app-bmi-measure-dialog',
  templateUrl: './bmi-measure-dialog.component.html',
  styleUrls: ['./bmi-measure-dialog.component.css'],


})
export class BmiMeasureDialogComponent implements OnInit {

  id: string
  isNew: boolean
  isDisabled: boolean
  bmiMeasure: BMIMeasure
  LocalCreatedAt: Date


  constructor(
    private _labService: CaretxMedicalProviderService,
    private toastrService: ToasterService,
    private dialogRef: MatDialogRef<BmiMeasureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }


  ngOnInit(): void {
    this.id = this.data.personelid;
    this.bmiMeasure = this.data.bmiMeasure
    this.isDisabled = this.data.IsDisabled
    this.bmiMeasure.UpdatedAt = this.bmiMeasure.CreatedAt = new Date()
    this.GetTheLatestHeightMeasure()
  }


  GetTheLatestHeightMeasure() {

    this._labService.getPerosnelMeasuresByCodeText(this.id, "height").subscribe((data: PersonalMeasures) => {

      if (data != undefined) {
        this.bmiMeasure.Height = data.NumericValue
      }


    });
  }

  calculateBMI() {
    if (this.bmiMeasure.Height > 0 && this.bmiMeasure.Weight > 0) {
      var bmi = this.bmiMeasure.Weight / Math.pow(this.bmiMeasure.Height / 100, 2)
      this.bmiMeasure.BMI = Number(bmi.toFixed(1))
    }
  }

  submit() {

    this._labService.createNewBMIMeasure(this.id, this.bmiMeasure).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.dialogRef.close(this.bmiMeasure);
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })




  }

  onCloseDialog() {

  }

}



