import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { MedicalFile } from '../../../../../src/app/models/customer';
import { MedicalFileProviderService } from '../../medicalfile-services/medicalfile.data-provider.service';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataService } from '../../../../../src/app/services/data.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { Departments, NursingStatus } from '../../../../../src/app/models/generalhousedsetups';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';







export interface MedicalFileElement {
  ID: string
  FullName: string;
  StatusDescr: string;
  DepartmentName: string;
  HMO: string;
  DepartmentAcceptanceDate: Date;
  StatusCode: string;
  PhotoNotExists: boolean;
  ImagePath: string;
  file: MedicalFile;
}


type Items1 = {
  DepartmentName: string;
  ID: string
};



@Component({
  selector: 'app-customers',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],

})

export class CustomersComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['FullName', 'StatusDescr', 'DepartmentName', 'HMO', 'DepartmentAcceptanceDate'];


  TabId = "medicalfiles"
  medicalFilesOrigin: MedicalFile[];
  filteredData: MedicalFile[] = [];
  renderedData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];
  ELEMENTS_DATA: MedicalFileElement[] = []
  departments: Departments[] = []
  NurseStatuses: NursingStatus[]

  dataSource = new MatTableDataSource([]);
  DefaultPhotoURL: string = "../../assets/images/user-avatar.png"
  pageHeader: string;
  pageIcon: string;
  dropdownSettings1 = {};
  totalNumOfRecords: number
  selectedItems1: Items1[] = [];
  formData: any[] = []
  enableSearch: boolean = false
  RecordStatus: any[]

  searchForm = new FormGroup({
    status_id: new FormControl(''),
    department_id: new FormControl(''),
    personel_id: new FormControl(''),
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
  });


  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private title: Title,
    private medicalFileService: MedicalFileProviderService,
    private caretxSetupService: CaretxSetupsProviderService,
    private authServive: AuthService,
    public httpClient: HttpClient,
    public dialog: MatDialog,
    private router: Router,
    private data: DataService,
    private toastrService: ToasterService

  ) {
    this.selectedItems1 = JSON.parse(localStorage.getItem('user_selected_departments') || '{}');
    this.caretxSetupService.GetUserAllowedDepartments(this.authServive.LoggedInUser.UID).subscribe((data => {
      // this.departments = data

      data.forEach(element => {
        this.departments.push(new Departments(element))

      });


      // Foreach on departments
      if (this.selectedItems1.length == undefined) this.selectedItems1 = []
      if (this.selectedItems1.length == 0) {

        if (this.departments.length > 0) {
          this.departments.forEach(element => {
            this.selectedItems1.push({ "DepartmentName": element.DepartmentName, "ID": element.ID })
          });
        } else {

        }
      }
      this.caretxSetupService.GetNursingStatuses().subscribe((data => {
        this.NurseStatuses = data
        this.RecordStatus = []
        this.NurseStatuses.forEach(element => {
          this.RecordStatus.push({ id: element.ID, desc: element.StatusDescr })

        });

        this.formData.push({ "formcontrolname": "status_id", "label": "סטטוס", "type": "multi", "value": JSON.stringify(this.RecordStatus), "selectid": "id", "selectval": "desc" })

      }))

    }))
  }



  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }



  ngAfterViewInit() {

    this.enableSearch = true
  }

  ngOnInit(): void {
    this.title.setTitle('Caretex| תיקים פעילים');
    this.data.isMedicalFileOpened = false
    this.pageHeader = 'Medical files ';
    this.pageIcon = 'fas fa-user';
    this.doInitialization()
    this.pageIcon = 'fas fa-user';
    this.loadData2("");

    this.formData.push({ "formcontrolname": "personel_id", "label": "תעודת זהות", "type": "txtinput", "value": "", "selectid": "", "selectval": "" })
    this.formData.push({ "formcontrolname": "first_name", "label": "שם פרטי", "type": "txtinput", "value": "", "selectid": "", "selectval": "" },)
    this.formData.push({ "formcontrolname": "last_name", "label": "שם משפחה", "type": "txtinput", "value": "", "selectid": "", "selectval": "" })
    this.formData.push({ "formcontrolname": "created_at_from", "label": "תאריך קליטה", "type": "date", "value": "", "selectid": "", "selectval": "" })
    this.formData.push({ "formcontrolname": "created_at_to", "label": "עד תאריך קליטה", "type": "date", "value": "", "selectid": "", "selectval": "" })
    //this.dataSource.sort = this.sort;

  }


  loadData2(filter: string) {


    this.medicalFileService.getMedicalFiles(filter);
    this.medicalFileService.dataChange.subscribe((data) => {
      this.filteredData = []
      this.allFiles = []
      this.selectedItems1 = JSON.parse(localStorage.getItem('user_selected_departments') || '{}');
      this.allFiles = data
      if (this.selectedItems1.length == 0) {
        this.filteredData = this.allFiles
        this.prepareFilterToSearchMedicalFiles()
        this.FillDataSourceToShowInMatTable()
      } else {
        if (this.selectedItems1 != undefined) {

          if (Array.isArray(this.selectedItems1)) {
            this.allFiles.forEach(file => {
              const found = this.selectedItems1.some(x => x.ID === file.DepartmentId)
              if (found) {
                this.filteredData.push(file)
              }
            });
          } else {
            console.log("Given data is not an array")
          }




        }
        this.FillDataSourceToShowInMatTable()
      }
      this.totalNumOfRecords = this.filteredData.length
    })
  }


  doInitialization() {
    // Get LabCategories :
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: "ID",
      textField: "DepartmentName",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  addNewFile() {
    this.router.navigate(['newmeidcalfile']);
  }

  SearchWithFilter($event) {
    this.loadData2($event);
  }

  onDepartmentSelect($event) {
    this.filteredData = []
    this.prepareFilterToSearchMedicalFiles()
    this.FillDataSourceToShowInMatTable()
  }

  onItemDeSelect($event) {
    this.filteredData = []
    if (this.selectedItems1.length == 0) {
      this.filteredData = this.allFiles
    }
    this.prepareFilterToSearchMedicalFiles()
    this.FillDataSourceToShowInMatTable()
  }

  onSelectingAllDepartments(items: any) {
    this.selectedItems1 = items
    this.filteredData = []
    //this.totalNumOfRecords = this.filteredData.length
    this.prepareFilterToSearchMedicalFiles()
    this.FillDataSourceToShowInMatTable()


  }

  onDeSelectAll(items: any) {
    this.selectedItems1 = items
    this.filteredData = this.allFiles
    this.prepareFilterToSearchMedicalFiles()
    this.FillDataSourceToShowInMatTable()
  }

  prepareFilterToSearchMedicalFiles() {
    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));
    this.allFiles.forEach(file => {
      const found = this.selectedItems1.some(x => x.ID === file.DepartmentId)
      if (found) {
        this.filteredData.push(file)
      }
    });
    this.totalNumOfRecords = this.filteredData.length
  }

  startEdit(tableRecord) {
    this.medicalFileService.CurrentOpenedFile = tableRecord.file
    this.data.updateIsNotesShowed(false)
    this.router.navigateByUrl("/medicalfiles/" + tableRecord.file.PersonelID)
  }

  FillDataSourceToShowInMatTable() {
    this.ELEMENTS_DATA = []
    this.filteredData.forEach(file => {
      this.ELEMENTS_DATA.push({
        ID: file.PersonelID,
        FullName: file.LastName + " " + file.FirstName,
        StatusDescr: file.Status.StatusDescr,
        DepartmentName: file.Department.DepartmentName,
        HMO: file.HMO,
        DepartmentAcceptanceDate: file.DepartmentAcceptanceDate,
        StatusCode: "",
        PhotoNotExists: file.PhotoNotExists,
        ImagePath: file.ImagePath,
        file: file,
      });
    });
    this.dataSource = new MatTableDataSource(this.ELEMENTS_DATA)
    // this.dataSource.sort = this.sort;
  }

  filterPaitents($event) {
    let filter: string = $event.currentTarget.value
    this.filteredData = this.allFiles
    this.allFiles = this.medicalFileService.data
    this.filteredData = this.medicalFileService.data.slice().filter((medicalFile: MedicalFile) => {
      const searchStr = (medicalFile.PersonelID + medicalFile.FirstName + medicalFile.LastName).toLowerCase();
      return searchStr.indexOf(filter.toLowerCase()) !== -1;
    });
    this.FillDataSourceToShowInMatTable()
  }


  departmentsFilter: boolean = false
  showDepMandatoryLbl: boolean = false
  OpenSideNavFilter() {
    this.departmentsFilter = true
  }

}
