import { Departments } from './generalhousedsetups'
import { propertyMap } from './modelmapper'
import { Tenant } from './tenant'

// Need Server to send Payload in below structure


export class User {
   
    @propertyMap('uid')
    UID: string
    @propertyMap('displayName')
    displayName: string
    @propertyMap('role')
    role: string
    @propertyMap('lastName')
    lastName: string
    @propertyMap('email')
    email: string
    @propertyMap('phone')
    phone: string
    @propertyMap('age')
    age: number
    @propertyMap('tenantID')
    tenantID: string
    @propertyMap('photoURL')
    photoURL: string
    @propertyMap('password')
    password: string
    @propertyMap('gender')
    gender: string
    @propertyMap('firstName')
    firstName: string
    @propertyMap('SocialId')
    SocialId: string
    @propertyMap('HMO')
    HMO: string
    @propertyMap('TitleId')
    TitleId: string
    @propertyMap('Title')
    Title: SocialTitle
    @propertyMap('LicenseId')
    LicenseId: string
    @propertyMap('BirthDay')
    BirthDay: Date
    @propertyMap('JoinedWorkDate')
    JoinedWorkDate: Date
    @propertyMap('Address')
    Address: string
    
    @propertyMap('UserPermessions')
    UserPermessions: UserPermession[] = []

    @propertyMap('DepartmentsPermessions')
    DepartmentsPermessions: UserDepartmentsPermession[] = []

    
    @propertyMap('PhotoNotExists')
    PhotoNotExists: boolean

    Tenant: Tenant
        
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.UID = data["UID"]
            this.displayName = data["displayName"]
            this.role = data["role"]
            this.lastName = data["lastName"]
            this.email = data["email"]
            this.phone = data["phone"]
            this.age = data["age"]
            this.tenantID = data["tenantID"]
            this.photoURL = data["photoURL"]
            this.password = data["password"]
            this.gender = data["gender"]
            this.firstName = data["firstName"]
            this.LicenseId = data["LicenseId"]
            this.BirthDay = data["BirthDay"]
            this.Address = data["Address"]
            this.photoURL = data["photoURL"]
            this.SocialId = data["SocialId"]
            this.HMO = data["HMO"]
            this.TitleId = data["TitleId"]
            this.Title = data["Title"]
            this.PhotoNotExists = data["PhotoNotExists"]
            this.JoinedWorkDate = data["JoinedWorkDate"]
            data["UserPermessions"].forEach(el => this.UserPermessions.push(new UserPermession(el)))
            if (data["DepartmentsPermessions"] != undefined && data["DepartmentsPermessions"] != null)
            data["DepartmentsPermessions"].forEach(el => this.DepartmentsPermessions.push(new UserDepartmentsPermession(el)))
             if (this.PhotoNotExists) this.photoURL = "../assets/images/user-avatar.png"
   
        }
        else {
            this.TitleId = ""
        }
    }
}


export class UserPermession{
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('RealmID')
    public RealmID: string
    @propertyMap('ComponentId')
    public ComponentId: string
    @propertyMap('CaretexComponent')
    public CaretexComponent: CaretexComponent
    @propertyMap('Create')
    public Create: boolean
    @propertyMap('Update')
    public Update: boolean
    @propertyMap('Delete')
    public Delete: boolean
    @propertyMap('NavigateTo')
    public NavigateTo: boolean
    @propertyMap('GenerateReport')
    public GenerateReport: boolean
    public editMode :boolean = false

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.RealmID = data["RealmID"]
            this.ComponentId = data["ComponentId"]
            this.CaretexComponent = data["CaretexComponent"]
            this.Create = data["Create"]
            this.Update = data["Update"]
            this.Delete = data["Delete"]
            this.NavigateTo = data["NavigateTo"]
            this.GenerateReport = data["GenerateReport"]
            this.editMode = false
        }
    }

}

export class UserDepartmentsPermession{
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('DepartmentId')
    public DepartmentId: string
    @propertyMap('Department')
    public Department: Departments
    @propertyMap('IsAllowed')
    public IsAllowed: boolean
    @propertyMap('UserId')
    public UserId: string

    @propertyMap('Modified')
    public Modified: boolean

    @propertyMap('New')
    public New: boolean



   
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.TenantID = data["TenantID"]
            this.DepartmentId = data["DepartmentId"]
            this.Department = data["Department"]
            this.IsAllowed = data["IsAllowed"]
            this.UserId = data["UserId"]
            this.Modified = data["Modified"]
            this.New = data["New"]

        }else {
      
            this.CreatedAt = new Date ()
            this.UpdatedAt = this.CreatedAt
            
            this.DepartmentId = ""
     
        }
    }

}


export class CaretexComponent{
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('ComponentId')
    public ComponentId: string
    @propertyMap('ComponentName')
    public ComponentName: string
    @propertyMap('ComponentGroup')
    public ComponentGroup: string
    @propertyMap('Sequence')
    public Sequence: Number
    
}


export class SocialTitle{
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('SocialTitle')
    public SocialTitle: string
    @propertyMap('Modified')
    public Modified: boolean
    @propertyMap('New')
    public New: boolean
    @propertyMap('Sequence')
    public Sequence: Number
    
}

