


import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';

@Component({
  selector: 'app-medicalomdan-land-page',
  templateUrl: './medicalomdan-land-page.component.html',
  styleUrls: ['./medicalomdan-land-page.component.css']
})
export class MedicalomdanLandPageComponent extends CaretexComponent implements OnInit {
  id :string
  moduleName:string="אומדנים/מבחנים"
  constructor( 
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private router: Router,
    private route: ActivatedRoute,
    ) {
    super(medicalFileService);
  }

  ngOnInit(): void {
    this._title.setTitle('Caretx | אומדנים/מבחנים');
    if (this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
  }
  
  openOmdanMiniMentalList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams/minimental/list")
  }

  openOmdanClockList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams/omdanclock/list")
  }

 

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }

}
