import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { CaretxNursingProviderService } from '../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';

import {  MedicalPrescriptionLine } from '../../../../../src/app/models/medical-prescriptions';
import {  DrugUom } from '../../../../../src/app/models/generalhousedsetups';
import { Drug, MedicalDiagnoses } from '../../../../../src/app/models/drug';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { MedicalDiagnosesProviderService } from '../../../../../src/app/caretx-doctors-module/doctor-services/medical-diagnoses.data-provider.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { ModalService } from '../../../../../src/app/modal/modal.service';
import { Observable } from 'rxjs';
import { FormControl, NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-medicalprescription-dialog',
  templateUrl: './medicalprescription-dialog.component.html',
  styleUrls: ['./medicalprescription-dialog.component.css']
})
export class MedicalprescriptionDialogComponent implements OnInit {


  MedicalPrescriptionRecord: MedicalPrescriptionLine
  currentRowNumClickedModal: number = 0
  IsDisabled: boolean = false
  NewRecord: boolean = false
  @ViewChild('mainform') mainFormElement: NgForm;




  observablefilteredDiagnoses: Observable<MedicalDiagnoses[]>
  filteredDiagnoses: MedicalDiagnoses[]
  MedicalDiagnoses: MedicalDiagnoses[]
  private _searchTxt: string
  indecationFound: boolean
  myControl = new FormControl();
  showNotFoundMsg: boolean = false
  currentSelectedStep: number = 0
  @Output() diagnosesEvent = new EventEmitter<MedicalDiagnoses>();
  DoseUoms: DrugUom[] = []
  public addTagNowRef: (name) => void;

  constructor(
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private medicalDiagnosesProviderService: MedicalDiagnosesProviderService,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private toastrService: ToasterService,
    private modalService: ModalService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<MedicalprescriptionDialogComponent>,
    private caretxNursingProviderService: CaretxNursingProviderService, @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.caretxSetupsProviderService.getDrugUoms().subscribe((data => {
      this.DoseUoms = data
    }))
    this.addTagNowRef = this.CreateNewUom.bind(this);
  }


  ngOnInit(): void {

    this.IsDisabled = this.data.Disabled
    this.MedicalPrescriptionRecord = this.data.MedicalPrescriptionRecord
    this.NewRecord = this.data.NewRecord
    this.getDrugIndexFromDB()

  }

  diagnosesEventHandler($event) {
    let medicalDiagnose: MedicalDiagnoses = new MedicalDiagnoses($event)
    //this.currentDiagnoses.MedicalDiagnoses = medicalDiagnose
  }


  /****************************Drug Lookup*************************************** */

  drugs: Drug[] = []
  filteredDrugs: Drug[] = []
  getDrugIndexFromDB() {
    let filter: string = ""
    this.caretxSharedlService.getMedicalDrugs(filter).subscribe(data => {
      this.drugs = data
      if (this.drugs != undefined && this.drugs != null)
        this.filteredDrugs = this.drugs.slice(0, 100)
    }),
      err => {
        this.drugs = []
      }

  }



  SearchDrug(text: string) {
    let searchedTxt = text.toLowerCase().trim()
    this.filteredDrugs = this.drugs.filter((element) => {
      return (element.ShortTradeName.toLowerCase().includes(searchedTxt));
    })

  }



  submitDrugModal(drug: Drug, stepper: MatStepper) {
    this.MedicalPrescriptionRecord.drugId = drug.id
    this.MedicalPrescriptionRecord.Drug = drug
    this.MedicalPrescriptionRecord.QtyOfServe = this.MedicalPrescriptionRecord.Drug.QtyOfServe 
    this.openMainStep(stepper)
  }



  CreateNewUom(uomVal) {
    // Create New Uom 
    var newDrugUom = new DrugUom()
    newDrugUom.New = true
    newDrugUom.editMode = true
    newDrugUom.CreatedAt = new Date()
    newDrugUom.UpdatedAt = new Date()
    newDrugUom.TenantID = this.authService.LoggedInTenantID
    newDrugUom.Uom = uomVal

    let uoms: DrugUom[] = []
    uoms.push(newDrugUom)

    this.caretxSetupsProviderService.CreateUpdateDrugUoms(uoms).subscribe({
      next: data => {
        this.getDrugUomsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })

  }

  getDrugUomsFromDB() {
    this.caretxSetupsProviderService.getDrugUoms().subscribe(
      data => {
        this.DoseUoms = data
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      err => {
        this.DoseUoms = []

      }
    )
  }

  openCodesStep(stepper: MatStepper) {
    stepper.next();
  }

  openMainStep(stepper: MatStepper) {
    stepper.previous();
  }

  conevrtoDateTime($event) {
    return new Date($event)
  }

  submitForm() {
    this.mainFormElement.onSubmit(new Event("", undefined));
  }

  submit() {

    // Should I save into DB  ?
    // Lookup the Medication UOM 

    for (let i = 0; i < this.DoseUoms.length; i++) {
      if (this.DoseUoms[i].ID == this.MedicalPrescriptionRecord.Drug.DrugForm) {
        this.MedicalPrescriptionRecord.DrugForm = this.DoseUoms[i]
        this.MedicalPrescriptionRecord.DrugFormId = this.DoseUoms[i].ID
        break
      }
    }
    this.dialogRef.close(this.MedicalPrescriptionRecord);
  }

  selectionChange($event) {
    this.currentSelectedStep = $event.selectedIndex
  }

}
