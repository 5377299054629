import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Instruction } from 'src/app/models/nurseinstruction';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-nusringinstructionssetup',
  templateUrl: './nusringinstructionssetup.component.html',
  styleUrls: ['./nusringinstructionssetup.component.css']
})
export class NusringinstructionssetupComponent extends CaretexComponent implements OnInit {
  instructions:Instruction[]  = []
  TabId:string = "main"
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private router: Router,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {super(medicalFileServive)

     }


  ngOnInit(): void {
    this.getInstructionsFromDB()
  }

  getInstructionsFromDB(){
    this.caretxSetupsProviderService.GetInstruction().subscribe(
      data => {
        this.instructions = data
      },
      err => {
        console.log("Error", err)
        this.instructions = []

      }
    )
  }
  updateNurseInstr($event){
    this.caretxSetupsProviderService.CreateInstruction(this.instructions).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getInstructionsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })  
  }

  BackToList(){
    this.router.navigate(['system_setups']);
  }
  addNewInstruction(){
    var addNewInstruction= new Instruction()
    addNewInstruction.New = true
    addNewInstruction.editMode = true
    addNewInstruction.CreatedAt = new Date()
    addNewInstruction.UpdatedAt = new Date()
    addNewInstruction.TenantID = this.authServive.LoggedInTenantID
    this.instructions.unshift(addNewInstruction)
  }

  deleteRow(i){
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.instructions[i].New){
          this.caretxSetupsProviderService.DeleteInstruction(this.instructions[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.instructions.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.instructions.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i){
    this.instructions[i].editMode = true
    this.instructions[i].Modified = true
  }

  cancelEditRow(i){
    this.instructions[i].editMode  = false;
    this.instructions[i].Modified = false
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const statusesListChanged = this.instructions.some(row => {
      return row.New || row.editMode
    })
    return  statusesListChanged
  }


}
