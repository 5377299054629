import { propertyMap } from "./modelmapper"
import { User } from "./user"



export class Attachment {
    @propertyMap('ID')
    public ID:string
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Downloaded_at')
    public Downloaded_at:Date
    @propertyMap('Uploaded_at')
    public Uploaded_at:Date
    @propertyMap('Description')
    public Description:string
    @propertyMap('LinkedTo')
    public LinkedTo:string
    @propertyMap('FileDataBase64')
    public FileDataBase64:string
    @propertyMap('FileCategory')
    public FileCategory:string
    @propertyMap('FileName')
    public FileName:string
    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User
    @propertyMap('DownloadedById')
    public DownloadedById: string
    public DownloadedBy: User

}




