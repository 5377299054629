import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PagenationObject } from '../../../../../src/app/models/pagenation';
import { PhysioTherapyFollowUp } from '../../../../../src/app/models/physiotherapy';


@Component({
  selector: 'app-historicphsiofollowups',
  templateUrl: './historicphsiofollowups.component.html',
  styleUrls: ['./historicphsiofollowups.component.css']
})
export class HistoricfollowupsComponent implements OnInit {

  @Input() HistoricRecords: PhysioTherapyFollowUp[]
  @Input() Pagenation: PagenationObject
  @Output() PageNumSelectionEvent = new EventEmitter<string>();


  currentPage: number = 1
  pages: number[]
  page1: number = 1
  page2: number = 2
  page3: number = 3
  selectedPage: number = -1
  pageSize: number = 5
  pageCount: number = 1
  public editorDisabled = true;

  public config: any = {
    airMode: false,
    tabDisable: true,
    height: '200px',
    toolbar: [

    ],

  };

  constructor() { }


  ngOnInit(): void {
    this.pageCount = Math.ceil(this.Pagenation.TotalNumOfPages / this.pageSize)
    if (this.pageCount == 0) {
      this.pageCount = 1
    }
    this.pages = Array.from({ length: this.pageCount }, (_, i) => i + 1)
    this.selectedPage = 1
  }
  onReady2(editor2) {
    editor2.ui.getEditableElement().parentElement.insertBefore(
      editor2.ui.view.toolbar.element,
      editor2.ui.getEditableElement()
    );
    editor2.isReadOnly = true
  }
  SelectPage($event) {
    this.selectedPage = $event
    this.PageNumSelectionEvent.emit(this.selectedPage.toString())
  }
  PrevPage() {

    if (this.selectedPage == this.pages[0]) {

      if (this.pages[0] - 1 >= 1) {
        for (let i = 0; i < this.pages.length; i++) {
          this.pages[i] -= 1
        }
        this.selectedPage = this.pages[0]
      }
    } else if (this.selectedPage > this.pages[0]) {
      this.selectedPage -= 1
    }

    this.PageNumSelectionEvent.emit(this.selectedPage.toString())

  }
  NextPage() {
    if (this.pageCount > 3) {
      if (this.selectedPage == this.pages[2]) {

        if (this.pages[2] + 1 <= this.pageCount) {
          for (let i = 0; i < this.pages.length; i++) {
            this.pages[i] += 1
          }
          this.selectedPage = this.pages[2]
        }
      } else if (this.selectedPage < this.pages[2]) {
        this.selectedPage += 1
      }
    } else {
      if (this.selectedPage < this.pageCount) {
        this.selectedPage += 1
      }
    }
    this.PageNumSelectionEvent.emit(this.selectedPage.toString())
  }


}
