import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { OccupationalTherapyAssessment } from '../../../../../src/app/models/occupationalTherapy';
import { sideNaveGroups } from '../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { OccupationalDataProviderService } from '../../ModuleServices/occupational.data-provider.service';

@Component({
  selector: 'app-occupation-assessment-list',
  templateUrl: './occupation-assessment-list.component.html',
  styleUrls: ['./occupation-assessment-list.component.css']
})
export class OccupationAssessmentListComponent extends CaretexComponent implements OnInit {
  moduleName:string = "רשימת הערכות ריפוי בעיסוק"
  SideNaveGroup: sideNaveGroups=sideNaveGroups.Medical;
  id:string
  records : OccupationalTherapyAssessment[] = []
  currentRowIndex:number
  constructor(
  private router: Router,
  public medicalFileService: MedicalFileProviderService,
  private caretxOccupationalProviderService: OccupationalDataProviderService,
  private _title: Title,
  private toastrService: ToasterService,
  private route: ActivatedRoute,) { super(medicalFileService)}
  formData : any[] 
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), //, [Validators.required, Validators.email]),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0

  RecordStatus = [{id:"A" , desc:"רשומה פעילה"},{id:"C" , desc:"רשומה נעולה"}]


  ngOnInit(): void {
    this._title.setTitle('Caretx | רשימת הערכות');
    if(this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'] 
    this.CheckIfComponentIsDisabled(this.id)
    this.getAssessmentsFromDB("")
    this.formData=  [
      {"formcontrolname":"status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
      {"formcontrolname":"created_at_from","label":"מתאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"created_at_to","label":"עד תאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_from","label":"תאריך נעילה מ" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_to","label":"תאריך נעילה עד" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},

    ]
  }


  getAssessmentsFromDB(filter:string) {

    this.caretxOccupationalProviderService.getOccupationalAssessment(this.id , filter, "list").subscribe(
      data =>{
        this.records = data 
         this.totalNumOfRecords = data.length
        },
      err => {
        this.records = []
      }
    )
  }
  SearchWithFilter($event) {
    this.getAssessmentsFromDB($event)
  }

  AddNewRecord($event){
 this.router.navigateByUrl("caretexfile/" + this.id + "/occupationaltherapyassessment/new")
  }

  deleteRow(i){
    this.currentRowIndex = i
    if (!(this.records[i].Status == "C")) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRecord(this.records[this.currentRowIndex])
        }
      })

    }
  }


  deleteRecord(assessment: OccupationalTherapyAssessment) {
    let filter = '(id=' + '\'' + assessment.ID + '\'' + ')'
    this.caretxOccupationalProviderService.deleteOccupationalAssessment(this.id, filter).subscribe({
      next: data => {
        this.getAssessmentsFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }


  generateReport($event,record){
    
  }
  OnSDrillToHazardDetail($event,record){
    this.router.navigateByUrl("caretexfile/" + this.id + "/occupationaltherapyassessment/"+record.ID)
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }
}
