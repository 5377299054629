import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NurseExamination } from 'src/app/models/nurseexamination';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxNursingProviderService } from '../../nursing-services/Nursing.data-provider.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-nurseexaminationslist',
  templateUrl: './nurseexaminationslist.component.html',
  styleUrls: ['./nurseexaminationslist.component.css']
})
export class NurseexaminationslistComponent extends CaretexComponent  implements OnInit {

  id: string
  isValid: true
  currentRowIndex: number
  deleteSysMsgId: string
  deleteAlert: string
  alertTitle: string
  physicalchecks: NurseExamination[] = []
  moduleName:string="רשימה של הערכות גופניות"
  formData : any[] 
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), 
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0
  RecordStatus = [{id:"A" , desc:"רשומה פעילה"},{id:"C" , desc:"רשומה נעולה"}]

  
  constructor(
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private toastrService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,) {  super(medicalFileService)}

  ngOnInit(): void {
    this._title.setTitle('Caretx | הערכה גופנית');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id) 
    this.getExaminatinsListFromDB("")
    this.formData=  [
      {"formcontrolname":"status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
      {"formcontrolname":"created_at_from","label":"מתאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"created_at_to","label":"עד תאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_from","label":"תאריך נעילה מ" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_to","label":"תאריך נעילה עד" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
    ]
  }

  getExaminatinsListFromDB(filter:string) {
    this.caretxNursingProviderService.getAllNurseExaminations(this.id, filter).subscribe(
      data => {
        this.physicalchecks = data
        this.totalNumOfRecords = data.length
      },
      err => {
        console.log("Error", err)
        this.physicalchecks = []
      }
    )
  }

  SearchWithFilter($event) {
    this.getExaminatinsListFromDB($event)
  }

  AddNewNursePhysicalcheck($event) {
    let filter = '(status=\'A\')'
    this.caretxNursingProviderService.getAllNurseExaminations(this.id , filter).subscribe((data: NurseExamination[]) => {
      if (data.length > 0) {
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
      else {
        this.router.navigateByUrl("caretexfile/" + this.id + "/physicalnursecheck/new")
      }
    });
  }

  deleteRow(i){ 
    this.currentRowIndex = i
    if (this.physicalchecks[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletePhysicalcheck(this.physicalchecks[this.currentRowIndex])
        }
      })
    }
  }

  deletePhysicalcheck(physicalCheck: NurseExamination) {
    let filter = '(id=' + '\'' + physicalCheck.ID + '\'' + ')'
    this.caretxNursingProviderService.deleteNurseExamination(this.id , filter).subscribe({
      next: data => {
        this.getExaminatinsListFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }

  generateReport($event, event) {

  }

  OnSDrillToeventDetails($event, check) {
    this.router.navigateByUrl("caretexfile/" + this.id + "/physicalnursecheck/" + check.ID)
  }

  handleDeleteAlerMsg($event) {
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }
  
}
