import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DailyMealReport, Meal } from 'src/app/models/Dieticianfoodintake';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { BridgeEnums, DieticianAssessmentSteps, MealType } from '../../../services/enums';
import { DieticianDataProviderService } from '../../../caretex-dietician/Dietician-services/dietician.data-provider.service';
import { Title } from '@angular/platform-browser';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { MedicalFile } from 'src/app/models/customer';

@Component({
  selector: 'app-shareddailyfoodreport',
  templateUrl: './shareddailyfoodreport.component.html',
  styleUrls: ['./shareddailyfoodreport.component.css']
})
export class ShareddailyfoodreportComponent extends CaretexComponent  implements OnInit {

  moduleName:string="תיעוד צריכת מזון יומית"
  isRecordClosed:boolean = false
  CurrentStep: DieticianAssessmentSteps = DieticianAssessmentSteps.Problems
  breakfastMeal : Meal
  noonMeal : Meal
  eveningMeal : Meal
  personalId:string
  recordId:string
  currentDailyFoodReport : DailyMealReport
  BaseUrl:string
  @Input() ForNursing:boolean
  @Output() backToCentralPage = new EventEmitter<void>();
  @Input() PersonalId: string
  @Input() IsFromCentralizedPage: boolean = false
  @Input() SelectedFile: MedicalFile
  @Input() FoodReportId: string


  
  constructor( 
    private _title: Title,
    private router: Router,
    private _ar: ActivatedRoute,
    private bridgeEnum: BridgeEnums,
    private caretxDieticianProviderService: DieticianDataProviderService,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authService: AuthService,
    private route: ActivatedRoute,) { super(medicalFileService);}



  ngOnInit(): void {
    if (this.IsFromCentralizedPage) {
      this.personalId = this.PersonalId
      this.recordId =this.FoodReportId
    }else {
      this.personalId = this.route.parent.snapshot.params['id'];
      this.BaseUrl = this.route.parent.snapshot.url[2].path
      this.recordId = this.route.snapshot.params.recordid 
    }




    this.CheckIfComponentIsDisabled(this.personalId)

    if (this.recordId == 'new') {
      this.initializeNewDailyReport()
     }else{

      this. getDailyFoodReportById()

     }
  }


  getDailyFoodReportById() {
    let filter = ""
      filter = '(id=' + '\'' + this.recordId + '\'' + ')'

    this.caretxDieticianProviderService.getDieticianMealsReport(this.personalId, filter, "details").subscribe((data: DailyMealReport[]) => {
      if (data.length == 1) {
        this.currentDailyFoodReport = new DailyMealReport(data[0])
        this.isRecordClosed = (this.currentDailyFoodReport.Status == "C")
        this.currentDailyFoodReport .Meals.forEach(meal => {
                if (meal.Type == MealType.Morning) this.breakfastMeal = meal
                else if(meal.Type == MealType.Noon) this.noonMeal = meal
                else  if(meal.Type == MealType.Evening) this.eveningMeal = meal
        });


      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  BackToList(){
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/"+this.BaseUrl +"/list")
  }


  lockRecord() {
    this.currentDailyFoodReport.Status = "C"
    this.DoUpdate()
  }
  SaveRecord(){
    if (this.currentDailyFoodReport.ID == "00000000-0000-0000-0000-000000000000" || this.currentDailyFoodReport.ID == "") {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave(){
    this.caretxDieticianProviderService.createDieticianMealsReport(this.currentDailyFoodReport).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      
          //retreive again the file
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          if (!this.IsFromCentralizedPage) {
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/"+this.BaseUrl +"/" + data["ID"])
          }
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate(){

     // Check  Mandatory Fields

     this.caretxDieticianProviderService.updateDieticianMealsReport(this.currentDailyFoodReport).subscribe({
       next: data => {
         this.toastrService.Sucesss("", this.toastrService.successMsg)
         this.currentDailyFoodReport = new DailyMealReport(data)
         if (this.currentDailyFoodReport.Status == 'C')
           this,this.isRecordClosed = true
           this.router.routeReuseStrategy.shouldReuseRoute = function () {
             return false;
           }
           if (!this.IsFromCentralizedPage) {
           this.router.onSameUrlNavigation = 'reload';
           this.router.navigateByUrl("caretexfile/" + this.personalId + "/"+this.BaseUrl +"/" + this.currentDailyFoodReport.ID)
           }
          },
       error: error => {
         this.toastrService.Error("", error.error)
       }
     })

  }

  selectTab(tabId: string) {
    this.CurrentStep = (tabId as DieticianAssessmentSteps)
  }

  initializeNewDailyReport(){
    this.currentDailyFoodReport = new  DailyMealReport()
    this.currentDailyFoodReport.Status = "A"
    this.currentDailyFoodReport.Meals = []
    this.currentDailyFoodReport.ID = "00000000-0000-0000-0000-000000000000"
    this.currentDailyFoodReport.CreatedById = this.authService.LoggedInUser.UID
    this.currentDailyFoodReport.TenantID = this.authService.LoggedInTenantID
    this.currentDailyFoodReport.CreatedAt =  new Date()
    this.currentDailyFoodReport.PersonelID = this.personalId
    this.currentDailyFoodReport.UpdatedAt = this.currentDailyFoodReport.CreatedAt

    // Create Meals
    this.breakfastMeal = new Meal(null, MealType.Morning , this.personalId , this.authService.LoggedInTenantID )
    this.breakfastMeal.CreatedAt = this.breakfastMeal.UpdatedAt = this.currentDailyFoodReport.CreatedAt
    this.currentDailyFoodReport.Meals.push(this.breakfastMeal)

    this.noonMeal =  new Meal(null, MealType.Noon, this.personalId , this.authService.LoggedInTenantID )
    this.noonMeal.CreatedAt = this.noonMeal.UpdatedAt = this.currentDailyFoodReport.CreatedAt
    this.currentDailyFoodReport.Meals.push(this.noonMeal)


    this.eveningMeal = new Meal(null, MealType.Evening, this.personalId , this.authService.LoggedInTenantID )
    this.eveningMeal.CreatedAt = this.eveningMeal.UpdatedAt = this.currentDailyFoodReport.CreatedAt
    this.currentDailyFoodReport.Meals.push(this.eveningMeal)
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  
  CheckIfDocumentIsDirty(){
    return false;
  }

  
  ReturnToCentralPage() {
    this.backToCentralPage.emit()
  }

}
