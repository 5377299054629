import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedicalDiagnosesProviderService } from 'src/app/caretx-doctors-module/doctor-services/medical-diagnoses.data-provider.service';
import { Diagnoses } from 'src/app/models/diagnoses';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';

@Component({
  selector: 'app-persondiagnoseslookup',
  templateUrl: './persondiagnoseslookup.component.html',
  styleUrls: ['./persondiagnoseslookup.component.css']
})
export class PersondiagnoseslookupComponent implements OnInit {
  personnelId: string
  @Input() FormTitle: String
  @Output() selectedDiagnosesEvent = new EventEmitter<Diagnoses[]>();


  DiagnosesList: Diagnoses[]
  selectedDiagnoses: Diagnoses[] = []
  
  constructor(
    private route: ActivatedRoute,
    private medicalDiagnosesService :MedicalDiagnosesProviderService,
    private sharedService: CaretxSharedModulesProviderService,) {
  }

  ngOnInit(): void {
    this.personnelId = this.route.parent.snapshot.params['id'];
    this.getPersonalDiagnosesFromDB();
  }


  getPersonalDiagnosesFromDB() {
    this.medicalDiagnosesService.getPersonelDiagnoses(this.personnelId).subscribe(
      data => {
        this.DiagnosesList = data
      },
      err => {
        this.DiagnosesList = []

      }
    )
  }

  closeModal() {
    this.selectedDiagnosesEvent.emit(this.selectedDiagnoses)
  }

 DiagnosesSelection(e,diagnoses) {

    let isFound  = this.checkIfListContainsUser(diagnoses.ID)
    if (e.currentTarget.checked) {
        // Check if it is already included in the list
       if (!isFound) {
          this.selectedDiagnoses.push(diagnoses)
       }
    }
    else{
        if (isFound){
          let objIndex = this.selectedDiagnoses.findIndex((e: Diagnoses) =>  e.ID == diagnoses.ID);
          this.selectedDiagnoses.splice(objIndex, 1);
        }
    }

  }


  checkIfListContainsUser(Id:string):boolean {
    const result = this.selectedDiagnoses.some(x => x.ID === Id)
    return result
  }
}
