import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import {  CartexEvent, TabHistory } from '../../../../../src/app/models/genericTypes';
import { BridgeEnums, DieticianAssessmentSteps, OptionObject } from '../../../services/enums';
import { DieticianAssessment } from '../../../models/dieticianassessment';
import { Subject } from 'rxjs';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { DrinkForm, EatingStatus, FeedForm, NutritionalStatus, SwallowStatus, TeethStatus, WeightStatus } from 'src/app/models/generalhousedsetups';
import { DieticianDataProviderService } from '../../Dietician-services/dietician.data-provider.service';
import Swal from 'sweetalert2';
import { DieticiankneeheightDialogComponent } from 'src/app/caretx-shared-module/CaretexModals/dieticiankneeheight-dialog/dieticiankneeheight-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dieticianassessment',
  templateUrl: './dieticianassessment.component.html',
  styleUrls: ['./dieticianassessment.component.css']
})
export class DieticianassessmentComponent extends CaretexComponent  implements OnInit {
  @ViewChild('mainform') mainFormElement: NgForm;
  moduleName: string = "הערכה תזונתית"
  currentDieticianAssessment: DieticianAssessment
//  CurrentStep: DieticianAssessmentSteps = DieticianAssessmentSteps.Problems
  isRecordClosed: boolean
  personalId: string
  recordid: string
  AssessmentResons: OptionObject[]
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  feedForms: FeedForm[] = []
  drinkforms: DrinkForm[] = []
  eatingstatus: EatingStatus[] = []
  teethstatus: TeethStatus[] = []
  nutritionalstatus: NutritionalStatus[] = []
  weightstatus: WeightStatus[] = []
  swallowstatus: SwallowStatus[] = []
  medicationTitle:string =""
  medicationtype:string=""
  tabHistory: TabHistory = new TabHistory()
  showActions:boolean = false
  caretexEvent: CartexEvent = new CartexEvent()

  step: number = 0


  constructor(
    private _title: Title,
    private router: Router,
    private _ar: ActivatedRoute,
    private bridgeEnum: BridgeEnums,
    public dialog: MatDialog,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private caretxDieticianProviderService: DieticianDataProviderService,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authService: AuthService,
    private route: ActivatedRoute,) {
    super(medicalFileService);
    bridgeEnum = new BridgeEnums()
    this.AssessmentResons = bridgeEnum.AssessmentReasons

    this.caretxSetupsProviderService.GetFeedForms("").subscribe((data => {
      this.feedForms = data
    }))

    this.caretxSetupsProviderService.GetDrinkForms("").subscribe((data => {
      this.drinkforms = data
    }))

    this.caretxSetupsProviderService.GetEatingStatus("").subscribe((data => {
      this.eatingstatus = data
    }))
    this.caretxSetupsProviderService.GetTeethStatus("").subscribe((data => {
      this.teethstatus = data
    }))
    this.caretxSetupsProviderService.GetNutritionalStatus("").subscribe((data => {
      this.nutritionalstatus = data
    }))
    this.caretxSetupsProviderService.GetWeightStatuses("").subscribe((data => {
      this.weightstatus = data
    }))
    this.caretxSetupsProviderService.GetSwallowStatus("").subscribe((data => {
      this.swallowstatus = data
    }))
  }

  ngOnInit(): void {
    this.personalId = this.route.parent.snapshot.params['id'];
    this.recordid = this.route.snapshot.params.recordid
    this.CheckIfComponentIsDisabled(this.personalId)
 
    if (this.route.snapshot.params['tabid'] != "" && this.route.snapshot.params['tabid'] != undefined) {
      this.step = Number(this.route.snapshot.params['tabid']); 
    }

    if (this.route.snapshot.params.recordid == 'new') {
      this.getDieticianAssessmentById(this.personalId, this.route.snapshot.params.recordid, "new")
    } else {
      this.getDieticianAssessmentById(this.personalId, this.route.snapshot.params.recordid, "")
    }
  }


  getDieticianAssessmentById(id: string, recordid: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + recordid + '\'' + ')'
    this.caretxDieticianProviderService.getDieticianAssessment(id, filter, mode,"1").subscribe((data: DieticianAssessment[]) => {
      if (data.length == 1) {
        this.currentDieticianAssessment = new DieticianAssessment(data[0])
        this.isRecordClosed = (this.currentDieticianAssessment.Status == "C")
   
         

          if (mode =="new") {
              this.currentDieticianAssessment.ValDate3 = new Date()
              this.currentDieticianAssessment.EvaluateDate1 = new Date()
              this.currentDieticianAssessment.EvaluateDate2 = new Date()

          }


      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }



  SaveRecord() {
    // In case Current
    this.eventsSubject.next(this.caretexEvent);
    if (this.currentDieticianAssessment.ID == "00000000-0000-0000-0000-000000000000" || this.currentDieticianAssessment.ID == "") {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }


  DoSave(){
    this.currentDieticianAssessment.LastUpdatedById = this.authService.LoggedInUser.UID
    this.caretxDieticianProviderService.createNewDieticianAssessment(this.currentDieticianAssessment).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentDieticianAssessment = new DieticianAssessment(data)
        if (this.currentDieticianAssessment.Status == 'C')
          this.isRecordClosed = true
          //retreive again the file
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
         }
        this.router.onSameUrlNavigation = 'reload';
        this.mainFormElement.reset(this.mainFormElement.value); 
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/dieticianassessment/" + this.currentDieticianAssessment.ID + "/step/" + this.step )
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })

  }

  DoUpdate(){
    // Check  Mandatory Fields
    this.currentDieticianAssessment.LastUpdatedById = this.authService.LoggedInUser.UID
    this.caretxDieticianProviderService.updateDieticianAssessment(this.currentDieticianAssessment).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentDieticianAssessment = new DieticianAssessment(data)
        if (this.currentDieticianAssessment.Status == 'C')
          this,this.isRecordClosed = true
          //retreive again the file
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
           return false;
          }
         this.router.onSameUrlNavigation = 'reload';
         this.mainFormElement.reset(this.mainFormElement.value); 
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/dieticianassessment/" + this.currentDieticianAssessment.ID + "/step/" + this.step )
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  lockRecord(){
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם את/ה רוצה לנעול הערכה ?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText:'לא',
      confirmButtonText: 'כן,תנעל',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentDieticianAssessment.Status = "C"
        this.currentDieticianAssessment.Closed_at = new Date()
        this.currentDieticianAssessment.ClosedById = this.authService.LoggedInUser.UID
        this.SaveRecord()

      }
    })
  }

  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/dieticianassessment/list")
  }

  setStep(index: number) {
    this.step = index;
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty ==null?false:this.mainFormElement.dirty
  }

  /************************************************************************** */

  calculateHeightByKnee() {

    let calcHeight : number
    let knee_height : number = this.currentDieticianAssessment.Val1
    let age :number  =  Number(this.medicalFile1.calculateAge())
    let isMale : boolean  = this.medicalFile1.Gender == "male" ? true : false

    if (this.currentDieticianAssessment.Opt1) {
      if (isMale) {
        calcHeight = 78.31 + (1.94 * knee_height) - (0.14 * age)
      }else {
        calcHeight = 82.21 + (1.85 *knee_height) -  (0.21  * age)
      }
      this.currentDieticianAssessment.Val2  = Math.round(calcHeight)
      this.calculateWeightInterval()
    }else{
      let below65 : boolean =false
      if (age < 65)   below65 = true
  
      const dialogRef = this.dialog.open(DieticiankneeheightDialogComponent, {
        data: {
          below65: below65,
          isMale:  isMale,
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
          let vals: string[] = result.split(":");
          this.currentDieticianAssessment.Val1 = ( Number(vals[1]) )
          this.currentDieticianAssessment.Val2 = ( Number(vals[0]) * 100 )
          this.calculateWeightInterval()
      });

    }

  }

  calculateBMI() {  
      var bmi = this.currentDieticianAssessment.Val3 / Math.pow(this.currentDieticianAssessment.Val2 / 100, 2)
      this.currentDieticianAssessment.Val4 = Number(bmi.toFixed(1))
  }

  calculateWeightInterval() {
    let age :number  =  Number(this.medicalFile1.calculateAge())
    let minBMI : number  = 23 
    let  maxBMI : number = 29.9

    if (age < 65) {
       let minBMI : number  = 19
       let  maxBMI : number = 24.9
    }
    this.currentDieticianAssessment.WeightInterval = Math.round(minBMI *  Math.pow( (this.currentDieticianAssessment.Val2 / 100) ,2))
    this.currentDieticianAssessment.WeightIntervalTo =  Math.round(maxBMI *  Math.pow( (this.currentDieticianAssessment.Val2 / 100) ,2))
  }



}
