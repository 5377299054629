import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HazardType } from 'src/app/models/hazard';
import { AuthService } from 'src/app/services/auth.service';
import { DialogResult } from 'src/app/services/enums';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';


@Component({
  selector: 'app-hazardstypes',
  templateUrl: './hazardstypes.component.html',
  styleUrls: ['./hazardstypes.component.css']
})
export class HazardstypesComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  hazardTypes: HazardType[] = []
  alertTitle: string
  currentRowIndex: number
  deleteSysMsgId: string
  deleteAlert: string

  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {  super(medicalFileServive)}


  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getHazardTypesFromDB()
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.hazardTypes.some(row => {
      return row.New || row.editMode
    })
    return  hospitalsLisChanged
  }

  getHazardTypesFromDB() {
    this.caretxSetupsProviderService.GetHazardTypes().subscribe(
      data => {
        this.hazardTypes = data
      },
      err => {
        console.log("Error", err)
        this.hazardTypes = []

      }
    )
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }
  updateHazardType($event) {
    this.caretxSetupsProviderService.CreateHazardTypes(this.hazardTypes).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getHazardTypesFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }
  addNewHazardType() {
    var newHazardType = new HazardType()
    newHazardType.New = true
    newHazardType.editMode = true
    newHazardType.CreatedAt = new Date()
    newHazardType.UpdatedAt = new Date()
    newHazardType.RealmID = this.authServive.LoggedInTenantID
    this.hazardTypes.unshift(newHazardType)
  }





  deleteRow(i){

    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.hazardTypes[i].New){
          this.caretxSetupsProviderService.DeleteHazardType(this.hazardTypes[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.hazardTypes.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.hazardTypes.splice(this.currentRowIndex, 1);
      }
    })
  }



 
  editRow(i) {
    this.hazardTypes[i].editMode = true
    this.hazardTypes[i].Modified = true
  }

  cancelEditRow(i) {
    this.hazardTypes[i].editMode = false;
    this.hazardTypes[i].Modified = false
  }



 
}
