import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { PersonalMeasures } from 'src/app/models/measure';
import { MedicalPersonelMeasuresProviderService } from '../../../caretx-doctors-module/doctor-services/medical-perosnelmeasures.data-provider.service';

@Component({
  selector: 'app-importantmedicalmeasures',
  templateUrl: './importantmedicalmeasures.component.html',
  styleUrls: ['./importantmedicalmeasures.component.css']
})

export class ImportantmedicalmeasuresComponent implements OnInit {
  id: string
  personelMeasures: PersonalMeasures[]
  @Output() closeImportanceMeasureModal = new EventEmitter<void>();
  constructor(
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private _ar: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this._ar.snapshot.params['id'];
    this.getEssentialMeasures()
  }

  getEssentialMeasures() {
    //Add Filter to get latest Measures . 
    this.caretxMedicalProviderService.getLatestPerosenelMeasuresById(this.id).subscribe(
      (data: PersonalMeasures[]) => {
        this.personelMeasures = []
        data.forEach(line => {
          this.personelMeasures.push(new PersonalMeasures(line))
        })
      },
      err => {
        console.log("Error", err)
        this.personelMeasures = []
      }
    )
  }

  closeModal() {
    this.closeImportanceMeasureModal.emit()
  }

  SetAlertcolor(measure: PersonalMeasures) {
    if (!measure.PersonalMeasureCode.HasRedLimit) return "rgb(31 60 78)"
    if (measure.NumericValue < measure.PersonalMeasureCode.LowerRedLimit || measure.NumericValue > measure.PersonalMeasureCode.UpperRedLimit)
      return "red"
    else
      return "rgb(31 60 78)"
  }
}
