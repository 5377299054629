import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { OccupationalTherapyAssessment } from '../../../models/occupationalTherapy';
import { OccupationalDataProviderService } from '../../ModuleServices/occupational.data-provider.service';
@Component({
  selector: 'app-occupation-assessment',
  templateUrl: './occupation-assessment.component.html',
  styleUrls: ['./occupation-assessment.component.scss']
})
export class OccupationAssessmentComponent extends CaretexComponent implements OnInit {

  currentAssessment: OccupationalTherapyAssessment
  @ViewChild('mainForm') mainFormElement: NgForm;
  isRecordClosed: boolean = false
  show: string = "section1"
  personalId: string
  step: number = 0
  moduleName:string = "הערכת ריפוי בעיסוק"
  DominantHand = [
    { id: 'R', name: 'יד ימין' },
    { id: 'L', name: 'יד שמאל' }
  ]

  FunctioningHand = [
    { id: 'R', name: 'יד ימין' },
    { id: 'L', name: 'יד שמאל' }
  ]

  constructor(
    private router: Router,
    public medicalFileService: MedicalFileProviderService,
    private _title: Title,
    private authService: AuthService,
    private toastrService: ToasterService,
    private caretxOccupationalProviderService: OccupationalDataProviderService,
    private route: ActivatedRoute,) { super(medicalFileService) }

  ngOnInit(): void {
    this._title.setTitle('Caretx | הערכת מטופל ריפוי בעיסוק');
    this.personalId = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.personalId)

    if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
      this.step = Number(this.route.snapshot.params['stepid']); 
    }
    if (this.route.snapshot.params.recordid == 'new') {
      this.getOccupationalAssessmentById(this.personalId, this.route.snapshot.params.recordid, "new")
    } else {
      this.getOccupationalAssessmentById(this.personalId, this.route.snapshot.params.recordid, "")
    }
  }

  getOccupationalAssessmentById(id: string, recordid: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + recordid + '\'' + ')'
    this.caretxOccupationalProviderService.getOccupationalAssessment(id, filter, mode).subscribe((data: OccupationalTherapyAssessment[]) => {
      if (data.length == 1) {
        this.currentAssessment = new OccupationalTherapyAssessment(data[0])
        this.isRecordClosed = (this.currentAssessment.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  SaveRecord() {
    if (this.currentAssessment.ID == "00000000-0000-0000-0000-000000000000" || this.currentAssessment.ID == "") {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    this.currentAssessment.LastUpdateById = this.authService.LoggedInUser.UID
    this.caretxOccupationalProviderService.createOccupationalAssessment(this.currentAssessment).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentAssessment = new OccupationalTherapyAssessment(data)
        if (this.currentAssessment.Status == 'C')
          this.isRecordClosed = true
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.mainFormElement.reset(this.mainFormElement.value); 
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.personalId + "/occupationaltherapyassessment/" + this.currentAssessment.ID + "/step/" + this.step  )

      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    // Check  Mandatory Fields
    this.currentAssessment.LastUpdateById = this.authService.LoggedInUser.UID
    this.caretxOccupationalProviderService.updateOccupationalAssessment(this.currentAssessment).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentAssessment = new OccupationalTherapyAssessment(data)
        if (this.currentAssessment.Status == 'C')
          this, this.isRecordClosed = true
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.personalId + "/occupationaltherapyassessment/" + this.currentAssessment.ID + "/step/" + this.step  )
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  lockRecord() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם את/ה רוצה לנעול הערכה ?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText:'לא',
      confirmButtonText: 'כן,תנעל',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentAssessment.Status = "C"
        this.currentAssessment.Closed_at = new Date()
        this.currentAssessment.ClosedById = this.authService.LoggedInUser.UID
        this.SaveRecord()
      }
    })
  }

  NewRecord() {
  }

  BackToMainDocument() {
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/occupationaltherapyassessment/list")
  }

  setStep(index: number) {
    this.step = index;
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty 
  }

  enableContent(type) {
    this.show = type;
  }
}
