import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../../src/app/shared/CaretexComponent';

@Component({
  selector: 'app-examslandingpage',
  templateUrl: './examslandingpage.component.html',
  styleUrls: ['./examslandingpage.component.css']
})
export class ExamslandingpageComponent extends CaretexComponent implements OnInit {
  id :string
  moduleName:string="אומדנים/מבחנים"
  constructor( 
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private router: Router,
    private route: ActivatedRoute,
    ) {
    super(medicalFileService);
  }

  ngOnInit(): void {
    this._title.setTitle('Caretx | אומדנים/מבחנים');
    if (this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
  }
  
  openbohseList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/bohse/list")
  }

  openOmdan2List(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/omdan2/list")
  }

  openSnaqList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/snaq/list")
  }

  openOmdan4List(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/omdan4/list")
  }

  openFimList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/fim/list")
  }

  openOmdan5List(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/omdan5/list")
  }
  
  openNortonList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/norton/list")
  }

  openDiabeticTestList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/diabetictests/list")
  }

  openPainTestList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/vasfps/list")
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }

}
