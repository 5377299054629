import { Component, Inject, OnInit } from '@angular/core';
import { User } from '../../../../../src/app/models/user';
import { UserRoles } from 'src/app/services/enums';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-userselection-dialog',
  templateUrl: './userselection-dialog.component.html',
  styleUrls: ['./userselection-dialog.component.css']
})
export class UserselectionDialogComponent implements OnInit {

  constructor(
          private sharedService: CaretxSharedModulesProviderService,
          private dialogRef: MatDialogRef<UserselectionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) 
          { dialogRef.disableClose = true;}

  users: User[]
  selectedUsers: User[] = []
  UserRole: UserRoles
  DialogTitle:string
  MultipleSelection:boolean = true
  ngOnInit(): void {

    this.UserRole = this.data.UserRole
    this.DialogTitle = this.data.DialogTitle
    this.MultipleSelection = this.data.MultipleSelection
    this.getUsers(this.UserRole);
  }

  getUsers(userRole: UserRoles) {
    let filter = '(role=' + '\'' + userRole + '\'' + ')'
    this.sharedService.getUsers(filter).subscribe(
      data => this.users = data,
      err => {
        console.log("Error", err)
        this.users = []

      }
    )
  }

  UserSelection(e,user) {

    let isFound  = this.checkIfListContainsUser(user.UID)
    if (e.currentTarget.checked) {
        // Check if it is already included in the list
       if (!isFound) {
          this.selectedUsers.push(user)
       }
    }
    else{
        if (isFound){
          let objIndex = this.selectedUsers.findIndex((e: User) => e.UID == user.UID);
          this.selectedUsers.splice(objIndex, 1);
        }
    }

  }


  SelectSingleUser(user) {

    this.dialogRef.close(user);

  }

  checkIfListContainsUser(UserId:string):boolean {
    const result = this.selectedUsers.some(x => x.UID === UserId)
    return result
  }

  submit(){
    this.dialogRef.close(this.selectedUsers);
  }

  closeModal(){
    
  }

}
