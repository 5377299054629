import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { TeekNote } from 'src/app/models/Teeknote';
import { ModalService } from 'src/app/modal/modal.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-defaultheader',
  templateUrl: './defaultheader.component.html',
  styleUrls: ['./defaultheader.component.css']
})
export class DefaultheaderComponent implements OnInit {
  TeekNotes: TeekNote[] = [];

  CurentUser:User
  removeAlertsCount = true
  removeMsgsCount = true
  selectedNote: TeekNote
  selectedNoteIndex: number
  TotalNumberOfNotes: Number
  newNote: TeekNote
  @ViewChild("newNoteText") newNoteText: ElementRef;
  removeNotesCount: boolean
  
  constructor( private router: Router,private authService: AuthService,private modalService: ModalService,private _customersService: CustomerService,) { 
    this.newNote = new TeekNote(false,"", "")
  }

  ngOnInit(): void {
    this.CurentUser = this.authService.LoggedInUser
    this.getUserNotesByID()
  }

  signOut() {


    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה לצאת מהמערכת ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.authService.SignOut()
      }
    })

    
    
  }

  OpenProfile(){
    this.router.navigateByUrl("/users_management/" + this.authService.LoggedInUser.UID)
  }



  getUserNotesByID() {
    let userid: string  = this.authService.LoggedInUser.UID
    this._customersService.getUserNotesById(userid).subscribe((data: TeekNote[]) => {
      this.TeekNotes = data;
      if (this.TeekNotes.length > 0) {
        this.selectedNote = this.TeekNotes[0]
        this.selectedNoteIndex = 1
        this.removeNotesCount = false
        this.TotalNumberOfNotes = this.TeekNotes.length
      }
      else {
        this.TotalNumberOfNotes = 0
        this.removeNotesCount = true
        this.modalService.close('teek-note-modal')
      }
    });
  }



  openNoteCards() {
    if (this.TeekNotes.length <= 0)
      this.openModal("new-teek-note-modal")
    else
      this.openModal('teek-note-modal')
  }

  openModal(id: string) {
    this.modalService.open(id);
  }



  showPrevNote() {

    --this.selectedNoteIndex
    if (this.selectedNoteIndex < 1)
      this.selectedNoteIndex = this.TeekNotes.length

    this.selectedNote = this.TeekNotes[this.selectedNoteIndex - 1]


  }

  showNextNote() {
    ++this.selectedNoteIndex
    if (this.selectedNoteIndex > this.TeekNotes.length)
      this.selectedNoteIndex = 1

    this.selectedNote = this.TeekNotes[this.selectedNoteIndex - 1]
  }

  onClickDeleteNote() {

    this._customersService.deleteTeekNoteById(this.selectedNote).subscribe(() => {
      this.getUserNotesByID()
    });
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }


  addNoteCards() {
    this.modalService.open("new-teek-note-modal")
    this.newNote = new TeekNote(false,"","")
    this.newNoteText.nativeElement.focus()
  }

  saveNewNote() {
    this.newNote.Created_at = this.newNote.LastUpdate_at = new Date()
    this.newNote.Status = "A"
    this.newNote.UserId = this.authService.LoggedInUser.UID
    this.newNote.IsFileNote = false

    this._customersService.addTeekNotesById(this.newNote).subscribe(() => {
      this.getUserNotesByID()
      this.closeModal("new-teek-note-modal")
    });

  }

  openNotificationPage(){
    Swal.fire({
      title: "הודעת מערכת",
      text: "שירות זה לא זמין כעת?",
      icon: 'warning',
      confirmButtonText: 'סגור',
      allowOutsideClick: false,
      allowEscapeKey: false
    })
  }

  openMessagesPage(){
    Swal.fire({
      title: "הודעת מערכת",
      text: "שירות זה לא זמין כעת?",
      icon: 'warning',
      confirmButtonText: 'סגור',
      allowOutsideClick: false,
      allowEscapeKey: false
    })
  }

}
