import { Component, OnInit } from '@angular/core';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';

import { NursePhysicalCheck } from 'src/app/models/nursingphysicalstatuses';

@Component({
  selector: 'app-nursephysicalstatuses-setup',
  templateUrl: './nursephysicalstatuses-setup.component.html',
  styleUrls: ['./nursephysicalstatuses-setup.component.css']
})
export class NursephysicalstatusesSetupComponent extends CaretexComponent implements OnInit {

  TabId: string = "main"
  checks: NursePhysicalCheck[] = []


  currentRowIndex: number
  title: string
  showDetails: boolean = false




  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) { super(medicalFileServive) }

  ngOnInit(): void {
    this.getMeasuresFromDB()
    //this.getMeasuresTypeFromDB()
  }


  AddNewRecord() {
    /* this.router.navigateByUrl("/essentialmeasures_settings/new" )*/
  }


  canDeactivate() {

    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    /*  const hospitalsLisChanged = this.measures.some(row => {
        return row.isNew || row.editMode
      })
      return hospitalsLisChanged*/
    return false
  }

  getMeasuresFromDB() {
    this.caretxSetupsProviderService.GetNursePhysicalChecks().subscribe(
      data => {
        this.checks = data

      },
      err => {
        this.checks = []

      }
    )
  }





  BackToList() {

    this.router.navigate(['system_setups']);
  }

  cancelEditRow(i) {
    this.checks[i].editMode = false
    this.showDetails = true
  }

  editRow(i) {
    this.currentRowIndex = i
    this.router.navigateByUrl("/physicalnursechecks_settings/" + this.checks[i].ID)
  }

  deleteRow(i) {
    /*   this.currentRowIndex = i
       Swal.fire({
         title: "הודעת מערכת",
         text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonText: 'כן,תמחק',
         allowOutsideClick: false,
         allowEscapeKey: false, 
         cancelButtonText:'לא'
       }).then((result) => {
         if (result.isConfirmed) {
           if (!this.measures[i].isNew) {
             this.caretxSetupsProviderService.DeleteEssentialMeasure(this.measures[this.currentRowIndex].ID).subscribe({
               next: data => {
                 this.measures.splice(this.currentRowIndex, 1);
                 this.toastrService.Sucesss("", this.toastrService.successMsg)
                 this.getMeasuresFromDB()
               },
               error: error => {
                 this.toastrService.Error("", error.error)
               }
             })
           }
           else
             this.measures.splice(this.currentRowIndex, 1);
         }
       })*/
  }
}
