import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';
import { ModelMapper } from '../../models/modelmapper';
import {  LabOrderCategories,  LabRequests, LabTemplate } from '../../models/testlabchecks';


@Injectable()
export class LabsProviderService {

  labCategories :Observable<LabOrderCategories[]> 

  constructor(private httpClient: HttpClient) {
  }




/*
*/

  getLaboratoryCheckTemplate(templateid: string): Observable<LabTemplate[]> {
    return this.httpClient.get(environment.gateway + '/laboratorytemplates/' + templateid).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(LabTemplate).map(item)
        })
      }), catchError(this.errorHandler));
  }

  saveApprovedLabTests(personelId:string , labRequest: LabRequests) {

    return this.httpClient.post(environment.gateway + '/laboratory/' + personelId + '/addlabrequest', labRequest)
  }


  updateApprovedLabTests(personelId:string , labRequest: LabRequests) {

    return this.httpClient.put(environment.gateway + '/laboratory/' + personelId + '/requests/'+labRequest.ID, labRequest)
  }

 
  /**************************************************************** */
  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }

}


