import { Component, OnInit } from '@angular/core';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';

@Component({
  selector: 'app-medicalexceptionaleventslist',
  templateUrl: './medicalexceptionaleventslist.component.html',
  styleUrls: ['./medicalexceptionaleventslist.component.css']
})
export class MedicalexceptionaleventslistComponent extends CaretexComponent  implements OnInit {

  baseUrl = "eventmedical"
  constructor(public medicalFileService: MedicalFileProviderService,) {
    super(medicalFileService);
  }

  ngOnInit(): void {
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty(){
    return false;
  }
}
