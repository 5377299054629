import { Component, OnInit } from '@angular/core';
import { sideNaveGroups } from 'src/app/services/enums';

@Component({
  selector: 'app-occupationanchor-component',
  templateUrl: './occupationanchor-component.component.html',
  styleUrls: ['./occupationanchor-component.component.css']
})
export class OccupationanchorComponentComponent implements OnInit {
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Physiotherapy;
  constructor() { }
  TabId:string = "medicalfiles"
  ngOnInit(): void {
  }

}
