import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, CanDeactivateGuard } from '../shared/guard/auth.guard';
import { BedSoresSettingsComponent } from './components/bed-sores-settings/bed-sores-settings.component';
import { BedSoreslocationsComponent } from './components/bed-soreslocations/bed-soreslocations.component';
import { BedsoresidesComponent } from './components/bedsoresides/bedsoresides.component';
import { BedsorestatusesComponent } from './components/bedsorestatuses/bedsorestatuses.component';
import { DepartmentssetupComponent } from './components/departmentssetup/departmentssetup.component';
import { DietarySupplementComponent } from './components/dietary-supplement/dietary-supplement.component';
import { DieticiandrinkformComponent } from './components/dieticiandrinkform/dieticiandrinkform.component';
import { DieticianeatingstatusesComponent } from './components/dieticianeatingstatuses/dieticianeatingstatuses.component';
import { DieticianfeedformComponent } from './components/dieticianfeedform/dieticianfeedform.component';
import { DieticianfoodadditionsComponent } from './components/dieticianfoodadditions/dieticianfoodadditions.component';
import { DieticiannutritionalstatusComponent } from './components/dieticiannutritionalstatus/dieticiannutritionalstatus.component';
import { DieticianswallowstatusComponent } from './components/dieticianswallowstatus/dieticianswallowstatus.component';
import { DieticianteethstatusComponent } from './components/dieticianteethstatus/dieticianteethstatus.component';
import { DieticianweightstatusComponent } from './components/dieticianweightstatus/dieticianweightstatus.component';
import { DruguomsComponent } from './components/druguoms/druguoms.component';
import { EssentialmeasuresComponent } from './components/essentialmeasures/essentialmeasures.component';
import { EssentialmeasuresdetailsComponent } from './components/essentialmeasures/essentialmeasuresdetails/essentialmeasuresdetails.component';
import { FamilyrelationsSetupComponent } from './components/familyrelations-setup/familyrelations-setup.component';
import { FundersetupComponent } from './components/fundersetup/fundersetup.component';
import { GeneralhousesetupComponent } from './components/generalhousesetup/generalhousesetup.component';
import { HazardriskComponent } from './components/hazardrisk/hazardrisk.component';
import { HazardsSettingsComponent } from './components/hazards-settings/hazards-settings.component';
import { HazardstypesComponent } from './components/hazardstypes/hazardstypes.component';
import { HospitalsComponent } from './components/hospitals/hospitals.component';
import { LabcategorytestsComponent } from './components/labtypes/labcategorytests/labcategorytests.component';
import { LabtypesComponent } from './components/labtypes/labtypes.component';
import { NursingstatusessetupComponent } from './components/nursingstatusessetup/nursingstatusessetup.component';
import { NusringinstructionssetupComponent } from './components/nusringinstructionssetup/nusringinstructionssetup.component';
import { ReferrersetupComponent } from './components/referrersetup/referrersetup.component';
import { SocialPocketCodesComponent } from './components/social-pocket-codes/social-pocket-codes.component';
import { SocialprovidersComponent } from './components/socialproviders/socialproviders.component';
import { EventtypesetupComponent } from './components/eventtypesetup/eventtypesetup.component';
import { WorkingshiftssetupComponent } from './components/workingshiftssetup/workingshiftssetup.component';
import { AmbulancessetupComponent } from './components/ambulancessetup/ambulancessetup.component';
import { EssentialMetricsTypesComponent } from './components/essential-metrics-types/essential-metrics-types.component';
import { ExceptionaleventlocationComponent } from './components/exceptionaleventlocation/exceptionaleventlocation.component';
import { BedsComponent } from './components/beds/beds.component';
import { ConsciousnessStateComponent } from './components/consciousness-state/consciousness-state.component';
import { MedicalfileSettingsComponent } from './components/medicalfile-settings/medicalfile-settings.component';
import { NursephysicalstatusesSetupComponent } from './components/nursephysicalstatuses-setup/nursephysicalstatuses-setup.component';
import { NursephysicalstatusesDetailsComponent } from './components/nursephysicalstatuses-setup/nursephysicalstatuses-details/nursephysicalstatuses-details.component';
import { NursephysicalstatusesDetails2Component } from './components/nursephysicalstatuses-setup/nursephysicalstatuses-details2/nursephysicalstatuses-details2.component';
import { PaintreatmentSetupComponent } from './components/paintreatment-setup/paintreatment-setup.component';
import { PainNatureSetupComponent } from './components/pain-nature-setup/pain-nature-setup.component';
import { PainSymptomsSetupComponent } from './components/pain-symptoms-setup/pain-symptoms-setup.component';
import { PainMitigatingFactorSetupComponent } from './components/pain-mitigating-factor-setup/pain-mitigating-factor-setup.component';
import { PainLocationSetupComponent } from './components/pain-location-setup/pain-location-setup.component';
import { PainLevelSetupComponent } from './components/pain-level-setup/pain-level-setup.component';
import { AdhesionPlacesComponent } from './components/adhesion-places/adhesion-places.component';
import { ContaminationSourcesComponent } from './components/contamination-sources/contamination-sources.component';
import { InfectionStatusesComponent } from './components/infection-statuses/infection-statuses.component';
import { IsolationTypesComponent } from './components/isolation-types/isolation-types.component';
import { ResistanceTypesComponent } from './components/resistance-types/resistance-types.component';
import { HospitalRoomComponent } from './components/hospital-room/hospital-room.component';
import { DedicatedHospitalizationTeamComponent } from './components/dedicated-hospitalization-team/dedicated-hospitalization-team.component';
import { PCRTestComponent } from './components/pcrtest/pcrtest.component';
import { HodgeTestComponent } from './components/hodge-test/hodge-test.component';
import { VaccinationsComponent } from './components/vaccinations/vaccinations.component';



const routes: Routes = [

  
  { 
    path: 'vaccinations_setup', 
    component: VaccinationsComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'druguom_setup', 
    component: DruguomsComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },


  { 
    path: 'medicalfile_settings', 
    component: MedicalfileSettingsComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  



  { 
    path: 'referrer_setup', 
    component: ReferrersetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'dietarysupplement_setup', 
    component: DietarySupplementComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },



  { 
    path: 'ambulances_setup', 
    component: AmbulancessetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },
  

  { 
    path: 'foodaddition_setup', 
    component: DieticianfoodadditionsComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'weightstatuses_setup', 
    component: DieticianweightstatusComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'drinkform_setup', 
    component: DieticiandrinkformComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'feedform_setup', 
    component: DieticianfeedformComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'nutritionalstatus_setup', 
    component: DieticiannutritionalstatusComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'teethstatus_setup', 
    component: DieticianteethstatusComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'swallowstatus_setup', 
    component: DieticianswallowstatusComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'eatingstatuses_setup', 
    component: DieticianeatingstatusesComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  
  { 
    path: 'nurseinstruction_setup', 
    component: NusringinstructionssetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'pockettrackingCode_setup', 
    component: SocialPocketCodesComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'house_settings', 
    component: GeneralhousesetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },
  { 
    path: 'departments_settings', 
    component: DepartmentssetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },
  { 
    path: 'familyrelations', 
    component: FamilyrelationsSetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  {path: 'socialproviders', 
  component: SocialprovidersComponent, 
  canActivate: [AuthGuard],
  canDeactivate: [CanDeactivateGuard],
  data:{mode:['setup']} 
},  
  { 
    path: 'funder_settings', 
    component: FundersetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']}  
  },
  { 
    path: 'nursingstatuses_settings', 
    component: NursingstatusessetupComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },
  { 
    path: 'hospitals_settings', 
    component: HospitalsComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'essentialmeasurestypes_settings', 
    component: EssentialMetricsTypesComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']}  
  },

  { 
    path: 'essentialmeasures_settings', 
    component: EssentialmeasuresComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']}  
  },
  { 
    path: 'essentialmeasures_settings/:id', 
    component: EssentialmeasuresdetailsComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']}  
  },



  { 
    path: 'physicalnursechecks_settings', 
    component: NursephysicalstatusesSetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']}  
  },
  { 
    path: 'physicalnursechecks_settings/:id', 
    component: NursephysicalstatusesDetailsComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']}  
  },
  { 
    path: 'physicalnursechecks_settings/:id/status/:statusid', 
    component: NursephysicalstatusesDetails2Component, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']}  
  },



  { 
    path: 'bedsores_types', 
    component: BedSoresSettingsComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },
  { 
    path: 'bedsores_locations', 
    component: BedSoreslocationsComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },
  { 
    path: 'bedsores_statuses', 
    component: BedsorestatusesComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },
  { 
    path: 'bedsores_sides', 
    component: BedsoresidesComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },


 /* { 
    path: 'hazards_settings', 
    component: HazardsSettingsComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },
  { 
    path: 'hazards_types', 
    component: HazardstypesComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },
  { 
    path: 'hazards_risks', 
    component: HazardriskComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },*/



  { 
    path: 'adhesionplaces', 
    component: AdhesionPlacesComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'contaminationsources', 
    component: ContaminationSourcesComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'infectionstatuses', 
    component: InfectionStatusesComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'isolationtypes', 
    component: IsolationTypesComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'resistancetypes', 
    component: ResistanceTypesComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'hospitaltoom', 
    component: HospitalRoomComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },
  { 
    path: 'hospitalizationteam', 
    component: DedicatedHospitalizationTeamComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },
  { 
    path: 'pcrtest', 
    component: PCRTestComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },
  { 
    path: 'hodgetest', 
    component: HodgeTestComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },










  { 
    path: 'labs_setup', 
    component: LabtypesComponent, 
    canActivate: [AuthGuard] , 
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'labstemplates/:hmo/:categoryId/:categoryDescr', 
    component: LabcategorytestsComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'eventtypes_settings', 
    component: EventtypesetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'eventlocation_setup', 
    component: ExceptionaleventlocationComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'beds_setup', 
    component: BedsComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },

  { 
    path: 'consciousnessstate_setup', 
    component: ConsciousnessStateComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']},  
  },


  { 
    path: 'workingshifts_settings', 
    component: WorkingshiftssetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },


  { 
    path: 'painlevel_setup', 
    component: PainLevelSetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'painlocation_settings', 
    component: PainLocationSetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'paintreatment_settings', 
    component: PaintreatmentSetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'mitigatingfactor_settings', 
    component: PainMitigatingFactorSetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'painsymptoms_settings', 
    component: PainSymptomsSetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },

  { 
    path: 'painnature_settings', 
    component: PainNatureSetupComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    data:{mode:['setup']} 
  },





  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaretxSetupsModuleRoutingModule { }
