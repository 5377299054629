import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { PersonalVaccination } from 'src/app/models/Vaccinations';
import { Vaccinations } from 'src/app/models/generalhousedsetups';
import { ToasterService } from 'src/app/services/toastr.service';
import { ModalService } from 'src/app/modal';
import { ActivatedRoute } from '@angular/router';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MatStepper } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CaretxNursingProviderService } from 'src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-vaccinationmodal',
  templateUrl: './vaccinationmodal.component.html',
  styleUrls: ['./vaccinationmodal.component.css']
})
export class VaccinationmodalComponent implements OnInit {

  id: string
  currentPersonalVaccine: PersonalVaccination
  currentRowNumClickedModal: number = 0
  IsDisabled: boolean = false
  ActionAllowed: boolean = false
  @ViewChild('f') mainFormElement: NgForm;
  IsNew: boolean = false


  newVaccine : Vaccinations = new Vaccinations()

  constructor(
    private caretxNursingService: CaretxNursingProviderService,
    private authService: AuthService,
    private caretxSetupsService: CaretxSetupsProviderService,
    private toastrService: ToasterService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<VaccinationmodalComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }


  ngOnInit(): void {
    this.id = this.data.id;
    this.currentPersonalVaccine = this.data.currentPersonalVaccine
    this.IsDisabled = this.data.IsDisabled
    this.ActionAllowed = this.data.ActionAllowed
    this.IsNew = this.data.IsNew
    this.getVaccinations()
  }



  /****************************Diagnoses Lookup*************************************** */

  observablefilteredVaccinations: Observable<Vaccinations[]>
  filteredVaccines: Vaccinations[]
  AllVaccines: Vaccinations[] = []
   searchTxt: string
  indecationFound: boolean
  myControl = new FormControl();
  showNotFoundMsg: boolean = false
  currentSelectedStep: number = 0
  @Output() diagnosesEvent = new EventEmitter<Vaccinations>();

  getVaccinations(name?:string) {

    this.caretxSetupsService.getVaccinations().subscribe(data => {
      this.AllVaccines = data
     
      this.filteredVaccines = this.AllVaccines.slice(0, 100)
      if (name != "" && name != undefined) {
        for (let i = 0; i < this.AllVaccines.length; i++) {
          if (this.AllVaccines[i].Name == name) {
            this.diagnosesEvent.emit(this.AllVaccines[i])
            this.currentPersonalVaccine.Vaccination = this.AllVaccines[i]
            this.currentPersonalVaccine.VaccinationId = this.AllVaccines[i].ID
            break
          }
        }
      }
    })
  }


  submitVaccineModal(vaccine: Vaccinations, stepper: MatStepper) {
    this.currentPersonalVaccine.Vaccination = vaccine
    this.openMainStep(stepper)
  }

  SearchVaccine($event) {
    this.searchTxt = $event
   this.DoSearch()
  }

  DoSearch(){
    if (this.searchTxt == "") {
      this.filteredVaccines = this.AllVaccines
    } else {
      this.filteredVaccines = this.AllVaccines.slice().filter((record: Vaccinations) => {
        return (record.Name.toLowerCase()).indexOf(this.searchTxt.toLowerCase()) !== -1;
      });
    }
    if (this.filteredVaccines.length > 0) {
      this.showNotFoundMsg = false
    } else {
      this.showNotFoundMsg = true
      this.newVaccine = new Vaccinations()
    }
  }

  AddNewVaccine(stepper: MatStepper) {
    this.newVaccine.New = true
    this.newVaccine.Name = this.searchTxt
    this.newVaccine.RealmID = this.authService.LoggedInTenantID
    this.caretxSetupsService.CreateUpdateVaccinations([this.newVaccine]).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.showNotFoundMsg = false
        this.getVaccinations(this.newVaccine.Name)
        stepper.previous()
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }


  checkIfDiagnosesExistsInDB() {

  }

  openCodesStep(stepper: MatStepper ,textForSearch:string) {
    stepper.next();
    this.searchTxt = textForSearch
    this.DoSearch()
  }

  openMainStep(stepper: MatStepper) {
    stepper.previous();
  }

  conevrtoDateTime($event) {
    return new Date($event)
  }


  submitForm() {
    this.mainFormElement.onSubmit(new Event("", undefined));
  }

  submit() {
    // Save Diagnoses
    if (this.IsNew) {
      this.caretxNursingService.CreatePersonalVaccinations(this.id, this.currentPersonalVaccine).subscribe({
        next: data => {
          this.toastrService.Sucesss("", this.toastrService.successMsg)
          this.dialogRef.close(data);
        },
        error: error => {
          this.toastrService.Error("", error.error)
        }
      })

    }else {
      this.caretxNursingService.UpdatePersonalVaccinations(this.id, this.currentPersonalVaccine).subscribe({
        next: data => {
          this.toastrService.Sucesss("", this.toastrService.successMsg)
          this.dialogRef.close(data);
        },
        error: error => {
          this.toastrService.Error("", error.error)
        }
      })

    }


  }

  selectionChange($event) {
    this.currentSelectedStep = $event.selectedIndex
  }


} 