import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, CanDeactivateGuard } from '../shared/guard/auth.guard';
import { MedicalfileComponent } from './components/medicalfile/medicalfile.component';
import { PersonelDocumentsComponent } from './components/personel-documents/personel-documents.component';
import { MedicalfileLockComponent } from './components/medicalfile-lock/medicalfile-lock.component';


const routes: Routes = [

  { 
    path: 'newmeidcalfile', 
    component: MedicalfileComponent, 
    data: { mode: ['newpage'] },
    canActivate: [AuthGuard] 
  },
  { path: 'medicalfiles/:id', component: MedicalfileComponent, canDeactivate: [CanDeactivateGuard],canActivate: [AuthGuard] },
  { path: 'medicalfiles/:id/:tabid', component: MedicalfileComponent, canDeactivate: [CanDeactivateGuard],canActivate: [AuthGuard] },
  { path: 'medicalfilelock/:id', component: MedicalfileLockComponent, canDeactivate: [CanDeactivateGuard],canActivate: [AuthGuard] },

 // { path: 'editmedicalfile/:id', component: MedicalfileComponent,  canDeactivate: [CanDeactivateGuard], canActivate: [AuthGuard] },
  //{ path: 'editmedicalfile/:id/:tabid', component: MedicalfileComponent, canDeactivate: [CanDeactivateGuard],canActivate: [AuthGuard] },
  //{ path: 'clients/:id/personeldocuments', component: PersonelDocumentsComponent, canDeactivate: [CanDeactivateGuard],canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CartexMedicalfileModuleRoutingModule { }
