import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BedSoreStatus } from 'src/app/models/generalhousedsetups';
import { AuthService } from 'src/app/services/auth.service';
import { DialogResult } from 'src/app/services/enums';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';

@Component({
  selector: 'app-bedsorestatuses',
  templateUrl: './bedsorestatuses.component.html',
  styleUrls: ['./bedsorestatuses.component.css']
})
export class BedsorestatusesComponent extends CaretexComponent implements OnInit {


  TabId:string = "main"

  bedsoreStatuses:BedSoreStatus[]  = []
  
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private router: Router,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {
      super(medicalFileServive)
     }

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getBedSoreStatusesFromDB()
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }
  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.bedsoreStatuses.some(row => {
      return row.New || row.editMode
    })
    return  hospitalsLisChanged
  }

  getBedSoreStatusesFromDB(){
    this.caretxSetupsProviderService.GetBedSoreStatuses().subscribe(
      data => {
        this.bedsoreStatuses = data
      },
      err => {
        console.log("Error", err)
        this.bedsoreStatuses = []

      }
    )
  }



  addNewSoreStatus(){
    var newBedSoreStatus = new BedSoreStatus()
    
    newBedSoreStatus.New = true
    newBedSoreStatus.editMode = true
    newBedSoreStatus.CreatedAt = new Date()
    newBedSoreStatus.UpdatedAt = new Date()
    newBedSoreStatus.RealmID = this.authServive.LoggedInTenantID
    this.bedsoreStatuses.unshift(newBedSoreStatus)
  }








  deleteRow(i){

    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.bedsoreStatuses[i].New){
          this.caretxSetupsProviderService.DeleteBedSoreStatus(this.authServive.LoggedInTenantID, this.bedsoreStatuses[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.bedsoreStatuses.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.bedsoreStatuses.splice(this.currentRowIndex, 1);
      }
    })



  
  }





  editRow(i){
    this.bedsoreStatuses[i].editMode = true
    this.bedsoreStatuses[i].Modified = true
  }

  cancelEditRow(i){
    this.bedsoreStatuses[i].editMode  = false;
    this.bedsoreStatuses[i].Modified = false
  }



  updateBedSoreStatus($event){
     this.caretxSetupsProviderService.CreateBedSoreStatuses(this.authServive.LoggedInTenantID,this.bedsoreStatuses).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getBedSoreStatusesFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })  


    
  }
}
