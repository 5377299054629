import { Component, OnInit } from '@angular/core';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { HodgeTest } from 'src/app/models/generalhousedsetups';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-hodge-test',
  templateUrl: './hodge-test.component.html',
  styleUrls: ['./hodge-test.component.css']
})
export class HodgeTestComponent extends CaretexComponent implements OnInit {

  TabId: string = "main"

  records: HodgeTest[] = []

  currentRowIndex: number
  deleteSysMsgId: string
  deleteAlert: string
  alertTitle: string
  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private router: Router,
    private authService: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {
    super(medicalFileServive)
  }

  ngOnInit(): void {
    this.getRecordsFromDB()
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  BackToList() {
    this.router.navigate(['system_setups']);
  }
  
  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.records.some(row => {
      return row.New || row.editMode
    })
    return hospitalsLisChanged
  }

  getRecordsFromDB() {
    this.caretxSetupsProviderService.GetHodgeTest().subscribe(
      data => {
        this.records = data
      },
      err => {
        console.log("Error", err)
        this.records = []

      }
    )
  }

  addNewRecord() {
    var newRecord = new HodgeTest()
    newRecord.New = true
    newRecord.editMode = true
    newRecord.CreatedAt = new Date()
    newRecord.UpdatedAt = new Date()
    newRecord.RealmID = this.authService.LoggedInTenantID
    this.records.unshift(newRecord)
  }

  deleteRow(i) {
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.records[i].New) {
          this.caretxSetupsProviderService.deleteResistanceTypes(this.records[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.records.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
          this.records.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i) {
    this.records[i].editMode = true
    this.records[i].Modified = true
  }

  cancelEditRow(i) {
    this.records[i].editMode = false;
    this.records[i].Modified = false
  }

  updateRecord($event) {
    this.caretxSetupsProviderService.CreateUpdateHodgeTest(this.records).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getRecordsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }
}
