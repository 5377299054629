import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { MedicalPersonelMeasuresProviderService } from 'src/app/caretx-doctors-module/doctor-services/medical-perosnelmeasures.data-provider.service';
import { MeasureCodeListValues, PersonalMeasureCode, PersonalMeasures } from 'src/app/models/measure';
import { AuthService } from 'src/app/services/auth.service';
import { BridgeEnums, OptionObject } from 'src/app/services/enums';



@Component({
  selector: 'app-frequent-measures',
  templateUrl: './frequent-measures.component.html',
  styleUrls: ['./frequent-measures.component.css']
})
export class FrequentMeasuresComponent implements OnInit {


  @Input() personalMeasures: PersonalMeasures[] = []
  @Input() admissionId: string
  @Input() admissionType: string
  @Input() isdocumentClosed: boolean
  @Output() personalMeasuresEvent = new EventEmitter<PersonalMeasures[]>();
  @Output() frequentMeasuresChanged = new EventEmitter<boolean>();
  id: string
  @ViewChild('mainform') mainFormElement: NgForm;
  UrinateMethods: OptionObject[]
  OxygenMethods: OptionObject[]

  constructor(
    private _title: Title,
    private authServive: AuthService,
    private route: ActivatedRoute,
    private medicalPersonelMeasuressService: MedicalPersonelMeasuresProviderService,
    private bridgeEnum: BridgeEnums,) {
    bridgeEnum = new BridgeEnums()
    this.UrinateMethods = bridgeEnum.UrinateMethods
    this.OxygenMethods = bridgeEnum.OxygenMethods
  }

  ngOnInit(): void {
    this._title.setTitle('Bridge CRM | מדדים חיוניים');
    this.id = this.route.parent.snapshot.params['id']
    if (this.personalMeasures == undefined || this.personalMeasures == null || this.personalMeasures.length == 0) {
      this.getPersonalMeasuresCodes()
    }
  }

  getPersonalMeasuresCodes() {
    this.medicalPersonelMeasuressService.getMeasuresCodes(this.id).subscribe(data => {
      this.initiateNewPersonalMeasures(data)
    });
  }

  initiateNewPersonalMeasures(data: PersonalMeasureCode[]) {

    data.forEach(element => {
      let newPersonalMeasure = new PersonalMeasures()
      newPersonalMeasure.PersonelID = this.id
      newPersonalMeasure.AdmissionId = this.admissionId
      newPersonalMeasure.AdmissionType = this.admissionType
      newPersonalMeasure.PersonalMeasureCode = element
      newPersonalMeasure.MeasureCodeId = element.ID
      newPersonalMeasure.UpdatedAt = new Date()
      newPersonalMeasure.UserId = this.authServive.LoggedInUser.UID
      newPersonalMeasure.User = this.authServive.LoggedInUser
      newPersonalMeasure.TenantID = this.authServive.LoggedInTenantID
      newPersonalMeasure.ListValue = new MeasureCodeListValues()
      this.personalMeasures.push(newPersonalMeasure)
    });

  }


  inputChangedEvent(measure: PersonalMeasures) {
    this.frequentMeasuresChanged.emit(this.mainFormElement.dirty)
    // calc BMI 
    if (measure.PersonalMeasureCode.CaretexCode == "weight" || measure.PersonalMeasureCode.CaretexCode == "height") {

      let weight: PersonalMeasures
      let height: PersonalMeasures

      this.personalMeasures.forEach(element => {
        if (element.PersonalMeasureCode.CaretexCode == "height")
          height = element
        else if (element.PersonalMeasureCode.CaretexCode == "weight")
          weight = element
      });


      for (let i = 0; i < this.personalMeasures.length ; i++) {
          if  ( this.personalMeasures[i].PersonalMeasureCode.CaretexCode == "bmi" ) {
            this.personalMeasures[i].NumericValue = Math.round((weight.NumericValue) / ((height.NumericValue / 100) *  (height.NumericValue / 100) ))
            break;
          }
      }




    }

  }
  newMeasure() {
    // Clean the current Document 
  }

  copyMeasure() {
  }

}
