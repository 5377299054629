import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FamilyRelations } from 'src/app/models/generalhousedsetups';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-familyrelations-setup',
  templateUrl: './familyrelations-setup.component.html',
  styleUrls: ['./familyrelations-setup.component.css']
})
export class FamilyrelationsSetupComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  familyRelations:FamilyRelations[]  = []
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {  super(medicalFileServive)}

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getFamilyRelationsFromDB()
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const relationLisChanged = this.familyRelations.some(row => {
      return row.New || row.Modified
    })
    return  relationLisChanged
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }

  getFamilyRelationsFromDB(){
    this.caretxSetupsProviderService.GetFamilyRelations().subscribe(
      data => {
        this.familyRelations = data
      },
      err => {
        console.log("Error", err)
        this.familyRelations = []

      }
    )
  }



  addNewFamilyRelation(){
    var newFunder = new FamilyRelations()
    
    newFunder.New = true
    newFunder.editMode = true
    newFunder.CreatedAt = new Date()
    newFunder.UpdatedAt = new Date()
    newFunder.RealmID = this.authServive.LoggedInTenantID
    newFunder.IsGlobal = false
    this.familyRelations.unshift(newFunder)
  }

  deleteRow(i) {
    this.currentRowIndex = i
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false, 
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.caretxSetupsProviderService.DeleteFamilyRelation(this.familyRelations[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.familyRelations.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
      })
  }

  editRow(i){
    this.familyRelations[i].Modified = true
    this.familyRelations[i].editMode = true
  }

  cancelEditRow(i){
    this.familyRelations[i].Modified  = false;
    this.familyRelations[i].editMode = false
  }

  updateFamilyRelation($event){

     this.caretxSetupsProviderService.CreateFamilyRelation(this.familyRelations).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getFamilyRelationsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })  


    
  }

}
