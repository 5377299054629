import { propertyMap } from './modelmapper'

export class Drug {

    @propertyMap('id')
    public id: string
    @propertyMap('DrugEngName')
    public DrugEngName: string
    @propertyMap('DrugHebName')
    public DrugHebName: string
    @propertyMap('DrugCode')
    public DrugCode: string
    @propertyMap('DrugType')
    public DrugType: string
    @propertyMap('DrugIndication')
    public DrugIndication: string
    @propertyMap('DrugBaseQty')
    public DrugBaseQty: number
    @propertyMap('DrugUsageForm')
    public DrugUsageForm: string
    @propertyMap('ShortTradeName')
    public ShortTradeName: string
    @propertyMap('LongTradeName')
    public LongTradeName: string
    @propertyMap('DrugForm')
    public DrugForm: string
    @propertyMap('QtyInPackage')
    public QtyInPackage: number
    @propertyMap('QtyOfServe')
    public QtyOfServe: number
    @propertyMap('GenericComp1Name')
    public GenericComp1Name: string
    @propertyMap('GenericComp2Name')
    public GenericComp2Name: string
    @propertyMap('GenericComp3Name')
    public GenericComp3Name: string
    @propertyMap('GenericComp1Qty')
    public GenericComp1Qty: number
    @propertyMap('GenericComp2Qty')
    public GenericComp2Qty: number
    @propertyMap('GenericComp3Qty')
    public GenericComp3Qty: number
    @propertyMap('GenericComp1UOM')
    public GenericComp1UOM: string
    @propertyMap('GenericComp2UOM')
    public GenericComp2UOM: string
    @propertyMap('GenericComp3UOM')
    public GenericComp3UOM: string
    @propertyMap('Cb1')
    public Cb1: boolean
    @propertyMap('Cb2')
    public Cb2: boolean
    @propertyMap('Cb3')
    public Cb3: boolean
    @propertyMap('Cb4')
    public Cb4: boolean
    @propertyMap('Cb5')
    public Cb5: boolean
    @propertyMap('Cb6')
    public Cb6: boolean
    @propertyMap('Cb7')
    public Cb7: boolean
    @propertyMap('Cb8')
    public Cb8: boolean
    @propertyMap('Cb9')
    public Cb9: boolean
    @propertyMap('Cb10')
    public Cb10: boolean
    @propertyMap('Cb11')
    public Cb11: boolean
    @propertyMap('Cb12')
    public Cb12: boolean
    @propertyMap('DoctorInstructions')
    public DoctorInstructions: string
    @propertyMap('SideEffects')
    public SideEffects: string
    @propertyMap('HospitalCode')
    public HospitalCode: string
    @propertyMap('ATC')
    public ATC: String
    @propertyMap('AcceptabledosageQty')
    public AcceptabledosageQty: number

    @propertyMap('CretedById')
    public CretedById: string


    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.id = data["id"]
            this.DrugEngName = data["DrugEngName"]
            this.DrugHebName = data["DrugHebName"]
            this.DrugCode = data["DrugCode"]
            this.DrugType = data["DrugType"]
            this.DrugIndication = data["DrugIndication"]
            this.DrugBaseQty = data["DrugBaseQty"]
            this.DrugUsageForm = data["DrugUsageForm"]
            this.ShortTradeName = data["ShortTradeName"]
            this.LongTradeName = data["LongTradeName"]
            this.DrugForm = data["DrugForm"]
            this.QtyInPackage = data["QtyInPackage"]
            this.QtyOfServe = data["QtyOfServe"]
            this.GenericComp1Name = data["GenericComp1Name"]
            this.GenericComp2Name = data["GenericComp2Name"]
            this.GenericComp3Name = data["GenericComp3Name"]
            this.GenericComp1Qty = data["GenericComp1Qty"]
            this.GenericComp2Qty = data["GenericComp2Qty"]
            this.GenericComp3Qty = data["GenericComp3Qty"]
            this.GenericComp1UOM = data["GenericComp1UOM"]
            this.GenericComp2UOM = data["GenericComp2UOM"]
            this.GenericComp3UOM = data["GenericComp3UOM"]
            this.Cb1 = data["Cb1"]
            this.Cb2 = data["Cb2"]
            this.Cb3 = data["Cb3"]
            this.Cb4 = data["Cb4"]
            this.Cb5 = data["Cb5"]
            this.Cb6 = data["Cb6"]
            this.Cb7 = data["Cb7"]
            this.Cb8 = data["Cb8"]
            this.Cb9 = data["Cb9"]
            this.Cb10 = data["Cb10"]
            this.Cb11 = data["Cb11"]
            this.Cb12 = data["Cb12"]
            this.DoctorInstructions = data["DoctorInstructions"]
            this.SideEffects = data["SideEffects"]
            this.HospitalCode = data["HospitalCode"]
            this.ATC = data["ATC"]
            this.AcceptabledosageQty = data["AcceptabledosageQty"]
            this.CretedById = data["CretedById"]

        }


    }

}



export class MedicalDiagnoses {
    @propertyMap('ID')
    public ID: string
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('Code')
    public Code: string
    @propertyMap('ShortDescription')
    public ShortDescription: string
    @propertyMap('IsGlobal')
    public IsGlobal: boolean
    @propertyMap('Modified')
    public Modified: boolean
    @propertyMap('New')
    public New: boolean

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.TenantID = data["TenantID"]
            this.Code = data["Code"]
            this.ShortDescription = data["ShortDescription"]
        }
    }

}