import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { BMIMeasure, IMonthlyBmiMeasure } from '../../../../../src/app/models/DieticianFollowup';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { DieticianDataProviderService } from '../../Dietician-services/dietician.data-provider.service';


@Component({
  selector: 'app-yearlyweightreport',
  templateUrl: './yearlyweightreport.component.html',
  styleUrls: ['./yearlyweightreport.component.css'],

})
export class YearlyweightreportComponent extends CaretexComponent implements OnInit {


  moduleName: string = "דוח משקל שנתי"
  personalId: string
  bmiMeasures: BMIMeasure[] = []
  monthlyBmiMeasures: BMIMeasure[] = []
  currentYear:string 
  currentMonth:string = ""
  showMonthTable:boolean = false
  dropdownSettings: any = {};
  selectedItems1 : any = {};
  yearsCollection: { id: number, name: string }[] = [];




  yearMonthsMeasures: { [id: number]: IMonthlyBmiMeasure; } =  {
    1: { Bmi: 0, name: "ינואר", Weight: 0 },
    2: { Bmi: 0, name: "פברואר", Weight: 0 },
    3: { Bmi: 0, name: "מרץ", Weight: 0 },
    4: { Bmi: 0, name: "אפריל", Weight: 0 },
    5: { Bmi: 0, name: "מאי", Weight: 0 },
    6: { Bmi: 0, name: "יוני", Weight: 0 },
    7: { Bmi: 0, name: "יולי", Weight: 0 },
    8: { Bmi: 0, name: "אוגוסט", Weight: 0 },
    9: { Bmi: 0, name: "ספטמבר", Weight: 0 },
    10: { Bmi: 0, name: "אוקטובר", Weight: 0 },
    11: { Bmi: 0, name: "נובמבר", Weight: 0 },
    12: { Bmi: 0, name: "דצמבר", Weight: 0 },
  };


  constructor(private _title: Title,
    private router: Router,
    private _ar: ActivatedRoute,
    public medicalFileService: MedicalFileProviderService,
    private caretxDieticianProviderService: DieticianDataProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private route: ActivatedRoute,) {   super(medicalFileService);}

  ngOnInit(): void {
    if(this.route.parent !=null)
    this.personalId = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.personalId)
    this.getYearlyBmiMeasures(this.personalId, "2022")

    for (let i = 2010; i < 2050; i++) {
        this.yearsCollection.push({id:i,name:i.toString()})
    }


    this.dropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "name",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.currentYear = new Date().getFullYear().toString()
    this.selectedItems1 = [{ id: new Date().getFullYear(), name: this.currentYear}]

  }



 
onYearSelect($event){
  this.currentYear = $event.name
  this.showMonthTable = false
  this.getYearlyBmiMeasures(this.personalId  ,  this.currentYear )

}

  getYearlyBmiMeasures(id: string, year: string) {
    this.caretxDieticianProviderService.getYearlyBmiMeasures(id, year).subscribe((data: BMIMeasure[]) => {
      this. ressetyearMonthsMeasures()
      this.bmiMeasures = data
      this.bmiMeasures.forEach(element => {

            let month = new Date(element.CreatedAt).getMonth() + 1
            this.yearMonthsMeasures[month].Bmi = element.BMI
            this.yearMonthsMeasures[month].Weight = element.Weight

        });

    });
  }


  ressetyearMonthsMeasures(){
    for (let [key, value] of Object.entries(this.yearMonthsMeasures)) {
      this.yearMonthsMeasures[key].Bmi = 0
      this.yearMonthsMeasures[key].Weight = 0
  } 
  }
  
  getAllMeasuresForMonth(month , monthName , year){
    this.currentMonth = monthName
    this.showMonthTable = true
    this.caretxDieticianProviderService.getMonthlyBmiMeasures(this.personalId, year,month).subscribe((data: BMIMeasure[]) => {
      this.monthlyBmiMeasures = data
    });
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }
}
