import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SetupslandingpageComponent } from '../caretx-setups-module/components/setupslandingpage/setupslandingpage.component';
import { ArchivedfilesListComponent } from '../cartex-medicalfile-module/components/archivedfiles-list/archivedfiles-list.component';
import { CustomersComponent } from '../cartex-medicalfile-module/components/medicalfiles-list/customer.component';
import { CaretexMessagesComponent } from './CaretexModals/caretex-messages/caretex-messages.component';
import { NotificationsComponent } from '../components/Common/notifications/notifications.component';
import { UserManagementComponent } from '../components/user-management/user-management.component';
import { UserinfoComponent } from '../components/user-management/userinfo/userinfo.component';
import { AuthGuard, CanDeactivateGuard } from '../shared/guard/auth.guard';
import { DrugDetailsComponent } from './CaretexSharedComponents/drug-details/drug-details.component';
import { DrugslistComponent } from './CaretexSharedComponents/drugslist/drugslist.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { FollowupsofalldepartmentsComponent } from './CaretexSharedComponents/followupsofalldepartments/followupsofalldepartments.component';
import { PatientsMedicationReviewComponent } from './CaretexSharedComponents/patients-medication-review/patients-medication-reviews.component';
import { PatientsMedicationReviewDetailComponent } from './CaretexSharedComponents/patients-medication-review/patients-medication-review-detail/patients-medication-review-detail.component';
import { PatientsListComponent } from './CaretexSharedComponents/patients-medication-review/patients-list/patients-list.component';
import { MedicalPrescriptionsComponent } from './CaretexSharedComponents/medical-prescriptions/medical-prescriptions.component';
import { NursemedicationreviewsComponent } from './CaretexSharedComponents/nursemedicationreviews/nursemedicationreviews.component';
import { HospitalizationslistComponent } from './CaretexSharedComponents/hospitalizationslist/hospitalizationslist.component';
import { HospitalizationDetailsComponent } from './CaretexSharedComponents/hospitalization-details/hospitalization-details.component';
import { ReportslandingpageComponent } from './CaretexReports/reportslandingpage/reportslandingpage.component';
import { CaretexReport1Component } from './CaretexReports/reportslandingpage/caretex-report1/caretex-report1.component';
import { CaretxCardixComponent } from '../caretx-cardix/caretx-cardix.component';

const routes: Routes = [
  { path: 'medfiles', component: CustomersComponent, canActivate: [AuthGuard] },
  { path: 'archivedfiles', component: ArchivedfilesListComponent, canActivate: [AuthGuard] },
  { path: 'users_management', component: UserManagementComponent, canActivate: [AuthGuard] },
  { path: 'users_management/:id', component: UserinfoComponent, canActivate: [AuthGuard] },
  { path: 'main', component: LandingpageComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'messages', component: CaretexMessagesComponent, canActivate: [AuthGuard] },

  { path: 'system_setups', component: SetupslandingpageComponent, canActivate: [AuthGuard] },
  { path: 'cardix', component: CaretxCardixComponent, canActivate: [AuthGuard] },  //loadChildren: () => import('./../caretx-cardix/caretx-cardix.module').then(m => m.CaretxTaskSchedulerModule) },
  { path: 'drugslist', component: DrugslistComponent, canActivate: [AuthGuard] },
  { path: 'followups', component: FollowupsofalldepartmentsComponent, canActivate: [AuthGuard] },
  
  { 
    path: 'newmedicaldrug', 
    component: DrugDetailsComponent, 
    data: { mode: ['newpage'] },
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard]
  },

  { path: 'medicationreviews', component: PatientsListComponent },


  
  { 
    path: ':id/pmreviews', 
    component: PatientsMedicationReviewComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard]
  },
  

  

  { 
    path: 'medicalprescriptions', component: MedicalPrescriptionsComponent 
  },
  { 
    path: 'medicalprescriptions/:recordid', component: MedicalPrescriptionsComponent 
  },

  { 
    path: 'nursemedicationreviews', component: NursemedicationreviewsComponent 
  }, 
    
  { 
    path: 'hospitalizations', component: HospitalizationslistComponent 
  }, 
  { 
    path: 'hospitalizations/:recordid', component: HospitalizationDetailsComponent 
  },




  { 
    path: ':id/pmreviewxref/:reviewid', 
    component: PatientsMedicationReviewDetailComponent, 
    canActivate: [AuthGuard] ,
    canDeactivate: [CanDeactivateGuard]
  },

  { path: 'drugslist/:id', component: DrugDetailsComponent, canActivate: [AuthGuard],
  canDeactivate: [CanDeactivateGuard] },



  { path: 'reports', component: ReportslandingpageComponent, canActivate: [AuthGuard] },
  { path: 'reports/:reportname', component: CaretexReport1Component, canActivate: [AuthGuard] },




  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedModuleRoutingModule { }
