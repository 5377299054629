import { Component, OnInit, ViewChild } from '@angular/core';
import { BridgeEnums, DieticianFollowupSteps, OptionObject } from '../../../../../src/app/services/enums';
import { BMIMeasure, DieticianFollowUp } from '../../../models/DieticianFollowup';
import { PagenationObject } from '../../../../../src/app/models/pagenation';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { DieticianDataProviderService } from '../../Dietician-services/dietician.data-provider.service';
import { Subject } from 'rxjs';
import { CartexEvent, TabHistory } from '../../../../../src/app/models/genericTypes';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { CaretxMedicalProviderService } from '../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { LabCheck, LabCheckHeader } from '../../../../../src/app/models/testlabchecks';
import { PersonalMeasures, PersonalMeasuresHeader } from '../../../../../src/app/models/measure';
import { CaretxSharedModulesProviderService } from '../../../../../src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { Attachment } from '../../../../../src/app/models/attachments';
import { DocViewerComponent } from 'src/app/caretx-shared-module/CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { AttachmentDialogComponent } from 'src/app/caretx-shared-module/CaretexModals/attachment-dialog/attachment-dialog.component';
import { DieticianDialogFollowupsubjectComponent } from 'src/app/caretx-shared-module/CaretexModals/dietician-dialog-followupsubject/dietician-dialog-followupsubject.component';
import { DrugInstruction } from 'src/app/models/drugInstruction';
import { Diagnoses } from 'src/app/models/diagnoses';
import { MedicalDiagnosesProviderService } from 'src/app/caretx-doctors-module/doctor-services/medical-diagnoses.data-provider.service';
import { CaretxNursingProviderService } from 'src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { BedSore } from 'src/app/models/bedsore';
import { MedicalObservation } from 'src/app/models/Observations';
import { OccupationalDataProviderService } from 'src/app/caretex-occupational-therapy/ModuleServices/occupational.data-provider.service';
import { OccupationalTherapyFollowUp } from 'src/app/models/occupationalTherapy';
import { NurseFollowUp } from 'src/app/models/nurseadmission';
import { CaretxPhsioTherapyProviderService } from 'src/app/cartex-physiotherapy-module/Physiotherapty.data-provider.service';
import { PhysioTherapyFollowUp } from 'src/app/models/physiotherapy';
import { BmiMeasureDialogComponent } from 'src/app/caretx-shared-module/CaretexModals/bmi-measure-dialog/bmi-measure-dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-dieticianfollowup',
  templateUrl: './dieticianfollowup.component.html',
  styleUrls: ['./dieticianfollowup.component.scss']
})
export class DieticianfollowupComponent extends CaretexComponent implements OnInit {


  show = 'tab1';


  moduleName: string = "מעקב תזונתי"
  FileCategoryList: OptionObject[] = [{ id: 'dieticianobsrvfile', name: 'מסמכי מעקב דאיטאנית' },]
  CurrentStep: DieticianFollowupSteps = DieticianFollowupSteps.Current
  currentDieticianFollowUp: DieticianFollowUp
  followups: DieticianFollowUp[] = []
  pagenationObject: PagenationObject
  personalId: string
  isFollowUpClosed: boolean = false
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  tabHistory: TabHistory = new TabHistory()
  caretexEvent: CartexEvent = new CartexEvent()
  saveProcessCompleted: boolean
  @ViewChild('mainForm') mainForm: NgForm;
  //@ViewChild('sectionAForm2') sectionAForm2: NgForm;
  LabCodeResults: LabCheck[]
  LastCreatinineVal: string = "-"
  HeightMeasure: PersonalMeasures
  WeightMeasure: PersonalMeasures
  step: number = 0
  pdfContent: any
  LoggedInUser: string
  selectedHistoricReport: DieticianFollowUp

  /**************New Params************* */
  newRecord: boolean = false
  public editorDisabled = true;
  medicationtype: string = ""
  drugInstructions: DrugInstruction[] = []
  diagnoses: Diagnoses[] = []
  personMeasureHeaders: PersonalMeasuresHeader[] = []
  checkHeaders: LabCheckHeader[];
  showMeasuresDetails: boolean = false
  showLabResult: boolean = false
  currentPersonelMeasureHeader: PersonalMeasuresHeader
  CurrentLabResultId: string
  bedSores: BedSore[] = []
  expandDieticianHistoricReport: boolean = false
  selectedMedicalHistoricReport: MedicalObservation
  expandMedicalHistoricReport: boolean = false

  expandNursingHistoricReport: boolean = false
  selectedNursingHistoricReport: NurseFollowUp

  expandPhsioHistoricReport: boolean = false
  selectedPhysioHistoricReport: PhysioTherapyFollowUp

  expandOccupationalHistoricReport: boolean = false
  selectedOccupationalHistoricReport: OccupationalTherapyFollowUp


  public config: any = {
    airMode: false,
    tabDisable: true,
    height: '50px',
    toolbar: [
    ],
  };
  public config2: any = {
    airMode: false,
    tabDisable: true,
    height: '500px',
    toolbar: [
    ],
  };

  /************************************* */

  constructor(
    private _title: Title,
    private router: Router,
    private _ar: ActivatedRoute,
    private bridgeEnum: BridgeEnums,
    public dialog: MatDialog,
    private caretxOccupationalProviderService: OccupationalDataProviderService,
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private caretxMedicalService: CaretxMedicalProviderService,
    private caretxDieticianProviderService: DieticianDataProviderService,
    public medicalFileService: MedicalFileProviderService,
    private medicalDiagnosesService: MedicalDiagnosesProviderService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private phsiotheraptyService: CaretxPhsioTherapyProviderService,
    private toastrService: ToasterService,
    private authService: AuthService,
    private route: ActivatedRoute,) {
    super(medicalFileService);
    this.LoggedInUser = this.authService.LoggedInUser.UID
  }

  ngOnInit(): void {
    this.personalId = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.personalId)
    if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
      this.step = Number(this.route.snapshot.params['stepid']);
    }
    // Get Total Number of records :
    this.getTotalNumOfFollowUpsFromDB()
    this.getDieticianFollowUpById(this.personalId, "", "new")
    this.getFollowUpListFromDB("1")
    this.getDrugInstrFromDB("")
    this. refreshBmiMeasures()

  }

  openNewFollowUpReport() {
    const dialogRef = this.dialog.open(DieticianDialogFollowupsubjectComponent, {
      data: {
        id: this.personalId,
        dieticianFollowUp: this.currentDieticianFollowUp,
        isDisabled: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined || result == null || result == "") return
      this.newRecord = true
    });
  }
  cancelNewFollowUpReport() {
    this.newRecord = false
  }
  getFollowUpListFromDB(page: string) {
    this.caretxDieticianProviderService.getDieticianFollowUps(this.personalId, "", "list", page).subscribe((data: DieticianFollowUp[]) => {
      if (data.length > 0) {
        this.followups = data
      }
      else {
        this.followups = []
      }
    });
  }
  getTotalNumOfFollowUpsFromDB() {
    this.caretxDieticianProviderService.getTotalOfDieticianFollowups(this.personalId).subscribe((data: number) => {
      this.pagenationObject = new PagenationObject(data)
    });
  }
  getDieticianFollowUpById(id: string, followupid: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + followupid + '\'' + ')'

    this.caretxDieticianProviderService.getDieticianFollowUps(id, filter, mode, "1").subscribe((data: DieticianFollowUp[]) => {
      if (data.length == 1) {
        this.currentDieticianFollowUp = new DieticianFollowUp(data[0])
        this.isFollowUpClosed = (this.currentDieticianFollowUp.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }
  getDrugInstrFromDB(filter: string) {
    this.caretxMedicalService.getDrugInstrucs(this.personalId, filter).subscribe(
      data => {
        this.drugInstructions = data
      },
      err => {
        this.drugInstructions = []
      }
    )
  }
  getPersonalMeasureHeadersFromDB(filter: string) {
    this.caretxNursingProviderService.getAllPersonalMeasureHeaders(this.personalId, "all", "", "list", filter).subscribe(
      data => {
        this.personMeasureHeaders = data
      },
      err => {
        this.personMeasureHeaders = []

      }
    )
  }
  getPersonalDiagnosesFromDB() {
    this.medicalDiagnosesService.getPersonelDiagnoses(this.personalId).subscribe(
      data => {
        this.diagnoses = data
      },
      err => {
        this.diagnoses = []

      }
    )
  }
  getBedSoresFromDB(filter: string) {
    this.caretxNursingProviderService.GetBedSores(this.personalId, filter, "list").subscribe(
      data => {
        this.bedSores = data
      },
      err => {
        this.bedSores = []

      }
    )
  }
  getLaboratortCheckHeaders() {
    this.caretxMedicalService.getLaboratoryResultsHeaders(this.personalId, null).subscribe(
      data => {
        this.checkHeaders = data
      },
      err => {
        console.log("Error", err)
        this.checkHeaders = []
      }
    )
  }
  medicalFollowups: MedicalObservation[] = []
  getMedicalFollowUpListFromDB(page: string) {
    this.caretxMedicalService.getMedicalObservations("", this.personalId, "list", page).subscribe((data: MedicalObservation[]) => {
      if (data.length > 0) {
        this.medicalFollowups = data
      }
      else {
        this.medicalFollowups = []
      }
    });
  }
  Occupationalfollowups: OccupationalTherapyFollowUp[] = []
  getOccupationalFollowUpListFromDB(page: string) {
    this.caretxOccupationalProviderService.getOccupationalFollowUps(this.personalId, "", "list", page).subscribe((data: OccupationalTherapyFollowUp[]) => {
      if (data.length > 0) {
        this.Occupationalfollowups = data
      }
      else {
        this.Occupationalfollowups = []
      }
    });
  }
  NurseFollowups: NurseFollowUp[] = []
  getNurseFollowUpListFromDB(page: string) {
    this.caretxNursingProviderService.getNurseFollowUps(this.personalId, "", "list", page).subscribe((data: NurseFollowUp[]) => {
      if (data.length > 0) {
        this.NurseFollowups = data

      }
      else {
        this.NurseFollowups = []
      }
    });
  }
  phsiotheraptyFollowups: PhysioTherapyFollowUp[] = []
  getPhysioFollowUpListFromDB(page: string) {
    this.phsiotheraptyService.getPhysioTherapyFollowUps(this.personalId, "", "list", page).subscribe((data: PhysioTherapyFollowUp[]) => {
      if (data.length > 0) {
        this.phsiotheraptyFollowups = data
      }
      else {
        this.phsiotheraptyFollowups = []
      }
    });
  }


  SaveFollowUp() {
    this.currentDieticianFollowUp.LastUpdateById = this.authService.LoggedInUser.UID
    this.currentDieticianFollowUp.UpdatedAt = this.currentDieticianFollowUp.CreatedAt
    this.DoSave()
  }

  DoSave() {

    this.caretxDieticianProviderService.createNewDieticianFollowUp(this.currentDieticianFollowUp).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentDieticianFollowUp = data as DieticianFollowUp
        if (this.currentDieticianFollowUp.Status == 'C')
          this.isFollowUpClosed = true
        this.saveProcessCompleted = true;
        this.medicalFileService.ResetCachedMedicalFile(this.personalId)
        this.resetModuleAfterSave()
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.personalId + "/dieticianfollowup/step/" + this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
        this.saveProcessCompleted = true;
      }
    })
  }

  setStep(index: number) {
    this.step = index;
    if (this.step == 3) {
      this.getLastXMeasuresOfMeasureCodeY()
      this.getPersonMeasured("height")
      this.getPersonMeasured("weight")
    }
  }

  HandlePagenation($event) {
    this.getFollowUpListFromDB($event)
  }

  CheckIfDocumentIsDirty() {

    if (!this.isRowExpanded && this.newRecord)
      return this.mainForm.dirty
    else
      return false
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  resetModuleAfterSave() {
    this.mainForm.reset(this.mainForm.value);

  }

  // get CREATININE values
  getLastXMeasuresOfMeasureCodeY() {
    let testName: string = "CREATININE"
    let filter = '(test_name=' + '\'' + testName + '\'' + ')'
    this.caretxMedicalService.getLabCodeResults(this.personalId, filter).subscribe(
      (data: LabCheck[]) => {
        this.LabCodeResults = []


        data.forEach(labResult => {
          this.LabCodeResults.push(new LabCheck(labResult))
        })

        if (this.LabCodeResults.length > 0) {
          this.LastCreatinineVal = this.LabCodeResults[0].TestValue.toString() + " " + this.LabCodeResults[0].LabTemplate.Uom
        }

      },
      err => {
        console.log("Error", err)
        this.LabCodeResults = []

      }
    )
  }

  getPersonMeasured(code: string) {
    this.caretxDieticianProviderService.getPersonMeasures(this.personalId, code).subscribe(
      (data: PersonalMeasures) => {

        if (code == "height") {
          this.HeightMeasure = data
          this.currentDieticianFollowUp.NewBmiMeasure.Height = this.HeightMeasure.NumericValue
        }

        if (code == "weight") {
          this.WeightMeasure = data
          this.currentDieticianFollowUp.NewBmiMeasure.Weight = this.WeightMeasure.NumericValue
        }

      },
      err => {
        console.log("Error", err)
        this.LabCodeResults = []

      }
    )
  }

  CopyLastReportedFollowUp() {
    if (this.followups.length > 0){
      this.currentDieticianFollowUp.RichText = this.followups[0].RichText
    }
  }

  ClearTextField() {
    this.currentDieticianFollowUp.RichText = ""
  }

  AttachFile() {
    const dialogRef = this.dialog.open(AttachmentDialogComponent, {
      data: {
        UserId: this.LoggedInUser,
        FileCategoryList: this.FileCategoryList,
        Id: this.personalId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Save Diagnoses
      if (result == undefined || result == null || result == "") return
      this.updateRefreshedObservFiles()
    });
  }

  updateRefreshedObservFiles() {
    let filter: string = "file_category = 'dieticianobsrvfile'"
    this.caretxSharedlService.getAttachments(this.personalId, filter).subscribe((data: Attachment[]) => {
      if (data.length > 0) {
        this.currentDieticianFollowUp.DieticianObservationFiles = data
      }
      else {
        // this.followups = []
      }
    });
  }

  downloadFile(file: Attachment) {
    const dialogRef = this.dialog.open(DocViewerComponent, {
      data: {
        Type: 'GF', // General File
        Id: this.personalId,
        Attachment: file
      }
    });
  }

  enableContent(type) {

    switch (type) {
      case "tab1":
        this.getDrugInstrFromDB("")
        break
      case "tab2":
        this.getPersonalDiagnosesFromDB()
        break
      case "tab3":
        this.getPersonalMeasureHeadersFromDB("")
        break
        case "tab3.1":
          this.refreshBmiMeasures()
          break
      case "tab4":
        this.getBedSoresFromDB("")
        break
      case "tab5":
        this.getLaboratortCheckHeaders()
        break
      case "tab6":
        this.getNurseFollowUpListFromDB("1")
        break
      case "tab7":
        this.getMedicalFollowUpListFromDB("1")
        break
      case "tab8":
        break
      case "tab9":
        this.getPhysioFollowUpListFromDB("1")
        break
      case "tab10":
        this.getOccupationalFollowUpListFromDB("1")
        break
    }
    this.show = type;
  }

  OnSDrillToLabResult($event, checkHeader) {

    this.CurrentLabResultId = checkHeader.ID
    this.showLabResult = true
  }

  closeLabRecord() {
    this.showLabResult = false
  }

  OnSDrillToeventDetails($event, measureHeader) {
    this.currentPersonelMeasureHeader = measureHeader
    this.showMeasuresDetails = true
  }

  closeMeasureRecord() {
    this.showMeasuresDetails = false
  }


  closeExpandedDocument() {
    switch (this.show) {
      case "tab6":
        this.expandNursingHistoricReport = false
        break
      case "tab7":
        this.expandMedicalHistoricReport = false
        break
      case "tab9":
        this.expandPhsioHistoricReport = false
        break
      case "tab10":
        this.expandOccupationalHistoricReport = false
        break
    }
    this.expandDieticianHistoricReport = false
    this.isRowExpanded = false
  }


  isRowExpanded: boolean = false

  expandHistoricReportRow(type, record) {

    switch (type) {
      case "dietician":
        this.selectedHistoricReport = record
        this.expandDieticianHistoricReport = true
        break
      case "nurse":
        this.selectedNursingHistoricReport = record
        this.expandNursingHistoricReport = true

        break
      case "medical":
        this.selectedMedicalHistoricReport = record
        this.expandMedicalHistoricReport = true

        break
      case "phsio":
        this.selectedPhysioHistoricReport = record
        this.expandPhsioHistoricReport = true

        break
      case "Occupational":
        this.selectedOccupationalHistoricReport = record
        this.expandOccupationalHistoricReport = true

        break
    }
    this.isRowExpanded = true


  }

  /**************************** */

  BmiMeasures: BMIMeasure[] = []
  LatestBmiMeasure : BMIMeasure
  refreshBmiMeasures() {

    this.caretxMedicalService.getBMIMeasure(this.personalId , "").subscribe(
      data => {
        this.BmiMeasures = data
        this.LatestBmiMeasure = this.BmiMeasures[0]
      },
      err => {
        this.BmiMeasures = []
      }
    )

  }

  openNewBMIMeasure() {
   
    let bmiMeasure : BMIMeasure
    bmiMeasure = new BMIMeasure()
    bmiMeasure.CreatedAt = bmiMeasure.UpdatedAt =  new Date()
    bmiMeasure.CreatedById = this.authService.LoggedInUser.UID
    bmiMeasure.TenantID = this.authService.LoggedInTenantID
    bmiMeasure.PersonelID = this.personalId

    const dialogRef = this.dialog.open(BmiMeasureDialogComponent, {
      data: {
        personelid: this.personalId,
        bmiMeasure:  bmiMeasure,
        isDisabled: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshBmiMeasures()
      if (result == undefined || result == null || result == "") return
      this.refreshBmiMeasures()
    });


  }
}
