import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LabOrderCategories } from 'src/app/models/testlabchecks';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-labtypes',
  templateUrl: './labtypes.component.html',
  styleUrls: ['./labtypes.component.css']
})
export class LabtypesComponent extends CaretexComponent implements OnInit {
  @ViewChild('savevalidator') savevalidator: ElementRef;
  labs: LabOrderCategories[] = []
  currentRowIndex: number
  modalTarget: string
  TabId:string = "main"
  hmoObject: any = { "id": "clalit", "name": "ק\"\ח כללית" }
  hmos: { id: string, name: string }[] = [
    { "id": "clalit", "name": "ק\"\ח כללית" },
    { "id": "macabi", "name": "ק\"\ח מכבי" },
    { "id": "leumit", "name": "ק\"\ח ליאומית" },
    { "id": "meohedet", "name": "ק\"\ח מאוחדת" }
  ];

  constructor(
    private router: Router,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService) {  super(medicalFileServive)
    }

  ngOnInit(): void {
    this.modalTarget = "#systemAlertModal"
    this.getLabTypesFromDB()
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  compareObject(item1, item2) {
    return item1 && item2 ? item1.id === item2.id : item1 === item2;
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }
  getLabTypesFromDB() {
    this.caretxSetupsProviderService.GetLabTypes(this.hmoObject.id).subscribe(
      data => {
        this.labs = data
      },
      err => {
        this.labs = []

      }
    )
  }

  updateLabTypes() {
    if (!this.validateBeforeSave()) {
      this.caretxSetupsProviderService.CreateUpdateCategoryLabs(this.labs).subscribe({
        next: data => {

          this.toastrService.Sucesss("", this.toastrService.successMsg)
          this.getLabTypesFromDB()
        },
        error: error => {
          this.toastrService.Error("", this.toastrService.errorMsg)
        }
      })
    }else{
      this.savevalidator.nativeElement.click()
    }
  }

  addLabType() {
    var newLabType = new LabOrderCategories()
    newLabType.New = true
    newLabType.Modified = false
    newLabType.editMode = true
    newLabType.HMO = this.hmoObject.id
    newLabType.TenantID = this.authServive.LoggedInTenantID
    this.labs.unshift(newLabType)
  }

  openLabTestsPage(i) {
    this.router.navigateByUrl("labstemplates/" + this.hmoObject.id + "/" + this.labs[i].CategoryId + "/" + this.labs[i].CategoryDescr)
  }


  deleteRow(i){

    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.labs[i].New){
       
        }
        else
        this.labs.splice(this.currentRowIndex, 1);
      }
    })
  }



 
  editRow(i) {
    this.labs[i].editMode = true
    this.labs[i].Modified = true
  }

  cancelEditRow(i) {
    this.labs[i].editMode = false
    this.labs[i].Modified = false
  }

  onChange($event) {
    this.getLabTypesFromDB()
  }



  CheckIfDocumentIsDirty() {
    const labtypeDirty = this.labs.some(row => {
      return row.New || row.Modified
    })
    return labtypeDirty
  }

  validateBeforeSave() {
    const labtypeDirty = this.labs.some(row => {
      if (row.CategoryDescr == undefined || row.CategoryDescr == ""){
          row.HasError = true
          return true
      }
    })
    return labtypeDirty
  }

  showValidationError(){

    Swal.fire({
      title: "הודעת מערכת",
      text: "אתה צריך למלאה את כל השדות שצבועות באדום!",
      icon: 'warning',
      allowOutsideClick: false,
      allowEscapeKey: false, 
    })

  }
}
