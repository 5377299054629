import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSocialProviderService } from 'src/app/caretx-social-module/Social.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { FamilyVisit } from 'src/app/models/socialfamilyvisits';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-familyvisitslist',
  templateUrl: './familyvisitslist.component.html',
  styleUrls: ['./familyvisitslist.component.css']
})
export class FamilyvisitslistComponent extends CaretexComponent implements OnInit {

  @Input() Origin :string
  
  familyVisits:FamilyVisit[] = []
  id:string
  moduleName:string = "רשימת ביקורי משפחה"
  currentRowIndex:number
  formData : any[] 
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), //, [Validators.required, Validators.email]),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0

  RecordStatus = [{id:"A" , desc:"רשומה פעילה"},{id:"C" , desc:"רשומה נעולה"}]

  constructor(private _title: Title,
    public  medicalFileService: MedicalFileProviderService,
    private router: Router,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,
    private authServive: AuthService) {
      super(medicalFileService)



  }

  ngOnInit(): void {
    this._title.setTitle('Caretex |ביקור משפחה');
    if(this.route.parent !=null)
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id) 
    this.getFamilyVisitsFromDB("")
    this.formData=  [
      {"formcontrolname":"status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
      {"formcontrolname":"created_at_from","label":"מתאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"created_at_to","label":"עד תאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_from","label":"תאריך נעילה מ" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_to","label":"תאריך נעילה עד" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},

    ]
  }


  getFamilyVisitsFromDB(filter:string) {
    this.caretxSocialProviderService.getFamilyVisits(this.id,filter , "list").subscribe(
      data => {
        this.familyVisits = data
        this.totalNumOfRecords = data.length},
      err => {
        this.familyVisits = []
      }
    )
  }

 
  SearchWithFilter($event) {
    this.getFamilyVisitsFromDB($event)
  }

  AddNewFamilyVisit($event){
    if (this.Origin == "nurse" ){
      this.router.navigateByUrl("caretexfile/" + this.id + "/nursefamilyvisit/new" )
    }else{
      this.router.navigateByUrl("caretexfile/" + this.id + "/familyvisits/new")
    }
  }

  deleteRow(i){
    this.currentRowIndex = i
    if (this.familyVisits[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,       
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteVisit(this.familyVisits[this.currentRowIndex])
        }
      })
    }
  }


  deleteVisit(visit: FamilyVisit) {
    let filter = '(id=' + '\'' + visit.ID + '\'' + ')'
    this.caretxSocialProviderService.deleteFamilyVisit( filter,this.id).subscribe({
      next: data => {
        this.getFamilyVisitsFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }


  OnSDrillToVisit($event,visit){
    if (this.Origin == "nurse" ){
      this.router.navigateByUrl("caretexfile/" + this.id + "/nursefamilyvisit/" + visit.ID)
    }else{
      this.router.navigateByUrl("caretexfile/" + this.id + "/familyvisits/" + visit.ID)
    }

  }

  generateReport($event,visit){
    
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return  false
  }
}
