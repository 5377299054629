
import { Router } from '@angular/router';
import { Component, ElementRef, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '../../services/auth.service';
import { ToasterService } from '../../services/toastr.service';
import { User } from '../../models/user';
import * as _ from 'lodash';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

import { DataService } from 'src/app/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';



@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {




  public displayedColumns = ['actions', 'displayName', 'lastName', 'email', 'role', 'phone', 'password', 'status'];
  dataSource: UserDataSource | null;
  //customerService: CustomerService | null;
  pageHeader: string;
  pageIcon: string;
  TabId:string = "main"
  users: User[]
  usersOrigin: User[];


  filteredData: User[] = [];
  renderedData: User[] = [];
  allUsers: User[] = [];
  newUser: User;



  phone: string = '';
  index: number;
  id: number;


  constructor(
    private title: Title,
    
    public httpClient: HttpClient,
    public dialog: MatDialog,
    public authService : AuthService,
    private router: Router,
    private data: DataService,
    private toastrService: ToasterService
    //  public iziToast: Ng2IzitoastService
  ) {

    this.newUser = new User()
    this.newUser.firstName = ""
    this.newUser.lastName = ""


  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('filter', { static: true }) filter: ElementRef;

  ngOnInit(): void {

    this.title.setTitle('Bridge eldry system | Users Management');
    this.data.isMedicalFileOpened = false
    this.pageHeader = 'Users Management ';
    this.pageIcon = 'fas fa-user';

    this.pageIcon = 'fas fa-user';
    this.loadData2();
  }



  success() {
    this.toastrService.Sucesss("", "נתונים נשמרו בהצלחה")
  }

  error() {
    this.toastrService.Error("", "הפעולה נכשלה")
  }

  info() {
    this.toastrService.Info("הפעולה נכשלה")
  }

  warnning() {
    this.toastrService.Warnning("", "הפעולה נכשלה")
  }



  refresh() {
    this.loadData2();
  }

  public loadData() {
    //this.customersService = new CustomerService(this.httpClient);
    this.dataSource = new UserDataSource(this.authService, this.paginator, this.sort);
    fromEvent(this.filter.nativeElement, 'keyup')
      // .debounceTime(150)
      // .distinctUntilChanged()
      .subscribe(() => {
        if (!this.dataSource) {
          return;
        }
        this.dataSource.filter = this.filter.nativeElement.value;
      });


  }



  setDefaultPic(index) {
    this.allUsers[index].photoURL = "./../../../assets/images/user-avatar.png"
  }


  loadData2() {



    this.authService.getUsers();

    this.authService.dataChange.subscribe((data) => {
      this.allUsers = data
    }, error => {
      console.log(error);
      this.toastrService.Error("", error)
    });

  }


  addNewUser() {

    this.authService.createNewUserInTenant(this.newUser).subscribe(data => {
      // do something, if upload success
      this.toastrService.Sucesss("", this.toastrService.successMsg)
      this.loadData2()

    }, error => {
      console.log(error);
    });

    this.refreshTable();
  }

  // startEdit(i: number, id: number, firstName: string, lastName: string, email: string, phone: string) {
  //  this.id = id;
  // index row is used just for debugging proposes and can be removed
  // this.index = i;
  startEdit(user: User) {
    this.authService.CurrentSelectedUser = user
    this.data.updateIsNotesShowed(false)
    this.router.navigateByUrl("/users_management/" + user.UID)


  }


  newApplicationUser(){
    this.router.navigateByUrl("/users_management/new" )
  }
  deleteItem(user: User) {
    this.authService.CurrentSelectedUser = user
  }

  private refreshTable() {

    this.paginator._changePageSize(this.paginator.pageSize);
  }

}
export class UserDataSource extends DataSource<User> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  filteredData: User[] = [];
  renderedData: User[] = [];
  allFiles: User[] = [];

  constructor(public authService: AuthService,
    public _paginator: MatPaginator,
    public _sort: MatSort) {
    super();
    // Reset to the first page when the user changes the filter.
    this._filterChange.subscribe(() => this._paginator.pageIndex = 0);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<User[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this.authService.dataChange,
      this._sort.sortChange,
      this._filterChange,
      this._paginator.page
    ];

    this.authService.getUsers();


    return merge(displayDataChanges).pipe(map(() => {
      // Filter data
      this.allFiles = this.authService.data
      this.filteredData = this.authService.data.slice().filter((user: User) => {

        const searchStr = (user.displayName + user.lastName + user.email + user.role).toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) !== -1;
      });

      // Sort filtered data
      const sortedData = this.sortData(this.filteredData.slice());

      // Grab the page's slice of the filtered sorted data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
      return this.renderedData;
    }
    ));
  }

  disconnect() { }


  /** Returns a sorted copy of the database data. */
  sortData(data: User[]): User[] {
    if (!this._sort.active || this._sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';

      switch (this._sort.active) {
        case 'displayName': [propertyA, propertyB] = [a.displayName, b.displayName]; break;
        case 'lastName': [propertyA, propertyB] = [a.lastName, b.lastName]; break;
        case 'email': [propertyA, propertyB] = [a.email, b.email]; break;
        case 'role': [propertyA, propertyB] = [a.role, b.role]; break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._sort.direction === 'asc' ? 1 : -1);
    });
  }
}

