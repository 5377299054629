import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SocialPocketTrackingCode } from 'src/app/models/generalhousedsetups';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';

import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-social-pocket-codes',
  templateUrl: './social-pocket-codes.component.html',
  styleUrls: ['./social-pocket-codes.component.css']
})
export class SocialPocketCodesComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  socialPocketTrackingCodes:SocialPocketTrackingCode[]  = []
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {  super(medicalFileServive)}

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getPocketCodesFromDB()
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.socialPocketTrackingCodes.some(row => {
      return row.isNew || row.editMode
    })
    return  hospitalsLisChanged
  }


  getPocketCodesFromDB(){
    this.caretxSetupsProviderService.GetPocketCodes(this.authServive.LoggedInTenantID).subscribe(
      data => {
        this.socialPocketTrackingCodes = data
      },
      err => {
        this.socialPocketTrackingCodes = []

      }
    )
  }

  addNewCode(){
    var newCode = new SocialPocketTrackingCode()
    newCode.New = true
    newCode.Modified = false
    newCode.editMode= true
    newCode.CreatedAt = new Date()
    newCode.UpdatedAt = new Date()
    newCode.TenantID = this.authServive.LoggedInTenantID
    this.socialPocketTrackingCodes.unshift(newCode)
  }

  deleteRow(i){
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.socialPocketTrackingCodes[i].isNew){
          this.caretxSetupsProviderService.DeletePocketCodes(this.authServive.LoggedInTenantID, this.socialPocketTrackingCodes[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.socialPocketTrackingCodes.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error.error.message)
            }
          })
        }
        else
        this.socialPocketTrackingCodes.splice(this.currentRowIndex, 1);
      }
    })
  }

  

  editRow(i){
    this.socialPocketTrackingCodes[i].New = false
    this.socialPocketTrackingCodes[i].Modified = true
    this.socialPocketTrackingCodes[i].editMode = true
  }

  cancelEditRow(i){
    this.socialPocketTrackingCodes[i].New = false
    this.socialPocketTrackingCodes[i].Modified = false
    this.socialPocketTrackingCodes[i].editMode = false
  }

 




  updateCode($event){

     this.caretxSetupsProviderService.CreateUpdatePocketCodes(this.authServive.LoggedInTenantID,this.socialPocketTrackingCodes).subscribe({
      next: data => {
        this.getPocketCodesFromDB()
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })  


    
  }

}
