import { Component, OnInit } from '@angular/core';
import { CaretexComponent } from '../../../shared/CaretexComponent';
import { FormControl, FormGroup } from '@angular/forms';
import { MedicalFileProviderService } from '../../../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../services/toastr.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

import { HospitalReferral } from '../../../models/hospitalreferrals';
import { CaretxMedicalProviderService } from '../../doctor-services/Medical.data-provider.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-hospital-referrals',
  templateUrl: './hospital-referrals.component.html',
  styleUrls: ['./hospital-referrals.component.css']
})
export class HospitalReferralsComponent extends CaretexComponent implements OnInit {
  id: string
  currentRowIndex: number
  pdfContent: any
  hospitalReferrals: HospitalReferral[] = []
  moduleName: string = "הפניות לבתי חולים/בדיקות/מרפאות"
  totalNumOfRecords: Number = 0


  RecordStatus = [{ id: "A", desc: "טופס הפנייה פתוח" }, { id: "C", desc: "טופס הפנייה נעול"  }]

  formData: any[]

  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), //, [Validators.required, Validators.email]),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });



  
  constructor(
    private _title: Title, 
    private toastrService: ToasterService,
    private router: Router,
    private medicalProviderService: CaretxMedicalProviderService,
    private route: ActivatedRoute,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,) {super(medicalFileServive) }

  ngOnInit(): void {
    this._title.setTitle('Caretex | קבלות רפואיות');
    if(this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.id)
    this.getHospitalReferralFromDB("")
  }



  getHospitalReferralFromDB(referralid: string) {
    let filter = ""
    this.medicalProviderService.getReferrals(filter, this.id, 1, "list").subscribe(
      data => {
        this.hospitalReferrals = data
        this.totalNumOfRecords = data.length
      },

      err => {
        this.hospitalReferrals = []
      }
    );
  }


  OnSDrillToMedicalReferral($event,hospitalReferral) {
    //admissionid/locked
    if (hospitalReferral.Status == "C"){
      this.router.navigateByUrl("caretexfile/" + this.id + "/hospitalreferrals/" + hospitalReferral.ID + "/locked")
    }else{
      this.router.navigateByUrl("caretexfile/" + this.id + "/hospitalreferrals/" + hospitalReferral.ID)
    }
  }


  deleteRow(i) {
    this.currentRowIndex = i
    if (this.hospitalReferrals[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAdmission(this.hospitalReferrals[this.currentRowIndex])
        }
      })
    }
  }

  deleteAdmission(referralRecord: HospitalReferral) {

    this.medicalProviderService.deleteReferral(this.id, referralRecord.ID ).subscribe({
      next: data => {
        this.getHospitalReferralFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }



  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }

  AddNewHospitalReferral($event) {
    this.router.navigateByUrl("caretexfile/" + this.id + "/hospitalreferrals/new") 
  }

  SearchWithFilter($event){

  }

}
