import { CaretxCardixComponent } from './caretx-cardix.component';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaretxCardixRoutingModule } from './caretx-cardix-routing.module';
import { SharedModule } from '../caretx-shared-module/shared-module.module';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { CardixDataProviderService } from './CardixServices/cardix.data-provider.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [CaretxCardixComponent],
  imports: [
    CommonModule,
    FormsModule,
    CaretxCardixRoutingModule,
    SharedModule,
    NgMultiSelectDropDownModule,
    PdfJsViewerModule,
  ],
  providers: [
    CardixDataProviderService
   ],
})
export class CaretxTaskSchedulerModule { }
