import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FoodAddition } from 'src/app/models/generalhousedsetups';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';

@Component({
  selector: 'app-dieticianfoodadditions',
  templateUrl: './dieticianfoodadditions.component.html',
  styleUrls: ['./dieticianfoodadditions.component.css']
})
export class DieticianfoodadditionsComponent extends CaretexComponent  implements OnInit {

  additions:FoodAddition[]  = []
  currentRowIndex:number
  TabId:string = "main"
  constructor(private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authService: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {  super(medicalFileServive)}

  ngOnInit(): void {

    this.getFoodAdditionsFromDB()
  }

  getFoodAdditionsFromDB(){
    this.caretxSetupsProviderService.getFoodAdditions().subscribe(
      data => {
        this.additions = data
      },
      err => {
        this.additions = []

      }
    )
  }
  addNewAddition(){
    var newAddition = new FoodAddition()
    newAddition.isNew = true
    newAddition.editMode = true
    newAddition.CreatedAt = new Date()
    newAddition.UpdatedAt = new Date()
    newAddition.TenantID = this.authService.LoggedInTenantID
    newAddition.New = true
    this.additions.unshift(newAddition)
  }
  updateAddition($event){
    this.caretxSetupsProviderService.CreateUpdateFoodAddition(this.additions).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getFoodAdditionsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    }) 
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }

  editRow(i){
    this.additions[i].editMode = true
    this.additions[i].Modified = true
  }

  deleteRow(i){
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.additions[i].isNew){
          this.caretxSetupsProviderService.deleteFoodAddition( this.additions[this.currentRowIndex]).subscribe({
            next: data => {
              this.additions.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
              this.getFoodAdditionsFromDB()
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.additions.splice(this.currentRowIndex, 1);
      }
    })
  }

  cancelEditRow(i){
    this.additions[i].editMode  = false;
    this.additions[i].Modified = false
  }
  CheckIfDocumentIsDirty() {
    const statusesListChanged = this.additions.some(row => {
      return row.New || row.Modified
    })
    return  statusesListChanged
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
}
