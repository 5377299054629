import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HazardRisk } from 'src/app/models/hazard';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-hazardrisk',
  templateUrl: './hazardrisk.component.html',
  styleUrls: ['./hazardrisk.component.css']
})
export class HazardriskComponent extends CaretexComponent implements OnInit {
hazardRisks:HazardRisk[] = []
currentRowIndex:number
deleteSysMsgId:string
deleteAlert:string
TabId:string = "main"
  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {  super(medicalFileServive)}

  ngOnInit(): void {

    this.getHazardRisksFromDB()
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.hazardRisks.some(row => {
      return row.New || row.editMode
    })
    return  hospitalsLisChanged
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }
  getHazardRisksFromDB(){
      this.caretxSetupsProviderService.GetHazardRisks().subscribe(
        data => {
          this.hazardRisks = data
        },
        err => {
          console.log("Error", err)
          this.hazardRisks = []
  
        }
      )
    }
  
  updateHazardRisk($event){
    this.caretxSetupsProviderService.CreateHazardRisks(this.hazardRisks).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getHazardRisksFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  addNewHazardRisk(){
    var newHazardRisk = new HazardRisk()
    newHazardRisk.New = true
    newHazardRisk.editMode = true
    newHazardRisk.CreatedAt = new Date()
    newHazardRisk.UpdatedAt = new Date()
    newHazardRisk.RealmID = this.authServive.LoggedInTenantID
    this.hazardRisks.unshift(newHazardRisk)
  }

  

  deleteRow(i){

    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.hazardRisks[i].New){
          this.caretxSetupsProviderService.DeleteHazardRisk(this.hazardRisks[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.hazardRisks.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.hazardRisks.splice(this.currentRowIndex, 1);
      }
    })
  }



  editRow(i){
    this.hazardRisks[i].editMode = true
    this.hazardRisks[i].Modified = true
  }

  cancelEditRow(i){
    this.hazardRisks[i].editMode = false
    this.hazardRisks[i].Modified = false
  }


 
}
