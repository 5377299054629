import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LabCheckHeader } from 'src/app/models/testlabchecks';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';

@Component({
  selector: 'app-laboratorycheckslist',
  templateUrl: './laboratorycheckslist.component.html',
  styleUrls: ['./laboratorycheckslist.component.css']
})
export class LaboratorycheckslistComponent extends CaretexComponent implements OnInit  {

  checkHeaders: LabCheckHeader[];
  id: string;
  currentRowIndex:number;
  moduleName = 'תוצאות מעבדה';

  constructor(
    private _title: Title,
    private router: Router,
    private route: ActivatedRoute,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,
    private _labService: CaretxMedicalProviderService,
    ) {super(medicalFileServive) }


  ngOnInit(): void {
    this._title.setTitle('Bridge CRM | בדיקות מעבדה');
    this.id = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.id)
    this.getLaboratortCheckHeaders()

  }


  getLaboratortCheckHeaders() {
    this._labService.getLaboratoryResultsHeaders(this.id,null).subscribe(
      data => {
        this.checkHeaders = data
      },
      err => {
        console.log("Error", err)
        this.checkHeaders = []
      }
    )
  }


  AddNewLaboratoryCheck($event){
    this.router.navigateByUrl("caretexfile/" + this.id + "/laboratory/results/new")
  }

  drillToLaboratoryResult($event,checkHeader){
    this.router.navigateByUrl("caretexfile/" + this.id + "/laboratory/results/"+checkHeader.ID)
  }

  generateReport($event,checkHeader){
    
  }

  deleteRow(i) {
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteLabResultHeader(this.checkHeaders[this.currentRowIndex])
      }
    })
  }


  deleteLabResultHeader(labResultHeader: LabCheckHeader) {
    let filter = '(id=' + '\'' + labResultHeader.ID + '\'' + ')'
    this._labService.deleteLaboratoryResult(this.id , filter).subscribe({
      next: data => {
        this.getLaboratortCheckHeaders()
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty(){
    return false;
  }


}
