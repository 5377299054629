import { Component, OnInit } from '@angular/core';
import { DrugInstruction } from '../../../../../src/app/models/drugInstruction';
import { Title } from '@angular/platform-browser';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toastr.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { InstrStatus } from 'src/app/services/enums';
import { CaretexTextareaDialogComponent } from '../../CaretexModals/caretex-textarea-dialog/caretex-textarea-dialog.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-nursemedicationreviews',
  templateUrl: './nursemedicationreviews.component.html',
  styleUrls: ['./nursemedicationreviews.component.scss']
})
export class NursemedicationreviewsComponent implements OnInit {

  drugInstrs: DrugInstruction[] = []
  selectedInstr: DrugInstruction = new DrugInstruction()
  DisableParentWindow: boolean = false
  startDateText: string
  endDateText: string
  totalNumOfRecords: number
  TabId = "main"
  DefaultPhotoURL: string = "../../../assets/images/user-avatar.png"
  constructor(
    private _title: Title,
    private caretxMedicalService: CaretxMedicalProviderService,
    private authService: AuthService,
    private router: Router,
    private toastrService: ToasterService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private _ar: ActivatedRoute,
    public medicalFileServive: MedicalFileProviderService,) { }

  ngOnInit(): void {
    this.loadMedicationInstructions()
  }


  loadMedicationInstructions() {

    this.caretxMedicalService.getDrugInstrsForApproval().subscribe(
      data => {
        this.drugInstrs = data
        this.totalNumOfRecords = data.length
      },
      err => {
        this.drugInstrs = []
      }
    )

  }
  OpenSideNav() {

  }

  OpenSideNavDepartment() {

  }

  taskAction(drugInstr: DrugInstruction, action: string) {
      if (action === 'rejected') {
        this.ShowUserTextDialog(drugInstr)
      }
      if (action === 'approved') {
        drugInstr.instrStatus = InstrStatus.Active
        drugInstr.SignedByNurseAt =  new Date()
        drugInstr.nurseId = this.authService.LoggedInUser.UID
        drugInstr.Modified = true
        this.DoUpdate(drugInstr);
      }

  }


DoUpdate(drugInstr: DrugInstruction ) {
    this.caretxMedicalService.updateDrugInstr(drugInstr).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.loadMedicationInstructions() 
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
    
   }


   ShowUserTextDialog(drugInstr: DrugInstruction) {
    // Need to be changed 
    const dialogRef = this.dialog.open(CaretexTextareaDialogComponent, {
      data: {
        Disabled: drugInstr.instrStatus == InstrStatus.Rejected ,
        UserText: drugInstr.NurseRejectComment,
        Title: "סיבת דחית הוראת תרופה",
        TextFieldName: "סיבת דחית הוראת תרופה"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (drugInstr.instrStatus == 'SD') {
        drugInstr.instrStatus = InstrStatus.Rejected
        drugInstr.NurseRejectComment = result
        drugInstr.SignedByNurseAt = new Date()
        drugInstr.nurseId = this.authService.LoggedInUser.UID
        this.DoUpdate(drugInstr)
      }
    });
  }

  onMouseEnter(instr) {
    this.selectedInstr = instr
  }

}
