import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Tenant } from '../../../../../src/app/models/tenant';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../shared/CaretexComponent';


@Component({
  selector: 'app-generalhousesetup',
  templateUrl: './generalhousesetup.component.html',
  styleUrls: ['./generalhousesetup.component.scss']
})
export class GeneralhousesetupComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  currentTenant: Tenant
  changePhone: boolean = false
  changeHouseName: boolean = false
  changeFax: boolean = false
  changeManager: boolean = false
  changeMainNurse: boolean = false
  changeMainDoctor: boolean = false
  changeAddress: boolean = false
  profileImgUrl: any = 'assets/images/defaultHouse.png';
  profileImgTemp: any = 'assets/images/defaultHouse.png';
  profileImgFile: any;
  constructor(
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private router: Router,
    private toastrService: ToasterService,) { super(medicalFileServive)}

  ngOnInit(): void {
    this.getTenantInfoFromDB(this.authServive.LoggedInTenantID)
  }

  getTenantInfoFromDB(tenantId: string) {
    this.caretxSetupsProviderService.GetTenantInfoById(tenantId).subscribe(
      data => {
        this.currentTenant = data
        if (this.currentTenant.iconPath == "" || this.currentTenant.iconPath == null || this.currentTenant.iconPath == undefined) {
          this.profileImgTemp = this.profileImgUrl;
        } else {
          this.profileImgTemp = this.currentTenant.iconPath;
        }
      },
      err => {
        console.log("Error", err)
        this.currentTenant = new Tenant
      }
    )
  }

  BackToList() {
    this.router.navigate(['system_setups']);
  }

  updateTenant($event) {
    this.caretxSetupsProviderService.UpdateTenantInfoById(this.currentTenant).subscribe({
      next: data => {
        this.getTenantInfoFromDB(this.authServive.LoggedInTenantID)
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.changePhone = false
        this.changeHouseName = false
        this.changeFax = false
        this.changeManager = false
        this.changeMainNurse = false
        this.changeMainDoctor = false
        this.changeAddress = false
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  changePhoneEvent() {
    this.changePhone = true
  }

  cancelPhoneChangeEvent() {
    this.changePhone = false
  }

  houseNameChangeEvent() {
    this.changeHouseName = true
  }
  cancelhouseNameChangeEvent() {
    this.changeHouseName = false
  }

  FaxChangeEvent() {
    this.changeFax = true
  }
  cancelFaxChangeEvent() {
    this.changeFax = false
  }

  managerChangeEvent() {
    this.changeManager = true
  }

  cancelManagerChangeEvent() {
    this.changeManager = false
  }
  mainNurseChangeEvent() {
    this.changeMainNurse = true
  }

  cancelMainNurseChangeEvent() {
    this.changeMainNurse = false
  }
  mainDoctorChangeEvent() {
    this.changeMainDoctor = true
  }
  cancelMainDoctorChangeEvent() {
    this.changeMainDoctor = false
  }

  addressChangeEvent() {
    this.changeAddress = true
  }

  cancelAddressChangeEvent() {
    this.changeAddress = false
  }

  onDiscardProfileImg() {
    this.profileImgFile = '';
    if (this.currentTenant.iconPath == "" || this.currentTenant.iconPath == null || this.currentTenant.iconPath == undefined) {
      this.profileImgTemp = this.profileImgUrl;
    } else {
      this.profileImgTemp = this.currentTenant.iconPath;
    }
  }

  saveHouseIcon() {
    this.caretxSetupsProviderService.uploadHouseLogo(this.profileImgFile).subscribe(
      data => {

        this.profileImgFile = '';
      }
    );
  }

  fileUpload(event) {
    if (event.target.files.length > 0) {
      const imgType = event.target.files[0].type;
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.profileImgTemp = reader.result;
        console.log('FILE FOUND...', this.profileImgTemp);
      }
      const file = event.target.files[0];
      this.profileImgFile = file
    }
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }

}
