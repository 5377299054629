import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxMedicalProviderService } from '../../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { Consultatory } from '../../../../../../src/app/models/expertconsulatant';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import { MedicalFile } from '../../../../../../src/app/models/customer';
import jsPDF from 'jspdf';

import { ReportingService } from '../../../../../../src/app/services/reporting.service';

@Component({
  selector: 'app-expert-consultant-locked',
  templateUrl: './expert-consultant-locked.component.html',
  styleUrls: ['./expert-consultant-locked.component.css']
})
export class ExpertConsultantLockedComponent extends CaretexComponent implements OnInit {
  moduleName: string = "יועצים / מומחים - דוח יועץ מומחה"
  currentOpenedRecord: Consultatory
  recordId: string
  personalId: string 
  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  selectedFile: MedicalFile
  pdfContent: any

  constructor(private caretxMedicalProviderService: CaretxMedicalProviderService,
    private _title: Title,
    private router: Router,
    public reportingService: ReportingService,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authService: AuthService,
    private route: ActivatedRoute,) { super(medicalFileService) }

  ngOnInit(): void {
    this._title.setTitle('Caretex | טופס תיאום טיפול');
    if (this.route.parent != null)
      this.personalId = this.route.parent.snapshot.params['id']
    this.recordId = this.route.snapshot.params.consultatoryid
    this.CheckIfComponentIsDisabled(this.personalId)
    this.getConsultatoryFormById(this.personalId, this.recordId, "")
  }

  getConsultatoryFormById(id: string, recordId: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + recordId + '\'' + ')'
    this.caretxMedicalProviderService.getConsultatory(id, filter, mode).subscribe((data: Consultatory[]) => {
      if (data.length == 1) {
        this.currentOpenedRecord = new Consultatory(data[0])
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }

  NewRecord() {
  }

  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/consultatory/list")
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }

/********************************************************************************** */
openPDFViewer() {
  let reportTitle = "יועצים / מומחים - דוח יועץ מומחה"
  this.showGeneratedPdf = true
  this.selectedFile = this.medicalFile1
  var doc = new jsPDF()
  let lastYPosition: number
  let xPosTitle:number = 118

  lastYPosition = this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle)
  this.reportingService.setFooter2(doc, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date())

  lastYPosition += 6
  let label = "מטרה:"
  lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentOpenedRecord.Purpose == "" ? "לא צויין" : this.currentOpenedRecord.Purpose, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

  label = "שם יועץ / מכון:"
  lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentOpenedRecord.ConsultantName == "" ? "לא צויין" : this.currentOpenedRecord.ConsultantName, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

  label = "תפקיד:"
  lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentOpenedRecord.Role == "" ? "לא צויין" : this.currentOpenedRecord.Role, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
  
  label = "תאריך הזמנה:"
  lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.reportingService.formatDateToMMDDYYY(this.currentOpenedRecord.DueDate), this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)


  label = "שעת יציאה:"
  lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.reportingService.formatDateToHHmm(this.currentOpenedRecord.LeavingTime), this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)


  label = "שעת חזרה:"
  lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.reportingService.formatDateToHHmm(this.currentOpenedRecord.ReturnTime),this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

  label = "דיווח:"
  lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label,  this.currentOpenedRecord.Report == "" ? "לא צויין" : this.currentOpenedRecord.Report, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

  label = "מטלות לביצוע:"
  lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label,  this.currentOpenedRecord.TasksToDo == "" ? "לא צויין" : this.currentOpenedRecord.TasksToDo, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)


  let file: Blob = doc.output('blob')
  this.pdfContent = URL.createObjectURL(file);
  this.pdfViewer.pdfSrc = file
  this.pdfViewer.refresh();
}

closePdfDocument() {
  this.showGeneratedPdf = false
}




}
