import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NursingplanComponent } from 'src/app/caretx-nusrsing-module/Components/nursingadmissionlist/nurseadmission/subcomponents/nursingplan/nursingplan.component';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { NursePhysicalStatus, NursePhysicalStatusDefaultText } from 'src/app/models/nursingphysicalstatuses';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-nursephysicalstatuses-details2',
  templateUrl: './nursephysicalstatuses-details2.component.html',
  styleUrls: ['./nursephysicalstatuses-details2.component.css']
})
export class NursephysicalstatusesDetails2Component extends CaretexComponent  implements OnInit {

  selectedNursePhysicalStatus: NursePhysicalStatus = new NursePhysicalStatus()
nursePhysicalStatuses : NursePhysicalStatus[] = []
statusId:string
id:string



  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,) {  super(medicalFileServive)}

  ngOnInit(): void {

    this.id = this.route.snapshot.params['id'];
    this.statusId = this.route.snapshot.params['statusid'];
    if (this.statusId == "new") {
        this.selectedNursePhysicalStatus =  new NursePhysicalStatus()
        this.selectedNursePhysicalStatus.isNew = true
        this.selectedNursePhysicalStatus.New = true
        this.selectedNursePhysicalStatus.NursePhysicalCheckId = this.id
        this.selectedNursePhysicalStatus.DefaultTexts = []
        this.selectedNursePhysicalStatus.TenantID = this.authServive.LoggedInTenantID
    }else {
      this.getPhysicalCheckStatusByIDFromDB()
    }
  }

  getPhysicalCheckStatusByIDFromDB() {


    let filter = '(id=' + '\'' + this.statusId+ '\'' + ')'
    this.caretxSetupsProviderService.GetNursePhysicalCheckStatus(filter).subscribe(
      data => {
        this.nursePhysicalStatuses = data
        this.selectedNursePhysicalStatus = data[0]
      },
      err => {
        this.nursePhysicalStatuses = []

      }
    )
  }

  addNewValue() {
    let tempRow : NursePhysicalStatusDefaultText = new NursePhysicalStatusDefaultText()
    tempRow.CreatedAt = new Date()  
    tempRow.UpdatedAt = tempRow.CreatedAt
    tempRow.TenantID = this.authServive.LoggedInTenantID
    tempRow.New = true
    
    this.selectedNursePhysicalStatus.DefaultTexts.push(tempRow)




  }

  deleteDefaultText(i) {

  }

  deActivateDefaultText(i) {

  }

  updateStatus($event) {

    let arrayOfStatuses:NursePhysicalStatus[] = []
    arrayOfStatuses.push(this.selectedNursePhysicalStatus)

    this.caretxSetupsProviderService.CreateUpdateNursePhysicalCheckStatus(arrayOfStatuses).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("physicalnursechecks_settings/" + this.id)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })

  }

  BackToList() {
    this.router.navigateByUrl("/physicalnursechecks_settings/" +  this.id )
  }

  canDeactivate() {

    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    /*  const hospitalsLisChanged = this.measures.some(row => {
        return row.isNew || row.editMode
      })
      return hospitalsLisChanged*/
    return false
  }
}
