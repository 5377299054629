
import { MeasuresTypes } from './generalhousedsetups'
import { propertyMap } from './modelmapper'
import { User } from './user'


export class MeasureCodeListValues {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('Value')
    public Value:string
    @propertyMap('Type')
    public Type:string

    @propertyMap('MeasureCodeId')
    public MeasureCodeId:string
    public isNew :boolean = false
    public editMode :boolean = false

}

export class PersonalMeasureCode {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Description')
    public Description:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('Uom')
    public Uom:string
    @propertyMap('Type')
    public Type:string
    @propertyMap('AdditionalValueType')
    public AdditionalValueType:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('IsEssential')
    public IsEssential:boolean
    @propertyMap('IsCalculated')
    public IsCalculated:boolean
    @propertyMap('ClonedFrom')
    public ClonedFrom:string
    @propertyMap('IsCaretext')
    public IsCaretext:boolean
    @propertyMap('CaretexCode')
    public CaretexCode:string
    @propertyMap('HasRedLimit')
    public HasRedLimit:boolean
    @propertyMap('UpperRedLimit')
    public UpperRedLimit:number
    @propertyMap('LowerRedLimit')
    public LowerRedLimit:number
    @propertyMap('Sequence')
    public Sequence:number
    @propertyMap('ListValues')
    public ListValues:MeasureCodeListValues[]
    @propertyMap('AdditionalListValues')
    public AdditionalListValues:MeasureCodeListValues[]
    public isNew :boolean = false
    public editMode :boolean = false
    @propertyMap('MeasureType')
    public MeasureType:string
    @propertyMap('MustBeInInterval')
    public MustBeInInterval:boolean

    @propertyMap('IsForPain')
    public IsForPain:boolean


}

export class PersonalMeasuresHeader {
    @propertyMap('ID')
    public ID:string
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('AdmissionId')
    public AdmissionId:string
    @propertyMap('AdmissionType')
    public AdmissionType:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('UserId')
    public UserId:string
    public User:User
    @propertyMap('PersonalMeasures')
    public PersonalMeasures:PersonalMeasures[]
    @propertyMap('Status')
    public Status:string
    @propertyMap('LockedDate')

    public MeasureType:string
    @propertyMap('MeasureType')


    public LockedDate:Date
    @propertyMap('LockedById')
    public LockedById:string
    public LockedBy:User
    @propertyMap('LastUpdateById')
    public LastUpdateById:string
    public LastUpdateBy:User

 
    @propertyMap('MeasureTypeRecord')
    public MeasureTypeRecord:MeasuresTypes
    

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.UpdatedAt = data["UpdatedAt"]
            this.ID = data["ID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.PersonelID = data["PersonelID"]
            this.UserId = data["UserId"]
            this.User = data["User"]
            this.Status = data["Status"]
            this.PersonelID = data["PersonelID"]
            this.LockedDate = data["LockedDate"]
            this.LockedById = data["LockedById"]
            this.AdmissionType = data["AdmissionType"]
            this.LockedBy = data["LockedBy"]
            this.LastUpdateById = data["LastUpdateById"]
            this.LastUpdateBy = data["LastUpdateBy"]
            this.MeasureType = data["MeasureType"]
            this.MeasureTypeRecord = data["MeasureTypeRecord"]
            // initilaize  Personel Measures
            this.PersonalMeasures=[]
            if (data["PersonalMeasures"] != null && data["PersonalMeasures"] != undefined){
                data["PersonalMeasures"].forEach(element => {
                    this.PersonalMeasures.push(new PersonalMeasures(element))

                    if (element.PersonalMeasureCode.Type == "N" && element.PersonalMeasureCode.HasRedLimit) {
                        if (element.NumericValue > element.PersonalMeasureCode.UpperRedLimit  || element.NumericValue < element.PersonalMeasureCode.LowerRedLimit)
                        element.IsRed = true
                    }
                });
            }

        }
        else{
            this.UpdatedAt =  this.CreatedAt =  new Date()
            this.Status = "A"
            this.PersonalMeasures = []
        }

    }


    initiateNewPersonalMeasures(data: PersonalMeasureCode[] , personelId :string) {

        data.forEach(element => {
          let newPersonalMeasure = new PersonalMeasures()
          newPersonalMeasure.PersonelID = personelId
          newPersonalMeasure.PersonalMeasureCode = element
          newPersonalMeasure.MeasureCodeId = element.ID
          newPersonalMeasure.UpdatedAt = new Date()
           
          // Set Up Type Of Value
            if (newPersonalMeasure.MeasureCodeId  == "bloodpressure") {
                newPersonalMeasure.ValueType = "bloodpressure"
            }
            else if (newPersonalMeasure.MeasureCodeId == "urinate" || newPersonalMeasure.MeasureCodeId == "bowelaction"
            || newPersonalMeasure.MeasureCodeId == "providingoxygen" ){
                newPersonalMeasure.ValueType = "string"
            }
            else {
                newPersonalMeasure.ValueType = "number"
            }
         // newPersonalMeasure.UserId = this.authServive.LoggedInUser.uid
         // newPersonalMeasure.User = this.authServive.LoggedInUser
    
          this.PersonalMeasures.push(newPersonalMeasure)
        });
    
      }
}

export class PersonalMeasures{
    @propertyMap('ID')
    public ID:string
    @propertyMap('MeasureId')
    public MeasureId:Number
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('AdmissionId')
    public AdmissionId:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('AdmissionType')
    public AdmissionType:string
    @propertyMap('Description')
    public Description:string
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('MeasureCodeId')
    public MeasureCodeId:string
    @propertyMap('PersonalMeasureCode')
    public PersonalMeasureCode:PersonalMeasureCode
    @propertyMap('NumericValue')
    public NumericValue:number
    @propertyMap('StringValue')
    public StringValue:string
    @propertyMap('ListValueId')
    public ListValueId:string
    @propertyMap('ListValue')
    public ListValue:MeasureCodeListValues
    @propertyMap('AdditionalInfoTxt')
    public AdditionalInfoTxt:string
    @propertyMap('AdditionalListValueId')
    public AdditionalListValueId:string
    @propertyMap('AdditionalListValue')
    public AdditionalListValue:MeasureCodeListValues
    @propertyMap('ValueType')
    public ValueType:string
    @propertyMap('AdditionalValue')
    public AdditionalValue:number
    @propertyMap('UserId')
    public UserId:string
    public User:User

    public IsRed:boolean

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.MeasureId = data["MeasureId"]
            this.PersonelID = data["PersonelID"]
            this.UpdatedAt = data["UpdatedAt"]
            this.CreatedAt = data["CreatedAt"]
            this.MeasureCodeId = data["MeasureCodeId"]
            this.AdmissionId = data["AdmissionId"]
            this.AdmissionType = data["AdmissionType"]
            this.TenantID = data["TenantID"]
            this.Description = data["Description"]
            this.UserId = data["UserId"]
            this.User = data["User"]
            this.AdditionalValue = data["AdditionalValue"]
            this.NumericValue = data["NumericValue"]
            this.StringValue = data["StringValue"]
            this.ValueType = data["ValueType"]
            this.PersonalMeasureCode = data["PersonalMeasureCode"]
            this.ListValueId = data["ListValueId"]
            this.ListValue = data["ListValue"]
            this.AdditionalListValueId = data["AdditionalListValueId"]
            this.AdditionalListValue = data["AdditionalListValue"]
            this.AdditionalInfoTxt = data["AdditionalInfoTxt"]
 


            if (this.ListValue == undefined || this.ListValue == null){
                this.ListValue =  new MeasureCodeListValues()
            }

            if (this.PersonalMeasureCode.Type == "N" && this.PersonalMeasureCode.HasRedLimit) {
                if (this.NumericValue > this.PersonalMeasureCode.UpperRedLimit  || this.NumericValue < this.PersonalMeasureCode.LowerRedLimit)
                this.IsRed = true
            }


        }
    }

 
}

