import { propertyMap } from './modelmapper'
import { User } from './user'



export class PersonelDocument {
  
    @propertyMap('AttachmentId')
    public AttachmentId:string

    @propertyMap('PersonelID')
    public PersonelID:string

    @propertyMap('FileName')
    public FileName:String


    @propertyMap('Uploaded_at')
    public Uploaded_at:Date
    @propertyMap('Description')
    public Description:string

    @propertyMap('Downloaded_at')
    public Downloaded_at:Date

    @propertyMap('FileCategory')
    public FileCategory:String

    @propertyMap('UserId')
    public UserId:string
    public User:User

 

    constructor() {
    }


}


export class CartexReport{
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('ReportName')
    public ReportName:string
    @propertyMap('GeneratedBy')
    public GeneratedBy:User
    @propertyMap('GeneratedOn')
    public GeneratedOn:Date
    @propertyMap('ReportType')
    public ReportType :string
    @propertyMap('ReportParam')
	public ReportParam:string
}


export class CarouselItem{
    public ImageAsDataUrl:string
    public PersonelID:string 
    public ObservationID:string 
    public ItemType:string 
    public Title:string 
    @propertyMap('UserId')
    public UserId:string
    public User:User
    @propertyMap('Uploaded_at')
    public Uploaded_at:Date
}
