import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { CaretxSharedModulesProviderService } from 'src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { Instruction, NurseInstruction } from 'src/app/models/nurseinstruction';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { AccessComponenetType, NurseInstructionEnum, UserRoles } from 'src/app/services/enums';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CaretxMedicalProviderService } from '../../doctor-services/Medical.data-provider.service';
import Swal from 'sweetalert2';
import { MedicalFile } from 'src/app/models/customer';

@Component({
  selector: 'app-nursinginstructions',
  templateUrl: './nursinginstructions.component.html',
  styleUrls: ['./nursinginstructions.component.css']
})
export class NursinginstructionsComponent extends CaretexComponent implements OnInit {

  @Output() backToCentralPage = new EventEmitter<void>();
  @Input() PersonalId: string
  @Input() IsFromCentralizedPage: boolean = false
  @Input() SelectedFile: MedicalFile

  
  personelid: string
  @ViewChild('subform1') subform1: NgForm;
  InstrIsClosed: boolean = false
  isClosed: boolean = false
  moduleName: string = "הוראת אחות"
  Type: string = AccessComponenetType.SubModule
  modalTarget = ""
  nurses: User[] = []
  selectedItems1 = [];
  dropdownSettings1 = {};
  UserRole: UserRoles
  currentNurseInstr: NurseInstruction
  newNurseInstrcions: NurseInstruction
  retreivedNurseInstrcions: NurseInstruction[] = []
  showInstructionModal: boolean = false
  ListOfInstructionsFromSetup: Instruction[]
  selectedInstructionCodes: Instruction = new Instruction()
  isInstructionInputValid: boolean = true
  instructionType: string
  frequencyParam: number
  frequencyUomParam: string
  startDateParam: Date
  endDateParam: Date
  additionalCommentsParam: string
  CurrentStep: NurseInstructionEnum = NurseInstructionEnum.Create

  freqAsNum: number = 0
  timeX1: string
  timeX2: string
  timeX3: string
  timeX4: string
  timeX5: string
  datetimeX1: Date
  datetimeX2: Date
  datetimeX3: Date
  datetimeX4: Date
  datetimeX5: Date

  frequencyuom: { uomid: string; uomval: string }[] = [
    { uomid: "D", uomval: "ביום" },
    { uomid: "W", uomval: "בשבוע" },
    { uomid: "M", uomval: "בחודש" },

  ];

  frequency: { freqid: number; freqval: string }[] = [
    { freqid: 1, freqval: "פעם אחת" },
    { freqid: 2, freqval: "פעמים" },
    { freqid: 3, freqval: "3 פעמים" },
    { freqid: 4, freqval: "4 פעמים" },
    { freqid: 5, freqval: "5 פעמים" },
  ];



  constructor(
    private route: ActivatedRoute,
    private authServive: AuthService,
    private router: Router,
    public datepipe: DatePipe,
    private sharedService: CaretxSharedModulesProviderService,
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    public medicalFileService: MedicalFileProviderService,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private toastrService: ToasterService,) {
    super(medicalFileService)

  }

  ngOnInit(): void {
    this.UserRole = UserRoles.Nurse
    if (this.IsFromCentralizedPage) {
      this.personelid = this.PersonalId
    } else {

      this.personelid = this.route.parent.snapshot.params['id']
    }

    this.CheckIfComponentIsDisabled(this.personelid)
    this.doInitialization()
    this.getUsers(this.UserRole)
    this.instructionType = "O"
    this.currentNurseInstr = new NurseInstruction()
    this.getInstructionsFromDB()
  }


  getInstructionsFromDB() {
    this.caretxSetupsProviderService.GetInstruction().subscribe(
      data => {
        this.ListOfInstructionsFromSetup = data
      },
      err => {
        console.log("Error", err)
        this.ListOfInstructionsFromSetup = []

      }
    )
  }


  doInitialization() {
    // Get LabCategories :
    this.dropdownSettings1 = {
      singleSelection: true,
      idField: "UID",
      textField: "displayName",

      itemsShowLimit: 1,
      allowSearchFilter: true
    };
  }

  getUsers(userRole: UserRoles) {
    let filter = '(role=' + '\'' + userRole + '\'' + ')'
    this.sharedService.getUsers(filter).subscribe(
      data => this.nurses = data,
      err => {
        console.log("Error", err)
        this.nurses = []

      }
    )
  }

  onchangeInstrType($event, val) {
    this.instructionType = val
  }

  NewRecord() {

  }
  BackToList() {

  }


  showValidationMsg(msg: string) {
    Swal.fire({
      title: 'לידיעתך',
      text: msg,
      icon: 'warning',
      allowOutsideClick: false,
      allowEscapeKey: false

    })
  }

  submitForm() {


    if (this.selectedInstructionCodes.InstructionName == "" || this.selectedInstructionCodes.InstructionName == undefined) {
      let msg: string = "את/ה צריך לבחור הוראה"
      this.isInstructionInputValid = false
      this.showValidationMsg(msg)
      return
    } else {
      this.isInstructionInputValid = true
      this.subform1.onSubmit(undefined);
      if (this.subform1.form.invalid) {
        let msg: string = "אחד או יותר מהשדות לא מולאו כמו שצריך"
        this.showValidationMsg(msg)
      }
    }
  }


  SaveRecord() {
    this.newNurseInstrcions = new NurseInstruction()
    // this.newNurseInstrcions.forEach(element => {
    this.newNurseInstrcions.Frequency = this.frequencyParam
    this.newNurseInstrcions.FrequencyUom = this.frequencyUomParam
    this.newNurseInstrcions.PersonelID = this.personelid
    this.newNurseInstrcions.TenantID = this.authServive.LoggedInTenantID
    this.newNurseInstrcions.Status = "A"
    this.newNurseInstrcions.InstructionCodeId = this.selectedInstructionCodes.ID
    this.newNurseInstrcions.InstructionCode = this.selectedInstructionCodes
    this.newNurseInstrcions.AdditionalComments = this.additionalCommentsParam
    this.newNurseInstrcions.InstrStartDate = new Date(this.startDateParam)
    this.newNurseInstrcions.InstrStopDate = new Date(this.endDateParam)
    this.newNurseInstrcions.InstructionType = this.instructionType
    this.newNurseInstrcions.Time1 = this.datetimeX1
    this.newNurseInstrcions.Time2 = this.datetimeX2
    this.newNurseInstrcions.Time3 = this.datetimeX3
    this.newNurseInstrcions.Time4 = this.datetimeX4
    this.newNurseInstrcions.Time5 = this.datetimeX5
    this.newNurseInstrcions.CreatedById = this.authServive.LoggedInUser.UID

    this.caretxMedicalProviderService.createNewNurseInstructions(this.personelid, this.newNurseInstrcions).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        // this.newNurseInstrcions = []
        this.subform1.reset(this.subform1.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        if (!this.IsFromCentralizedPage) {
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.personelid + "/nurseinstrs")
        }

      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  showDrugSelectionHandler() {
  }

  onDeSelectAll($event) {
  }

  onNurseSelect($event) {
  }

  onItemDeSelect($event) {
  }

  showMe() {
    this.showInstructionModal = true
  }

  instructionSelectionEvent($event) {
    this.selectedInstructionCodes = $event
    this.isInstructionInputValid = true
    this.showInstructionModal = false
  }

  matStepChange(event) {
    if (event.selectedIndex == 1) {
      this.getNurseInstructionsFromDB()
    }
  }

  getNurseInstructionsFromDB() {
    let filter = ""
    this.caretxMedicalProviderService.getNurseInstructions(this.personelid, null, "").subscribe(
      data => {
        this.retreivedNurseInstrcions = data
      },
      err => {
        console.log("Error", err)
        this.retreivedNurseInstrcions = []

      }
    )
  }

  OnstopBtnClick($event, nurseInst) {
  }

  OncancelBtnClick($event, nurseInst) {
  }


  SetNumberOfTimeBoxes() {

    switch (this.frequencyParam) {
      case 1:

        this.timeX1 = this.datepipe.transform(new Date("01/01/01 08:00:00"), 'HH:mm:ss');

        break
      case 2:

        this.timeX1 = this.datepipe.transform(new Date("01/01/01 08:00:00"), 'HH:mm:ss');
        this.timeX2 = this.datepipe.transform(new Date("01/01/01 12:00:00"), 'HH:mm:ss');
        break
      case 3:

        this.timeX1 = this.datepipe.transform(new Date("01/01/01 08:00:00"), 'HH:mm:ss');
        this.timeX2 = this.datepipe.transform(new Date("01/01/01 12:00:00"), 'HH:mm:ss');
        this.timeX3 = this.datepipe.transform(new Date("01/01/01 16:00:00"), 'HH:mm:ss');
        break
      case 4:

        this.timeX1 = this.datepipe.transform(new Date("01/01/01 08:00:00"), 'HH:mm:ss');
        this.timeX2 = this.datepipe.transform(new Date("01/01/01 12:00:00"), 'HH:mm:ss');
        this.timeX3 = this.datepipe.transform(new Date("01/01/01 16:00:00"), 'HH:mm:ss');
        this.timeX4 = this.datepipe.transform(new Date("01/01/01 20:00:00"), 'HH:mm:ss');
        break
      case 5:
        this.timeX1 = this.datepipe.transform(new Date("01/01/01 08:00:00"), 'HH:mm:ss');
        this.timeX2 = this.datepipe.transform(new Date("01/01/01 12:00:00"), 'HH:mm:ss');
        this.timeX3 = this.datepipe.transform(new Date("01/01/01 16:00:00"), 'HH:mm:ss');
        this.timeX4 = this.datepipe.transform(new Date("01/01/01 20:00:00"), 'HH:mm:ss');
        this.timeX5 = this.datepipe.transform(new Date("01/01/01 23:59:00"), 'HH:mm:ss');
        break

    }
    this.SetTimeFields("time1", 8, 0)
    this.SetTimeFields("time2", 12, 0)
    this.SetTimeFields("time3", 16, 0)
    this.SetTimeFields("time4", 20, 0)
    this.SetTimeFields("time5", 23, 59)

  }

  TimeChanged($event) {
    let val = $event.currentTarget.value
    var splitted = val.split(":", 2);
    let Hours = Number(splitted[0])
    let Minutes = Number(splitted[1])
    this.SetTimeFields($event.currentTarget.id, Hours, Minutes)
  }

  SetTimeFields(timeFieldId: string, Hours: number, Minutes: number) {
    switch (timeFieldId) {
      case "time1":
        this.datetimeX1 = new Date();
        this.datetimeX1.setHours(Hours)
        this.datetimeX1.setMinutes(Minutes)
        break
      case "time2":
        this.datetimeX2 = new Date();
        this.datetimeX2.setHours(Hours)
        this.datetimeX2.setMinutes(Minutes)
        break
      case "time3":
        this.datetimeX3 = new Date();
        this.datetimeX3.setHours(Hours)
        this.datetimeX3.setMinutes(Minutes)
        break
      case "time4":
        this.datetimeX4 = new Date();
        this.datetimeX4.setHours(Hours)
        this.datetimeX4.setMinutes(Minutes)
        break
      case "time5":
        this.datetimeX5 = new Date();
        this.datetimeX5.setHours(Hours)
        this.datetimeX5.setMinutes(Minutes)
        break
      default:

        break; 

    }
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty() {
    return false;
  }

  ReturnToCentralPage() {
    this.backToCentralPage.emit()
  }
}
