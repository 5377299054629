import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-caretex-textarea-dialog',
  templateUrl: './caretex-textarea-dialog.component.html',
  styleUrls: ['./caretex-textarea-dialog.component.css']
})
export class CaretexTextareaDialogComponent implements OnInit {

  Disabled:boolean = false
  UserText:string
  Title:string
  TextFieldName:string

  constructor(
    private dialogRef: MatDialogRef<CaretexTextareaDialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any) {

      }


  ngOnInit(): void {
    this.UserText = this.data.UserText
    this.Disabled = this.data.Disabled
    this.Title = this.data.Title
    this.TextFieldName = this.data.TextFieldName
  }

  submit(){
    this.dialogRef.close(this.UserText);
  }


}
