import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { AuthService } from '../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { PocketTracking } from '../../models/socialpockettrack';
import { CaretxSocialProviderService } from '../Social.data-provider.service';

@Component({
  selector: 'app-pocket-tracking',
  templateUrl: './pocket-tracking.component.html',
  styleUrls: ['./pocket-tracking.component.css']
})
export class PocketTrackingComponent extends CaretexComponent implements OnInit {

  isrecordClosed: boolean
  currentPocketTrack: PocketTracking = new PocketTracking()
  id: string
  codeid: string
  @ViewChild('mainform') mainFormElement: NgForm;
  step: number = 0

  moduleName: string = "מעקב דמי כיס"
  constructor(private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private router: Router,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,
    private authServive: AuthService) {
    super(medicalFileService)
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex |טופס רישום סוציאלי');
    this.id = this.route.parent.snapshot.params['id'];
    this.codeid = this.route.snapshot.params.codeid
    this.CheckIfComponentIsDisabled(this.id)
    if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
      this.step = Number(this.route.snapshot.params['stepid']);
    }

    if (this.route.snapshot.params.codeid == 'new') {
      this.getPocketTrackFromDB(this.id, this.route.snapshot.params.codeid, "new")

    } else {
      this.getPocketTrackFromDB(this.id, this.route.snapshot.params.codeid, "")
 
    }
  }

  getPocketTrackFromDB(id: string, trackId: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + trackId  + '\'' + ')'

    this.caretxSocialProviderService.getPocketTracks(this.id, filter, mode).subscribe((data: PocketTracking[]) => {
      if (data.length == 1) {
        this.currentPocketTrack = new PocketTracking(data[0])

        if (this.codeid == 'new') {
          this.currentPocketTrack.PersonelID = this.id
          this.currentPocketTrack.Status = "A"
          this.currentPocketTrack.CreatedById = this.authServive.LoggedInUser.UID
        }
        if (this.currentPocketTrack.Status == "C") {
          this.isrecordClosed = true

        } else
          this.isrecordClosed = false
      }
      else if (data.length == 0) {
        let errorMsg = "המערכת לא הצליחה לייצר רשומה חדשה"
        this.toastrService.Error("", errorMsg)
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }


  setStep(index: number) {
    this.step = index;
  }



  BackToList() {
    this.router.navigateByUrl("/caretexfile/" + this.id + "/pocket_track")
  }

  LockDocument() {
    if (this.currentPocketTrack.Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למעול רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תנעל',
        allowOutsideClick: false,
        allowEscapeKey: false,       
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DoLock()
        }
      })
    }
  }

  DoLock() {
    this.currentPocketTrack.Status = "C"
    this.currentPocketTrack.ClosedById = this.authServive.LoggedInUser.UID
    this.currentPocketTrack.ClosedAt = new Date()
    this.DoUpdate()

  }

  Save() {
    this.currentPocketTrack.DateOfDeposit = new Date(this.currentPocketTrack.DateOfDeposit)

    if (this.codeid == 'new') {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    this.currentPocketTrack.UpdatedAt = new Date()
    this.currentPocketTrack.CreatedAt = this.currentPocketTrack.UpdatedAt
    this.currentPocketTrack.CreatedById = this.authServive.LoggedInUser.UID
    this.currentPocketTrack.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.createPocketTrack(this.currentPocketTrack).subscribe({
      next: data => {
        this.currentPocketTrack = new PocketTracking(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/pocket_track/" + this.currentPocketTrack.ID+"/step/"+this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  DoUpdate() {
    this.currentPocketTrack.UpdatedAt = new Date()
    this.currentPocketTrack.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.UpdatePocketTrack(this.currentPocketTrack).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentPocketTrack = new PocketTracking(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/pocket_track/" + this.currentPocketTrack.ID+"/step/"+this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return  this.mainFormElement.dirty
  }

  deleteRow(i){

  }

}
