import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ArchiveAlert, MedicalFile } from 'src/app/models/customer';
import { MedicalFileProviderService } from '../../medicalfile-services/medicalfile.data-provider.service';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSharedModulesProviderService } from 'src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { MedicalFileStatus } from 'src/app/models/generalhousedsetups';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { CaretxNursingProviderService } from 'src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { CaretxSocialProviderService } from 'src/app/caretx-social-module/Social.data-provider.service';
import { DataService } from 'src/app/services/data.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-medicalfile-lock',
  templateUrl: './medicalfile-lock.component.html',
  styleUrls: ['./medicalfile-lock.component.scss']
})
export class MedicalfileLockComponent extends CaretexComponent implements OnInit {
  TabId = "medicalfiles"
  medicalFile = new MedicalFile()
  LoggedInUser: string
  id: string
  statuses: MedicalFileStatus[] = []
  selectedStatus: string
  Alerts: ArchiveAlert[] = []
  public addTagNowRef: (name) => void;
  showAlertMsg: boolean = false

  constructor(private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private caretxSetupService: CaretxSetupsProviderService,
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private router: Router,
    private route: ActivatedRoute,
    private _ar: ActivatedRoute,
    private data: DataService,
    public dialog: MatDialog,
    private sharedServices: CaretxSharedModulesProviderService,
    private authService: AuthService,
    private toastrService: ToasterService) {
    super(medicalFileService)


    this.caretxSetupService.GetMedicalStatuses().subscribe((data => {
      this.statuses = data
    }))
    this.addTagNowRef = this.CreateFileStatus.bind(this);
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex |נעילת כרטיס מטופל');
    this.LoggedInUser = this.authService.LoggedInUser.UID
    this.id = this._ar.snapshot.params['id'];
    this.getArchiveAlerts()
    this.getMedicalFile()

  }

  getMedicalFile() {
    this.medicalFileService.getMedicalFile(this.id).subscribe(data => {
      this.medicalFile = new MedicalFile(data)
      this.medicalFileService.CurrentOpenedFile = this.medicalFile
    })
  }

  // Get Alerts
  getArchiveAlerts() {
    this.medicalFileService.SendFileToArchive(this.id).subscribe((data: ArchiveAlert[]) => {
      if (data.length > 0) {
        this.Alerts = data
        this.showAlertMsg = true
      }
      else {
        this.Alerts = []
        this.showAlertMsg = false
      }

    });
  }

  OpenMedicalFile() {
    this.medicalFileService.CurrentOpenedFile = this.medicalFile
    this.data.updateIsNotesShowed(false)
    this.router.navigateByUrl("/medicalfiles/" + this.id)
  }

  CheckIfDocumentIsDirty() {
    return false
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CreateFileStatus(val) {
    // Create New Uom 
    var newStatus = new MedicalFileStatus()
    newStatus.New = true
    newStatus.editMode = true
    newStatus.CreatedAt = new Date()
    newStatus.UpdatedAt = new Date()
    newStatus.RealmID = this.authService.LoggedInTenantID
    newStatus.StatusDescr = val

    let statuses: MedicalFileStatus[] = []
    statuses.push(newStatus)

    this.caretxSetupService.CreateUpdateMedicalStatus(statuses).subscribe({
      next: data => {
        this.getStatusesFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  getStatusesFromDB() {
    this.caretxSetupService.GetMedicalStatuses().subscribe(
      data => {
        this.statuses = data
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      err => {
        this.statuses = []

      }
    )
  }

  LockFile() {
    // Check If Reason is supplied 
    if (this.selectedStatus == undefined || this.selectedStatus == null || this.selectedStatus =="" ){
      Swal.fire({
        title: "הודעת מערכת",
        text: "סיבת נעילת תיק רפואי היא שדה חובה ! ",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'הבנתי',
        allowOutsideClick: false,
        allowEscapeKey: false,       
      })
    }else {
      this.medicalFileService.LockMedicalFile(this.id , this.selectedStatus).subscribe({
        next: data => {
          this.toastrService.Sucesss("", this.toastrService.successMsg)
          this.router.navigate(['medfiles']);
        },
        error: error => {
          this.toastrService.Error("", error.error)
        }
      })
    }
    




  }

  LockRecordByType(alert: ArchiveAlert) {
    switch (alert.RecordType) {
      case "medicaladmission":
        // Lock Admission
        alert.MedicalAdmission.Status = "C"
        alert.MedicalAdmission.Closed_at = new Date()
        alert.MedicalAdmission.ClosedById = this.authService.LoggedInUser.UID
        this.caretxMedicalProviderService.updateMeidcalAdmission(alert.MedicalAdmission).subscribe({
          next: data => {
            this.toastrService.Sucesss("", this.toastrService.successMsg)
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
              return false;
            }
            this.router.onSameUrlNavigation = 'reload';
            this.medicalFileService.ResetCachedMedicalFile(this.id)
            this.router.navigateByUrl("/medicalfilelock/" +  this.id )
          },
          error: error => {
            this.toastrService.Error("", error.error.error)
          }
        })
        break
      case "medicalrelease":
        alert.MedicalRelease.Status = "C"
        alert.MedicalRelease.Closed_at = new Date()
        alert.MedicalRelease.ClosedById = this.authService.LoggedInUser.UID
        this.caretxMedicalProviderService.updateMedicalRelease(alert.MedicalRelease).subscribe({
          next: data => {
            this.toastrService.Sucesss("", this.toastrService.successMsg)
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
              return false;
            }
            this.router.onSameUrlNavigation = 'reload';
            this.medicalFileService.ResetCachedMedicalFile(this.id)
            this.router.navigateByUrl("/medicalfilelock/" +  this.id )
          },
          error: error => {
            this.toastrService.Error("", error.error.error)
          }
        })

        break
      case "nurseadmission":
        alert.NurseAdmission.Status = "C"
        alert.NurseAdmission.Closed_at = new Date()
        alert.NurseAdmission.ClosedById = this.authService.LoggedInUser.UID
        this.caretxNursingProviderService.updateurseAdmission(alert.NurseAdmission).subscribe({
          next: data => {
            this.toastrService.Sucesss("", this.toastrService.successMsg)
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
              return false;
            }
            this.router.onSameUrlNavigation = 'reload';
            this.medicalFileService.ResetCachedMedicalFile(this.id)
            this.router.navigateByUrl("/medicalfilelock/" +  this.id )
          },
          error: error => {
            this.toastrService.Error("", error.error.error)
          }
        })
        break
      case "nurserelease":
        /* alert.NurseRelease.Status = "C"
         alert.NurseRelease. = new Date()
         alert.NurseRelease.ClosedById = this.authService.LoggedInUser.UID
         this.caretxNursingProviderService.updateurseAdmission(alert.NurseAdmission).subscribe({
           next: data => {
             this.toastrService.Sucesss("", this.toastrService.successMsg)
             this. getArchiveAlerts()
           },
           error: error => {
             this.toastrService.Error("", error.error.error)
           }
         })*/
        break
      case "socialadmission":
        alert.SocialAdmission.Status = "C"
        alert.SocialAdmission.Closed_at = new Date()
        alert.SocialAdmission.ClosedById = this.authService.LoggedInUser.UID
        this.caretxSocialProviderService.UpdateSocialAdmission(alert.SocialAdmission).subscribe({
          next: data => {
            this.toastrService.Sucesss("", this.toastrService.successMsg)
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
              return false;
            }
            this.router.onSameUrlNavigation = 'reload';
            this.medicalFileService.ResetCachedMedicalFile(this.id)
            this.router.navigateByUrl("/medicalfilelock/" +  this.id )
          },
          error: error => {
            this.toastrService.Error("", error.error.error)
          }
        })
        break
      case "socialrelease":
        alert.SocialRelease.Status = "C"
        alert.SocialRelease.Closed_at = new Date()
        alert.SocialRelease.ClosedById = this.authService.LoggedInUser.UID
        this.caretxSocialProviderService.UpdateSocialRelease(alert.SocialRelease).subscribe({
          next: data => {
            this.toastrService.Sucesss("", this.toastrService.successMsg)
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
              return false;
            }
            this.router.onSameUrlNavigation = 'reload';
            this.medicalFileService.ResetCachedMedicalFile(this.id)
            this.router.navigateByUrl("/medicalfilelock/" +  this.id )
          },
          error: error => {
            this.toastrService.Error("", error.error.error)
          }
        })
        break

    }
  }

}
