import { Component, OnInit } from '@angular/core';
import { CaretexComponent } from '../../../shared/CaretexComponent';
import { FormControl, FormGroup } from '@angular/forms';
import { MedicalFileProviderService } from '../../../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../services/toastr.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';

import { CaretxMedicalProviderService } from '../../doctor-services/Medical.data-provider.service';
import { Consultatory } from 'src/app/models/expertconsulatant';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-expert-consultants',
  templateUrl: './expert-consultants.component.html',
  styleUrls: ['./expert-consultants.component.css']
})
export class ExpertConsultantsComponent extends CaretexComponent implements OnInit {
  id: string
  currentRowIndex: number
  pdfContent: any
  expertConsultants: Consultatory[] = []
  moduleName: string = "יועצים / מומחים"
  totalNumOfRecords: Number = 0


  RecordStatus = [{ id: "A", desc: "טופס יעוץ פתוח" }, { id: "C", desc: "טופס יעוץ נעול"  }]

  formData: any[]

  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), //, [Validators.required, Validators.email]),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });



  
  constructor(
    private _title: Title, 
    private toastrService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,) {super(medicalFileServive) }

  ngOnInit(): void {
    this._title.setTitle('Caretex |  יועצים/מומחים');
    if(this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.id)
    this.getListOfRecordsFromDB("all")
  }


  getListOfRecordsFromDB(filter:string) {
    this.caretxMedicalProviderService.getConsultatory(this.id, filter,"list").subscribe(
      data => {
        this.expertConsultants = data
        this.totalNumOfRecords = data.length}
      ,
      err => {
        this.expertConsultants = []
      }
    )
  }


  OnSDrillToMedicalAdmission($event, openedRecord : Consultatory) {

    //admissionid/locked
    if (openedRecord.Status == "C"){
      this.router.navigateByUrl("caretexfile/" + this.id + "/consultatory/" + openedRecord.ID + "/locked")
    }else{
      this.router.navigateByUrl("caretexfile/" + this.id + "/consultatory/" + openedRecord.ID)
    }

  }



  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }


  deleteRow(i) {
    this.currentRowIndex = i
    if (this.expertConsultants[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRecord(this.expertConsultants[this.currentRowIndex])
        }
      })
    }
  }


  deleteRecord(record: Consultatory) {
    this.caretxMedicalProviderService.deleteConsultatory(this.id, record.ID).subscribe({
      next: data => {
        this.getListOfRecordsFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }



  AddNewExperConsultant($event) {
    let filter = '(status=\'A\')'
    this.caretxMedicalProviderService.getConsultatory(this.id, filter,"list").subscribe((data: Consultatory[]) => {
      if (data.length > 0) {
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
      else {
        this.router.navigateByUrl("caretexfile/" + this.id + "/consultatory/new")
      }
    });

  }

  SearchWithFilter($event){

  }

}
