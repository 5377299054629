import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialTrack } from 'src/app/models/socialobservation';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxSocialProviderService } from '../Social.data-provider.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-socialtrackslist',
  templateUrl: './socialtrackslist.component.html',
  styleUrls: ['./socialtrackslist.component.css']
})
export class SocialtrackslistComponent extends CaretexComponent implements OnInit {
  id: string
  canSocailPagesBeEditted: boolean
  socialTracks: SocialTrack[] = []
  currentRowIndex: number
  moduleName:string = "מעקבים סוציאלים"
  formData : any[] 
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), //, [Validators.required, Validators.email]),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0

  RecordStatus = [{id:"A" , desc:"רשומה פעילה"},{id:"C" , desc:"רשומה נעולה"}]

  
  constructor(private router: Router,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private route: ActivatedRoute) { super(medicalFileService)}

  ngOnInit(): void {
    this._title.setTitle('Caretx | קבלות סיעודיות');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id) 
    this.getSocialTracksFromDB("all")
    this.formData=  [
      {"formcontrolname":"status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
      {"formcontrolname":"created_at_from","label":"מתאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"created_at_to","label":"עד תאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_from","label":"תאריך נעילה מ" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_to","label":"תאריך נעילה עד" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},

    ]
  }

  getSocialTracksFromDB(filter :string) {
    this.caretxSocialProviderService.getSocialTrack(this.id, filter).subscribe(
      data => {
        this.socialTracks = data
        this.totalNumOfRecords = data.length},
      err => {
        this.socialTracks = []
      }
    )
  }

  SearchWithFilter($event) {
    this.getSocialTracksFromDB($event)
  }


  AddNewTrack($event) {
    let filter = '(status=\'A\')'
    this.caretxSocialProviderService.getSocialTrack(this.id, filter).subscribe((data: SocialTrack[]) => {
      if (data.length == 0)
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialtracks/new")
      else {
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
    });
  }


  deleteRow(i) {
    this.currentRowIndex = i
    if (this.socialTracks[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,       
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRecord(this.socialTracks[this.currentRowIndex])
        }
      })
    }
  }


  deleteRecord(record: SocialTrack) {
    let filter = '(id=' + '\'' + record.ID + '\'' + ')'
    this.caretxSocialProviderService.deleteSocialTrack(filter, this.id).subscribe({
      next: data => {
        this.getSocialTracksFromDB("all")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }

  OnSDrillToSocialAdmission($event, track) {
    this.router.navigateByUrl("caretexfile/" + this.id + "/socialtracks/" + track.ID)
  }
  generateReport($event, track) {

  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }

}
