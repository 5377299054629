import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LabCheck, LabCheckHeader, LabOrderCategories, LabOrderCheck, LabTemplate } from 'src/app/models/testlabchecks';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { NgForm } from '@angular/forms';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-newlaboratorycheck',
  templateUrl: './newlaboratorycheck.component.html',
  styleUrls: ['./newlaboratorycheck.component.scss']
})
export class NewlaboratorycheckComponent extends CaretexComponent implements OnInit {
  @ViewChild('f') mainFormElement: NgForm;
  id: string
  displayName: string
  labresultheaderId: string

  isNew: boolean = false
  isEdit: boolean = false;
  labsCategories: LabOrderCategories[] = []
  labOrderCheck: LabOrderCheck[] = []
  LabResult: LabCheckHeader
  hmo: string
  dropdownSettings: any = {};
  dropdownCategorySettings: any = {}
  selectedItems = []
  selectedLabCategory: LabOrderCategories
  closeDropdownSelection = false;
  labCatddldisabled: boolean = false
  selectedItems1 = [];
  dropdownSettings1 = {};
  moduleName: string = "תוצאת בדיקה"
  ShowLabSelectionDropDown: boolean
  showLabcodeResultsModal:boolean
  personelLabResultModalId:string
  personelLabResultModalTarget:string
  currentSelectedLab:LabCheck
  value: boolean = false;
  childNotifier: Subject<LabCheck> = new Subject<LabCheck>();

  
  constructor(
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private _labService: CaretxMedicalProviderService,
    private _setupsService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToasterService) {
    super(medicalFileService)
    this.LabResult = new LabCheckHeader()
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex | מעבדה');
    this.id = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.id)
    this.labresultheaderId = this.route.snapshot.params.labheaderid;
    this.personelLabResultModalId = 'measures-modal'
    this.personelLabResultModalTarget = '#measuresModal'

    this.medicalFileService.openedFile.subscribe(data => {
      this.hmo = data.HMO
    })
    if (this.hmo == undefined) {
      //Get Medical Files
      this.medicalFileService.getMedicalFile(this.id).subscribe(data => {
        this.hmo = data.HMO
        this.doInitialization()
      })
    } else {
      this.doInitialization()
    }
  }

  doInitialization() {
    // Get LabCategories :
    this._setupsService.GetCategoryLabs(this.hmo, "").subscribe(data => {
      this.labsCategories = data

      if (this.labsCategories.length == 0) {
        let tmp = new LabOrderCategories()
        tmp.CategoryId = "na"
        tmp.CategoryDescr = "לא נמצאו ערכים"
        tmp.HMO = this.hmo
        let subTmp = new LabOrderCheck()
        subTmp.CategoryId = "na"
        subTmp.HMO = this.hmo
        subTmp.PrimaryCode = "na"
        subTmp.Name = "לא נמצאו ערכים"

        this.labsCategories.push(tmp)
        this.labsCategories[0].LabOrders.push(subTmp)
      }

      // Create Test Data Till  we have server  side ready 
      if (this.labresultheaderId == 'new') {
        this.isNew = true
        this.isEdit = false
        this.LabResult.TenantID = this.authServive.LoggedInTenantID
        this.LabResult.UserId = this.authServive.LoggedInUser.UID
        this.LabResult.HMO =  this.medicalFile1.HMO
      }
      else {
        this.isNew = false
        this.isEdit = false
        this.getLabCheckFromDB()
      }
    })

    this.dropdownSettings = {
      singleSelection: true,
      idField: "PrimaryCode",
      textField: "Name",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.dropdownSettings1 = {
      singleSelection: true,
      idField: "CategoryId",
      textField: "CategoryDescr",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
  }

  toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings, { closeDropDownOnSelection: this.closeDropdownSelection });
  }

  onItemSelect(item: any) {
    this.LabResult.Labchecks = []
    let filter = '(primary_code=' + '\'' + item.PrimaryCode + '\'' + ')'
    this._labService.getLaboratoryCheckTemplate(this.hmo, filter).subscribe((data: LabTemplate[]) => {
      // Create the set of the checks 
      this.LabResult.CopyTemplateLine(data, this.id, this.authServive.LoggedInTenantID)
      this.LabResult.Labchecks = this.LabResult.Labchecks.sort((a, b) => (a.LabTemplate.Sequence > b.LabTemplate.Sequence) ? 1 : -1)
    });
  }


  getLabCategoriesFromDB() {
    this._setupsService.GetLabTypes(this.hmo).subscribe(data => {
      this.labsCategories = data
    })
  }

  getLabCheckFromDB() {
    let filter = '(id=' + '\'' + this.labresultheaderId + '\'' + ')'
    this._labService.getLaboratoryResultsHeaders(this.id, filter).subscribe(data => {

      if (data.length == 1) {
        this.LabResult = new LabCheckHeader(data[0])
        this.selectedItems1 = [this.LabResult.LabCategory];

        // Retreive All LabCheck types
        let index = this.labsCategories.findIndex(x => x.CategoryId == this.LabResult.CategoryId)
        this.labOrderCheck = this.labsCategories[index].LabOrders


        this.labCatddldisabled = true
        this.LabResult.Labchecks = this.LabResult.Labchecks.sort((a, b) => (a.LabTemplate.Sequence > b.LabTemplate.Sequence) ? 1 : -1)

        // Get All selected LabCheck
        let selectedLabOrderCheckIds: string[]
        selectedLabOrderCheckIds = this.LabResult.Labchecks.map(item => item.LabTemplate.PrimaryCode)
          .filter((value, index, self) => self.indexOf(value) === index)

        var c = this.labOrderCheck.filter(function (objFromA) {
          return selectedLabOrderCheckIds.find(function (objFromB) {
            return objFromA.PrimaryCode === objFromB
          })
        })

        this.selectedItems = c

      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  saveLabResult() {
    this.mainFormElement.onSubmit(undefined);
  }

  saveTest() {
    this.LabResult.PersonelID = this.id
    this.LabResult.UserId = this.authServive.LoggedInUser.UID
    this.LabResult.Tested_at = new Date()

    //Missing TenantID

    if (this.isNew)
      this.DoSave()
    else
      this.DoUpdate()
  }

  DoSave() {
    this._labService.CreateLabResults(this.LabResult).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.LabResult = new LabCheckHeader(data)
        //Route To details
        // this.dataservice.setCurrentSavedLabcheck(this.LabResult)
        this.router.navigateByUrl("caretexfile/" + this.id + '/laboratory/results' + this.LabResult.ID) //"/editlaboratory/" + this.LabResult.ID)

      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  DoUpdate() {
    this._labService.UpdateLabResults(this.LabResult).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.LabResult = new LabCheckHeader(data)
        //Route To details
        // this.dataservice.setCurrentSavedLabcheck(this.LabResult)
        this.router.navigateByUrl("caretexfile/" + this.id + '/laboratory/results' + this.LabResult.ID)

      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  onCategorySelect($event) {
    let index = this.labsCategories.findIndex(x => x.CategoryId == $event.CategoryId)
    this.LabResult.CategoryId = $event.CategoryId
    this.labOrderCheck = this.labsCategories[index].LabOrders
    if (this.LabResult.CategoryId == "hematology") {
      this.ShowLabSelectionDropDown = true
      return
    }

    else
      this.ShowLabSelectionDropDown = false


    this.LabResult.Labchecks = []
    let filter = '(test_category=' + '\'' + $event.CategoryId + '\'' + ')'
    this._labService.getLaboratoryCheckTemplate(this.hmo, filter).subscribe((data: LabTemplate[]) => {
      // Create the set of the checks 
      this.LabResult.CopyTemplateLine(data, this.id, this.authServive.LoggedInTenantID)
      this.LabResult.Labchecks = this.LabResult.Labchecks.sort((a, b) => (a.LabTemplate.Sequence > b.LabTemplate.Sequence) ? 1 : -1)



    })
  }


  returnToResultsList() {
    this.router.navigateByUrl("/caretexfile/" + this.id + '/laboratory/results')
  }

  editCheckTest() {
    this.isEdit = !this.isEdit
  }

  onLabOrderhange($event) {

  }


  NewRecord(){
    if (!this.isNew) {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigateByUrl("caretexfile/" + this.id + "/laboratory/results/new")
    }
  }
  closeMeasureResultsModal() {
    this.showLabcodeResultsModal = false
  }

  openMeasuresModal(check: LabCheck) {
    this.currentSelectedLab= check
    this.notifyChild()
    this.personelLabResultModalId = 'measures-modal'
    this.personelLabResultModalTarget = '#measuresModal'
    this.showLabcodeResultsModal = true
  }

  notifyChild() {
    this.value = !this.value;
    this.childNotifier.next(this.currentSelectedLab);
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty(){
    return false;
  }

}
