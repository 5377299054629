import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import { RowInput } from 'jspdf-autotable';
import { Subject } from 'rxjs';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { MedicalDiagnosesProviderService } from 'src/app/caretx-doctors-module/doctor-services/medical-diagnoses.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalFile } from 'src/app/models/customer';
import { Diagnoses } from 'src/app/models/diagnoses';
import { DrugInstruction } from 'src/app/models/drugInstruction';
import { CartexEvent, TabHistory } from 'src/app/models/genericTypes';
import { MedicalAdmission } from 'src/app/models/medicaladmission';
import { AuthService } from 'src/app/services/auth.service';
import { AccessComponenetType, BridgeEnums, OptionObject, OriginModule } from 'src/app/services/enums';
import { ReportingService } from 'src/app/services/reporting.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-medical-admission-locked',
  templateUrl: './medical-admission-locked.component.html',
  styleUrls: ['./medical-admission-locked.component.css']
})
export class MedicalAdmissionLockedComponent extends CaretexComponent implements OnInit {
  @ViewChild('mainform') mainFormElement: NgForm;
  AccessedComponent: AccessComponenetType = AccessComponenetType.SubModule
  moduleName: string = "קבלה רפואית"
  Origin: OriginModule = OriginModule.MedicalAdmission
  Admissionid: string
  personalId: string
  DrugModalId: string
  medicationTitle: string = "תרופות קבועות"
  medicationtype: string = "F"
  //CurrentStep = '';
  saveProcessCompleted: boolean
  isAdmissionClosed: boolean = false
  currentMedicalAdmission: MedicalAdmission
  currentAdmissionReady: boolean
  AdmissionType: string = "MedicalAdmission"
  pastDiagnosesList: Diagnoses[] = []
  CurrentSelectedDrugInstr: DrugInstruction
  tabHistory: TabHistory = new TabHistory()
  caretexEvent: CartexEvent = new CartexEvent()
  DrugFreqs: OptionObject[]
  DrugFreqUoms: OptionObject[]
  DrugUoms: OptionObject[]
  DrugDosageForms: OptionObject[]
  Type: string = AccessComponenetType.SubModule
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  isPhysicalexaminationIsDirty: boolean = false
  isDoctorSummaryDirty: boolean = false
  isDoctorSummaryDirty2: boolean = false
  isDoctorSummaryDirty3: boolean = false
  isMedicalPlanTextDirty: boolean = false
  personelSensitivityModuleChanged: boolean = false
  isfrequentMeasuresDirty: boolean = false
  step: number = 0
  isNewRecord: boolean = false
  DiagnosesList: Diagnoses[]
  isDrugSensitivityDirty: boolean = false
  isOtherSensitivityDirty: boolean = false
  drugInstructionChanged: boolean = false
  zoomInModalData: any;
  dismissModal: boolean = false

  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  selectedFile: MedicalFile
  pdfContent: any



  subject = new Subject<boolean>();
  justRout: boolean
  constructor(
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private bridgeEnum: BridgeEnums,
    private router: Router,
    private medicalDiagnosesService: MedicalDiagnosesProviderService,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    public reportingService: ReportingService,
    private authService: AuthService,
    private route: ActivatedRoute,

    private _ar: ActivatedRoute,) {
    super(medicalFileService)
    bridgeEnum = new BridgeEnums()
    this.DrugFreqs = bridgeEnum.DrugFreqs
    this.DrugFreqUoms = bridgeEnum.DrugFreqUoms
    this.DrugUoms = bridgeEnum.DrugUOMs
    this.DrugDosageForms = bridgeEnum.DrugDosageForm
    this.DrugModalId = "drug-modal"

  }

  ngOnInit(): void {

    if (this.route.parent != null)
      this.personalId = this.route.parent.snapshot.params['id']
    this.Admissionid = this.route.snapshot.params.admissionid
    this.CheckIfComponentIsDisabled(this.personalId)

    this.getMedicalAdmissionById(this.personalId, this.route.snapshot.params.admissionid, "")



  }

  getMedicalAdmissionById(id: string, admissionId: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + admissionId + '\'' + ')'
    this.caretxMedicalProviderService.getMedicalAdmissions(filter, id, 1, mode).subscribe((data: MedicalAdmission[]) => {
      if (data.length == 1) {
        this.currentMedicalAdmission = new MedicalAdmission(data[0])
        this.isAdmissionClosed = (this.currentMedicalAdmission.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }

  NewRecord() {

  }

  BackToMainDocument() {
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/medicaladmissions/list")
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }

  openPDFViewer() {
    let reportTitle = "סיכום קבלה רפואית"
    this.showGeneratedPdf = true
    this.selectedFile = this.medicalFile1
    var doc = new jsPDF()
    let lastYPosition: number
    let xPosTitle:number = 118
    lastYPosition = this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle)
    this.reportingService.setFooter2(doc, this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date())

    lastYPosition += 6
    let label = "תלונה עיקרית:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentMedicalAdmission.MainComplaint == "" ? "לא צויין" : this.currentMedicalAdmission.MainComplaint, this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    label = "מחלה נוכחית:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentMedicalAdmission.CurrentDisease.toString() == "" ? "לא צויין" : this.currentMedicalAdmission.CurrentDisease.toString(), this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    label = "תלונות עבר:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentMedicalAdmission.OldComplaint == "" ? "לא צויין" : this.currentMedicalAdmission.OldComplaint, this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)


    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,10,label, ":אבחנות רפואיות", this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    if (this.currentMedicalAdmission.Diagnoses.length <= 0) {
      lastYPosition = this.reportingService.AddBulletLinesSection(doc, lastYPosition, "לא נמצאו אבחנות פעילות", this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    } else {
      var colHeader1: RowInput[] = [[this.reportingService.reverseString("סוג"), this.reportingService.reverseString("אבחנה")]]
      var colVals1: RowInput[] = []
      this.currentMedicalAdmission.Diagnoses.forEach(medcalDiagnoses => {
        let tmpValRow: RowInput = [this.reportingService.reverseString(medcalDiagnoses.Type), this.reportingService.reverseString(medcalDiagnoses.MedicalDiagnoses.ShortDescription)]
        colVals1.push(tmpValRow)
      });
      lastYPosition = this.reportingService.AddTableToPDF2(doc, lastYPosition, 50, this.medicalFile1, this.currentMedicalAdmission.ClosedBy ,this.authService.LoggedInUser , 
        this.currentMedicalAdmission.Closed_at, new Date(), colHeader1, colVals1,reportTitle,xPosTitle)
    }




    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,10, label, "רגישות לתרופות:", this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    if (this.currentMedicalAdmission.PersonSensitivity != null &&
      this.currentMedicalAdmission.PersonSensitivity.DrugSensitivity != null &&
      this.currentMedicalAdmission.PersonSensitivity.DrugSensitivity.length > 0) {
      var colHeader1: RowInput[] = [[this.reportingService.reverseString("שם תרופה")]]
      var colVals1: RowInput[] = []
      this.currentMedicalAdmission.PersonSensitivity.DrugSensitivity.forEach(drugSensitivity => {
        let tmpValRow: RowInput = [this.reportingService.reverseString(drugSensitivity.Drug.ShortTradeName)]
        colVals1.push(tmpValRow)
      });
      lastYPosition = this.reportingService.AddTableToPDF2(doc, lastYPosition, 50, this.medicalFile1, this.currentMedicalAdmission.ClosedBy ,this.authService.LoggedInUser , 
        this.currentMedicalAdmission.Closed_at, new Date(), colHeader1, colVals1,reportTitle,xPosTitle)
    } else {
      lastYPosition = this.reportingService.AddBulletLinesSection(doc, lastYPosition, "לא נמצאו רגישיות לתרופות", this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    }



    label = "רגישות כללית:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentMedicalAdmission.PersonSensitivity.OtherSensitivity.Description == "" ? "לא צויין" : this.currentMedicalAdmission.PersonSensitivity.OtherSensitivity.Description, this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition + 3, 10,label, ":תרופות קבועות", this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentMedicalAdmission.DrugInstructions.length <= 0) {
      lastYPosition = this.reportingService.AddBulletLinesSection(doc, lastYPosition, "לא נמצאו תרופות קבועות", this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    } else {

      var colHeader: RowInput[] = [[this.reportingService.reverseString("שם תרופה")]]
      var colVals: RowInput[] = []
      this.currentMedicalAdmission.DrugInstructions.forEach(instr => {
        let tmpValRow: RowInput = [this.reportingService.reverseString(instr.Drug.ShortTradeName)]
        colVals.push(tmpValRow)
      });
      lastYPosition = this.reportingService.AddTableToPDF2(doc, lastYPosition, 50, this.medicalFile1, this.currentMedicalAdmission.ClosedBy ,this.authService.LoggedInUser , 
        this.currentMedicalAdmission.Closed_at, new Date(), colHeader, colVals,reportTitle,xPosTitle)
    }



    label = "סיכום קבלה:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentMedicalAdmission.DoctorSummary == "" ? "לא צויין" : this.currentMedicalAdmission.DoctorSummary, this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    label = "הערכת מצב תפקודי:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentMedicalAdmission.DoctorSummary2 == "" ? "לא צויין" : this.currentMedicalAdmission.DoctorSummary2, this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    label = "הערכת מצב מנטלי:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentMedicalAdmission.DoctorSummary3 == "" ? "לא צויין" : this.currentMedicalAdmission.DoctorSummary3, this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    label = "הערכת מצב נפשי:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentMedicalAdmission.DoctorSummary4 == "" ? "לא צויין" : this.currentMedicalAdmission.DoctorSummary4,this.currentMedicalAdmission.ClosedBy,this.authService.LoggedInUser, this.currentMedicalAdmission.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)




    let file: Blob = doc.output('blob')
    this.pdfContent = URL.createObjectURL(file);
    this.pdfViewer.pdfSrc = file
    this.pdfViewer.refresh();

  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }



}
