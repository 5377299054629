import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Drug } from 'src/app/models/drug';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';

@Component({
  selector: 'app-new-drug-form',
  templateUrl: './new-drug-form.component.html',
  styleUrls: ['./new-drug-form.component.css']
})
export class NewDrugFormComponent implements OnInit {

  @Input() showModal: boolean
  @Output() newDrugModalClosed = new EventEmitter<boolean>();
  Drug: Drug = new Drug()

  constructor(
    private authServive: AuthService,
    private toastrService: ToasterService,
    private caretxSharedlService: CaretxSharedModulesProviderService,) { }

  ngOnInit(): void {
  }

  SaveNewDrug() {
    this.Drug.CretedById = this.authServive.LoggedInUser.UID


    this.caretxSharedlService.createNewMedicalDrug(this.Drug).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)

      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }
  closeModal() {
    this.showModal = false
    this.newDrugModalClosed.emit(true)
  }


}
