import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { MedicalObservation } from 'src/app/models/Observations';
import { AuthService } from 'src/app/services/auth.service';
import { CommeonHelperService } from 'src/app/services/common.data-helper.service';
import { CaretexOperation, sideNaveGroups } from 'src/app/services/enums';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CommonInterface } from 'src/app/shared/SharedInterface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-medicaltrack',
  templateUrl: './medicaltrack.component.html',
  styleUrls: ['./medicaltrack.component.css']
})
export class MedicaltrackComponent extends CaretexComponent implements OnInit, CommonInterface {

  id: string
  currentRowIndex: number
  medicalObservations: MedicalObservation[] = []
  childCaretexModule = "MedicalobservationComponent"
  currentCaretexModule = "MedicaltrackComponent"
  moduleName: string = "מעקבים רפואיים"
  constructor(private _title: Title,
    private commonHelper: CommeonHelperService,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,
    private medicalObservationservice: CaretxMedicalProviderService,
    private router: Router,
    private _ar: ActivatedRoute,) {
      super(medicalFileServive)

  }
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Medical;

  ngOnInit(): void {
    this._title.setTitle('Caretex|מעקבים רפואיים');
    this.id = this.id = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.id)
    this.getMedicalObservationsFromDB()
  }


  getMedicalObservationsFromDB() {


    this.medicalObservationservice.getMedicalObservations("",this.id, "list","1").subscribe(
      data => {
        this.medicalObservations = data
        this.PrepareDataBeforeDisplaying()
      },
      err => {
        this.medicalObservations = []
      }
    )


  }


  OnSDrillToMedicalObservationRecordb($event, medicalTrack) {
    this.router.navigateByUrl("caretexfile/" + this.id + "/medicalobservations/" + medicalTrack.ID)
  }

  AddNewMedicalTrack($event) {
    if (this.authServive.isOperationAllowed(CaretexOperation.Create, this.childCaretexModule))
      this.router.navigateByUrl("caretexfile/" + this.id + "/medicalobservations/new")
  }

  PrepareDataBeforeDisplaying() {
    if (this.medicalObservations != undefined && this.medicalObservations.length > 0) {
      this.medicalObservations.forEach(row => {
        row.Locked = this.commonHelper.diff_hours(new Date(), row.CreatedAt) > 24 ? true : false
      })
    }
  }

  deleteRow(i) {
    this.currentRowIndex = i

    if (!this.medicalObservations[i].Locked) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteObservation(this.medicalObservations[this.currentRowIndex])
        }
      })
    }
  }
  deleteObservation(observation: MedicalObservation) {
    this.medicalObservationservice.deleteObservation(this.id, observation.ID).subscribe({
      next: data => {
        this.getMedicalObservationsFromDB()
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }


}
