import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BedSoreLocation } from 'src/app/models/generalhousedsetups';
import { AuthService } from 'src/app/services/auth.service';
import { DialogResult } from 'src/app/services/enums';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';

@Component({
  selector: 'app-bed-soreslocations',
  templateUrl: './bed-soreslocations.component.html',
  styleUrls: ['./bed-soreslocations.component.css']
})
export class BedSoreslocationsComponent extends CaretexComponent implements OnInit {

 
  TabId:string = "main"
  bedsorelocations:BedSoreLocation[]  = []
  
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private router: Router,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {
      super(medicalFileServive)
     }

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getBedSoreLocationsFromDB()
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.bedsorelocations.some(row => {
      return row.New || row.editMode
    })
    return  hospitalsLisChanged
  }


  getBedSoreLocationsFromDB(){
    this.caretxSetupsProviderService.GetBedSoreLocations(this.authServive.LoggedInTenantID).subscribe(
      data => {
        this.bedsorelocations = data
      },
      err => {
        console.log("Error", err)
        this.bedsorelocations = []

      }
    )
  }


  BackToList(){
    this.router.navigate(['system_setups']);
  }

  addNewSoreLocation(){
    var newBedSoreLocation = new BedSoreLocation()
    
    newBedSoreLocation.New = true
    newBedSoreLocation.editMode = true
    newBedSoreLocation.CreatedAt = new Date()
    newBedSoreLocation.UpdatedAt = new Date()
    newBedSoreLocation.RealmID = this.authServive.LoggedInTenantID
    this.bedsorelocations.unshift(newBedSoreLocation)
  }

  deleteRow(i){

    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.bedsorelocations[i].New){
          this.caretxSetupsProviderService.DeleteBedSoreLocation(this.authServive.LoggedInTenantID, this.bedsorelocations[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.bedsorelocations.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.bedsorelocations.splice(this.currentRowIndex, 1);
      }
    })
  }




  editRow(i){
    this.bedsorelocations[i].editMode = true
    this.bedsorelocations[i].Modified = true
  }

  cancelEditRow(i){
    this.bedsorelocations[i].editMode  = false;
    this.bedsorelocations[i].Modified = false
  }



  updateBedSoreLocation($event){
     this.caretxSetupsProviderService.CreateBedSoreLocations(this.authServive.LoggedInTenantID,this.bedsorelocations).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getBedSoreLocationsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })  


    
  }

}
