import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BedSoreSide } from '../../../../../src/app/models/generalhousedsetups';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';


@Component({
  selector: 'app-bedsoresides',
  templateUrl: './bedsoresides.component.html',
  styleUrls: ['./bedsoresides.component.css']
})
export class BedsoresidesComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  bedsoresides: BedSoreSide[] = []
  currentRowIndex: number
  deleteSysMsgId: string
  deleteAlert: string
  alertTitle: string
  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private router: Router,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {
    super(medicalFileServive)
  }

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getBedSoreSidesFromDB()
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  BackToList() {
    this.router.navigate(['system_setups']);
  }
  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.bedsoresides.some(row => {
      return row.New || row.editMode
    })
    return hospitalsLisChanged
  }

  getBedSoreSidesFromDB() {
    this.caretxSetupsProviderService.GetBedSoreSides(this.authServive.LoggedInTenantID).subscribe(
      data => {
        this.bedsoresides = data
      },
      err => {
        console.log("Error", err)
        this.bedsoresides = []
      }
    )
  }

  addNewSoreSide() {
    var newBedSoreSide = new BedSoreSide()
    newBedSoreSide.New = true
    newBedSoreSide.editMode = true
    newBedSoreSide.CreatedAt = new Date()
    newBedSoreSide.UpdatedAt = new Date()
    newBedSoreSide.RealmID = this.authServive.LoggedInTenantID
    this.bedsoresides.unshift(newBedSoreSide)
  }

  deleteRow(i) {
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.bedsoresides[i].New) {
          this.caretxSetupsProviderService.DeleteBedSoreSide(this.authServive.LoggedInTenantID, this.bedsoresides[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.bedsoresides.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
          this.bedsoresides.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i) {
    this.bedsoresides[i].editMode = true
    this.bedsoresides[i].Modified = true
  }

  cancelEditRow(i) {
    this.bedsoresides[i].editMode = false;
    this.bedsoresides[i].Modified = false
  }

  updateBedSoreSide($event) {
    this.caretxSetupsProviderService.CreateBedSoreSides(this.authServive.LoggedInTenantID, this.bedsoresides).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getBedSoreSidesFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

}
