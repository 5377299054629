import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { share } from 'rxjs/operators';
import { MedicalFileProviderService } from  '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalFile } from '../../../../../src/app/models/customer';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { PersonelSensitivity } from '../../../../../src/app/models/drugsenstivity';
import { CaretxMedicalProviderService } from '../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';


@Component({
  selector: 'app-generalsensitivitybanner',
  templateUrl: './generalsensitivitybanner.component.html',
  styleUrls: ['./generalsensitivitybanner.component.scss']
})
export class GeneralsensitivitybannerComponent implements OnInit {
  id : string
  medicalFile$:Observable<MedicalFile>
  @Input() TextToDisplay :String
  @Input() TextToDisplay2 :String
  showContent:boolean = false
  PersonelSensitivity: PersonelSensitivity

  @Input() PersonalId: string
  @Input() IsFromCentralizedPage: boolean = false


  constructor(
    private authService: AuthService,
    private router: Router,
    private _ar: ActivatedRoute, 
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private medicalFileService:MedicalFileProviderService,) { }


  ngOnInit(): void {
    this.id =  this._ar.parent.snapshot.params['id']
    if (this.IsFromCentralizedPage){
      this.id = this.PersonalId
    }
    this.medicalFile$ = this.medicalFileService.getMedicalFile(this.id).pipe(share())
    this. getRegularSensitivity( ) 
  }

  ShowHiddenContent() {
      this.showContent = !this.showContent
  }

  // Get Sensitivity

      getRegularSensitivity() {
        this.caretxMedicalProviderService.getPersoenlSesitivity(this.id ).
      subscribe({
        next: data => {
          this.PersonelSensitivity = new PersonelSensitivity(data)

          this.TextToDisplay2 = this.GenerateTextToDisplay()

        },
        error: error => {
          this.PersonelSensitivity = new PersonelSensitivity()
        }
      })
      }


      GenerateTextToDisplay() {
        let drugSensitivities :string = ""
        drugSensitivities = this.PersonelSensitivity.DrugSensitivity.map(o => o.Drug.ShortTradeName).join(' , ');
        return drugSensitivities
      }

}
