import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ModelMapper } from '../../models/modelmapper';
import { OccupationalTherapyAssessment, OccupationalTherapyFollowUp } from '../../models/occupationalTherapy';



@Injectable()
export class OccupationalDataProviderService {

  constructor(private httpClient: HttpClient) { }


  createOccupationalAssessment( assessment: OccupationalTherapyAssessment) {
    return this.httpClient.post(environment.gateway + '/occtherapyassessment/' + assessment.PersonelID , assessment)
  }

  getOccupationalAssessment(PersonelID: string , filter:string ,mode:string ): Observable<OccupationalTherapyAssessment[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

      const headerDict = {
        'Mode': mode
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
    return this.httpClient.get(environment.gateway + '/occtherapyassessment/' + PersonelID + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(OccupationalTherapyAssessment).map(item)
        })

      }),catchError(this.errorHandler));
  }

  updateOccupationalAssessment(assessment: OccupationalTherapyAssessment) {
    return this.httpClient.put(environment.gateway + '/occtherapyassessment/' + assessment.PersonelID, assessment )
  }

  deleteOccupationalAssessment(personelId:string,filter:string ) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/occtherapyassessment/' + personelId + filterQuery)
  }


  getOccupationalFollowUps(PersonelID: string , filter:string ,mode:string , page :string): Observable<OccupationalTherapyFollowUp[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

      const headerDict = {
        'Mode': mode,
        'Page':page,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
    return this.httpClient.get(environment.gateway + '/occupationaltherapyfollowup/' + PersonelID + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(OccupationalTherapyFollowUp).map(item)
        })

      }),catchError(this.errorHandler));
  }

  getTotalOfOccupationalFollowups(PersonelID: string): Observable<number> {
    return this.httpClient.get(environment.gateway + '/occupationaltherapyfollowup/' + PersonelID +'/count').pipe(
      map((response: any) => {
        return response
      }), catchError(this.errorHandler));
  }


  createNewNurseFollowUp(followup: OccupationalTherapyFollowUp) {
    return this.httpClient.post(environment.gateway + '/occupationaltherapyfollowup/' + followup.PersonelID , followup)
  }





  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }
}
