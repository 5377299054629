import { Component, OnInit } from '@angular/core';
import { sideNaveGroups } from '../../../../src/app/services/enums';
import { CommonInterface } from '../../../../src/app/shared/SharedInterface';

@Component({
  selector: 'app-nurseanchor',
  templateUrl: './nurseanchor.component.html',
  styleUrls: ['./nurseanchor.component.css']
})
export class NurseanchorComponent implements OnInit,CommonInterface  {
  TabId:string = "medicalfiles"
  constructor() { }
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Nursing;

  ngOnInit(): void {
  }

}
