import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reportslandingpage',
  templateUrl: './reportslandingpage.component.html',
  styleUrls: ['./reportslandingpage.component.scss']
})
export class ReportslandingpageComponent implements OnInit {

  TabId:string = "main"
  constructor(public router: Router) { }

  menuItems = [
    {
      category: 'דוחות כללי', links: [
        { text: 'דוח דיירים לתרופה', url: 'reports/Resident_medicines' },
       
      ]
    },


  ]


  menuItems2 = [
    {
      category: 'דוחות מחלקתיים', links: [

        { text: 'דוח אומדן כאב מרוכז', url: 'reports/pain_report' },
        { text: 'דוח משקלים למחלקה', url: 'report3' },
        { text: 'דוח חיסונים', url: 'report4' },
        { text: 'דוח זיהומים', url: 'report4' },
        { text: 'דוח ריגשיות', url: 'report4' },
        { text: 'דוח דיירים עם הגבלה פיזית במחלקה', url: 'report5' },
      ]
    },


  ]



  ngOnInit(): void {
  }

}
