import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { HazardManagementDialogComponent } from '../../CaretexModals/hazard-managment-dialog/hazard-managment-dialog.component';
import { Hazard, HazardRisk, HazardType, PersonelHazards } from 'src/app/models/hazard';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { MedicalFile } from 'src/app/models/customer';
import { AdhesionPlace, ContaminationSource, HodgeTest, HospitalRoom, HospitalizationTeam, InfectionStatus, IsolationType, PcrTest, ResistanceType } from 'src/app/models/generalhousedsetups';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-hazard-detials-page',
  templateUrl: './hazard-detials-page.component.html',
  styleUrls: ['./hazard-detials-page.component.css']
})
export class HazardDetialsPageComponent extends CaretexComponent implements OnInit {

  id: string
  isrecordClosed: boolean
  currentHazard: PersonelHazards
  hazards: Hazard[] = []
  ResistanceTypes: ResistanceType[] = []
  IsolationTypes: IsolationType[] = []
  InfectionStatuses: InfectionStatus[] = []
  ContaminationSources: ContaminationSource[] = []
  AdhesionPlaces: AdhesionPlace[] = []
  hazardsRisks: HazardRisk[] = []
  hazradsTypes: HazardType[] = []

  hospitalRooms: HospitalRoom[] = []
  hospitalizationTeams: HospitalizationTeam[] = []
  pcrTests: PcrTest[] = []
  hodgeTests: HodgeTest[] = []

  @Output() backToCentralPage = new EventEmitter<void>();
  @Input() PersonalId: string
  @Input() IsFromCentralizedPage: boolean = false
  @Input() SelectedFile: MedicalFile
  @Input() HazardId: string



  moduleName: string = "זיהום"
  @ViewChild('mainform') mainFormElement: NgForm;
  isLoading: boolean;
  isClosed: boolean = false
  public model = {
    editorData: ''
  };
  public config2: any = {
    airMode: false,
    tabDisable: true,
    popover: {},
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [],
    fontSizes: [
      '8',
      '9',
      '10',
      '11',
      '12',
      '14',
      '18',
      '24',
      '36',
      '44',
      '56',
      '64',
      '76',
      '84',
      '96',
    ],
    fontNames: [
      'Arial',
      'Times New Roman',
      'Inter',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
      'MangCau',
      'BayBuomHep',
      'BaiSau',
      'BaiHoc',
      'CoDien',
      'BucThu',
      'KeChuyen',
      'MayChu',
      'ThoiDai',
      'ThuPhap-Ivy',
      'ThuPhap-ThienAn',
    ],

    codeviewFilter: true,
    codeviewFilterRegex:
      /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public medicalFileServive: MedicalFileProviderService,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private medicalProviderService: CaretxMedicalProviderService,
    private authServive: AuthService,
    private toastrService: ToasterService,) {
    super(medicalFileServive)
    this.caretxSetupsProviderService.GetHazards().subscribe((data => {
      this.hazards = data
    }))

    this.caretxSetupsProviderService.GetHazardRisks().subscribe((data => {
      this.hazardsRisks = data
    }))

    this.caretxSetupsProviderService.GetHazardTypes().subscribe((data => {
      this.hazradsTypes = data
    }))


    this.caretxSetupsProviderService.GetResistanceTypes().subscribe((data => {
      this.ResistanceTypes = data
    }))


    this.caretxSetupsProviderService.GetIsolationTypes().subscribe((data => {
      this.IsolationTypes = data
    }))


    this.caretxSetupsProviderService.GetInfectionStatus().subscribe((data => {
      this.InfectionStatuses = data
    }))


    this.caretxSetupsProviderService.GetContaminationSource().subscribe((data => {
      this.ContaminationSources = data
    }))


    this.caretxSetupsProviderService.GetAdhesionPlaces().subscribe((data => {
      this.AdhesionPlaces = data
    }))


    this.caretxSetupsProviderService.GetHospitalRooms().subscribe((data => {
      this.hospitalRooms = data
    }))

    this.caretxSetupsProviderService.GetHospitalizationTeams().subscribe((data => {
      this.hospitalizationTeams = data
    }))

    this.caretxSetupsProviderService.GetPCRTests().subscribe((data => {
      this.pcrTests = data
    }))

    this.caretxSetupsProviderService.GetHodgeTest().subscribe((data => {
      this.hodgeTests = data
    }))



  }


  ngOnInit(): void {


    if (this.route.parent != null && this.route.parent != undefined) {
      this.id = this.route.parent.snapshot.params['id'];
    }

    if (this.IsFromCentralizedPage) {
      this.id = this.PersonalId
    } else {
      this.HazardId = this.route.snapshot.params.hazardid
    }





    this.CheckIfComponentIsDisabled(this.id)
    if (this.HazardId == 'new') {
      this.currentHazard = new PersonelHazards()
      this.currentHazard.PersonelID = this.id
      this.currentHazard.RetestRequired = "N"
      this.currentHazard.RealmID = this.authServive.LoggedInTenantID
      this.currentHazard.CreatedAt = new Date()
      this.currentHazard.Status = "A"
    }
    else {
      this.getPersonalHazradFromDB(this.HazardId)
    }
  }

  getPersonalHazradFromDB(hazardId: string) {
    let filter = '(id=' + '\'' + hazardId + '\'' + ')'
    this.medicalProviderService.getPersonelHazard(this.id, filter).subscribe((data: PersonelHazards[]) => {
      if (data.length == 1) {
        this.currentHazard = new PersonelHazards(data[0])
        this.isrecordClosed = (this.currentHazard.Status == "C")


        if (!this.currentHazard.IsReTakenTestDateSet) {
          this.currentHazard.ReTakenTestDate = undefined
        }
        if (!this.currentHazard.IsIsolationDenialDateSet) {
          this.currentHazard.IsolationDenialDate = undefined
        }
        if (!this.currentHazard.IsIsolationRemovalDateSet) {
          this.currentHazard.IsolationRemovalDate = undefined
        }


      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  submitForm() {
    this.mainFormElement.onSubmit(undefined);
    if (this.mainFormElement.form.invalid) {
      Swal.fire({
        title: 'לידיעתך',
        text: "אחד או יותא מהשדות לא מולאו כמו שצריך",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false

      })
    }
  }

  Save() {
    if (this.currentHazard.ID == "" || this.currentHazard.ID == null) {


      this.currentHazard.FirstTakenTestDate = new Date(this.currentHazard.FirstTakenTestDate)

      if (!this.currentHazard.IsReTakenTestDateSet) {
        this.currentHazard.ReTakenTestDate = new Date('1970-01-01Z00:00:00:000')
      }

      if (!this.currentHazard.IsIsolationDenialDateSet) {
        this.currentHazard.IsolationDenialDate = new Date('1970-01-01Z00:00:00:000')
      }

      if (!this.currentHazard.IsIsolationRemovalDateSet) {
        this.currentHazard.IsolationRemovalDate = new Date('1970-01-01Z00:00:00:000')
      }



      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    // Check  Mandatory Fields
    this.currentHazard.ReportedById = this.authServive.LoggedInUser.UID
    this.currentHazard.PersonelID = this.id

    this.medicalProviderService.createNewPersonelHazard(this.currentHazard).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentHazard = new PersonelHazards(data)
        if (this.currentHazard.Status == 'C')
          this.isrecordClosed = true
        //retreive again the file
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        if (!this.IsFromCentralizedPage) {
          this.router.navigateByUrl("caretexfile/" + this.id + "/hazards/" + this.currentHazard.ID)
        }
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    // Check  Mandatory Fields
    this.currentHazard.LastUpdatedById = this.authServive.LoggedInUser.UID
    this.currentHazard.PersonelID = this.id
    this.medicalProviderService.updatePersonelHazard(this.currentHazard).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentHazard = new PersonelHazards(data)
        if (this.currentHazard.Status == 'C')
          this.isrecordClosed = true
        //retreive again the file
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        if (!this.IsFromCentralizedPage) {
          this.router.navigateByUrl("caretexfile/" + this.id + "/hazards/" + this.currentHazard.ID)
        }
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/hazards")
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    editor.isReadOnly = this.isClosed
  }

  PopulatePropertiesOfHazard() {
    this.currentHazard.HazardRisk = this.currentHazard.Hazard.Risk
    this.currentHazard.HazardType = this.currentHazard.Hazard.Type
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty() {
    return false;
  }

  /************************Manage Hazard ***************** */
  manageHazard(type) {


    const dialogRef = this.dialog.open(HazardManagementDialogComponent, {
      data: {
        id: this.id,
        isNew: false,
        currentHazard: this.currentHazard,
        isDisabled: false,
        isForIsolation: type == "Isolation" ? true : false,
        isForRetakeTest: type == "ReTakenTest" ? true : false
      }
    });



    dialogRef.afterClosed().subscribe(result => {
      if (result == "" || result == undefined) return
      else {
        this.getPersonalHazradFromDB(result)
      }

    });
  }


  ReturnToCentralPage() {
    this.backToCentralPage.emit()
  }


}
