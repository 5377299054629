import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-caretextextmodal',
  templateUrl: './caretextextmodal.component.html',
  styleUrls: ['./caretextextmodal.component.scss']
})
export class CaretextextmodalComponent implements OnInit {
  @Input() Text :string
  @Input() IsClosed:boolean
  @Output() closeTextModal = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  closeModal(){

    this.closeTextModal.emit(this.Text)
  }

}
