import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VaccinationmodalComponent } from '../../../../../src/app/caretx-shared-module/CaretexModals/vaccinationmodal/vaccinationmodal.component';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { PersonalVaccination } from '../../../../../src/app/models/Vaccinations';
import { Vaccinations } from '../../../../../src/app/models/generalhousedsetups';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { CaretxNursingProviderService } from '../../nursing-services/Nursing.data-provider.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-vaccinations-list',
  templateUrl: './vaccinations-list.component.html',
  styleUrls: ['./vaccinations-list.component.css']
})
export class VaccinationsListComponent extends CaretexComponent implements OnInit {

  Vaccinations: PersonalVaccination[] = []
  personelId: string
  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  IsClosed: boolean = false
  moduleName: string = "פנקס חיסונים"
  showTitle: boolean = true
  GrayHeader: boolean = false
  ShowActions: boolean = true
  currentRowIndex:number

  constructor(private authService: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private caretxNursingService: CaretxNursingProviderService,
    public dialog: MatDialog,
    private toastrService: ToasterService,
    private route: ActivatedRoute,
    private _ar: ActivatedRoute,) {
    super(medicalFileServive)
  }

  ngOnInit(): void {
    if (this.route.parent != null)
      this.personelId = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.personelId)
    this.getPersonalVaccinationsLFromDB()
  }


  getPersonalVaccinationsLFromDB() {
    this.caretxNursingService.getPersonalVaccinations(this.personelId).subscribe(
      data => {
        this.Vaccinations = data
      },
      err => {
        this.Vaccinations = []

      }
    )
  }

  openDialog(personalVaccine?: PersonalVaccination, index?: number) {

    let currPersonalVaccine: PersonalVaccination
    let isNew = false
    if ((personalVaccine == null || personalVaccine == undefined)) {

      currPersonalVaccine = new PersonalVaccination()
      currPersonalVaccine.PersonelID = this.personelId
      currPersonalVaccine.Vaccination = new Vaccinations()
      currPersonalVaccine.RealmID = this.authService.LoggedInTenantID
      currPersonalVaccine.GivenBy = this.authService.LoggedInUser
      currPersonalVaccine.GivenById = this.authService.LoggedInUser.UID
      isNew = true
    }
    const dialogRef = this.dialog.open(VaccinationmodalComponent, {
      data: {
        currentPersonalVaccine: (personalVaccine == null || personalVaccine == undefined) ? currPersonalVaccine : personalVaccine,
        id: this.personelId,
        ActionAllowed: this.ShowActions,
        IsDisabled: false,
        IsNew: isNew

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Save Diagnoses
      if (result == undefined || result == null || result == "") return
      this.getPersonalVaccinationsLFromDB()
    });
  }

  openPDFViewer() {

  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }

  deleteRow(i) {
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteVaccination(this.Vaccinations[this.currentRowIndex])
      }
    })

  }


  deleteVaccination(vaccination: PersonalVaccination) {
    this.caretxNursingService.deletePersonalVaccinations(this.personelId, vaccination).subscribe({
      next: data => {
        this.Vaccinations.splice(this.currentRowIndex, 1);
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }




  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const vaccines = this.Vaccinations.some(row => {
      return row.New || row.Modified
    })
    return vaccines
  }



}
