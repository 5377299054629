import { MedicalFile } from './customer'
import { Diagnoses } from './diagnoses'
import { MedicalDiagnoses } from './drug'
import { DrugInstruction } from './drugInstruction'
import { DrugSensitivity } from './drugsenstivity'
import { MedicalAdmission } from './medicaladmission'
import { propertyMap } from './modelmapper'
import { User } from './user'



// Need Server to send Payload in below structure
export class Notice {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('Reason')
    public Reason: string
    @propertyMap('DateOfDeath')
    public DateOfDeath: string
    @propertyMap('TimeOfDeath')
    public TimeOfDeath: string
    @propertyMap('Drugs')
    public Drugs: DrugInstruction[]
    @propertyMap('PersonDoc')
    public PersonDoc: MedicalFile

    @propertyMap('Admission')
    public Admission: MedicalAdmission


    @propertyMap('Diagnoses')
	Diagnoses: Diagnoses[]
    @propertyMap('Sensitivities')
	Sensitivities: DrugSensitivity[]
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User
    @propertyMap('Status')
    Status: string
    @propertyMap('Closed_at')
    public Closed_at: Date


    @propertyMap('AddmissionReason')
    public AddmissionReason: string
    @propertyMap('BackGroundDieses')
    public BackGroundDieses: string
    @propertyMap('CurrentDeises')
    public CurrentDeises: string
    @propertyMap('DeathReason')
    public DeathReason: string
    @propertyMap('HospitlizationReview')
    public HospitlizationReview: string

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonDoc = data["PersonDoc"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.AddmissionReason = data["AddmissionReason"]
            this.BackGroundDieses = data["BackGroundDieses"]
            this.CurrentDeises = data["CurrentDeises"]
            this.DeathReason = data["DeathReason"]
            this.Diagnoses = data["Diagnoses"]
            this.Sensitivities = data["Sensitivities"]
            this.Drugs = data["Drugs"]
            this.HospitlizationReview = data["HospitlizationReview"]
            this.CreatedById = data["CreatedById"]
            this.CreatedBy = data["CreatedBy"]
            this.ClosedById = data["ClosedById"]
            this.ClosedBy = data["ClosedBy"]
            this.Status = data["Status"]
            this.DateOfDeath = data["DateOfDeath"]
            this.TimeOfDeath = data["TimeOfDeath"]



       




        }
        else {
            this.CreatedAt = this.UpdatedAt = new Date()
            this.Diagnoses = []
            this.Sensitivities = []
            this.Drugs = []

        }
    }
}


export class PaitentFamily {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('FullName')
    public FullName: string
    @propertyMap('Address')
    public Address: string
    @propertyMap('Relation')
    public Relation: string
    @propertyMap('Phone')
    public Phone: string
    @propertyMap('LivesWithPatient')
    public LivesWithPatient: boolean
    @propertyMap('Modified')
    public Modified: boolean
    @propertyMap('New')
    public New: boolean

    isLocked: boolean
    editMode: boolean

    constructor() {
    }
}
