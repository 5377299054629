import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


import { AuthService } from '../../../../../src/app/services/auth.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';

@Component({
  selector: 'app-nursing-medical-tasksalert-banner',
  templateUrl: './nursing-medical-tasksalert-banner.component.html',
  styleUrls: ['./nursing-medical-tasksalert-banner.component.css']
})
export class NursingMedicalTasksalertBannerComponent implements OnInit {
  id : string


  showContent:boolean = false

  TotalTasksNum:number = 0
  constructor(
    private authService: AuthService,
    private router: Router,
    private _ar: ActivatedRoute, 
    private caretxSharedModulesProviderService: CaretxSharedModulesProviderService,
    ) { }


  ngOnInit(): void {
    this. getTotalTasksOfToday()

  }

  ShowHiddenContent() {
      this.showContent = !this.showContent
  }

  // Get Sensitivity

  getTotalTasksOfToday() {
      this.caretxSharedModulesProviderService.getTotalUnapprovedDrugInstructions().
      subscribe({
        next: data => {
          this.TotalTasksNum = data
        },
        error: error => {
          this.TotalTasksNum = 0
        }
      })
      }



}
