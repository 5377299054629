import { Component, OnInit } from '@angular/core';
import { Vaccinations } from 'src/app/models/generalhousedsetups';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-vaccinations',
  templateUrl: './vaccinations.component.html',
  styleUrls: ['./vaccinations.component.css']
})
export class VaccinationsComponent extends CaretexComponent implements OnInit {

  vaccinations:Vaccinations[]  = []
  TabId:string = "main"
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private router: Router,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {
      super(medicalFileServive)
     }

  ngOnInit(): void {
    this.getVaccinationsFromDB()
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.vaccinations.some(row => {
      return row.New || row.editMode
    })
    return  hospitalsLisChanged
  }


  getVaccinationsFromDB(){
    this.caretxSetupsProviderService.getVaccinations().subscribe(
      data => {
        this.vaccinations = data
      },
      err => {
        console.log("Error", err)
        this.vaccinations = []

      }
    )
  }



  addVaccinations(){
    var newRecord = new Vaccinations()
    newRecord.New = true
    newRecord.editMode = true
    newRecord.CreatedAt = new Date()
    newRecord.UpdatedAt = new Date()
    newRecord.RealmID = this.authServive.LoggedInTenantID
    this.vaccinations.unshift(newRecord)
  }

  deleteRow(i){

    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.vaccinations[i].New){
          this.caretxSetupsProviderService.deleteVaccinations(this.vaccinations[this.currentRowIndex]).subscribe({
            next: data => {
              this.vaccinations.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.vaccinations.splice(this.currentRowIndex, 1);
      }
    })
  }



  editRow(i){
    this.vaccinations[i].editMode = true
    this.vaccinations[i].Modified = true
  }

  cancelEditRow(i){
    this.vaccinations[i].editMode  = false;
    this.vaccinations[i].Modified = false
  }



updateVaccinations($event){
     this.caretxSetupsProviderService.CreateUpdateVaccinations(this.vaccinations).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getVaccinationsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })  


    
  }

  BackToList(){
    this.router.navigate(['system_setups']);
  }

}
