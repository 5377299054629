import { Component, OnInit, ViewChild } from '@angular/core';
import { CaretxSetupsProviderService } from '../../../../app/caretx-setups-module/setup-services/setups.data-provider.service';
import { Hazard, HazardRisk, HazardType, PersonelHazards } from '../../../../app/models/hazard';
import { AuthService } from '../../../../app/services/auth.service';
import { ToasterService } from '../../../../app/services/toastr.service';
import { CaretxMedicalProviderService } from '../../doctor-services/Medical.data-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../../app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from   '../../../../app/shared/CaretexComponent'          //'src/app/shared/CaretexComponent';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-hazarddetails',
  templateUrl: './hazarddetails.component.html',
  styleUrls: ['./hazarddetails.component.css']
})
export class HazarddetailsComponent extends CaretexComponent implements OnInit {

  id: string
  isrecordClosed: boolean
  currentHazard: PersonelHazards
  hazards: Hazard[] = []
  hazardsRisks: HazardRisk[] = []
  hazradsTypes: HazardType[] =[]
  moduleName:string="זיהום"
  @ViewChild('mainform') mainFormElement: NgForm;
  isLoading: boolean;
  isClosed:boolean = false
  public model = {
    editorData: ''
  };
  public config2:any= {
    airMode: false,
    tabDisable: true,
    popover: {},
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [],
    fontSizes: [
      '8',
      '9',
      '10',
      '11',
      '12',
      '14',
      '18',
      '24',
      '36',
      '44',
      '56',
      '64',
      '76',
      '84',
      '96',
    ],
    fontNames: [
      'Arial',
      'Times New Roman',
      'Inter',
      'Comic Sans MS',
      'Courier New',
      'Roboto',
      'Times',
      'MangCau',
      'BayBuomHep',
      'BaiSau',
      'BaiHoc',
      'CoDien',
      'BucThu',
      'KeChuyen',
      'MayChu',
      'ThoiDai',
      'ThuPhap-Ivy',
      'ThuPhap-ThienAn',
    ],

    codeviewFilter: true,
    codeviewFilterRegex:
      /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
    codeviewIframeFilter: true,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private medicalProviderService: CaretxMedicalProviderService,
    private authServive: AuthService,
    private toastrService: ToasterService,) {
      super(medicalFileServive)
    this.caretxSetupsProviderService.GetHazards().subscribe((data => {
      this.hazards = data
    }))

    this.caretxSetupsProviderService.GetHazardRisks().subscribe((data => {
      this.hazardsRisks = data
    }))

    this.caretxSetupsProviderService.GetHazardTypes().subscribe((data => {
      this.hazradsTypes = data
    }))
  }


  ngOnInit(): void {
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
    if (this.route.snapshot.params.hazardid == 'new') {
      this.currentHazard = new PersonelHazards()
      this.currentHazard.PersonelID = this.id
      this.currentHazard.RealmID = this.authServive.LoggedInTenantID
      this.currentHazard.CreatedAt = new Date()
      this.currentHazard.Status = "A"
    }
    else {
      this.getPersonalHazradFromDB(this.route.snapshot.params.hazardid )
    }
  }

  getPersonalHazradFromDB(hazardId: string) {
    let filter = '(id=' + '\'' + hazardId + '\'' + ')'
    this.medicalProviderService.getPersonelHazard(this.id, filter).subscribe((data: PersonelHazards[]) => {
      if (data.length == 1) {
        this.currentHazard = new PersonelHazards(data[0])
        this.isrecordClosed = (this.currentHazard.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  submitForm() {
    this.mainFormElement.onSubmit(undefined);
    if (this.mainFormElement.form.invalid){
      Swal.fire({
        title: 'לידיעתך',
        text: "אחד או יותא מהשדות לא מולאו כמו שצריך",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false

      })
    }
  }

  Save() {
    if (this.currentHazard.ID == "" || this.currentHazard.ID == null) {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    // Check  Mandatory Fields
    this.currentHazard.ReportedById = this.authServive.LoggedInUser.UID
    this.currentHazard.PersonelID = this.id

    this.medicalProviderService.createNewPersonelHazard(this.currentHazard).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentHazard = new PersonelHazards(data)
        if (this.currentHazard.Status == 'C')
          this.isrecordClosed = true
          //retreive again the file
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigateByUrl("caretexfile/" + this.id + "/hazards/" + this.currentHazard.ID)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate(){
    // Check  Mandatory Fields
    this.currentHazard.LastUpdatedById = this.authServive.LoggedInUser.UID
    this.currentHazard.PersonelID = this.id
    this.medicalProviderService.updatePersonelHazard(this.currentHazard).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentHazard = new PersonelHazards(data)
        if (this.currentHazard.Status == 'C')
          this.isrecordClosed = true
          //retreive again the file
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigateByUrl("caretexfile/" + this.id + "/hazards/" + this.currentHazard.ID)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  BackToList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/hazards")
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    editor.isReadOnly = this.isClosed
  }

  PopulatePropertiesOfHazard() {
    this.currentHazard.HazardRisk = this.currentHazard.Hazard.Risk
    this.currentHazard.HazardType = this.currentHazard.Hazard.Type
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty(){
    return false;
  }

}
