import { Departments } from './generalhousedsetups'
import { propertyMap } from './modelmapper'
import { User } from './user'

export class Exceptionevent {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('EventStatus')
    public EventStatus:string
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('EventReportedBy')
    public EventReportedBy:string
    @propertyMap('ReportedByJob')
    public ReportedByJob:string
    @propertyMap('EventType')
    public EventType:string
    @propertyMap('EventLocation')
    public EventLocation:string
    @propertyMap('StatusBeforeEvent')
    public StatusBeforeEvent:string
    @propertyMap('DrugsEffect')
    public DrugsEffect:string
    @propertyMap('Cognitivestatus')
    public Cognitivestatus:string
    @propertyMap('Bed')
    public Bed:string
    @propertyMap('PreviouseEvents')
    public PreviouseEvents:string
    @propertyMap('DepartmentId')
    public DepartmentId:string
    @propertyMap('Department')
    public Department:Departments
    @propertyMap('EventDate')
    public EventDate:Date
    @propertyMap('Shift')
    public Shift:string
    @propertyMap('DoctorAtEvent')
    public DoctorAtEvent:string
    @propertyMap('NurseAtEvent')
    public NurseAtEvent:string
    @propertyMap('EventDescription')
    public EventDescription:string
    @propertyMap('ReportedToDoctor')
    public ReportedToDoctor:string
    @propertyMap('ReportedToDoctorJob')
    public ReportedToDoctorJob:string
    @propertyMap('ReportedToDoctorOn')
    public ReportedToDoctorOn:Date
    @propertyMap('ReportedTDoctorAt')
    public ReportedToDoctorAt:Date 
    @propertyMap('ReportedToNurse')
    public ReportedToNurse:string
    @propertyMap('ReportedToNurseJob')
    public ReportedToNurseJob:string
    @propertyMap('ReportedToNurseOn')
    public ReportedToNurseOn:Date
    @propertyMap('ReportedToNurseAt')
    public ReportedToNurseAt:Date
    @propertyMap('ReportedToFamily')
    public ReportedToFamily:string
    @propertyMap('ReportedToFamilyPhone')
    public ReportedToFamilyPhone:string
    @propertyMap('ReportedToFamilyOn')
    public ReportedToFamilyOn:Date
    @propertyMap('ReportedToFamilyAt')
    public ReportedToFamilyAt:Date
    @propertyMap('Witnesses')
    public Witnesses:ExceptionEventWitness[]
    @propertyMap('CheckedByNurseId')
    public CheckedByNurseId:string
    @propertyMap('CheckedByNurse')
    public CheckedByNurse:User
    @propertyMap('NurseCheckDate')
    public NurseCheckDate:Date
    @propertyMap('NurseCheckTime')
    public NurseCheckTime:Date
    @propertyMap('NurseChecDescription')
    public NurseChecDescription:string
    @propertyMap('NurseCheckSignOfDate')
    public NurseCheckSignOfDate:Date
    @propertyMap('IsSignedByNurse')
    public IsSignedByNurse:boolean
    @propertyMap('CheckedByDoctorId')
    public CheckedByDoctorId:string
    @propertyMap('CheckedByDoctor')
    public CheckedByDoctor:User
    @propertyMap('DoctorCheckDate')
    public DoctorCheckDate:Date
    @propertyMap('DoctorCheckTime')
    public DoctorCheckTime:Date
    @propertyMap('DoctorChecDescription')
    public DoctorChecDescription:string
    @propertyMap('DoctorCheckSignOfDate')
    public DoctorCheckSignOfDate:Date
    @propertyMap('IsSignedByDoctor')
    public IsSignedByDoctor:boolean
    @propertyMap('SentToHospital')
    public SentToHospital:boolean
    @propertyMap('HospitalID')
    public HospitalID:string
    @propertyMap('EventSummary')
    public EventSummary:string
    @propertyMap('ViolenceCommittee')
    public ViolenceCommittee:string

    @propertyMap('SignedByMedicalManagerId')
    public SignedByMedicalManagerId:string
    @propertyMap('SignedByMedicalManager')
    public SignedByMedicalManager:User
    @propertyMap('MedicalManagerSignOfDate')
    public MedicalManagerSignOfDate:Date
    @propertyMap('IsSignedByMedicalManager')
    public IsSignedByMedicalManager:boolean
    @propertyMap('SignedByHouseManagerId')
    public SignedByHouseManagerId:string
    @propertyMap('SignedByHouseManager')
    public SignedByHouseManager:User
    @propertyMap('HouseManagerSignOfDate')
    public HouseManagerSignOfDate:Date
    @propertyMap('IsSignedByHouseManager')
    public IsSignedByHouseManager:boolean
    @propertyMap('SignedByNursingManagerId')
    public SignedByNursingManagerId:string
    @propertyMap('SignedByNursingManager')
    public SignedByNursingManager:User
    @propertyMap('NursingManagerSignOfDate')
    public NursingManagerSignOfDate:Date
    @propertyMap('IsSignedByNursingManager')
    public IsSignedByNursingManager:boolean
    @propertyMap('LockedById')
    public LockedById:string
    @propertyMap('LockedBy')
    public LockedBy:User
    @propertyMap('LockedDate')
    public LockedDate:Date
    public isNew :boolean = false
    public editMode :boolean = false

    @propertyMap('LocationId')
    public LocationId:string
    @propertyMap('IsFreeTxtLocation')
    public IsFreeTxtLocation:boolean
    
    @propertyMap('ConsStateId')
    public ConsStateId:string
    @propertyMap('IsFreeTxtConsState')
    public IsFreeTxtConsState:boolean

    @propertyMap('BedTypeId')
    public BedTypeId:string
    @propertyMap('IsFreeTxtBedType')
    public IsFreeTxtBedType:boolean

}


export class ExceptionEventWitness {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('Phone')
    public Phone:string
    @propertyMap('Address')
    public Address:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('ExceptioneventId')
    public ExceptioneventId:string

    public isNew :boolean = false
    public editMode :boolean = false
}