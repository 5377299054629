import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Departments } from '../../../../../src/app/models/generalhousedsetups';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';

@Component({
  selector: 'app-departmentssetup',
  templateUrl: './departmentssetup.component.html',
  styleUrls: ['./departmentssetup.component.css']
})
export class DepartmentssetupComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  departments:Departments[]  = []
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {   super(medicalFileServive)}

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getDepartmentsFromDB()
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  
  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.departments.some(row => {
      return row.New || row.Modified
    })
    return  hospitalsLisChanged
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }

  getDepartmentsFromDB(){

    this.caretxSetupsProviderService.GetHouseDepartments().subscribe(
      data => {
        this.departments = data
      },
      err => {
        console.log("Error", err)
        this.departments = []

      }
    )
  }

  

  addNewDepartment(){
    var newDepartment = new Departments()
    newDepartment.New = true
    newDepartment.editMode = true
    newDepartment.CreatedAt = new Date()
    newDepartment.UpdatedAt = new Date()
    newDepartment.RealmID = this.authServive.LoggedInTenantID
    this.departments.unshift(newDepartment)
  }



  deleteRow(i){

    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.departments[i].New){
          this.caretxSetupsProviderService.DeleteDepartment( this.departments[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.departments.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
              this.getDepartmentsFromDB()
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.departments.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i){
    this.departments[i].editMode = true
    this.departments[i].Modified = true
  }
  cancelEditRow(i){
    this.departments[i].editMode  = false;
    this.departments[i].Modified = false
  }

  updateDepartment($event){
     this.caretxSetupsProviderService.CreateUpdateDepartment(this.departments).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getDepartmentsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })  
  }
  
}
