import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PersonalMeasureCode, PersonalMeasures, PersonalMeasuresHeader } from "src/app/models/measure";
import { ModelMapper } from "src/app/models/modelmapper";
import { environment } from "src/environments/environment";


@Injectable()
export class MedicalPersonelMeasuresProviderService {

  constructor(private httpClient: HttpClient) {
  }




 



 




  getMeasuresCodes(tenantid: string): Observable<PersonalMeasureCode[]> {
    return this.httpClient.get(environment.gateway + '/measurescodes' ).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PersonalMeasureCode).map(item)
        })
      }), catchError(this.errorHandler));
  }





  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }
}