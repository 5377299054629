import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LabCheck, LabCheckHeader, LabOrderCategories, LabOrderCheck } from 'src/app/models/testlabchecks';
import { CustomerService } from 'src/app/services/customer.service';
import { DataService } from 'src/app/services/data.service';
import { LabsProviderService } from 'src/app/caretx-doctors-module/doctor-services/laboratory.data-provider.service';
import { CaretxMedicalProviderService } from '../../../caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';


@Component({
  selector: 'app-labresultsmodal',
  templateUrl: './labresultsmodal.component.html',
  styleUrls: ['./labresultsmodal.component.css']
})
export class LabresultsmodalComponent implements OnInit {
  commentTxt: string
  hideModal: boolean = true;
  isEmpty: boolean
  labortaoryTypes: LabOrderCategories[] = []
  @Input() title: string
  @Input() hmo: string
  @Output() closeLabResultModal = new EventEmitter<void>();

  dropdownSettings: any = {};
  selectedItems = []
  selectedLabCategory: LabOrderCategories
  selectedItems1 = [];
  dropdownSettings1 = {};
  labsCategories: LabOrderCategories[] = []
  labOrderCheck: LabOrderCheck[] = []
  labcheckHeaders: LabCheckHeader[] = []
  id: string
  constructor(
  //  private _customersService: CustomerService, 
    private _setupsService: CaretxSetupsProviderService,
    private labService: CaretxMedicalProviderService,
    private _ar: ActivatedRoute,) { }

  ngOnInit(): void {
    this.id = this._ar.snapshot.params['id'];
    this.doInitialization() 
    //this.getspecificLabResultsFromDB("hematology")
  }



  doInitialization() {
    // Get LabCategories :
    this._setupsService.GetCategoryLabs(this.hmo, "").subscribe(data => {
      this.labsCategories = data
     
    })
    this.dropdownSettings = {
      singleSelection: false,
      idField: "PrimaryCode",
      textField: "Name",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.dropdownSettings1 = {
      singleSelection: true,
      idField: "CategoryId",
      textField: "CategoryDescr",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }


  getLabCategoriesFromDB() {
    this._setupsService.GetLabTypes(this.hmo).subscribe(data => {
      this.labsCategories = data
    })
  }

  getspecificLabResultsFromDB(categoryId: string) {
    let filter = '(category_id=' + '\'' + categoryId + '\'' + ')'
    this.labService.getLaboratoryResultsHeaders(this.id, filter).subscribe(
      data => {
        this.labcheckHeaders = data
      },
      err => {
        console.log("Error", err)
        this.labcheckHeaders = []

      }
    )
  }

  // To show the values of the selected test in first column
  getFirstLabcheckHeader() {
    if (Array.isArray(this.labcheckHeaders) && this.labcheckHeaders.length) {
      return this.labcheckHeaders[0].Labchecks
    }
    else
      return []
  }

  findValueOfGivencode(labcheckHeader: LabCheckHeader, TestName: string) {
    let val: any = ""
    if (labcheckHeader != undefined && labcheckHeader != null) {
      if (labcheckHeader.Labchecks.length) {
        for (let elem of labcheckHeader.Labchecks) {
          if (elem.TestName == TestName) {
            val = elem.LabTemplate.Uom + " " + elem.TestValue
            break
          }
        }
      }
      else
        val = ""
    }
    else {
      val = ""
    }
    return val
  }

  isRed(labcheckHeader: LabCheckHeader, TestName: string) {
    let isRed: Boolean = false
    if (labcheckHeader != undefined && labcheckHeader != null) {
      if (labcheckHeader.Labchecks.length) {
        for (let elem of labcheckHeader.Labchecks) {
          if (elem.TestName == TestName) {
            isRed = (new LabCheck(elem)).isValueInInterval()
            break

          }
        }
      }
    }
    return isRed
  }

  labTypeChange($event) {
    var labCategoryId = $event.currentTarget.options[$event.currentTarget.options.selectedIndex].id
    this.getspecificLabResultsFromDB(labCategoryId)
  }

  closeModal() {
    this.closeLabResultModal.emit()
  }

  onItemSelect(item: any) {

   
  }

  onSelectAll(items: any) {

  }

  onCategorySelect($event) {
   // let index = this.labsCategories.findIndex(x => x.CategoryId == $event.CategoryId)
    this.getspecificLabResultsFromDB($event.CategoryId)
   // this.labOrderCheck = this.labsCategories[index].LabOrders

  }
}
