import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { CaretxNursingProviderService } from '../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';

import { Diagnoses } from '../../../../../src/app/models/diagnoses';
import { DiagnosesTypes } from '../../../../../src/app/models/generalhousedsetups';
import { MedicalDiagnoses } from '../../../../../src/app/models/drug';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { MedicalDiagnosesProviderService } from '../../../../../src/app/caretx-doctors-module/doctor-services/medical-diagnoses.data-provider.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { ModalService } from '../../../../../src/app/modal/modal.service';
import { Observable } from 'rxjs';
import { FormControl, NgForm } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'personaldiagnoses-dialog.component',
  templateUrl: 'personaldiagnoses-dialog.component.html',
  styleUrls: ['personaldiagnoses-dialog.component.css']
})
export class PersonaldiagnosesDialogComponent implements OnInit {

  id: string
  measureCodeId: string
  currentDiagnoses: Diagnoses
  DiagnosesTypes: DiagnosesTypes[] = []
  currentRowNumClickedModal: number = 0
  IsDisabled: boolean = false
  ActionAllowed: boolean = false
  @ViewChild('f') mainFormElement: NgForm;
  IsFromAdmission: boolean = false
  AdmissionId: string

  constructor(
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private medicalDiagnosesProviderService: MedicalDiagnosesProviderService,

    private toastrService: ToasterService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<PersonaldiagnosesDialogComponent>,
    private caretxNursingProviderService: CaretxNursingProviderService, @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }


  ngOnInit(): void {
    this.id = this.data.personelid;
    this.currentDiagnoses = this.data.newDiagnoses
    this.DiagnosesTypes = this.data.TypesOfDiagnoses
    this.IsDisabled = this.data.IsDisabled
    this.ActionAllowed = this.data.ActionAllowed
    this.IsFromAdmission = this.data.IsFromAdmission
    this.AdmissionId = this.data.AdmissionId

    this.getDiagnoses()

  }

  diagnosesEventHandler($event) {
    let medicalDiagnose: MedicalDiagnoses = new MedicalDiagnoses($event)
    this.currentDiagnoses.MedicalDiagnoses = medicalDiagnose
  }


  /****************************Diagnoses Lookup*************************************** */

  observablefilteredDiagnoses: Observable<MedicalDiagnoses[]>
  filteredDiagnoses: MedicalDiagnoses[]
  MedicalDiagnoses: MedicalDiagnoses[]
  private _searchTxt: string
  indecationFound: boolean
  myControl = new FormControl();
  showNotFoundMsg: boolean = false
  currentSelectedStep: number = 0
  @Output() diagnosesEvent = new EventEmitter<MedicalDiagnoses>();

  getDiagnoses() {
    this.medicalDiagnosesProviderService.medicalDiagnoses = []
    this.medicalDiagnosesProviderService.fetchDiagnoses().subscribe(data => {
      this.MedicalDiagnoses = this.medicalDiagnosesProviderService.medicalDiagnoses//data
      if (this.MedicalDiagnoses != undefined && this.MedicalDiagnoses != null)
        this.filteredDiagnoses = this.MedicalDiagnoses.slice(0, 100)
    })
  }


  submitDiagnosesModal(diagnoses: MedicalDiagnoses, stepper: MatStepper) {

    this.currentDiagnoses.MedicalDiagnoses = diagnoses
    this.openMainStep(stepper)
  }



  SearchDiagnoses($event) {
    this._searchTxt = $event
    if (this._searchTxt == "") {
      this.filteredDiagnoses = this.MedicalDiagnoses
    } else {
      this.filteredDiagnoses = this.MedicalDiagnoses.slice().filter((record: MedicalDiagnoses) => {
        return (record.ShortDescription.toLowerCase()).indexOf(this._searchTxt.toLowerCase()) !== -1;
      });
    }
    if (this.filteredDiagnoses.length > 0) {
      this.showNotFoundMsg = false
    } else {
      this.showNotFoundMsg = true
    }
  }

  AddNewDiagnoses(stepper: MatStepper) {
    var newDiagnoses = new MedicalDiagnoses();
    newDiagnoses.IsGlobal = false
    newDiagnoses.New = true
    newDiagnoses.ShortDescription = this._searchTxt
    this.caretxSharedlService.createNewDiagnoses(newDiagnoses).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        let savedMedicalDiagnoses: MedicalDiagnoses = new MedicalDiagnoses(data)
        this.diagnosesEvent.emit(savedMedicalDiagnoses)
        this.currentDiagnoses.MedicalDiagnoses = savedMedicalDiagnoses
        this.getDiagnoses()
        stepper.previous()
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  checkIfDiagnosesExistsInDB() {

  }

  openCodesStep(stepper: MatStepper) {
    stepper.next();
  }

  openMainStep(stepper: MatStepper) {
    stepper.previous();
  }

  conevrtoDateTime($event) {
    return new Date($event)
  }


  submitForm() {
    this.mainFormElement.onSubmit(new Event("", undefined));
  }

  submit() {
    // Save Diagnoses
    if (this.currentDiagnoses.MedicalDiagnosesCode == "" || this.currentDiagnoses.MedicalDiagnosesCode == undefined || this.currentDiagnoses.MedicalDiagnosesCode == null) {
    }
    for (let i = 0; i < this.DiagnosesTypes.length; i++) {
      this.currentDiagnoses.TypeCode == this.DiagnosesTypes[i].Id
      this.currentDiagnoses.DiagnosesType = this.DiagnosesTypes[i]
      break
    }
    // Save the Diagnoses into DB
    if (this.mainFormElement.dirty && !this.currentDiagnoses.New) {
      this.currentDiagnoses.Modified = true
    }
    if (!this.IsFromAdmission) {
      this.medicalDiagnosesProviderService.createPersonelDiagnoses(this.id, [this.currentDiagnoses]).subscribe({
        next: data => {
          this.toastrService.Sucesss("", this.toastrService.successMsg)
          this.dialogRef.close(data);
        },
        error: error => {
          this.toastrService.Error("", error.error)
        }
      })
    } else {
      this.medicalDiagnosesProviderService.createPersonelDiagnosesByAdmission(this.id, this.AdmissionId, [this.currentDiagnoses]).subscribe({
        next: data => {
          this.toastrService.Sucesss("", this.toastrService.successMsg)
          this.dialogRef.close(data);
        },
        error: error => {
          this.toastrService.Error("", error.error)
        }
      })

    }
  }

  selectionChange($event) {
    this.currentSelectedStep = $event.selectedIndex
  }

  onCloseDialog() {
    this.dialogRef.close()
  }
} 



