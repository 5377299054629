import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Instruction } from 'src/app/models/nurseinstruction';


@Component({
  selector: 'app-nurseinstructionselectionmodal',
  templateUrl: './nurseinstructionselectionmodal.component.html',
  styleUrls: ['./nurseinstructionselectionmodal.component.css']
})
export class NurseinstructionselectionmodalComponent implements OnInit {
  hideModal: boolean = true;
  selectedInstructions: Instruction

  @Input() ListOfInstructions: Instruction[]
  @Output() instructionEvent = new EventEmitter<Instruction>();

  constructor() { }

  ngOnInit(): void {
   
  }




  closeModal() {
    this.hideModal = true;
  
    //
    
  
  }

  submitInstruction(instruction: Instruction, $event) {
    //this.selectedInstructions.push(instruction)
    this.instructionEvent.emit(instruction)
    this.closeModal()
  }

  InstructionSelection(e,instruction) {

  /*  let isFound  = this.checkIfListContainsInstruction(instruction.ID)
    if (e.currentTarget.checked) {
        // Check if it is already included in the list
       if (!isFound) {
          this.selectedInstructions.push(instruction)
       }
    }
    else{
        if (isFound){
          let objIndex = this.selectedInstructions.findIndex((e: Instruction) => e.ID == instruction.ID);
          this.selectedInstructions.splice(objIndex, 1);
        }
    }*/

  }

 /* checkIfListContainsInstruction(departmentId:string):boolean {
    const result = this.selectedInstructions.some(x => x.ID === departmentId)
    return result
  }*/



}
