import { MedicalFile } from './customer'
import { Drug } from './drug'
import { propertyMap } from './modelmapper'
import { OtherSensitivity } from './othersensitivity'
import { User } from './user'

export class DrugSensitivity {
    clientId: string
    @propertyMap('OriginType')
    public OriginType: string
    @propertyMap('OriginId')
    public OriginId: string
    @propertyMap('ID')
    public ID: string
    @propertyMap('PersonelID')
    public PersonelID: string

    @propertyMap('PersonDoc')
    public PersonDoc: MedicalFile


    @propertyMap('RealmID')
    public RealmID: string
    @propertyMap('DrugId')
    public DrugId: string
    public Drug: Drug
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('UserId')
    public UserId: string
    public User: User
    @propertyMap('Modified')
    public Modified: boolean
    @propertyMap('New')
    public New: boolean
    public isNew: boolean
    isLocked: boolean
    editMode: boolean
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonDoc = data["PersonDoc"]
            this.OriginType = data["OriginType"]
            this.OriginId = data["OriginId"]
            this.Drug = data["Drug"]
            this.PersonelID = data["PersonelID"]
            this.RealmID = data["RealmID"]
            this.DrugId = data["DrugId"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.UserId = data["UserId"]
            this.User = data["User"]
            this.New = false
            this.Modified = false
        }
        else {
            this.clientId = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            this.PersonelID = undefined
            this.DrugId = undefined
            this.Drug = new Drug()
            this.CreatedAt = new Date()
            this.isNew = false;
            this.isLocked = false;
            this.editMode = false;
        }

    }
    buildDrugNameforDisplay(): string {
      //  if (this.Drug.DrugEngName == undefined) return ""
    //    return this.Drug.LongTradeName + "\n" + "(" + this.Drug.ShortTradeName + ")"

      if (this.Drug.ShortTradeName == undefined) return ""
        let longtxt :string 
        if (this.Drug.LongTradeName == undefined || this.Drug.LongTradeName == "" ){
            longtxt = ""
        }else{
            longtxt = "(" + this.Drug.LongTradeName + ")"
        }

        return this.Drug.ShortTradeName + " " + longtxt
    }


}


export class PersonelSensitivity {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('TenantID')
    public TenantID:Date
    @propertyMap('HasSensitivity')
    HasSensitivity: string
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('DrugSensitivity')
    DrugSensitivity: DrugSensitivity[] = []
    @propertyMap('OtherSensitivity')
    OtherSensitivity: OtherSensitivity


    constructor(data?: Object) {

        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.TenantID = data["TenantID"]
            this.PersonelID = data["PersonelID"]
            if(data["DrugSensitivity"] != null && data["DrugSensitivity"] != undefined){
                data["DrugSensitivity"].forEach(element => {
                    this.DrugSensitivity.unshift(new DrugSensitivity(element))
                });
            }else{
                this.DrugSensitivity = []
            }
 
            this.OtherSensitivity = new OtherSensitivity(data["OtherSensitivity"])

            this.HasSensitivity = data["HasSensitivity"]
        }
        else {
            this.DrugSensitivity = []
            this.OtherSensitivity = new OtherSensitivity()
        }
    }
}