import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../../../src/app/modal/modal.service';
import { TeekNote } from '../../../../../src/app/models/Teeknote';
import { DataService } from '../../../../../src/app/services/data.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalFile } from '../../../../../src/app/models/customer';
import { User } from '../../../../../src/app/models/user';
import Swal from 'sweetalert2';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-admin-panel-header',
  templateUrl: './admin-panel-header.component.html',
  styleUrls: ['./admin-panel-header.component.scss']
})
export class AdminPanelHeaderComponent extends CaretexComponent implements OnInit {

  TeekNotes: TeekNote[] = [];
  @Output() QuickButtonclicked = new EventEmitter<boolean>();
  @Input() isSideNavbarcollapsed: boolean = false
  @Input() IsFileBar: boolean = true
  @Input() TabId: string = ""


  selectedNote: TeekNote
  CurentUser: User
  selectedNoteIndex: number
  TotalNumberOfNotes: Number
  fileToUpload: File | null;
  profilePicUrl: string
  newNote: TeekNote
  id: string
  @ViewChild("newNoteText") newNoteText: ElementRef;
  medicalFile: MedicalFile
  showAsAlert: boolean
  removeNotesCount: boolean
  removeAlertsCount = true
  removeMsgsCount = true
  isQuickButtonClicked: boolean = false
  private eventNewProfilePicSubscription: Subscription;
  DefaultPhotoURL = "../../../../assets/images/user-avatar.png"
  @Input() events: Observable<void>;


  constructor(
    private _title: Title,
    private router: Router,
    public dataservice: DataService,
    private _ar: ActivatedRoute,
    public medicalFileService: MedicalFileProviderService,
    private modalService: ModalService,
    private toastrService: ToasterService,
    private authService: AuthService,
    private medicalFielService: MedicalFileProviderService,
    private sharedServices: CaretxSharedModulesProviderService,) {
    super(medicalFileService)

  }

  ngOnInit(): void {
    this.id = this._ar.snapshot.params['id'];
    this.CurentUser = this.authService.LoggedInUser
    if (this.CurentUser.photoURL == "" || this.CurentUser.photoURL == null || this.CurentUser.photoURL == undefined) {
      this.CurentUser.photoURL = "../../../../assets/images/user-avatar.png"
    }
    //this.CheckIfComponentIsDisabled(this.id)
    if (this.id == undefined) {
      this.showAsAlert = false
    } else {
      this.medicalFielService.openedFile.subscribe(data => {
        this.medicalFile = data
        this.showAsAlert = this.medicalFile.IsHazard
      })
    }

    if (this.IsFileBar) {
      this.getTeekNotesByFileID()
    } else {
      this.getUserNotesByUserID()
    }
    this.newNote = new TeekNote(this.IsFileBar,this.authService.LoggedInUser.UID,this.id)
    if (this.events != null)

    this.eventNewProfilePicSubscription = this.events.subscribe(() => this.refreshNavBarProfileImage());


  }








  refreshNavBarProfileImage(){
    this.CurentUser = this.authService.LoggedInUser 


  }

  getTeekNotesByFileID() {
    this.sharedServices.getTeekNotes(this.id,"file").subscribe((data: TeekNote[]) => {
      this.TeekNotes = data;
      if (this.TeekNotes.length > 0) {
        this.selectedNote = this.TeekNotes[0]
        this.selectedNoteIndex = 1
        this.removeNotesCount = false
        this.TotalNumberOfNotes = this.TeekNotes.length
      }
      else {
        this.TotalNumberOfNotes = 0
        this.removeNotesCount = true
        this.modalService.close('teek-note-modal')
      }
    });
  }

  getUserNotesByUserID() {
    let userid: string = this.authService.LoggedInUser.UID
    this.sharedServices.getTeekNotes(userid,"user").subscribe((data: TeekNote[]) => {
      this.TeekNotes = data;
      if (this.TeekNotes.length > 0) {
        this.selectedNote = this.TeekNotes[0]
        this.selectedNoteIndex = 1
        this.removeNotesCount = false
        this.TotalNumberOfNotes = this.TeekNotes.length
      }
      else {
        this.TotalNumberOfNotes = 0
        this.removeNotesCount = true
        this.modalService.close('teek-note-modal')
      }
    });
  }



  openNotificationPage() {
    this.dataservice.isMedicalFilesSelected = false
    this.dataservice.isAlertsSelected = false
    this.dataservice.isNotificationsSelected = true
    this.dataservice.isMessagesSelected = false
    this.router.navigate(['notifications']);
  }

  openMEdicalFilesPage() {

    this.dataservice.isMedicalFilesSelected = true
    this.dataservice.isAlertsSelected = false
    this.dataservice.isNotificationsSelected = false
    this.dataservice.isMessagesSelected = false
    this.router.navigate(['medfiles']);

  }

  openLandingPage() {

    this.router.navigate(['main']);
  }


  openNoteCards() {

    if (this.TeekNotes.length <= 0)
      this.openModal("new-teek-note-modal")
    else
      this.openModal('teek-note-modal')
  }

  openModal(id: string) {
    this.modalService.open(id);
  }



  showPrevNote() {

    --this.selectedNoteIndex
    if (this.selectedNoteIndex < 1)
      this.selectedNoteIndex = this.TeekNotes.length

    this.selectedNote = this.TeekNotes[this.selectedNoteIndex - 1]


  }

  showNextNote() {
    ++this.selectedNoteIndex
    if (this.selectedNoteIndex > this.TeekNotes.length)
      this.selectedNoteIndex = 1

    this.selectedNote = this.TeekNotes[this.selectedNoteIndex - 1]
  }

  onClickDeleteNote() {
    let mode = ""
    if (this.IsFileBar) {
      mode = "file"
    } else {
      mode = "user"
    }
    this.sharedServices.deleteTeekNote(this.selectedNote,mode).subscribe(() => {
      if (this.IsFileBar) {
        this.getTeekNotesByFileID()
      } else {
        this.getUserNotesByUserID()
      }
    });
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }


  addNoteCards() {
    this.modalService.open("new-teek-note-modal")
    this.newNote = new TeekNote(this.IsFileBar,this.authService.LoggedInUser.UID,this.id)

    if (this.IsFileBar) {
      this.newNote.IsFileNote = true
      this.newNote.PersonelID = this.id
    }else{
      this.newNote.IsFileNote = false

    }
    this.newNoteText.nativeElement.focus()
  }

  saveNewNote() {
    this.sharedServices.addTeekNotes(this.newNote).subscribe(() => {
      if (this.IsFileBar) {
        this.getTeekNotesByFileID()
      } else {
        this.getUserNotesByUserID()
      }
      this.newNote = new TeekNote(this.IsFileBar,this.authService.LoggedInUser.UID,this.id)
      this.closeModal("new-teek-note-modal")
    });

  }

  openFile() {
    (document.querySelector('input[id="file-input"]') as HTMLElement).click()
  }

  uploadProfilePic(files: FileList) {
    this.fileToUpload = files.item(0);
    this.sharedServices.uploadProfilePic(this.fileToUpload).subscribe(data => {
      // do something, if upload success
      this.profilePicUrl = data["filepath"]
      this.toastrService.Sucesss("", this.toastrService.successMsg)
    }, error => {
      this.toastrService.Error("", error.error.msg)
      console.log(error);
    });

  }


  signOut() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה לצאת מהמערכת ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.authService.SignOut()
      }
    })

  }

  goToMedicalFiels() { this.router.navigate(['medfiles']); }
  OpenProfile() {
    this.router.navigateByUrl("/users_management/" + this.authService.LoggedInUser.UID)
  }

  openQuickActions() {
    this.isQuickButtonClicked = !this.isQuickButtonClicked
    this.QuickButtonclicked.emit(this.isQuickButtonClicked)
  }

  openMessages() {
    this.router.navigate(['messages']);
  }

  setDefaultPic() {
    this.CurentUser.photoURL = "./../../../assets/images/user-avatar.png"
  }
}
