import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CaretxSetupsModuleRoutingModule } from './caretx-setups-module-routing.module';
import { SetupslandingpageComponent } from './components/setupslandingpage/setupslandingpage.component';
import { SharedModule } from '../caretx-shared-module/shared-module.module';
import { GeneralhousesetupComponent } from './components/generalhousesetup/generalhousesetup.component';
import { CaretxSetupsProviderService } from './setup-services/setups.data-provider.service';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DepartmentssetupComponent } from './components/departmentssetup/departmentssetup.component';
import { FundersetupComponent } from './components/fundersetup/fundersetup.component';
import { NursingstatusessetupComponent } from './components/nursingstatusessetup/nursingstatusessetup.component';
import { HospitalsComponent } from './components/hospitals/hospitals.component';
import { EssentialmeasuresComponent } from './components/essentialmeasures/essentialmeasures.component';
import { BedSoresSettingsComponent } from './components/bed-sores-settings/bed-sores-settings.component';
import { BedSoreslocationsComponent } from './components/bed-soreslocations/bed-soreslocations.component';
import { BedsorestatusesComponent } from './components/bedsorestatuses/bedsorestatuses.component';
import { BedsoresidesComponent } from './components/bedsoresides/bedsoresides.component';
import { HazardsSettingsComponent } from './components/hazards-settings/hazards-settings.component';
import { HazardriskComponent } from './components/hazardrisk/hazardrisk.component';
import { HazardstypesComponent } from './components/hazardstypes/hazardstypes.component';
import { LabtypesComponent } from './components/labtypes/labtypes.component';
import { LabcategorytestsComponent } from './components/labtypes/labcategorytests/labcategorytests.component';
import { FamilyrelationsSetupComponent } from './components/familyrelations-setup/familyrelations-setup.component';
import { SocialprovidersComponent } from './components/socialproviders/socialproviders.component';
import { NusringinstructionssetupComponent } from './components/nusringinstructionssetup/nusringinstructionssetup.component';
import { EssentialmeasuresdetailsComponent } from './components/essentialmeasures/essentialmeasuresdetails/essentialmeasuresdetails.component';
import { SocialPocketCodesComponent } from './components/social-pocket-codes/social-pocket-codes.component'
import { DieticianweightstatusComponent } from './components/dieticianweightstatus/dieticianweightstatus.component';
import { DieticiandrinkformComponent } from './components/dieticiandrinkform/dieticiandrinkform.component';
import { DieticianfeedformComponent } from './components/dieticianfeedform/dieticianfeedform.component';
import { DieticiannutritionalstatusComponent } from './components/dieticiannutritionalstatus/dieticiannutritionalstatus.component';
import { DieticianteethstatusComponent } from './components/dieticianteethstatus/dieticianteethstatus.component';
import { DieticianswallowstatusComponent } from './components/dieticianswallowstatus/dieticianswallowstatus.component';
import { DieticianeatingstatusesComponent } from './components/dieticianeatingstatuses/dieticianeatingstatuses.component';
import { DietarySupplementComponent } from './components/dietary-supplement/dietary-supplement.component';
import { DieticianfoodadditionsComponent } from './components/dieticianfoodadditions/dieticianfoodadditions.component';
import { ReferrersetupComponent } from './components/referrersetup/referrersetup.component';
import { DruguomsComponent } from './components/druguoms/druguoms.component';
import { WorkingshiftssetupComponent } from './components/workingshiftssetup/workingshiftssetup.component';
import { EventtypesetupComponent } from './components/eventtypesetup/eventtypesetup.component';
import { AmbulancessetupComponent } from './components/ambulancessetup/ambulancessetup.component';
import { EssentialMetricsTypesComponent } from './components/essential-metrics-types/essential-metrics-types.component';
import { ExceptionaleventlocationComponent } from './components/exceptionaleventlocation/exceptionaleventlocation.component';
import { BedsComponent } from './components/beds/beds.component';
import { ConsciousnessStateComponent } from './components/consciousness-state/consciousness-state.component';
import { MedicalfileSettingsComponent } from './components/medicalfile-settings/medicalfile-settings.component';
import { NursephysicalstatusesSetupComponent } from './components/nursephysicalstatuses-setup/nursephysicalstatuses-setup.component';
import { NursephysicalstatusesDetailsComponent } from './components/nursephysicalstatuses-setup/nursephysicalstatuses-details/nursephysicalstatuses-details.component';
import { NursephysicalstatusesDetails2Component } from './components/nursephysicalstatuses-setup/nursephysicalstatuses-details2/nursephysicalstatuses-details2.component';
import { PainLevelSetupComponent } from './components/pain-level-setup/pain-level-setup.component';
import { PainLocationSetupComponent } from './components/pain-location-setup/pain-location-setup.component';
import { PainMitigatingFactorSetupComponent } from './components/pain-mitigating-factor-setup/pain-mitigating-factor-setup.component';
import { PainSymptomsSetupComponent } from './components/pain-symptoms-setup/pain-symptoms-setup.component';
import { PainNatureSetupComponent } from './components/pain-nature-setup/pain-nature-setup.component';
import { PaintreatmentSetupComponent } from './components/paintreatment-setup/paintreatment-setup.component';
import { ResistanceTypesComponent } from './components/resistance-types/resistance-types.component';
import { ContaminationSourcesComponent } from './components/contamination-sources/contamination-sources.component';
import { IsolationTypesComponent } from './components/isolation-types/isolation-types.component';
import { AdhesionPlacesComponent } from './components/adhesion-places/adhesion-places.component';
import { InfectionStatusesComponent } from './components/infection-statuses/infection-statuses.component';
import { HospitalRoomComponent } from './components/hospital-room/hospital-room.component';
import { DedicatedHospitalizationTeamComponent } from './components/dedicated-hospitalization-team/dedicated-hospitalization-team.component';
import { PCRTestComponent } from './components/pcrtest/pcrtest.component';
import { HodgeTestComponent } from './components/hodge-test/hodge-test.component';
import { VaccinationsComponent } from './components/vaccinations/vaccinations.component';


@NgModule({
  declarations: [
    SetupslandingpageComponent,
    GeneralhousesetupComponent,
    DepartmentssetupComponent,
    FundersetupComponent,
    NursingstatusessetupComponent,
    HospitalsComponent,
    EssentialmeasuresComponent,
    BedSoresSettingsComponent,
    BedSoreslocationsComponent,
    BedsorestatusesComponent,
    BedsoresidesComponent,
    HazardsSettingsComponent,
    HazardstypesComponent,
    HazardriskComponent,
    LabtypesComponent,
    LabcategorytestsComponent,
    FamilyrelationsSetupComponent,
    SocialprovidersComponent,
    NusringinstructionssetupComponent,
    EssentialmeasuresdetailsComponent,
    SocialPocketCodesComponent,
    DieticianweightstatusComponent,
    DieticiandrinkformComponent,
    DieticianfeedformComponent,
    DieticiannutritionalstatusComponent,
    DieticianteethstatusComponent,
    DieticianswallowstatusComponent,
    DieticianeatingstatusesComponent,
    DietarySupplementComponent,
    DieticianfoodadditionsComponent,
    ReferrersetupComponent,
    DruguomsComponent,
    WorkingshiftssetupComponent,
    EventtypesetupComponent,
    AmbulancessetupComponent,
    EssentialMetricsTypesComponent,
    ExceptionaleventlocationComponent,
    BedsComponent,
    ConsciousnessStateComponent,
    MedicalfileSettingsComponent,
    NursephysicalstatusesSetupComponent,
    NursephysicalstatusesDetailsComponent,
    NursephysicalstatusesDetails2Component,
    PainLevelSetupComponent,
    PainLocationSetupComponent,
    PainMitigatingFactorSetupComponent,
    PainSymptomsSetupComponent,
    PainNatureSetupComponent,
    PaintreatmentSetupComponent,
    ResistanceTypesComponent,
    ContaminationSourcesComponent,
    IsolationTypesComponent,
    AdhesionPlacesComponent,
    InfectionStatusesComponent,
    HospitalRoomComponent,
    DedicatedHospitalizationTeamComponent,
    PCRTestComponent,
    HodgeTestComponent,
    VaccinationsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    
    CaretxSetupsModuleRoutingModule,
    SharedModule
  ],
  providers: [CaretxSetupsProviderService]
})
export class CaretxSetupsModuleModule { }
