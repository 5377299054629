import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../caretx-shared-module/shared-module.module';
import { PipesModule } from '../caretx-custompipes-module/custompipes.module';
import { BrowserModule } from '@angular/platform-browser';
import { ModalModule } from '../modal';
import { DieticiananchorComponent } from './components/dieticiananchor/dieticiananchor.component';
import { CaretexDieticianRoutingModule } from './caretex-dietician-routing.module';
import { DieticianfollowupComponent } from './../caretex-dietician/components/dieticianfollowup/dieticianfollowup.component';
import { DieticianassessmentComponent } from './../caretex-dietician/components/dieticianassessment/dieticianassessment.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core'; 
import { DoctorsModule } from '../caretx-doctors-module/doctors.module';
import { HistoricfollowupsComponent } from './components/dieticianfollowup/historicfollowups/historicfollowups.component';
import { DieticianDataProviderService } from './Dietician-services/dietician.data-provider.service';
import { DieticianassessmentsListComponent } from './components/dieticianassessments-list/dieticianassessments-list.component';
import { YearlyweightreportComponent } from './components/yearlyweightreport/yearlyweightreport.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DailyfoodintakeComponent } from './components/dailyfoodintake/dailyfoodintake.component';
import { DailyfoodintakelistComponent } from './components/dailyfoodintakelist/dailyfoodintakelist.component';
import { DieticianRecommendationsComponent } from './components/dietician-recommendations/dietician-recommendations.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxSummernoteModule } from 'ngx-summernote';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
@NgModule({
  declarations: [   

    DieticianassessmentComponent,
    DieticianfollowupComponent,
    DieticiananchorComponent,
    HistoricfollowupsComponent,
    DieticianassessmentsListComponent,
    YearlyweightreportComponent,
    DailyfoodintakeComponent,
    DailyfoodintakelistComponent,
    DieticianRecommendationsComponent
  ],
  imports: [
    CommonModule,
    CaretexDieticianRoutingModule,
    CommonModule,
    FormsModule,
    SharedModule,
    DoctorsModule,
    BrowserModule,
    ReactiveFormsModule,
    PipesModule,
    ModalModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgMultiSelectDropDownModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    NgxSummernoteModule

  
  ] ,
   providers: [DieticianDataProviderService]
})
export class CaretexDieticianModule { }
