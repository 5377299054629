import { InstrStatus, BridgeEnums } from '../services/enums'
import { Drug } from './drug'
import { propertyMap } from './modelmapper'
import { CustomerService } from '../services/customer.service'
import { User } from './user'
import { Diagnoses } from './diagnoses'
import { DrugUom } from './generalhousedsetups'
import { MedicalFile } from './customer'

// Need Server to send Payload in below structure
export class DrugInstruction {
    bridgeEnum: BridgeEnums
    clientId: string
    @propertyMap('ID')
    public ID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('RealmID')
    public RealmID: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('instructionId')
    instructionId: string
    @propertyMap('drugId')
    drugId: string
    @propertyMap('Drug')
    Drug: Drug
    @propertyMap('drugEngName')
    drugEngName: string
    @propertyMap('drugHebName')
    drugHebName: string
    @propertyMap('drugIndications')
    drugIndications: string
    @propertyMap('instructionType')
    instructionType: string
    @propertyMap('dosageForm')
    dosageForm: string // טליות . משחה ...
    @propertyMap('route')
    route: string // דרך מתן
    @propertyMap('dose')
    dose: number  // מינון
    @propertyMap('doseUom')
    doseUom: string // יחידת מינון
    @propertyMap('DoseUomRec')
    DoseUomRec: DrugUom // יחידת מינון
    @propertyMap('frequency')
    frequency: string
    @propertyMap('frequencyUom')
    frequencyUom: string // פעמים ביום . פעמים בשבוע ...
    @propertyMap('maxInday')
    maxInday: number // מקסימום ביום
    @propertyMap('daysToSkip')
    daysToSkip: number // אחת ל
    @propertyMap('ivRate')
    ivRate: string
    @propertyMap('ivDuration')
    ivDuration: number
    @propertyMap('drugInstrType')
    drugInstrType: string  // קבועה . SOS ...
    @propertyMap('instrCreated_at')
    instrCreated_at: Date
    @propertyMap('instrStartDate')
    instrStartDate: Date
    @propertyMap('instrDueDate')
    instrDueDate: Date
    @propertyMap('instrFullfillmentDate')
    instrFullfillmentDate: Date
    @propertyMap('instrStatus')
    instrStatus: string
    @propertyMap('nurseId')
    nurseId: string
    @propertyMap('doctorId')
    doctorId: string
    @propertyMap('Doctor')
    Doctor: User
    @propertyMap('doctorNotes')
    doctorNotes: string
    @propertyMap('periodInDays')
    periodInDays: number
    @propertyMap('StatusChangeReason')
    StatusChangeReason: string
    @propertyMap('Origin')
    Origin: string
    ShowDetails: boolean
    @propertyMap('Modified')
    public Modified: boolean
    @propertyMap('New')
    public New: boolean
    public addedToList: boolean
    @propertyMap('Time1')
    Time1: Date
    @propertyMap('Time2')
    Time2: Date
    @propertyMap('Time3')
    Time3: Date
    @propertyMap('Time4')
    Time4: Date
    @propertyMap('Time5')
    Time5: Date
    @propertyMap('TreatedDiagnoses')
    TreatedDiagnoses: TreatedDiagnoses[]
    @propertyMap('IsSun')
    public IsSun: boolean
    @propertyMap('IsMon')
    public IsMon: boolean
    @propertyMap('IsTue')
    public IsTue: boolean
    @propertyMap('IsWed')
    public IsWed: boolean
    @propertyMap('IsThu')
    public IsThu: boolean
    @propertyMap('IsFri')
    public IsFri: boolean
    @propertyMap('IsSat')
    public IsSat: boolean
    @propertyMap('IsFromAdmission')
    public IsFromAdmission: boolean
    @propertyMap('IsAdmissionInstrActive')
    public IsAdmissionInstrActive: boolean
    @propertyMap('SignedByNurseAt')
    SignedByNurseAt: Date
    @propertyMap('SignedByDoctorAt')
    SignedByDoctorAt: Date
    @propertyMap('PersonDoc')
    public PersonDoc: MedicalFile
    @propertyMap('NurseRejectComment')
    NurseRejectComment: string
    

    @propertyMap('MaxDose')
    MaxDose: number
    @propertyMap('MaxNumber')
    MaxNumber: number
    @propertyMap('DiffDoseHours')
    DiffDoseHours: number

    @propertyMap('IsPainMedication')
    IsPainMedication: boolean

    @propertyMap('AllowOnRelease')
    AllowOnRelease: boolean


    constructor(data?: Object ,personelId? :string , realmId?:string) {
        if (data != undefined && data != null) {
            this.ShowDetails = false;
            this.clientId = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            this.IsFromAdmission = data["IsFromAdmission"]
            this.IsAdmissionInstrActive = data["IsAdmissionInstrActive"]
            this.IsPainMedication = data["IsPainMedication"]
            this.MaxDose = data["MaxDose"]
            this.MaxNumber = data["MaxNumber"]
            this.DiffDoseHours = data["DiffDoseHours"]
            this.AllowOnRelease = data["AllowOnRelease"]
            this.IsSun = data["IsSun"]
            this.IsMon = data["IsMon"]
            this.IsTue = data["IsTue"]
            this.IsWed = data["IsWed"]
            this.IsThu = data["IsThu"]
            this.IsFri = data["IsFri"]
            this.IsSat = data["IsSat"]
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.RealmID = data["RealmID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.drugId = data["drugId"]
            this.Drug = data["Drug"]
            this.drugEngName = data["drugEngName"]
            this.drugHebName = data["drugHebName"]
            this.drugIndications = data["drugIndications"]
            this.instructionType = data["instructionType"]
            this.dosageForm = data["dosageForm"]// טליות . משחה ...
            this.route = data["route"]// דרך מתן
            this.dose = data["dose"]  // מינון
            this.doseUom = data["doseUom"]// יחידת מינון
            this.frequency = data["frequency"]
            this.frequencyUom = data["frequencyUom"] // פעמים ביום . פעמים בשבוע ...
            this.maxInday = data["maxInday"] // מקסימום ביום
            this.daysToSkip = data["daysToSkip"] // אחת ל
            this.ivRate = data["ivRate"]
            this.ivDuration = data["ivDuration"]
            this.drugInstrType = data["drugInstrType"] // קבועה . SOS ...
            this.instrFullfillmentDate = data["instrFullfillmentDate"]
            this.nurseId = data["nurseId"]
            this.doctorId = data["doctorId"]
            this.Doctor = data["Doctor"]
            this.doctorNotes = data["doctorNotes"]
            this.periodInDays = data["periodInDays"]
            this.StatusChangeReason = data["StatusChangeReason"]
            this.bridgeEnum = new BridgeEnums()
            this.instrCreated_at = data["instrCreated_at"]
            this.instrStartDate = data["instrStartDate"]
            this.instrDueDate = data["instrDueDate"]
            this.instrStatus = data["instrStatus"]
            this.daysToSkip = data["daysToSkip"]
            this.Origin = data["Origin"]
            this.Modified = data["Modified"]
            this.New = data["New"]
            this.ShowDetails = false
            this.Time1 =  data["Time1"]
            this.Time2 =  data["Time2"]
            this.Time3 =  data["Time3"]
            this.Time4 =  data["Time4"]
            this.Time5=  data["Time5"]
            this.TreatedDiagnoses = data["TreatedDiagnoses"]
            this.DoseUomRec=data["DoseUomRec"]
            this.SignedByNurseAt=data["SignedByNurseAt"]
            this.SignedByDoctorAt=data["SignedByDoctorAt"]
            this.PersonDoc = data["PersonDoc"]
            this.NurseRejectComment = data["NurseRejectComment"]
        }
        else {
            this.TreatedDiagnoses = []
            this.ShowDetails = false;
            this.clientId = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            this.PersonelID = personelId
            this.RealmID = realmId
            this.instructionType = "F"
            this.frequencyUom = "D" 
            this.periodInDays = 1
            this.StatusChangeReason = ""
            this.ShowDetails = false
            this.bridgeEnum = new BridgeEnums()
            let dateOfNow: Date = new Date();
            this.instrCreated_at = dateOfNow
            this.instrStartDate = dateOfNow
            this.instrDueDate = dateOfNow
            this.instrStatus = InstrStatus.NotReady
            this.daysToSkip = 1
            this.New = true
            this.Modified = false
            this.Time1 = new Date(2023, 1, 1, 8, 0, 0, 0);  
            this.Time2 = new Date(2023, 1, 1, 12, 0, 0, 0);  
            this.Time3 = new Date(2023, 1, 1, 16, 0, 0, 0);  
            this.Time4 = new Date(2023, 1, 1, 20, 0, 0, 0);  
            this.Time5 = new Date(2023, 1, 1, 24, 0, 0, 0); 
            this.PersonDoc =  new MedicalFile()
            this.PersonDoc.FirstName = "--"
            this.PersonDoc.LastName=""
            this.PersonDoc.PhotoNotExists = true
            this.PersonDoc.ImagePath = ""
        }
    }


    buildDrugNameforDisplay(): string {
        if (this.Drug.ShortTradeName == undefined) return ""
        let longtxt :string 
        if (this.Drug.LongTradeName == undefined || this.Drug.LongTradeName == "" ){
            longtxt = ""
        }else{
            longtxt = "(" + this.Drug.LongTradeName + ")"
        }

        return this.Drug.ShortTradeName + " " + longtxt
    }


    BuildRateStringToDisplay(): string {
        this.bridgeEnum = new BridgeEnums()
        let text: string = ''
        let drugFreqUomDescr: string = this.bridgeEnum.getName(this.frequencyUom, this.bridgeEnum.DrugFreqUoms)
        let drugFreq: string = this.bridgeEnum.getName(this.frequency, this.bridgeEnum.DrugFreqs)

        switch (this.frequencyUom) {
            case "D":
                if (this.daysToSkip == undefined)
                    text = drugFreq + " " + drugFreqUomDescr + " כל יום "
                else
                    text = drugFreq + " " + drugFreqUomDescr + " כל " + this.daysToSkip + "ימים"
                break

            case "P":
                if (this.maxInday == undefined)
                    text = "לפי צורך"
                else
                    text = " לפי צורך מקסימום " + this.maxInday + " ביום"
                break
            default:
                break
        }

        return text

    }

    BuildDoseForDisplay(): string {

        let text: string = ''
        switch (this.doseUom) {
            case "cc":
                text = this.dose + " " + "cc(ml)"
                break
            case "mg":
                text = this.dose + " " + "mg"
                break
            case "T":
                text = this.dose + " " + "כדורים"
                break
            case "D":
                text = this.dose + " " + "טיפות"
                break

            case "C":
                text = this.dose + " " + "מריחות"
                break
            default:
                break

        }
        return text
    }

    BuildRoutForDisplay(): string {
        let dosageForm: string = this.bridgeEnum.getName(this.dosageForm, this.bridgeEnum.DrugDosageForm)
        return dosageForm
    }

    NewInstruction(personelId:string , realmId:string) {
        this.ShowDetails = false;
        this.clientId = Math.floor(Math.random() * 16).toString(16).toUpperCase();
        this.PersonelID = personelId
        this.RealmID = realmId
        this.drugId = undefined
        this.Drug = new Drug()
        this.drugEngName = undefined
        this.drugHebName = undefined
        this.drugIndications = undefined
        this.instructionType = undefined
        this.dosageForm = undefined// טליות . משחה ...
        this.route = undefined// דרך מתן
        this.dose = undefined  // מינון
        this.doseUom = undefined// יחידת מינון
        this.frequency = undefined
        this.frequencyUom = undefined // פעמים ביום . פעמים בשבוע ...
        this.maxInday = 1 // מקסימום ביום
        this.daysToSkip = 1 // אחת ל
        this.ivRate = undefined
        this.ivDuration = undefined
        this.drugInstrType = undefined // קבועה . SOS ...
        this.instrCreated_at = undefined
        this.instrStartDate = undefined
        this.instrDueDate = undefined
        this.instrFullfillmentDate = undefined
        this.instrStatus = undefined
        this.nurseId = undefined
        this.doctorId = undefined
        this.doctorNotes = undefined
        this.periodInDays = undefined
        this.ShowDetails = false
    }

    cloneDrugInstruction(): DrugInstruction {
        let tmp = new DrugInstruction()
        tmp.daysToSkip = this.daysToSkip
        tmp.doctorNotes = this.doctorNotes
        tmp.dosageForm = this.dosageForm
        tmp.dose = this.dose
        tmp.doseUom = this.doseUom
        tmp.drugId = this.drugId
        tmp.drugInstrType = this.drugInstrType
        tmp.frequency = this.frequency
        tmp.frequencyUom = this.frequencyUom
        tmp.instrStartDate = this.instrStartDate
        tmp.instrDueDate = this.instrDueDate
        tmp.instructionType = this.instructionType
        tmp.ivDuration = this.ivDuration
        tmp.ivRate = this.ivRate
        tmp.maxInday = this.maxInday
        tmp.route = this.route
        tmp.periodInDays = this.periodInDays
        return tmp
    }

    isDrugInstructionChanged(originalDrugInst: DrugInstruction): boolean {
        let ischanged = false;
        if (this.New || originalDrugInst == undefined) return true
        if (this.daysToSkip != originalDrugInst.daysToSkip) return true;
        if (this.doctorNotes != originalDrugInst.doctorNotes) return true;
        if (this.dosageForm != originalDrugInst.dosageForm) return true;;
        if (this.dose != originalDrugInst.dose) return true;
        if (this.doseUom != originalDrugInst.doseUom) return true;
        if (this.drugId != originalDrugInst.drugId) return true;
        if (this.drugInstrType != originalDrugInst.drugInstrType) return true;
        if (this.frequency != originalDrugInst.frequency) return true;
        if (this.frequencyUom != originalDrugInst.frequencyUom) return true;
        if (this.instrStartDate != originalDrugInst.instrStartDate) return true;
        if (this.instrDueDate != originalDrugInst.instrDueDate) return true;
        if (this.instructionType != originalDrugInst.instructionType) return true;
        if (this.ivDuration != originalDrugInst.ivDuration) return true;
        if (this.ivRate != originalDrugInst.ivRate) return true;
        if (this.maxInday != originalDrugInst.maxInday) return true;
        if (this.route != originalDrugInst.route) return true;
        if (this.periodInDays != originalDrugInst.periodInDays) return true;

        return ischanged
    }

}



export class TreatedDiagnoses {
    @propertyMap('ID')
    public ID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('DrugInstrId')
    public DrugInstrId: string
    @propertyMap('DiagnosesId')
    DiagnosesId: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('DiagnosesRecord')
    DiagnosesRecord: Diagnoses
    @propertyMap('DoctorId')
    DoctorId: string
    @propertyMap('Doctor')
    Doctor: User
}






export class TeekDrugInstruction {
    instructions: DrugInstruction[]
    _customersService: CustomerService

    constructor(customersService: CustomerService) {
        this.instructions = []
        this._customersService = customersService
    }

    public addInstruction(inst: DrugInstruction) {
        this.instructions.push(inst)
    }

    public setInsStatus(instructionId: string, teekId: string, status: InstrStatus, comment: string) {
 
    }

    public showInstDetails(instructionId: string) {
        this.instructions.forEach((item, index) => {
            if (item.instructionId === instructionId)
                item.ShowDetails = !item.ShowDetails

        });
    }

}


export class InstructionStatusObj {
    Status: string
    Comment: string
}


