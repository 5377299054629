import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { FamilyRelations, SocialProvider } from '../../../../src/app/models/generalhousedsetups';
import { SocialAdmission } from '../../../../src/app/models/socialadmission';
import { AuthService } from '../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSocialProviderService } from '../Social.data-provider.service';
import { CaretexComponent } from '../../../../src/app/shared/CaretexComponent';
import {  CartexEvent } from '../../../../src/app/models/genericTypes';
import { Attachment } from 'src/app/models/attachments';
import { CaretxSharedModulesProviderService } from 'src/app/caretx-shared-module/sharedmodule.data-provider.services';

@Component({
  selector: 'app-socialadmission',
  templateUrl: './socialadmission.component.html',
  styleUrls: ['./socialadmission.component.css']
})
export class SocialadmissionComponent extends CaretexComponent implements OnInit {
  @ViewChild('mainform') mainFormElement: NgForm;
 // CurrentStep: SocialAdmissionSteps = SocialAdmissionSteps.History
  isrecordClosed: boolean = false
  currentSocialAdmission: SocialAdmission = new SocialAdmission()
  familyRelations: FamilyRelations[] = []
  providers: SocialProvider[] = []
 // tabHistory: TabHistory = new TabHistory()
  caretexEvent: CartexEvent = new CartexEvent()
  id: string
  Socialid: string
  HousingOptions: { id: string, descr: string }[]
  HousingOwner: { id: string, descr: string }[]
  moduleName: string = "קבלה סוציאלית"
  step: number = 0


  constructor(
    private _title: Title,
    private router: Router,
    public medicalFileService: MedicalFileProviderService,
    private route: ActivatedRoute,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private _ar: ActivatedRoute,
    private toastrService: ToasterService,
    private authService: AuthService,
    private caretxSetupsProviderService: CaretxSetupsProviderService) {
    super(medicalFileService)
    this.caretxSetupsProviderService.GetFamilyRelations().subscribe((data => {
      this.familyRelations = data
    }))

    this.caretxSetupsProviderService.GetSocialProviders().subscribe((data => {
      this.providers = data
    }))


    this.HousingOptions = [
      { "id": "A", "descr": "גר לבד" },
      { "id": "B", "descr": "גר עם בן זוג/משפחה" },
      { "id": "C", "descr": "גר עם מטפל" }
    ];
    this.HousingOwner = [
      { "id": "A", "descr": "בן/בת משפחה" },
      { "id": "B", "descr": "מטופל" },
      { "id": "C", "descr": "שכירות" },
      { "id": "D", "descr": "דיור ציבורי" },
      { "id": "E", "descr": "אחר" },
    ];

  }

  ngOnInit(): void {
    this._title.setTitle('Caretex |טופס קבלה סוציאלית');
    this.id = this.route.parent.snapshot.params['id'];
    this.Socialid = this.route.snapshot.params.admissionid
    this.CheckIfComponentIsDisabled(this.id)
    if (this.route.snapshot.params['tabid'] != "" && this.route.snapshot.params['tabid'] != undefined) {
      this.step = Number(this.route.snapshot.params['tabid']);
    }
    if (this.Socialid == 'new') {
      this.getSocialAdmissionFromDB("new")
    } else {
      this.getSocialAdmissionFromDB("record")
    }
  }


  getSocialAdmissionFromDB(mode: string) {
    let filter =""
    if (mode != "new") {
      filter = '(id=' + '\'' +   this.Socialid + '\'' + ')'
    }

    this.caretxSocialProviderService.getSocialAdmission(this.id, filter, mode).subscribe((data: SocialAdmission[]) => {
      if (data.length == 1) {
        this.currentSocialAdmission = new SocialAdmission(data[0])

        if (this.Socialid == 'new') {
          this.currentSocialAdmission.PersonelID = this.id
          this.currentSocialAdmission.Status = "A"
          this.currentSocialAdmission.CreatedById = this.authService.LoggedInUser.UID
        }
        if (this.currentSocialAdmission.Status == "C") {
          this.isrecordClosed = true

        } else
          this.isrecordClosed = false
      }
      else if (data.length == 0) {
        let errorMsg = "המערכת לא הצליחה לייצר רשומה חדשה"
        this.toastrService.Error("", errorMsg)
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }


  setStep(index: number) {
    this.step = index;
  }

  LockDocument() {
    if (this.currentSocialAdmission.Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למעול רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תנעל',
        allowOutsideClick: false,
        allowEscapeKey: false,       
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DoLock()
        }
      })
    }
  }

  DoLock() {
    this.currentSocialAdmission.Status = "C"
    this.currentSocialAdmission.ClosedById = this.authService.LoggedInUser.UID
    this.currentSocialAdmission.Closed_at = new Date()
    this.DoUpdate()

  }

  Save() {
    if (this.Socialid == 'new') {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }

  }

  DoSave() {
    this.currentSocialAdmission.UpdatedAt = new Date()
    this.currentSocialAdmission.CreatedAt = this.currentSocialAdmission.UpdatedAt
    this.currentSocialAdmission.CreatedById = this.authService.LoggedInUser.UID
    this.currentSocialAdmission.TenantID = this.authService.LoggedInTenantID
    this.caretxSocialProviderService.createSocialAdmission(this.currentSocialAdmission).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentSocialAdmission = new SocialAdmission(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';

     /*   let tabId: string = this.tabHistory.NextTab
        if (tabId == undefined || tabId == "" || tabId == null) {
          tabId = SocialAdmissionSteps.General
        }*/
        this.medicalFileService.ResetCachedMedicalFile(this.id)
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialadmission/" + this.currentSocialAdmission.ID + "/step/" + this.step)

        //this.router.navigateByUrl("caretexfile/" + this.id + "/socialadmission/" + this.currentSocialAdmission.ID + "/" + tabId)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    this.currentSocialAdmission.UpdatedAt = new Date()
    this.currentSocialAdmission.TenantID = this.authService.LoggedInTenantID
    this.caretxSocialProviderService.UpdateSocialAdmission(this.currentSocialAdmission).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentSocialAdmission = new SocialAdmission(data)
        this.mainFormElement.reset(this.mainFormElement.value);


        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
     //   let tabId: string = this.tabHistory.NextTab
      /*  if (tabId == undefined || tabId == "" || tabId == null) {
          tabId = SocialAdmissionSteps.General
        }*/
        this.medicalFileService.ResetCachedMedicalFile(this.id)
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialadmission/" + this.currentSocialAdmission.ID + "/step/" + this.step)
       // this.router.navigateByUrl("caretexfile/" + this.id + "/socialadmission/" + this.currentSocialAdmission.ID + "/" + tabId)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  BackToList() {
    this.router.navigateByUrl("/caretexfile/" + this.id + "/socialadmission")
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty
  }

  /******************************************************************************* */
  OpenFileDialog(){
    (document.querySelector('input[id="doc-input"]') as HTMLElement).click()
  }

  fileName:string
  DocToUpload: any = null;
  fileTitle:string
  file:any
  attachedFile: Attachment = new Attachment()
  
  prepareDocumentForUpload(files: FileList) {
    this.fileName = ""
    this.DocToUpload = null
    this.fileTitle = ""
    this.file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      this.fileName = this.file["name"]
      this.DocToUpload = reader.result
      this.fileTitle = this.file["name"]
    };
  }

  uploadAdmissionAsPdf() {
    this.attachedFile.CreatedAt = new Date()
    this.attachedFile.UpdatedAt = this.attachedFile.CreatedAt
    this.attachedFile.PersonelID = this.id
    this.attachedFile.FileDataBase64 = this.DocToUpload
    this.attachedFile.Description = ""
    this.attachedFile.FileName = this.fileName
    this.attachedFile.Uploaded_at = new Date()
    this.attachedFile.CreatedById = this.authService.LoggedInUser.UID
    this.attachedFile.CreatedBy = this.authService.LoggedInUser
    this.attachedFile.Uploaded_at = new Date()
    this.caretxSharedlService.uploadFile(this.attachedFile).subscribe({
      next: data => {
      //  this.refreshListOfAttachments.emit(data as Attachment[])
        this.toastrService.Sucesss("", this.toastrService.successMsg)
       // this.dialogRef.close(data as Attachment[]);
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

}
