import { Diagnoses } from './diagnoses'
import { DrugInstruction } from './drugInstruction'
import { propertyMap } from './modelmapper'
import { User } from './user'
import { BedSore } from './bedsore'


export class DieticianAssessment {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('Reason')
    public Reason: string
    @propertyMap('DietaryHabits')
    public DietaryHabits: string
    @propertyMap('LastUpdatedById')
    public LastUpdatedById: string
    public LastUpdatedBy: User
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('Closed_at')
    public Closed_at: Date
    @propertyMap('Status')
    public Status: string
    @propertyMap('AdmissionId')
    public AdmissionId: string
    @propertyMap('Diagnoses')
    public Diagnoses: Diagnoses[]
    @propertyMap('DrugInstructions')
    public DrugInstructions: DrugInstruction[]
    @propertyMap('Anamnesis')
    public Anamnesis: string
    @propertyMap('Summary')
    public Summary: string
    @propertyMap('TreatmentGoals')
    public TreatmentGoals: string
    @propertyMap('TreatmentPurpose')
    public TreatmentPurpose: string
    @propertyMap('Energy1')
    public Energy1: number
    @propertyMap('Energy2')
    public Energy2: number
    @propertyMap('EvaluateDate1')
    public EvaluateDate1: Date
    @propertyMap('EvaluateDate2')
    public EvaluateDate2: Date
    @propertyMap('Protein1')
    public Protein1: number
    @propertyMap('Protein2')
    public Protein2: number
    @propertyMap('WeightEnergy')
    public WeightEnergy: number
    @propertyMap('FixedEnergyRate')
    public FixedEnergyRate: number
    @propertyMap('FixedEnergyRateBy')
    public FixedEnergyRateBy: number
    @propertyMap('WeightProtein')
    public WeightProtein: number
    @propertyMap('FixedProteinRate')
    public FixedProteinRate: number
    @propertyMap('FixedProteinRateBy')
    public FixedProteinRateBy: number
    @propertyMap('Val1')
    public Val1: number
    @propertyMap('Val2')
    public Val2: number
    @propertyMap('Val3')
    public Val3: number
    @propertyMap('ValDate3')
    public ValDate3: Date
    @propertyMap('Val4')
    public Val4: number
    @propertyMap('WeightInterval')
    public WeightInterval: Number
    @propertyMap('WeightIntervalTo')
    public WeightIntervalTo: Number
    



    @propertyMap('Val6')
    public Val6: number
    @propertyMap('Val7')
    public Val7: number
    @propertyMap('WeightStatus')
    public WeightStatus: string
    @propertyMap('EatingStatus')
    public EatingStatus: string
    @propertyMap('TeethStatus')
    public TeethStatus: string
    @propertyMap('SwallowStatus')
    public SwallowStatus: string
    @propertyMap('NutritionalStatus')
    public NutritionalStatus: string
    @propertyMap('FeedStatus')
    public FeedStatus: string
    @propertyMap('DrinkStatus')
    public DrinkStatus: string
    @propertyMap('DigestiveSystem')
    public DigestiveSystem: string
    @propertyMap('NortonScore')
    public NortonScore: number
    @propertyMap('FoodSensitivity')
    public FoodSensitivity: string
    @propertyMap('Opt1')
    public Opt1: boolean
    @propertyMap('Cb1')
    public Cb1: boolean
    
    @propertyMap('BedSores')
    public BedSores:BedSore[]

    public NortonMsg: string

    public WeightNotes: string
    

    constructor(data?: any) {

        if (data != null) {
            this.ID = data.ID   
            this.PersonelID = data.PersonelID
            this.Status = data.Status 
            this.TenantID = data.TenantID
            this.DietaryHabits = data.DietaryHabits
            this.CreatedAt = data.CreatedAt
            this.UpdatedAt = data.UpdatedAt
            this.Closed_at = data.Closed_at
            this.LastUpdatedById = data.LastUpdatedById
            this.LastUpdatedBy = data.LastUpdatedBy
            this.AdmissionId = data.AdmissionId
            this.Diagnoses = data.Diagnoses
            this.Anamnesis = data.Anamnesis
            this.Summary = data.Summary
            this.TreatmentGoals = data.TreatmentGoals
            this.TreatmentPurpose = data.TreatmentPurpose
            this.Energy1 = data.Energy1
            this.Energy2 = data.Energy2
            this.EvaluateDate1 = data.EvaluateDate1
            this.EvaluateDate2 = data.EvaluateDate2
            this.Protein1  = data.Protein1
            this.Protein2  = data.Protein2
            this.WeightEnergy = data.WeightEnergy
            this.WeightProtein = data.WeightProtein
            this.FixedEnergyRateBy = data.FixedEnergyRateBy
            this.FixedEnergyRate = data.FixedEnergyRate
            this.FixedProteinRate = data.FixedProteinRate
            this.Val1 = data.Val1
            this.Val2 = data.Val2
            this.Val3 = data.Val3
            this.Val4 = data.Val4
            this.ValDate3 = data.ValDate3
            this.WeightInterval = data.WeightInterval
            this.Val6 = data.Val6
            this.Val7= data.Val7
            this.EatingStatus = data.EatingStatus
            this.WeightStatus = data.WeightStatus
            this.TeethStatus = data.TeethStatus
            this.SwallowStatus = data.SwallowStatus
            this.NutritionalStatus = data.NutritionalStatus
            this.FeedStatus =data.FeedStatus
            this.DrinkStatus = data.DrinkStatus
            this.DigestiveSystem = data.DigestiveSystem
            this.FoodSensitivity = data.FoodSensitivity
            this.Opt1 = data.Opt1
            this.FixedProteinRateBy = data.FixedProteinRateBy
            this.DrugInstructions = data.DrugInstructions
            this.EvaluateDate1 = data.EvaluateDate1
            this.EvaluateDate2 = data.EvaluateDate2
            this.Cb1 = data.Cb1
            this.BedSores = data.BedSores
            this.Reason = data.Reason
            this.NortonScore = data.NortonScore
            this.WeightIntervalTo = data.WeightIntervalTo
            // Calculate  Norton Message 
            if (this.NortonScore  <= 16){
                let txt1 = "סיכון גבוהה להיארעות של פצע לחץ"
                this.NortonMsg =  this.NortonScore + " - " + txt1
            }else {
                let txt2 = "סיכון נמוך להיארעות של פצע לחץ"
                this.NortonMsg =  this.NortonScore + " - " + txt2
            }

            if (this.Val1 == 0) this.Val1 = undefined
            if (this.Val2 == 0) this.Val2 = undefined
            if (this.Val3 == 0) this.Val3 = undefined
            if (this.Val4 == 0) this.Val4 = undefined
            if (this.Val6 == 0) this.Val6 = undefined
            if (this.Val7 == 0) this.Val7 = undefined
            if (this.Energy1 == 0) this.Energy1 = undefined
            if (this.Protein1 == 0) this.Protein1 = undefined
            if (this.Energy2 == 0) this.Energy2 = undefined
            if (this.Protein2 == 0) this.Protein2 = undefined
            if (this.WeightEnergy == 0) this.WeightEnergy = undefined
            if (this.FixedEnergyRate == 0) this.FixedEnergyRate = undefined
            if (this.FixedEnergyRateBy == 0) this.FixedEnergyRateBy = undefined
            if (this.WeightProtein == 0) this.WeightProtein = undefined
            if (this.FixedProteinRateBy == 0) this.FixedProteinRateBy = undefined
            if (this.FixedProteinRate == 0) this.FixedProteinRate = undefined         
        }
        
        else {
            this.Status = "A"
            this.DietaryHabits = ""
            this.UpdatedAt = new Date()
            this.CreatedAt = new Date()
            this.Diagnoses = []
        }
    }



}