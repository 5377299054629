import { Injectable, NgZone } from '@angular/core';

import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from "@angular/router";
import firebase from 'firebase';
import { ModelMapper } from '../models/modelmapper';
import { from, Observable, Subscription, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';


import { User } from '../models/user';
import { catchError, map } from 'rxjs/operators';
import { CaretexOperation } from './enums';
import { ToasterService } from './toastr.service';





@Injectable({
  providedIn: 'root'
})


@Injectable()
export class AuthService {


  userData: User; // Save logged in user data
  private CurrentUser: User
  subscription: Subscription
  browserRefresh: boolean = false
  dataChange: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  public userLoggedInOut: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
 

  public constructor(

    public toastrService: ToasterService,

    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private httpClient: HttpClient
  ) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
      } else {
      }
    })


    firebase.auth().onAuthStateChanged(function (user) {
      if (user == null) {
        localStorage.removeItem('user');
      }
    });
  }

  getUsers(): void {

    let tenantID: any = this.LoggedInTenantID   //JSON.parse(localStorage.getItem('user'));      ///firebase.auth().currentUser;
    if (tenantID) {
      this.httpClient.get<User[]>(environment.gateway + '/users').subscribe(data => {
        this.dataChange.next(data);
      },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
        });

    } else {
      // Return Login Page
      this.ReturnToSignInPage()

    }
  }

  getUsers2(filter: string): Observable<User[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/users' + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(User).map(item)
        })
      }), catchError(this.errorHandler));
  }

  UpdateUser(user: User) {
    return this.httpClient.put(environment.gateway + '/users', user)
  }

  public set CurrentSelectedUser(user: User) {
    this.CurrentUser = user

  }

  get data(): User[] {
    return this.dataChange.value;
  }

  sendAndVerifyToken(idToken: string): void {
    this.httpClient.get(environment.gateway + '/verifyToken/' + idToken).
      subscribe(data => {
        console.log("Token verified");
      },
        (error: HttpErrorResponse) => {
          console.log(error.name + ' ' + error.message);
        });
  }

  // Sign in with email/password

  SignIn(userName: string, password: string) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(environment.gateway + '/getLoggedInUser/' + userName).subscribe(data => {
        let loggedInUser = new ModelMapper(User).map(data);

        if (loggedInUser == null) {
          reject("user is not assigned to tenant");
        }
        firebase.auth().tenantId = loggedInUser[0].tenantID
        return firebase.auth().signInWithEmailAndPassword(userName, password)
          .then((result) => {
            this.ngZone.run(() => {
              this.SetUserData(loggedInUser[0], loggedInUser[0].tenantID);
              result.user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
                // Send token to backend via HTTPS
               // this.sendAndVerifyToken(idToken)
               // console.log(idToken)
                //let user = new User()
  
  
              }).catch(function (error) {
                console.log(error.name + ' ' + error.message);
              //  window.alert(error.message)
                resolve(error.message);
              });
              this.userLoggedInOut.next(true);
              this.router.navigate(['main']);
             // this.router.navigate(['verify-sign-in']);
            });
  
  
          }).catch((error) => {
           // window.alert(error.message)
            resolve(error.message);
          })

          }, (error) => {
            resolve(error.error.message);
          },
          () => {
 
           });
        });
  }


  SignIn3(userName: string, password: string)  {

    // Get tenant id based on username


      this.httpClient.get(environment.gateway + '/getLoggedInUser/' + userName).subscribe(data => {
        let loggedInUser = new ModelMapper(User).map(data);
  
        if (loggedInUser == null) {
          console.log("use is not assigned to tenant");
          return;
        }
        firebase.auth().tenantId = loggedInUser[0].tenantID
        return firebase.auth().signInWithEmailAndPassword(userName, password)
          .then((result) => {
            this.ngZone.run(() => {
              this.SetUserData(loggedInUser[0], loggedInUser[0].tenantID);
              result.user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
                // Send token to backend via HTTPS
                //this.sendAndVerifyToken(idToken)
                console.log(idToken)
                //let user = new User()
  
  
              }).catch(function (error) {
                console.log(error.name + ' ' + error.message);
              });
              this.userLoggedInOut.next(true);
              this.router.navigate(['main']);
            });
  
  
          }).catch((error) => {
          })
      },
        (error: HttpErrorResponse) => {
          
        });

   
  }

  createNewUserInTenant(user: User) {
    return this.httpClient.post(environment.gateway + '/createUserInTenant', user)
  }
  // Sign up with email/password
  SignUp(email, password) {
    var user = new User()
    user.age = 37
    user.displayName = "Yosef"
    user.email = "yosef@caretex.co"
    user.lastName = "yosef"
    user.password = "123456"
    user.role = "doctor"
    user.tenantID = "TENANT1-332fb"

    this.createNewUserInTenant(user)

  }

  CreateSuperAdminHack() {
    var user = new User()
    user.age = 37
    user.displayName = "יוסף עבד"
    user.email = "adminp@caretex.co"
    user.lastName = "Admin"
    user.password = "123456"
    user.role = "doctor"
    //user.tenantID = "TENANT1-332fb"


    return this.httpClient.post(environment.gateway + '/createRoot/', user).subscribe(data => {
      console.log("Created user in tenant");
    });
  }

  SendVerificationMail() {
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error)
      })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  get LoggedInUser(): User {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      return user
    } catch (Error) {

    }

    return null
  }

  get LoggedInTenantID(): string {
    try {
      const tenantID = JSON.parse(localStorage.getItem('tenantID'));
      return tenantID
    } catch (Error) {
      return null;
    }
  }

  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        })
        //this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user, tenantID) {


    // set LoggedInUser to be accessed in the future
    // Keep  login in localStorage

    this.userData = user;
    localStorage.setItem('user', JSON.stringify(this.userData));
    localStorage.setItem('tenantID', JSON.stringify(tenantID));
    localStorage.setItem('forcelogout', JSON.stringify(false));
    localStorage.setItem('LoggedInAt',JSON.stringify(new Date()));
    JSON.parse(localStorage.getItem('user'));
    //AuthService.LoggedInUser.uid = user.uid
    //AuthService.LoggedInUser.tenantID = user.tenantId
  }

  SignOut() {
   
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
      this.userLoggedInOut.next(false);
    })
  }

  ReturnToSignInPage() {
    this.router.navigate(['sign-in']);
    this.userLoggedInOut.next(false);
  }

  isOperationAllowed(op: CaretexOperation, caretexModule: string) {
    const result = this.LoggedInUser.UserPermessions.some(elem => {
      return (caretexModule == elem.CaretexComponent.ComponentId &&
        (op == CaretexOperation.Create && elem.Create ||
          op == CaretexOperation.Update && elem.Update ||
          op == CaretexOperation.Delete && elem.Delete ||
          op == CaretexOperation.NavigateTo && elem.NavigateTo ||
          op == CaretexOperation.GenerateReport && elem.GenerateReport)
      )
    })

    if (!result) this.toastrService.Error("", "אין לך הרשאה לבצע פעולה זאת.")
    return result
  }


  DeleteUserDepPermession(userId: String , departmentid:string) {

    return this.httpClient.delete(environment.gateway + '/users/' + userId  +'/departments/'+ departmentid)
  }


  public uploadImage(userid, image){
    const formData: FormData = new FormData();
    formData.append('Image', image, image["name"]);
    return this.httpClient.put(environment.gateway + '/users/' + userid + '/uploadimage', formData)
  }



  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }
}


