import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { OccupationalTherapyFollowUp } from '../../../../../src/app/models/occupationalTherapy';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { OccupationalFollowupSteps, OptionObject } from '../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { OccupationalDataProviderService } from '../../ModuleServices/occupational.data-provider.service';
import { PagenationObject } from '../../../../../src/app/models/pagenation';
import { CaretxSharedModulesProviderService } from '../../../../../src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { Attachment } from 'src/app/models/attachments';
import { DocViewerComponent } from 'src/app/caretx-shared-module/CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { AttachmentDialogComponent } from 'src/app/caretx-shared-module/CaretexModals/attachment-dialog/attachment-dialog.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-occupational-therapy-follow-ups',
  templateUrl: './occupational-therapy-follow-ups.component.html',
  styleUrls: ['./occupational-therapy-follow-ups.component.scss']
})
export class OccupationalTherapyFollowUpsComponent extends CaretexComponent implements OnInit {

  moduleName: string = "מעקב ריפוי בעיסוק"
  personalId: string
  followups: OccupationalTherapyFollowUp[] = []
  CurrentStep: OccupationalFollowupSteps = OccupationalFollowupSteps.Current
  currentFollowUp: OccupationalTherapyFollowUp 
  isFollowUpClosed: boolean
  pagenationObject: PagenationObject
  step: number = 0
  pdfContent: any
  LoggedInUser:string
  FileCategoryList: OptionObject[] = [{ id: 'Occupobsrvfile', name: 'מסמכי מעקב ריפוי בעיסוק' },]

  constructor(private router: Router,
    public medicalFileService: MedicalFileProviderService,
    private _title: Title,
    private authService: AuthService,
    public dialog: MatDialog,
    private toastrService: ToasterService,
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private caretxOccupationalProviderService: OccupationalDataProviderService,
    private route: ActivatedRoute,) { super(medicalFileService)   
      this.LoggedInUser = this.authService.LoggedInUser.UID }

  ngOnInit(): void {
    this._title.setTitle('Caretx | מעקב מטופל ריפוי בעיסוק');
    this.personalId = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.personalId)
    if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
      this.step = Number(this.route.snapshot.params['stepid']); 
    }
    this.getTotalNumOfFollowUpsFromDB()
    this.getFollowUpById(this.personalId, "", "new")
    this.getFollowUpListFromDB("1")
  }

  getFollowUpListFromDB(page: string) {
    this.caretxOccupationalProviderService.getOccupationalFollowUps(this.personalId, "", "list", page).subscribe((data: OccupationalTherapyFollowUp[]) => {
      if (data.length > 0) {
        this.followups = data
      }
      else {
        this.followups = []
      }
    });
  }

  getTotalNumOfFollowUpsFromDB() {
    this.caretxOccupationalProviderService.getTotalOfOccupationalFollowups(this.personalId).subscribe((data: number) => {
      this.pagenationObject = new PagenationObject(data)
    });
  }

  getFollowUpById(id: string, followupid: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + followupid + '\'' + ')'

    this.caretxOccupationalProviderService.getOccupationalFollowUps(id, filter, mode, "1").subscribe((data: OccupationalTherapyFollowUp[]) => {
      if (data.length == 1) {
        this.currentFollowUp = new OccupationalTherapyFollowUp(data[0])
        this.isFollowUpClosed = (this.currentFollowUp.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    editor.isReadOnly = this.isFollowUpClosed
  }
 
  SaveRecord() {
    this.currentFollowUp.LastUpdateById = this.authService.LoggedInUser.UID
    this.DoSave()
  }

  DoSave() {
    this.caretxOccupationalProviderService.createNewNurseFollowUp(this.currentFollowUp).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentFollowUp = data as OccupationalTherapyFollowUp
        if (this.currentFollowUp.Status == 'C')
          this.isFollowUpClosed = true

        this.resetModuleAfterSave()
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';

        this.router.navigateByUrl("caretexfile/" + this.personalId + "/occupationaltherapyfollowup/step/" + this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  setStep(index: number) {
    this.step = index;
  }

  CheckIfDocumentIsDirty() {
    return false
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  resetModuleAfterSave() {
  }

  CopyLastReportedFollowUp() {
  }

  ClearTextField() {
  }

  AttachFile() {
    const dialogRef = this.dialog.open(AttachmentDialogComponent, {
      data: {
        UserId: this.LoggedInUser,
        FileCategoryList: this.FileCategoryList,
        Id: this.personalId
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Save Diagnoses
      if (result == undefined || result == null || result == "") return
      this.updateRefreshedObservFiles()
    });
  }

  HandlePagenation($event) {
    this.getFollowUpListFromDB($event)
  }

  updateRefreshedObservFiles() {
    let filter :string = "file_category = 'Occupobsrvfile'"
    this.caretxSharedlService.getAttachments(this.personalId , filter).subscribe((data: Attachment[]) => {
      if (data.length > 0) {
        this.currentFollowUp.OccupationalbservationFiles = data
      }
      else {
       // this.followups = []
      }
    });
  }

  downloadFile(file: Attachment) {
    const dialogRef = this.dialog.open(DocViewerComponent, {
      data: {
        Type:'GF', // General File
        Id:this.personalId,
        Attachment: file
      }
    });
  }



}
