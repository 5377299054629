import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MeasuresTypes } from '../../../../../src/app/models/generalhousedsetups';
import { MeasureCodeListValues, PersonalMeasureCode } from '../../../../../src/app/models/measure';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';

@Component({
  selector: 'app-essentialmeasures',
  templateUrl: './essentialmeasures.component.html',
  styleUrls: ['./essentialmeasures.component.css']
})
export class EssentialmeasuresComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  measures: PersonalMeasureCode[] = []
  measuresTypes: MeasuresTypes[]
  selectedMeasure: PersonalMeasureCode
  clonedMeasures: PersonalMeasureCode[] = []

  currentRowIndex: number
  title: string
  showDetails: boolean = false




  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {  super(medicalFileServive)}

  ngOnInit(): void {
    this.getMeasuresFromDB()
    //this.getMeasuresTypeFromDB()
  }


  AddNewRecord(){
    this.router.navigateByUrl("/essentialmeasures_settings/new" )
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.measures.some(row => {
      return row.isNew || row.editMode
    })
    return hospitalsLisChanged
  }

  getMeasuresFromDB() {
    this.caretxSetupsProviderService.GetEssentialMeasures("").subscribe(
      data => {
        this.measures = data
        this.measures.forEach(val => this.clonedMeasures.push(Object.assign({}, val)));
      },
      err => {
        console.log("Error", err)
        this.measures = []

      }
    )
  }





  BackToList() {

      this.router.navigate(['system_setups']);
  }

  cancelEditRow(i) {
    this.measures[i].editMode = false
    this.measures[i] = this.clonedMeasures[i]
    this.showDetails = true
  }

  editRow(i) {
    this.currentRowIndex = i

    this.router.navigateByUrl("/essentialmeasures_settings/" + this.measures[i].ID)

    
   /* this.showDetails = true
    let Id = this.measures[i].ID
    if (this.measures[i].IsGlobal) {
      this.measures[i].IsGlobal = false
      this.measures[i].ClonedFrom = Id
      this.measures[i].ID = null
    }
    this.measures[i].TenantID = this.authServive.LoggedInTenantID
    this.selectedMeasure = this.measures[i]*/
  }

  deleteRow(i) {
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.measures[i].isNew) {
          this.caretxSetupsProviderService.DeleteEssentialMeasure(this.measures[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.measures.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
              this.getMeasuresFromDB()
            },
            error: error => {
              this.toastrService.Error("", error.error)
            }
          })
        }
        else
          this.measures.splice(this.currentRowIndex, 1);
      }
    })
  }
}
