import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ModelMapper } from 'src/app/models/modelmapper';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { SocialAdmission } from '../models/socialadmission';
import { FamilyConversation, FamilyVisit } from '../models/socialfamilyvisits';
import { SocialHelpReport } from '../models/SocialHelpReport';
import { SocialTrack } from '../models/socialobservation';
import { SocialPeriodReport } from '../models/socialPeriodReport';
import { PocketTracking } from '../models/socialpockettrack';
import { PaitentFamily, SocialRegistrationDoc } from '../models/socialregistration';
import { SocialRelease } from '../models/socialrelease';


@Injectable()
export class CaretxSocialProviderService {

  constructor(private httpClient: HttpClient, private authServive: AuthService,) {
  }

  getSocialRegistration(personelId: string, filter: string): Observable<SocialRegistrationDoc[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/socialregistration/' + personelId + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(SocialRegistrationDoc).map(item)
        })
      }), catchError(this.errorHandler));
  }
  createSocialRegistration(socialRegDoc: SocialRegistrationDoc) {
    return this.httpClient.post(environment.gateway + '/socialregistration/' + socialRegDoc.PersonelID, socialRegDoc)
  }
  UpdateSocialRegistration(registration: SocialRegistrationDoc) {
    return this.httpClient.put(environment.gateway + '/socialregistration/' + registration.PersonelID, registration)
  }
  deleteFamilyMember(personelId: string, member: PaitentFamily) {
    return this.httpClient.delete(environment.gateway + '/socialregistration/' + personelId + '/familymember/' + member.ID)
  }

  createSocialAdmission(socialAdmission: SocialAdmission) {
    return this.httpClient.post(environment.gateway + '/socialadmission/' + socialAdmission.PersonelID, socialAdmission)
  }
  getSocialAdmission(personelId: string, filter: string,mode): Observable<SocialAdmission[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

      const headerDict = {
        'Mode': mode,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };

    return this.httpClient.get(environment.gateway + '/socialadmission/' + personelId + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(SocialAdmission).map(item)
        })
      }), catchError(this.errorHandler));
  }
  deleteSocialAdmission(filter: string, personelId: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/socialadmission/' + personelId + filterQuery)
  }
  UpdateSocialAdmission(admission: SocialAdmission) {
    return this.httpClient.put(environment.gateway + '/socialadmission/' + admission.PersonelID, admission)
  }


  getSocialAnnual(personelId: string, filter: string): Observable<SocialPeriodReport[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/socialannual/' + personelId + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(SocialPeriodReport).map(item)
        })
      }), catchError(this.errorHandler));
  }
  createSocialAnnual(socialAnnual: SocialPeriodReport) {
    return this.httpClient.post(environment.gateway + '/socialannual/' + socialAnnual.PersonelID, socialAnnual)
  }
  UpdateSocialAnnual(socialAnnual: SocialPeriodReport) {
    return this.httpClient.put(environment.gateway + '/socialannual/' + socialAnnual.PersonelID, socialAnnual)
  }
  deleteSocialAnnual(filter: string, personelId: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/socialannual/' + personelId + filterQuery)
  }



  getSocialRelease(personelId: string, filter: string): Observable<SocialRelease[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/socialrelease/' + personelId + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(SocialRelease).map(item)
        })
      }), catchError(this.errorHandler));
  }
  createSocialRelease(socialRelease: SocialRelease) {
    return this.httpClient.post(environment.gateway + '/socialrelease/' + socialRelease.PersonelID, socialRelease)
  }
  UpdateSocialRelease(socialRelease: SocialRelease) {
    return this.httpClient.put(environment.gateway + '/socialrelease/' + socialRelease.PersonelID, socialRelease)
  }
  deleteSocialRelease(filter: string, personelId: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/socialrelease/' + personelId + filterQuery)
  }


  getSocialTrack(personelId: string, filter: string): Observable<SocialTrack[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/socialtrack/' + personelId + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(SocialTrack).map(item)
        })
      }), catchError(this.errorHandler));
  }
  createSocialTrack(socialTrack: SocialTrack) {
    return this.httpClient.post(environment.gateway + '/socialtrack/' + socialTrack.PersonelID, socialTrack)
  }
  UpdateSocialTrack(socialTrack: SocialTrack) {
    return this.httpClient.put(environment.gateway + '/socialtrack/' + socialTrack.PersonelID, socialTrack)
  }
  deleteSocialTrack(filter: string, personelId: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/socialtrack/' + personelId + filterQuery)
  }


  getFamilyVisits(personelId: string, filter: string , mode:string): Observable<FamilyVisit[]> {

    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
      const headerDict = {
        'Mode': mode,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };



    return this.httpClient.get(environment.gateway + '/familyvisit/' + personelId + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(FamilyVisit).map(item)
        })
      }), catchError(this.errorHandler));
  }
  createFamilyVisit(familyVisit: FamilyVisit) {
    return this.httpClient.post(environment.gateway + '/familyvisit/' + familyVisit.PersonelID, familyVisit)
  }
  UpdateFamilyVisit(familyVisit: FamilyVisit) {
    return this.httpClient.put(environment.gateway + '/familyvisit/' + familyVisit.PersonelID, familyVisit)
  }
  deleteFamilyVisit(filter: string, personelId: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/familyvisit/' + personelId + filterQuery)
  }


  getFamilyConverstions(personelId: string, filter: string , mode:string): Observable<FamilyConversation[]> {

    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
      const headerDict = {
        'Mode': mode,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };



    return this.httpClient.get(environment.gateway + '/familyconverstion/' + personelId + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(FamilyConversation).map(item)
        })
      }), catchError(this.errorHandler));
  }
  createFamilyConverstion(familyConversation: FamilyConversation) {
    return this.httpClient.post(environment.gateway + '/familyconverstion/' + familyConversation.PersonelID, familyConversation)
  }
  UpdateFamilyConverstion(familyConversation: FamilyConversation) {
    return this.httpClient.put(environment.gateway + '/familyconverstion/' + familyConversation.PersonelID, familyConversation)
  }
  deleteFamilyConverstion(filter: string, personelId: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/familyconverstion/' + personelId + filterQuery)
  }




  getPocketTracks(personelId: string, filter: string , mode:string): Observable<PocketTracking[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
      const headerDict = {
        'Mode': mode,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
    return this.httpClient.get(environment.gateway + '/pockettrack/' + personelId + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PocketTracking).map(item)
        })
      }), catchError(this.errorHandler));
  }
  createPocketTrack(track: PocketTracking) {
    return this.httpClient.post(environment.gateway + '/pockettrack/' + track.PersonelID, track)
  }
  UpdatePocketTrack(track: PocketTracking) {
    return this.httpClient.put(environment.gateway + '/pockettrack/' + track.PersonelID, track)
  }
  deletePocketTrack(filter: string, personelId: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/pockettrack/' + personelId + filterQuery)
  }


  getSocialHelpReport(personelId: string, filter: string): Observable<SocialHelpReport[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/socialhelpreport/' + personelId + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(SocialHelpReport).map(item)
        })
      }), catchError(this.errorHandler));
  }

  createSocialHelpReport(helpReport: SocialHelpReport) {
    return this.httpClient.post(environment.gateway + '/socialhelpreport/' + helpReport.PersonelID, helpReport)
  }

  UpdateSocialHelpReport(helpReport: SocialHelpReport) {
    return this.httpClient.put(environment.gateway + '/socialhelpreport/' + helpReport.PersonelID, helpReport)
  }




  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }

}