import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from "../../services/auth.service";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
 
})

export class SignInComponent implements OnInit {
  submitted = false;
  requestLoader = false;
  errorMessage:string =""
  loginForm = this.fb.group({
    username: ['', [Validators.minLength(3), Validators.required]],
    password: ['', [Validators.minLength(3), Validators.required]],
  });

  get l() {
    return this.loginForm.controls;
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public authService: AuthService
  ) { }

  ngOnInit() { }

  onLogin(): void {
   this.requestLoader = true;
    this.submitted = true;
    const formValues = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
    };

    this.errorMessage = ""
   this.authService.SignIn(formValues.username, formValues.password).then((res)=>{
    this.errorMessage = res as string
  
   })


 


  /*  setTimeout(() => {
      this.requestLoader = false;
      this.router.navigateByUrl('/verify-sign-in');
    }, 2000); */
  }

   showPassword:Boolean = false

  managePassVisibility() {
   
    this.showPassword = !this.showPassword
  }


}
