import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CartexReport } from '../../../../../../../src/app/models/personeldocument';
import { LabRequests } from '../../../../../../../src/app/models/testlabchecks';
import { AuthService } from '../../../../../../../src/app/services/auth.service';
import { ReportingService } from '../../../../../../../src/app/services/reporting.service';
import { ToasterService } from '../../../../../../../src/app/services/toastr.service';
import { CaretxMedicalProviderService } from '../../../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import Swal from 'sweetalert2';
import { MedicalFileProviderService } from '../../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../../../../../src/app/shared/CaretexComponent';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-laboratoryrequestslist',
  templateUrl: './laboratoryrequestslist.component.html',
  styleUrls: ['./laboratoryrequestslist.component.css']
})
export class LaboratoryrequestslistComponent extends CaretexComponent implements OnInit  {

  labRequests: LabRequests[]
  id:string
  pdfContent: any

  currentRowIndex : number
  formData : any[];
  moduleName = 'הזמנות מעבדה';
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), //, [Validators.required, Validators.email]),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0

  RecordStatus = [{id:"A" , desc:"בקשה פתוחה"},{id:"S" , desc:"בקשה נמסרה לטיפול אחים/ות"},{id:"D" , desc:"בקשה בוצעה"}]

  
  constructor(
    private _title: Title,
    private router: Router,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,
    private route: ActivatedRoute,
    private reportingService: ReportingService,
    public dialog: MatDialog,
    private labService: CaretxMedicalProviderService,
    ) {super(medicalFileServive)  }


  ngOnInit(): void {
    this._title.setTitle('Caretex |  הזמנות מעבדה');
    if(this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'] 
    this.CheckIfComponentIsDisabled(this.id)
    this.getLaboratortRequests("")
    this.formData=  [
      {"formcontrolname":"status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
    ]
  }


  getLaboratortRequests(filter:string){
    this.labService.getLaboratoryRequests2(this.id,filter).subscribe(
      data => {
        this.labRequests = data
        this.totalNumOfRecords = data.length
      },
      err => {
        console.log("Error", err)
        this.labRequests = []

      }
    )
  }

  SearchWithFilter($event) {
    this.getLaboratortRequests($event)
  }
  NewLaboratoryRequest($event){
    this.router.navigateByUrl("/caretexfile/" +this.id + "/laboratory/orders/new")
  }

  OnSDrillToLaboratoryRequest($event,labRequest:LabRequests){
    this.router.navigateByUrl("/caretexfile/" +this.id + "/laboratory/orders/" + labRequest.ID)
  }



  deleteRow(i) {
    this.currentRowIndex = i
    if ((this.labRequests[i].RequestStatus == "A")) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteLabRequest(this.labRequests[this.currentRowIndex])
        }
      })

    }
  }




  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty(){
    return false;
  }


  deleteLabRequest(labrequest:LabRequests){
    this.labService.deleteLaboratoryRequestById(this.id, labrequest.ID).subscribe({
      next: data => {
        this.getLaboratortRequests("")
        this.toastrService.Sucesss("", "הרשומה נמחקה")
      },
      error: error => {
        this.toastrService.Error("", error.error.error)
      }
    })
  }


  showGeneratedPdf:boolean = false
  @ViewChild('pdfViewer') pdfViewer;




  generateReport($event,labrequest:LabRequests) {
    var careTexReport =  new CartexReport()
    careTexReport.PersonelID = this.id
    careTexReport.ReportName = "LabRequest"
    careTexReport.ReportType ="laboratory"
    careTexReport.ReportParam = labrequest.ID
    careTexReport.GeneratedBy  = this.authServive.LoggedInUser
    careTexReport.GeneratedOn =  new Date()

    this.reportingService.generateReport(this.id, careTexReport)
    .subscribe(data => {
      var file = new Blob([data], { type: 'application/pdf' });
      this.pdfContent = URL.createObjectURL(file);
      this.pdfViewer.pdfSrc = file
      this.showGeneratedPdf = true
      this.pdfViewer.refresh();

    }, error => {
      console.log(error);
      var errorAsString = "failed to download file"
      try {
        var enc = new TextDecoder("utf-8")
        var text = enc.decode(error.error)
        errorAsString = JSON.parse(text).error
      } catch (e) {

      }
    })




  }


  closePdfDocument() {
    this.showGeneratedPdf = false
  }

}
