import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DietarySupplement } from 'src/app/models/generalhousedsetups';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';

@Component({
  selector: 'app-dietary-supplement',
  templateUrl: './dietary-supplement.component.html',
  styleUrls: ['./dietary-supplement.component.css']
})
export class DietarySupplementComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  supplements:DietarySupplement[]  = []
  currentRowIndex:number

  constructor(private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authService: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) { super(medicalFileServive)}

  ngOnInit(): void {

    this.getDietarySupplementsFromDB()
  }

  getDietarySupplementsFromDB(){
    this.caretxSetupsProviderService.getDietarySupplement().subscribe(
      data => {
        this.supplements = data
      },
      err => {
        this.supplements = []

      }
    )
  }
  addNewAddition(){
    var newSupplement = new DietarySupplement()
    newSupplement.isNew = true
    newSupplement.editMode = true
    newSupplement.CreatedAt = new Date()
    newSupplement.UpdatedAt = new Date()
    newSupplement.TenantID = this.authService.LoggedInTenantID
    newSupplement.New = true
    this.supplements.unshift(newSupplement)
  }
  updateAddition($event){
    this.caretxSetupsProviderService.CreateUpdateDietarySupplement(this.supplements).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getDietarySupplementsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    }) 
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }

  editRow(i){
    this.supplements[i].editMode = true
    this.supplements[i].Modified = true
  }

  deleteRow(i){
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.supplements[i].isNew){
          this.caretxSetupsProviderService.deleteDietarySupplement( this.supplements[this.currentRowIndex]).subscribe({
            next: data => {
              this.supplements.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
              this.getDietarySupplementsFromDB()
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.supplements.splice(this.currentRowIndex, 1);
      }
    })
  }

  cancelEditRow(i){
    this.supplements[i].editMode  = false;
    this.supplements[i].Modified = false
  }
  CheckIfDocumentIsDirty() {
    const statusesListChanged = this.supplements.some(row => {
      return row.New || row.Modified
    })
    return  statusesListChanged
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

}
