import { Component, OnInit } from '@angular/core';
import { sideNaveGroups } from '../../../services/enums';

@Component({
  selector: 'app-dieticiananchor',
  templateUrl: './dieticiananchor.component.html',
  styleUrls: ['./dieticiananchor.component.css']
})
export class DieticiananchorComponent implements OnInit {
  TabId:string = "medicalfiles"
  constructor() { }
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Physiotherapy;

  ngOnInit(): void {
  }


}
