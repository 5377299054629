import { Component, OnInit } from '@angular/core';
import { PainSymptoms } from 'src/app/models/generalhousedsetups';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pain-symptoms-setup',
  templateUrl: './pain-symptoms-setup.component.html',
  styleUrls: ['./pain-symptoms-setup.component.css']
})
export class PainSymptomsSetupComponent extends CaretexComponent implements OnInit {

  TabId: string = "main"

  painSymptoms: PainSymptoms[] = []

  currentRowIndex: number
  deleteSysMsgId: string
  deleteAlert: string
  alertTitle: string
  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private router: Router,
    private authService: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {
    super(medicalFileServive)
  }

  ngOnInit(): void {
    this.getPainSymptomsFromDB()
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  BackToList() {
    this.router.navigate(['system_setups']);
  }
  
  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.painSymptoms.some(row => {
      return row.New || row.editMode
    })
    return hospitalsLisChanged
  }

  getPainSymptomsFromDB() {
    this.caretxSetupsProviderService.getPainSymptoms().subscribe(
      data => {
        this.painSymptoms = data
      },
      err => {
        console.log("Error", err)
        this.painSymptoms = []

      }
    )
  }

  addNewRecord() {
    var newPainSymptoms = new PainSymptoms()
    newPainSymptoms.New = true
    newPainSymptoms.editMode = true
    newPainSymptoms.CreatedAt = new Date()
    newPainSymptoms.UpdatedAt = new Date()
    newPainSymptoms.RealmID = this.authService.LoggedInTenantID
    this.painSymptoms.unshift(newPainSymptoms)
  }

  deleteRow(i) {
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.painSymptoms[i].New) {
          this.caretxSetupsProviderService.deletePainSymptoms(this.painSymptoms[this.currentRowIndex]).subscribe({
            next: data => {
              this.painSymptoms.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
          this.painSymptoms.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i) {
    this.painSymptoms[i].editMode = true
    this.painSymptoms[i].Modified = true
  }

  cancelEditRow(i) {
    this.painSymptoms[i].editMode = false;
    this.painSymptoms[i].Modified = false
  }

  updateRecord($event) {
    this.caretxSetupsProviderService.CreateUpdatePainSymptoms(this.painSymptoms).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getPainSymptomsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }
}