import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { DieticianAssessment } from '../../../../../src/app/models/dieticianassessment';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { DieticianDataProviderService } from '../../Dietician-services/dietician.data-provider.service';
import Swal from 'sweetalert2';
import {FormControl, FormGroup } from '@angular/forms';

@Component({ 
  selector: 'app-dieticianassessments-list',
  templateUrl: './dieticianassessments-list.component.html',
  styleUrls: ['./dieticianassessments-list.component.css']
})
export class DieticianassessmentsListComponent extends CaretexComponent  implements OnInit {
  moduleName:string = "רשימת הערכות תזונתיות של מטופל"
  records:DieticianAssessment[] = []
  currentRowIndex:number
  id:string
  formData : any[] 
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), //, [Validators.required, Validators.email]),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0
  RecordStatus = [{id:"A" , desc:"רשומה פעילה"},{id:"C" , desc:"רשומה נעולה"}]


  constructor(
    private _title: Title,
    private router: Router,
    private _ar: ActivatedRoute,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private caretxDieticianProviderService: DieticianDataProviderService,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private route: ActivatedRoute,) {
    super(medicalFileService);

  }

  ngOnInit(): void {
    this._title.setTitle('Caretx | הערכות תזונתיות');
    if(this.route.parent !=null)
    this.id = this.route.parent.snapshot.params['id'] 
    this.CheckIfComponentIsDisabled(this.id)
    this.getAssessmentsFromDB("")
    this.formData=  [
      {"formcontrolname":"status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
      {"formcontrolname":"created_at_from","label":"מתאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"created_at_to","label":"עד תאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_from","label":"תאריך נעילה מ" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_to","label":"תאריך נעילה עד" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},

    ]
  }


  getAssessmentsFromDB(filter:string) {

    this.caretxDieticianProviderService.getDieticianAssessment(this.id , filter, "list","1").subscribe(
      data => {this.records = data
        this.totalNumOfRecords = data.length
      },
      err => {
        this.records = []
      }
    )
  }

  SearchWithFilter($event) {
    this.getAssessmentsFromDB($event)
  }

  AddNewRecord($event){
    this.router.navigateByUrl("caretexfile/" + this.id + "/dieticianassessment/new")
  }

  deleteRow(i){
    this.currentRowIndex = i
    if (!(this.records[i].Status == "C")) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRecord(this.records[this.currentRowIndex])
        }
      })

    }
  }


  deleteRecord(assessment: DieticianAssessment) {
    let filter = '(id=' + '\'' + assessment.ID + '\'' + ')'
    this.caretxDieticianProviderService.deleteDieticianAssessment(this.id, filter).subscribe({
      next: data => {
        this.getAssessmentsFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }


  generateReport($event,record){
    
  }
  
  OnSDrillToHazardDetail($event,record){
    this.router.navigateByUrl("caretexfile/" + this.id + "/dieticianassessment/"+record.ID)
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }
}
