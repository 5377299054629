import { Component, Inject, OnInit } from '@angular/core';

import { NurseFollowUp } from '../../../../../src/app/models/nurseadmission';
import {
  MAT_DIALOG_DATA,

  MatDialogRef,

} from '@angular/material/dialog';

@Component({
  selector: 'app-nurse-dialog-followupsubject',
  templateUrl: './nurse-dialog-followupsubject.component.html',
  styleUrls: ['./nurse-dialog-followupsubject.component.css'],


})
export class NurseDialogFollowupsubjectComponent implements OnInit {

  id:string
  isNew:boolean
  isDisabled:boolean
  nurseFollowUp:NurseFollowUp
  LocalCreatedAt : Date
  
  constructor(
    private dialogRef: MatDialogRef<NurseDialogFollowupsubjectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      dialogRef.disableClose = true; 
     }

  ngOnInit(): void {

    this.id = this.data.personelid;
    this.nurseFollowUp = this.data.dieticianFollowUp
    this.isDisabled = this.data.IsDisabled
    this.nurseFollowUp.CreatedAt  = new Date()
    this.LocalCreatedAt = new Date()
    this.nurseFollowUp.FollowUpSubject = ""
  }

  updateTimeField($event) {
    var newarr = $event.split(":");

    let x1: number
    let x2: number

    x1 = new Date(this.nurseFollowUp.CreatedAt).setHours(newarr[0])
    this.nurseFollowUp.CreatedAt = new Date(x1)
    x2 = new Date(this.nurseFollowUp.CreatedAt).setMinutes(newarr[1])
    this.nurseFollowUp.CreatedAt = new Date(x2)

  }

  submit(){

    this.dialogRef.close(this.nurseFollowUp);
  }
}
