import { Injectable } from '@angular/core';

@Injectable()
export class CommeonHelperService {


    
    diff_hours(dt2, dt1) 
    {
     var diff =(dt2.getTime() - (new Date(dt1)).getTime()) / 1000;
     diff /= (60 * 60);
     return Math.abs(Math.round(diff));
     
    }


}