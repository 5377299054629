import { User } from 'firebase'
import { MedicalFile } from './customer'
import { FamilyRelations, SocialProvider } from './generalhousedsetups'
import { propertyMap } from './modelmapper'




// Need Server to send Payload in below structure
export class SocialPeriodReport {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID') //
    public PersonelID: string
    @propertyMap('ProblemDetials') //
    public ProblemDetials: string
    @propertyMap('ImportantFamilyInfo') //
    public ImportantFamilyInfo: string
    @propertyMap('ExternalRel') //
    public ExternalRel: boolean
    @propertyMap('ExternalRelTxt') //
    public ExternalRelTxt: string
    @propertyMap('IsGurdian') //
    public IsGurdian: boolean
    @propertyMap('ResidentsMeeting') //
    public ResidentsMeeting: string
    @propertyMap('Groups') //
    public Groups: string
    @propertyMap('Travels') //
    public Travels: string
    @propertyMap('Classes') //
    public Classes: string
    @propertyMap('HelpOthers') //
    public HelpOthers: string
    @propertyMap('Other') //
    public Other: string
    @propertyMap('SocialRelationWithOthers') //
    public SocialRelationWithOthers: string
    @propertyMap('RelWithCrew') //
    public RelWithCrew: string
    @propertyMap('GeneralFeelInHouse') //
    public GeneralFeelInHouse: string
    @propertyMap('Privacy') //
    public Privacy: string
    @propertyMap('Food') //
    public Food: string
    @propertyMap('Residents') //
    public Residents: string
    @propertyMap('Influance') //
    public Influance: string
    @propertyMap('Other2') //
    public Other2: string
    @propertyMap('PlanAndTargets') //
    public PlanAndTargets: string
    @propertyMap('GroupIntervention') //
    public GroupIntervention: string
    @propertyMap('ContactWihFactors') //
    public ContactWihFactors: string
    @propertyMap('Achievments') //
    public Achievments: string
    @propertyMap('NewTargets') //
    public NewTargets: string
    @propertyMap('Summary') //
    public Summary: string
    @propertyMap('PeriodicRecords') //
    public PeriodicRecords: string

    @propertyMap('Cb1') //
    public Cb1: boolean
    @propertyMap('Cb2') //
    public Cb2: boolean
    @propertyMap('Cb3') //
    public Cb3: boolean
    @propertyMap('Cb4') //
    public Cb4: boolean
    @propertyMap('Cb5') //
    public Cb5: boolean
    @propertyMap('Cb6') //
    public Cb6: boolean
    @propertyMap('Cb7') //
    public Cb7: boolean
    @propertyMap('Cb8') //
    public Cb8: boolean
    @propertyMap('Cb9') //
    public Cb9: boolean
    @propertyMap('Cb10') //
    public Cb10: boolean
    @propertyMap('Cb11') //
    public Cb11: boolean
    @propertyMap('Cb12') //
    public Cb12: boolean
    @propertyMap('Cb13') //
    public Cb13: boolean
    @propertyMap('Cb14') //
    public Cb14: boolean
    @propertyMap('Cb15') //
    public Cb15: boolean
    @propertyMap('Cb16') //
    public Cb16: boolean
    @propertyMap('Cb17') //
    public Cb17: boolean
    @propertyMap('Cb18') //
    public Cb18: boolean

    @propertyMap('CbF1') //
    public CbF1: boolean
    @propertyMap('CbF2') //
    public CbF2: boolean
    @propertyMap('CbF3') //
    public CbF3: boolean
    @propertyMap('CbF4') //
    public CbF4: boolean
    @propertyMap('CbF5') //
    public CbF5: boolean
    @propertyMap('CbF6') //
    public CbF6: boolean
    @propertyMap('CbF7') //
    public CbF7: boolean
    @propertyMap('GoalsAchieved') //
    public GoalsAchieved: boolean
    @propertyMap('IsNewTargets') //
    public IsNewTargets: boolean
    
    
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User
    @propertyMap('Status')
    Status: string
    @propertyMap('Closed_at')
    public Closed_at: Date

    
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.ImportantFamilyInfo = data["ImportantFamilyInfo"]
            this.ProblemDetials = data["ProblemDetials"]
            this.ExternalRel = data["ExternalRel"]
            this.ExternalRelTxt = data["ExternalRelTxt"]
            this.IsGurdian = data["IsGurdian"]
            this.ResidentsMeeting = data["ResidentsMeeting"]
            this.Groups = data["Groups"]
            this.Travels = data["Travels"]
            this.Classes = data["Classes"]
            this.HelpOthers = data["HelpOthers"]
            this.Other = data["Other"]
            this.SocialRelationWithOthers = data["SocialRelationWithOthers"]
            this.RelWithCrew = data["RelWithCrew"]
            this.GeneralFeelInHouse = data["GeneralFeelInHouse"]
            this.Privacy = data["Privacy"]
            this.Food = data["Food"]
            this.Residents = data["Residents"]
            this.Influance = data["Influance"]
            this.Other2 = data["Other2"]
            this.PlanAndTargets = data["PlanAndTargets"]
            this.GroupIntervention = data["GroupIntervention"]
            this.ContactWihFactors = data["ContactWihFactors"]
            this.Achievments = data["Achievments"]
            this.NewTargets = data["NewTargets"]
            this.Summary = data["Summary"]
            this.PeriodicRecords = data["PeriodicRecords"]
            this.Cb1 = data["Cb1"]
            this.Cb2 = data["Cb2"]
            this.Cb3 = data["Cb3"]
            this.Cb4 = data["Cb4"]
            this.Cb5 = data["Cb5"]
            this.Cb6 = data["Cb6"]
            this.Cb7 = data["Cb7"]
            this.Cb8 = data["Cb8"]
            this.Cb9 = data["Cb9"]
            this.Cb10 = data["Cb10"]
            this.Cb11 = data["Cb11"]
            this.Cb12 = data["Cb12"]
            this.Cb13 = data["Cb13"]
            this.Cb14 = data["Cb14"]
            this.Cb15 = data["Cb15"]
            this.Cb16 = data["Cb16"]
            this.Cb17 = data["Cb17"]
            this.Cb18 = data["Cb18"]

            this.CbF1 = data["CbF1"]
            this.CbF2 = data["CbF2"]
            this.CbF3 = data["CbF3"]
            this.CbF4 = data["CbF4"]
            this.CbF5 = data["CbF5"]
            this.CbF6 = data["CbF6"]
            this.CbF7 = data["CbF7"]
            
            this.GoalsAchieved = data["GoalsAchieved"]
            this.IsNewTargets = data["IsNewTargets"]





            this.ClosedBy = data["ClosedBy"]
            this.ClosedById = data["ClosedById"]
            this.CreatedBy = data["CreatedBy"]
            this.CreatedById = data["CreatedById"]
            this.Status = data["Status"]
            this.Closed_at = data["Closed_at"]
        }
        else {
            this.CreatedAt = this.UpdatedAt = new Date()

            this.Status = "A"
        }
    }
}



