import { MedicalFile } from "./customer"
import { Drug } from "./drug"
import { PainLevel, PainLocation, PainMitigatingFactor, PainNature, PainSymptoms, Paintreatment } from "./generalhousedsetups"
import { propertyMap } from "./modelmapper"
import { User } from "./user"



export class OmdanPainNature {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('OmdanPainId')
    public OmdanPainId: string
    @propertyMap('PainNatureId')
    public PainNatureId: string
    @propertyMap('PainMitigatingFactor')
    public PainNature: PainNature
    constructor(data?: Object) {
        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAtD"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.PainNatureId = data["PainNatureId"]
            this.PainNature = data["PainNature"]
            this.OmdanPainId = data["OmdanPainId"]
        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
        }
    }
}



export class OmdanPainTreatment {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('OmdanPainId')
    public OmdanPainId: string
    @propertyMap('PaintreatmentId')
    public PaintreatmentId: string
    @propertyMap('PainMitigatingFactor')
    public Paintreatment: Paintreatment 
    constructor(data?: Object) {
        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAtD"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.PaintreatmentId = data["PaintreatmentId"]
            this.Paintreatment = data["Paintreatment"]
            this.OmdanPainId = data["OmdanPainId"]
        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
        }
    }
}

export class OmdanPainDrugs {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('OmdanPainId')
    public OmdanPainId: string
    @propertyMap('PaintDrugId')
    public PaintDrugId: string
    @propertyMap('Drug')
    public Drug: Drug 
    constructor(data?: Object) {
        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAtD"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.PaintDrugId = data["PaintDrugId"]
            this.Drug = data["Drug"]
            this.OmdanPainId = data["OmdanPainId"]
        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
        }
    }
}


export class OmdanPainMitigationFactors {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('OmdanPainId')
    public OmdanPainId: string
    @propertyMap('PainMitigatingFactorId')
    public PainMitigatingFactorId: string
    @propertyMap('PainMitigatingFactor')
    public PainMitigatingFactor: PainMitigatingFactor
    constructor(data?: Object) {
        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAtD"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.PainMitigatingFactorId = data["PainMitigatingFactorId"]
            this.PainMitigatingFactor = data["PainMitigatingFactor"]
            this.OmdanPainId = data["OmdanPainId"]
        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
        }
    }
}

export class OmdanPainSmptoms {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('OmdanPainId')
    public OmdanPainId: string
    @propertyMap('PainSymptomId')
    public PainSymptomId: string
    @propertyMap('PainSymptom')
    public PainSymptom: PainSymptoms
    
    constructor(data?: Object) {
        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAtD"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.PainSymptomId = data["PainSymptomId"]
            this.PainSymptom = data["PainSymptom"]
            this.OmdanPainId = data["OmdanPainId"]
        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
        }
    }
}

export class OmdanPainMedication {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('DrugId')
    public DrugId: string
    @propertyMap('Drug')
    public Drug: Drug
    @propertyMap('OmdanPainId')
    public OmdanPainId: string
    constructor(data?: Object) {
        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAtD"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.DrugId = data["DrugId"]
            this.Drug = data["Drug"]
            this.OmdanPainId = data["OmdanPainId"]
        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
        }
    }
}

export class OmdanPainLocations {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('OmdanPainId')
    public OmdanPainId: string
    @propertyMap('PainLocationId')
    public PainLocationId: string
    @propertyMap('PainLocation')
    public PainLocation: PainLocation
    constructor(data?: Object) {
        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAtD"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.PainLocationId = data["PainLocationId"]
            this.PainLocation = data["PainLocation"]
            this.OmdanPainId = data["OmdanPainId"]
        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
        }
    }
}

export class OmdanPainLevels {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('OmdanPainId')
    public OmdanPainId: string
    @propertyMap('PainLevelId')
    public PainLevelId: string
    @propertyMap('PainLevel')
    public PainLevel: PainLevel
    constructor(data?: Object) {
        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAtD"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.PainLevelId = data["PainLevelId"]
            this.PainLevel = data["PainLevel"]
            this.OmdanPainId = data["OmdanPainId"]
        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
        }
    }
}


export class VasFpsPainExam { 
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('OmdanDate')
    public OmdanDate: Date

    @propertyMap('PainLevels')
    public PainLevels: OmdanPainLevels[]
    @propertyMap('PainLocations')
    public PainLocations: OmdanPainLocations[]
    @propertyMap('MitigationsFactors')
    public MitigationsFactors: OmdanPainMitigationFactors[]
    @propertyMap('PainSymptoms')
    public PainSymptoms: OmdanPainSmptoms[]
    @propertyMap('OmdanPainMedications')
    public OmdanPainMedications: OmdanPainMedication[]
    @propertyMap('PainNatures')
    public PainNatures: OmdanPainNature[]
    @propertyMap('Paintreatments')
    public Paintreatments: OmdanPainTreatment[]
   // @propertyMap('PainDrugs')
   // public PainDrugs: OmdanPainDrugs[]
    @propertyMap('Notes')
    public Notes: string
    @propertyMap('Status')
    public Status: string
    @propertyMap('ClosedAt')
    public ClosedAt: Date
    @propertyMap('ClosedById')
    public ClosedById: string
    @propertyMap('ClosedBy')
    public ClosedBy: User

    @propertyMap('UpdatedById')
    public UpdatedById: string
    @propertyMap('UpdatedBy')
    public UpdatedBy: User




    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.OmdanDate = data["OmdanDate"]
            this.PainLevels = data["PainLevels"]
            this.PainLocations = data["PainLocations"]
            this.MitigationsFactors = data["MitigationsFactors"]
            this.PainSymptoms = data["PainSymptoms"]
            this.OmdanPainMedications = data["OmdanPainMedications"]
            this.PainNatures = data["PainNatures"]
            this.Paintreatments = data["Paintreatments"]
         //   this.PainDrugs = data["PainDrugs"]
            this.Notes = data["Notes"]
            this.Status = data["Status"]
            this.ClosedAt = data["ClosedAt"]
            this.ClosedById = data["ClosedById"]
            this.ClosedBy = data["ClosedBy"]

            this.UpdatedById = data["UpdatedById"]
            this.UpdatedBy = data["UpdatedBy"]


        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
            this.UpdatedBy = new User()
        }
    }
}


export class PaintReport  {
    MedicalFileForReport: MedicalFile;
    PainOmdanForReport: VasFpsPainExam
    PainSymptomsTxt: string 
    PainMitigationTxt: string 
    PainLocationTxt: string 
    PainLevelsTxt: String
    PainNatureTxt: String 
    PaintreatmentTxt: String 
    DrugsTxt: String 

  };
  


