import { Component, OnInit } from '@angular/core';
import { EventBeds } from 'src/app/models/generalhousedsetups';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-beds',
  templateUrl: './beds.component.html',
  styleUrls: ['./beds.component.css']
})
export class BedsComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  eventBeds:EventBeds[]  = []
  currentRowIndex:number

  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authService: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {   super(medicalFileServive)}

  ngOnInit(): void {
    this.getBedsTypeFromDB()
  }


  getBedsTypeFromDB() {
    this.caretxSetupsProviderService.GetBedsTypes().subscribe(
      data => {
        this.eventBeds = data
      },
      err => {
        console.log("Error", err)
        this.eventBeds = []

      }
    )
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  
  CheckIfDocumentIsDirty() {
    const eventTypesLisChanged = this.eventBeds.some(row => {
      return row.New || row.Modified
    })
    return  eventTypesLisChanged
  }


  BackToList(){
    this.router.navigate(['system_setups']);
  }

  addNewBeds(){
    var newEventBed = new EventBeds()
    newEventBed.New = true
    newEventBed.editMode = true
    newEventBed.CreatedAt = new Date()
    newEventBed.UpdatedAt = new Date()
    newEventBed.RealmID = this.authService.LoggedInTenantID
    this.eventBeds.unshift(newEventBed)
  }

  deleteRow(i){
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.eventBeds[i].New){
          this.caretxSetupsProviderService.DeleteBedType( this.eventBeds[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.eventBeds.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
              this.getBedsTypeFromDB()
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.eventBeds.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i){
    this.eventBeds[i].editMode = true
    this.eventBeds[i].Modified = true
  }
  cancelEditRow(i){
    this.eventBeds[i].editMode  = false;
    this.eventBeds[i].Modified = false
  }

  updateEvent($event){
    this.caretxSetupsProviderService.CreateUpdateBedTypes(this.eventBeds).subscribe({
     next: data => {

       this.toastrService.Sucesss("", this.toastrService.successMsg)
       this.getBedsTypeFromDB()
     },
     error: error => {
       this.toastrService.Error("", this.toastrService.errorMsg)
     }
   })  
 }


}
