import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartexPhysiotherapyModuleRoutingModule } from './cartex-physiotherapy-module-routing.module';
import { PhysiotherapyanchorComponent } from './physiotherapyanchor/physiotherapyanchor.component';
import { PhysicaldiagnosisComponent } from './physicaldiagnosis/physicaldiagnosis.component';
import { PhysicaldiagnosislistComponent } from './physicaldiagnosislist/physicaldiagnosislist.component';
import { SharedModule } from '../caretx-shared-module/shared-module.module';
import { PipesModule } from '../caretx-custompipes-module/custompipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ModalModule } from '../modal';
import { CaretxPhsioTherapyProviderService } from './Physiotherapty.data-provider.service';
import { PhysiotherapyfollowupsComponent } from './physiotherapyfollowups/physiotherapyfollowups.component';
import { HistoricfollowupsComponent } from './physiotherapyfollowups/historicfollowups/historicphsiofollowups.component';
import { Physicaldiagnoses2listComponent } from './physicaldiagnoses2list/physicaldiagnoses2list.component';
import { Physicaldiagnoses2Component } from './physicaldiagnoses2/physicaldiagnoses2.component';
import { PhysioShortenAssessmentComponent } from './physio-shorten-assessment/physio-shorten-assessment.component';
import { PhysioShortenAssessmentListComponent } from './physio-shorten-assessment/physio-shorten-assessment-list/physio-shorten-assessment-list.component';
//import { DoctorsModule } from '../caretx-doctors-module/doctors.module';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxSummernoteModule } from 'ngx-summernote';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { DoctorsModule } from '../caretx-doctors-module/doctors.module';
@NgModule({
  declarations: [
    PhysiotherapyanchorComponent,
    PhysicaldiagnosisComponent,
    PhysicaldiagnosislistComponent,
    PhysiotherapyfollowupsComponent,
    HistoricfollowupsComponent,
    Physicaldiagnoses2listComponent,
    Physicaldiagnoses2Component,
    PhysioShortenAssessmentComponent,
    PhysioShortenAssessmentListComponent,

   
  ],
  imports: [
    CommonModule,
    CartexPhysiotherapyModuleRoutingModule,
    CommonModule,
    FormsModule,
    SharedModule,
    BrowserModule,
    ReactiveFormsModule,
    PipesModule,
    ModalModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatRadioModule,
    MatStepperModule,
    NgxSummernoteModule,
    DoctorsModule
  ],
  providers: [
    CaretxPhsioTherapyProviderService
   ],
})
export class CartexPhysiotherapyModuleModule { }
