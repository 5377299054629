import { Component, OnInit } from '@angular/core';
import { Funder, MedicalFileStatus } from 'src/app/models/generalhousedsetups';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { AuthService } from 'src/app/services/auth.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-medicalfile-settings',
  templateUrl: './medicalfile-settings.component.html',
  styleUrls: ['./medicalfile-settings.component.css']
})
export class MedicalfileSettingsComponent extends CaretexComponent implements OnInit {

  TabId: string = "main"
  statuses: MedicalFileStatus[] = []
  currentRowIndex: number
  deleteSysMsgId: string
  deleteAlert: string
  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private router: Router,
    private toastrService: ToasterService,) { super(medicalFileServive) }

  ngOnInit(): void {
    this.getMedicalFileStatusesFromDB()
  }

  BackToList() {
    this.router.navigate(['system_setups']);
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.statuses.some(row => {
      return row.New || row.Modified
    })
    return hospitalsLisChanged
  }

  getMedicalFileStatusesFromDB() {
    this.caretxSetupsProviderService.GetMedicalStatuses().subscribe(
      data => {
        this.statuses = data
      },
      err => {
        this.statuses = []

      }
    )
  }

  addNewStatus() {
    var newStatus = new MedicalFileStatus()
    newStatus.New = true
    newStatus.editMode = true
    newStatus.CreatedAt = new Date()
    newStatus.UpdatedAt = new Date()
    newStatus.RealmID = this.authServive.LoggedInTenantID
    this.statuses.unshift(newStatus)
  }

  deleteRow(i) {
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.statuses[i].New) {
          this.caretxSetupsProviderService.DeleteMedicalStatus(this.statuses[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.statuses.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
          this.statuses.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i) {
    this.statuses[i].editMode = true
    this.statuses[i].Modified = true
  }

  cancelEditRow(i) {
    this.statuses[i].editMode = false;
    this.statuses[i].Modified = false;
  }

  updateStatus($event) {
    this.caretxSetupsProviderService.CreateUpdateMedicalStatus(this.statuses).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getMedicalFileStatusesFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }
}
