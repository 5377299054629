import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialTrack } from  '../../../../src/app/models/socialobservation';
import { AuthService } from '../../../../src/app/services/auth.service';
import { SocialTrackSteps } from '../../../../src/app/services/enums';
import { MedicalFileProviderService } from '../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../src/app/shared/CaretexComponent';
import { CaretxSocialProviderService } from '../Social.data-provider.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-socialobservationform',
  templateUrl: './socialobservationform.component.html',
  styleUrls: ['./socialobservationform.component.css']
})
export class SocialobservationformComponent extends CaretexComponent  implements OnInit {
  isrecordClosed: boolean = false
  currentTracking: SocialTrack 
  id :string
  trackid:string
  NextTrackDate:string
  followupType:string = "social"
  step: number = 0

  @ViewChild('mainform') mainFormElement: NgForm;
  moduleName:string = "מעקב סוציאלי"
  constructor( private _title: Title,
    private router: Router,
    private route: ActivatedRoute,
    public medicalFileService: MedicalFileProviderService,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private _ar: ActivatedRoute,
    private authService: AuthService,
    private toastrService: ToasterService,
    ) {super(medicalFileService) }

  ngOnInit(): void {
    this._title.setTitle('Caretex |טופס מעקב סוציאלי');
    this.id = this.route.parent.snapshot.params['id'];
    this.trackid = this.route.snapshot.params.trackid
    this.CheckIfComponentIsDisabled(this.id) 
  
    if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
      this.step = Number(this.route.snapshot.params['stepid']);
    }

    this. getSocialTracFromDB() 
  }




  getSocialTracFromDB() {
    let filter = ""
    if (this.trackid != 'new')
      filter = '(id=' + '\'' + this.trackid + '\'' + ')'
    this.caretxSocialProviderService.getSocialTrack(this.id, filter).subscribe((data: SocialTrack[]) => {
      if (data.length == 1) {
        this.currentTracking = new SocialTrack(data[0])
        if (this.trackid == 'new') {
          this.currentTracking.CreatedById = this.authService.LoggedInUser.UID
          this.currentTracking.CreatedAt = new Date()
          this.currentTracking.CreatedBy =  this.authService.LoggedInUser

       
        }
        if (this.currentTracking.Status == "C") {
          this.isrecordClosed = true
        }else
        this.isrecordClosed = false
      }
      else if (data.length == 0) {
        let errorMsg = "המערכת לא הצליחה לייצר רשומה חדשה"
        this.toastrService.Error("", errorMsg)
      }
      else {
        let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }


  
  setStep(index: number) {
    this.step = index;
  }






  LockDocument(){
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם את/ה רוצה לנעול הערכה ?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: "בטל",
      confirmButtonText: 'כן,תנעל',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentTracking.Status = "C"
        this.currentTracking.Closed_at = new Date()
        this.currentTracking.ClosedById = this.authService.LoggedInUser.UID
        this.Save()

      }
    })
  }
  BackToList(){
    this.router.navigateByUrl("/caretexfile/" + this.id + "/socialtracks")
  }
  Save() {
    this.currentTracking.Closed_at = new Date(this.currentTracking.Closed_at)
    this.currentTracking.NextTrackDate = new Date(this.currentTracking.NextTrackDate)
   
    if (this.trackid == 'new') {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }

  }

  DoSave() {
    this.currentTracking.UpdatedAt = new Date()
    this.currentTracking.CreatedAt = this.currentTracking.UpdatedAt
    this.currentTracking.CreatedById = this.authService.LoggedInUser.UID
    this.currentTracking.TenantID = this.authService.LoggedInTenantID
    this.caretxSocialProviderService.createSocialTrack(this.currentTracking).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentTracking = new SocialTrack(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload'; 
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialtracks/" + this.currentTracking.ID + "/step/" + this.step )

      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  DoUpdate() {
    this.currentTracking.UpdatedAt = new Date()
    this.currentTracking.TenantID = this.authService.LoggedInTenantID
    this.caretxSocialProviderService.UpdateSocialTrack(this.currentTracking).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentTracking = new SocialTrack(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialtracks/" + this.currentTracking.ID + "/step/" + this.step )
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty 
  }


}
