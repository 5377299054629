import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ModalService } from 'src/app/modal/modal.service';
import { BridgeBuiltInSentenses } from 'src/app/services/builtinsentenses';
import { DialogResult } from 'src/app/services/enums';


@Component({
  selector: 'app-builtinsentences',
  templateUrl: './builtinsentences.component.html',
  styleUrls: ['./builtinsentences.component.css']
})
export class BuiltinsentencesComponent implements OnInit {
  GeneralExamination: string[]


  @Input() title: string

  @Output() selectedBuiltInSentenseEvent = new EventEmitter<string>();



  constructor(private builtInSentenses: BridgeBuiltInSentenses, private modalService: ModalService) {
    builtInSentenses = new BridgeBuiltInSentenses()
    this.GeneralExamination = builtInSentenses.GeneralExamination
  }

  ngOnInit(): void {
  }


  submitCommentModal(dialogResult: DialogResult, sentense: string, $event) {
    if (dialogResult == DialogResult.OK) {
      this.selectedBuiltInSentenseEvent.emit(sentense)
    }
    this.modalService.close('builtinsentnses-modal');
  }

  public get dialogResults(): typeof DialogResult {
    return DialogResult;
  }


}
