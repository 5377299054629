
import { HttpClient } from '@angular/common/http'
import { propertyMap } from './modelmapper'
import { User } from './user'

export class OmdanQuestions {
  @propertyMap('ID')
  ID: string
  @propertyMap('CreatedAt')
  CreatedAt: Date
  @propertyMap('UpdatedAt')
  UpdatedAt: Date
  @propertyMap('Question')
  Question: string
  @propertyMap('OmdanType')
  OmdanType: string
  @propertyMap('Sequence')
  Sequence: string
  @propertyMap('LastUpdateById')
  public LastUpdateById: string
  public LastUpdateBy: User
  @propertyMap('MedicalObservationFiles')
  PossibleAnswers: OmdanPossibleAnswer[]
}

export class OmdanPossibleAnswer {
  @propertyMap('ID')
  ID: string
  @propertyMap('CreatedAt')
  CreatedAt: Date
  @propertyMap('UpdatedAt')
  UpdatedAt: Date
  @propertyMap('Answer')
  Answer: string
  @propertyMap('AnswerValue')
  AnswerValue: number
}

export class BaseOmdan {

  protected http: HttpClient;
  @propertyMap('ID')
  ID: string
  @propertyMap('CreatedAt')
  CreatedAt: Date
  @propertyMap('UpdatedAt')
  UpdatedAt: Date
  @propertyMap('PersonelID')
  public PersonelID: string
  @propertyMap('RealmID')
  RealmID: string
  @propertyMap('SummaryTxt')
  SummaryTxt: string
  @propertyMap('NotesTxt')
  NotesTxt: string
  @propertyMap('OmdanQuestionAnswers')
  OmdanQuestionAnswers: OmdanQuestionAnswer[]
  @propertyMap('LockedById')
  LockedById: string
  @propertyMap('LockedBy')
  LockedBy: User
  @propertyMap('LockedDate')
  LockedDate: Date
  @propertyMap('LastUpdateById')
  LastUpdateById: string
  @propertyMap('LastUpdateBy')
  LastUpdateBy: string
  @propertyMap('OmdanStatus')
  OmdanStatus: string
  @propertyMap('Score')
  Score: number
  @propertyMap('Origin')
  Origin: string
  @propertyMap('OriginId')
  OriginId: string

  OmdanChangesComittedToDB:Boolean

  constructor(data?: Object) {

    if (data != undefined && data != null) {

      this.ID = data["ID"]
      this.CreatedAt = data["CreatedAtD"]
      this.UpdatedAt = data["UpdatedAt"]
      this.PersonelID = data["PersonelID"]
      this.RealmID = data["RealmID"]
      this.SummaryTxt = data["SummaryTxt"]
      this.NotesTxt = data["NotesTxt"]
      this.OmdanQuestionAnswers = data["OmdanQuestionAnswers"]
      this.LockedById = data["LockedById"]
      this.LockedBy = data["LockedBy"]
      this.LockedDate = data["LockedDate"]
      this.LastUpdateById = data["LastUpdateById"]
      this.LastUpdateBy = data["LastUpdateBy"]
      this.OmdanStatus = data["OmdanStatus"]
      this.Score = data["Score"]
      this.Origin = data["Origin"]
      this.OriginId = data["OriginId"]
      this.OmdanChangesComittedToDB = true
      
    }
    else {
      this.ID = undefined
      let currentDate = new Date()
      this.CreatedAt = currentDate
      this.UpdatedAt = currentDate
      this.PersonelID = undefined
      this.RealmID = undefined
      this.SummaryTxt = undefined
      this.NotesTxt = undefined
      this.OmdanQuestionAnswers = []
      this.LockedById = undefined
      this.LockedBy = undefined
      this.LockedDate = undefined
      this.LastUpdateById = undefined
      this.LastUpdateBy = undefined
      this.OmdanStatus = 'A'
      this.Score = undefined
    }
  }

  public CheckIfAllQuestionsAnswered() {
    const res = this.OmdanQuestionAnswers.some(answer =>
      (answer.AnswerId == undefined || answer.AnswerId == null || answer.AnswerId == '00000000-0000-0000-0000-000000000000'))
    return res;
  }


  generateSetOfQuestionsAnswers(questions: OmdanQuestions[]) {
    let index: number = 0
    this.OmdanQuestionAnswers = []
    questions.forEach(element => {
      var obj = new OmdanQuestionAnswer()
      obj.Question = element
      obj.QuestionId = element.ID
      obj.CreatedAt = new Date()
      obj.UpdatedAt = new Date()
      obj.Answer = new OmdanPossibleAnswer()
      this.OmdanQuestionAnswers.push(obj);
      ++index;
    });
  }
}

export class OmdanQuestionAnswer {
  @propertyMap('ID')
  ID: string
  @propertyMap('CreatedAt')
  CreatedAt: Date
  @propertyMap('UpdatedAt')
  UpdatedAt: Date
  @propertyMap('QuestionId')
  QuestionId: string
  @propertyMap('Question')
  Question: OmdanQuestions
  @propertyMap('AnswerId')
  AnswerId: string
  @propertyMap('Answer')
  Answer: OmdanPossibleAnswer
}
