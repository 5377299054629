import { propertyMap } from './modelmapper'
import { User } from './user'

export class DiabeticFootTestHeader{
    @propertyMap('ID')
    public ID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('NurseId')
    NurseId: string
    @propertyMap('Nurse')
    Nurse: User
    @propertyMap('Status')
    Status: string
    @propertyMap('Score')
    Score: number
    @propertyMap('ClosedAt')
    ClosedAt: Date
    @propertyMap('ClosedById')
    ClosedById: string
    @propertyMap('ClosedBy')
    ClosedBy: User
    @propertyMap('LastUpdatedBy')
    LastUpdatedBy: User
    @propertyMap('LastUpdatedById')
    LastUpdatedById: string
    @propertyMap('DiabeticFootTests')
    DiabeticFootTests: DiabeticFootTest[] // Max 2 and if 2 then 0 is Left 1 is right 
    @propertyMap('Amputee')
    public Amputee: string
    @propertyMap('HasUlcerHistory')
    public HasUlcerHistory: string
    @propertyMap('Origin')
    public Origin: string
    @propertyMap('OriginId')
    public OriginId: string

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.NurseId = data["NurseId"]
            this.Nurse = data["Nurse"]
            this.Status = data["Status"]
            this.ClosedAt = data["ClosedAt"]
            this.ClosedById = data["ClosedById"]
            this.ClosedBy = data["ClosedBy"]
            this.LastUpdatedBy = data["LastUpdatedBy"]
            this.LastUpdatedById = data["LastUpdatedById"]
            this.DiabeticFootTests = data["DiabeticFootTests"]
            this.Amputee = data["Amputee"]
            this.HasUlcerHistory = data["HasUlcerHistory"]
            this.Score = data["Score"]
            this.Origin = data["Origin"]
            this.OriginId = data["OriginId"]
        }else {
            this.Status = "A"
            this.DiabeticFootTests = []
            this.CreatedAt  = new Date()
            this.UpdatedAt = this.CreatedAt
        }
    }
}

export class DiabeticFootTest {
    @propertyMap('ID')
    public ID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('FootSide')
    public FootSide: string
    @propertyMap('HasEdema')
    public HasEdema: string
    @propertyMap('Dryness')
    public Dryness: string
    @propertyMap('HasColor')
    public HasColor: string
    @propertyMap('HasCracks')
    public HasCracks: string
    @propertyMap('NailCondition')
    public NailCondition: string
    @propertyMap('Hygiene')
    public Hygiene: string
    @propertyMap('Heat')
    public Heat: string
    @propertyMap('BackShin')
    public BackShin: string
    @propertyMap('Feel')
    public Feel: string
    @propertyMap('PainIntensity')
    public PainIntensity: string
    @propertyMap('Deformations')
    public Deformations: string
    @propertyMap('Comment')
    public Comment: string
    @propertyMap('PreventiveOp1')
    public PreventiveOp1: string
    @propertyMap('PreventiveOp2')
    public PreventiveOp2: string
    @propertyMap('PreventiveOp3')
    public PreventiveOp3: string
    @propertyMap('PreventiveOp4')
    public PreventiveOp4: string
    @propertyMap('PreventiveOp5')
    public PreventiveOp5: string
    @propertyMap('PreventiveOp6')
    public PreventiveOp6: string
    @propertyMap('PreventiveOp7')
    public PreventiveOp7: string
    @propertyMap('PreventiveOp8')
    public PreventiveOp8: string
    @propertyMap('PreventiveOp9')
    public PreventiveOp9: string
    @propertyMap('PreventiveOp10')
    public PreventiveOp10: string
    @propertyMap('MainCaretaker')
    public MainCaretaker: string
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.FootSide = data["FootSide"]
            this.MainCaretaker = data["MainCaretaker"]
            this.HasEdema = data["HasEdema"]
            this.HasColor = data["HasColor"]
            this.HasCracks = data["HasCracks"]
            this.NailCondition = data["NailCondition"]
            this.Hygiene = data["Hygiene"]
            this.Heat = data["Heat"]
            this.BackShin = data["BackShin"]
            this.Feel = data["Feel"]
            this.PainIntensity = data["PainIntensity"]
            this.Deformations = data["Deformations"]
            this.Comment = data["Comment"]
            this.PreventiveOp1 = data["PreventiveOp1"]
            this.PreventiveOp2 = data["PreventiveOp2"]
            this.PreventiveOp3 = data["PreventiveOp3"]
            this.PreventiveOp4 = data["PreventiveOp4"]
            this.PreventiveOp5 = data["PreventiveOp5"]
            this.PreventiveOp6 = data["PreventiveOp6"]
            this.PreventiveOp7 = data["PreventiveOp7"]
            this.PreventiveOp8 = data["PreventiveOp8"]
            this.PreventiveOp9 = data["PreventiveOp9"]
            this.PreventiveOp10 = data["PreventiveOp10"]
        }else {

            this.PersonelID = ""
            this.TenantID = ""
            this.CreatedAt = new Date()
            this.UpdatedAt = new Date()
            this.FootSide = ""
            this.MainCaretaker = ""
            this.HasEdema = ""
            this.HasColor = ""
            this.HasCracks = ""
            this.NailCondition = ""
            this.Hygiene = ""
            this.Heat = ""
            this.BackShin = ""
            this.Feel = ""
            this.PainIntensity = ""
            this.Deformations = ""
            this.Comment = ""
            this.PreventiveOp1 = ""
            this.PreventiveOp2 = ""
            this.PreventiveOp3 = ""
            this.PreventiveOp4 = ""
            this.PreventiveOp5 = ""
            this.PreventiveOp6 = ""
            this.PreventiveOp7 = ""
            this.PreventiveOp8 = ""
            this.PreventiveOp9 = ""
            this.PreventiveOp10 = ""
        }
    }


}