import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalFile } from 'src/app/models/customer';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.css']
})
export class PatientsListComponent extends CaretexComponent  implements OnInit {

  TabId = "main"
  filteredData: MedicalFile[] = [];
  renderedData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];
  totalNumOfRecords: number
  DefaultPhotoURL:string = "../../../assets/images/user-avatar.png"
  constructor(public medicalFileService: MedicalFileProviderService,
    private _title: Title,
    private route: ActivatedRoute,
    private router: Router,) {super(medicalFileService) }

  ngOnInit(): void {
    this._title.setTitle('Caretx| בקרת תרופות');
    this.loadData2("")
  }


  loadData2(filter: string) {
    this.medicalFileService.getMedicalFiles(filter);
    this.medicalFileService.dataChange.subscribe((data) => {
      this.filteredData = this.allFiles = data
      this.totalNumOfRecords = this.allFiles.length
    })
  }


  OpenMedicationReviews(medicalFile) {

    this.router.navigateByUrl(medicalFile.PersonelID+ '/pmreviews' )

  }


  CheckIfDocumentIsDirty() {
    return false 
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }


}
