import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MedicalPersonelMeasuresProviderService } from '../../../../../../src/app/caretx-doctors-module/doctor-services/medical-perosnelmeasures.data-provider.service';
import { CaretxNursingProviderService } from '../../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CartexEvent } from '../../../../../../src/app/models/genericTypes';
import { NurseAdmission } from '../../../../../../src/app/models/nurseadmission';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import { AccessComponenetType, OriginModule } from '../../../../services/enums';

@Component({
  selector: 'app-nurseing-admission-locked',
  templateUrl: './nurseing-admission-locked.component.html',
  styleUrls: ['./nurseing-admission-locked.component.css']
})
export class NurseingAdmissionLockedComponent extends CaretexComponent implements OnInit {
  personalId: string
  Admissionid: string
  currentNurseAdmission: NurseAdmission
  moduleName:string = "קבלה סיעודית - רשומה נעולה"
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  measureTypeSelectDisabled:boolean = true
  AccessedComponent: AccessComponenetType = AccessComponenetType.SubModule
  Origin = OriginModule.NurseAdmission
  Type: string = AccessComponenetType.SubModule
  
  constructor(
    private caretxNursingProviderService: CaretxNursingProviderService,
    private medicalPersonelMeasuressService: MedicalPersonelMeasuresProviderService,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
    super(medicalFileService)

  }

  ngOnInit(): void {
    if(this.route.parent !=null)
    this.personalId = this.route.parent.snapshot.params['id'];
    this.Admissionid = this.route.snapshot.params.admissionid
    this.CheckIfComponentIsDisabled(this.personalId)
    this.getNurseAdmissionById(this.personalId, this.route.snapshot.params.admissionid, "")

  }

  getNurseAdmissionById(id: string, admissionId: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + admissionId + '\'' + ')'

    this.caretxNursingProviderService.getNurseAdmissions(id, filter, mode).subscribe((data: NurseAdmission[]) => {
      if (data.length == 1) {
        this.currentNurseAdmission = new NurseAdmission(data[0])

      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/nurseadmission")
  }
  NewRecord() {

  }

  personelMeasuresChanged($event) {

  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }


  CheckIfDocumentIsDirty() {
    return false
  }

}
