import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-norton',
  templateUrl: './norton.component.html',
  styleUrls: ['./norton.component.css']
})
export class NortonComponent extends CaretexComponent implements OnInit {
  isSubModule:boolean = false
  
  constructor(  private route: ActivatedRoute,public medicalFileService: MedicalFileProviderService,) {
    super(medicalFileService)
  }

  ngOnInit(): void {
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
  
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }

}
