import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { MedicalPersonelMeasuresProviderService } from '../../../../../../src/app/caretx-doctors-module/doctor-services/medical-perosnelmeasures.data-provider.service';
import { CaretexEventTypes, CartexEvent, TabHistory } from '../../../../../../src/app/models/genericTypes';
import { NurseAdmission } from '../../../../../../src/app/models/nurseadmission';
import { BaseOmdan } from '../../../../../../src/app/models/omdans';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { AccessComponenetType, NurseAdmissionSteps} from '../../../../../../src/app/services/enums';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from  '../../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2'
import { CaretxNursingProviderService } from '../../../nursing-services/Nursing.data-provider.service';
import { NgForm } from '@angular/forms';
import { OriginModule } from '../../../../services/enums';
import { DiabeticFootTest, DiabeticFootTestHeader } from '../../../../../../src/app/models/DiabeticFootTest';

@Component({
  selector: 'app-nurseadmission',
  templateUrl: './nurseadmission.component.html',
  styleUrls: ['./nurseadmission.component.css']
})
export class NurseadmissionComponent extends CaretexComponent implements OnInit {

  AccessedComponent: AccessComponenetType = AccessComponenetType.SubModule
  personalId: string
  DrugModalId: string
  AdmissionType: string = "N"
  currentNurseAdmission: NurseAdmission
  CurrentStep: NurseAdmissionSteps = NurseAdmissionSteps.BackGround
  Admissionid: string
  isAdmissionClosed: boolean = false
  moduleName: string = "קבלה סיעודית"
  isSubModule: boolean = true
  BackgroundControlMsg: string
  HabitsControlMsg: string
  HobbiesControlMsg: string
  saveProcessCompleted: boolean = true
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  Type: string = AccessComponenetType.SubModule
  hideMainButtons: boolean = false
  omdan: BaseOmdan
  omdanChanged: boolean = false
  admissionSaved: boolean = false
  nurseExaminationModuleChanged: boolean = false
  personelSensitivityModuleChanged: boolean = false
  personelMeasuresModuleChanged: boolean = false
  nurseCognitiveModuleChanged: boolean = false
  tabHistory: TabHistory = new TabHistory()
  caretexEvent: CartexEvent = new CartexEvent()
  @ViewChild('sectionAForm') sectionAForm: NgForm;
  @ViewChild('sectionFForm') sectionFForm: NgForm;
  step: number = 0
  MeasureHeaderOrigin : OriginModule = OriginModule.NurseAdmission
  measureTypeSelectDisabled:boolean = true
  zoomInModalData: any;
  dismissModal:boolean = false
  Origin = OriginModule.NurseAdmission
  diabeticFootTest:DiabeticFootTestHeader

  constructor(
    private caretxNursingProviderService: CaretxNursingProviderService,
    private medicalPersonelMeasuressService: MedicalPersonelMeasuresProviderService,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
    super(medicalFileService)
    this.isSubModule = true
  }

  ngOnInit(): void {
    if (this.route.parent != null)
    this.personalId = this.route.parent.snapshot.params['id'];
    this.Admissionid = this.route.snapshot.params.admissionid
    this.CheckIfComponentIsDisabled(this.personalId)
    if (this.route.snapshot.params['tabid'] != "" && this.route.snapshot.params['tabid'] != undefined) {
      this.step = Number(this.route.snapshot.params['tabid']);
    }

    if (this.route.snapshot.params.admissionid == 'new') {
      this.getNurseAdmissionById(this.personalId, this.route.snapshot.params.admissionid, "new")
      this.admissionSaved = false
    } else {
      this.getNurseAdmissionById(this.personalId, this.route.snapshot.params.admissionid, "")
      this.admissionSaved = true
    }
  }

  /******************************************************************************* */
  getNurseAdmissionById(id: string, admissionId: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + admissionId + '\'' + ')'

    this.caretxNursingProviderService.getNurseAdmissions(id, filter, mode).subscribe((data: NurseAdmission[]) => {
      if (data.length == 1) {
        this.currentNurseAdmission = new NurseAdmission(data[0])
        this.isAdmissionClosed = (this.currentNurseAdmission.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  SaveAdmission() {
    // Check if current active page is Omdan Page 
    if (this.CurrentStep == NurseAdmissionSteps.BhoseExam ||
      this.CurrentStep == NurseAdmissionSteps.FimExam ||
      this.CurrentStep == NurseAdmissionSteps.NortonExam ||
      this.CurrentStep == NurseAdmissionSteps.Omdan2Exam ||
      this.CurrentStep == NurseAdmissionSteps.Omdan4Exam ||
      this.CurrentStep == NurseAdmissionSteps.Omdan5Exam ||
      this.CurrentStep == NurseAdmissionSteps.DiabeticTest ||
      this.CurrentStep == NurseAdmissionSteps.SnaqExam) {
      this.SaveOmdan()
    } else {
      this.saveProcessCompleted = false;
      if (this.currentNurseAdmission.ID == "" || this.currentNurseAdmission.ID == null || this.currentNurseAdmission.ID == "00000000-0000-0000-0000-000000000000") {
        this.DoSave()
      }
      else {
        this.DoUpdate()
      }
    }
  }

  DoSave() {
    this.caretxNursingProviderService.createNewNurseAdmission(this.currentNurseAdmission).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentNurseAdmission = data as NurseAdmission

        if (this.currentNurseAdmission.Status == 'C')
          this.isAdmissionClosed = true
        this.saveProcessCompleted = true;
        this.medicalFileService.ResetCachedMedicalFile(this.personalId)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.resetFlagsAfterSave()
        this.router.onSameUrlNavigation = 'reload';
        this.medicalFileService.ResetCachedMedicalFile(this.personalId)
        if (this.currentNurseAdmission.Status == "C"){
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/nurseadmission/" + this.currentNurseAdmission.ID + "/locked")
        }else{
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/nurseadmission/" + this.currentNurseAdmission.ID + "/step/" + this.step)
        }
       


      },
      error: error => {
        this.toastrService.Error("", error.error.message)
        this.saveProcessCompleted = true;
      }
    })
  }

  DoUpdate() {
    this.caretxNursingProviderService.updateurseAdmission(this.currentNurseAdmission).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentNurseAdmission = data as NurseAdmission
        if (this.currentNurseAdmission.Status == 'C')
          this.isAdmissionClosed = true
        this.saveProcessCompleted = true;
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.resetFlagsAfterSave()
        this.router.onSameUrlNavigation = 'reload';
        this.medicalFileService.ResetCachedMedicalFile(this.personalId)
        if (this.currentNurseAdmission.Status == "C"){
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/nurseadmission/" + this.currentNurseAdmission.ID + "/locked")
        }else{
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/nurseadmission/" + this.currentNurseAdmission.ID + "/step/" + this.step)
        }

      },
      error: error => {
        this.toastrService.Error("", error.error.message)
        this.saveProcessCompleted = true;
      }
    })
  }




  resetFlagsAfterSave() {
    this.omdanChanged = false
    this.personelSensitivityModuleChanged = false
    this.personelMeasuresModuleChanged = false
    this.nurseCognitiveModuleChanged = false
  }

  LockAdmission() {
    let res1 = false 
    if (res1) {
      Swal.fire({
        title: 'לידיעתך',
        text: "אחד או יותא מהאומדנים לא מולאו כמו שצריך (חלק מהשאלות עדיין פתוחות)",
        icon: 'warning',

      })
    }
    else {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם את/ה רוצה לנעול קבלה ?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText:"בטל",
        confirmButtonText: 'כן,תנעל'
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentNurseAdmission.Status = 'C'
          this.currentNurseAdmission.NurseExamination.Status = 'C'
          this.currentNurseAdmission.NurseExamination.PersonelID = this.personalId
          this.currentNurseAdmission.NurseExamination.LockedDate = this.currentNurseAdmission.Closed_at = new Date()
          this.currentNurseAdmission.NurseExamination.LockedById = this.currentNurseAdmission.ClosedById = this.authServive.LoggedInUser.UID
          this.currentNurseAdmission.NurseExamination.LockedBy = this.currentNurseAdmission.ClosedBy = this.authServive.LoggedInUser
          this.currentNurseAdmission.NurseExamination.RealmID = this.authServive.LoggedInTenantID

          this.currentNurseAdmission.NurseCognitiveTest.Status = 'C'
          this.currentNurseAdmission.NurseCognitiveTest.PersonelID = this.personalId
          this.currentNurseAdmission.NurseCognitiveTest.LockedAt = this.currentNurseAdmission.Closed_at = new Date()
          this.currentNurseAdmission.NurseCognitiveTest.LockedById = this.currentNurseAdmission.ClosedById = this.authServive.LoggedInUser.UID
          this.currentNurseAdmission.NurseCognitiveTest.LockedBy = this.currentNurseAdmission.ClosedBy = this.authServive.LoggedInUser
          this.currentNurseAdmission.NurseCognitiveTest.TenantID = this.authServive.LoggedInTenantID


          this.SaveAdmission()
          this.isAdmissionClosed = true
        }
      })
    }
  }

  selectTab(tabId: string) {


    this.tabHistory.CurrentTab = this.CurrentStep
    this.tabHistory.NextTab = tabId
    this.caretexEvent.EventType = CaretexEventTypes.Tab
    this.caretexEvent.EventData = this.tabHistory

    if (this.CurrentStep == NurseAdmissionSteps.PhysicalTest ||
      this.CurrentStep == NurseAdmissionSteps.Sensitivity ||
      this.CurrentStep == NurseAdmissionSteps.Measures ||
      this.CurrentStep == NurseAdmissionSteps.CognitiveTest
    )
      this.eventsSubject.next(this.caretexEvent);

    if (this.CheckIfDocumentIsDirty())
      this.SaveAdmission()
    else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.CurrentStep = (tabId as NurseAdmissionSteps)
    }
  }

  //Tobe deleted
  openSelectedExam($event) {

    // Prepare the Omdan Base

    this.CurrentStep = $event as NurseAdmissionSteps
    if (this.CurrentStep == NurseAdmissionSteps.BhoseExam ||
      this.CurrentStep == NurseAdmissionSteps.FimExam ||
      this.CurrentStep == NurseAdmissionSteps.NortonExam ||
      this.CurrentStep == NurseAdmissionSteps.Omdan2Exam ||
      this.CurrentStep == NurseAdmissionSteps.Omdan4Exam ||
      this.CurrentStep == NurseAdmissionSteps.Omdan5Exam ||
      this.CurrentStep == NurseAdmissionSteps.SnaqExam) {
      this.hideMainButtons = true
    } else {
      this.hideMainButtons = false
    }
  }

  backToOmdanemList($event) {
    this.CurrentStep = NurseAdmissionSteps.Tests
  }

  BackToList() {

    if (this.CurrentStep == NurseAdmissionSteps.BhoseExam ||
      this.CurrentStep == NurseAdmissionSteps.FimExam ||
      this.CurrentStep == NurseAdmissionSteps.NortonExam ||
      this.CurrentStep == NurseAdmissionSteps.Omdan2Exam ||
      this.CurrentStep == NurseAdmissionSteps.Omdan4Exam ||
      this.CurrentStep == NurseAdmissionSteps.Omdan5Exam ||
      this.CurrentStep == NurseAdmissionSteps.SnaqExam) {
      if (this.omdanChanged) {
        Swal.fire({
          title: "הודעת מערכת",
          text: "'אתה עומד לעזוב את המבחן עם שינויים שלא נשמרו. האם אתה רוצה להמשיך?'",
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: "תשאר",
          confirmButtonText: 'כן,תמשיך'
        }).then((result) => {
          if (result.isConfirmed) {
            this.CurrentStep = NurseAdmissionSteps.Tests
          }
        })
      } else {
        this.CurrentStep = NurseAdmissionSteps.Tests
      }
    } else {
      this.router.navigateByUrl("caretexfile/" + this.personalId + "/nurseadmission")
    }

  }

  updatePersonalMedicalMeasures($event) {
    this.currentNurseAdmission.PersonalMeasuresHeader.PersonalMeasures = $event
  }

  updatePersonalBhoseExam($event) {
    this.omdan = $event
  }

  ModuleBhosChanged($event) {
    this.omdanChanged = $event
  }


  ModuleDiabeticFootTesChanged($event) {
    this.omdanChanged = $event
  }

  updateDiabeticFootTest($event) {
    this.diabeticFootTest = $event
  }

  updatePersonalFimExam($event) {
    this.omdan = $event
    this.omdanChanged = true
  }

  ModuleFimChanged($event) {
    this.omdanChanged = $event
  }

  updatePersonalNortonExam($event) {
    this.omdan = $event
  }

  ModuleNortonChanged($event) {
    this.omdanChanged = $event
  }

  updatePhysicalCheck($event) {
    this.currentNurseAdmission.NurseExamination = $event
  }

  updatePersonalOmdan2Exam($event) {
    this.omdan = $event
  }

  ModuleOmdan2hanged($event) {
    this.omdanChanged = $event
  }

  updatePersonalOmdan4Exam($event) {
    this.omdan = $event
  }

  ModuleOmdan4hanged($event) {
    this.omdanChanged = $event
  }

  updatePersonalOmdan5Exam($event) {
    this.omdan = $event
  }

  ModuleOmdan5hanged($event) {
    this.omdanChanged = $event
  }

  updatePersonalSnaqExam($event) {
    this.omdan = $event
  }

  ModuleSanqhanged($event) {
    this.omdanChanged = $event
  }
  /***********************************Sub Modules Change Events*********************/
  nursePhysicalcheckChnaged($event) {
    this.nurseExaminationModuleChanged = $event
  }

  personelSensitivityChanged($event) {
    this.personelSensitivityModuleChanged = $event
  }

  personelMeasuresChanged($event) {
    this.personelMeasuresModuleChanged = $event
  }

  nursecognitivecheckChnaged($event) {
    this.nurseCognitiveModuleChanged = $event
  }

  updateCognitiveCheck($event) {
    this.currentNurseAdmission.NurseCognitiveTest = $event
  }

  updateNextTabFromSensitivity($event) {

  }

  /********************************** */
  SaveOmdan() {

    if (this.CurrentStep == NurseAdmissionSteps.DiabeticTest) {
      this.diabeticFootTest.Origin = "nurseadmission"
      this.diabeticFootTest.OriginId = this.Admissionid
      this.diabeticFootTest.PersonelID = this.personalId
      this.diabeticFootTest.TenantID = this.authServive.LoggedInTenantID
      if (this.diabeticFootTest.ID == "" || this.diabeticFootTest.ID == null || this.diabeticFootTest.ID == undefined) {
        this.DoDiabeticTestSave()
      }
      else {
        this.DoDiabeticTestUpdate()
      }
    } else {
      this.omdan.Origin = "nurseadmission"
      this.omdan.OriginId = this.Admissionid
      this.omdan.PersonelID = this.personalId
      this.omdan.RealmID = this.authServive.LoggedInTenantID
      let omdanType: string =""
  
      switch (this.CurrentStep) {
        case NurseAdmissionSteps.BhoseExam:
          omdanType = "bhose"
          break
        case NurseAdmissionSteps.FimExam:
          omdanType = "fim"
          break
        case NurseAdmissionSteps.NortonExam:
          omdanType = "norton"
          break
        case NurseAdmissionSteps.Omdan2Exam:
          omdanType = "omdan2"
          break
        case NurseAdmissionSteps.Omdan4Exam:
          omdanType = "omdan4"
          break
        case NurseAdmissionSteps.Omdan5Exam:
          omdanType = "omdan5"
          break
        case NurseAdmissionSteps.SnaqExam:
          omdanType = "snaq"
          break
        default:
          break
      }
  
        if (this.omdan.ID == "" || this.omdan.ID == null || this.omdan.ID == undefined) {
          this.DoOmdanSave(omdanType)
        }
        else {
          this.DoOmdanUpdate(omdanType)
        }
    }
   


  }


  DoDiabeticTestSave() {
    this.caretxNursingProviderService.CreateDiabeticFootTest(this.diabeticFootTest).subscribe({
      next: data => {
        this.diabeticFootTest = (data as DiabeticFootTestHeader)
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.updateNurseAdmisisonWithOmdanId("diabetic", this.diabeticFootTest.ID)
        this.omdanChanged = false
        this.CurrentStep = this.tabHistory.NextTab as NurseAdmissionSteps
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }


  DoDiabeticTestUpdate() {
    this.caretxNursingProviderService.UpdateDiabeticFootTest(this.diabeticFootTest).subscribe({
      next: data => {
        this.diabeticFootTest = (data as DiabeticFootTestHeader)
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.updateNurseAdmisisonWithOmdanId("diabetic", this.diabeticFootTest.ID)
        this.omdanChanged = false
        this.CurrentStep = this.tabHistory.NextTab as NurseAdmissionSteps
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }
  DoOmdanSave(omdanType: string) {
    this.caretxNursingProviderService.CreateOmdan(this.omdan, omdanType).subscribe({
      next: data => {
        this.omdan = (data as BaseOmdan)
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.updateNurseAdmisisonWithOmdanId(omdanType, this.omdan.ID)
        this.omdanChanged = false
        this.CurrentStep = this.tabHistory.NextTab as NurseAdmissionSteps
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  DoOmdanUpdate(omdanType: string) {
    this.caretxNursingProviderService.UpdateOmdan(this.omdan, omdanType).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.omdan = data as BaseOmdan
        this.updateNurseAdmisisonWithOmdanId(omdanType, this.omdan.ID)
        this.omdanChanged = false
        this.CurrentStep = this.tabHistory.NextTab as NurseAdmissionSteps
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  updateNurseAdmisisonWithOmdanId(omdanType: string, id: string) {
    switch (omdanType) {
      case "bhose":
        this.currentNurseAdmission.OmdanbohseId = id
        break
      case "fim":
        this.currentNurseAdmission.OmdanfimId = id
        break
      case "norton":
        this.currentNurseAdmission.OmdannortonId = id
        break
      case "omdan2":
        this.currentNurseAdmission.Omdan2Id = id
        break
      case "omdan4":
        this.currentNurseAdmission.Omdan4Id = id
        break
      case "omdan5":
        this.currentNurseAdmission.OmdanbohseId = id
        break
      case "snaq":
        this.currentNurseAdmission.OmdansnaqId = id
        break
        case "diabetic":
          this.currentNurseAdmission.DiabeticFootTestId = id
          break
      default:
        break
    }

  }

  openSelectedExam2($event) {

    // Prepare the Omdan Base
    this.CurrentStep = $event as NurseAdmissionSteps
    switch(this.CurrentStep) {
      case NurseAdmissionSteps.BhoseExam : 
          this.step = 6.1
      break

      case NurseAdmissionSteps.Omdan2Exam : 
        this.step = 6.2
      break

      case NurseAdmissionSteps.SnaqExam : 
        this.step = 6.3
      break

      case NurseAdmissionSteps.Omdan4Exam : 
        this.step = 6.4
      break

      case NurseAdmissionSteps.FimExam : 
        this.step = 6.5
      break

      case NurseAdmissionSteps.Omdan5Exam : 
        this.step = 6.6
      break

      case NurseAdmissionSteps.NortonExam : 
      this.step = 6.7
      break
      case NurseAdmissionSteps.DiabeticTest : 
      this.step = 6.8
      break
      default:
      break
    }
    if (this.CurrentStep == NurseAdmissionSteps.BhoseExam ||
      this.CurrentStep == NurseAdmissionSteps.FimExam ||
      this.CurrentStep == NurseAdmissionSteps.NortonExam ||
      this.CurrentStep == NurseAdmissionSteps.Omdan2Exam ||
      this.CurrentStep == NurseAdmissionSteps.Omdan4Exam ||
      this.CurrentStep == NurseAdmissionSteps.Omdan5Exam ||
      this.CurrentStep == NurseAdmissionSteps.DiabeticTest  ||
      this.CurrentStep == NurseAdmissionSteps.SnaqExam) {
      this.hideMainButtons = true
    } else {
      this.hideMainButtons = false
    }
  }

  backToOmdanemList_sub(){

    if (this.omdanChanged){
      Swal.fire({
        title: 'לידיעתך',
        text: "שינויים שנעשו למבחו לא נשמרו ! , רוצה להמשיך ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמשיך',
        cancelButtonText: 'תשאר בדף'
      }).then((result) => {
        if (result.isConfirmed) {

          this.step = 6
          this.omdanChanged = false
        }
      })
    }else {
      this.step = 6
      this.omdanChanged = false
    }
    
  }

/*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

CheckIfDocumentIsDirty() {

  return this.nurseExaminationModuleChanged
    || this.omdanChanged
    || this.personelSensitivityModuleChanged
    || this.personelMeasuresModuleChanged
    || this.nurseCognitiveModuleChanged
 /*  || this.sectionAForm.dirty
    || this.sectionFForm.dirty*/
}

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  NewRecord(){

  }

  zoomInField(title, fieldNgModel, preFilledData) {
    this.dismissModal = false
    this.zoomInModalData = {title, field: fieldNgModel, preFilled: (preFilledData || '')};
   
  }

  bindFieldData(responseData) {
    this.currentNurseAdmission[this.zoomInModalData.field] = responseData;

  }

  DismissCtxTextareaModal(){
      this.dismissModal = true
  }
}


