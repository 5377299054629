import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  NextFollowUps } from '../../../../../src/app/models/customer';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';

@Component({
  selector: 'app-nextfollowupbanner',
  templateUrl: './nextfollowupbanner.component.html',
  styleUrls: ['./nextfollowupbanner.component.css']
})
export class NextfollowupbannerComponent implements OnInit {

  id: string
  @Input() FollowUpType: string
  showContent: boolean = false
  followUp: NextFollowUps = new NextFollowUps()
  TextToDisplay:string

  constructor(
    private authService: AuthService,
    private router: Router,
    private _ar: ActivatedRoute,
    private caretxSharedlService: CaretxSharedModulesProviderService) { }


  ngOnInit(): void {
    this.id = this._ar.parent.snapshot.params['id']
    this.getNextFollowUp()

  }

  ShowHiddenContent() {
    this.showContent = !this.showContent
  }

  // Get Sensitivity

  getNextFollowUp() {

    let filter = "follow_up_type = '"+this.FollowUpType+"' and personel_id = '" + this.id + "'"
    this.caretxSharedlService.getNextFollowUps(filter).
      subscribe({
        next: data => {
          if (data.length == 0 ){
            this.showContent = false
            this.followUp = new NextFollowUps()
          }else{
            this.followUp = data[0]
            this.showContent = true
            this.TextToDisplay = this.formatDate(this.followUp.NextFollowUp);
          }
    

        },
        error: error => {
          this.followUp = new NextFollowUps()
        }
      })
  }

   padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }
  
  // 👇️ format as "YYYY-MM-DD hh:mm:ss"
  // You can tweak the format easily
   formatDate(date: Date) {

    const datepipe: DatePipe = new DatePipe('en-US')
    let formattedDate = datepipe.transform(date, 'dd/MM/yyyy')

    
    return formattedDate
  }


}
