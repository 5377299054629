import { Component, Inject, OnInit } from '@angular/core';
import { CaretxMedicalProviderService } from '../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { DrugInstruction } from '../../../../../src/app/models/drugInstruction';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-drug-instruction-action-dialog',
  templateUrl: './drug-instruction-action-dialog.component.html',
  styleUrls: ['./drug-instruction-action-dialog.component.css']
})
export class DrugInstructionActionDialogComponent implements OnInit {


  ActionTitle: string
  IntsrStatus:string
  DrugInstr: DrugInstruction
  ReasonText: string

  constructor(private caretxMedicalService: CaretxMedicalProviderService,private toastrService: ToasterService,
    private dialogRef: MatDialogRef<DrugInstructionActionDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { dialogRef.disableClose = true;}

  ngOnInit(): void {
    this.ActionTitle = this.data.ActionTitle
    this.DrugInstr = this.data.DrugInstr
    this.IntsrStatus = this.data.IntsrStatus
  }

  UpdateInstrStatusAction() {
    this.DrugInstr.Modified = true
    this.DrugInstr.instrStatus = this.IntsrStatus
    this.DrugInstr.StatusChangeReason = this.ReasonText
    if (this.caretxMedicalService) {
      this.caretxMedicalService.updateDrugInstr( this.DrugInstr).subscribe({
        next: data => {
          this.toastrService.Sucesss("", this.toastrService.successMsg)
          this.dialogRef.close();
        },
        error: error => {
          this.toastrService.Error("", this.toastrService.errorMsg)
        }
      })
    }
  }


  submit() {
      this. UpdateInstrStatusAction()
  }
}
