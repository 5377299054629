import { Component, OnInit } from '@angular/core';
import { CaretexComponent } from '../../../shared/CaretexComponent';
import { CaretxSetupsProviderService } from '../../../caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxNursingProviderService } from '../../../caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { ToasterService } from '../../../services/toastr.service';
import { Exceptionevent } from '../../../models/exceptionEvent';

@Component({
  selector: 'app-exceptionaleventlocked',
  templateUrl: './exceptionaleventlocked.component.html',
  styleUrls: ['./exceptionaleventlocked.component.css']
})
export class ExceptionaleventlockedComponent extends CaretexComponent implements OnInit {
  id: string
  moduleName: string = "אירוע חריג - רשומה נעולה"
  BaseUrl = ""
  currentExceptionEvent: Exceptionevent
  nurseSignTxt: string
  doctorSignTxt: string
  doctorsManagerSignTxt: string
  nursingManagerSignTxt: string
  houseManagerSignTxt: string

  constructor(
    private caretxSetupService: CaretxSetupsProviderService,
    public medicalFileService: MedicalFileProviderService,
    private authService: AuthService,
    private router: Router,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,) {
      super(medicalFileService)

  }

  ngOnInit(): void {
    this.CheckIfComponentIsDisabled(this.id)
    if (this.route.parent != undefined) {
      this.id = this.route.parent.snapshot.params['id'];
      this.BaseUrl = this.route.parent.snapshot.url[2].path
    }
    this.getEventById(this.route.snapshot.params.eventid)
  }



  getEventById(eventId: string) {
    let filter = '(id=' + '\'' + eventId + '\'' + ')'
    this.caretxNursingProviderService.getExceptionalEventsById(filter, this.id).subscribe((data: Exceptionevent[]) => {
      if (data.length == 1) {
        this.currentExceptionEvent = data[0]
        this.buildNurseSignText()
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }


  buildNurseSignText() {
    if (this.currentExceptionEvent.IsSignedByNurse) {
      let today = new Date(this.currentExceptionEvent.NurseCheckSignOfDate)
      let nurseName = this.currentExceptionEvent.CheckedByNurse.displayName
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var Hour = today.getHours().toString()
      var Min = today.getMinutes().toString()

      var todayAsString = mm + '/' + dd + '/' + yyyy + " "
      var time = Hour + ":" + Min;

      this.nurseSignTxt = "נחתם עי " + nurseName + " בתאריך " + todayAsString + " " + " בשעה " + time
    }
  }

  buildDoctorSignText() {
    if (this.currentExceptionEvent.IsSignedByDoctor) {
      let today = new Date(this.currentExceptionEvent.DoctorCheckSignOfDate)
      let docName = this.currentExceptionEvent.CheckedByDoctor.displayName
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var Hour = today.getHours().toString()
      var Min = today.getMinutes().toString()

      var todayAsString = mm + '/' + dd + '/' + yyyy + " "
      var time = Hour + ":" + Min;

      this.doctorSignTxt = "נחתם עי " + docName + " בתאריך " + todayAsString + " " + " בשעה " + time
    }
  }

  buildDoctorsManagerSignText() {
    if (this.currentExceptionEvent.IsSignedByMedicalManager) {
      let today = new Date(this.currentExceptionEvent.MedicalManagerSignOfDate)
      let docName = this.currentExceptionEvent.SignedByMedicalManager.displayName
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var Hour = today.getHours().toString()
      var Min = today.getMinutes().toString()

      var todayAsString = mm + '/' + dd + '/' + yyyy + " "
      var time = Hour + ":" + Min;

      this.doctorsManagerSignTxt = "נחתם עי " + docName + " בתאריך " + todayAsString + " " + " בשעה " + time
    }
  }

  buildHouseManagerSignText() {
    if (this.currentExceptionEvent.IsSignedByHouseManager) {
      let today = new Date(this.currentExceptionEvent.HouseManagerSignOfDate)
      let docName = this.currentExceptionEvent.SignedByHouseManager.displayName
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var Hour = today.getHours().toString()
      var Min = today.getMinutes().toString()

      var todayAsString = mm + '/' + dd + '/' + yyyy + " "
      var time = Hour + ":" + Min;

      this.houseManagerSignTxt = "נחתם עי " + docName + " בתאריך " + todayAsString + " " + " בשעה " + time
    }
  }

  buildNursingManagerSignText() {
    if (this.currentExceptionEvent.IsSignedByNursingManager) {
      let today = new Date(this.currentExceptionEvent.NursingManagerSignOfDate)
      let docName = this.currentExceptionEvent.SignedByNursingManager.displayName
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      var Hour = today.getHours().toString()
      var Min = today.getMinutes().toString()

      var todayAsString = mm + '/' + dd + '/' + yyyy + " "
      var time = Hour + ":" + Min;

      this.nursingManagerSignTxt = "נחתם עי " + docName + " בתאריך " + todayAsString + " " + " בשעה " + time
    }
  }


  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/" + this.BaseUrl + "/list")
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  
  CheckIfDocumentIsDirty(){
    return false;
  }
}
