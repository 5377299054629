import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import { CaretxMedicalProviderService } from '../../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalFile } from '../../../../../../src/app/models/customer';
import { HospitalReferral } from '../../../../../../src/app/models/hospitalreferrals';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { ReportingService } from '../../../../../../src/app/services/reporting.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import { RowInput } from 'jspdf-autotable';


@Component({
  selector: 'app-hospital-referral-details-locked',
  templateUrl: './hospital-referral-details-locked.component.html',
  styleUrls: ['./hospital-referral-details-locked.component.css']
})
export class HospitalReferralDetailsLockedComponent extends CaretexComponent implements OnInit {
  personalId: string
  referralid: string
  currentRecord: HospitalReferral
  selectedDoctor: string
  moduleName: string
  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  selectedFile: MedicalFile
  pdfContent: any


  constructor(
    public medicalFileService: MedicalFileProviderService,
    private medicalProviderService: CaretxMedicalProviderService,
    private authService: AuthService,
    private router: Router,
    public reportingService: ReportingService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,) {
    super(medicalFileService)
  }


  ngOnInit(): void {
    if (this.route.parent != null)
      this.personalId = this.route.parent.snapshot.params['id']
    this.referralid = this.route.snapshot.params.referralid
    this.CheckIfComponentIsDisabled(this.personalId)
    this.getHospitalReferralFromDBById(this.referralid)
  }

  getHospitalReferralFromDBById(referralid: string) {
    let filter = ""
    filter = '(id=' + '\'' + referralid + '\'' + ')'
    this.medicalProviderService.getReferrals(filter, this.personalId, 1, "").subscribe((data: HospitalReferral[]) => {
      if (data.length == 1) {
        this.currentRecord = new HospitalReferral(data[0])
        this.selectedDoctor = this.currentRecord.Doctor.firstName + " " + this.currentRecord.Doctor.lastName
        this.moduleName = "הפניה ל : " + this.currentRecord.ReferralTo
      }
      else {
        let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }


  openPDFViewer() {
    let reportTitle = "הפנייה רפואית"
    this.showGeneratedPdf = true
    this.selectedFile = this.medicalFile1
    var doc = new jsPDF()
    let lastYPosition: number
    let xPosTitle:number = 122
    lastYPosition = this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle)
    this.reportingService.setFooter2(doc, this.currentRecord.ClosedBy,this.authService.LoggedInUser, this.currentRecord.ClosedAt,new Date())

    lastYPosition += 6
    let label = "הפניה ל:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentRecord.ReferralTo == "" ? "לא צויין" : this.currentRecord.ReferralTo, this.currentRecord.ClosedBy,this.authService.LoggedInUser,this.currentRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    label = "תאריך הפניה:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentRecord.ReferralDate.toString() == "" ? "לא צויין" : this.currentRecord.ReferralDate.toString(), this.currentRecord.ClosedBy,this.authService.LoggedInUser,this.currentRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    label = "אפוטרוס:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentRecord.Guardian == "" ? "לא צויין" : this.currentRecord.Guardian, this.currentRecord.ClosedBy,this.authService.LoggedInUser,this.currentRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    label = "מספר טלפון של אפוטרופוס:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentRecord.GuardianPhone == "" ? "לא צויין" : this.currentRecord.GuardianPhone, this.currentRecord.ClosedBy,this.authService.LoggedInUser,this.currentRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)


    label = "סיבת הפנייה:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentRecord.ReferralReason == "" ? "לא צויין" : this.currentRecord.ReferralReason, this.currentRecord.ClosedBy,this.authService.LoggedInUser,this.currentRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)


    label = "הערות:"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentRecord.Comments == "" ? "לא צויין" : this.currentRecord.Comments, this.currentRecord.ClosedBy,this.authService.LoggedInUser,this.currentRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9, label, "אבחנות רפואיות", this.currentRecord.ClosedBy,this.authService.LoggedInUser, this.currentRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    if (this.currentRecord.Diagnoses.length <= 0) {
      lastYPosition = this.reportingService.AddBulletLinesSection(doc, lastYPosition, "לא נמצאו אבחנות פעילות", this.currentRecord.ClosedBy,this.authService.LoggedInUser,this.currentRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    } else {
      var colHeader1: RowInput[] = [[ this.reportingService.reverseString("סוג"),this.reportingService.reverseString("אבחנה")]]
      var colVals1: RowInput[] = []
      this.currentRecord.Diagnoses.forEach(medcalDiagnoses => {
        let tmpValRow: RowInput = [this.reportingService.reverseString(medcalDiagnoses.Type),this.reportingService.reverseString(medcalDiagnoses.MedicalDiagnoses.ShortDescription)]
        colVals1.push(tmpValRow)
      });
      lastYPosition = this.reportingService.AddTableToPDF2(doc, lastYPosition, 50, this.medicalFile1, this.currentRecord.ClosedBy,this.authService.LoggedInUser,this.currentRecord.ClosedAt,new Date(), colHeader1, colVals1,reportTitle,xPosTitle)
    }

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+3,9, label, "תרופות קבועות", this.currentRecord.ClosedBy,this.authService.LoggedInUser, this.currentRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentRecord.DrugInstructions.length <= 0) {
      lastYPosition = this.reportingService.AddBulletLinesSection(doc, lastYPosition, "לא נמצאו תרופות קבועות", this.currentRecord.ClosedBy,this.authService.LoggedInUser,this.currentRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    } else {

      var colHeader: RowInput[] = [[this.reportingService.reverseString("שם תרופה")]]
      var colVals: RowInput[] = []
      this.currentRecord.DrugInstructions.forEach(instr => {

        let tmpValRow: RowInput = [this.reportingService.reverseString(instr.Drug.ShortTradeName)]

        colVals.push(tmpValRow)
      });
      lastYPosition = this.reportingService.AddTableToPDF2(doc, lastYPosition, 50, this.medicalFile1, this.currentRecord.ClosedBy,this.authService.LoggedInUser,this.currentRecord.ClosedAt,new Date(), colHeader, colVals,reportTitle,xPosTitle)
    }
  



    let file: Blob = doc.output('blob')
    this.pdfContent = URL.createObjectURL(file);
    this.pdfViewer.pdfSrc = file
    this.pdfViewer.refresh();
  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }



  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false;
  }

  BackToMainDocument() {
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/hospitalreferrals/list")
  }

  NewRecord() {
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/hospitalreferrals/new")
  }
}
