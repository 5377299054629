import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NusrsingModuleRoutingModule } from './nusrsing-module-routing.module';
import { PersonalMeasuresComponent } from './Components/personalmeasureslist/personal-measures/personal-measures.component';
import { SharedModule} from '../caretx-shared-module/shared-module.module';
import { BrowserModule } from '@angular/platform-browser';
import { PipesModule } from '../caretx-custompipes-module/custompipes.module';
import { ModalModule } from '../modal/modal.module';
import { NurseadmissionComponent } from './Components/nursingadmissionlist/nurseadmission/nurseadmission.component';
import { NursingplanComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/nursingplan/nursingplan.component';
import { NursesummaryComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/nursesummary/nursesummary.component';
import { DoctorsModule } from '../caretx-doctors-module/doctors.module';
import { NurseLabOrdersComponent } from './Components/nurse-lab-orders/nurse-lab-orders.component';
import { CaretxNursingProviderService } from './nursing-services/Nursing.data-provider.service';
import { ExceptionEventsListComponent } from './Components/exception-events-list/exception-events-list.component';
import { BedsoresComponent } from './Components/bedsores/bedsores.component';
import { BohseComponent } from './examslandingpage/exams/bohse/bohse.component';
import { Omdan2Component } from './examslandingpage/exams/omdan2/omdan2.component';
import { SnaqComponent } from './examslandingpage/exams/snaqlist/snaq/snaq.component';
import { Omdan4Component } from './examslandingpage/exams/omdan4/omdan4.component';
import { FimComponent } from './examslandingpage/exams/fim/fim.component';
import { Omdan5Component } from './examslandingpage/exams/omdan5/omdan5.component';
import { NortonComponent } from './examslandingpage/exams/norton/norton.component';
import { BhoseListComponent } from './examslandingpage/exams/bhose-list/bhose-list.component';
import { Omdan2listComponent } from './examslandingpage/exams/omdan2list/omdan2list.component';
import { SnaqlistComponent } from './examslandingpage/exams/snaqlist/snaqlist.component';
import { FimlistComponent } from './examslandingpage/exams/fimlist/fimlist.component';
import { Omdan5listComponent } from './examslandingpage/exams/omdan5list/omdan5list.component';
import { NortonlistComponent } from './examslandingpage/exams/nortonlist/nortonlist.component';
import { Omdan4listComponent } from './examslandingpage/exams/omdan4list/omdan4list.component';
import { OmdanemListComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/omdanem-list/omdanem-list.component';
import { BhosesubmodelComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/bhosesubmodel/bhosesubmodel.component';
import { NursingadmissionlistComponent } from './Components/nursingadmissionlist/nursingadmissionlist.component';
import { PersonalmeasureslistComponent } from './Components/personalmeasureslist/personalmeasureslist.component';
import { NurseexaminationslistComponent } from './Components/nurseexaminationslist/nurseexaminationslist.component';
import { NursuryphysicalexaminationComponent } from './Components/nurseexaminationslist/nursuryphysicalexamination/nursuryphysicalexamination.component';
import { FimsummodelComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/fimsummodel/fimsummodel.component';
import { NortonsubmodelComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/nortonsubmodel/nortonsubmodel.component';
import { Omdan2submodelComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/omdan2submodel/omdan2submodel.component';
import { SnaqsubmodelComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/snaqsubmodel/snaqsubmodel.component';
import { Omdan5submodelComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/omdan5submodel/omdan5submodel.component';
import { Omdan4submodelComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/omdan4submodel/omdan4submodel.component';
import { BedSoreTracksListComponent } from './Components/bedsores/subcomponents/bed-sore-tracks-list/bed-sore-tracks-list.component';
import { BedSoresListComponent } from './Components/bedsores/bed-sores-list/bed-sores-list.component';
import { BedsorepictrackComponent } from './Components/bedsores/subcomponents/bedsorepictrack/bedsorepictrack.component';
import { NurseanchorComponent } from './nurseanchor/nurseanchor.component';
import { NursetasksComponent } from './Components/nursetasks/nursetasks.component';
import { NursingreleaseComponent } from './Components/nursingrelease/nursingrelease.component';
import {  CognitiveTestsComponent } from './Components/cognitice-tests/cognitice-tests.component';
import { NursecognitivetestslistComponent } from './Components/nursecognitivetestslist/nursecognitivetestslist.component';
import { NursingfollowupComponent } from './Components/nursingfollowuplist/nursingfollowup/nursingfollowup.component';
import { HistoricfollowupsComponent } from './Components/nursingfollowuplist/historicfollowups/historicfollowups.component';
import { BedsorefollowupshistoryComponent } from './Components/bedsores/subcomponents/bedsorefollowupshistory/bedsorefollowupshistory.component';
import { NursedailyfoodreportlistComponent } from './Components/nursedailyfoodreportlist/nursedailyfoodreportlist.component';
import { NursedailyfoodreportComponent } from './Components/nursedailyfoodreport/nursedailyfoodreport.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NurseingAdmissionLockedComponent } from './Components/nursingadmissionlist/nurseing-admission-locked/nurseing-admission-locked.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NursingmedicationsComponent } from './Components/nursingmedications/nursingmedications.component';
import { DiabeticFootTestComponent } from './examslandingpage/exams/diabetic-foot-test/diabetic-foot-test.component'
import { DiabeticfoottestsubmodelComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/diabeticfoottestsubmodel/diabeticfoottestsubmodel.component'
import { DiabetictestslistComponent } from './examslandingpage/exams/diabetictestslist/diabetictestslist.component'
import { ExternalnurseadmissionComponent } from './Components/nursingadmissionlist/nurseadmission/externalnurseadmission/externalnurseadmission.component';
import { NursingGeneralLandingPageComponent } from './Components/CaretexNusrsingGeneral/nursing-general-landing-page.component';
import { CenrtalizednusrsingfollowupComponent } from './Components/CaretexNusrsingGeneral/cenrtalizednusrsingfollowup/cenrtalizednusrsingfollowup.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OmdanVasFpsPainComponent } from './examslandingpage/exams/omdan-vas-fps-pain/omdan-vas-fps-pain.component';
import { OmdanVasFpsPainmodelComponent } from './Components/nursingadmissionlist/nurseadmission/subcomponents/omdan-vas-fps-painmodel/omdan-vas-fps-painmodel.component';
import { OmdanVasFpsPainListComponent } from './examslandingpage/exams/omdan-vas-fps-pain-list/omdan-vas-fps-pain-list.component';
import { VaccinationsListComponent } from './Components/vaccinations-list/vaccinations-list.component';
import { NursingFamilyVisitsListComponent } from './Components/nusrsingfamilyvistslist/nursingfamilyvisitslist.component';
import { NursefamilyconversationslistComponent } from './Components/nursefamilyconversationslist/nursefamilyconversationslist.component';
import { NursereleaselistComponent } from './Components/nursereleaselist/nursereleaselist.component';
import { DepartmentalPainMonitoringComponent } from './Components/CaretexNusrsingGeneral/departmental-pain-monitoring/departmental-pain-monitoring.component';




@NgModule({
  declarations: [
    NurseadmissionComponent,

    NursingplanComponent,
    NursesummaryComponent,
    PersonalMeasuresComponent,
    NurseLabOrdersComponent,
    ExceptionEventsListComponent,
    BedsoresComponent,
    BohseComponent, 
    Omdan2Component,
    SnaqComponent,
    Omdan4Component,
    FimComponent,
    Omdan5Component,
    NortonComponent,
    BhoseListComponent,
    Omdan2listComponent,
    SnaqlistComponent,
    FimlistComponent,
    Omdan5listComponent,
    NortonlistComponent,
    Omdan4listComponent,
    OmdanemListComponent,
    BhosesubmodelComponent,
    NursingadmissionlistComponent,
    PersonalmeasureslistComponent,
    NurseexaminationslistComponent,
    NursuryphysicalexaminationComponent,
    FimsummodelComponent,
    NortonsubmodelComponent,
    Omdan2submodelComponent,
    SnaqsubmodelComponent,
    Omdan5submodelComponent,
    Omdan4submodelComponent,
    BedSoreTracksListComponent,
    BedSoresListComponent,
    BedsorepictrackComponent,
    NurseanchorComponent,
    NursetasksComponent,
    NursingreleaseComponent,
    CognitiveTestsComponent,
    NursecognitivetestslistComponent,
    NursingfollowupComponent,
    HistoricfollowupsComponent,
    BedsorefollowupshistoryComponent,
    NursedailyfoodreportlistComponent,
    NursedailyfoodreportComponent,
    NurseingAdmissionLockedComponent,
    NursingmedicationsComponent,
    DiabeticFootTestComponent,
    DiabeticfoottestsubmodelComponent,
    DiabetictestslistComponent,
    ExternalnurseadmissionComponent,
    NursingGeneralLandingPageComponent,
    CenrtalizednusrsingfollowupComponent,

    OmdanVasFpsPainComponent,
    OmdanVasFpsPainmodelComponent,
    OmdanVasFpsPainListComponent,
    VaccinationsListComponent,
    NursingFamilyVisitsListComponent,
    NursefamilyconversationslistComponent,
    NursereleaselistComponent,
    DepartmentalPainMonitoringComponent,
 
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    PipesModule,
    ModalModule,
    NusrsingModuleRoutingModule,
    SharedModule,
    DoctorsModule,
    MatExpansionModule,
    MatStepperModule,
    NgxSummernoteModule,
    PdfJsViewerModule,
    NgMultiSelectDropDownModule,

  ],
  exports: [

  ],
  providers: [CaretxNursingProviderService]
})
export class NusrsingModuleModule { }
