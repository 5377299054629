import { Component, OnInit } from '@angular/core';
import { PainMitigatingFactor } from 'src/app/models/generalhousedsetups';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pain-mitigating-factor-setup',
  templateUrl: './pain-mitigating-factor-setup.component.html',
  styleUrls: ['./pain-mitigating-factor-setup.component.css']
})
export class PainMitigatingFactorSetupComponent extends CaretexComponent implements OnInit {

  TabId: string = "main"

  painMitigatingFactors: PainMitigatingFactor[] = []

  currentRowIndex: number
  deleteSysMsgId: string
  deleteAlert: string
  alertTitle: string
  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private router: Router,
    private authService: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {
    super(medicalFileServive)
  }

  ngOnInit(): void {
    this.getPainMitigatingFactorFromDB()
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  BackToList() {
    this.router.navigate(['system_setups']);
  }
  
  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.painMitigatingFactors.some(row => {
      return row.New || row.editMode
    })
    return hospitalsLisChanged
  }

  getPainMitigatingFactorFromDB() {
    this.caretxSetupsProviderService.getPainMitigatingFactor().subscribe(
      data => {
        this.painMitigatingFactors = data
      },
      err => {
        console.log("Error", err)
        this.painMitigatingFactors = []

      }
    )
  }

  addNewRecord() {
    var newPainMitigatingFactor = new PainMitigatingFactor()
    newPainMitigatingFactor.New = true
    newPainMitigatingFactor.editMode = true
    newPainMitigatingFactor.CreatedAt = new Date()
    newPainMitigatingFactor.UpdatedAt = new Date()
    newPainMitigatingFactor.RealmID = this.authService.LoggedInTenantID
    this.painMitigatingFactors.unshift(newPainMitigatingFactor)
  }

  deleteRow(i) {
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.painMitigatingFactors[i].New) {
          this.caretxSetupsProviderService.deletePainMitigatingFactor(this.painMitigatingFactors[this.currentRowIndex]).subscribe({
            next: data => {
              this.painMitigatingFactors.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
          this.painMitigatingFactors.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i) {
    this.painMitigatingFactors[i].editMode = true
    this.painMitigatingFactors[i].Modified = true
  }

  cancelEditRow(i) {
    this.painMitigatingFactors[i].editMode = false;
    this.painMitigatingFactors[i].Modified = false
  }

  updateRecord($event) {
    this.caretxSetupsProviderService.CreateUpdatePainMitigatingFactor(this.painMitigatingFactors).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getPainMitigatingFactorFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }
}