

import { Vaccinations } from './generalhousedsetups'
import { propertyMap } from './modelmapper'
import { User } from './user'




export class PersonalVaccination {


  @propertyMap('ID')
  ID: string
  @propertyMap('CreatedAt')
  CreatedAt: Date
  @propertyMap('UpdatedAt')
  UpdatedAt: Date
  @propertyMap('PersonelID')
  public PersonelID: string
  @propertyMap('RealmID')
  RealmID: string
  @propertyMap('Notes')
  Notes: string
  @propertyMap('VaccinationId')
  VaccinationId: string
  @propertyMap('Vaccination')
  Vaccination: Vaccinations
  @propertyMap('GivenById')
  GivenById: string
  @propertyMap('GivenBy')
  public GivenBy: User
  @propertyMap('DoseNum')
  public DoseNum: Number
  @propertyMap('DoseSerial')
  public DoseSerial: string

  @propertyMap('New')
  public New: boolean
  @propertyMap('Modified')
  public Modified: boolean


  constructor(data?: Object) {

    if (data != undefined && data != null) {
      this.ID = data["ID"]
      this.CreatedAt = data["CreatedAtD"]
      this.UpdatedAt = data["UpdatedAt"]
      this.PersonelID = data["PersonelID"]
      this.RealmID = data["RealmID"]
      this.VaccinationId = data["VaccinationId"]
      this.Vaccination = data["Vaccination"]
      this.GivenById = data["GivenById"]
      this.GivenBy = data["GivenBy"]
      this.DoseNum = data["DoseNum"]
      this.DoseSerial = data["DoseSerial"] 
    }
    else {
      this.ID = undefined
      let currentDate = new Date()
      this.CreatedAt = currentDate
      this.UpdatedAt = currentDate
      this.RealmID = undefined
    
    }
  }

}

