import { Component, OnInit, ViewChild } from '@angular/core';
import { CaretexComponent } from '../../../../shared/CaretexComponent';
import { Title } from '@angular/platform-browser';
import { CaretxMedicalProviderService } from '../../../doctor-services/Medical.data-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { MedicalFileProviderService } from '../../../../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalTreatmentCoordination } from '../../../../models/familytalk';
import { ToasterService } from '../../../../services/toastr.service';
import { MedicalFile } from '../../../../../../src/app/models/customer';
import { ReportingService } from '../../../../../../src/app/services/reporting.service';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-family-talk-locked',
  templateUrl: './family-talk-locked.component.html',
  styleUrls: ['./family-talk-locked.component.css']
})
export class FamilyTalkLockedComponent extends CaretexComponent implements OnInit {
  moduleName: string = "דיון עם המשפחה - רשומה נעולה"
  id: string
  currentOpenedRecord: MedicalTreatmentCoordination
  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  selectedFile: MedicalFile
  pdfContent: any

  constructor(
    private _title: Title,
    private toastrService: ToasterService,
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private router: Router,
    public reportingService: ReportingService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public medicalFileServive: MedicalFileProviderService,) { super(medicalFileServive) }


  ngOnInit(): void {
    this._title.setTitle('Caretex | טופס תיאום טיפול');
    if (this.route.parent != null)
      this.id = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.id)

    this.getFamilyTalkById(this.id, this.route.snapshot.params.talksid, "")
  }



  getFamilyTalkById(id: string, recordId: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + recordId + '\'' + ')'
    this.caretxMedicalProviderService.getFamilyTalks(id, filter, mode).subscribe((data: MedicalTreatmentCoordination[]) => {
      if (data.length == 1) {
        this.currentOpenedRecord = new MedicalTreatmentCoordination(data[0])

      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }

  NewRecord() {

  }

  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/treatmentcoordination/list")
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }


  CheckIfDocumentIsDirty() {
    return false
  }


  openPDFViewer() {
    let reportTitle = "סיכום דיון עם המשפחה"

    this.showGeneratedPdf = true
    this.selectedFile = this.medicalFile1
    var doc = new jsPDF()
    let lastYPosition: number
    let xPosTitle:number = 118

    lastYPosition = this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle)
    this.reportingService.setFooter2(doc, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date())
  

    var label =""
    var label2 = "דו\"\ח שיחה עם משפחת מטופל :" + this.medicalFile1.FirstName + " " + this.medicalFile1.LastName
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,11, "", label2, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"תאריך פגישה", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let meetingDate = this.reportingService.formatDateToMMDDYYY(this.currentOpenedRecord.ConversationDate)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, meetingDate == "" ? "לא צויין" : meetingDate,this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"שעת פגישה",this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let meetingTime = this.reportingService.formatDateToHHmm(this.currentOpenedRecord.ConversationTime)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, meetingTime == "" ? "לא צויין" : meetingTime,this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"יוזם השיחה", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.currentOpenedRecord.Initiator == "" ? "לא צויין" : this.currentOpenedRecord.Initiator, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"מנהל הפגישה", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.currentOpenedRecord.MeetingManager == "" ? "לא צויין" : this.currentOpenedRecord.MeetingManager, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection(doc, lastYPosition+8, "", "משתתפים:", this.authService.LoggedInUser, this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentOpenedRecord.participant1)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"רופא נוסף", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentOpenedRecord.participant2)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"אחות", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentOpenedRecord.participant3)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"המטופל", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentOpenedRecord.participant4)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"עובדת סוציאלית", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentOpenedRecord.participant5)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"בן משפחה", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentOpenedRecord.participant6)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"אחר", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)


    lastYPosition = this.reportingService.AddHeaderSection(doc, lastYPosition+8, "", " האם הדייר מסרב / לא מסוגל לקיים שיחה ?", this.authService.LoggedInUser, this.medicalFile1,reportTitle,xPosTitle)
    let answer1 = "לא"
    if (this.currentOpenedRecord.PatientCanDoTalk)
    answer1 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer1, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)


    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,10, "", "האם משפחתו של הדייר מסרבת לקיים שיחה ?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer0 = "לא"
    if (this.currentOpenedRecord.FamilyCanDoTalk)
    answer0 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer0, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)


    label = "תיאור מצבו של הדייר"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition+5, label, this.currentOpenedRecord.PatientStatusDescription == "" ? "לא צויין" : this.currentOpenedRecord.PatientStatusDescription, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

   
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,10, "", "האם הדייר מוגדר כפליאטיבי ?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer2 = "לא"
    if (this.currentOpenedRecord.IsPalliative)
    answer2 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer2, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"הערות", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, "", this.currentOpenedRecord.PalliativeNotes == "" ? "לא צויין" : this.currentOpenedRecord.PalliativeNotes, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)


    lastYPosition = this.reportingService.AddHeaderSection(doc, lastYPosition+8, "", "האם הדייר נוטה למות עפי חוק הדייר הנוטה למות ?", this.authService.LoggedInUser, this.medicalFile1,reportTitle,xPosTitle)
    let answer3 = "לא"
    if (this.currentOpenedRecord.IsTendToDie)
    answer3 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer3, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"הערות", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, "", this.currentOpenedRecord.TendToDieNotes == "" ? "לא צויין" : this.currentOpenedRecord.TendToDieNotes, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)



    lastYPosition = this.reportingService.AddHeaderSection(doc, lastYPosition+8, "", "האם הוסברו לדייר עקרונות חוק הדייר הנוטה למות ?", this.authService.LoggedInUser, this.medicalFile1,reportTitle,xPosTitle)
    let answer4 = "לא"
    if (this.currentOpenedRecord.PrinciplesExplained)
    answer4 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer4, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"הערות", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, "", this.currentOpenedRecord.PrinciplesExplainedNotes == "" ? "לא צויין" : this.currentOpenedRecord.PrinciplesExplainedNotes, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    label = "הגדרת רמת סבל"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition, label, this.currentOpenedRecord.SufferingLevelDescription == "" ? "לא צויין" : this.currentOpenedRecord.SufferingLevelDescription, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)


    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,10, "", "קיים תצהיר דייר בכתב ?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer5 = "לא"
    if (this.currentOpenedRecord.AffidavitExists)
    answer5 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer5, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    


    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,10, "", "קיימות הנחיות מוקדמות בכתב ?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer6 = "לא"
    if (this.currentOpenedRecord.PrinciplesExplained)
    answer6 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer6, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)


    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,10, "", "קיים מיפוה כח / אפוטרופוס ?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer7 = "לא"
    if (this.currentOpenedRecord.PrinciplesExplained)
    answer7 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer7,this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
 


    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8, 10,"", "האם הוסבר לדייר על מינוי מיופה כח / אפוטרופוס ?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer8 = "לא"
    if (this.currentOpenedRecord.GuardianExplained)
    answer8 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer8, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
 

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8, 10,"", "קיים תצהיר אדם קרוב בכתב ?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer9 = "לא"
    if (this.currentOpenedRecord.ClosePersonAffidavit)
    answer9 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer9,this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
 


    lastYPosition = this.reportingService.AddHeaderSection(doc, lastYPosition+8, "", "למי נמסר המידע ?", this.authService.LoggedInUser, this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentOpenedRecord.InformationGivenTo1)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"המטופל", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentOpenedRecord.InformationGivenTo2)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"בן משפחה", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentOpenedRecord.InformationGivenTo3)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"אפוטרופוס", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentOpenedRecord.InformationGivenTo4)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"אדם קרוב", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    if (this.currentOpenedRecord.InformationGivenTo5)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"אחר", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"הערות", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, "", this.currentOpenedRecord.InformationGivenComment == "" ? "לא צויין" : this.currentOpenedRecord.InformationGivenComment,this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)



    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+13,10, "", "האם להפנות לבית חולים ?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer10 = "לא"
    if (this.currentOpenedRecord.Hospitlize)
    answer10 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer10, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
 

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,10, "", "האם לבצע הנשמה ?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer11 = "לא"
    if (this.currentOpenedRecord.CPR)
    answer11 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer11, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
 

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8, 10,"", "האם לבצע החייאה?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer12 = "לא"
    if (this.currentOpenedRecord.Resuscitation)
    answer12 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer12, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
 


    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8, 10,"", "האם לבצע ניתוחים מצילי חיים?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer13 = "לא"
    if (this.currentOpenedRecord.surgeries)
    answer13 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer13, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
 

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,10, "", "האם לבצע חיבור לדיאליזה?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer14 = "לא"
    if (this.currentOpenedRecord.Dialysis)
    answer14 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer14, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
 

    
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8, 10,"", "כימותרפיה?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer15 = "לא"
    if (this.currentOpenedRecord.Chemotherapy)
    answer15 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer15, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
 

    lastYPosition = this.reportingService.AddHeaderSection(doc, lastYPosition+8, "", "האם לתת מזון ונוזלים באמצעים מלאכותיים?", this.authService.LoggedInUser, this.medicalFile1,reportTitle,xPosTitle)
    let answer16 = "לא"
    if (this.currentOpenedRecord.FluidsMeans)
    answer16 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer16, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
 


    lastYPosition = this.reportingService.AddHeaderSection(doc, lastYPosition+8, "", "האם לתת טיפול באטיביוטיקה?", this.authService.LoggedInUser, this.medicalFile1,reportTitle,xPosTitle)
    let answer17 = "לא"
    if (this.currentOpenedRecord.Antibiotics)
    answer17 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer17, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
 

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,10, "", "האם לתת דם ומוצריו ?", this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    let answer18 = "לא"
    if (this.currentOpenedRecord.AddBlood)
    answer18 = "כן"
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +answer18, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)
 

    label = "פעולות נוספות ?"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition+5, label, this.currentOpenedRecord.AdditionalActions == "" ? "לא צויין" : this.currentOpenedRecord.AdditionalActions, this.currentOpenedRecord.ClosedBy,this.authService.LoggedInUser, this.currentOpenedRecord.ClosedAt,new Date(), this.medicalFile1,reportTitle,xPosTitle)


    let file: Blob = doc.output('blob')
    this.pdfContent = URL.createObjectURL(file);
    this.pdfViewer.pdfSrc = file
    this.pdfViewer.refresh();

  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }




}
