import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Funder } from 'src/app/models/generalhousedsetups';
import { AuthService } from 'src/app/services/auth.service';
import { DialogResult } from 'src/app/services/enums';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-fundersetup',
  templateUrl: './fundersetup.component.html',
  styleUrls: ['./fundersetup.component.css']
})
export class FundersetupComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  funders:Funder[]  = []
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private router: Router,
    private toastrService: ToasterService,) {  super(medicalFileServive)}

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getDepartmentsFromDB()
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.funders.some(row => {
      return row.New || row.Modified
    })
    return  hospitalsLisChanged
  }


  getDepartmentsFromDB(){
    this.caretxSetupsProviderService.GetHouseFunders().subscribe(
      data => {
        this.funders = data
      },
      err => {
        console.log("Error", err)
        this.funders = []

      }
    )
  }



  addNewFunder(){
    var newFunder = new Funder()
    newFunder.New = true
    newFunder.editMode = true
    newFunder.CreatedAt = new Date()
    newFunder.UpdatedAt = new Date()
    newFunder.RealmID = this.authServive.LoggedInTenantID
    this.funders.unshift(newFunder)
  }




  deleteRow(i){

    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.funders[i].New){
          this.caretxSetupsProviderService.DeleteFunder(this.funders[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.funders.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.funders.splice(this.currentRowIndex, 1);
      }
    })
  }





 

  editRow(i){
    this.funders[i].editMode = true
    this.funders[i].Modified = true
  }

  cancelEditRow(i){
    this.funders[i].editMode  = false;
    this.funders[i].Modified = false;
  }

 




  updateFunder($event){

     this.caretxSetupsProviderService.CreateUpdateFunder(this.funders).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getDepartmentsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })  


    
  }
}
