
import { DrugInstruction } from './drugInstruction'
import { propertyMap } from './modelmapper'
import { User } from './user'



export class CardixTask {
    @propertyMap('ID')
    public ID:string
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('InstructionId')
    public InstructionId: string
    @propertyMap('Instruction')
    public Instruction: DrugInstruction
    @propertyMap('DueDate')
    public DueDate: Date
    @propertyMap('Status')
    public Status: string
    @propertyMap('Comment')
    public Comment: string
    @propertyMap('Title')
    public Title: string
    @propertyMap('SubTitle')
    public SubTitle: string
    @propertyMap('CompletedAt')
    public CompletedAt: Date
    @propertyMap('CompletedById')
    public CompletedById: string
    public CompletedBy: User
   
    @propertyMap('PainMeasure')
    public PainMeasure: Number


    constructor(data?: any) {

        if (data != null) {
            this.ID = data.ID   
            this.PersonelID = data.PersonelID
            this.CreatedAt = data.CreatedAt
            this.UpdatedAt = data.UpdatedAt 
            this.TenantID = data.TenantID
            this.InstructionId = data.InstructionId
            this.Instruction = data.Instruction
            this.DueDate = data.DueDate
            this.Status = data.Status
            this.Comment = data.Comment
            this.Title = data.Title
            this.CompletedAt = data.CompletedAt
            this.CompletedById = data.CompletedById
            this.CompletedBy = data.CompletedBy
            this.SubTitle  = data.SubTitle
            this.PainMeasure = data.PainMeasure
        }
        else {
            this.Status = "A"

        }
    }



}

export class HistoricCardixTask {
    @propertyMap('ID')
    public ID:string
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('InstructionId')
    public InstructionId: string
    @propertyMap('Instruction')
    public Instruction: DrugInstruction
    @propertyMap('DueDate')
    public DueDate: Date
    @propertyMap('Status')
    public Status: string
    @propertyMap('Comment')
    public Comment: string
    @propertyMap('Title')
    public Title: string
    @propertyMap('SubTitle')
    public SubTitle: string
    @propertyMap('CompletedAt')
    public CompletedAt: Date
    @propertyMap('CompletedById')
    public CompletedById: string
    public CompletedBy: User
   
    


    constructor(data?: any) {

        if (data != null) {
            this.ID = data.ID   
            this.PersonelID = data.PersonelID
            this.CreatedAt = data.CreatedAt
            this.UpdatedAt = data.UpdatedAt 
            this.TenantID = data.TenantID
            this.InstructionId = data.InstructionId
            this.Instruction = data.Instruction
            this.DueDate = data.DueDate
            this.Status = data.Status
            this.Comment = data.Comment
            this.Title = data.Title
            this.CompletedAt = data.CompletedAt
            this.CompletedById = data.CompletedById
            this.CompletedBy = data.CompletedBy
            this.SubTitle  = data.SubTitle
        }
        else {
            this.Status = "A"

        }
    }



}


export class ReportCardixTask {
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('DrugCode')
    public DrugCode:string
    @propertyMap('ShortTradeName')
    public ShortTradeName:string
    @propertyMap('FirstName')
    public FirstName:Date
    @propertyMap('LastName')
    public LastName: string
    @propertyMap('HMO')
    public HMO: string
    @propertyMap('NumOfRecords')
    public NumOfRecords:number
    @propertyMap('DepartmentName')
    public DepartmentName: string
   
    


    constructor(data?: any) {

        if (data != null) {
            this.PersonelID = data.PersonelID   
            this.DrugCode = data.DrugCode
            this.ShortTradeName = data.ShortTradeName
            this.FirstName = data.FirstName 
            this.LastName = data.LastName
            this.HMO = data.HMO
            this.NumOfRecords = data.NumOfRecords
            this.DepartmentName = data.DepartmentName
        }
        else {

        }
    }



}

