import { Component, OnInit, ViewChild } from '@angular/core';
import { MedicalAdmission } from '../../../../../../src/app/models/medicaladmission';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ReportingService } from '../../../../../../src/app/services/reporting.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CartexReport } from '../../../../../../src/app/models/personeldocument';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { CaretxMedicalProviderService } from '../../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { MatAccordion } from '@angular/material/expansion';
import { FormControl, FormGroup } from '@angular/forms';
import { DocViewerComponent } from '../../../../../../src/app/caretx-shared-module/CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-medical-admission-list',
  templateUrl: './medical-admission-list.component.html',
  styleUrls: ['./medical-admission-list.component.css']
})
export class MedicalAdmissionListComponent extends CaretexComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  id: string
  currentRowIndex: number
  pdfContent: any
  medicalAdmissions: MedicalAdmission[] = []
  moduleName: string = "רשימת קבלות רפואיות"
  totalNumOfRecords: Number = 0

  RecordStatus = [{ id: "A", desc: "קבלה פתוחה" }, { id: "C", desc: "קבלה נעולה" }]

  formData: any[]

  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), //, [Validators.required, Validators.email]),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });

  constructor(private _title: Title,
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private router: Router,
    private route: ActivatedRoute,
    private authServive: AuthService,
    public dialog: MatDialog,
    private reportingService: ReportingService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {
    super(medicalFileServive)
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex | קבלות רפואיות');
    if (this.route.parent != null)
      this.id = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.id)
    this.getMedicalAdmissionsFromDB("")
    this.formData = [
      { "formcontrolname": "status", "label": "סטטוס רשימה", "type": "multi", "value": JSON.stringify(this.RecordStatus), "selectid": "id", "selectval": "desc" },
      { "formcontrolname": "created_at_from", "label": "מתאריך", "type": "date", "value": "", "selectid": "", "selectval": "" },
      { "formcontrolname": "created_at_to", "label": "עד תאריך", "type": "date", "value": "", "selectid": "", "selectval": "" },
      { "formcontrolname": "closed_at_from", "label": "תאריך נעילה מ", "type": "date", "value": "", "selectid": "", "selectval": "" },
      { "formcontrolname": "closed_at_to", "label": "תאריך נעילה עד", "type": "date", "value": "", "selectid": "", "selectval": "" },

    ]
  }

  getMedicalAdmissionsFromDB(filter: string) {
    this.caretxMedicalProviderService.getMedicalAdmissions(filter, this.id, undefined, "list").subscribe(
      data => {
        this.medicalAdmissions = data
        this.totalNumOfRecords = data.length
      },

      err => {
        this.medicalAdmissions = []
      }
    )
  }

  OnSDrillToMedicalAdmission($event, medicalAdmission) {

    if (medicalAdmission.IsExternalAdmission) {
      this.router.navigateByUrl("caretexfile/" + this.id + "/externalmedicaladmission/" + medicalAdmission.ID)
    } else {
      //admissionid/locked
      if (medicalAdmission.Status == "C") {
        this.router.navigateByUrl("caretexfile/" + this.id + "/medicaladmissions/" + medicalAdmission.ID + "/locked")
      } else {
        this.router.navigateByUrl("caretexfile/" + this.id + "/medicaladmissions/" + medicalAdmission.ID)
      }
    }



  }

  generateReport($event, medicalAdmission) {
    var careTexReport = new CartexReport()
    careTexReport.PersonelID = this.id
    careTexReport.ReportName = "socialregistration"
    careTexReport.GeneratedBy = this.authServive.LoggedInUser
    careTexReport.GeneratedOn = new Date()
    const dialogRef = this.dialog.open(DocViewerComponent, {
      data: {
        Type: 'CR',
        PdfReport: careTexReport

      }
    });

  }

  AddNewMedicalAdmission($event) {
    let filter = '(status=\'A\')'
    this.caretxMedicalProviderService.getMedicalAdmissions(filter, this.id, null, "list").subscribe((data: MedicalAdmission[]) => {
      if (data.length > 0) {
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
      else {
        this.router.navigateByUrl("caretexfile/" + this.id + "/medicaladmissions/new")
      }
    });
  }

  deleteRow(i) {
    this.currentRowIndex = i
    if (this.medicalAdmissions[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteAdmission(this.medicalAdmissions[this.currentRowIndex])
        }
      })
    }
  }

  deleteAdmission(admission: MedicalAdmission) {
    let filter = '(id=' + '\'' + admission.ID + '\'' + ')'
    this.caretxMedicalProviderService.deleteAdmission(this.id, filter).subscribe({
      next: data => {
        this.getMedicalAdmissionsFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }

  SearchWithFilter($event) {
    this.getMedicalAdmissionsFromDB($event)
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false;
  }


  AddExistingAdmissionAsPDF() {
    let filter = '(status=\'A\')'
    this.caretxMedicalProviderService.getMedicalAdmissions(this.id, filter, undefined, "list").subscribe((data: MedicalAdmission[]) => {
      if (data.length > 0) {
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
      else {
        this.router.navigateByUrl("caretexfile/" + this.id + "/externalmedicaladmission/new")
      }
    });
  }
}
