import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';
import { ModelMapper } from '../models/modelmapper';
import { NurseAdmission } from '../models/nurseadmission';



@Injectable()
export class AdmissionsService {

  private shareNurseAdmission: NurseAdmission

  getSharedNurseAdmission() {
    return this.shareNurseAdmission
  }

  updateSharedData(data) {
    //Statements 
    this.shareNurseAdmission = data
  }



  constructor(private httpClient: HttpClient) {
  }

  

  getNurseAdmisions(id: string, filter: string): Observable<NurseAdmission[]> {
    return this.httpClient.get(environment.gateway + '/clients/' + id + '/nursedmissions/' + filter).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseAdmission).map(item)
        })
      }), catchError(this.errorHandler));
  }


  updateNurseAdmission(nurseAdmission: NurseAdmission, fieldName: string) {
    return this.httpClient.put(environment.gateway + '/clients/' + nurseAdmission.PersonelID + '/nursedmissions/' + fieldName, nurseAdmission)
  }

  createNewNurseAdmission(newNurseAdmission: NurseAdmission) {
    return this.httpClient.post(environment.gateway + '/clients/' + newNurseAdmission.PersonelID + '/nursedmissions', newNurseAdmission)
  }


  resetNurseAdmissionChangedFlags() {
    this.shareNurseAdmission.backgroundModified = false
    this.shareNurseAdmission.HabitsModified = false
    this.shareNurseAdmission.hobbiesModified = false
    this.shareNurseAdmission.nurseSummaryModified = false
    this.shareNurseAdmission.nursingPlanModified = false
  }


  checkNurseAdmissionChangedFields() {
    if (this.shareNurseAdmission.backgroundModified) {
     // this.shareNurseAdmission.BackgroundLastUpdatedById = "1"
      this.shareNurseAdmission.BackgroundLastUpdated_at = new Date()
    }

    if (this.shareNurseAdmission.HabitsModified) {
    //  this.shareNurseAdmission.HabitsLastUpdatedById = "1"
      this.shareNurseAdmission.HabitsLastUpdated_at = new Date()
    }

    if (this.shareNurseAdmission.hobbiesModified) {
   ///   this.shareNurseAdmission.HobbiesLastUpdatedById = "1"
      this.shareNurseAdmission.HobbiesLastUpdated_at = new Date()
    }
    if (this.shareNurseAdmission.nurseSummaryModified) {
     // this.shareNurseAdmission.NurseSummaryLastUpdatedById = "1"
      this.shareNurseAdmission.NurseSummaryLastUpdated_at = new Date()
    }
    if (this.shareNurseAdmission.nursingPlanModified) {
   //   this.shareNurseAdmission.NursingPlanLastUpdatedById = "1"
      this.shareNurseAdmission.NursingPlanLastUpdated_at = new Date()
    }


  }



  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }





}