import { Component, Inject, Input, OnInit } from '@angular/core';
import { PaintReport, VasFpsPainExam } from 'src/app/models/OmdanPain';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MedicalFile } from 'src/app/models/customer';
import { CaretxNursingProviderService } from 'src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-historyof-omdan-pain',
  templateUrl: './historyof-omdan-pain.component.html',
  styleUrls: ['./historyof-omdan-pain.component.css']
})
export class HistoryofOmdanPainComponent implements OnInit {


  @Input() SelectedFile: MedicalFile

  title: string = "היסטוריה של מבחני כאב של : "
  Records: PaintReport[] = []
  vasfpsOmdanem:VasFpsPainExam[] =[]
  PersonalId:string

  constructor(
    private caretxNursingProviderService: CaretxNursingProviderService,
    private toastrService: ToasterService,
    private dialogRef: MatDialogRef<HistoryofOmdanPainComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any) { dialogRef.disableClose = true; }


    ngOnInit(): void {
      this.SelectedFile = this.data.SelectedFile;
      this.PersonalId = this.SelectedFile.PersonelID
      this.dialogRef.updateSize('80%', '80%');
      this.getOmdansByPersonIdFromDB()
  
    }


    getOmdansByPersonIdFromDB() {
    
      this.caretxNursingProviderService.getVasFpsPainOmdanem(this.PersonalId,"","record").subscribe(
        data => {
          this.vasfpsOmdanem = data
          this.vasfpsOmdanem.forEach(e => {
              let tmp : PaintReport = new PaintReport()
              tmp.PainOmdanForReport = e

              tmp.PainLevelsTxt = "";
              tmp.PainOmdanForReport.PainLevels.forEach(element => {
                tmp.PainLevelsTxt += ' , ' + element.PainLevel.PainLevel + "\n"
              });
          
              tmp.PainLocationTxt = "";
              tmp.PainOmdanForReport.PainLocations.forEach(element => {
                tmp.PainLocationTxt += ' , ' + element.PainLocation.PainLocation + "\n"
              });
          
    
              tmp.PainMitigationTxt = "";
              tmp.PainOmdanForReport.MitigationsFactors.forEach(element => {
                tmp.PainMitigationTxt += ' , '+ element.PainMitigatingFactor.PainMitigatingFactor + "\n"
              });
          
              tmp.PainNatureTxt = "";
              tmp.PainOmdanForReport.PainNatures.forEach(element => {
                tmp.PainNatureTxt += ' , ' + element.PainNature.PainNature + "\n"
              });
          
              tmp.PaintreatmentTxt = "";
              tmp.PainOmdanForReport.Paintreatments.forEach(element => {
                tmp.PaintreatmentTxt += ' , ' + element.Paintreatment.Paintreatment + "\n"
              });
          
              tmp.DrugsTxt = "";
              tmp.PainOmdanForReport.OmdanPainMedications.forEach(element => {
                tmp.DrugsTxt += ' , ' + element.Drug.ShortTradeName + "\n"
              });
          
              tmp.PainSymptomsTxt = "";
              tmp.PainOmdanForReport.PainSymptoms.forEach(element => {
                tmp.PainSymptomsTxt += ' , ' + element.PainSymptom.PainSymptoms + "\n"
              });
      
              

              this.Records.push(tmp)

          })
        },
        err => {
          console.log("Error", err)
          this.vasfpsOmdanem = []
        }
      )
    }



  
}
