import { DietarySupplement, DietaryUomCode, EatingManner, FoodAddition, MealCode, MenuCode, Texture } from './generalhousedsetups'
import { propertyMap } from './modelmapper'
import { User } from './user'


// Need Server to send Payload in below structure
export class DieticianRecommendation {
    @propertyMap('ID')
    ID: string
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('Status')
    Status: string
    @propertyMap('Subject')
    Subject: string
    @propertyMap('Description')
    Description: string
    @propertyMap('DoseUom')
    DoseUom: DietaryUomCode
    @propertyMap('DoseUomId')
    DoseUomId: string
    @propertyMap('MenuCodeId')
    MenuCodeId: string
    @propertyMap('MenuCode')
    MenuCode: MenuCode
    @propertyMap('RecommendationType')
    RecommendationType: string
    @propertyMap('EatingMannerId')
    EatingMannerId: string
    @propertyMap('EatingManner')
    EatingManner: EatingManner
    @propertyMap('Texture')
    Texture: Texture
    @propertyMap('TextureId')
    TextureId: string
    @propertyMap('Dose')
    Dose: number
    @propertyMap('Repeat')
    Repeat: number
    @propertyMap('MealCodeId')
    MealCodeId : string
    @propertyMap('MealCode')
    MealCode : MealCode

    @propertyMap('FoodAdditionId')
    FoodAdditionId : string
    @propertyMap('FoodAddition')
    FoodAddition : FoodAddition
    
    @propertyMap('Comment')
    Comment: string
    @propertyMap('ApproverId')
    ApproverId: string
    @propertyMap('Approver')
    public Approver:User
    @propertyMap('ApproverComment')
    public ApproverComment:string
    @propertyMap('ApprovalDate')
    public ApprovalDate:Date
    @propertyMap('StartDate')
    public StartDate:Date
    @propertyMap('EndDate')
    public EndDate:Date
    @propertyMap('LastUpdateById')
    public LastUpdateById: string
    public LastUpdateBy: User
    @propertyMap('DietarySupplementId')
    DietarySupplementId :string
    @propertyMap('DietarySupplement')
    DietarySupplement :DietarySupplement

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.Status = data["Status"]
            this.Subject = data["Subject"]
            this.Description = data["Description"]
            this.EatingMannerId = data["EatingMannerId"]
            this.EatingManner = data["EatingManner"]
            this.TextureId = data["TextureId"]
            this.DietarySupplementId = data["DietarySupplementId"]
            this.DietarySupplement = data["DietarySupplement"]
            this.MenuCodeId = data["MenuCodeId"]
            this.MenuCode = data["MenuCode"]
            this.DoseUom = data["DoseUom"]
            this.DoseUomId = data["DoseUomId"]
            this.RecommendationType = data["RecommendationType"]


            this.FoodAdditionId = data["FoodAddition"]
            this.FoodAddition = data["FoodAddition"]
            this.Texture = data["Texture"]
            this.Dose = data["Dose"]
            this.Repeat = data["Repeat"]
            this.MealCodeId = data["MealCodeId"]
            this.MealCode = data["MealCode"]
            this.ApproverId = data["ApproverId"]
            this.Approver = data["Approver"]
            this.ApproverComment = data["ApproverComment"]
            this.ApprovalDate = data["ApprovalDate"]
            this.StartDate = data["StartDate"]
            this.EndDate = data["EndDate"]
            this.Comment = data["Comment"]
            this.LastUpdateById = data["LastUpdateById"]
            this.LastUpdateBy = data["LastUpdateBy"]
        }
        else {
            this.ID = "00000000-0000-0000-0000-000000000000"
            this.Status = 'A'
        }
    }
}