export enum CaretexEventTypes {
    Save = "S",
    Tab = "T",
    New = "N",
  }


export class TabHistory {
    public CurrentTab: string
    public NextTab: string
}

export class CartexEvent {

    public EventType:CaretexEventTypes
    public EventData:any
}