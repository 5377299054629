import { Attachment } from './attachments'
import { PersonelSensitivity } from './drugsenstivity'
import { propertyMap } from './modelmapper'
import { User } from './user'


// Need Server to send Payload in below structure
export class DieticianFollowUp {
    @propertyMap('ID')
    ID: Number
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('RichText')
    RichText: string
    @propertyMap('Status')
    Status: string
    @propertyMap('LastUpdateById')
    public LastUpdateById: string
    public LastUpdateBy: User
    @propertyMap('NewBmiMeasure')
    public NewBmiMeasure: BMIMeasure
    @propertyMap('BmiMeasures')
    public BmiMeasures: BMIMeasure[]

    @propertyMap('PersonSensitivity')
    public PersonSensitivity: PersonelSensitivity
    @propertyMap('NextTrackDate')
    public NextTrackDate: Date
    @propertyMap('DieticianObservationFiles')
    public DieticianObservationFiles: Attachment[]

    @propertyMap('FollowUpSubject')
    FollowUpSubject: string

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.FollowUpSubject = data["FollowUpSubject"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.RichText = data["RichText"]
            this.LastUpdateById = data["LastUpdateById"]
            this.LastUpdateBy = data["LastUpdateBy"]
            this.Status = data["Status"] 
            this.BmiMeasures = data["BmiMeasures"]
            this.NewBmiMeasure = data["NewBmiMeasure"]
            this.PersonSensitivity = data["PersonSensitivity"]
            this.NextTrackDate = data["NextTrackDate"]
            this.DieticianObservationFiles = data["DieticianObservationFiles"]
        }
        else {
            this.Status = 'A'
        }
    }
}

export class BMIMeasure {
    @propertyMap('ID')
    ID: Number
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('Height')
    Height: number
    @propertyMap('Weight')
    Weight: number
    @propertyMap('BMI')
    BMI: number

    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.Height = data["Height"]
            this.Weight = data["Weight"]
            this.CreatedById = data["CreatedById"]
            this.CreatedBy = data["CreatedBy"] 
            this.BMI = data["BMI"]
        }

    }






}

export interface IMonthlyBmiMeasure{
    name:string;
    Bmi: number;
    Weight: number;
}