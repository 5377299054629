
import { MedicalFile } from './customer'
import { Drug } from './drug'
import { DrugUom } from './generalhousedsetups'
import { propertyMap } from './modelmapper'
import { User } from './user'


// Need Server to send Payload in below structure

export class MedicalPrescriptionHeader {
    @propertyMap('ID')
    public ID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('PersonDoc')
    public PersonDoc: MedicalFile

    @propertyMap('Status')
    public Status: string
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('DoctorId')
    DoctorId: string
    @propertyMap('Doctor')
    Doctor: User
    @propertyMap('PrescriptionLines')
    PrescriptionLines: MedicalPrescriptionLine[]
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('Closed_at')
    public Closed_at: Date
    @propertyMap('LastUpdatedById')
    public LastUpdatedById: string
    public LastUpdatedBy: User

    constructor(data?: any) {

        if (data != null) {
            this.ID = data.ID   
            this.PersonelID = data.PersonelID
            this.Status = data.Status 
            this.TenantID = data.TenantID
            this.CreatedAt = data.CreatedAt
            this.UpdatedAt = data.UpdatedAt
            this.Closed_at = data.Closed_at
            this.LastUpdatedById = data.LastUpdatedById
            this.LastUpdatedBy = data.LastUpdatedBy
            this.PrescriptionLines = data.PrescriptionLines
            this.PersonDoc = data.PersonDoc
     
                   
        }
        
        else {
            this.Status = "A"
            this.UpdatedAt = new Date()
            this.CreatedAt = new Date()
            this.PrescriptionLines  = []
        }
    }





}


export class MedicalPrescriptionLine {
    @propertyMap('ID')
    public ID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date

    @propertyMap('drugId')
    drugId: string
    @propertyMap('Drug')
    Drug: Drug
    @propertyMap('Dose')
    Dose:Number
    @propertyMap('DoctorId')
    DoctorId: string
    @propertyMap('Doctor')
    Doctor: User
    @propertyMap('QtyToOrder')
    QtyToOrder: Number
    @propertyMap('DrugFormId')
    DrugFormId: string
    @propertyMap('DrugForm')
    DrugForm: DrugUom
    @propertyMap('QtyOfServe')
    QtyOfServe: Number
    @propertyMap('Frequency')
    Frequency: Number
    @propertyMap('ForPeriodOf')
    ForPeriodOf: Number

    @propertyMap('CreatedById')
    CreatedById: string
    @propertyMap('CreatedBy')
    CreatedBy: User
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    
}















