import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Hazard, HazardRisk, HazardType } from 'src/app/models/hazard';
import { AuthService } from 'src/app/services/auth.service';
import { DialogResult } from 'src/app/services/enums';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-hazards-settings',
  templateUrl: './hazards-settings.component.html',
  styleUrls: ['./hazards-settings.component.css']
})
export class HazardsSettingsComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  hazards : Hazard[] = []
  hazardsTypes: HazardType[] = []
  hazardsRisks: HazardRisk[] = []
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle:string
  selectedColor = '';
  colors = [
    {
      name: 'yellow',
      value: '#FFDD33'
    },
    {
      name: 'red',
      value: '#ff3300'
    },
    {
      name: 'orange',
      value: '#FF5733'
    }
  ];

  constructor( private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) { 
      super(medicalFileServive)
    this.caretxSetupsProviderService.GetHazardTypes().subscribe((data => {
      this.hazardsTypes = data
    }))

    this.caretxSetupsProviderService.GetHazardRisks().subscribe((data => {
      this.hazardsRisks = data
    }))
  }

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getHazardFromDB()
  }

  
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }
  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.hazards.some(row => {
      return row.isNew || row.editMode
    })
    return  hospitalsLisChanged
  }

  getHazardFromDB() {
    this.caretxSetupsProviderService.GetHazards().subscribe(
      data => {
        this.hazards = data
      },
      err => {
        console.log("Error", err)
        this.hazards = []

      }
    )
  }


  updateHazard($event){
    this.caretxSetupsProviderService.CreateHazards(this.hazards).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getHazardFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  addNewHazard(){ 
    var newHazard = new Hazard()
    newHazard.isNew = true
    newHazard.editMode = true
    newHazard.CreatedAt = new Date()
    newHazard.UpdatedAt = new Date()
    newHazard.RealmID = this.authServive.LoggedInTenantID
    this.hazards.unshift(newHazard)
  }




  deleteRow(i){

    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.hazards[i].isNew){
          this.caretxSetupsProviderService.DeleteHazard(this.hazards[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.hazards.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.hazards.splice(this.currentRowIndex, 1);
      }
    })
  }









 
  editRow(i){
    this.hazards[i].editMode = true
  }
  cancelEditRow(i){
    this.hazards[i].editMode = false
  }


  UpdateTypeId(index:number , hazard:Hazard){
    this.hazards[index].TypeId = hazard.Type.ID
  }
  UpdateRiskId(index:number , hazard:Hazard){
    this.hazards[index].RiskId = hazard.Risk.ID
  }
}
