import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CartexEvent } from '../../../../../src/app/models/genericTypes';

import { NurseCognitiveTest } from '../../../../../src/app/models/nurseexamination';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { NurseTest } from '../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxNursingProviderService } from '../../nursing-services/Nursing.data-provider.service';

@Component({
  selector: 'app-cognitice-tests',
  templateUrl: './cognitice-tests.component.html',
  styleUrls: ['./cognitice-tests.component.css']
})
export class CognitiveTestsComponent extends CaretexComponent implements OnInit {

  moduleName: string = "תיפקוד נפשי/קוגניטיבי"
  isdocumentClosed: boolean
  nurseCognitiveTest: NurseCognitiveTest
  saveProcessCompleted: boolean
  personalId: string
  recordid: string
  CurrentStep: NurseTest = NurseTest.Main
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  moduleCognitiveExaminationchanged: boolean = false
  private eventsSubscription: Subscription;



  constructor(
    private caretxNursingProviderService: CaretxNursingProviderService,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private router: Router,
    private route: ActivatedRoute) {
    super(medicalFileService)

  }

  ngOnInit(): void {
    if (this.route.parent != null)
      this.personalId = this.route.parent.snapshot.params['id'];
    this.recordid = this.route.snapshot.params.recordid

    this.CheckIfComponentIsDisabled(this.personalId)
    if (this.recordid == 'new') {
      this.nurseCognitiveTest = new NurseCognitiveTest()
      this.nurseCognitiveTest.PersonelID = this.personalId
      this.nurseCognitiveTest.TenantID = this.authServive.LoggedInTenantID
    } else {
      this.getNurseCognitiveTestById(this.personalId, this.recordid)
    }
  }


  getNurseCognitiveTestById(id: string, recordId: string) {
    let filter = '(id=' + '\'' + recordId + '\'' + ')'
    this.caretxNursingProviderService.getAllNurseCognitiveTests(id, filter).subscribe((data: NurseCognitiveTest[]) => {
      if (data.length == 1) {
        this.nurseCognitiveTest = new NurseCognitiveTest(data[0])
        this.isdocumentClosed = (this.nurseCognitiveTest.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }


  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/cognitivetest/list")
  }

  saveRecord() {
    this.nurseCognitiveTest.LastUpdatedById = this.authServive.LoggedInUser.UID
    if (this.nurseCognitiveTest.ID == "" || this.nurseCognitiveTest.ID == null) {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    this.caretxNursingProviderService.createNurseCognitiveTest(this.nurseCognitiveTest).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.moduleCognitiveExaminationchanged = false
        this.nurseCognitiveTest = data as NurseCognitiveTest
        if (this.nurseCognitiveTest.Status == 'C')
          this.isdocumentClosed = true
        this.saveProcessCompleted = true;
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.personalId + "/cognitivetest/" + this.nurseCognitiveTest.ID)
      },
      error: error => {
        this.toastrService.Error("", error.error)
        this.saveProcessCompleted = true;
      }
    })
  }

  DoUpdate() {
    this.caretxNursingProviderService.updateNurseCognitiveTest(this.nurseCognitiveTest).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.moduleCognitiveExaminationchanged = false
        this.nurseCognitiveTest = data as NurseCognitiveTest
        if (this.nurseCognitiveTest.Status == 'C')
          this.isdocumentClosed = true
        this.saveProcessCompleted = true;
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.personalId + "/cognitivetest/" + this.nurseCognitiveTest.ID)

      },
      error: error => {
        this.toastrService.Error("", error.error)
        this.saveProcessCompleted = true;
      }
    })
  }

  LockDocument() {

    Swal.fire({
      title: "הודעת מערכת",
      text: "האם את/ה רוצה לנעול מבחן ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תנעל',
      cancelButtonText: "לא",
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.nurseCognitiveTest.Status = "C"
        this.nurseCognitiveTest.LockedAt = new Date()
        this.nurseCognitiveTest.LockedById = this.authServive.LoggedInUser.UID
        this.nurseCognitiveTest.LockedBy = this.authServive.LoggedInUser
        this.saveRecord()
      }
    })


  }



  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.moduleCognitiveExaminationchanged
  }

  selectTab(tabId: string) {
    this.CurrentStep = (tabId as NurseTest)
  }

  ModuledChanged($event) {
    this.moduleCognitiveExaminationchanged = $event
  }
}
