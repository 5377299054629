import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { FamilyRelations } from '../../../app/models/generalhousedsetups';
import { SocialHelpReport } from '../../../app/models/SocialHelpReport';
import { AuthService } from '../../../app/services/auth.service';
import { ToasterService } from '../../../app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSocialProviderService } from '../Social.data-provider.service';
import { CaretexComponent } from '../../../app/shared/CaretexComponent';
import { MedicalFile } from '../../../app/models/customer';
import { PaitentFamily } from '../../../app/models/socialregistration';
import { CartexReport } from '../../../app/models/personeldocument';
import { ReportingService } from '../../../app/services/reporting.service';
import { CartexEvent, TabHistory } from '../../../app/models/genericTypes'
import { DocViewerComponent } from '../../../../src/app/caretx-shared-module/CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-social-report-for-helping-oldman',
  templateUrl: './social-report-for-helping-oldman.html',
  styleUrls: ['./social-report-for-helping-oldman.css']
})




export class SocialReportForHelpingOldmanComponent extends CaretexComponent implements OnInit {

  @ViewChild('mainForm') mainFormElement: NgForm;
  //CurrentStep: SocialHelpReportcodes = SocialHelpReportcodes.General
  CurrentSocialHelpReport: SocialHelpReport 
  medicalFile: MedicalFile = new MedicalFile()
  currentRowIndex:number

  moduleName:string = "מתן שירותים לזקן"
  isrecordClosed:boolean = false
  isNew:boolean = false
  familyRelations: FamilyRelations[] = []
  pdfContent: any
  tabHistory: TabHistory = new TabHistory()
  caretexEvent: CartexEvent = new CartexEvent()
  step: number = 0
  pdfFilePath = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  constructor(
    private _title: Title,
    private router: Router,
    private caretxSetupService: CaretxSetupsProviderService,
    public medicalFileService: MedicalFileProviderService,
    private route: ActivatedRoute,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private _ar: ActivatedRoute,
    private reportsService:ReportingService,
    private toastrService: ToasterService,
    private authServive: AuthService,
    public dialog: MatDialog,
    private caretxSetupsProviderService: CaretxSetupsProviderService) {
      super(medicalFileService)
      this.caretxSetupService.GetFamilyRelations().subscribe((data => {
        this.familyRelations = data
      }))
    }

  ngOnInit(): void {
    this._title.setTitle('Caretex |טופס קבלה סוציאלית');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id) 
    if (this.route.snapshot.params['tabid'] != "" && this.route.snapshot.params['tabid'] != undefined) {
      this.step = Number(this.route.snapshot.params['tabid']);

    }
    this.getSocialHelpReportFromDB()
  }

  getSocialHelpReportFromDB() {
    this.caretxSocialProviderService.getSocialHelpReport(this.id, "").subscribe((data: SocialHelpReport[]) => {
      if (data.length == 1) {
        this.CurrentSocialHelpReport = new SocialHelpReport(data[0])

      }
      if (data.length == 0) {
        this.CurrentSocialHelpReport = new SocialHelpReport()
        this.CurrentSocialHelpReport.PersonDoc = this.medicalFileService.currentSelectedFile
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
      }

    });
  }


  setStep(index: number) {
    this.step = index;
  }



  Save() {
    if (this.CurrentSocialHelpReport.ID == '00000000-0000-0000-0000-000000000000') {
      this.DoSave()
    } else {
      this.DoUpdate()
    }

  }

  DoUpdate() {
    this.CurrentSocialHelpReport.UpdatedAt = new Date()
    this.CurrentSocialHelpReport.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.UpdateSocialHelpReport(this.CurrentSocialHelpReport).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.CurrentSocialHelpReport = new SocialHelpReport(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';

        this.router.navigateByUrl("caretexfile/" + this.id + "/socialhelpreport"+ "/step/" + this.step)
        this. getSocialHelpReportFromDB()
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  DoSave() {
    this.CurrentSocialHelpReport.UpdatedAt = new Date()
    this.CurrentSocialHelpReport.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.createSocialHelpReport(this.CurrentSocialHelpReport).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.CurrentSocialHelpReport = new SocialHelpReport(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialhelpreport"+ "/step/" + this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

 


  deleteRow(type, i) {
    this.currentRowIndex = i
    let isNew: boolean = false

    if (!isNew) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,       
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.handleDeleteFamilyMember(type)
        }
      })
    }
    else {
      switch (type) {
        case 'A':
          this.CurrentSocialHelpReport.FamilyOfPaitent.splice(i, 1);
          break
        case 'B':
          this.CurrentSocialHelpReport.FamilyOfPaitent.splice(i, 1);
          break

        default:
          break
      }
    }
  }

  handleDeleteFamilyMember(type) {
    let familyMember : PaitentFamily 
    familyMember =  this.CurrentSocialHelpReport.FamilyOfPaitent[this.currentRowIndex]
    this.caretxSocialProviderService.deleteFamilyMember(this.id, familyMember).subscribe({
      next: data => {
        this.CurrentSocialHelpReport.FamilyOfPaitent.splice(this.currentRowIndex, 1);
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  editRow(type, i) {
    this.CurrentSocialHelpReport.FamilyOfPaitent[i].editMode = true;
    this.CurrentSocialHelpReport.FamilyOfPaitent[i].New = false
    this.CurrentSocialHelpReport.FamilyOfPaitent[i].Modified = true
   
  }

  cancelEditRow(type, i) {
    this.CurrentSocialHelpReport.FamilyOfPaitent[i].editMode = false;
    this.CurrentSocialHelpReport.FamilyOfPaitent[i].New = false
    this.CurrentSocialHelpReport.FamilyOfPaitent[i].Modified = false

  }

  addNewFamilyMember(type: string) {
    var newFamilyMember = new PaitentFamily()
    newFamilyMember.PersonelID = this.id
    newFamilyMember.TenantID = this.authServive.LoggedInTenantID
    newFamilyMember.editMode = true;
    newFamilyMember.CreatedAt = new Date()
    newFamilyMember.New = true
    newFamilyMember.Modified = false

    switch (type) {
      case 'A':
        newFamilyMember.LivesWithPatient = true
        this.CurrentSocialHelpReport.FamilyOfPaitent.unshift(newFamilyMember)
        break
      case 'B':
        newFamilyMember.LivesWithPatient = false
        this.CurrentSocialHelpReport.FamilyOfPaitent.unshift(newFamilyMember)
        break

      default:
        break
    }



  }

  PrintReport(){
    var careTexReport =  new CartexReport()
    careTexReport.PersonelID = this.id
    careTexReport.ReportName = "Satis"
    careTexReport.ReportType ="socila_helpreport"
    careTexReport.ReportParam = ""
    careTexReport.GeneratedBy  = this.authServive.LoggedInUser
    careTexReport.GeneratedOn =  new Date()

    const dialogRef = this.dialog.open(DocViewerComponent, {
      data: {
        Type:'CR',
        PdfReport: careTexReport
      
      }
    });
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty 
  }
}
