import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxNursingProviderService } from '../../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { BaseOmdan, OmdanPossibleAnswer, OmdanQuestionAnswer, OmdanQuestions } from '../../../../../../src/app/models/omdans';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import jsPDF from 'jspdf';
import { MedicalFile } from '../../../../../../src/app/models/customer';
import { ReportingService } from '../../../../../../src/app/services/reporting.service';
import autoTable, { RowInput } from 'jspdf-autotable';


@Component({
  selector: 'app-clock-exam',
  templateUrl: './clock-exam.component.html',
  styleUrls: ['./clock-exam.component.css']
})
export class ClockExamComponent extends CaretexComponent implements OnInit {

  modalTarget: string
  omdanName: string = "omdanclock"
  questions: OmdanQuestions[] = []
  id: string
  isomdanClosed: boolean = false;
  omdanScore: number = 0
  scorepercentage: number = 0
  moduleName: string = "מבחן השעון"
  @ViewChild('mainform') mainFormElement: NgForm;
  selectedFile: MedicalFile
  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  pdfContent: any


  omdanBase: BaseOmdan
  OmdanId: string

  constructor(
    private authService: AuthService,
    public medicalFileService: MedicalFileProviderService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private route: ActivatedRoute,
    public reportingService: ReportingService,
    private router: Router,
    private toastrService: ToasterService,) {
      super(medicalFileService)
  }

  ngOnInit(): void {
    if (this.route.parent != null)
      this.id = this.route.parent.snapshot.params['id']
      this.CheckIfComponentIsDisabled(this.id)
    if (this.route.snapshot.params.clockid != "new")
      this.getOmdanFromDB(this.route.snapshot.params.clockid)
    else {
      this.newOmdan("list")
    }
  }

  newOmdan(origin: string) {
    this.omdanBase = new BaseOmdan()
    let dateOfNow: Date = new Date();
    this.omdanBase.CreatedAt = dateOfNow
    this.omdanBase.UpdatedAt = dateOfNow
    this.omdanBase.OmdanChangesComittedToDB = false
    this.omdanBase.OmdanStatus = "A"
    this.omdanBase.PersonelID = this.id
    this.omdanBase.Origin = origin
    this.omdanBase.RealmID = this.authService.LoggedInTenantID
    this.getOmdanQuestions()
  }

  getOmdanFromDB(omdanId: string) {
    let filter = '(id=' + '\'' + omdanId + '\'' + ')'
    this.caretxNursingProviderService.getOmdanem(this.id, filter, "omdanclock").subscribe((data: BaseOmdan[]) => {
      if (data.length == 1) {
        this.omdanBase = data[0]
        this.isomdanClosed = (this.omdanBase.OmdanStatus == "C")
        this.omdanScore = this.omdanBase.Score
        this.scorepercentage = ((this.omdanScore * 100) / 12)
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  getOmdanQuestions() {
    let filter = '(omdan_type=' + '\'' + this.omdanName + '\'' + ')'
    this.caretxNursingProviderService.getOmdanQuestions(this.id, filter).subscribe((data: OmdanQuestions[]) => {
      this.questions = data
      this.generateSetOfQuestionsAnswers()
    });
  }

  SaveOmdan() {
    if (this.omdanBase.ID == "" || this.omdanBase.ID == null || this.omdanBase.ID == undefined) {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    this.caretxNursingProviderService.CreateOmdan(this.omdanBase, "omdanclock").subscribe({
      next: data => {
        this.omdanBase = (data as BaseOmdan)
        this.isomdanClosed = (this.omdanBase.OmdanStatus == "C")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.calculateScore()
     
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams/omdanclock/" + this.omdanBase.ID )
     

      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  DoUpdate() {
    this.caretxNursingProviderService.UpdateOmdan(this.omdanBase, "omdanclock").subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.omdanBase = data as BaseOmdan
        if (this.omdanBase.OmdanStatus == 'C')
          this.isomdanClosed = true
        this.calculateScore()
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams/omdanclock/" + this.omdanBase.ID )
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  generateSetOfQuestionsAnswers() {
    let index: number = 0
    this.omdanBase.OmdanQuestionAnswers = []
    this.questions.forEach(element => {
      var obj = new OmdanQuestionAnswer()
      obj.Question = element
      obj.QuestionId = element.ID
      obj.CreatedAt = new Date()
      obj.UpdatedAt = new Date()
      obj.Answer = new OmdanPossibleAnswer()
      this.omdanBase.OmdanQuestionAnswers.push(obj);
      ++index;
    });
  }

  onChange($event, questionAnswer) {
    questionAnswer.Question.PossibleAnswers.forEach(possibleAnswer => {
      if (possibleAnswer.ID == $event) {
        questionAnswer.Answer = possibleAnswer
        questionAnswer.AnswerId = possibleAnswer.ID
      }
    });
    this.calculateScore()
  }

  calculateScore() {
    if (this.omdanBase != undefined && this.omdanBase.OmdanQuestionAnswers != undefined) {
      this.omdanScore = 0;
      this.omdanBase.OmdanQuestionAnswers.forEach(answer => {
        if (answer.Answer.AnswerValue != undefined && answer.Answer.AnswerValue != null)
          this.omdanScore += answer.Answer.AnswerValue
      });
      this.omdanBase.Score = this.omdanScore
      this.scorepercentage = ((this.omdanScore * 100) / 12)
    }
  }

  CloseOmdan() {
    if (this.checkIfAllQuestionsAnswered()) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "לא עניתה על כל השאלות ?",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    }
    else {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה לנעול מבחן זה ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תנעל',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.omdanBase.OmdanStatus = "C"
          this.omdanBase.LockedById = this.authService.LoggedInUser.UID
          this.omdanBase.LockedDate = new Date()
          this.SaveOmdan()
        }
      })
    }
  }

  // Check if Omdan questions are answered 
  checkIfAllQuestionsAnswered() {
    const res = this.omdanBase.OmdanQuestionAnswers.some(answer =>
      (answer.AnswerId == undefined || answer.AnswerId == null || answer.AnswerId == '00000000-0000-0000-0000-000000000000'))
    return res;
  }

  RetrunToList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams/omdanclock/list")
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false;
  }

  /********************************************************************************************** */
  openPDFViewer() {
    let reportTitle = "סיכום מבחן שעון"

    this.showGeneratedPdf = true
    this.selectedFile = this.medicalFile1
    var doc = new jsPDF()
    let lastYPosition: number
    let xPosTitle: number = 125

    lastYPosition = this.reportingService.SetHeader(doc, this.medicalFile1, reportTitle, xPosTitle)
    this.reportingService.setFooter2(doc, this.omdanBase.LockedBy, this.authService.LoggedInUser, this.omdanBase.LockedDate, new Date())

    let firstTableRows: RowInput[] = []
    for (let i = 0; i <= this.omdanBase.OmdanQuestionAnswers.length-1 ; i++) {
      let tmpRowInput: RowInput = []
      let answer = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Answer.Answer)
      let question = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Question.Question)
      tmpRowInput = [answer, question]
      firstTableRows.push(tmpRowInput)
    }

    doc.setDrawColor(0);
    doc.setFillColor(0, 0, 0);
    doc.rect(10, 33, 190, 12, 'D'); //Fill and Border
    doc.setFontSize(9);
   
    doc.setFont("NotoSansHebrew-SemiBold");
    doc.text(this.reportingService.reverseString("ציון(נק)"), 190, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
    doc.setFont("NotoSansHebrew-Regular");
    doc.text(this.reportingService.reverseString(this.omdanScore.toString()), 175, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });

    doc.setFont("NotoSansHebrew-SemiBold");
    doc.text(this.reportingService.reverseString("ציון מקסימאלי"), 155, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
    doc.setFont("NotoSansHebrew-Regular");
    doc.text(this.reportingService.reverseString('03'), 130, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });

   
    autoTable(doc, {
      styles: {
        halign: "right",
        font: 'NotoSansHebrew-Regular',
        fontStyle: 'normal',
        fontSize: 9
      }, 
      willDrawPage: () => {
        this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle),
        this.reportingService.setFooter2(doc, this.omdanBase.LockedBy, this.authService.LoggedInUser,this.omdanBase.LockedDate,new Date())
        this.resultRect(doc)
      },
      margin: { bottom: 27, top: 36 },
      startY: 50,
      head: [[this.reportingService.reverseString('תשובה'), this.reportingService.reverseString('שאלה')]],
      body: firstTableRows
    });

    lastYPosition =  (doc as any).previousAutoTable.finalY
    let label = "הערות נוספות"
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition+5, label, this.omdanBase.NotesTxt == "" ? "לא צויין" : this.omdanBase.NotesTxt,this.omdanBase.LockedBy, this.authService.LoggedInUser,this.omdanBase.LockedDate,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    let file: Blob = doc.output('blob')
    this.pdfContent = URL.createObjectURL(file);
    this.pdfViewer.pdfSrc = file
    this.pdfViewer.refresh();
  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }


  resultRect(doc:jsPDF){ 
    doc.setDrawColor(0);
    doc.setFillColor(0, 0, 0);
    doc.rect(10, 33, 190, 12, 'D'); //Fill and Border
    doc.setFontSize(9);
    doc.setFont("NotoSansHebrew-SemiBold");
    doc.text(this.reportingService.reverseString("ציון(נק)"), 190, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
    doc.setFont("NotoSansHebrew-Regular");
    doc.text(this.reportingService.reverseString(this.omdanScore.toString()), 175, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
    doc.setFont("NotoSansHebrew-SemiBold");
    doc.text(this.reportingService.reverseString("ציון מקסימאלי"), 155, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
    doc.setFont("NotoSansHebrew-Regular");
    doc.text(this.reportingService.reverseString('03'), 130, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });

  }
}
