import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { CaretxSharedModulesProviderService } from '../../../../../src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { Attachment } from '../../../../../src/app/models/attachments';
import { BedSore, BedSoreHelpItem, BedSoreTracks, BedSoreTreatment } from '../../../../../src/app/models/bedsore';
import { BedSoreLocation, BedSoreSide, BedSoreStatus, BedSoreType } from '../../../../../src/app/models/generalhousedsetups';
import { User } from '../../../../../src/app/models/user';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { OptionObject, UserRoles } from '../../../../../src/app/services/enums';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxNursingProviderService } from '../../nursing-services/Nursing.data-provider.service';
import { PagenationObject } from '../../../../../src/app/models/pagenation';
import { NotificationMap } from '../../../models/notificationMap';
import { NotificationsSSEService } from '../../../services/notifications-sse.service';
import { HelperitemDialogComponent } from '../../../../../src/app/caretx-shared-module/CaretexModals/helperitem-dialog/helperitem-dialog.component';
import { UserselectionDialogComponent } from '../../../../../src/app/caretx-shared-module/CaretexModals/userselection-dialog/userselection-dialog.component';
import { CameraDialogComponent } from '../../../../../src/app/caretx-shared-module/CaretexModals/camera-dialog/camera-dialog.component';
import { MedicalFile } from '../../../../../src/app/models/customer';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-bedsores',
  templateUrl: './bedsores.component.html',
  styleUrls: ['./bedsores.component.css']
})
export class BedsoresComponent extends CaretexComponent implements OnInit {

  @ViewChild('myEditor') myEditor: any;
  @ViewChild('myModalClose') modalClose;



  bedsoretypes: BedSoreType[] = []
  bedsorelocations: BedSoreLocation[] = []
  bedsorestatuses: BedSoreStatus[] = []
  bedsoresides: BedSoreSide[] = []
  moduleName: string = "פצע לחץ"
  pagenationObject: PagenationObject
  public Bedsoresides: OptionObject[]

  public currentBedSore: BedSore
  public currentBedSoreTrack: BedSoreTracks
  id: string
  isrecordClosed: boolean = false
  isClosed: boolean = false
  isNewRecord:boolean = false
  usersFormTitle: string = "בחר רופא מטפל"
  userRole: UserRoles = UserRoles.Doctor
  public showWebcam: boolean = false
  alertUsers: User[]
  newTreatment: BedSoreTreatment = new BedSoreTreatment()
  currentRowIndex: number
  @ViewChild('f') mainFormElement: NgForm;
  GoToNextTabOnSuccess: boolean = false
  BedSoreTracks: BedSoreTracks[] = []
  showTxtSideField : boolean = false
  step: number

  @Output() backToCentralPage = new EventEmitter<void>();
  @Input() PersonalId: string
  @Input() IsFromCentralizedPage: boolean = false
  @Input() SelectedFile: MedicalFile
  @Input() BedSoreId: string


  constructor(
    private authServive: AuthService,
    public dialog: MatDialog,
    private notificationService: NotificationsSSEService,
    public medicalFileService: MedicalFileProviderService,
    private router: Router,
    private caretxSetupService: CaretxSetupsProviderService,
    private sharedServices: CaretxSharedModulesProviderService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,) {
    super(medicalFileService)


    this.caretxSetupService.GetBedSoreTypes(this.authServive.LoggedInTenantID).subscribe((data => {
      this.bedsoretypes = data
    }))
    this.caretxSetupService.GetBedSoreLocations(this.authServive.LoggedInTenantID).subscribe((data => {
      this.bedsorelocations = data
    }))

    this.caretxSetupService.GetBedSoreStatuses().subscribe((data => {
      this.bedsorestatuses = data
    }))

    this.caretxSetupService.GetBedSoreSides(this.authServive.LoggedInTenantID).subscribe((data => {
      this.bedsoresides = data
    }))



  }

  ngOnInit(): void {
    if (this.route.parent != null && this.route.parent != undefined) {
      this.id = this.route.parent.snapshot.params['id'];
    }

    if (this.IsFromCentralizedPage) {
      this.id = this.PersonalId
    }else {
      this.BedSoreId = this.route.snapshot.params.bedsoreid 
    }


    this.CheckIfComponentIsDisabled(this.id)
    this.currentBedSoreTrack = new BedSoreTracks()
    this.currentBedSoreTrack.PersonelID = this.id
    this.currentBedSoreTrack.TenantID = this.authServive.LoggedInTenantID
    this.currentBedSoreTrack.BedSoreTrackTxt = ""



    if (this.BedSoreId == 'new' || this.BedSoreId == "") {

      this.isNewRecord = true
      this.getBedSoreById(  "new")
      this.pagenationObject = new PagenationObject(0)
    }
    else {
      this.isNewRecord = false
      if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
        this.step = Number(this.route.snapshot.params['stepid']);
      }




      this.getTotalBedSoreTracksFromDB()
       this.getBedSoreById("record")
      this.getBedSoreTracksById("1")
    }

  }

  getBedSoreById( mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + this.BedSoreId + '\'' + ')'


    this.caretxNursingProviderService.GetBedSores(this.id, filter, mode).subscribe((data: BedSore[]) => {
      if (data.length == 1) {
        this.currentBedSore = new BedSore(data[0])

        this.isrecordClosed = (this.currentBedSore.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  getBedSoreTracksById(page: string) {
    let filter = '(bed_sore_id=' + '\'' + this.BedSoreId + '\'' + ')'
    this.caretxNursingProviderService.getBedSoreTracks(this.id, filter, "list", page).subscribe((data: BedSoreTracks[]) => {
      if (data.length > 0) {
        this.BedSoreTracks = data
      }
      else {
        this.BedSoreTracks = []
      }
    });
  }

  getTotalBedSoreTracksFromDB() {
    this.caretxNursingProviderService.getTotalBedSoreTracksFromDB(this.id, this.BedSoreId ).subscribe((data: number) => {
      this.pagenationObject = new PagenationObject(data)
    });
  }

  HandleLocation(locationcode) {
    for (let i = 0; i < this.bedsorelocations.length; ++i) {
      if (this.bedsorelocations[i].LocationCode == locationcode) {
        this.currentBedSore.BedSoreLocationId = this.bedsorelocations[i].ID
        break
      }
    }

    this.modalClose.nativeElement.click();
  }

  Save() {

    if (!this.mainFormElement.valid) {
      this.onSaveErrordmsg()
    } else {
      this.currentBedSore.BedSoreTreatments = []
      if (this.newTreatment.TreatmentTxt != "" && this.newTreatment.TreatmentTxt != undefined) {
        this.newTreatment.PersonelID = this.id
        this.newTreatment.TenantID = this.authServive.LoggedInTenantID
        this.newTreatment.UpdatedAt = this.newTreatment.CreatedAt = new Date()
        this.newTreatment.LastUpdatedById = this.authServive.LoggedInUser.UID
        this.currentBedSore.BedSoreTreatments.unshift(this.newTreatment)
      }


      this.currentBedSoreTrack.PersonelID = this.id
      this.currentBedSoreTrack.TenantID = this.authServive.LoggedInTenantID
      this.currentBedSore.BedSoreTracks = []
      this.currentBedSoreTrack.TrackedById = this.authServive.LoggedInUser.UID
      this.currentBedSoreTrack.TrackedDate = new Date()

      this.currentBedSore.BedSoreTracks.unshift(this.currentBedSoreTrack)

      this.currentBedSore.LastUpdatedById = this.authServive.LoggedInUser.UID
      if (this.currentBedSore.ID == "" || this.currentBedSore.ID == null || this.BedSoreId == 'new' || this.BedSoreId == "") {
        this.DoSave()
      }
      else {
        this.currentBedSore.BedSoreTracks[0].BedSoreID = this.BedSoreId 
        this.DoUpdate()
      }
    }


  }

  DoSave() {
    // Check  Mandatory Fields
    this.caretxNursingProviderService.CreateBedSore(this.currentBedSore).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.currentBedSore = data as BedSore
        if (this.currentBedSore.Status == 'C')
          this.isrecordClosed = true
            this.newTreatment = new BedSoreTreatment()

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload'

        if (!this.IsFromCentralizedPage) {
        if (!this.GoToNextTabOnSuccess)
          this.router.navigateByUrl("caretexfile/" + this.id + "/bedsores/" + this.currentBedSore.ID + "/step/" +  this.step )
        else {
          this.router.navigateByUrl("caretexfile/" + this.id + "/bedsores/" + this.currentBedSore.ID + "/step/" +  this.step )
        }
      }
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  DoUpdate() {
    // Check  Mandatory Fields
    this.caretxNursingProviderService.updateBedSore(this.currentBedSore).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.currentBedSore = data as BedSore
        if (this.currentBedSore.Status == 'C')
          this.isrecordClosed = true

        this.newTreatment = new BedSoreTreatment()
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload'
        if (!this.IsFromCentralizedPage) {
        if (!this.GoToNextTabOnSuccess)
          this.router.navigateByUrl("caretexfile/" + this.id + "/bedsores/" + this.currentBedSore.ID + "/step/" + + this.step, {
            skipLocationChange: true,
          })
        else {
          this.router.navigateByUrl("caretexfile/" + this.id + "/bedsores/" + this.currentBedSore.ID + "/step/" + + this.step, {
            skipLocationChange: true,
          })
        }
      }
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  ValidateBeforeSave() {
  }

  updateSoreDate($event) {
    this.currentBedSore.BedSoreDate = new Date($event)
  }

  LockRecord() {

    Swal.fire({
      title: "הודעת מערכת",
      text: "האם את/ה רוצה לנעול רשומה ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תנעל',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentBedSore.Status = 'C'
        this.currentBedSore.LockedDate = new Date()
        this.currentBedSore.LockedById = this.authServive.LoggedInUser.UID
        this.Save()
        this.isrecordClosed = true
      }
    })

  }

  showWebCam() {
    const dialogRef = this.dialog.open(CameraDialogComponent, {
      data: {
        FileCategory: "bedsorefile",
        Origin: this.currentBedSore.ID,
        Id: this.id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateRefreshedObservFiles()
    });
  }

  updateRefreshedObservFiles() {
    let filter: string = "file_category = 'bedsorefile' and linked_to='"+this.currentBedSore.ID + "'"
    this.sharedServices.getAttachments(this.id, filter).subscribe((data: Attachment[]) => {
      if (data.length > 0) {
        this.currentBedSore.BedSoreImages = data
      }
      else {
      }
    });
  }

  selectDoctorForAlert() {
  }

  newTrack() {
    this.currentBedSoreTrack.BedSoreTrackTxt = ""
  }

  copyLastTrack() {
    if (this.BedSoreTracks.length > 0)
      this.currentBedSoreTrack.BedSoreTrackTxt = this.BedSoreTracks[0].BedSoreTrackTxt
  }


  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/bedsores/list")
  }

  addNewdHelperItem(helpItem?: BedSoreHelpItem, index?: number) {
    var newHelpItem = new BedSoreHelpItem()
    if (helpItem == null || helpItem == undefined) {
      newHelpItem.UpdatedAt = new Date()
      newHelpItem.CreatedAt = newHelpItem.UpdatedAt
      newHelpItem.TenantID = this.authServive.LoggedInTenantID
      newHelpItem.PersonelID = this.id
      newHelpItem.New = newHelpItem.Modified = newHelpItem.editMode = true
    }
    const dialogRef = this.dialog.open(HelperitemDialogComponent, {
      data: {
        HelperItem: (helpItem == null || helpItem == undefined) ? newHelpItem : this.currentBedSore.BedSoreHelpItems[index],
        Disabled: this.isrecordClosed || !this.canNursingPagesBeEditted

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Save Diagnoses
      if (result == undefined || result == null || result == "") return
      let tmpHelpItem: BedSoreHelpItem = result
      if (helpItem == null || helpItem == undefined) {
        this.currentBedSore.BedSoreHelpItems.unshift(tmpHelpItem)
      } else {
        if (index != undefined) {
          this.currentBedSore.BedSoreHelpItems[index] = tmpHelpItem
          this.currentBedSore.BedSoreHelpItems[index].Modified = true
        }
      }
    });

  }

  conevrtoDateTime($event) {
    let date = new Date($event)
    return date
  }

  submitForm() {
    this.mainFormElement.onSubmit(undefined);
  }

  onSaveErrordmsg() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "אתה צריך למלאה את כל השדות שצבועות באדום!",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'סגור',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    })
  }

  CheckIfDocumentIsDirty() {
    const BedSoreHelpItems = this.currentBedSore.BedSoreHelpItems.some(row => {
      return row.New || row.Modified
    })
    return this.mainFormElement.dirty || BedSoreHelpItems
  }

  HandlePagenation($event) {
    this.getBedSoreTracksById($event)
  }

  downloadProcessDone() {
    this.getBedSoreImageTracks()
  }

  getBedSoreImageTracks() {
    this.caretxNursingProviderService.getBedSoreImages(this.id).subscribe((data: Attachment[]) => {
      if (data.length > 0) {
        this.currentBedSore.BedSoreImages = data
      }
      else {
        this.currentBedSore.BedSoreImages = []
      }
    });
  }

  setStep(step) {
    this.step = step
  }

  Notify() {
    // Need to be changed 
    const dialogRef = this.dialog.open(UserselectionDialogComponent, {
      data: {
        UserRole: this.userRole,
        DialogTitle: "שלח התראה על פצע לחץ"
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      this.alertUsers = result
      this.handleSelectedUserForAlert()
    });
  }

  handleSelectedUserForAlert() {
    this.alertUsers.forEach(user => {
      let notification: NotificationMap = new NotificationMap()
      notification.CreatedAt = new Date()
      notification.TimeCreated = new Date()
      notification.IsOpened = false
      notification.IsSeen = false
      notification.TenantID = this.authServive.LoggedInTenantID
      notification.TargetRole = ""
      notification.Type = "R"
      notification.TargetUserID = user.UID
      notification.SourceUserID = this.currentBedSore.ID
      notification.ShortDescription = "עדכון על פצע לחץ אצל דייר"       // "דייר " + this.id + " עם פצע לחץ שמצריך בדיקה"
      notification.LongDescription = "מטופל בשם " + this.medicalFile1.FirstName + " " + this.medicalFile1.LastName + " עם פצע לחץ " + this.currentBedSore.BedSoreType.Type
      notification.GneratedBy = "הודעת מערכת אוטומטית"
      notification.URL = "caretexfile/" + this.id + "/bedsores/" + this.currentBedSore.ID

      this.notificationService.AddNewNotification(notification).subscribe({
        next: data => {
          this.toastrService.Sucesss("", this.toastrService.successMsg)
        },
        error: error => {
          this.toastrService.Error("", error.error.error)
        }
      })
    });
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }


  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  isPdfFile:boolean = false
  file223 :string = ""
  pdfContent:any


  downloadFile(file: Attachment) {
    let extension = (file.FileName).split('.').pop();
    let typeOfBlob = "application/octet-stream"
    switch (extension) {
      case "pdf":
        typeOfBlob = "application/pdf"
          this.isPdfFile = true
        break
      case "jpg":
        typeOfBlob = "image/jpg"
        this.isPdfFile = false
        break
      case "jpeg":
        typeOfBlob = "image/jpeg"
        this.isPdfFile = false
        break
      case "png":
        typeOfBlob = "image/png"
        this.isPdfFile = false
        break
      default:
        break
    }



    this.sharedServices.downloadFile(this.id, file)
      .subscribe(data => {
        var file = new Blob([data], { type: typeOfBlob });
        this.pdfContent = URL.createObjectURL(file);
        if (this.isPdfFile){
          this.pdfViewer.pdfSrc = file
          this.showGeneratedPdf = true
          this.pdfViewer.refresh();
        }else{
        }
      }, error => {
        console.log(error);
        var errorAsString = "failed to download file"
        try {
          var enc = new TextDecoder("utf-8")
          var text = enc.decode(error.error)
          errorAsString = JSON.parse(text).error
        } catch (e) {

        }
      })
  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }


  showFreeTxtSideField() {
    this.currentBedSore.IsFreeTxtSide = true
  }

  showLupSideField() {
    this.currentBedSore.IsFreeTxtSide = false
  }

  refreshHistoryRecords() {
    this.getBedSoreTracksById("1")
  }

  ReturnToCentralPage() {
    this.backToCentralPage.emit()
  }
}


