import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DoctorsRoutingModule } from './doctors-routing.module';
import { DruginstructionsComponent } from './components/druginstructions/druginstructions.component';
import { DruginstrListComponent } from './components/druginstructions/druginstr-list/druginstr-list.component';
import { ImportantmedicalmeasuresComponent } from '../caretx-shared-module/CaretexModals/importantmedicalmeasures/importantmedicalmeasures.component';
import { UserManagementComponent } from '../components/user-management/user-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../caretx-shared-module/shared-module.module';
import { BrowserModule } from '@angular/platform-browser';
import { PipesModule } from '../caretx-custompipes-module/custompipes.module';
import { ModalModule } from '../modal';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { DrugindecationviewerComponent } from './1-module-dialogs/drugindecationviewer/drugindecationviewer.component';
import { CommentwindowComponent } from './1-module-dialogs/commentwindow/commentwindow.component';
import { LabresultsmodalComponent } from '../caretx-shared-module/CaretexModals/labresultsmodal/labresultsmodal.component';
import { MedicalAdmissionComponent } from './components/medical-admission/medical-admission.component';
import { MedicalAdmissionListComponent } from './components/medical-admission/medical-admission-list/medical-admission-list.component';
import { DoctorsummaryComponent } from './components/medical-admission/sub-components/doctorsummary/doctorsummary.component';
import { MedicalplanComponent } from './components/medical-admission/sub-components/medicalplan/medicalplan.component';
import { PhysicalcheckComponent } from './components/physicalcheck/physicalcheck.component';
import { MedicaltrackComponent } from './components/medicalobservation/medicaltrack/medicaltrack.component';
import { LaboratorycheckslistComponent } from './components/Laboratory/Lists/laboratorycheckslist/laboratorycheckslist.component';
import { NewlaboratorycheckComponent } from './components/Laboratory/laboratory/labortatoryresults/newlaboratorycheck.component';
import { MedicalobservationComponent } from './components/medicalobservation/medicalobservation.component';
import { MedicaldiagnosesComponent } from './components/medical-admission/sub-components/medicaldiagnoses/medicaldiagnoses.component';
import { RegularmedicationsComponent } from './components/medical-admission/sub-components/regularmedications/regularmedications.component';
import { RegularmidicationdetailsComponent } from './1-module-dialogs/regularmidicationdetails/regularmidicationdetails.component';
import { LablandingpageComponent } from './components/Laboratory/laboratory/lablandingpage/lablandingpage.component';
import { OrderlabchecksComponent } from './components/Laboratory/laboratory/orderlabchecks/orderlabchecks.component';
import { LaboratoryrequestslistComponent } from './components/Laboratory/Lists/laboratoryrequestslist/laboratoryrequestslist.component';
import { MedicalReleaseComponent } from './components/medical-release/medical-release.component';
import { MedicalDiagnosesComponent } from './components/medical-diagnoses/medical-diagnoses.component';
import { PhysicalcheckListComponent } from './components/physicalcheck/physicalcheck-list/physicalcheck-list.component';
import { CommeonHelperService } from '../services/common.data-helper.service';
import { LabsProviderService } from './doctor-services/laboratory.data-provider.service';
import { MedicalDiagnosesProviderService } from './doctor-services/medical-diagnoses.data-provider.service';
import { CustomerService } from '../services/customer.service';
import { AdmissionsService } from '../services/admissions.service';
import { DrugsService } from '../services/drugs.service';
import { MedicalPersonelMeasuresProviderService } from './doctor-services/medical-perosnelmeasures.data-provider.service';
import { CaretxMedicalProviderService } from './doctor-services/Medical.data-provider.service';
import { HazardslistComponent } from './components/hazardslist/hazardslist.component';
import { HazarddetailsComponent } from './components/hazarddetails/hazarddetails.component';
import { MedicalreleaselistComponent } from './components/medical-release/medicalreleaselist/medicalreleaselist.component';
import { SensitivitiesComponent } from './components/sensitivities/sensitivities.component';
import { MedicalroutanchorComponent } from './medicalroutanchor/medicalroutanchor.component';
import { PassovernoticeComponent } from './components/passovernotice/passovernotice.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NursinginstructionsComponent } from './components/nursinginstructions/nursinginstructions.component';
import { PreviuseobservationsComponent } from './components/medicalobservation/previuseobservations/previuseobservations.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MedicalexceptionaleventslistComponent } from './components/medicalexceptionaleventslist/medicalexceptionaleventslist.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSummernoteModule } from 'ngx-summernote';
import { MedicalAdmissionLockedComponent } from './components/medical-admission/medical-admission-locked/medical-admission-locked.component';
import { FamilyTalksComponent } from './components/family-talks/family-talks.component';
import { ExpertConsultantsComponent } from './components/expert-consultants/expert-consultants.component';
import { HospitalReferralsComponent } from './components/hospital-referrals/hospital-referrals.component';
import { ExpertConsultantDetailsComponent } from './components/expert-consultants/expert-consultant-details/expert-consultant-details.component';
import { FamilyTalkDetailsComponent } from './components/family-talks/family-talk-details/family-talk-details.component';
import { HospitalReferralDetailsComponent } from './components/hospital-referrals/hospital-referral-details/hospital-referral-details.component';
import { MedicalomdanLandPageComponent } from './components/medicalomdan-land-page/medicalomdan-land-page.component';
import { MiniMentalListComponent } from './components/medicalomdan-land-page/mini-mental-list/mini-mental-list.component';
import { MiniMentalOmdanComponent } from './components/medicalomdan-land-page/mini-mental-omdan/mini-mental-omdan.component';
import { ClockExamListComponent } from './components/medicalomdan-land-page/clock-exam-list/clock-exam-list.component';
import { ClockExamComponent } from './components/medicalomdan-land-page/clock-exam/clock-exam.component';
import { ExpertConsultantLockedComponent } from './components/expert-consultants/expert-consultant-locked/expert-consultant-locked.component';
import { FamilyTalkLockedComponent } from './components/family-talks/family-talk-locked/family-talk-locked.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { HospitalReferralDetailsLockedComponent } from './components/hospital-referrals/hospital-referral-details-locked/hospital-referral-details-locked.component';
import { ExternalmedicaladmissionComponent } from './components/medical-admission/externalmedicaladmission/externalmedicaladmission.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';


@NgModule({
  declarations: [
    DruginstructionsComponent,
    DruginstrListComponent,
    MedicalAdmissionComponent,
    DrugindecationviewerComponent,
    CommentwindowComponent,
    MedicalAdmissionListComponent,
    DoctorsummaryComponent,
    MedicalplanComponent,
    PhysicalcheckComponent,
    MedicaltrackComponent,
    UserManagementComponent,
    LaboratorycheckslistComponent,
    NewlaboratorycheckComponent,
    MedicalobservationComponent,
    MedicaldiagnosesComponent,
    RegularmedicationsComponent,
    RegularmidicationdetailsComponent,
    LablandingpageComponent,
    OrderlabchecksComponent,
    LaboratoryrequestslistComponent,
    MedicalReleaseComponent,
    MedicalDiagnosesComponent,
    LabresultsmodalComponent,
    PhysicalcheckListComponent,
    ImportantmedicalmeasuresComponent,
    HazardslistComponent,
    HazarddetailsComponent,
    MedicalreleaselistComponent,
    SensitivitiesComponent,
    MedicalroutanchorComponent,
    PassovernoticeComponent,
    NursinginstructionsComponent,
    PreviuseobservationsComponent,
    MedicalexceptionaleventslistComponent,
    MedicalAdmissionLockedComponent,
    FamilyTalksComponent,
    ExpertConsultantsComponent,
    HospitalReferralsComponent,
    FamilyTalkDetailsComponent,
    HospitalReferralDetailsComponent,
    ExpertConsultantDetailsComponent,
    MedicalomdanLandPageComponent,
    MiniMentalListComponent,
    MiniMentalOmdanComponent,
    ClockExamListComponent,
    ClockExamComponent,
    ExpertConsultantLockedComponent,
    FamilyTalkLockedComponent,
    HospitalReferralDetailsLockedComponent,
    ExternalmedicaladmissionComponent
  ],
  imports: [
    CommonModule,
    DoctorsRoutingModule,
    FormsModule,
    SharedModule,
    BrowserModule,
    ReactiveFormsModule,
    PipesModule,
    ModalModule,
    MatTableModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule, 
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatMenuModule,
    MatListModule,
    MatStepperModule,
    NgMultiSelectDropDownModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    NgSelectModule,
    NgxSummernoteModule,
    MatCheckboxModule,
    MatRadioModule,
    PdfJsViewerModule,
    MatChipsModule
  ],
  exports: [
    DruginstructionsComponent,
    DruginstrListComponent,
    MedicalAdmissionComponent,
    DrugindecationviewerComponent,
    CommentwindowComponent,
    MedicalAdmissionListComponent,
    DoctorsummaryComponent,
    MedicalplanComponent,
    PhysicalcheckComponent,
    MedicaltrackComponent,
    UserManagementComponent,
    LaboratorycheckslistComponent,
    NewlaboratorycheckComponent,
    MedicalobservationComponent,
    NursinginstructionsComponent,
    MedicaldiagnosesComponent,
    RegularmedicationsComponent,
    RegularmidicationdetailsComponent,
    LablandingpageComponent,
    OrderlabchecksComponent,
    LaboratoryrequestslistComponent,
    MedicalReleaseComponent,
    MedicalDiagnosesComponent,
    LabresultsmodalComponent,
    PhysicalcheckListComponent,
    ImportantmedicalmeasuresComponent,
    SensitivitiesComponent,


  ],
  providers: [
    MedicalPersonelMeasuresProviderService,
    CommeonHelperService,
    LabsProviderService,
    MedicalDiagnosesProviderService,
    CustomerService,
    CaretxMedicalProviderService,
    AdmissionsService,
    DrugsService],
})
export class DoctorsModule { }
