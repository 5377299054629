import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NurseAdmissionSteps } from 'src/app/services/enums';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-omdanem-list',
  templateUrl: './omdanem-list.component.html',
  styleUrls: ['./omdanem-list.component.css']
})
export class OmdanemListComponent implements OnInit {
  id: string
  @Input() SubModule: boolean
  @Input() AdmissionSaved :boolean
  @Output() choosedExamEvent = new EventEmitter<NurseAdmissionSteps>();
  moduleName:String ="אומדנים/מבחנים"


  constructor(
    private _title: Title,
    private router: Router,
    private _ar: ActivatedRoute,) { }

  ngOnInit(): void {
    this._title.setTitle('Caretx | אומדנים/מבחנים');
    this.id = this._ar.snapshot.params['id'];
  }

  openbohseList() {
    if (this.SubModule) {
      if (this.AdmissionSaved){
      this.choosedExamEvent.emit(NurseAdmissionSteps.BhoseExam)
      }
      else{
        this.AlertOmdanCannotBeOpened()
      }
    }
    else {
      this.router.navigateByUrl("caretexfile/" + this.id + "/bohselist")// not working
    }
  }

  openOmdan2List() {
    if (this.SubModule) {
      if (this.AdmissionSaved){
      this.choosedExamEvent.emit(NurseAdmissionSteps.Omdan2Exam)
      }
      else{
        this.AlertOmdanCannotBeOpened()
      }
    }
    else {
      this.router.navigateByUrl("caretexfile/" + this.id + "/omdan2list")// not working
    }
  }

  openSnaqList() {
    if (this.SubModule) {
      if (this.AdmissionSaved){
      this.choosedExamEvent.emit(NurseAdmissionSteps.SnaqExam)
      }
      else{
        this.AlertOmdanCannotBeOpened()
      }
    }
    else {
      this.router.navigateByUrl("caretexfile/" + this.id + "/snaqlist")// not working
    }
  }

  openOmdan4List() {
    if (this.SubModule) {
      if (this.AdmissionSaved){
      this.choosedExamEvent.emit(NurseAdmissionSteps.Omdan4Exam)
      }
      else{
        this.AlertOmdanCannotBeOpened()
      }
    }
    else {
      this.router.navigateByUrl("caretexfile/" + this.id + "/omdan4list")// not working
    }
  }

  openFimList() {
    if (this.SubModule) {
      if (this.AdmissionSaved){
        this.choosedExamEvent.emit(NurseAdmissionSteps.FimExam)
      }
      else{
          this.AlertOmdanCannotBeOpened()
      }
    }
    else {
      this.router.navigateByUrl("caretexfile/" + this.id + "/fimlist")// not working
    }
  }

  openOmdan5List() {
    if (this.SubModule) {
      if (this.AdmissionSaved){
      this.choosedExamEvent.emit(NurseAdmissionSteps.Omdan5Exam)
      }
      else{
        this.AlertOmdanCannotBeOpened()
      }
    }
    else {
      this.router.navigateByUrl("caretexfile/" + this.id + "/omdan5list") // not working
    }
  }
  openNortonList() {
    if (this.SubModule) {
      if (this.AdmissionSaved){
      this.choosedExamEvent.emit(NurseAdmissionSteps.NortonExam)
      }
      else{
        this.AlertOmdanCannotBeOpened()
      }
    }
    else {
      this.router.navigateByUrl("caretexfile/" + this.id + "/nortonlist")// not working
    }
  }


  openDiabeticTestList() {
    if (this.SubModule) {
      if (this.AdmissionSaved){
      this.choosedExamEvent.emit(NurseAdmissionSteps.DiabeticTest)
      }
      else{
        this.AlertOmdanCannotBeOpened()
      }
    }
    else {
      this.router.navigateByUrl("caretexfile/" + this.id + "/diabetictests")// not working
    }
  }


  AlertOmdanCannotBeOpened(){


        Swal.fire({
          title: "הודעת מערכת",
          text: "צריך לשמור קבלה קודם!",
          icon: 'warning',
          confirmButtonText: 'סגור',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      
  }

}
