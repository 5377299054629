import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CartexReport } from '../models/personeldocument';
import { CaretexFonts } from '../models/caretex_fonts';
import { jsPDF } from "jspdf";
import { User } from '../models/user';
import { MedicalFile } from '../models/customer';
import autoTable, { RowInput } from 'jspdf-autotable'


@Injectable()
export class ReportingService {

  regularNormal: string

  constructor(private httpClient: HttpClient) {


  }

  generateReport(personalId: string, careTextReport: CartexReport): Observable<any> {
    return this.httpClient.post(environment.gateway + '/clients/' + personalId + '/reports', careTextReport, {
      responseType: "arraybuffer" //tried with 'blob'
    })
  }


  AddFontDavidLibreRegular(doc: jsPDF) {


    doc.addFileToVFS("NotoSansHebrew-Regular-normal.ttf", CaretexFonts.NotoSansHebrewRegular);
    doc.addFont("NotoSansHebrew-Regular-normal.ttf", "NotoSansHebrew-Regular", "normal");
    doc.setFont("NotoSansHebrew-Regular");

    doc.addFileToVFS("NotoSansHebrew-SemiBold-normal.ttf", CaretexFonts.NotoSansHebrewSemiBold);
    doc.addFont("NotoSansHebrew-SemiBold-normal.ttf", "NotoSansHebrew-SemiBold", "normal");


  }



  TenantLogoBase64 = ''


  GetTenantLogoInBase64() {

    return this.TenantLogoBase64

  }



  //Generate PDF Report 

  SetHeaderForCardixGeneric(doc: jsPDF,title:string,xPosTitle: number , departments:string , fromDate:string , toDate:string) {
    this.AddFontDavidLibreRegular(doc);

    doc.setR2L(true)
    doc.setFontSize(10);
    let maxWidth = 40;
    let text02 = 'מתאריך:';
    let lines = ''

    var imgData = this.GetTenantLogoInBase64()

    doc.addImage(imgData, 'JPEG', 175, 5, 25, 25);
    let x1 = 10
    let x2 = 205

    doc.setDrawColor(0, 0, 0);

    doc.text(lines, 180, 20, { align: 'right' });

    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 195, 25, { align: 'right' });

    doc.setR2L(false)
    doc.setFontSize(9);
    text02 = "1/5/2024 08:00";
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 177, 25, { align: 'right' });
    //doc.rect(10, 30, 190, 20);

    doc.setR2L(true)
    doc.setFontSize(10);
    text02 = 'עד תאריך:';
    maxWidth = 20;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 145, 25, { align: 'right' });

    doc.setR2L(false)
    doc.setFontSize(9);
    text02 = "1/11/2024 15:30";
    maxWidth = 40;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 120, 25, { align: 'right' });

    doc.setR2L(true)
    text02 = 'מחלקות:';
    maxWidth = 20;
    doc.setFontSize(10);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 85, 25, { align: 'right' });

    
    if (departments == "") {
      text02 = "לא צוין"
    }else{
      text02 = departments
    }

   
    maxWidth = 70;
    doc.setFontSize(9);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 70, 25, { align: 'right' });


    let lineHeight = doc.getLineHeight() / doc.internal.scaleFactor
    let numOflines = lines.length  // splitted text is a string array
    let blockHeight = numOflines * lineHeight
    var yPos = (blockHeight + 28)

    doc.line(x1, yPos, x2, yPos)


    text02 = 'אור להורים - בית אבות';
    maxWidth = 50;
    doc.setFontSize(13);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 130, 10, { align: 'right' });


    text02 = title;
    maxWidth = 50;
    doc.setFontSize(12);

    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, xPosTitle, 15, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true });


    // return Y  angle
    return blockHeight + 29

  }




  SetHeader(doc: jsPDF, medicalfile: MedicalFile,title:string,xPosTitle: number) {
    this.AddFontDavidLibreRegular(doc);

    doc.setR2L(true)
    doc.setFontSize(10);
    let maxWidth = 40;
    let text02 = 'מס זהות:';
    let lines = ''

    var imgData = this.GetTenantLogoInBase64()

    doc.addImage(imgData, 'JPEG', 175, 5, 25, 25);
    let x1 = 10
    let y1 = 29
    let x2 = 205
    let y2 = 29

    doc.setDrawColor(0, 0, 0);


    doc.line(x1, y1, x2, y2)
    doc.text(lines, 180, 20, { align: 'right' });

    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 195, 25, { align: 'right' });

    doc.setR2L(false)
    text02 = medicalfile.PersonelID;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 170, 25, { align: 'right' });
    //doc.rect(10, 30, 190, 20);

    doc.setR2L(true)
    text02 = medicalfile.FirstName + ' ' + medicalfile.LastName;
    maxWidth = 50;
    doc.setFontSize(10);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 120, 25, { align: 'right' });


    text02 = 'גיל:';
    maxWidth = 20;
    doc.setFontSize(10);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 60, 25, { align: 'right' });

    doc.setR2L(false)
    text02 = this.calculateAge(medicalfile.BirthDay).toString();
    maxWidth = 20;
    doc.setFontSize(10);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 50, 25, { align: 'right' });

    doc.setR2L(true)
    text02 = 'מין:';
    maxWidth = 20;
    doc.setFontSize(10);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 35, 25, { align: 'right' });

    text02 = medicalfile.Gender == "male" ? "ז" : "נ";
    maxWidth = 20;
    doc.setFontSize(10);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 25, 25, { align: 'right' });



    text02 = 'אור להורים - בית אבות';
    maxWidth = 50;
    doc.setFontSize(13);
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 130, 10, { align: 'right' });


    text02 = title;
    maxWidth = 50;
    doc.setFontSize(12);

    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, xPosTitle, 15, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true });


    // return Y  angle
    return 29

  }

  setFooter(doc: jsPDF, doctorName: string, doctorSpecialization: string) {

    doc.setFontSize(9);
    let footerHeight = doc.internal.pageSize.height - 25


    let x1 = 5
    let y1 = footerHeight
    let x2 = 200
    let y2 = footerHeight

    doc.line(x1, y1, x2, y2)


    doc.setR2L(true)
    let footerFirtRow = footerHeight + 5

    let text02 = "שם הרופא:"
    let maxWidth = 30;
    let lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 190, footerFirtRow, { align: 'right' });

    text02 = "ד\"\ר " + doctorName
    maxWidth = 50;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 175, footerFirtRow, { align: 'right' });


    text02 = "טלפון:"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 80, footerFirtRow, { align: 'right' });

    doc.setR2L(false)
    text02 = "04-8789555"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 65, footerFirtRow, { align: 'right' });

    doc.setR2L(true)
    let footersecondRow = footerHeight + 9
    text02 = "תחום:"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 185, footersecondRow, { align: 'right' });

    text02 = doctorSpecialization
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 175, footersecondRow, { align: 'right' });

    text02 = "כתובת"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 80, footersecondRow, { align: 'right' });

    text02 = "גלגוליה רח 123"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 65, footersecondRow, { align: 'right' });

    doc.setR2L(false)
    var str = "Page " + doc.getCurrentPageInfo().pageNumber;
    doc.setFontSize(10);// optional
    doc.text(str, 20, doc.internal.pageSize.height - 6);
  }


  setFooter2(doc: jsPDF, Person1: User, Person2: User,ClosedAt:Date , PrintedAt:Date) {

    doc.setFontSize(9);
    let footerHeight = doc.internal.pageSize.height - 25


    let x1 = 5
    let y1 = footerHeight
    let x2 = 200
    let y2 = footerHeight
    doc.line(x1, y1, x2, y2)

    doc.setR2L(true)
    let footerFirtRow = footerHeight + 5

    let text02 = "מסמך ננעל ע\"\י:"
    let maxWidth = 30;
    let lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 190, footerFirtRow, { align: 'right' });
    text02 = Person1.firstName + " " + Person1.lastName
    maxWidth = 50;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 165, footerFirtRow, { align: 'right' });


    text02 = "תאריך נעילה:"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 80, footerFirtRow, { align: 'right' });
    doc.setR2L(false)
    text02 = this.formatDateToMMDDYYY(ClosedAt)
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 55, footerFirtRow, { align: 'right' });


    doc.setR2L(true)
    let footersecondRow = footerHeight + 9
     text02 = "מסמך הודפס ע\"\י:"
     maxWidth = 30;
     lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 190, footersecondRow, { align: 'right' });
    text02 = Person2.firstName + " " + Person2.lastName
    maxWidth = 50;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 165, footersecondRow, { align: 'right' });

    text02 = "תאריך הדפסה:"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 80, footersecondRow, { align: 'right' });
    doc.setR2L(false)
    text02 = this.formatDateToMMDDYYY(PrintedAt)
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 55, footersecondRow, { align: 'right' });



    doc.setR2L(false)
    var str = "Page " + doc.getCurrentPageInfo().pageNumber;
    doc.setFontSize(10);// optional
    doc.text(str, 20, doc.internal.pageSize.height - 6);
  }

  setFooter3(doc: jsPDF, Person1: User , PrintedAt:Date) {

    doc.setFontSize(9);
    let footerHeight = doc.internal.pageSize.height - 25


    let x1 = 5
    let y1 = footerHeight
    let x2 = 200
    let y2 = footerHeight
    doc.line(x1, y1, x2, y2)

    doc.setR2L(true)
    let footerFirtRow = footerHeight + 5

    let text02 = "מסמך הודפס ע\"\י:"
    let maxWidth = 30;
    let lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 190, footerFirtRow, { align: 'right' });
    text02 = Person1.firstName + " " + Person1.lastName
    maxWidth = 50;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 165, footerFirtRow, { align: 'right' });


    text02  = "תאריך הדפסה:"
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 80, footerFirtRow, { align: 'right' });
    doc.setR2L(false)
    text02 = this.formatDateToMMDDYYY(PrintedAt)
    maxWidth = 30;
    lines = doc.splitTextToSize(text02, maxWidth);
    doc.text(lines, 55, footerFirtRow, { align: 'right' });


    doc.setR2L(false)
    var str = "Page " + doc.getCurrentPageInfo().pageNumber;
    doc.setFontSize(10);// optional
    doc.text(str, 20, doc.internal.pageSize.height - 6);
  }




  AddLabelTextField(doc: jsPDF, initialYPosition: number, label: string, bodytxt: string, ClosedBy: User, PrintedBy: User,ClosedAt:Date,PrintedAt:Date, medicalfile: MedicalFile,title:string,xPosTitle: number) {
    doc.setR2L(true)
    // Label
    if (label != ""){
      doc.setFontSize(11);
      doc.setFont("NotoSansHebrew-SemiBold");
      var lineHeight = doc.getLineHeight() / doc.internal.scaleFactor
      var splittedText = doc.splitTextToSize(label, 180)
      var lines = splittedText.length  // splitted text is a string array
      var blockHeight = lines * lineHeight
      var yPos = (initialYPosition + 4)
      var xPos = 195
      doc.text(splittedText, xPos, yPos, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true });
      doc.setFont("NotoSansHebrew-Regular");
    }

    // Body text
    doc.setFontSize(9);
    lineHeight = doc.getLineHeight() / doc.internal.scaleFactor
    splittedText = doc.splitTextToSize(bodytxt, 180)
    lines = splittedText.length  // splitted text is a string array
    blockHeight = lines * lineHeight
    var yPos = (initialYPosition + 8)
    xPos = 195
    if (yPos + blockHeight <= doc.internal.pageSize.height - 25) {
      doc.text(splittedText, xPos, yPos, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
      yPos += blockHeight
      return yPos
    } else {
      splittedText.forEach(line => {
        //check if line exceeds the current plage
        doc.setR2L(true)
        doc.setFontSize(9);
        if (yPos + lineHeight < doc.internal.pageSize.height - 25) {
          doc.text(line, xPos, yPos, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
          yPos += lineHeight
        } else {
          // Add new Page 
          doc.addPage()

          yPos = this.SetHeader(doc, medicalfile,title,xPosTitle)
          this.setFooter2(doc, ClosedBy,PrintedBy,ClosedAt,PrintedAt)
          doc.setR2L(true)
          doc.setFontSize(9);
          doc.text(line, xPos, yPos + 4, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
          yPos += lineHeight + 4
        }

      });
      return yPos
    }


  }

  AddBulletLinesSection(doc: jsPDF, initialYPosition: number, lineText: string, ClosedBy: User, PrintedBy: User,ClosedAt:Date,PrintedAt:Date, medicalfile: MedicalFile,title:string,xPosTitle: number) {

    // Label

    var lineHeight = doc.getLineHeight() / doc.internal.scaleFactor
    var blockHeight = 1 * lineHeight
    var yPos = (initialYPosition + 4)
    var xPos = 185
    if (yPos + blockHeight <= doc.internal.pageSize.height - 25) {

      doc.setFontSize(9);
      doc.setR2L(true)

      doc.text('\u2022 ' + lineText, xPos, yPos, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
      yPos += blockHeight
      return yPos
    } else {
      doc.addPage()

      yPos = this.SetHeader(doc, medicalfile,title,xPosTitle)
      this.setFooter2(doc, ClosedBy,PrintedBy,ClosedAt,PrintedAt)
      doc.setR2L(true)
      doc.setFontSize(9);
      doc.text('\u2022 ' + lineText, xPos, yPos + 4, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
      yPos += lineHeight + 4
    }
    return yPos
  }

  AddBulletLinesSection2(doc: jsPDF, initialYPosition: number, lineText: string,  PrintedBy: User,PrintedAt:Date, title:string,xPosTitle: number,departments:string ,fromdate:string,todate:string) {

    // Label

    var lineHeight = doc.getLineHeight() / doc.internal.scaleFactor
    var blockHeight = 1 * lineHeight
    var yPos = (initialYPosition + 4)
    var xPos = 185
    if (yPos + blockHeight <= doc.internal.pageSize.height - 25) {

      doc.setFontSize(9);
      doc.setR2L(true)

      doc.text('\u2022 ' + lineText, xPos, yPos, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
      yPos += blockHeight
      return yPos
    } else {
      doc.addPage()

      yPos = this.SetHeaderForCardixGeneric(doc,title,xPosTitle ,departments,fromdate,todate)
      this.setFooter3(doc, PrintedBy,PrintedAt)
      doc.setR2L(true)
      doc.setFontSize(9);
      doc.text('\u2022 ' + lineText, xPos, yPos + 4, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
      yPos += lineHeight + 4
    }
    return yPos
  }



  AddHeaderSection(doc: jsPDF, initialYPosition: number, label: string, HeaderTxt: string, Doctor: User, medicalfile: MedicalFile,title:string,xPosTitle: number) {
    doc.setR2L(true)
    // Label
    doc.setFontSize(11);
    var lineHeight = doc.getLineHeight() / doc.internal.scaleFactor
    var blockHeight = 1 * lineHeight
    var yPos = (initialYPosition + 4)
    var xPos = 190
    if (yPos + blockHeight <= doc.internal.pageSize.height - 25) {
      doc.text(HeaderTxt, xPos, yPos, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
      yPos += blockHeight
      return yPos
    } else {
      doc.addPage()

      yPos = this.SetHeader(doc, medicalfile,title,xPosTitle)
      this.setFooter(doc, Doctor.firstName + " " + Doctor.lastName, "פנימאי")
      doc.setR2L(true)
      doc.setFontSize(10);
      doc.text(HeaderTxt, xPos, yPos + 4, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
      yPos += lineHeight + 4
    }
    return yPos
  }

  AddHeaderSection2(doc: jsPDF, initialYPosition: number,fontsize:number , fontName:string, HeaderTxt: string, ClosedBy: User, PrintedBy: User,ClosedAt:Date,PrintedAt:Date, medicalfile: MedicalFile,title:string,xPosTitle: number) {
    doc.setR2L(true)
    // Label
    doc.setFontSize(fontsize);
    var lineHeight = doc.getLineHeight() / doc.internal.scaleFactor
    var blockHeight = 1 * lineHeight
    var yPos = (initialYPosition + 4)
    var xPos = 190
    if (yPos + blockHeight <= doc.internal.pageSize.height - 25) {
      doc.setFont("NotoSansHebrew-SemiBold");
      doc.text(HeaderTxt, xPos, yPos, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
      doc.setFont("NotoSansHebrew-Regular");
      yPos += blockHeight
      return yPos
    } else {
      doc.addPage()
      yPos = this.SetHeader(doc, medicalfile,title,xPosTitle)
      this.setFooter2(doc, ClosedBy,PrintedBy,ClosedAt,new Date())
      doc.setR2L(true)
      doc.setFontSize(fontsize);
      doc.setFont("NotoSansHebrew-SemiBold");
      doc.text(HeaderTxt, xPos, yPos + 4, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
      yPos += lineHeight + 4
      doc.setFont("NotoSansHebrew-Regular");
    }
    return yPos
  }


  AddTableToPDF(doc: jsPDF, yPos: number, xPos: number, medicalfile: MedicalFile, doctorName: string, doctorSpecialization: string,colHeader : RowInput[],colVal : RowInput[],title:string,xPosTitle: number) {
    // doc.setR2L(true)
    autoTable(doc, {
      styles: {

        halign: "right",
        font: 'NotoSansHebrew-Regular',
        fontStyle: 'normal',
        fontSize: 9

      },
      margin: { bottom: 27, top: 31 },
      willDrawPage: () => {
        this.SetHeader(doc, medicalfile,title,xPosTitle),
        this.setFooter(doc, doctorName, "פנימאי")
      },
      startY: yPos + 3,
      head: colHeader,
      body: colVal,
    })
    let finalY = (doc as any).previousAutoTable.finalY
    return finalY + 10

  }


  AddTableToPDF2(doc: jsPDF, yPos: number, xPos: number, medicalfile: MedicalFile, ClosedBy: User, PrintedBy: User,ClosedAt:Date,PrintedAt:Date,colHeader : RowInput[],colVal : RowInput[],title:string,xPosTitle: number) {
    // doc.setR2L(true)
    autoTable(doc, {
      styles: {

        halign: "right",
        font: 'NotoSansHebrew-Regular',
        fontStyle: 'normal',
        fontSize: 9

      },
      margin: { bottom: 27, top: 31 },
      willDrawPage: () => {
        this.SetHeader(doc, medicalfile,title,xPosTitle),
        this.setFooter2(doc, ClosedBy, PrintedBy,ClosedAt,PrintedAt)
      },
      startY: yPos + 3,
      head: colHeader,
      body: colVal,
    })
    let finalY = (doc as any).previousAutoTable.finalY
    return finalY + 10

  }



  AddGenericTableToPDF(doc: jsPDF, yPos: number, user: User,colHeader : RowInput[],colVal : RowInput[],title:string,xPosTitle: number, departments:string , fromDate:string , toDate:string , printedAt:Date) {
    // doc.setR2L(true)
    autoTable(doc, {
      styles: {

        halign: "right",
        font: 'NotoSansHebrew-Regular',
        fontStyle: 'normal',
        fontSize: 9

      },
      margin: { bottom: 27, top: 31 },
      willDrawPage: () => {
        this.SetHeaderForCardixGeneric(doc,title,xPosTitle,departments,fromDate,toDate),
        this.setFooter3(doc, user, printedAt)
      },
      startY: yPos + 3,
      head: colHeader,
      body: colVal,
    })
    let finalY = (doc as any).previousAutoTable.finalY
    return finalY + 10

  }




  calculateAge(birthDay: any) {
    const bdate = new Date(birthDay);
    const timeDiff = Math.abs(Date.now() - bdate.getTime());
    //Used Math.floor instead of Math.ceil
    //so 26 years and 140 days would be considered as 26, not 27.
    return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
  }

  checkIfNeedNewPage(currentPageHeight: number, textBlockHeigh: number) {
  }

  reverseString(str1: string) {
    if (this.checkIfTxtContainsNonEnglish(str1))
      return str1
    let words: Array<string> = str1.split(" ");
    let reversedWords: Array<string> = words.map((word) => {
      let charArray: Array<string> = word.split("");
      charArray.reverse();
      let reversed: string = charArray.join("");
      return reversed;
    });
    let reverseString: string = reversedWords.reverse().join(" ");
    return reverseString
  }

  checkIfTxtContainsNonEnglish(txt: string) {

    var english = /^[A-Za-z0-9&_ @ .\-$*()~"':<>?,{}|[\]#!'/+/%/ ]*$/
    return english.test(txt)


  }


  formatDateToMMDDYYY(dateToFormat:Date){

    let tmpDate = new Date(dateToFormat)
    var dd = tmpDate.getDate();
    var mm = tmpDate.getMonth() + 1;
    var yyyy = tmpDate.getFullYear();



    return (mm < 10 ? '0' + mm.toString():mm.toString()) + '/' + ( dd < 10?'0' + dd.toString():dd.toString()) + '/' + yyyy;

  }


  formatDateToHHmm(dateToFormat:Date){

    let tmpDate = new Date(dateToFormat)
    var hh = tmpDate.getHours();
    var mm = tmpDate.getMinutes() + 1;




    return (hh < 10 ? '0' + hh.toString():hh.toString()) + ':' + ( mm < 10?'0' + mm.toString():mm.toString()) ;

  }

}