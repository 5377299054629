import { Component, OnInit } from '@angular/core';

import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { NotificationsSSEService } from "src/app/services/notifications-sse.service";
import { map } from 'rxjs/operators';
import { NotificationMap } from "src/app/models/notificationMap";
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ToasterService } from 'src/app/services/toastr.service';

import { Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { sideNaveGroups } from 'src/app/services/enums';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  
  //dataSource: NotificationsDataSource | null;
  allNotifications: NotificationMap[] = [];
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Nursing;
  TabId:string = "notifications"

  constructor(
    private authService: AuthService, 
    public dataservice:DataService, 
    //private sseService:NotificationsSSEService,
    
    public httpClient: HttpClient,
    public notificationsService : NotificationsSSEService,
    private router: Router,
    private data: DataService,
    private toastrService: ToasterService)
     { }

 /* ngOnInit(): void {
    this.sseService
      .getServerSentEvent(environment.gateway + '/handshake')
      .subscribe(data => console.log(data));
  }*/

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    let filter = ""
    this.notificationsService.getNotifications(this.authService.LoggedInUser['UID'],this.authService.LoggedInUser['role'],filter).subscribe((data: NotificationMap[]) => {
      this.allNotifications = data;
    }, error => {
      console.log(error);
      this.toastrService.Error("", error)
    });

//    this.notificationsService.dataChange.subscribe((data) => {
  //    this.allNotifications = data
//    }, error => {
//      console.log(error);
//      this.toastrService.Error("", error)
//    });

  }
  elapsedTime(date: Date): number{

       
        let currentDate = new Date();
        let currentTime = currentDate.getTime()
        let dateNew = new Date(date)
        let notificationTime = dateNew.getTime()
        let hours = Math.floor((currentTime - notificationTime) / 1000 / 60 / 60);
        return hours;
  }

}

export class NotificationsDataSource extends DataSource<NotificationMap> {
  _filterChange = new BehaviorSubject('');

  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  filteredData: NotificationMap[] = [];
  renderedData: NotificationMap[] = [];
  allNotifications: NotificationMap[] = [];

  constructor(public notificationsService: NotificationsSSEService,
    public authService: AuthService
    ) {
    super();
    // Reset to the first page when the user changes the filter.
    //this._filterChange.subscribe(() => this._paginator.pageIndex = 0);
  }

  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<NotificationMap[]> {
    // Listen for any changes in the base data, sorting, filtering, or pagination
    const displayDataChanges = [
      this.notificationsService.dataChange,
     
      this._filterChange    
    ];

    this.notificationsService.getNotifications(this.authService.LoggedInUser['UID'],this.authService.LoggedInUser['role'],"").subscribe((data: NotificationMap[]) => {
      this.allNotifications = data;
    });


    return merge(...displayDataChanges).pipe(map(() => {
      // Filter data
      this.allNotifications = this.notificationsService.data
      
      this.renderedData = this.allNotifications
      return this.renderedData;
    }
    ));
  }

  disconnect() { }


}

