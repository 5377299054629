import { Component, OnInit, ViewChild } from '@angular/core';
import { Diagnoses } from '../../../../../src/app/models/diagnoses';
import { OptionObject, BridgeEnums, MedicalAdmissionSteps, AccessComponenetType } from '../../../../../src/app/services/enums';
import { DrugInstruction } from '../../../../../src/app/models/drugInstruction';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalAdmission } from '../../../../../src/app/models/medicaladmission';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretxMedicalProviderService } from '../../doctor-services/Medical.data-provider.service';
import { Subject } from 'rxjs';
import { CaretexEventTypes, CartexEvent, TabHistory } from '../../../../../src/app/models/genericTypes';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';
import { OriginModule } from '../../../services/enums';
import { MedicalDiagnosesProviderService } from '../../doctor-services/medical-diagnoses.data-provider.service';


@Component({
  selector: 'app-medical-admission',
  templateUrl: './medical-admission.component.html',
  styleUrls: ['./medical-admission.component.css']
})


export class MedicalAdmissionComponent extends CaretexComponent implements OnInit {
  @ViewChild('mainform') mainFormElement: NgForm;
  AccessedComponent: AccessComponenetType = AccessComponenetType.SubModule
  moduleName: string = "קבלה רפואית"
  Origin: OriginModule = OriginModule.MedicalAdmission
  Admissionid: string
  personalId: string
  DrugModalId: string
  medicationTitle: string = "תרופות קבועות"
  medicationtype: string = "F"
  //CurrentStep = '';
  saveProcessCompleted: boolean
  isAdmissionClosed: boolean = false
  currentMedicalAdmission: MedicalAdmission
  currentAdmissionReady: boolean
  AdmissionType: string = "MedicalAdmission"
  pastDiagnosesList: Diagnoses[] = []
  CurrentSelectedDrugInstr: DrugInstruction
  tabHistory: TabHistory = new TabHistory()
  caretexEvent: CartexEvent = new CartexEvent()
  DrugFreqs: OptionObject[]
  DrugFreqUoms: OptionObject[]
  DrugUoms: OptionObject[]
  DrugDosageForms: OptionObject[]
  Type: string = AccessComponenetType.SubModule
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  isPhysicalexaminationIsDirty: boolean = false
  isDoctorSummaryDirty: boolean = false
  isDoctorSummaryDirty2: boolean = false
  isDoctorSummaryDirty3: boolean = false
  isMedicalPlanTextDirty: boolean = false
  personelSensitivityModuleChanged: boolean = false
  isfrequentMeasuresDirty: boolean = false
  step: number = 0
  isNewRecord: boolean = false
  DiagnosesList: Diagnoses[]
  isDrugSensitivityDirty: boolean = false
  isOtherSensitivityDirty: boolean = false
  drugInstructionChanged: boolean = false
  zoomInModalData: any;
  dismissModal: boolean = false
  subject = new Subject<boolean>();
  justRout: boolean
  measureTypeSelectDisabled: boolean = true

  constructor(
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private bridgeEnum: BridgeEnums,
    private router: Router,
    private medicalDiagnosesService: MedicalDiagnosesProviderService,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private route: ActivatedRoute,

    private _ar: ActivatedRoute,) {
    super(medicalFileService)
    bridgeEnum = new BridgeEnums()
    this.DrugFreqs = bridgeEnum.DrugFreqs
    this.DrugFreqUoms = bridgeEnum.DrugFreqUoms
    this.DrugUoms = bridgeEnum.DrugUOMs
    this.DrugDosageForms = bridgeEnum.DrugDosageForm
    this.DrugModalId = "drug-modal"

  }

  ngOnInit(): void {
    if (this.route.parent != null)
      this.personalId = this.route.parent.snapshot.params['id']
    this.Admissionid = this.route.snapshot.params.admissionid
    this.CheckIfComponentIsDisabled(this.personalId)
    if (this.route.snapshot.params['tabid'] != "" && this.route.snapshot.params['tabid'] != undefined) {
      this.step = Number(this.route.snapshot.params['tabid']);
    }


    if (this.route.snapshot.params.admissionid == 'new') {
      this.getMedicalAdmissionById(this.personalId, this.route.snapshot.params.admissionid, "new")
    } else {
      this.getMedicalAdmissionById(this.personalId, this.route.snapshot.params.admissionid, "")
    }
  }

  getMedicalAdmissionById(id: string, admissionId: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + admissionId + '\'' + ')'
    this.caretxMedicalProviderService.getMedicalAdmissions(filter, id, 1, mode).subscribe((data: MedicalAdmission[]) => {
      if (data.length == 1) {
        this.currentMedicalAdmission = new MedicalAdmission(data[0])
        this.isAdmissionClosed = (this.currentMedicalAdmission.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }

  /*********************Medical Admission ******************* */
  SaveAdmission() {
    this.saveProcessCompleted = false;
    if (this.step == 3.1 && this.drugInstructionChanged) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "את/ה צריך לשמור קודם את השינויים שנעשו להוראת תרופה",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'סגור',
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {

        }
      })
    } else {
      if (this.currentMedicalAdmission.ID == "" || this.currentMedicalAdmission.ID == null || this.currentMedicalAdmission.ID == "00000000-0000-0000-0000-000000000000") {
        this.DoSave()
      }
      else {
        this.DoUpdate()
      }
    }
  }

  DoUpdate() {
    this.caretxMedicalProviderService.updateMeidcalAdmission(this.currentMedicalAdmission).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentMedicalAdmission = new MedicalAdmission(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'ignore'; // Change from 'reload' to 'igore' to avoid page refresh on save
        this.resetChangedFlagsAfterSave()
        this.medicalFileService.ResetCachedMedicalFile(this.personalId)
        if (this.currentMedicalAdmission.Status == "C") {
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/medicaladmissions/" + this.currentMedicalAdmission.ID + "/locked")
        } else {
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/medicaladmissions/" + this.currentMedicalAdmission.ID + "/step/" + this.step)
        }

      },
      error: error => {
        this.toastrService.Error("", error.error.error)
      }
    })
  }

  DoSave() {
    this.caretxMedicalProviderService.createNewMedicalAdmission(this.currentMedicalAdmission).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentMedicalAdmission = new MedicalAdmission(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.resetChangedFlagsAfterSave()
        this.medicalFileService.ResetCachedMedicalFile(this.personalId)

        if (this.currentMedicalAdmission.Status == "C") {
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/medicaladmissions/" + this.currentMedicalAdmission.ID + "/locked")
        } else {
          this.router.navigateByUrl("caretexfile/" + this.personalId + "/medicaladmissions/" + this.currentMedicalAdmission.ID + "/step/" + this.step)
        }
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }


  DoRefresh(flag: boolean) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.resetChangedFlagsAfterSave()
    this.router.onSameUrlNavigation = 'reload';
    this.medicalFileService.ResetCachedMedicalFile(this.personalId)

    if (this.currentMedicalAdmission.Status == "C") {
      this.router.navigateByUrl("caretexfile/" + this.personalId + "/medicaladmissions/" + this.currentMedicalAdmission.ID + "/locked")
    } else {
      this.router.navigateByUrl("caretexfile/" + this.personalId + "/medicaladmissions/" + this.currentMedicalAdmission.ID + "/step/" + this.step)
    }
  }

  drugInstrDetailsConfirmedSaveRequest($event) {
  }

  updateNextTabFromDrugInstr($event) {
  }

  setStep(index: number) {
    this.step = index;
  }

  lockMedicalAdmission() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם את/ה רוצה לנעול קבלה ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תנעל',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentMedicalAdmission.Status = "C"

        // Set all diagnoses belong to Admission as Locked 
        this.currentMedicalAdmission.Diagnoses.forEach(element => {
          if (element.CreatedFrom == "admission") {
            element.Modified = true
            element.IsLocked = true
          }
        });
        this.currentMedicalAdmission.Closed_at = new Date()
        this.currentMedicalAdmission.ClosedById = this.authServive.LoggedInUser.UID
        this.currentMedicalAdmission.ClosedBy = this.authServive.LoggedInUser
        this.SaveAdmission()

      }
    })
  }

  /***************************************New Method ************************ */
  unSavedNewlyDiagnoses($event) {
    this.currentMedicalAdmission.Diagnoses
  }

  updateDoctorSummaryText($event) {
    this.currentMedicalAdmission.DoctorSummary = $event
    this.isDoctorSummaryDirty = true
  }

  updateDoctorSummaryText2($event) {
    this.currentMedicalAdmission.DoctorSummary2 = $event
    this.isDoctorSummaryDirty2 = true
  }

  updateDoctorSummaryText3($event) {
    this.currentMedicalAdmission.DoctorSummary3 = $event
    this.isDoctorSummaryDirty3 = true
  }

  updateMedicalPlanText($event) {
    this.currentMedicalAdmission.MedicalPlan = $event
    this.currentMedicalAdmission.MedicalPlanLastUpdated_at = new Date()
    this.currentMedicalAdmission.MedicalPlanLastUpdatedById = this.authServive.LoggedInUser.UID
    this.isMedicalPlanTextDirty = true
  }

  updatePersonalMedicalMeasures($event) {
    this.currentMedicalAdmission.PersonalMeasuresHeader.PersonalMeasures = $event
  }

  deleteDiagnosesLine($event) {
    this.caretxMedicalProviderService.deleteMeidcalAdmissionDiagnoses(this.currentMedicalAdmission.ID, this.currentMedicalAdmission.Diagnoses[$event]).subscribe({
      next: data => {
        this.currentMedicalAdmission.Diagnoses.splice($event, 1);
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }


  stopDiagnosesLine($event) {
    let diagnosesList: Diagnoses[] = []
    $event.Modified = true
    diagnosesList.unshift($event)
    this.medicalDiagnosesService.createPersonelDiagnoses(this.personalId, diagnosesList).subscribe({
      next: data => {
        this.getPersonalDiagnosesFromDB()
      },
      error: error => {
      }
    })
  }

  getPersonalDiagnosesFromDB() {
    this.medicalDiagnosesService.getPersonelDiagnoses(this.personalId).subscribe(
      data => {
        this.DiagnosesList = data
        this.currentMedicalAdmission.Diagnoses = this.DiagnosesList
      },
      err => {
        console.log("Error", err)
        this.DiagnosesList = []

      }
    )
  }

  updatePhysicalExaminationObject($event) {
    this.currentMedicalAdmission.PhysicalExamination = $event
  }

  /*************************************************************************** */
  openSelectedDrugInstr($event) {
    //  this.CurrentStep = MedicalAdmissionSteps.PermenantDrugDetails
    this.step = 3.1
    if ($event == null || $event == undefined) {
      // Do nothing for now
      this.CurrentSelectedDrugInstr = new DrugInstruction(undefined, this.personalId, this.authServive.LoggedInTenantID)
      this.CurrentSelectedDrugInstr.instrStatus = "D"
      this.CurrentSelectedDrugInstr.Doctor = this.authServive.LoggedInUser
      this.CurrentSelectedDrugInstr.doctorId = this.authServive.LoggedInUser.UID
      this.CurrentSelectedDrugInstr.instructionType = "F"
      this.CurrentSelectedDrugInstr.New = true
      this.isNewRecord = true
    } else {
      let index = $event
      this.CurrentSelectedDrugInstr = this.currentMedicalAdmission.DrugInstructions[index]
      this.currentMedicalAdmission.DrugInstructions[index].Modified = true
      this.isNewRecord = false
    }
  }

  AddNewDrugInstruction($event) {
    this.currentMedicalAdmission.DrugInstructions.push($event)
    this.CurrentSelectedDrugInstr = $event
  }

  BackToMainDocument() {
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/medicaladmissions/list")
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  physicalexaminationIsDirty($event) {
    this.isPhysicalexaminationIsDirty = $event
  }

  frequentMeasuresChanged($event) {
    this.isfrequentMeasuresDirty = $event
  }



  NewRecord() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload'
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/medicaladmissions/new")
  }
  // Check 
  personelMeasuresChanged($event) {

  }

  NewDrugInstr() {
    this.caretexEvent.EventType = CaretexEventTypes.New
    this.tabHistory.NextTab = MedicalAdmissionSteps.PermenantDrugDetails
    this.caretexEvent.EventData = this.tabHistory
    this.eventsSubject.next(this.caretexEvent);
  }

  backToMainDrugsListSub() {
    this.step = 3
  }

  /*+++++++++++++++++++++++++++++Handling Sensitivities section++++++++++++++++++++++++++++++*/

  personelSensitivityChanged($event) {
    this.personelSensitivityModuleChanged = $event
  }

  updateNextTabFromSensitivity($event) {

  }

  checkIfDrugSensitivityDirty() {
    let found: boolean = false
    let newOrUpdatedRow: boolean = false
    if (this.currentMedicalAdmission.PersonSensitivity.DrugSensitivity != undefined && this.currentMedicalAdmission.PersonSensitivity.DrugSensitivity != null) {
      found = this.currentMedicalAdmission.PersonSensitivity.DrugSensitivity.some(r => r.DrugId == undefined || r.DrugId == "" || r.DrugId == null)
      newOrUpdatedRow = this.currentMedicalAdmission.PersonSensitivity.DrugSensitivity.some(r => r.New || r.Modified)
      this.isDrugSensitivityDirty = found || newOrUpdatedRow
    }

  }

  isOtherSensitivityChanged() {
    this.isOtherSensitivityDirty = this.currentMedicalAdmission.PersonSensitivity.OtherSensitivity.Modified || this.currentMedicalAdmission.PersonSensitivity.OtherSensitivity.New
  }


  /*+++++++++++++++++++++++++++++++++++++++++Handle Drug Instructions +++++++++++++++++++++++++++++++++*/
  drugInstructionChangedSub($event) {
    this.drugInstructionChanged = $event
  }

  /*++++++++++++++++++++++++++++++++++++Check If Dirty Form++++++++++++++++++++++++++++++++++++++++++*/
  resetChangedFlagsAfterSave() {
    this.isDrugSensitivityDirty = false
    this.isPhysicalexaminationIsDirty = false
    this.isDoctorSummaryDirty = false
    this.isDoctorSummaryDirty2 = false
    this.isDoctorSummaryDirty3 = false
    this.isMedicalPlanTextDirty = false
    this.isfrequentMeasuresDirty = false
    this.personelSensitivityModuleChanged = false
    this.isOtherSensitivityDirty = false
    this.drugInstructionChanged = false
  }



  CheckIfDocumentIsDirty() {
    let diagnoses = false

    if (this.currentMedicalAdmission.Diagnoses != null && this.currentMedicalAdmission.Diagnoses != undefined) {
      diagnoses = this.currentMedicalAdmission.Diagnoses.some(row => {
        return row.New || row.Modified
      })
    }
    this.checkIfDrugSensitivityDirty()
    this.isOtherSensitivityChanged()


    return this.mainFormElement.dirty || this.drugInstructionChanged || this.isDrugSensitivityDirty || this.isOtherSensitivityDirty || diagnoses || this.isfrequentMeasuresDirty
      || this.isPhysicalexaminationIsDirty || this.isDoctorSummaryDirty || this.isDoctorSummaryDirty2 || this.isDoctorSummaryDirty3 || this.isMedicalPlanTextDirty
  }





  zoomInField(title, fieldNgModel, preFilledData) {
    this.dismissModal = false
    this.zoomInModalData = { title, field: fieldNgModel, preFilled: (preFilledData || '') };
  }



  bindFieldData(responseData) {
    this.currentMedicalAdmission[this.zoomInModalData.field] = responseData;
  }


  DismissCtxTextareaModal() {
    this.dismissModal = true
  }

}