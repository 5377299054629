import { DatePipe } from '@angular/common'
import { Diagnoses } from './diagnoses'
import { DrugInstruction } from './drugInstruction'
import { PersonelSensitivity } from './drugsenstivity'
import { PersonalMeasuresHeader } from './measure'
import { propertyMap } from './modelmapper'
import { PhysicalExamination } from './physicalexamination'
import { User } from './user'
import { Attachment } from './attachments'





export class MedicalObservation {
    @propertyMap('ID')
    ID: Number
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('RealmID')
    RealmID: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('RichText')
    RichText: string
    @propertyMap('Status')
    Status: string
    @propertyMap('LastUpdateById')
    public LastUpdateById: string
    public LastUpdateBy: User
    @propertyMap('MedicalObservationFiles')
    MedicalObservationFiles: Attachment[]
    @propertyMap('Diagnoses')
    public Diagnoses: Diagnoses[]
    @propertyMap('PhysicalExamination')
    public PhysicalExamination: PhysicalExamination
    @propertyMap('Sensitivity')
    public Sensitivity: PersonelSensitivity

    @propertyMap('DrugInstructions')
    public DrugInstructions:DrugInstruction[]
    @propertyMap('PersonalMeasuresHeader')
    public PersonalMeasuresHeader: PersonalMeasuresHeader

    @propertyMap('DoctorInstrctions')
    DoctorInstrctions: string
    @propertyMap('NextTrackDate')
    public NextTrackDate: Date

    public Disabled:boolean = true
    public OpenedForEdit:boolean = false
    Locked: boolean
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.RealmID = data["RealmID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.RichText = data["RichText"]
            this.LastUpdateById = data["LastUpdateById"]
            this.Status = data["Status"]
            this.Diagnoses = data["Diagnoses"]
            this.Sensitivity = data["Sensitivity"]
            this.DoctorInstrctions = data["DoctorInstrctions"]
            this.NextTrackDate = data["NextTrackDate"]
            this.PersonalMeasuresHeader = data["PersonalMeasuresHeader"]
            this.PhysicalExamination = new PhysicalExamination(data["PhysicalExamination"])
            this.MedicalObservationFiles = data["MedicalObservationFiles"]
            this.Disabled = true
            this.DrugInstructions = data["DrugInstructions"]
            
        }
        else {
            this.Status = 'A'
            this.Diagnoses = []
           /* this.PersonDrugSensitivity = []*/
            this.PersonalMeasuresHeader = new PersonalMeasuresHeader()
            this.PersonalMeasuresHeader.PersonalMeasures = []
            this.DrugInstructions = []
            this.PhysicalExamination = new PhysicalExamination()
        }
    }

    MedicalObservation() {
        this.CreatedAt = new Date()
        this.UpdatedAt = new Date()
    }

    buildChangeMsg() {
        if (this.LastUpdateBy == null || this.LastUpdateBy == undefined) return ""

        let datepipe = new DatePipe('en-US')
        let updatedDate = datepipe.transform(this.UpdatedAt, 'dd/MM/yyyy');
        let updatedTime = datepipe.transform(this.UpdatedAt, 'HH:mm');

        return "עודכן עי " + this.LastUpdateBy.displayName + " בתאריך " + updatedDate + " בשעה " + updatedTime
    }




}



export class MedicalObservationFile {
    @propertyMap('Id')
    Id: Number
    @propertyMap('ObservationId')
    ObservationId: string
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('Created_at')
    Created_at: Date
    @propertyMap('LastUpdate_at')
    LastUpdate_at: Date
    @propertyMap('FileDataBase64')
    FileDataBase64: string
    @propertyMap('FileName')
    FileName: string
    @propertyMap('FileTitle')
    FileTitle: string
    @propertyMap('FileUrl')
    FileUrl: string
    @propertyMap('LastUpdateById')
    public LastUpdateById: string
    public LastUpdateBy: User
    @propertyMap('ShortDescription')
    ShortDescription: string
    @propertyMap('FileCategory')
    FileCategory: string
    @propertyMap('DownloadedAt')
    DownloadedAt: string
    @propertyMap('LastDownloadedById')
    LastDownloadedBy: string
    @propertyMap('IsSocialCard')
    IsSocialCard: boolean


    constructor(data?: any) {
        if (data != null) {
            this.Created_at = data.Created_at
            this.FileDataBase64 = ""
            this.FileUrl = data.FileUrl
            this.Id = data.Id
            this.LastUpdateBy = data.LastUpdateBy
            this.LastUpdateById = data.LastUpdateById
            this.PersonelID = data.PersonelID
            this.ObservationId = data.ObservationId
            this.FileTitle = data.FileTitle
            this.FileName = data.FileName
            this.IsSocialCard = data.IsSocialCard
        }
    }




}

