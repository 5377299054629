import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseOmdan } from '../../../../../../src/app/models/omdans';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import { CaretxNursingProviderService } from '../../../nursing-services/Nursing.data-provider.service';

@Component({
  selector: 'app-fimlist',
  templateUrl: './fimlist.component.html',
  styleUrls: ['./fimlist.component.css']
})
export class FimlistComponent extends CaretexComponent implements OnInit {

  fimOmdans:BaseOmdan[] =[]
  id: string

  moduleName:string="מבחני FIM"
  constructor( 
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private caretxNursingProviderService:CaretxNursingProviderService,
    private toastrService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,) { super(medicalFileService)}

  ngOnInit(): void {
    this._title.setTitle('Caretx | מבחני FIM');
    if (this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
    this.getOmdanemFromDB()
  }
  
  getOmdanemFromDB() {
    let filter:string ="origin <> 'admission'" // Origin is not admission
    this.caretxNursingProviderService.getOmdanem(this.id,filter,"fim").subscribe(
      data => this.fimOmdans = data,
      err => {
        console.log("Error", err)
        this.fimOmdans = []
      }
    )
  }

  OnSDrillToOmdan($event,snaqOmdan){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/fim/" + snaqOmdan.ID)
  }

  AddNewFim($event){
    let filter = '(omdan_status=\'A\' and origin <> \'admission\')'
    this.caretxNursingProviderService.getOmdanem(this.id,filter,"fim").subscribe((data: BaseOmdan[]) => {
      if (data.length > 0){
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
      else{
        this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/fim/new")
      }
    }); 
  }

  deleteEvent(i){

  }

  handleDeleteAlerMsg($event){

  }

  generateReport($event,event){
    
  }
  RetrunToList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem")
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }

}
