import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalFile } from '../../../app/models/customer';
import { Departments, FamilyRelations, Funder, NursingStatus } from '../../../app/models/generalhousedsetups';
import { PaitentFamily, SocialRegistrationDoc } from '../../../app/models/socialregistration';
import { AuthService } from '../../../app/services/auth.service'
import { SocialRegistrationSteps } from '../../../app/services/enums';
import { ToasterService } from '../../../app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSocialProviderService } from '../Social.data-provider.service';
import { CaretexComponent } from '../../../app/shared/CaretexComponent';
import { TabHistory } from '../../../app/models/genericTypes';
import { FamilymemberdialogComponent } from 'src/app/caretx-shared-module/CaretexModals/familymemberdialog/familymemberdialog.component';
import { CaretxSharedModulesProviderService } from 'src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-social-registration',
  templateUrl: './social-registration.component.html',
  styleUrls: ['./social-registration.component.css']
})
export class SocialRegistrationComponent extends CaretexComponent implements OnInit {
  isrecordClosed: boolean
  //canSocialPagesBeEditted: boolean
  medicalFile: MedicalFile = new MedicalFile()
  socailRegDoc: SocialRegistrationDoc
  id: string
  currentRowIndex: number
  departments: Departments[] = []
  nursingStatuses: NursingStatus[] = []
  funders: Funder[] = []
  familyRelations: FamilyRelations[] = []
  isEditMode: boolean = false
  isNew: boolean = false
  moduleName: string = "טופס רישום סוציאלי"
  tabHistory: TabHistory = new TabHistory()
  step: number = 0


  @ViewChild('mainForm') mainFormElement: NgForm;
  constructor(private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private caretxSetupService: CaretxSetupsProviderService,
    private router: Router,
    public dialog: MatDialog,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private sharedServices: CaretxSharedModulesProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,
    private authServive: AuthService) {
    super(medicalFileService)
    this.caretxSetupService.GetHouseDepartments().subscribe((data => {
      this.departments = data
    }))

    this.caretxSetupService.GetHouseFunders().subscribe((data => {
      this.funders = data
    }))

    this.caretxSetupService.GetFamilyRelations().subscribe((data => {
      this.familyRelations = data
    }))

    this.caretxSetupService.GetNursingStatuses().subscribe((data => {
      this.nursingStatuses = data
    }))
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex |טופס רישום סוציאלי');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)

    if (this.route.snapshot.params['tabid'] != "" && this.route.snapshot.params['tabid'] != undefined) {
      this.step = Number(this.route.snapshot.params['tabid']);
    }

    this.getSocialRegistrationFromDB()
  }

  getSocialRegistrationFromDB() {
    this.caretxSocialProviderService.getSocialRegistration(this.id, "").subscribe((data: SocialRegistrationDoc[]) => {
      if (data.length == 1) {
        this.socailRegDoc = new SocialRegistrationDoc(data[0])

      }
      if (data.length == 0) {
        this.socailRegDoc = new SocialRegistrationDoc()
        this.socailRegDoc.PersonDoc = this.medicalFileService.currentSelectedFile
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
      }

    });
  }


  setStep(index: number) {
    this.step = index;
  }

  LockDocument() {
  }

  Save() {
    if (this.socailRegDoc.ID == '00000000-0000-0000-0000-000000000000') {
      this.DoSave()
    } else {
      this.DoUpdate()
    }

  }

  DoUpdate() {
    this.socailRegDoc.UpdatedAt = new Date()
    this.socailRegDoc.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.UpdateSocialRegistration(this.socailRegDoc).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.socailRegDoc = new SocialRegistrationDoc(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialregistration/step/" + this.step)
        this.getSocialRegistrationFromDB()
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoSave() {
    this.socailRegDoc.UpdatedAt = new Date()
    this.socailRegDoc.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.createSocialRegistration(this.socailRegDoc).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.socailRegDoc = new SocialRegistrationDoc(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialregistration/step/" + this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }



  deleteRow(type, i) {
    this.currentRowIndex = i
    let isNew: boolean = false
    switch (type) {
      case 'A':
        isNew = this.socailRegDoc.FamilyLivesWithPaitent[i].New
        break
      case 'B':
        isNew = this.socailRegDoc.FamilyDoesNotLiveWithPaitent[i].New
        break
      case 'C':
        isNew = this.socailRegDoc.FamilyThatCanBecontacted[i].New
        break
      default:
        break
    }
    if (!isNew) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.handleDeleteFamilyMember(type)
        }
      })
    }
    else {
      switch (type) {
        case 'A':
          this.socailRegDoc.FamilyLivesWithPaitent.splice(i, 1);
          break
        case 'B':
          this.socailRegDoc.FamilyDoesNotLiveWithPaitent.splice(i, 1);
          break
        case 'C':
          this.socailRegDoc.FamilyThatCanBecontacted.splice(i, 1);
          break
        default:
          break
      }
    }
  }


  handleDeleteFamilyMember(type) {
    let familyMember: PaitentFamily
    switch (type) {
      case 'A':
        familyMember = this.socailRegDoc.FamilyLivesWithPaitent[this.currentRowIndex]
        break
      case 'B':
        familyMember = this.socailRegDoc.FamilyDoesNotLiveWithPaitent[this.currentRowIndex]
        break
      case 'C':
        familyMember = this.socailRegDoc.FamilyThatCanBecontacted[this.currentRowIndex]
        break
      default:
        break
    }
    this.caretxSocialProviderService.deleteFamilyMember(this.id, familyMember).subscribe({
      next: data => {
        switch (type) {
          case 'A':
            this.socailRegDoc.FamilyLivesWithPaitent.splice(this.currentRowIndex, 1);
            break
          case 'B':
            this.socailRegDoc.FamilyDoesNotLiveWithPaitent.splice(this.currentRowIndex, 1);
            break
          case 'C':
            this.socailRegDoc.FamilyThatCanBecontacted.splice(this.currentRowIndex, 1);
            break
          default:
            break
        }
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  editFamilyRow(type, i) {
    let filter = ""
    switch (type) {
      case 'A':
        filter = "lives_with_patient=true"
        this.OpenFamilyMemberDialog(this.socailRegDoc.FamilyLivesWithPaitent[i], filter, type)
        break
      case 'B':
        filter = "lives_with_patient=false"
        this.OpenFamilyMemberDialog(this.socailRegDoc.FamilyDoesNotLiveWithPaitent[i], filter, type)
        break
      case 'C':
        filter = "can_be_contacted=true"
        this.OpenFamilyMemberDialog(this.socailRegDoc.FamilyThatCanBecontacted[i], filter, type)
        break
      default:
        break
    }





  }


  OpenFamilyMemberDialog(familyMember: PaitentFamily, filter: string, type: string) {
    const dialogRef = this.dialog.open(FamilymemberdialogComponent, {
      data: {
        id: this.id,
        isNew: false,
        familyMember: familyMember,
        isDisabled: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // let familymember: PaitentFamily = result
      this.getFamilyMemmbers(filter, type)
    });
  }


  cancelEditRow(type, i) {
    switch (type) {
      case 'A':
        this.socailRegDoc.FamilyLivesWithPaitent[i].editMode = false;
        this.socailRegDoc.FamilyLivesWithPaitent[i].New = false
        this.socailRegDoc.FamilyLivesWithPaitent[i].Modified = false
        break
      case 'B':
        this.socailRegDoc.FamilyDoesNotLiveWithPaitent[i].editMode = false;
        this.socailRegDoc.FamilyDoesNotLiveWithPaitent[i].New = false
        this.socailRegDoc.FamilyDoesNotLiveWithPaitent[i].Modified = false
        break
      case 'C':
        this.socailRegDoc.FamilyThatCanBecontacted[i].editMode = false;
        this.socailRegDoc.FamilyThatCanBecontacted[i].New = false
        this.socailRegDoc.FamilyThatCanBecontacted[i].Modified = false
        break
      default:
        break
    }
  }

  addNewFamilyMember(type: string) {
    var newFamilyMember = new PaitentFamily()
    newFamilyMember.PersonelID = this.id
    newFamilyMember.TenantID = this.authServive.LoggedInTenantID
    newFamilyMember.editMode = true;
    newFamilyMember.CreatedAt = new Date()
    newFamilyMember.New = true
    newFamilyMember.Modified = false
    let filter = ""

    switch (type) {
      case 'A':
        filter = "lives_with_patient=true"
        newFamilyMember.LivesWithPatient = true
        //   this.socailRegDoc.FamilyLivesWithPaitent.unshift(newFamilyMember)
        break
      case 'B':
        filter = "lives_with_patient=false"
        newFamilyMember.LivesWithPatient = false
        // this.socailRegDoc.FamilyDoesNotLiveWithPaitent.unshift(newFamilyMember)
        break
      case 'C':
        filter = "can_be_contacted=true"
        newFamilyMember.CanBeContacted = true
        // this.socailRegDoc.FamilyThatCanBecontacted.unshift(newFamilyMember)
        break
      default:
        break
    }

    // this.medicalFile.Family.unshift(newFamilyMember)
    const dialogRef = this.dialog.open(FamilymemberdialogComponent, {
      data: {
        id: this.id,
        isNew: true,
        familyMember: newFamilyMember,
        isDisabled: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getFamilyMemmbers(filter, type)
    });




  }


  getFamilyMemmbers(filter: string, type: string) {
    this.sharedServices.getFamilyMemebrs(this.id, filter).subscribe(
      data => {
        switch (type) {
          case 'A':
            this.socailRegDoc.FamilyLivesWithPaitent = data
            break
          case 'B':
            this.socailRegDoc.FamilyDoesNotLiveWithPaitent = data
            break
          case 'C':
            this.socailRegDoc.FamilyThatCanBecontacted
            break
          default:
            break
        }

        this.medicalFile.Family = data
      },
      err => {
        console.log("Error", err)
        this.medicalFile.Family = []

      }
    )
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty
  }


}
