import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { LabOrderCategories, LabOrderCheck, LabTemplate } from '../../../../../../src/app/models/testlabchecks';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretexComponent } from '../../../../shared/CaretexComponent';
import { MedicalFileProviderService } from '../../../../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';

@Component({
  selector: 'app-labcategorytests',
  templateUrl: './labcategorytests.component.html',
  styleUrls: ['./labcategorytests.component.css']
})
export class LabcategorytestsComponent extends CaretexComponent  implements OnInit {
  @ViewChild('tabnavbtn') tabnavbtn: ElementRef;


  categoryLabs: LabOrderCategories // LabTemplate[] = []
  selectedLabTemplates: LabTemplate[] = []
  currentRowIndex: number
  hmo: string
  categoryId: string
  hmoDesr: string
  categoryDescr: string
  CurrentStep: string
  SelectedLabIndex: number
  modalTarget: string

  constructor(
    private _title: Title,
    private router: Router,
    private toastrService: ToasterService,
    public medicalFileServive: MedicalFileProviderService,
    private authServive: AuthService,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private _ar: ActivatedRoute,) {super(medicalFileServive)
  }

  ngOnInit(): void {
    this.CurrentStep = "A"
    this.modalTarget = "#systemAlertModal"
    this._title.setTitle('Caretex |הגדרות מעבדה');
    this.hmo = this._ar.snapshot.params['hmo'];
    this.hmoDesr = this.translateHmo()  
    this.categoryDescr = this._ar.snapshot.params['categoryDescr'];
    this.categoryId = this._ar.snapshot.params['categoryId'];
    this.getLabTypesFromDB()

  }

  getLabTypesFromDB() {
    let filter = '(category_id=' + '\'' + this.categoryId + '\'' + ')'
    this.caretxSetupsProviderService.GetCategoryLabs(this.hmo, filter).subscribe(
      data => {
        if (data.length == 1) {
          this.categoryLabs = data[0]
          if (this.CurrentStep == "B") {
            this.selectedLabTemplates = this.categoryLabs.LabOrders[this.SelectedLabIndex].LabOrderTemplates

          }
        }
        else {
          let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
          this.toastrService.Error("", errorMsg)
        }
      },
      err => {
        this.toastrService.Error("", "שגיאת מערכת")
      }
    )
  }


  save() {
    if (this.CurrentStep == 'A')
      this.AddUpdateLabToCategory()
    else
      this.AddUpdateLabTemplates()

  }

  AddUpdateLabToCategory() {
    this.caretxSetupsProviderService.AddLabToCategory(this.categoryLabs.LabOrders).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getLabTypesFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  AddUpdateLabTemplates() {
    let templates = this.categoryLabs.LabOrders[this.SelectedLabIndex].LabOrderTemplates
    this.caretxSetupsProviderService.AddTemplateToLab(templates).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getLabTypesFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  deleteRow(i) {
    this.currentRowIndex = i
    if (!this.categoryLabs.LabOrders[i].New) {

      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false, 
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
        }
      })

    }
    else {
      this.categoryLabs.LabOrders.splice(this.currentRowIndex, 1);
    }
  }

  editLabCategoryRow(i) {
    this.categoryLabs.LabOrders[i].editMode = true
    this.categoryLabs.LabOrders[i].Modified = true
  }

  cancelLabCategoryRow(i) {
    this.categoryLabs.LabOrders[i].editMode = false
    this.categoryLabs.LabOrders[i].Modified = false
  }

  cancelEditOfTemplateRow(i) {
    this.categoryLabs.LabOrders[this.SelectedLabIndex].LabOrderTemplates[i].editMode = false
    this.categoryLabs.LabOrders[this.SelectedLabIndex].LabOrderTemplates[i].Modified = false
  }

  editTemplateRow(i) {
    this.categoryLabs.LabOrders[this.SelectedLabIndex].LabOrderTemplates[i].editMode = true
    this.categoryLabs.LabOrders[this.SelectedLabIndex].LabOrderTemplates[i].Modified = true
  }

  translateHmo() {
    switch (this.hmo) {
      case "clalit":
        return "כללית"
      case "macabi":
        return "מכבי"
      case "leumit":
        return "לאומית"
      case "meohedet":
        return "מאוחדת"
      default:  return ""

    }
  }

  showValidationError() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "צריך לבחור בדיקה קודם ,אם זאת בדיקה חדשה אז צריך לשמור אותה קודם",
      icon: 'warning',
      allowOutsideClick: false,
      allowEscapeKey: false
    })
  }

  addLabTemplate() {
    var newLabTemplate = new LabTemplate()
    newLabTemplate.New = true
    newLabTemplate.TestCategory = this.categoryId
    newLabTemplate.PrimaryCode = this.categoryLabs.LabOrders[this.SelectedLabIndex].PrimaryCode
    newLabTemplate.HMO = this.hmo
    newLabTemplate.Modified = false
    newLabTemplate.editMode = true
    newLabTemplate.RealmID = this.authServive.LoggedInTenantID
    this.categoryLabs.LabOrders[this.SelectedLabIndex].LabOrderTemplates.unshift(newLabTemplate)
  }

  addLabToCategory() {
    var newLabOrderCheck = new LabOrderCheck()
    newLabOrderCheck.CategoryId = this.categoryId
    newLabOrderCheck.HMO = this.hmo
    newLabOrderCheck.Modified = false
    newLabOrderCheck.editMode = true
    newLabOrderCheck.New = true
    newLabOrderCheck.RealmID = this.authServive.LoggedInTenantID
    this.categoryLabs.LabOrders.unshift(newLabOrderCheck)
  }

  BackToList() {
    if (this.CurrentStep == "A")
      this.router.navigate(['labs_setup']);
    else if (this.CurrentStep == "B")
      this.selectTab("A")
  }

  selectTab(tabId: string) {
    if (this.SelectedLabIndex == null || this.SelectedLabIndex == undefined) {
      this.tabnavbtn.nativeElement.click()
    } else {
      this.CurrentStep = tabId
    }

  }

  openLabValuesPage(i) {
    this.SelectedLabIndex = i
    this.CurrentStep = "B"
    if (this.categoryLabs.LabOrders[i] != undefined && this.categoryLabs.LabOrders[i] != null)
      this.selectedLabTemplates = this.categoryLabs.LabOrders[i].LabOrderTemplates
    else
      this.selectedLabTemplates = []
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const statusesListChanged = this.categoryLabs.LabOrders.some(row => {
      return row.New || row.editMode
    })
    return  statusesListChanged
  }



}
