import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../src/environments/environment';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { catchError } from 'rxjs/operators';
import { MedicalDiagnoses } from '../../../../src/app/models/drug';
import { ArchiveAlert, MedicalFile } from '../../../../src/app/models/customer';
import { PaitentFamily } from '../../models/socialregistration';
import { ModelMapper } from 'src/app/models/modelmapper';
import { MedicalFileStatus } from 'src/app/models/generalhousedsetups';



@Injectable({ providedIn: 'root' })
export class MedicalFileProviderService {
  public medicalDiagnoses: MedicalDiagnoses[] = []
  public currentSelectedFile: MedicalFile
  dataChange: BehaviorSubject<MedicalFile[]> = new BehaviorSubject<MedicalFile[]>([]);
  // Temporarily stores data from dialogs
  dialogData: any;
  openedFile: BehaviorSubject<MedicalFile> = new BehaviorSubject<MedicalFile>(new MedicalFile());
  // Used for In Memory cache
  private observableCache: { [key: string]: Observable<MedicalFile> } = {}
  private medicalFileCache: { [key: string]: MedicalFile } = {}






  constructor(private httpClient: HttpClient) {
  }

  public set CurrentOpenedFile(file: MedicalFile) {
    this.currentSelectedFile = file
  }

  get data(): MedicalFile[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  updateMedicalFile(medicalFile: MedicalFile) {
    this.observableCache[medicalFile.PersonelID] = null
    this.medicalFileCache[medicalFile.PersonelID] = null
    return this.httpClient.put(environment.gateway + '/medicalFile/' + medicalFile.PersonelID, medicalFile)

  }

  newMedicalFile(medicalFile: MedicalFile) {
    return this.httpClient.post(environment.gateway + '/medicalFile/' + medicalFile.PersonelID, medicalFile)
  }

  getMedicalFiles(filter: string): void {
    console.log("----------------------")
    console.log(filter)
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    this.httpClient.get<MedicalFile[]>(environment.gateway + '/medicalFiles' + filterQuery).subscribe(data => {
      this.dataChange.next(data);
    },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      });
  }

  addMedicalFile(medicalFile: MedicalFile): void {
    this.dialogData = medicalFile;
  }

  deleteMedicalFile(id: number): void {
    console.log(id);
  }

  getMedicalFile(id: string) {
   // if (this.medicalFileCache[id]) return of(this.medicalFileCache[id])
   // else if (this.observableCache[id]) return this.observableCache[id]
   /// else 
    this.observableCache[id] = this.fetchMedicalFile(id)
    return this.observableCache[id]
  }


  private fetchMedicalFile(id: string): Observable<MedicalFile> {

    const url = String(environment.gateway + '/medicalFiles/' + id)
    return this.httpClient.get<MedicalFile>(url).pipe(
      map((rowData: any) => this.mapCachedMedicalFile(rowData)),
      catchError(<T>(error: any, result?: T) => {
        console.log(error);
        return of(result as T);
      })

    );

  }
  mapCachedMedicalFile(rowData: any): any {
    this.observableCache[rowData.PersonelID] = null
    this.currentSelectedFile = this.medicalFileCache[rowData.PersonelID] = new MedicalFile(rowData)
    return this.medicalFileCache[rowData.PersonelID]
  }

  resetCachedMedicalFile(id: string) {
    this.observableCache[id] = null
    this.medicalFileCache[id] = null
  }

  public ResetCachedMedicalFile(id: string) {
    this.observableCache[id] = null
    this.medicalFileCache[id] = null
  }




  public uploadImage(personelId, image) {
    const formData: FormData = new FormData();
    formData.append('Image', image, image["name"]);
    return this.httpClient.post(environment.gateway + '/medicalFile/' + personelId + '/uploadimage', formData)
  }



  //Delete Family Row
  deleteFamilyMember(personelId: string, member: PaitentFamily) {
    return this.httpClient.delete(environment.gateway + '/medicalFile/' + personelId + '/familymember/' + member.ID)
  }



  SendFileToArchive(personelId: string): Observable<ArchiveAlert[]> {
   
    return this.httpClient.get(environment.gateway + '/archivefile/' + personelId ).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(ArchiveAlert).map(item)
        })
      }), catchError(this.errorHandler));
  }


  LockMedicalFile(medicalFileId: string ,  selectedStatus: string) {

    return this.httpClient.put(environment.gateway + '/archivefile/' + medicalFileId, selectedStatus)

  }


  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }

}


