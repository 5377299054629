import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { FamilyRelations, SocialProvider } from '../../../../../src/app/models/generalhousedsetups';
import { SocialAdmission } from '../../../../../src/app/models/socialadmission';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSocialProviderService } from '../../Social.data-provider.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import {  CartexEvent } from '../../../../../src/app/models/genericTypes';
import { Attachment } from '../../../../../src/app/models/attachments';
import { CaretxSharedModulesProviderService } from '../../../../../src/app/caretx-shared-module/sharedmodule.data-provider.services';


@Component({
  selector: 'app-externalsocialadmission',
  templateUrl: './externalsocialadmission.component.html',
  styleUrls: ['./externalsocialadmission.component.css']
})
export class ExternalsocialadmissionComponent extends CaretexComponent implements OnInit {

  currentSocialAdmission: SocialAdmission = new SocialAdmission()
  showGeneratedPdf:boolean = false
  caretexEvent: CartexEvent = new CartexEvent()
  id: string
  Socialid: string
  moduleName: string = "קבלה סוציאלית"
  step: number = 0


  constructor(
    private _title: Title,
    private router: Router,
    public medicalFileService: MedicalFileProviderService,
    private route: ActivatedRoute,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private _ar: ActivatedRoute,
    private toastrService: ToasterService,
    private authService: AuthService,
    private caretxSetupsProviderService: CaretxSetupsProviderService) {
    super(medicalFileService)
   
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex |טופס קבלה סוציאלית');
    if(this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'];
    this.Socialid = this.route.snapshot.params.admissionid
    this.CheckIfComponentIsDisabled(this.id)

    if (this.Socialid == 'new') {
      this.getSocialAdmissionFromDB("new")
    } else {
      this.getSocialAdmissionFromDB("record")
    }
  }


  getSocialAdmissionFromDB(mode: string) {
    let filter =""
    if (mode != "new") {
      filter = '(id=' + '\'' +   this.Socialid + '\'' + ')'
    }

    this.caretxSocialProviderService.getSocialAdmission(this.id, filter, mode).subscribe((data: SocialAdmission[]) => {
      if (data.length == 1) {
        this.currentSocialAdmission = new SocialAdmission(data[0])
        if (this.currentSocialAdmission.PdfFile != undefined) {
            this.downloadFile(this.currentSocialAdmission.PdfFile )
        }
      }
    });
  }


  Save() {
    if (this.Socialid == 'new') {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }

  }

  DoSave() {
    this.currentSocialAdmission.UpdatedAt = new Date()
    this.currentSocialAdmission.PersonelID = this.id
    this.currentSocialAdmission.CreatedAt = this.currentSocialAdmission.UpdatedAt
    this.currentSocialAdmission.CreatedById = this.authService.LoggedInUser.UID
    this.currentSocialAdmission.TenantID = this.authService.LoggedInTenantID
    this.currentSocialAdmission.Status="C"
    this.currentSocialAdmission.ClosedById = this.authService.LoggedInUser.UID
    this.currentSocialAdmission.Closed_at =  new Date()
    this.currentSocialAdmission.IsExternalAdmission = true
    this.caretxSocialProviderService.createSocialAdmission(this.currentSocialAdmission).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentSocialAdmission = new SocialAdmission(data)
        this.uploadAdmissionAsPdf(this.currentSocialAdmission.ID)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    this.currentSocialAdmission.UpdatedAt = new Date()
    this.currentSocialAdmission.TenantID = this.authService.LoggedInTenantID
    this.caretxSocialProviderService.UpdateSocialAdmission(this.currentSocialAdmission).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentSocialAdmission = new SocialAdmission(data)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.medicalFileService.ResetCachedMedicalFile(this.id)
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialadmission/" + this.currentSocialAdmission.ID + "/step/" + this.step)

      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  BackToList() {
    this.router.navigateByUrl("/caretexfile/" + this.id + "/socialadmission")
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }

  /******************************************************************************* */
  OpenFileDialog(){
    (document.querySelector('input[id="doc-input"]') as HTMLElement).click()
  }

  fileName:string
  DocToUpload: any = null;
  fileTitle:string
  file:any
  attachedFile: Attachment = new Attachment()
  
  prepareDocumentForUpload(files: FileList) {
    this.fileName = ""
    this.DocToUpload = null
    this.fileTitle = ""
    this.file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = () => {
      this.fileName = this.file["name"]
      this.DocToUpload = reader.result
      this.fileTitle = this.file["name"]
    };
  }

  uploadAdmissionAsPdf(socialId:string) {
    this.attachedFile.CreatedAt = new Date()
    this.attachedFile.FileCategory ="socialadmission"
    this.attachedFile.UpdatedAt = this.attachedFile.CreatedAt
    this.attachedFile.PersonelID = this.id
    this.attachedFile.FileDataBase64 = this.DocToUpload
    this.attachedFile.Description = "Imported As external social Admission"
    this.attachedFile.FileName = this.fileName
    this.attachedFile.Uploaded_at = new Date()
    this.attachedFile.CreatedById = this.authService.LoggedInUser.UID
    this.attachedFile.CreatedBy = this.authService.LoggedInUser
    this.attachedFile.Uploaded_at = new Date()
    this.attachedFile.LinkedTo = socialId
    this.caretxSharedlService.uploadFile(this.attachedFile).subscribe({
      next: data => {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.medicalFileService.ResetCachedMedicalFile(this.id)
        this.router.navigateByUrl("caretexfile/" + this.id + "/externalsocialadmission/" + socialId)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  @ViewChild('pdfViewer') pdfViewer;
  isPdfFile:boolean = false
  file223 :string = ""
  pdfContent:any

  downloadFile(file: Attachment) {
    let extension = (file.FileName).split('.').pop();
    let typeOfBlob = "application/octet-stream"
    switch (extension) {
      case "pdf":
        typeOfBlob = "application/pdf"
          this.isPdfFile = true
        break
      case "jpg":
        typeOfBlob = "image/jpg"
        this.isPdfFile = false
        break
      case "jpeg":
        typeOfBlob = "image/jpeg"
        this.isPdfFile = false
        break
      case "png":
        typeOfBlob = "image/png"
        this.isPdfFile = false
        break
      default:
        break
    }



    this.caretxSharedlService.downloadFile(this.id, file)
      .subscribe(data => {
        var file = new Blob([data], { type: typeOfBlob });
        


        this.pdfContent = URL.createObjectURL(file);
        if (this.isPdfFile){
          this.pdfViewer.pdfSrc = file
          this.showGeneratedPdf = true
          this.pdfViewer.refresh();
        }else{
        }


      }, error => {
        console.log(error);
        var errorAsString = "failed to download file"
        try {
          var enc = new TextDecoder("utf-8")
          var text = enc.decode(error.error)
          errorAsString = JSON.parse(text).error
        } catch (e) {

        }
      })
  }

}
