
import { propertyMap } from './modelmapper'
import { User } from './user'
import { PersonelSensitivity } from './drugsenstivity'
import { PersonalMeasuresHeader } from './measure'
import { NurseCognitiveTest, NurseExamination } from './nurseexamination'
import { Diagnoses } from './diagnoses'
import { DrugInstruction } from './drugInstruction'
import { Attachment } from './attachments'
import { NotificationMap } from './notificationMap'
import { MedicalFile } from './customer'
// Need Server to send Payload in below structure
export class NurseAdmission {
    clientId: string 
    @propertyMap('ID')
    ID: string 
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('RealmID')
    RealmID: string
    @propertyMap('Created_at')
    Created_at: Date
    @propertyMap('LastUpdate_at')
    LastUpdate_at: Date
    @propertyMap('Background')
    Background: string
    @propertyMap('BackgroundLastUpdated_at')
    BackgroundLastUpdated_at: Date
    @propertyMap('Habits')
    Habits: string
    @propertyMap('HabitsLastUpdated_at')
    HabitsLastUpdated_at: Date
    @propertyMap('Hobbies')
    Hobbies: string
    @propertyMap('HobbiesLastUpdated_at')
    HobbiesLastUpdated_at: Date
    @propertyMap('NurseSummary')
    NurseSummary: string
    @propertyMap('NurseSummaryLastUpdated_at')
    NurseSummaryLastUpdated_at: Date
    @propertyMap('NursingPlan')
    NursingPlan: string
    @propertyMap('NursingPlanLastUpdated_at')
    NursingPlanLastUpdated_at: Date
    @propertyMap('Status')
    Status: string
    @propertyMap('Closed_at')
    Closed_at: Date
    @propertyMap('Sensitivity')
    public Sensitivity: PersonelSensitivity
    @propertyMap('PersonalMeasuresHeader')
    public PersonalMeasuresHeader: PersonalMeasuresHeader
    @propertyMap('OmdanfimId')
    public OmdanfimId: string
    @propertyMap('OmdanbohseId')
    public OmdanbohseId: string
    @propertyMap('Omdan2Id')
    public Omdan2Id: string
    @propertyMap('OmdansnaqId')
    public OmdansnaqId: string
    @propertyMap('Omdan5Id')
    public Omdan5Id: string
    @propertyMap('Omdan4Id')
    public Omdan4Id: string
    @propertyMap('DiabeticFootTestId')
    public DiabeticFootTestId: string
    @propertyMap('OmdannortonId')
    public OmdannortonId: string
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('NurseExamination')
    public NurseExamination: NurseExamination
    @propertyMap('NurseCognitiveTest')
    public NurseCognitiveTest: NurseCognitiveTest
    @propertyMap('Diagnoses')
    public Diagnoses: Diagnoses[]
    @propertyMap('DrugInstructions')
    public DrugInstructions: DrugInstruction[]
    ShowDetails: boolean
    backgroundModified: boolean
    hobbiesModified: boolean
    HabitsModified: boolean
    nurseSummaryModified: boolean
    nursingPlanModified: boolean

    // Service  
    @propertyMap('PreviousFunctionalState')
    public PreviousFunctionalState: string
    @propertyMap('CommunicationAndLanguages')
    public CommunicationAndLanguages: string
    @propertyMap('IsExternalAdmission')
    public IsExternalAdmission: boolean
    @propertyMap('PdfFile')
    public PdfFile: Attachment


    constructor(data?: Object) {

        if (data != undefined && data != null) {
            this.ShowDetails = false;
            this.ID = data["ID"] 
            this.RealmID = data["RealmID"]
            this.ClosedById = data["ClosedById"] // Logged in user
            this.clientId = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            this.PersonelID = data["PersonelID"]
            this.Created_at = data["Created_at"]
            this.LastUpdate_at = data["LastUpdate_at"]
            this.BackgroundLastUpdated_at = data["BackgroundLastUpdated_at"]
            this.HobbiesLastUpdated_at = data["HobbiesLastUpdated_at"]
            this.NurseSummaryLastUpdated_at = data["NurseSummaryLastUpdated_at"]
            this.HabitsLastUpdated_at = data["HabitsLastUpdated_at"]
            this.Background = data["Background"]
            this.Habits = data["Habits"]
            this.Hobbies = data["Hobbies"]
            this.NurseSummary = data["NurseSummary"]
            this.Sensitivity = new PersonelSensitivity(data["Sensitivity"])
            this.PersonalMeasuresHeader = data["PersonalMeasuresHeader"]
            this.OmdanfimId =data["OmdanfimId"]
            this.OmdanbohseId =data["OmdanbohseId"] 
            this.Omdan2Id =data["Omdan2Id"]
            this.OmdansnaqId =data["OmdansnaqId"]
            this.Omdan5Id =data["Omdan5Id"]
            this.Omdan4Id =data["Omdan4Id"]
            this.OmdannortonId =data["OmdannortonId"]
            this.ShowDetails = false
            this.Status = data["Status"]
            this.Closed_at = data["Closed_at"]
            this.backgroundModified = false
            this.hobbiesModified = false
            this.HabitsModified = false
            this.nurseSummaryModified = false
            this.nursingPlanModified = false
            this.NurseExamination = data["NurseExamination"]
            this.NurseCognitiveTest = data["NurseCognitiveTest"]
            this.DrugInstructions = data["DrugInstructions"]
            this.Diagnoses = data["Diagnoses"]
            this.DiabeticFootTestId = data["DiabeticFootTestId"]
            this.PreviousFunctionalState = data["PreviousFunctionalState"]
            this.CommunicationAndLanguages = data["CommunicationAndLanguages"]
            this.IsExternalAdmission = data["IsExternalAdmission"]
            this.PdfFile = data["PdfFile"]

        }
        else {
            this.ShowDetails = false;
            this.clientId = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            this.Created_at = new Date()
            this.LastUpdate_at = new Date()
            this.NurseExamination =  new NurseExamination()
            this.Background = ""
            this.Habits = ""
            this.Hobbies = ""
            this.NurseSummary = ""
            this.Sensitivity = new PersonelSensitivity()
            this.PersonalMeasuresHeader = new PersonalMeasuresHeader()
            this.PersonalMeasuresHeader.PersonalMeasures = []
            this.ShowDetails = false
            this.Status = 'A'
            this.backgroundModified = false
            this.hobbiesModified = false
            this.HabitsModified = false
            this.nurseSummaryModified = false
            this.nursingPlanModified = false
        }
    }

    NewNurseAdmission() {
        this.ShowDetails = false;
        this.clientId = Math.floor(Math.random() * 16).toString(16).toUpperCase();
       // this.PersonelID = undefined
        this.Created_at = new Date()
        this.LastUpdate_at = new Date()
        this.Background = ""
        this.Habits = ""
        this.Sensitivity = new PersonelSensitivity()
        this.PersonalMeasuresHeader = new PersonalMeasuresHeader()
        this.PersonalMeasuresHeader.PersonalMeasures = []
        this.DrugInstructions = []
        this.Diagnoses = []
       // this.Omdanbohse = new BaseOmdan()
       // this.Omdanfim = new BaseOmdan()
       // this.Omdanbohse = new BaseOmdan()
       // this.Omdan2 = new BaseOmdan()
      //  this.OmdanSnaq = new BaseOmdan()
      //  this.Omdan5 = new BaseOmdan()
      //  this.Omdan4 = new BaseOmdan()
      //  this.OmdanNorton = new BaseOmdan()
        this.Status = 'A'
    }




}



export class NurseFollowUp {
    @propertyMap('ID')
    ID: string 
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PersonelID')
    PersonelID: string

    @propertyMap('PersonDoc')
    PersonDoc: MedicalFile


    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('RichText')
    RichText: string
    @propertyMap('Status')
    Status: string
    @propertyMap('ClosedAt')
    ClosedAt: Date
    @propertyMap('Sensitivity')
    public Sensitivity: PersonelSensitivity
    @propertyMap('PersonalMeasuresHeader')
    public PersonalMeasuresHeader: PersonalMeasuresHeader
    @propertyMap('OmdanfimId')
    public OmdanfimId: string
    @propertyMap('OmdanbohseId')
    public OmdanbohseId: string
    @propertyMap('Omdan2Id')
    public Omdan2Id: string
    @propertyMap('OmdansnaqId')
    public OmdansnaqId: string
    @propertyMap('Omdan5Id')
    public Omdan5Id: string
    @propertyMap('Omdan4Id')
    public Omdan4Id: string
    @propertyMap('OmdannortonId')
    public OmdannortonId: string
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('NurseExamination')
    public NurseExamination: NurseExamination
    @propertyMap('NurseCognitiveTest')
    public NurseCognitiveTest: NurseCognitiveTest
    @propertyMap('LastUpdateById')
    public LastUpdateById: string
    public LastUpdateBy: User
    @propertyMap('NextTrackDate')
    public NextTrackDate: Date

    @propertyMap('NurseObservationFiles')
    public NurseObservationFiles: Attachment[]
    @propertyMap('Notifications')
    public Notifications: NotificationMap[]

    @propertyMap('DrugInstructions')
    public DrugInstructions:DrugInstruction[]
    @propertyMap('Summary')
    public Summary: string

    @propertyMap('FollowUpSubject')
    FollowUpSubject: string
    
    public Disabled:boolean = true
    public OpenedForEdit:boolean = false

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"] 
            this.PersonDoc = data["PersonDoc"] 
            this.Summary = data["Summary"] 
            this.TenantID = data["TenantID"]
            this.ClosedById = data["ClosedById"] // Logged in user
            this.PersonelID = data["PersonelID"]
             this.RichText = data["RichText"]
            this.Sensitivity = new PersonelSensitivity(data["Sensitivity"])
            this.PersonalMeasuresHeader = data["PersonalMeasuresHeader"]
            this.OmdanfimId =data["OmdanfimId"]
            this.OmdanbohseId =data["OmdanbohseId"] 
            this.Omdan2Id =data["Omdan2Id"]
            this.OmdansnaqId =data["OmdansnaqId"]
            this.Omdan5Id =data["Omdan5Id"]
            this.Omdan4Id =data["Omdan4Id"]
            this.OmdannortonId =data["OmdannortonId"]
            this.Status = data["Status"]
            this.NurseExamination = data["NurseExamination"]
            this.NurseCognitiveTest = data["NurseCognitiveTest"]
            this.LastUpdateById = data["LastUpdateById"]
            this.NextTrackDate = data["NextTrackDate"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.NurseObservationFiles = data["NurseObservationFiles"]
            this.Notifications = data["Notifications"]
            this.Disabled = true
            this.DrugInstructions = data["DrugInstructions"]
            this.FollowUpSubject = data["FollowUpSubject"]
        }
        else {
          //  this.ClosedById = undefined // Logged in user
           // this.PersonelID = undefined
            this.Sensitivity = new PersonelSensitivity()
            this.PersonalMeasuresHeader = new PersonalMeasuresHeader()
            this.PersonalMeasuresHeader.PersonalMeasures = []
            this.Status = 'A'
            this.DrugInstructions =[]
        }
    }
}


