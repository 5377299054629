import { Component, Inject, OnInit } from '@angular/core';

import { BedSoreHelpItem } from '../../../models/bedsore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-helperitem-dialog',
  templateUrl: './helperitem-dialog.component.html',
  styleUrls: ['./helperitem-dialog.component.css']
})
export class HelperitemDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<HelperitemDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  currentHelperItem : BedSoreHelpItem
  Disabled:boolean = false
  ngOnInit(): void {

    this.currentHelperItem = this.data.HelperItem
    this.Disabled = this.data.Disabled
    
  }

  conevrtoDateTime($event) {
    return new Date($event)
  }

  submit(){
    this.dialogRef.close(this.currentHelperItem);
  }

}
