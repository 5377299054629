import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxNursingProviderService } from '../../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { BaseOmdan } from '../../../../../../src/app/models/omdans';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';

@Component({
  selector: 'app-mini-mental-list',
  templateUrl: './mini-mental-list.component.html',
  styleUrls: ['./mini-mental-list.component.css']
})
export class MiniMentalListComponent extends CaretexComponent implements OnInit {


  miniMentals:BaseOmdan[] =[]
  id: string
  moduleName:string="מבחני MINI - MINTALS"
  
  constructor( 
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private caretxNursingProviderService:CaretxNursingProviderService,
    private toastrService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,) { super(medicalFileService)}


    ngOnInit(): void {
      this._title.setTitle('Caretx | מבחני MINI-MENTALS');
      if(this.route.parent != null)
      this.id = this.route.parent.snapshot.params['id'];
      this.CheckIfComponentIsDisabled(this.id)
      this.getOmdanemFromDB()
    }

  
    getOmdanemFromDB() {

      this.caretxNursingProviderService.getOmdanem(this.id,"","minimental").subscribe(
        data => this.miniMentals = data,
        err => {
          this.miniMentals = []
        }
      )
    }

    OnSDrillToOmdan($event,miniMentalExam :BaseOmdan){
      this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams/minimental/" + miniMentalExam.ID)
    }


    AddNewExam($event){
      let filter = '(omdan_status=\'A\')'
      this.caretxNursingProviderService.getOmdanem(this.id,filter,"minimental").subscribe((data: BaseOmdan[]) => {
        if (data.length > 0){
          let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
          this.toastrService.Error("", errorMsg)
        }
        else{
          this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams/minimental/new")
        }
      }); 
    }


    canDeactivate() {
      this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
    }
  
    CheckIfDocumentIsDirty(){
      return false;
    }

    deleteEvent(i){

    }

    generateReport($event,event){
    
    }

    RetrunToList(){
      this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams")
    }

}
