import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { CaretxMedicalProviderService } from '../../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { UserselectionDialogComponent } from '../../../../../../src/app/caretx-shared-module/CaretexModals/userselection-dialog/userselection-dialog.component';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalFile } from '../../../../../../src/app/models/customer';
import { HospitalReferral } from '../../../../../../src/app/models/hospitalreferrals';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { UserRoles } from '../../../../../../src/app/services/enums';
import { ReportingService } from '../../../../../../src/app/services/reporting.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-hospital-referral-details',
  templateUrl: './hospital-referral-details.component.html',
  styleUrls: ['./hospital-referral-details.component.css']
})
export class HospitalReferralDetailsComponent extends CaretexComponent implements OnInit {

  isRecordClosed: boolean = false
  currentRecord: HospitalReferral 
  moduleName: string = "הפנייה"
  @ViewChild('pdfViewer') pdfViewer;
  selectedFile: MedicalFile
  pdfContent: any
  userRole: UserRoles = UserRoles.Doctor
  showGeneratedPdf: boolean = false
  @ViewChild('mainform') mainFormElement: NgForm;
  selectedDoctor: string

  constructor(
    public medicalFileService: MedicalFileProviderService,
    private authService: AuthService,
    private medicalProviderService: CaretxMedicalProviderService,
    private router: Router,
    public dialog: MatDialog,
    public reportingService: ReportingService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,) {
    super(medicalFileService)
  }


  ngOnInit(): void {
    if (this.route.parent != undefined) {
      this.id = this.route.parent.snapshot.params['id'];
    }
    this.CheckIfComponentIsDisabled(this.id)
    if (this.route.snapshot.params.referralid == 'new') {
      this.selectedDoctor = null
      this.currentRecord = new HospitalReferral()
      this.currentRecord.PersonelID = this.id
      this.currentRecord.TenantID = this.authService.LoggedInTenantID
      this.currentRecord.ReferralDate = this.currentRecord.CreatedAt = new Date()
      this.currentRecord.Status = "A"

      this.medicalFile$.subscribe(val => {
        this.currentRecord.Guardian = this.medicalFile1.GuardianName.toString()
        this.currentRecord.GuardianPhone = this.medicalFile1.GuardianPhone
      })
    }
    else {
      this.getHospitalReferralFromDB(this.route.snapshot.params.referralid)
    }
  }

  getHospitalReferralFromDB(referralid: string) {
    let filter = ""
    filter = '(id=' + '\'' + referralid + '\'' + ')'
    this.medicalProviderService.getReferrals(filter, this.id, 1, "").subscribe((data: HospitalReferral[]) => {
      if (data.length == 1) {
        this.currentRecord = new HospitalReferral(data[0])
        this.isRecordClosed = (this.currentRecord.Status == "C")
        this.selectedDoctor = this.currentRecord.Doctor.firstName + " " + this.currentRecord.Doctor.lastName
      }
      else {
        let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }

  submitForm() {
    this.mainFormElement.onSubmit(undefined);
    if (this.mainFormElement.form.invalid) {
      Swal.fire({
        title: 'לידיעתך',
        text: "אחד או יותא מהשדות לא מולאו כמו שצריך",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false

      })
    }
  }

  Save() {
    if (this.currentRecord.ID == "" || this.currentRecord.ID == null || this.currentRecord.ID == "00000000-0000-0000-0000-000000000000") {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoUpdate() {
    this.medicalProviderService.updateReferral(this.currentRecord).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentRecord = new HospitalReferral(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'ignore';
        if (this.currentRecord.Status == "C") {
          this.router.navigateByUrl("caretexfile/" + this.id + "/hospitalreferrals/" + this.currentRecord.ID + "/locked")
        } else {
          this.router.navigateByUrl("caretexfile/" + this.id + "/hospitalreferrals/" + this.currentRecord.ID)
        }
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  DoSave() {
    this.medicalProviderService.createNewReferral(this.currentRecord).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentRecord = new HospitalReferral(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        if (this.currentRecord.Status == "C") {
          this.router.navigateByUrl("caretexfile/" + this.id + "/hospitalreferrals/" + this.currentRecord.ID + "/locked")
        } else {
          this.router.navigateByUrl("caretexfile/" + this.id + "/hospitalreferrals/" + this.currentRecord.ID)
        }
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  LockRecord() {
    this.currentRecord.Status = "C"
    this.currentRecord.ClosedAt = new Date()
    this.currentRecord.ClosedById = this.authService.LoggedInUser.UID
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם את/ה רוצה לנעול ההפנייה הרפואית ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תנעל',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentRecord.Status = "C"
        this.currentRecord.ClosedAt = new Date()
        this.currentRecord.ClosedById = this.authService.LoggedInUser.UID
        this.Save()
      }
    })
  }

  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/hospitalreferrals/list")
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty;
  }

  /************************* Print Report **************************************** */
  openPDFViewer() {
   
  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }

  /****************************************************************************** */

  OpenDoctorsDialog() {
    const dialogRef = this.dialog.open(UserselectionDialogComponent, {
      data: {
        UserRole: this.userRole,
        MultipleSelection: false,
        DialogTitle: "בחר רופא מרשימה"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != "") {
        this.currentRecord.Doctor = result
        this.currentRecord.DoctorID = this.currentRecord.Doctor.UID
        this.selectedDoctor = this.currentRecord.Doctor.firstName + " " + this.currentRecord.Doctor.lastName
      } else {
        this.selectedDoctor = ""
      }

    });
  }

}
