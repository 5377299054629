import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, CanDeactivateGuard } from '../shared/guard/auth.guard';
import { Physicaldiagnoses2Component } from './physicaldiagnoses2/physicaldiagnoses2.component';
import { Physicaldiagnoses2listComponent } from './physicaldiagnoses2list/physicaldiagnoses2list.component';
import { PhysicaldiagnosisComponent } from './physicaldiagnosis/physicaldiagnosis.component';
import { PhysicaldiagnosislistComponent } from './physicaldiagnosislist/physicaldiagnosislist.component';
import { PhysioShortenAssessmentComponent } from './physio-shorten-assessment/physio-shorten-assessment.component';
import { PhysiotherapyanchorComponent } from './physiotherapyanchor/physiotherapyanchor.component';
import { PhysiotherapyfollowupsComponent } from './physiotherapyfollowups/physiotherapyfollowups.component';


const routes: Routes = [



  { 
    path: 'caretexfile/:id/phsiotherapyassessment', 
    component: PhysiotherapyanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'new',
        pathMatch:'full',
      },

      {
        path: ':recordid', 
        component: PhysioShortenAssessmentComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':recordid/step/:stepid', 
        component: PhysioShortenAssessmentComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':recordid/step/:stepid/childstep/:childstepid', 
        component: PhysioShortenAssessmentComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
 
    ]
  },



  
  {
    path: 'caretexfile/:id/physiotherapyfollowup',
    component: PhysiotherapyanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: PhysiotherapyfollowupsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'list/step/:stepid', 
        component: PhysiotherapyfollowupsComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'step/:stepid',
        component: PhysiotherapyfollowupsComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

    ]

  },


  { 
    path: 'caretexfile/:id/physicaldiagnose', 
    component: PhysiotherapyanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: PhysicaldiagnosislistComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':recordid', 
        component: PhysicaldiagnosisComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':recordid/step/:tabid', 
        component: PhysicaldiagnosisComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':recordid/step/:tabid/childstep/:subtabid', 
        component: PhysicaldiagnosisComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
 
    ]
  },


  { 
    path: 'caretexfile/:id/physicalreport', 
    component: PhysiotherapyanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: Physicaldiagnoses2listComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':recordid', 
        component: Physicaldiagnoses2Component, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':recordid/step/:tabid', 
        component: Physicaldiagnoses2Component, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':recordid/step/:tabid/childstep/:subtabid', 
        component: Physicaldiagnoses2Component, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
 
    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CartexPhysiotherapyModuleRoutingModule { }
