import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';



@Component({
  selector: 'app-dailyfoodintakelist',
  templateUrl: './dailyfoodintakelist.component.html',
  styleUrls: ['./dailyfoodintakelist.component.css']
})
export class DailyfoodintakelistComponent extends CaretexComponent implements OnInit {

  moduleName:string = "תיעוד צריכת מזון יומית "

  personalId:string

  constructor(private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private route: ActivatedRoute) { super(medicalFileService); }

  ngOnInit(): void {
    this.personalId = this.route.parent.snapshot.params['id'];
    this._title.setTitle('Caretx |  תיעוד צריכת מזון יומית');
    this.personalId = this.route.parent.snapshot.params['id'] 
    this.CheckIfComponentIsDisabled(this.personalId)
  
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }
}
