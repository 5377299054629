
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-doctorsummary',
  templateUrl: './doctorsummary.component.html',
  styleUrls: ['../../medical-admission.component.css', './doctorsummary.component.scss']
})
export class DoctorsummaryComponent implements OnInit {


  @Input() DoctorSummaryTxt: string
  @Input() DoctorSummaryTxt2: string
  @Input() DoctorSummaryTxt3: string
  @Input() IsClosed:boolean
  @Input() currentActiveStep:number



  @Output() doctorSummaryChangeEvent = new EventEmitter<string>();
  @Output() doctorSummaryChangeEvent2 = new EventEmitter<string>();
  @Output() doctorSummaryChangeEvent3 = new EventEmitter<string>();


  DoctorSummaryChangeMessage: string
  show:string = "summary1"

  constructor(
    private router: Router,
    private _ar: ActivatedRoute,) {
 
  }

  ngOnInit(): void {

  }

  enableContent(type) {
    this.show = type;
  }
  SummaryTextchanged($event){
    this.doctorSummaryChangeEvent.emit($event)
  }

  SummaryTextchanged2($event){
    this.doctorSummaryChangeEvent2.emit($event)
  }
  SummaryTextchanged3($event){
    this.doctorSummaryChangeEvent3.emit($event)
  }

  zoomInModalData: any;
  zoomInField(title, fieldNgModel, preFilledData) {
    this.zoomInModalData = {title, field: fieldNgModel, preFilled: (preFilledData || '')};
  }

  bindFieldData(responseData) {

    if (this.zoomInModalData.field == "DoctorSummaryTxt"){
      this.DoctorSummaryTxt = responseData;
      this.doctorSummaryChangeEvent.emit( this.DoctorSummaryTxt)
    }

    if (this.zoomInModalData.field  == "DoctorSummaryTxt2"){
      this.DoctorSummaryTxt2 = responseData;
      this.doctorSummaryChangeEvent2.emit( this.DoctorSummaryTxt2)
    }

    if (this.zoomInModalData.field  == "DoctorSummaryTxt3"){
      this.DoctorSummaryTxt3 = responseData;
      this.doctorSummaryChangeEvent3.emit( this.DoctorSummaryTxt3)
    }

   
  }

}
