import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MedicalPersonelMeasuresProviderService } from '../../../../../../src/app/caretx-doctors-module/doctor-services/medical-perosnelmeasures.data-provider.service';
import { CaretxSetupsProviderService } from '../../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MeasuresTypes } from '../../../../../../src/app/models/generalhousedsetups';
import { PersonalMeasureCode, PersonalMeasures, PersonalMeasuresHeader } from '../../../../../../src/app/models/measure';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { CartexEvent} from '../../../../../../src/app/models/genericTypes';
import { BridgeEnums,OptionObject } from '../../../../../../src/app/services/enums';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxNursingProviderService } from '../../../nursing-services/Nursing.data-provider.service';

@Component({
  selector: 'app-personal-measures',
  templateUrl: './personal-measures.component.html',
  styleUrls: ['./personal-measures.component.css']
})
export class PersonalMeasuresComponent extends CaretexComponent implements OnInit {


  id: string
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  isdocumentClosed: boolean
  measuresTypes: MeasuresTypes[]
  showPersonelMeasuresModal: boolean
  personelMeasuresModalId: string
  personelMeasuresModalTarget: string
  currentSelectedMeasurecode: PersonalMeasureCode

  UrinateMethods: OptionObject[]
  OxygenMethods: OptionObject[]
  currentPersonelMeasureHeader: PersonalMeasuresHeader
  allPersonelMeasures: PersonalMeasures[] = []
  value: boolean = false;
  childNotifier: Subject<PersonalMeasureCode> = new Subject<PersonalMeasureCode>();
  moduleName: string = "מדדים חיוניים"
  isSelectionEnabled: boolean = true
  allMeasuresTypeId: string
  measureTypeSelectDisabled :boolean = false
  isRecordChanged: boolean = false
  HeaderId:string

  constructor(
    private _title: Title,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private toastrService: ToasterService,
    private router: Router,
    public medicalFileService: MedicalFileProviderService,
    private route: ActivatedRoute,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private medicalPersonelMeasuressService: MedicalPersonelMeasuresProviderService,
    bridgeEnum: BridgeEnums,) {
    super(medicalFileService)
    this.UrinateMethods = bridgeEnum.UrinateMethods
    this.OxygenMethods = bridgeEnum.OxygenMethods
    this.currentPersonelMeasureHeader = new PersonalMeasuresHeader()
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex | מדדים חיוניים');
    this.showPersonelMeasuresModal = false
    if (this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)

    this.HeaderId = this.route.snapshot.params.headerid 




  }



  getMeasuresTypeFromDB() {
    this.caretxSetupsProviderService.GetMeasuresTypes(this.authServive.LoggedInTenantID).subscribe(
      data => {
        this.measuresTypes = data
        for (let i = 0; i < this.measuresTypes.length; i++) {
          if (this.measuresTypes[i].Sequence == 1) {
            this.allMeasuresTypeId = this.measuresTypes[i].ID
            this.currentPersonelMeasureHeader.MeasureType = this.allMeasuresTypeId 
            break
          }
        }
      },
      err => {
        this.measuresTypes = []

      }
    )
  }


  newPersonalMeasure() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl("caretexfile/" + this.id + "/personelmeasures/new")

  
  }


  RefreshPersonalMeasuresHeader($event) {
    this.currentPersonelMeasureHeader = $event
  }
  savePersonelMeasure() {
   // this.currentPersonelMeasureHeader.Status = "C"
   // this.currentPersonelMeasureHeader.LockedById = this.authServive.LoggedInUser.UID
  //  this.currentPersonelMeasureHeader.LockedDate =  new Date
    if (this.currentPersonelMeasureHeader.ID == "" || this.currentPersonelMeasureHeader.ID == undefined ||this.currentPersonelMeasureHeader.ID == "00000000-0000-0000-0000-000000000000") {
      this.doSave()
    }
    else {
      this.doUpdate()
    }
  }

  doSave() {
    this.caretxNursingProviderService.createPersonelMeasure(this.currentPersonelMeasureHeader).subscribe({
      next: data => {
        this.currentPersonelMeasureHeader = new PersonalMeasuresHeader(data)
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.isdocumentClosed = (this.currentPersonelMeasureHeader.Status == "C")
        this.isRecordChanged = false
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/personelmeasures/" + this.currentPersonelMeasureHeader.ID)

      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  doUpdate() {
    this.caretxNursingProviderService.updatePersonelMeasure(this.currentPersonelMeasureHeader).subscribe({
      next: data => {
        this.currentPersonelMeasureHeader = new PersonalMeasuresHeader(data)
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.isRecordChanged = false
        this.isdocumentClosed = (this.currentPersonelMeasureHeader.Status == "C")
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  notifyChild() {
    this.value = !this.value;
    this.childNotifier.next(this.currentSelectedMeasurecode);
  }

  LockDocumentEvent() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה לנעול רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תנעל',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.LockDocument()
      }
    })
  }

  LockDocument() {
      this.currentPersonelMeasureHeader.LockedById = this.authServive.LoggedInUser.UID
      this.currentPersonelMeasureHeader.LockedDate = new Date()
      this.currentPersonelMeasureHeader.Status = "C"
      this.savePersonelMeasure()
  }

  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/personelmeasures")
  }

  personelMeasuresChanged($event){
      this.isRecordChanged = $event
  }

  CheckIfDocumentIsDirty() {
    return  this.isRecordChanged
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
      }

}
