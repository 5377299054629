import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { Departments, Funder, NursingStatus } from '../../../../../src/app/models/generalhousedsetups';
import { Notice } from '../../../../../src/app/models/notice';
import { CartexReport } from '../../../../../src/app/models/personeldocument';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ReportingService } from '../../../../../src/app/services/reporting.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxMedicalProviderService } from '../../doctor-services/Medical.data-provider.service';

import { DocViewerComponent } from '../../../../../src/app/caretx-shared-module/CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-passovernotice',
  templateUrl: './passovernotice.component.html',
  styleUrls: ['./passovernotice.component.css']
})
export class PassovernoticeComponent extends CaretexComponent implements OnInit {
  id: string
  @ViewChild('mainForm') mainFormElement: NgForm;
  isrecordClosed: boolean
  canSocialPagesBeEditted: boolean
  notice: Notice
  funders: Funder[] = []
  departments: Departments[] = []
  nursingStatuses: NursingStatus[] = []
  pdfContent: any
  moduleName :string = "סיכום טופס פטירה";
  
  constructor(
    private _title: Title,
    private route: ActivatedRoute,
    private router: Router,
    public medicalFileService: MedicalFileProviderService,
    private caretxSetupService: CaretxSetupsProviderService,
    private reportsService: ReportingService,
    public dialog: MatDialog,
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService) {

    super(medicalFileService)
    this.caretxSetupService.GetHouseDepartments().subscribe((data => {
      this.departments = data
    }))

    this.caretxSetupService.GetHouseFunders().subscribe((data => {
      this.funders = data
    }))

    this.caretxSetupService.GetNursingStatuses().subscribe((data => {
      this.nursingStatuses = data
    }))

  }

  ngOnInit(): void {
    this._title.setTitle('Caretex |סיכום ודיווח פטירה');
    if(this.route.parent!= null)
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
    this.getNoticeFromDB()
  }

  getNoticeFromDB() {
    this.caretxMedicalProviderService.getNotice(this.id).subscribe((data: Notice[]) => {
      if (data.length == 1) {
        this.notice = new Notice(data[0])
        this.isrecordClosed = (this.notice.Status == "C")
      }
      if (data.length == 0) {

      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
      }

    });
  }


  Lock() {


    let msg = this.checkMandatoryFields()
    if (msg != "") {
      Swal.fire({
        title: "הודעת מערכת",
        text: msg,
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    }
    else {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם להעביר את התיק לארכיון ?",
        icon: 'warning',

        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'כן,תעביר',
        denyButtonText: `אחר כך`,
      }).then((result) => {
        if (result.isConfirmed || result.isDenied) {
          this.notice.Status = "C"
          this.notice.Closed_at = new Date()
          this.notice.ClosedById = this.authServive.LoggedInUser.UID
          if (result.isConfirmed) this.notice.PersonDoc.IsArchived = true

          this.Save()
        }
      })
    }




  }

  Save() {
    if (this.notice.ID == '00000000-0000-0000-0000-000000000000') {
      this.DoSave()
    } else {
      this.DoUpdate()
    }

  }

  DoUpdate() {
    this.notice.UpdatedAt = new Date()
    this.notice.TenantID = this.authServive.LoggedInTenantID
    this.caretxMedicalProviderService.updateNotice(this.notice).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.notice = new Notice(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(["caretexfile/" + this.id + "/passovernotice"]);
        });
        window.location.reload();

      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoSave() {
    this.notice.UpdatedAt = new Date()
    this.notice.TenantID = this.authServive.LoggedInTenantID
    this.caretxMedicalProviderService.createNotice(this.notice).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.notice = new Notice(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(["caretexfile/" + this.id + "/passovernotice"]);
        });
        window.location.reload();

      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  GenerateReport() {

    if (this.notice.ID == '00000000-0000-0000-0000-000000000000') {
      Swal.fire({
        icon: 'warning',
        title: "הודעת מערכת",
        text: "צריך לשמור את המסמך קודם",
        allowOutsideClick: false,
        allowEscapeKey: false
        //footer: '<a href="">Why do I have this issue?</a>'
      })
    }
    else {
      var careTexReport = new CartexReport()
      careTexReport.PersonelID = this.id
      careTexReport.ReportName = "Satis"
      careTexReport.ReportType = "notice_sumamry"
      careTexReport.ReportParam = ""
      careTexReport.GeneratedBy = this.authServive.LoggedInUser
      careTexReport.GeneratedOn = new Date()
      const dialogRef = this.dialog.open(DocViewerComponent, {
        data: {
          Type:'CR',
          PdfReport: careTexReport
        
        }
      });
    }
  }




  checkMandatoryFields() {

    if (this.notice.DeathReason == "")
      return "חסר שדה סיבת מוות !"
    if (this.notice.HospitlizationReview == "")
      return "חסר שדה מהלך אשפוז !"
    return ""
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty(){
    return false;
  }


}
