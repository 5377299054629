import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-setupslandingpage',
  templateUrl: './setupslandingpage.component.html',
  styleUrls: ['./setupslandingpage.component.scss']
})
export class SetupslandingpageComponent implements OnInit {
  TabId:string = "main"
  constructor(public router: Router) { }

  menuItems = [
    {
      category: 'הגדרות כלליות', links: [
        { text: 'הגדרות בית אבות', url: 'house_settings' },
        { text: 'מחלקות', url: 'departments_settings' },
        { text: 'גורמים ממנים', url: 'funder_settings' },
        { text: 'סטטוסים סיעודיים', url: 'nursingstatuses_settings' },
        { text: 'סטטוס תיק/דייר', url: 'medicalfile_settings' },
        { text: 'גורם מפנה', url: 'referrer_setup' },
        { text: 'בתי חולים', url: 'hospitals_settings' },
        { text: 'נותני שירות אמבולנסים', url: 'ambulances_setup' },
        { text: 'משמרות עבודה', url: 'workingshifts_settings' },
      ]
    },

    {
      category: 'הגדרות מעבדה', links: [
        { text: 'מעבדות', url: 'labs_setup' },
        { text: 'רשימת יחידות מדידה של תרופות', url: 'druguom_setup' }
      ]
    },
    {
      category: 'הגדרות מדדים רפואיים', links: [
        { text: 'סוגי מדדים חיוניים', url: 'essentialmeasurestypes_settings' },
        { text: 'מדדים חיוניים', url: 'essentialmeasures_settings' }
      ]
    },

    {
      category: 'הגדרות חיסונים', links: [
        { text: 'חיסונים', url: 'vaccinations_setup' },

      ]
    },

    {
      category: 'הגדרות פצעי לחץ', links: [
        { text: 'סוגי פצעי לחץ', url: 'bedsores_types' },
        { text: 'מיקום פצעי לחץ', url: 'bedsores_locations' },
        { text: 'דרגות פצע לחץ', url: 'bedsores_statuses' },
        { text: 'הגדרת צד של פצע לחץ', url: 'bedsores_sides' }
      ]
    },
    {
      category: 'הגדרות זיהומים', links: [

        { text: 'סוג עמידות', url: 'resistancetypes' },
        { text: 'מקרות זיהום', url: 'contaminationsources' },
        { text: 'סוגי בידוד', url: 'isolationtypes' },
        { text: 'מקומות הידבקות', url: 'adhesionplaces' },
        { text: 'סטטוסים של הזיהום', url: 'infectionstatuses' },

        { text: 'חדרי בידוד', url: 'hospitaltoom' },
        { text: 'צוותי בידוד', url: 'hospitalizationteam' },
        { text: 'PCR Test', url: 'pcrtest' },
        { text: 'Hodge Test', url: 'hodgetest' },


       /* { text: 'זיהומים', url: 'hazards_settings' },
        { text: 'סוגי זיהום', url: 'hazards_types' },
        { text: 'רמות סיכון של זיהום', url: 'hazards_risks' }*/
      ]
    },

    {
      category: 'הגדרות אירוע חריג', links: [
        { text: 'סוגי אירועים חריגים', url: 'eventtypes_settings' },
        { text: 'מקום אירוע חריג', url: 'eventlocation_setup' },
        { text: 'מצבי הכרה', url: 'consciousnessstate_setup' },
        { text: 'מיטות', url: 'beds_setup' },
      ] 
    },


    {
      category: 'הגדרות סוציאליות', links: [
        { text: 'יחסי משפחה', url: 'familyrelations' },
        { text: 'חברות סיעוד', url: 'socialproviders' },
        { text: 'קודים למעקב כיס', url: 'pockettrackingCode_setup' }
      ]
    },
    {
      category: 'הגדרות סיעודיות', links: [
        { text: 'הוראות לצוות אחים', url: 'nurseinstruction_setup' },
        { text: 'בדירות גופניות', url: 'physicalnursechecks_settings' }
      ]
    },

    {
      category: 'הגדרות כאב', links: [
        { text: 'דרגת כאב', url: 'painlevel_setup' },
        { text: 'מיקום כאב', url: 'painlocation_settings' },
        { text: 'גורם מקל/מחמיר', url: 'mitigatingfactor_settings' },
        { text: 'סמפטומים נלווים', url: 'painsymptoms_settings' },
        { text: 'אופי כאב', url: 'painnature_settings' },
        { text: 'סוג טיפול של כאב', url: 'paintreatment_settings' },
      ]
    },


    {
      category: 'הגדרות דיאטנית', links: [
        { text: 'תקינות משקל', url: 'weightstatuses_setup' },
        { text: 'צורת אכילה', url: 'eatingstatuses_setup' },
        { text: 'צורת הזנה', url: 'feedform_setup' },
        { text: 'אופן שתיה', url: 'drinkform_setup' },
        { text: 'מצב בליעה', url: 'swallowstatus_setup' },
        { text: 'מצב תזונתי', url: 'nutritionalstatus_setup' },
        { text: 'מצב שיניים', url: 'teethstatus_setup' },
        { text: 'תוספות מזון', url: 'foodaddition_setup' },
        { text: 'תוסף תזונה', url: 'dietarysupplement_setup' },
      ]
    },
  ]

  ngOnInit(): void {
  }
}
