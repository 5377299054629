

import { propertyMap } from './modelmapper'
import { User } from './user'




export class MedicalTreatmentCoordination {
    @propertyMap('ID')
    public ID:string
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('ConversationDate')
    public ConversationDate:Date
    @propertyMap('ConversationTime')
    public ConversationTime:Date
    @propertyMap('Initiator')
    public Initiator: string
    @propertyMap('MeetingManager')
    public MeetingManager: string
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('Status')
    public Status: string
    @propertyMap('participant1')
    public participant1: boolean
    @propertyMap('participant2')
    public participant2: boolean
    @propertyMap('participant3')
    public participant3: boolean
    @propertyMap('participant4')
    public participant4: boolean
    @propertyMap('participant5')
    public participant5: boolean
    @propertyMap('participant6')
    public participant6: boolean
    @propertyMap('participant7')
    public participant7: boolean
    @propertyMap('participantComment')
    public participantComment: string
    @propertyMap('PatientCanDoTalk')
    public PatientCanDoTalk: boolean
    @propertyMap('PatientCanDoTalkNotes')
    public PatientCanDoTalkNotes: string
    @propertyMap('FamilyCanDoTalk')
    public FamilyCanDoTalk: boolean
    @propertyMap('FamilyCanDoTalkNotes')
    public FamilyCanDoTalkNotes: string
    @propertyMap('PatientStatusDescription')
    public PatientStatusDescription: string
    @propertyMap('PalliativeNotes')
    public PalliativeNotes: string
    @propertyMap('IsPalliative')
    public IsPalliative: boolean
    @propertyMap('TendToDieNotes')
    public TendToDieNotes: string
    @propertyMap('IsTendToDie')
    public IsTendToDie: boolean
    @propertyMap('PrinciplesExplainedNotes')
    public PrinciplesExplainedNotes: string
    @propertyMap('PrinciplesExplained')
    public PrinciplesExplained: boolean
    @propertyMap('SufferingLevelDescription')
    public SufferingLevelDescription: string
    @propertyMap('AffidavitExists')
    public AffidavitExists: boolean
    @propertyMap('DirectivesExists')
    public DirectivesExists: boolean
    @propertyMap('GuardianExists')
    public GuardianExists: boolean
    @propertyMap('Hospitlize')
    public Hospitlize: boolean
    @propertyMap('CPR')
    public CPR: boolean
    @propertyMap('Resuscitation')
    public Resuscitation: boolean
    @propertyMap('surgeries')
    public surgeries: boolean
    @propertyMap('Dialysis')
    public Dialysis: boolean
    @propertyMap('Chemotherapy')
    public Chemotherapy: boolean
    @propertyMap('FluidsMeans')
    public FluidsMeans: boolean
    @propertyMap('Antibiotics')
    public Antibiotics: boolean   
    @propertyMap('AddBlood')
    public AddBlood: boolean
    @propertyMap('AdditionalActions')
    public AdditionalActions: string
    @propertyMap('GuardianExplained')
    public GuardianExplained: boolean
    @propertyMap('ClosePersonAffidavit')
    public ClosePersonAffidavit: boolean
    @propertyMap('InformationGivenComment')
    public InformationGivenComment: string
    @propertyMap('InformationGivenTo1')
    public InformationGivenTo1: boolean
    @propertyMap('InformationGivenTo2')
    public InformationGivenTo2: boolean
    @propertyMap('InformationGivenTo3')
    public InformationGivenTo3: boolean
    @propertyMap('InformationGivenTo4')
    public InformationGivenTo4: boolean
    @propertyMap('InformationGivenTo5')
    public InformationGivenTo5: boolean

    @propertyMap('ClosedById')
    public ClosedById: string
    @propertyMap('ClosedBy')
    public ClosedBy: User
    @propertyMap('ClosedAt')
    public ClosedAt:Date




    constructor(data?: any) {

        if (data != null) {
            this.ID = data.ID   
            this.PersonelID = data.PersonelID
            this.CreatedAt = data.CreatedAt
            this.UpdatedAt = data.UpdatedAt 
            this.ClosedById = data.ClosedById
            this.ClosedBy = data.ClosedBy
            this.ClosedAt = data.ClosedAt
            this.Status = data.Status
            this.TenantID = data.TenantID
            this.ConversationDate = data.ConversationDate
            this.ConversationTime = data.ConversationTime
            this.Initiator = data.Initiator
            this.MeetingManager = data.MeetingManager
            this.participant1 = data.participant1
            this.participant2 = data.participant2
            this.participant3 = data.participant3
            this.participant4 = data.participant4
            this.participant5 = data.participant5
            this.participant6 = data.participant6
            this.participant7 = data.participant7
            this.participantComment = data.participantComment
            this.PatientCanDoTalk = data.PatientCanDoTalk
            this.PatientCanDoTalkNotes = data.PatientCanDoTalkNotes
            this.PatientStatusDescription = data.PatientStatusDescription
            this.FamilyCanDoTalk = data.FamilyCanDoTalk
            this.FamilyCanDoTalkNotes = data.FamilyCanDoTalkNotes
            this.PalliativeNotes = data.PalliativeNotes
            this.IsPalliative = data.IsPalliative
            this.TendToDieNotes = data.TendToDieNotes
            this.IsTendToDie = data.IsTendToDie
            this.GuardianExists = data.GuardianExists 
            this.PrinciplesExplained = data.PrinciplesExplained
            this.PrinciplesExplainedNotes = data.PrinciplesExplainedNotes
            this.SufferingLevelDescription = data.SufferingLevelDescription
            this.AffidavitExists = data.AffidavitExists
            this.DirectivesExists = data.DirectivesExists
            this.Hospitlize = data.Hospitlize
            this.CPR = data.CPR
            this.Resuscitation = data.Resuscitation
            this.surgeries = data.surgeries
            this.Dialysis = data.Dialysis
            this.Chemotherapy = data.Chemotherapy
            this.Antibiotics = data.Antibiotics
            this.FluidsMeans = data.FluidsMeans
            this.AddBlood = data.AddBlood
            this.AdditionalActions = data.AdditionalActions
            this.GuardianExplained = data.GuardianExplained
            this.ClosePersonAffidavit = data.ClosePersonAffidavit
            this.InformationGivenComment = data.InformationGivenComment
            this.InformationGivenTo1 = data.InformationGivenTo1
            this.InformationGivenTo2 = data.InformationGivenTo2
            this.InformationGivenTo3 = data.InformationGivenTo3
            this.InformationGivenTo4 = data.InformationGivenTo4
            this.InformationGivenTo5 = data.InformationGivenTo5
            
            
        }
        else {
            this.Status = "A"

        }
    }



}