import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxNursingProviderService } from 'src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { BedSore } from 'src/app/models/bedsore';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-bed-sores-list',
  templateUrl: './bed-sores-list.component.html',
  styleUrls: ['./bed-sores-list.component.css']
})
export class BedSoresListComponent extends CaretexComponent implements OnInit {

  id: string
  isValid: true
  currentRowIndex: number
  deleteSysMsgId: string
  deleteAlert: string
  alertTitle: string
  bedSores: BedSore[] = []
  moduleName: string = "רשימת פצעי לחץ"
  formData: any[]
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), 
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0

  RecordStatus = [{ id: "A", desc: "דיווח פעיל" }, { id: "C", desc: "דיווח שהסתיים" }]

  constructor(
    private _title: Title,
    private toastrService: ToasterService,
    public medicalFileService: MedicalFileProviderService,
    private caretxNursingProviderService: CaretxNursingProviderService,

    private router: Router,
    private route: ActivatedRoute) { super(medicalFileService) }

  ngOnInit(): void {
    this._title.setTitle('Caretx |  אירועים חריגים');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
    this.getBedSoresFromDB("")
    this.formData=  [
      {"formcontrolname":"status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
      {"formcontrolname":"created_at_from","label":"מתאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"created_at_to","label":"עד תאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_from","label":"תאריך נעילה מ" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_to","label":"תאריך נעילה עד" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},

    ]
  }

  getBedSoresFromDB(filter: string) {
    this.caretxNursingProviderService.GetBedSores(this.id, filter,"list").subscribe(
      data => {
        this.bedSores = data
        this.totalNumOfRecords = data.length
      },
      err => {
        console.log("Error", err)
        this.bedSores = []

      }
    )
  }

  SearchWithFilter($event) {
    this.getBedSoresFromDB($event)
  }

  AddNewBedSore($event) {
    this.router.navigateByUrl("caretexfile/" + this.id + "/bedsores/new" ) /*, {
      skipLocationChange: true,
    })*/
  }
  
  OnSDrillToBedSore($event, bedSore) {
    this.router.navigateByUrl("caretexfile/" + this.id + "/bedsores/" + bedSore.ID)/*, {
      skipLocationChange: true,
    })*/
  }

  deleteRow(i) {
    this.currentRowIndex = i

    if (this.bedSores[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.doDeletion(this.bedSores[this.currentRowIndex])
        }
      })
    }
  }

  doDeletion(bedSore: BedSore) {
    let filter = '(id=' + '\'' + bedSore.ID + '\'' + ')'
    this.caretxNursingProviderService.deleteBedSore(filter, this.id).subscribe({
      next: data => {
        this.getBedSoresFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  generateReport($event, bedsore) {
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }

}
