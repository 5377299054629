import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-pain-measure-dialog',
  templateUrl: './pain-measure-dialog.component.html',
  styleUrls: ['./pain-measure-dialog.component.scss']
})
export class PainMeasureDialogComponent implements OnInit {
  @ViewChild('f') mainFormElement: NgForm;
  constructor(private dialogRef: MatDialogRef<PainMeasureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { dialogRef.disableClose = true; }




  range2 = document.getElementById("month-price") as HTMLInputElement | null;
  minusButton = document.querySelector(".control-minus") ;
  plusButton = document.querySelector(".control-plus") ;
 // tooltip = document.querySelector(".current-value") ;
  steps = 9
  padding = 15;
  subpixelCorrection = 0.4;
  
  painVal : number


  @ViewChild('rangeSlider') range: ElementRef<HTMLInputElement>;
  @ViewChild('tooltip') tooltip: ElementRef<HTMLDivElement>;

  public min : number = 0;
  public max : number = 10;

  ngOnInit(): void {



  }


  updateTooltip () {
    this.tooltip.nativeElement.firstElementChild.textContent = this.range.nativeElement.value;
    
    var startPosition = - (this.tooltip.nativeElement.clientWidth)/2 + this.padding + 4;
    var stepWidth = (this.range.nativeElement.getBoundingClientRect().width - this.padding*2)/this.steps - this.subpixelCorrection;  
    let  currentStep : number  =  (this.range.nativeElement.valueAsNumber) - this.min;
    
    // Reposition tooltip on top of the thumb
    this.tooltip.nativeElement.style.visibility = "visible";
    this.tooltip.nativeElement.style.right = Math.round(stepWidth*currentStep + startPosition) + "px";
      
  }

  DecreaseStep() {
    this.range.nativeElement.stepDown()
    this.updateTooltip ();
    this.painVal = this.range.nativeElement.valueAsNumber
  }

  IncreaseStep(){
    this.range.nativeElement.stepUp();
    this.updateTooltip ();
    this.painVal = this.range.nativeElement.valueAsNumber
  }

  submit() { 
    this.dialogRef.close(this.painVal);
  }
}
