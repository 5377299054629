import { Diagnoses } from './diagnoses'
import { DrugInstruction } from './drugInstruction'
import { propertyMap } from './modelmapper'
import { User } from './user'


export class LabTemplate {
  @propertyMap('Id')
  public Id: string
  @propertyMap('TestName')
  public TestName: string
  @propertyMap('MinValue')
  public MinValue: number
  @propertyMap('MaxValue')
  public MaxValue: number
  @propertyMap('Uom')
  public Uom: string
  @propertyMap('TestCategory')
  public TestCategory: string
  @propertyMap('PrimaryCode')
  public PrimaryCode: string
  @propertyMap('HMO')
  public HMO: string
  @propertyMap('Sequence')
  public Sequence: string
  @propertyMap('RealmID')
  public RealmID: string
  @propertyMap('Modified')
  public Modified: boolean
  @propertyMap('New')
  public New: boolean
  public editMode: boolean = false
}

export class LabType {
  @propertyMap('LabTypeId')
  public LabTypeId: string
  @propertyMap('LabTypeDescr')
  public LabTypeDescr: string
  @propertyMap('TenantID')
  public TenantID: string
  @propertyMap('HMO')
  public HMO: string
  @propertyMap('Modified')
  public Modified: boolean
  @propertyMap('New')
  public New: boolean
  public HasError: boolean
  public editMode: boolean = false
}

export class LabCheck {
  @propertyMap('ID')
  public ID: string
  @propertyMap('CreatedAt')
  public CreatedAt: Date
  @propertyMap('UpdatedAt')
  public UpdatedAt: Date
  @propertyMap('PersonelID')
  public PersonelID: string
  @propertyMap('TestName')
  public TestName: string
  @propertyMap('TestValue')
  public TestValue: number
  @propertyMap('Uom')
  public Uom: string
  @propertyMap('TestHeaderId')
  public TestHeaderId: string
  public TestHeader: LabCheckHeader
  @propertyMap('LabTemplateId')
  public LabTemplateId: string
  public LabTemplate: LabTemplate
  public DisplayIndicator: boolean
  @propertyMap('TestHeaderID')
  public TestHeaderID: string
  @propertyMap('TenantID')
  public TenantID: string


  constructor(data?: Object) {
    this.DisplayIndicator = false
    if (data != undefined && data != null) {
      this.PersonelID = data["PersonelID"]
      this.TestName = data["TestName"]
      this.TestValue = data["TestValue"]
      this.Uom = data["Uom"]
      this.TestHeaderId = data["TestHeaderId"]
      this.PersonelID = data["PersonelID"]
      this.LabTemplateId = data["LabTemplateId"]
      this.LabTemplate = data["LabTemplate"]
      this.TestHeaderId = data["TestHeaderId"]
      this.CreatedAt = data["CreatedAt"]
      this.UpdatedAt = data["UpdatedAt"]
    }
  }




  isExceedsMaxValue() {
    if (this.TestValue == undefined || this.TestValue == null) return 0
    return this.TestValue > this.LabTemplate.MaxValue ? 100 : 0
  }

  isBelowMinValue() {
    if (this.TestValue == undefined || this.TestValue == null) return 0
    return this.TestValue < this.LabTemplate.MinValue ? 100 : 0
  }


  movePositiveArrow() {
    return 83 - 63 * (this.TestValue - this.LabTemplate.MinValue) / (this.LabTemplate.MaxValue - this.LabTemplate.MinValue)
  }

  isValueInInterval(): boolean {
    if (this.TestValue == undefined || this.TestValue == null) return true
    return this.TestValue >= this.LabTemplate.MinValue && this.TestValue <= this.LabTemplate.MaxValue ? false : true
  }

}

export class LabCheckHeader {
  @propertyMap('ID')
  public ID: string
  @propertyMap('PersonelID')
  public PersonelID: string
  @propertyMap('TenantID')
  public TenantID: string
  @propertyMap('LabCheckNum')
  public LabCheckNum: string
  @propertyMap('Tested_at')
  public Tested_at: Date
  @propertyMap('CategoryId')
  public CategoryId: string
  @propertyMap('SubCategoryId')
  public SubCategoryId: string

  @propertyMap('HMO')
  public HMO: string


  @propertyMap('LabCategory')
  public LabCategory: LabOrderCategories

  @propertyMap('UserId')
  public UserId: string
  public User: User
  @propertyMap('Labchecks')
  Labchecks: LabCheck[]

  constructor(data?: Object) {
    if (data != undefined && data != null) {
      this.PersonelID = data["PersonelID"]
      this.ID = data["ID"]
      this.LabCheckNum = data["LabCheckNum"]
      this.Tested_at = data["Tested_at"]
      this.UserId = data["UserId"]
      this.CategoryId = data["CategoryId"]
      this.LabCategory = data["LabCategory"]
      this.User = data["User"]
      this.TenantID = data["TenantID"]
      this.HMO = data["HMO"]
      this.SubCategoryId = data["SubCategoryId"]

      this.Labchecks = LabCheckHeader.fromJSON(data["Labchecks"]);

    }
  }



  CopyTemplateLine(laboratoryTemplate: LabTemplate[], personalId: string, tenantId: string) {
    this.Labchecks = []
    laboratoryTemplate.forEach(function (value) {
      let checkRow = new LabCheck()
      checkRow.TestName = value.TestName
      checkRow.TestValue = null
      checkRow.PersonelID = personalId
      checkRow.TestHeaderId = null
      checkRow.TenantID = tenantId
      checkRow.TestHeader = null
      checkRow.Uom = value.Uom
      checkRow.LabTemplateId = value.Id
      checkRow.LabTemplate = value

      if (value.MaxValue == value.MinValue)
        checkRow.DisplayIndicator = true

      this.Labchecks.push(checkRow)
    }, this)
  }



  static fromJSON(checks: Object[]): LabCheck[] {

    let labchecks: LabCheck[] = []
    checks.forEach(function (value) {
      labchecks.push(Object.assign(new LabCheck(), value));
    });


    return labchecks
  }


}

export class LabOrderCheck {

  @propertyMap('PrimaryCode')
  public PrimaryCode: string
  @propertyMap('CategoryId')
  public CategoryId: string
  @propertyMap('LabCategory')
  public LabCategory: LabOrderCategories
  

  @propertyMap('Code2')
  public Code2: string
  @propertyMap('Name')
  public Name: string
  @propertyMap('HMO')
  public HMO: string
  @propertyMap('Sequence')
  public Sequence: number
  @propertyMap('LabOrderTemplates')
  public LabOrderTemplates: LabTemplate[]
  @propertyMap('RealmID')
  public RealmID: string
  @propertyMap('Modified')
  public Modified: boolean
  @propertyMap('New')
  public New: boolean
  public editMode: boolean = false
  public IsSelected: boolean

  constructor() {
    this.IsSelected = false;
  }
}

export class LabOrderCategories {
  @propertyMap('CategoryId')
  public CategoryId: string
  @propertyMap('CategoryDescr')
  public CategoryDescr: string
  @propertyMap('TenantID')
  public TenantID: string
  @propertyMap('HMO')
  public HMO: string
  @propertyMap('Modified')
  public Modified: boolean
  @propertyMap('New')
  public New: boolean
  public HasError: boolean
  public editMode: boolean = false
  @propertyMap('LabOrders')
  public LabOrders: LabOrderCheck[]

  constructor(data?: Object) {
    if (data != undefined && data != null) {
      this.CategoryId = data["CategoryId"]
      this.CategoryDescr = data["CategoryDescr"]
      this.HMO = data["HMO"]
      this.LabOrders = LabOrderCategories.fromJSON(data["LabOrders"]);
    }
  }

  static fromJSON(checks: Object[]): LabOrderCheck[] {
    let labchecks: LabOrderCheck[] = []
    checks.forEach(function (value) {
      labchecks.push(Object.assign(new LabOrderCheck(), value));
    });
    return labchecks
  }

  selectedLabOrderchecks() {
    return this.LabOrders.filter(x => x.IsSelected);
  }
  isSelected() {
    return this.LabOrders.some(x => x.IsSelected);
  }
}

export class LabRequests {
  @propertyMap('ID')
  public ID: string
  @propertyMap('CreatedAt')
  public CreatedAt: Date
  @propertyMap('UpdatedAt')
  public UpdatedAt: Date
  @propertyMap('RealmID')
  public RealmID: string
  @propertyMap('PersonelID') //
  public PersonelID: string
  @propertyMap('RequestedById')
  public RequestedById: string
  public RequestedBy: User
  @propertyMap('ExecutedById')
  public ExecutedById: string
  public ExecutedBy: User
  @propertyMap('Created_at')
  public Created_at: Date
  @propertyMap('RequestStatus')
  public RequestStatus: string
  @propertyMap('RequestedTests')
  public RequestedTests: LabOrderCheck[]
  @propertyMap('Diagnoses')
  public Diagnoses: Diagnoses[]
  @propertyMap('DrugInstructions')
  public DrugInstructions: DrugInstruction[]
  @propertyMap('LabOrderCategories')
  public LabOrderCategories: LabOrderCategories[]

  constructor(data?: Object) {
    if (data != undefined && data != null) {
      this.ID = data["ID"]
      this.PersonelID = data["PersonelID"]
      this.UpdatedAt = data["UpdatedAt"]
      this.CreatedAt = data["CreatedAt"]
      this.RealmID = data["RealmID"]
      this.CreatedAt = data["CreatedAt"]
      this.RequestedById = data["RequestedById"]
      this.RequestedBy = data["RequestedBy"]
      this.ExecutedById = data["ExecutedById"]
      this.ExecutedBy = data["ExecutedBy"]
      this.RequestStatus = data["RequestStatus"]
      this.RequestedTests = data["RequestedTests"]
      this.Diagnoses = data["Diagnoses"]
      this.DrugInstructions = []
      data["DrugInstructions"].forEach(element => {
        this.DrugInstructions.push(new DrugInstruction(element))
      });
      this.LabOrderCategories = []
      data["LabOrderCategories"].forEach(element => {
        this.LabOrderCategories.push(new LabOrderCategories(element))
      });
    }
  }






}
