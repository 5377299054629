import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../../../../src/app/models/user';
import { UserRoles } from '../../../../../src/app/services/enums';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';

@Component({
  selector: 'app-userselectorform',
  templateUrl: './userselectorform.component.html',
  styleUrls: ['./userselectorform.component.css']
})
export class UserselectorformComponent implements OnInit {

  id: string
  @Input() UserRole: UserRoles
  @Input() FormTitle: String
  @Output() selectedUsersEvent = new EventEmitter<User[]>();
  hideModal: boolean = true;


  users: User[]
  selectedUsers: User[] = []
  constructor(
    private _ar: ActivatedRoute,
    private sharedService: CaretxSharedModulesProviderService,) {
  }

  ngOnInit(): void {
    this.id = this._ar.snapshot.params['id'];
    this.getUsers(this.UserRole);
  }


  getUsers(userRole: UserRoles) {
    let filter = '(role=' + '\'' + userRole + '\'' + ')'
    this.sharedService.getUsers(filter).subscribe(
      data => this.users = data,
      err => {
        console.log("Error", err)
        this.users = []

      }
    )
  }

  closeModal() {
    this.selectedUsersEvent.emit(this.selectedUsers)
  }

 UserSelection(e,user) {

    let isFound  = this.checkIfListContainsUser(user.UID)
    if (e.currentTarget.checked) {
        // Check if it is already included in the list
       if (!isFound) {
          this.selectedUsers.push(user)
       }
    }
    else{
        if (isFound){
          let objIndex = this.selectedUsers.findIndex((e: User) => e.UID == user.UID);
          this.selectedUsers.splice(objIndex, 1);
        }
    }

  }


  checkIfListContainsUser(UserId:string):boolean {
    const result = this.selectedUsers.some(x => x.UID === UserId)
    return result
  }




}
