import { Component, OnInit, ViewChild } from '@angular/core';
import {  NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { TabHistory } from '../../../../../src/app/models/genericTypes';
import { PhysicalExamination } from '../../../../../src/app/models/physicalexamination';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { CaretxMedicalProviderService } from '../../doctor-services/Medical.data-provider.service';
import Swal from 'sweetalert2';
import { MedicalFile } from '../../../../../src/app/models/customer';
import jsPDF from 'jspdf';
import { ReportingService } from '../../../../../src/app/services/reporting.service';

@Component({
  selector: 'app-physicalcheck',
  templateUrl: './physicalcheck.component.html',
  styleUrls: ['../medical-admission/medical-admission.component.css', './physicalcheck.component.css']
})
export class PhysicalcheckComponent extends CaretexComponent implements OnInit  {
  @ViewChild('Physicalform1') Physicalform1: NgForm;
  id: string
  physicalcheckId: string
  eventsSubject: Subject<TabHistory> = new Subject<TabHistory>();
  isEdit: Boolean
  isNew: boolean
  selectedPhysicalExamination: PhysicalExamination
  moduleName:string = "בדיקה גופנית"
  isDirty: boolean
  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  selectedFile: MedicalFile
  pdfContent: any


  
  constructor(
    private _ar: ActivatedRoute,
    private authService: AuthService,
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    public medicalFileService: MedicalFileProviderService,
    private route: ActivatedRoute,
    private reportingService: ReportingService,
    private router: Router,
    private toastrService: ToasterService,
    private _title: Title) {
    super(medicalFileService)
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
  
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this._title.setTitle('Caretx | בדיקות גופניות');
    this.id = this.route.parent.snapshot.params['id']  //this._ar.snapshot.params['id'];
    this.physicalcheckId = this.route.snapshot.params.physicalcheckId

    this.CheckIfComponentIsDisabled(this.id)
    this.isDirty = false
    if (this.physicalcheckId == 'new') {
      this.isEdit = false
      this.isNew = true
      this.selectedPhysicalExamination = new PhysicalExamination()
      this.selectedPhysicalExamination.PersonelID = this.id
      this.selectedPhysicalExamination.UserId = this.authService.LoggedInUser.UID
      this.selectedPhysicalExamination.CreatedAt = new Date()
      this.selectedPhysicalExamination.UpdatedAt = new Date()
      this.selectedPhysicalExamination.Status = "A"
    }
    else {
      this.isEdit = true
      this.isNew = false
      this.getPhysicalCheckFromDB()
    }
  }

  getPhysicalCheckFromDB() {
    let filter = '(id=' + '\'' + this.physicalcheckId + '\'' + ')'

    this.caretxMedicalProviderService.getExamination( this.id,filter).subscribe(
      data => {
        this.selectedPhysicalExamination = data[0]
        this.caretxMedicalProviderService.selectedPhysicalExamination = this.selectedPhysicalExamination
      },
      err => {
        console.log("Error", err)
      }
    )
  }

  backToPhisicalCheckList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/physicalchecks")
  }

  submitForm() {
    this.Physicalform1.onSubmit(undefined);
  }

  savePhysicalCheck() {
    if (this.isEdit) {
      this.doUpdate()
    }
    else {
      this.doSave()
    }
  }

  doUpdate() {
    this.selectedPhysicalExamination.UpdatedAt = new Date()
    this.caretxMedicalProviderService.updateExamination(this.selectedPhysicalExamination).subscribe({
      next: data => {
        this.selectedPhysicalExamination = new PhysicalExamination(data)
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.isDirty = false
        this.Physicalform1.reset(this.Physicalform1.value);
        this.router.navigateByUrl("caretexfile/" + this.id + "/physicalchecks/" + this.selectedPhysicalExamination.ID)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  doSave() {
    this.caretxMedicalProviderService.createNewExamination(this.selectedPhysicalExamination).subscribe({
      next: data => {
        this.Physicalform1.reset(this.Physicalform1.value);
        this.isDirty = false
        this.selectedPhysicalExamination = new PhysicalExamination(data)
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
       
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/physicalchecks/" + this.selectedPhysicalExamination.ID)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.isDirty)
  }

  SubComponentIsDirty($event) {
    this.isDirty = $event
  } 

  lockRecod(){
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם את/ה רוצה לנעול בדיקה זו ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תנעל',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.selectedPhysicalExamination.Status = "C"
        this.selectedPhysicalExamination.ClosedById = this.authService.LoggedInUser.UID
        this.selectedPhysicalExamination.Closed_at = new Date()
        this.savePhysicalCheck()

      }
    })










  }

  /*************************************************************************************** */
  openPDFViewer() {

    this.showGeneratedPdf = true
    this.selectedFile = this.medicalFile1
    var doc = new jsPDF()
    let lastYPosition: number
    let xPosTitle:number = 118
    let reportTitle = "סיכום בדיקה רפואית"
    lastYPosition = this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle)
    this.reportingService.setFooter2(doc, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date())


    var label =""
    var label2 = "בדיקה גופנית מתאריך - " + this.reportingService.formatDateToMMDDYYY(this.selectedPhysicalExamination.CreatedAt)
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,10 ,"", label2, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
   
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"מצב כללי:", this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.GeneralExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.GeneralExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"מצב ראש:", this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.HeadExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.HeadExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)


    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"מצב עיניים:",this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.EyesExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.EyesExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"מצב אוזניים:", this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.EarsExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.EarsExamination,this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"מצב פה ולוע:", this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.MouthExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.MouthExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"מצב צוור:", this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.NeckExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.NeckExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"קשרי לימפה:", this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.LemphaExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.LemphaExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"בית חזה וראות:",this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.ChestExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.ChestExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"לב וכלי דם:",this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.HeartExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.HeartExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"שדיים:", this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.BreastsExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.BreastsExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"בטן:", this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.StomechExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.StomechExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"",'\u2022 ' + "בדיקה רקטלית:", this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.RectalExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.RectalExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"אורולוגיה / גניקולוגית:", this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.OrologiaExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.OrologiaExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"גפיים:",this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.LimbsExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.LimbsExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)

    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition,9,"", '\u2022 ' +"נוירולוגית:",this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)
    lastYPosition = this.reportingService.AddLabelTextField(doc, lastYPosition-6, label, this.selectedPhysicalExamination.NeuroExamination == "" ? "לא צויין" : this.selectedPhysicalExamination.NeuroExamination, this.selectedPhysicalExamination.ClosedBy,this.authService.LoggedInUser, this.selectedPhysicalExamination.Closed_at,new Date(), this.medicalFile1,reportTitle,xPosTitle)


    let file: Blob = doc.output('blob')
    this.pdfContent = URL.createObjectURL(file);
    this.pdfViewer.pdfSrc = file
    this.pdfViewer.refresh();
  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }

}
