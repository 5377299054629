import { Component, OnInit } from '@angular/core';
import { Ambulance } from '../../../../../src/app/models/generalhousedsetups';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ambulancessetup',
  templateUrl: './ambulancessetup.component.html',
  styleUrls: ['./ambulancessetup.component.css']
})
export class AmbulancessetupComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  ambulances: Ambulance[] = []
  currentRowIndex: number
  deleteSysMsgId: string
  deleteAlert: string
  alertTitle: string
  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) { super(medicalFileServive) }

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getAmbulanceProvidersFromDB()

  }

  getAmbulanceProvidersFromDB() {
    this.caretxSetupsProviderService.getAmbulanceProviders().subscribe(
      data => {
        this.ambulances = data
      },
      err => {
        this.ambulances = []

      }
    )
  }

  addNewAmbulanceProvider() {
    var newAmbulanceProvider = new Ambulance()
    newAmbulanceProvider.New = true
    newAmbulanceProvider.editMode = true
    newAmbulanceProvider.CreatedAt = new Date()
    newAmbulanceProvider.UpdatedAt = new Date()
    newAmbulanceProvider.TenantID = this.authServive.LoggedInTenantID
    this.ambulances.unshift(newAmbulanceProvider)
  }

  deleteRow(i) {
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.ambulances[i].New) {
          this.caretxSetupsProviderService.deleteAmbulanceProvider(this.ambulances[this.currentRowIndex]).subscribe({
            next: data => {
              this.ambulances.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
          this.ambulances.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i) {
    this.ambulances[i].editMode = true
    this.ambulances[i].Modified = true
  }
  cancelEditRow(i) {
    this.ambulances[i].editMode = false;
    this.ambulances[i].Modified = false
  }
  BackToList() {
    this.router.navigate(['system_setups']);
  }



  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const statusesListChanged = this.ambulances.some(row => {
      return row.New || row.editMode
    })
    return  statusesListChanged
  }



  updateAmbulanceProvider($event) {

    this.caretxSetupsProviderService.CreateUpdateAmbulanceProviders(this.ambulances).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getAmbulanceProvidersFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })



  }


}
