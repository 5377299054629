
import { propertyMap } from './modelmapper'
import { User } from './user'
import { Diagnoses } from './diagnoses'
import { PhysicalExamination } from './physicalexamination'
import {  PersonelSensitivity } from './drugsenstivity'
import {  PersonalMeasuresHeader } from './measure'
import { DrugInstruction } from './drugInstruction'
import { Attachment } from './attachments'


// Need Server to send Payload in below structure
export class MedicalAdmission {
    
    @propertyMap('ID')
    ID: string 
    @propertyMap('RealmID')
    RealmID: string
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('MainComplaint')
    MainComplaint: string
    @propertyMap('CurrentDisease')
    CurrentDisease: string
    @propertyMap('OldComplaint')
    OldComplaint: string
    @propertyMap('MainCompliantLastUpdatedById')
    MainCompliantLastUpdatedById: string
    @propertyMap('MainCompliantLastUpdated_at')
    MainCompliantLastUpdated_at: Date
    MainCompliantLastUpdatedBy: User
    @propertyMap('CurrentDiseaseLastUpdatedById')
    CurrentDiseaseLastUpdatedById: string
    @propertyMap('CurrDiseaseLastUpdated_at')
    CurrDiseaseLastUpdated_at: Date
    CurrDiseaseLastUpdatedBy: User
    @propertyMap('OldComplaintLastUpdatedById')
    OldComplaintLastUpdatedById: string
    @propertyMap('OldComplaintLastUpdated_at')
    OldComplaintLastUpdated_at: Date
    OldComplaintLastUpdatedBy: User
    @propertyMap('Status')
    Status: string
    @propertyMap('DoctorId')
    public DoctorId: string
    public Doctor: User
    @propertyMap('DoctorSummary')
    DoctorSummary: string

    @propertyMap('DoctorSummary2')
    DoctorSummary2: string

    @propertyMap('DoctorSummary3')
    DoctorSummary3: string

    @propertyMap('DoctorSummary4')
    DoctorSummary4: string
    

    @propertyMap('DoctorSummaryLastUpdatedById')
    DoctorSummaryLastUpdatedById: string
    DoctorSummaryLastUpdatedBy: User
    @propertyMap('DoctorSummaryLastUpdated_at')
    DoctorSummaryLastUpdated_at: Date
    @propertyMap('MedicalPlan')
    MedicalPlan: string
    @propertyMap('MedicalPlanLastUpdatedById')
    MedicalPlanLastUpdatedById: string
    MedicalPlanLastUpdatedBy: User
    @propertyMap('MedicalPlanLastUpdated_at')
    MedicalPlanLastUpdated_at: Date
    @propertyMap('Diagnoses')
    public Diagnoses: Diagnoses[]
    @propertyMap('PhysicalExamination')
    public PhysicalExamination: PhysicalExamination
    @propertyMap('PersonSensitivity')
    public PersonSensitivity: PersonelSensitivity
    @propertyMap('DrugInstructions')
    public DrugInstructions:DrugInstruction[]
    @propertyMap('PersonalMeasuresHeader')
    public PersonalMeasuresHeader: PersonalMeasuresHeader
    @propertyMap('Closed_at')
    public Closed_at: Date
    @propertyMap('ClosedById')
    public ClosedById: string
    @propertyMap('ClosedBy')
    public ClosedBy: User
    ShowDetails: boolean
    clientId: string
    @propertyMap('IsExternalAdmission')
    public IsExternalAdmission: boolean
    @propertyMap('PdfFile')
    public PdfFile: Attachment
    
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.IsExternalAdmission = data["IsExternalAdmission"]
            this.PdfFile = data["PdfFile"]

            this.PersonelID = data["PersonelID"]
            this.RealmID = data["RealmID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.MainComplaint = data["MainComplaint"]
            this.CurrentDisease = data["CurrentDisease"]
            this.OldComplaint = data["OldComplaint"]
            this.DoctorSummary = data["DoctorSummary"]
            this.DoctorSummary2 = data["DoctorSummary2"]
            this.DoctorSummary3 = data["DoctorSummary3"]
            this.DoctorSummary4 = data["DoctorSummary4"]
            this.MedicalPlan = data["MedicalPlan"]
            this.CurrDiseaseLastUpdated_at = data["CurrDiseaseLastUpdated_at"]
            this.OldComplaintLastUpdated_at = data["OldComplaintLastUpdated_at"]
            this.MainCompliantLastUpdated_at = data["MainCompliantLastUpdated_at"]
            this.ShowDetails = false
            this.Status = data["Status"]
            this.Diagnoses = data["Diagnoses"]
            this.PersonSensitivity = data["PersonSensitivity"]
            //
            this.DrugInstructions=[]
            if (data["DrugInstructions"] != null && data["DrugInstructions"] != undefined) {
                data["DrugInstructions"].forEach(element => {
                    this.DrugInstructions.push(new DrugInstruction(element,data["PersonelID"],data["RealmID"]))
                });
            }

            this.PersonalMeasuresHeader = new PersonalMeasuresHeader (data["PersonalMeasuresHeader"])

            // Prepare Personel Measures List values if Empty or null



            this.PhysicalExamination = new PhysicalExamination(data["PhysicalExamination"])
            this.Closed_at   = data["Closed_at"]
            this.ClosedById   = data["ClosedById"]
            this.ClosedBy   = data["ClosedBy"]
        }
        else {
            this.ShowDetails = false;
            this.clientId = Math.floor(Math.random() * 16).toString(16).toUpperCase();
            this.CreatedAt = new Date()
            this.UpdatedAt = new Date()
            this.MainComplaint = ""
            this.CurrentDisease = ""
            this.OldComplaint = ""
            this.DoctorSummary = ""
            this.MedicalPlan = ""
            this.CurrDiseaseLastUpdated_at = new Date()
            this.OldComplaintLastUpdated_at = new Date()
            this.MainCompliantLastUpdated_at = new Date()
            this.ShowDetails = false
            this.Status = 'A'
            this.Diagnoses = []
            this.PersonSensitivity = new PersonelSensitivity()
            this.PersonalMeasuresHeader = new PersonalMeasuresHeader()
            this.PersonalMeasuresHeader.PersonalMeasures = []
            this.DrugInstructions = []
            this.PhysicalExamination = new PhysicalExamination()
        }
    }

    NewMedicalAdmission() {
        this.ShowDetails = false;
        this.clientId = Math.floor(Math.random() * 16).toString(16).toUpperCase();
        this.CreatedAt = new Date()
        this.UpdatedAt = new Date()
        this.MainComplaint = ""
        this.CurrentDisease = ""
        this.OldComplaint = ""
        this.MainCompliantLastUpdatedById = undefined
        this.CurrentDiseaseLastUpdatedById = undefined
        this.OldComplaintLastUpdatedById = undefined
        this.CurrDiseaseLastUpdated_at = new Date()
        this.OldComplaintLastUpdated_at = new Date()
        this.MainCompliantLastUpdated_at = new Date()
        this.DoctorSummary = ""
        this.Status = 'A'
        this.Diagnoses = []
        this.PersonSensitivity = new PersonelSensitivity()
        this.PhysicalExamination = new PhysicalExamination()
        this.PersonalMeasuresHeader = new PersonalMeasuresHeader()
        this.PersonalMeasuresHeader.PersonalMeasures = []
        this.DrugInstructions = []
    }
}
