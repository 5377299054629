import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';


@Component({
  selector: 'app-exception-events-list',
  templateUrl: './exception-events-list.component.html',
  styleUrls: ['./exception-events-list.component.css']
})
export class ExceptionEventsListComponent extends CaretexComponent implements OnInit {
  baseUrl: string = "eventnursing"
 
  constructor( 
    private _title: Title,
    public medicalFileServive: MedicalFileProviderService,
   ) {super(medicalFileServive) }

  ngOnInit(): void {
    this._title.setTitle('Caretx |  אירועים חריגים');
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }

}
