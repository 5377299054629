import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-omdan5',
  templateUrl: './omdan5.component.html',
  styleUrls: ['./omdan5.component.css']
})
export class Omdan5Component extends CaretexComponent  implements OnInit {
  isSubModule:boolean = false
  constructor( private route: ActivatedRoute,public medicalFileService: MedicalFileProviderService,) {super(medicalFileService) }

  ngOnInit(): void {   
    this.id = this.route.parent.snapshot.params['id'];
  this.CheckIfComponentIsDisabled(this.id) }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }
}