import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { AuthService } from '../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../src/app/shared/CaretexComponent';
import { PagenationObject } from '../../../../src/app/models/pagenation';
import { CaretxPhsioTherapyProviderService } from '../Physiotherapty.data-provider.service';
import { PhysioTherapyFollowUp } from '../../../../src/app/models/physiotherapy';
import { OptionObject } from '../../../../src/app/services/enums';
import { CaretxSharedModulesProviderService } from '../../../../src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { Attachment } from '../../../../src/app/models/attachments';
import { AttachmentDialogComponent } from '../../caretx-shared-module/CaretexModals/attachment-dialog/attachment-dialog.component';
import { DocViewerComponent } from 'src/app/caretx-shared-module/CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { CameraDialogComponent } from 'src/app/caretx-shared-module/CaretexModals/camera-dialog/camera-dialog.component';
import { MatDialog } from '@angular/material/dialog';




@Component({
  selector: 'app-physiotherapyfollowups',
  templateUrl: './physiotherapyfollowups.component.html',
  styleUrls: ['./physiotherapyfollowups.component.scss']
})
export class PhysiotherapyfollowupsComponent extends CaretexComponent  implements OnInit {

  moduleName: string = "מעקבי פיזוטרפיה"
  personalId: string
  followups: PhysioTherapyFollowUp[] = []
  FileCategoryList: OptionObject[] = [{ id: 'physioobsrvfile', name: 'מסמכי מעקב פיזוטרפיה' },]
  currentFollowUp: PhysioTherapyFollowUp
  isFollowUpClosed: boolean
  pagenationObject: PagenationObject
  showActions:boolean = false
  medicationtype:string=""
  LoggedInUser:string
  step: number = 1
  substep: number = 1
  pdfContent: any


  constructor(private router: Router,
    public medicalFileService: MedicalFileProviderService,
    private _title: Title,
    private authService: AuthService,
    public dialog: MatDialog,
    private caretxSharedlService: CaretxSharedModulesProviderService,
    private toastrService: ToasterService,
    private phsiotheraptyService :CaretxPhsioTherapyProviderService,
    private route: ActivatedRoute,) { super(medicalFileService) 
      this.LoggedInUser = this.authService.LoggedInUser.UID 
    }

  ngOnInit(): void {
    this._title.setTitle('Caretx | מעקבי פיזוטרפיה');
    if(this.route.parent != null)
    this.personalId = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.personalId)
    if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
      this.step = Number(this.route.snapshot.params['stepid']);
    }

    // Get Total Number of records :
    this.getTotalNumOfFollowUpsFromDB()
    this.getFollowUpById(this.personalId, "", "new")
    this.getFollowUpListFromDB("1")
  }

  getFollowUpListFromDB(page: string) {
    this.phsiotheraptyService.getPhysioTherapyFollowUps(this.personalId, "", "list", page).subscribe((data: PhysioTherapyFollowUp[]) => {
      if (data.length > 0) {
        this.followups = data
      }
      else {
        this.followups = []
      }
    });
  }

  getTotalNumOfFollowUpsFromDB() {
    this.phsiotheraptyService.getTotalOfPhysioTherapyFollowups(this.personalId).subscribe((data: number) => {
      this.pagenationObject = new PagenationObject(data)
    });
  }

  getFollowUpById(id: string, followupid: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + followupid + '\'' + ')'

    this.phsiotheraptyService.getPhysioTherapyFollowUps(id, filter, mode, "1").subscribe((data: PhysioTherapyFollowUp[]) => {
      if (data.length == 1) {
        this.currentFollowUp = new PhysioTherapyFollowUp(data[0])
        this.isFollowUpClosed = (this.currentFollowUp.Status == "C")
      }
      else {
        let errorMsg = "נמצאו יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
    editor.isReadOnly = this.isFollowUpClosed
  }

  SaveRecord() {
    this.currentFollowUp.LastUpdateById = this.authService.LoggedInUser.UID
    this.DoSave()
  }

  DoSave() {
    this.phsiotheraptyService.createNewPhysioTherapyFollowUp(this.currentFollowUp).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentFollowUp = data as PhysioTherapyFollowUp
        if (this.currentFollowUp.Status == 'C')
          this.isFollowUpClosed = true
        this.resetModuleAfterSave()
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.personalId + "/physiotherapyfollowup/"+"step/" + this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)

      }
    })
  }
 
  setStep(index: number) {
    this.step = index;
  }

  CheckIfDocumentIsDirty() {
    return false
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  resetModuleAfterSave() {
  }

  CopyLastReportedFollowUp() {
  }

  ClearTextField() {
  }

  AttachFile() {
    const dialogRef = this.dialog.open(AttachmentDialogComponent, {
      data: {
        UserId: this.LoggedInUser,
        FileCategoryList: this.FileCategoryList,
        Id: this.personalId 

      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Save Diagnoses
      if (result == undefined || result == null || result == "") return
      this.updateRefreshedObservFiles()
    });
  }
  ShowWebcam(){
    const dialogRef = this.dialog.open(CameraDialogComponent, {
      data: {
        FileCategory: this.FileCategoryList[0].id,
        Id: this.personalId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateRefreshedObservFiles()
    });
  }
  
  HandlePagenation($event) {
    this.getFollowUpListFromDB($event)
  }

  updateRefreshedObservFiles(){
    let filter :string = "file_category = 'physioobsrvfile'"

     this.caretxSharedlService.getAttachments(this.personalId , filter).subscribe((data: Attachment[]) => {
      if (data.length > 0) {
        this.currentFollowUp.PhysioObservationFiles = data
      }
      else {
        this.currentFollowUp.PhysioObservationFiles = []
      }
    });
  }

  downloadFile(file: Attachment) {
    const dialogRef = this.dialog.open(DocViewerComponent, {
      data: {
        Type:'GF', // General File
        Id:this.personalId,
        Attachment: file
      }
    });
  }
}

