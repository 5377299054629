import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { DrugInstruction } from '../../../../../src/app/models/drugInstruction';
import { CaretexEventTypes, CartexEvent, TabHistory } from '../../../../../src/app/models/genericTypes';
import { MedicalAdmission } from '../../../../../src/app/models/medicaladmission';
import { MedicalRelease } from '../../../../../src/app/models/MedicalRelease';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { AccessComponenetType, MedicalAdmissionSteps, MedicalReleaseSteps } from '../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';

import { CaretxMedicalProviderService } from '../../doctor-services/Medical.data-provider.service';

@Component({
  selector: 'app-medical-release',
  templateUrl: './medical-release.component.html',
  styleUrls: ['./medical-release.component.css']
})
export class MedicalReleaseComponent extends CaretexComponent implements OnInit {

  @ViewChild('subform1') subform1: NgForm;


  currentmedicalRelease: MedicalRelease
  LastMedicalAdmission: MedicalAdmission
  CurrentStep: MedicalReleaseSteps = MedicalReleaseSteps.BackGround
  releaseId: string
  personalId: string
  isrecordClosed: boolean
  medicationTitle: string = "תרופות בשחרור"
  medicationtype: string = "F"
  CurrentSelectedDrugInstr: DrugInstruction
  Type: string = AccessComponenetType.SubModule
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  tabHistory: TabHistory = new TabHistory()
  caretexEvent: CartexEvent = new CartexEvent()
  originid: string
  originType: string = "medicalrelease"
  isDocumentChnaged: boolean = false
  moduleName: string = "שחרור רפואי"
  zoomInModalData: any;
  dismissModal:boolean = false
  step: number = 0
  constructor(private caretxMedicalProviderService: CaretxMedicalProviderService,
    private _ar: ActivatedRoute,
    private route: ActivatedRoute,
    private router: Router,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {
    super(medicalFileServive)
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  ngOnInit(): void {
    this.personalId = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.personalId)
    this.releaseId = this.route.snapshot.params.releaseid
    if (this.route.snapshot.params['tabid'] != "" && this.route.snapshot.params['tabid'] != undefined) {
      this.CurrentStep = (this.route.snapshot.params['tabid'] as MedicalReleaseSteps)
    }

    if (this.releaseId == 'new') {
      this.getMedicalReleaseById(this.personalId, this.releaseId, "new")
    }
    else {
      this.getMedicalReleaseById(this.personalId, this.releaseId, "retreive")
    }
  }

  getMedicalReleaseById(id: string, releaseid: string, mode: string) {
    let filter = null
    if (mode == "new") {
      filter = '(id=' + '\'' + releaseid + '\'' + ')'
    }
    this.caretxMedicalProviderService.getMedicalRelease(id, filter, mode).subscribe((data: MedicalRelease[]) => {
      if (data.length == 1) {
        this.currentmedicalRelease = new MedicalRelease(data[0])
        this.isrecordClosed = (this.currentmedicalRelease.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }

  selectTab(tabId: string) {
    this.tabHistory.CurrentTab = this.CurrentStep
    this.tabHistory.NextTab = tabId
    this.caretexEvent.EventType = CaretexEventTypes.Tab
    this.caretexEvent.EventData = this.tabHistory

    if (this.CurrentStep == MedicalReleaseSteps.PermenantDrugDetails ||
      this.CurrentStep == MedicalReleaseSteps.Diagnoses)
      this.eventsSubject.next(this.caretexEvent);
    else {
      this.CurrentStep = (tabId as MedicalReleaseSteps)
      if (this.CheckIfDocumentIsDirty())
        this.saveMedicalRelease()
      else {
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
      }
    }
  }

  newMainComplaint() {
    if (this.currentmedicalRelease.ReasonForAdmission != "") {
      Swal.fire({
        title: 'הודעת מערכת',
        text: "האם אתה רוצה למחוק שדה סיבת קבלה ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק'
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentmedicalRelease.ReasonForAdmission = ""
        }
      })
    }
    else {
      this.currentmedicalRelease.ReasonForAdmission = ""
    }

  }

  newBackgroundDiesese() {
    if (this.currentmedicalRelease.BackGroundDisease != "") {
      Swal.fire({
        title: 'הודעת מערכת',
        text: "האם אתה רוצה למחוק שדה מחלות רקע?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentmedicalRelease.BackGroundDisease = ""
        }
      })
    }
    else {
      this.currentmedicalRelease.BackGroundDisease = ""
    }

  }

  newCurrentDiesese() {
    if (this.currentmedicalRelease.CurrentDisease != "") {
      Swal.fire({
        title: 'הודעת מערכת',
        text: "האם אתה רוצה למחוק שדה מחלה נוכחית?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק'
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentmedicalRelease.CurrentDisease = ""
        }
      })
    }
    else {
      this.currentmedicalRelease.CurrentDisease = ""
    }

  }

  copyMainComplaint() {
    if (this.currentmedicalRelease.ReasonForAdmission != "") {
      Swal.fire({
        title: 'לידיעתך',
        text: "פעולת העתקה תחליף את הטקסט בשדה סיבת הקבלה ,רוצה להמשיך?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמשיך',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.copyTextFromAdmission("maincomplient")
        }
      })
    }
    else {
      this.copyTextFromAdmission("maincomplient")
    }

  }

  copyBackgroundDiesese() {
    if (this.currentmedicalRelease.BackGroundDisease != "") {
      Swal.fire({
        title: 'לידיעתך',
        text: "פעולת העתקה תחליף את הטקסט בשדה מחלות רקע ,רוצה להמשיך?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמשיך',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.copyTextFromAdmission("backgrounddeiseses")
        }
      })
    }
    else {
      this.copyTextFromAdmission("backgrounddeiseses")
    }
  }

  copyCurrentDiesese() {
    if (this.currentmedicalRelease.CurrentDisease != "") {

      Swal.fire({
        title: 'לידיעתך',
        text: "פעולת העתקה תחליף את הטקסט בשדה מחלה נוכחית ,רוצה להמשיך?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמשיך',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.copyTextFromAdmission("currentdeiseses")
        }
      })
    }
    else {
      this.copyTextFromAdmission("currentdeiseses")
    }
  }

  copyTextFromAdmission(targetField: string) {
    let status = "C"
    let filter = '(status=' + '\'' + status + '\'' + ')'
    if (this.currentmedicalRelease.LastMedicalAdmission == undefined || this.currentmedicalRelease.LastMedicalAdmission == null) {
      this.caretxMedicalProviderService.getMedicalAdmissions(filter, this.personalId, 1, "list").subscribe((data: MedicalAdmission[]) => {
        if (data.length == 1) {
          this.LastMedicalAdmission = new MedicalAdmission(data[0])
          switch (targetField) {
            case "maincomplient":
              this.currentmedicalRelease.ReasonForAdmission = this.currentmedicalRelease.LastMedicalAdmission.MainComplaint
              break
            case "currentdeiseses":
              this.currentmedicalRelease.CurrentDisease = this.currentmedicalRelease.LastMedicalAdmission.CurrentDisease
              break
            case "backgrounddeiseses":
              this.currentmedicalRelease.BackGroundDisease = this.currentmedicalRelease.LastMedicalAdmission.OldComplaint
            default:
              break
          }
        }
        else {
          let errorMsg = "העתקה נכשלה"
          this.toastrService.Error("", errorMsg)
        }
      });
    }
    else {
      switch (targetField) {
        case "maincomplient":
          this.currentmedicalRelease.ReasonForAdmission = this.currentmedicalRelease.LastMedicalAdmission.MainComplaint
          break
        case "currentdeiseses":
          this.currentmedicalRelease.CurrentDisease = this.currentmedicalRelease.LastMedicalAdmission.CurrentDisease
          break
        case "backgrounddeiseses":
          this.currentmedicalRelease.BackGroundDisease = this.currentmedicalRelease.LastMedicalAdmission.OldComplaint
        default:
          break
      }
    }

  }

  submitForm() {
    this.subform1.onSubmit(undefined);
  }

  saveMedicalRelease() {
    this.currentmedicalRelease.DrugInstructions = [] // only include 1 drug instruction that is currently being updated
    if (this.CurrentSelectedDrugInstr != undefined && this.CurrentSelectedDrugInstr.New) {
      // Need To Check Mandatory Fields are filled

      this.tabHistory.CurrentTab = this.CurrentStep
      this.tabHistory.NextTab = MedicalAdmissionSteps.PermenantDrugs
      this.caretexEvent.EventType = CaretexEventTypes.Save
      this.caretexEvent.EventData = this.tabHistory

      this.eventsSubject.next(this.caretexEvent);

    } else {
      if (this.releaseId == "new") {
        this.DoSave()
      }
      else
        this.DoUpdate()
    }
  }


  DoUpdate() {
    this.caretxMedicalProviderService.updateMedicalRelease(this.currentmedicalRelease).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentmedicalRelease = data as MedicalRelease
        this.subform1.reset(this.subform1.value);

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        if (this.currentmedicalRelease.Status == "C") {
          this.medicalFileService.ResetCachedMedicalFile(this.personalId)
        }
        this.router.onSameUrlNavigation = 'reload';

        if (this.CurrentStep == MedicalReleaseSteps.PermenantDrugDetails)
          this.CurrentStep = MedicalReleaseSteps.PermenantDrugs

        this.router.navigateByUrl("caretexfile/" + this.personalId + "/MedicalRelease/" + this.currentmedicalRelease.ID + "/" + this.CurrentStep)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoSave() {
    this.caretxMedicalProviderService.createNewMedicalRelease(this.currentmedicalRelease).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentmedicalRelease = data as MedicalRelease
        this.subform1.reset(this.subform1.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.personalId + "/MedicalRelease/" + this.currentmedicalRelease.ID + "/" + this.CurrentStep)

      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  unSavedNewlyDiagnoses($event) {
  }

  deleteDiagnosesLine($event) {
    this.caretxMedicalProviderService.deleteMeidcalAdmissionDiagnoses(this.currentmedicalRelease.ID, this.currentmedicalRelease.Diagnoses[$event]).subscribe({
      next: data => {
        this.currentmedicalRelease.Diagnoses.splice($event, 1);
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }

  BackToMainDocument() {

    if (this.CurrentStep == MedicalReleaseSteps.PermenantDrugDetails)
      this.CurrentStep = MedicalReleaseSteps.PermenantDrugs
    else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.onSameUrlNavigation = 'reload'
      this.router.navigateByUrl("caretexfile/" + this.personalId + "/MedicalRelease")
    }



  }




  updatePersonalMedicalMeasures($event) {
    this.currentmedicalRelease.PersonalMeasuresHeader.PersonalMeasures = $event
  }

  updateNextTabFromDrugInstr($event) {
    this.CurrentStep = ($event as MedicalReleaseSteps)
  }

  updateNextTabFromDiagnoses($event) {
    this.CurrentStep = ($event as MedicalReleaseSteps)
    this.saveMedicalRelease()
  }

  InitialzePersonelMeasures() {
    this.currentmedicalRelease.PersonalMeasuresHeader.TenantID = this.authServive.LoggedInTenantID
    this.currentmedicalRelease.PersonalMeasuresHeader.UserId = this.authServive.LoggedInUser.UID
    this.currentmedicalRelease.PersonalMeasuresHeader.PersonelID = this.personalId
    this.currentmedicalRelease.PersonalMeasuresHeader.Status = 'A'
    this.currentmedicalRelease.PersonalMeasuresHeader.CreatedAt = new Date()
    this.currentmedicalRelease.PersonalMeasuresHeader.LastUpdateById = this.authServive.LoggedInUser.UID
  }

  selectIfToContinueTherapy($event) {
    if ($event == "true")
      this.currentmedicalRelease.ContinueTherapy = true
    else
      this.currentmedicalRelease.ContinueTherapy = false
  }

  CheckIfDocumentIsDirty() {
    const medicalDrugListDirty = this.currentmedicalRelease.DrugInstructions.some(row => {
      return row.New || row.Modified
    })
    const medicalDiagnoses = this.currentmedicalRelease.Diagnoses.some(row => {
      return row.New || row.Modified
    })
    return  medicalDrugListDirty || medicalDiagnoses
  }

  lockMedicalRelease() {
    Swal.fire({
      title: 'לידיעתך',
      text: "נעילת רשומה זו יפסיק את היכולת שלך לעדכן תיק רפואי זה , להמשיך ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תנעל רשומה',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentmedicalRelease.Status = "C"
        this.currentmedicalRelease.ClosedById = this.authServive.LoggedInUser.UID
        this.currentmedicalRelease.Closed_at = new Date()
        this.saveMedicalRelease()
      }
    })
  }

  CheckIfDrugInstIsValid() {

    let msg: string = ""

    if (this.CurrentSelectedDrugInstr.drugId == undefined || this.CurrentSelectedDrugInstr.drugId == "")
      msg += "תרופה חובה" + ","
    if (this.CurrentSelectedDrugInstr.dose == undefined || this.CurrentSelectedDrugInstr.dose == 0)
      msg += "מינון חובה" + ","
    if (this.CurrentSelectedDrugInstr.doseUom == undefined || this.CurrentSelectedDrugInstr.doseUom == "")
      msg += "יחידת מדידת מינון חובה" + ","
    if (this.CurrentSelectedDrugInstr.dosageForm == undefined || this.CurrentSelectedDrugInstr.dosageForm == "")
      msg += "דרך מתן חובה" + ","
    if (this.CurrentSelectedDrugInstr.frequency == undefined || this.CurrentSelectedDrugInstr.frequency == "")
      msg += "תדירות חובה" + ","
    if (this.CurrentSelectedDrugInstr.frequencyUom == undefined || this.CurrentSelectedDrugInstr.frequencyUom == "")
      msg += "יחידת תדירות חובה" + ","


    return msg
  }


  zoomInField(title, fieldNgModel, preFilledData) {
    this.dismissModal = false
    this.zoomInModalData = {title, field: fieldNgModel, preFilled: (preFilledData || '')};
   
  }



  bindFieldData(responseData) {
    this.currentmedicalRelease[this.zoomInModalData.field] = responseData;

  }

  DismissCtxTextareaModal(){
      this.dismissModal = true
  }

  setStep(index: number) {
    this.step = index;
  }

  /****************************** */
  drugInstructionChanged: boolean = false
  isNewRecord: boolean = false


  drugInstrDetailsConfirmedSaveRequest($event) {

  }

  backToMainDrugsListSub() {
    this.step = 3
  }

  drugInstructionChangedSub($event) {
    this.drugInstructionChanged = $event
  }

  openSelectedDrugInstr($event) {

    this.step = 3.1
    if ($event == null || $event == undefined) {
      // Do nothing for now
      this.CurrentSelectedDrugInstr = new DrugInstruction(undefined, this.personalId, this.authServive.LoggedInTenantID)
      this.CurrentSelectedDrugInstr.instrStatus = "D"
      this.CurrentSelectedDrugInstr.Doctor = this.authServive.LoggedInUser
      this.CurrentSelectedDrugInstr.doctorId = this.authServive.LoggedInUser.UID
      this.CurrentSelectedDrugInstr.instructionType = "F"
      this.CurrentSelectedDrugInstr.Origin = "medicalrelease"
      this.CurrentSelectedDrugInstr.New = true
      this.CurrentSelectedDrugInstr.AllowOnRelease = true
      this.isNewRecord = true
    } else {
      let index = $event
      this.CurrentSelectedDrugInstr = this.currentmedicalRelease.DrugInstructions[index]
      this.currentmedicalRelease.DrugInstructions[index].Modified = true
      this.isNewRecord = false
    }
  }



  AddNewDrugInstruction($event) {
    this.currentmedicalRelease.DrugInstructions.push($event)
    this.CurrentSelectedDrugInstr = $event
  }

  selectInstrForRelease($event) {
    this.currentmedicalRelease.DrugInstructions = $event
  }

}
