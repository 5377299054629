import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { PersonelSensitivity } from '../../../../../src/app/models/drugsenstivity';
import { CartexEvent,CaretexEventTypes } from '../../../../../src/app/models/genericTypes';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { sideNaveGroups } from '../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { CommonInterface } from '../../../../../src/app/shared/SharedInterface';
import { CaretxMedicalProviderService } from '../../doctor-services/Medical.data-provider.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sensitivities1',
  templateUrl: './sensitivities.component.html',
  styleUrls: ['./sensitivities.component.css']
})
export class SensitivitiesComponent extends CaretexComponent implements OnInit, CommonInterface {
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  personalId: string
  PersonelSensitivity: PersonelSensitivity
  moduleName: string = "רגישיות"
  caretexEvent: CartexEvent = new CartexEvent()

  @Input() OriginType: boolean
  @Input() OriginID: boolean

  unkownsensitivityStatus: { id: string; statusdescr: string }[] = [
    { id: "H", statusdescr: "ידוע לרגישות" },
    { id: "U", statusdescr: "לא ידוע לרגישות" },


  ];


  constructor(
    private _title: Title,
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private _ar: ActivatedRoute,) { super(medicalFileServive) }
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Medical;

  ngOnInit(): void {
    this._title.setTitle('Caretex |רגישות');
    if (this.route.parent != null)
    this.personalId = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.personalId)
    this.getDrugSensitivities()
  }

  getDrugSensitivities() {
    this.caretxMedicalProviderService.getPersoenlSesitivity(this.personalId).
      subscribe({
        next: data => {
          this.PersonelSensitivity = new PersonelSensitivity(data)
        },
        error: error => {
          this.toastrService.Error("", error.error.message)
        }
      })
  }


  Save() {
    this.PersonelSensitivity.OtherSensitivity.PersonelID = this.personalId
    this.PersonelSensitivity.OtherSensitivity.RealmID = this.authServive.LoggedInTenantID
    this.PersonelSensitivity.OtherSensitivity.UserId = this.authServive.LoggedInUser.UID
    this.caretexEvent.EventType = CaretexEventTypes.Save
    this.eventsSubject.next(this.caretexEvent);
  }

  DoSave(){
    this.caretxMedicalProviderService.createUpdatePersoenlSesitivity(this.personalId, this.PersonelSensitivity).subscribe({
      next: data => {
       // this.getDrugSensitivities()
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.medicalFileService.ResetCachedMedicalFile(this.personalId)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload'
        this.router.navigateByUrl("caretexfile/" + this.personalId + "/sensitivities")
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }


  DoRefresh(flag:boolean) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload'
    this.router.navigateByUrl("caretexfile/" + this.personalId + "/sensitivities")
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const drugSensitivities = this.PersonelSensitivity.DrugSensitivity.some(row => {
      return row.New || row.Modified
    })

    const otherSensitivities = this.PersonelSensitivity.OtherSensitivity.New || this.PersonelSensitivity.OtherSensitivity.Modified
    return drugSensitivities || otherSensitivities
  }


}
