import { propertyMap } from './modelmapper'



export class Vaccinations {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Type')
    public Type:string
    @propertyMap('Dosage')
    public Dosage:string
    @propertyMap('NumOfDoses')
    public NumOfDoses:number
    @propertyMap('Field1')
    public Field1:string
    @propertyMap('Field2')
    public Field2:string
    @propertyMap('Field3')
    public Field3:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}



export class Ambulance {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('AmbulanceName')
    public AmbulanceName:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('PrimaryContact')
    public PrimaryContact:string
    @propertyMap('Phone1')
    public Phone1:string
    public IsGlobal:boolean
    @propertyMap('Modified')
    @propertyMap('Phone2')
    public Phone2:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false
}
export class EatingManner {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false
}

export class Texture {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false
}

export class MenuCode {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false
}

export class DietaryUomCode {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false
}

export class MealCode {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false
}

export class FoodAddition {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false
}

export class DietarySupplement {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false
}

export class DrugUom {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Uom')
    public Uom:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public isNew :boolean = false
    public editMode :boolean = false
}

export class Hospital {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('HospitalName')
    public HospitalName:string
    @propertyMap('RealmID')
    public RealmID:string
    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean


}

export class WeightStatus {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string
    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
}

export class DrinkForm {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string

    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
}

export class FeedForm {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string
    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
}

export class NutritionalStatus {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}

export class SwallowStatus {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string
    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
}

export class TeethStatus {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string
    public editMode :boolean = false
    @propertyMap('Modified')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
}

export class EatingStatus {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('TenantID')
    public TenantID:string

    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
}

export class BedSoreType {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Type')
    public Type:string
    @propertyMap('Description')
    public Description:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode: boolean = false

}

export class BedSoreLocation {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Location')
    public Location:string
    @propertyMap('LocationCode')
    public LocationCode:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode: boolean = false
}

export class BedSoreStatus {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Status')
    public Status:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode: boolean = false
}

export class BedSoreSide {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Side')
    public Side:string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode: boolean = false
  
}

export class Departments {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('DepartmentName')
    public DepartmentName:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean


    constructor(data?: Object) {

        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.DepartmentName = data["DepartmentName"]
            this.RealmID = data["RealmID"]
            this.Modified = data["Modified"]
            this.IsGlobal = data["IsGlobal"]
          

        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
        }
    }



}

export class EventType {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
}

export class ConsciousnessState {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Description')
    public Description:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
}

export class EventLocation {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('LocationName')
    public LocationName:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
}

export class EventBeds{
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Bed')
    public Bed:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
}

export class WorkingShift {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Name')
    public Name:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('StartAt')
    public StartAt:Date
    @propertyMap('EndsAt')
    public EndsAt:Date

}

export class Referrer {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('ReferrerName')
    public ReferrerName:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('AdditionalField')
    public AdditionalField:string
}

export class NursingStatus {

    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('StatusDescr')
    public StatusDescr:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    @propertyMap('IsGlobal')
    public IsGlobal:boolean

    public editMode :boolean = false
}

export class MedicalFileStatus {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('StatusDescr')
    public StatusDescr:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}

export class Funder {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('FunderName')
    public FunderName:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}

export class DiagnosesTypes {

    @propertyMap('Id')
    public Id:string
    @propertyMap('Descr')
    public Descr:string
}

export class SocialProvider {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Description')
    public Description:string
    @propertyMap('Sequence')
    public Sequence:Number
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
   public editMode :boolean = false
}

export class FamilyRelations {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Description')
    public Description:string
    @propertyMap('Sequence')
    public Sequence:Number
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    @propertyMap('IsOtherVal')
    public IsOtherVal:boolean
   public editMode :boolean = false
}

export class MeasuresTypes {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('TenantID')
    public TenantID:string
    @propertyMap('Name')
    public Name:string
    @propertyMap('Sequence')
    public Sequence:number

    @propertyMap('Modified')
    public Modified:boolean

    @propertyMap('IsCaretex')
    public IsCaretex:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}

// Need Server to send Payload in below structure
export class SocialPocketTrackingCode {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PocketCodeID')
    public PocketCodeID: string
    @propertyMap('PocketCodeDescription')
    public PocketCodeDescription: string
    public isNew :boolean = false
    public editMode :boolean = false
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PocketCodeID = data["PocketCodeID"]
            this.PocketCodeDescription = data["PocketCodeDescription"]
          
            
        }

    }
}



export class PainLevel {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PainLevel')
    public PainLevel:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}

export class PainLocation {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PainLocation')
    public PainLocation:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}

export class PainMitigatingFactor {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PainMitigatingFactor')
    public PainMitigatingFactor:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}

export class PainSymptoms {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PainSymptoms')
    public PainSymptoms:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}

export class PainNature {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PainNature')
    public PainNature:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}

export class Paintreatment {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('Paintreatment')
    public Paintreatment:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}



export class ResistanceType {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('ResistanceType')
    public ResistanceType:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}


export class HospitalRoom {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('HospitalRoom')
    public HospitalRoom:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}

export class HospitalizationTeam {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('HospitalizationTeam')
    public HospitalizationTeam:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}


export class PcrTest {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PcrTestVal')
    public PcrTestVal:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}




export class HodgeTest {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('HodgeVal')
    public HodgeVal:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}



export class ContaminationSource {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('ContaminationSource')
    public ContaminationSource:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}


export class IsolationType {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('IsolationType')
    public IsolationType:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}


export class AdhesionPlace {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('AdhesionPlace')
    public AdhesionPlace:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}


export class InfectionStatus {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('InfectionStatus')
    public InfectionStatus:string
    @propertyMap('RealmID')
    public RealmID:string
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode :boolean = false
}



