
import { Observable, Subject } from "rxjs";
import { share } from "rxjs/operators";
import { MedicalFileProviderService } from "../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service";
import { MedicalFile } from "../models/customer";
import Swal from "sweetalert2";


export class CaretexComponent {

    public canMedicalPagesBeEditted: boolean = true
    public canNursingPagesBeEditted: boolean = true
    public canSocialPagesBeEditted: boolean = true
    public canPhysiotherapyPagesBeEditted: boolean = true
    public canDieticianPagesBeEdited: boolean = true
    public canOccupationTherapyPagesBeEdited: boolean = true
    public hasAllergy: boolean = false
    public Allergy: string 
    public HospitalizationMessage:string = "הדייר נמצא באשפוז חוץ"
    public IsArchived: boolean = false
    id: string
    public medicalFile$: Observable<MedicalFile>
    public medicalFile1: MedicalFile
    subject = new Subject<boolean>();
    justRout:boolean 

    constructor(public medicalFileService: MedicalFileProviderService) {
        this.canMedicalPagesBeEditted = true
        this.canNursingPagesBeEditted = true
    }

    CheckIfComponentIsDisabled(id: string) {
        this.medicalFile$ = this.medicalFileService.getMedicalFile(id).pipe(share())
        this.medicalFile$.subscribe(val => {
            this.medicalFile1 = new MedicalFile(val)
            this.canMedicalPagesBeEditted = !this.medicalFile1.IsMedicalReleased && !this.medicalFile1.IsArchived && !this.medicalFile1.IsHospitalized
            this.canNursingPagesBeEditted = !this.medicalFile1.IsNusrsingReleased && !this.medicalFile1.IsArchived && !this.medicalFile1.IsHospitalized
            this.canSocialPagesBeEditted = !this.medicalFile1.IsSocialReleased && !this.medicalFile1.IsArchived
            this.canPhysiotherapyPagesBeEditted = !this.medicalFile1.IsPhysiotherapyReleased && !this.medicalFile1.IsArchived && !this.medicalFile1.IsHospitalized
            this.canDieticianPagesBeEdited =  !this.medicalFile1.IsPhysiotherapyReleased && !this.medicalFile1.IsArchived && !this.medicalFile1.IsHospitalized
            this.canOccupationTherapyPagesBeEdited = !this.medicalFile1.IsOccupationTherapyReleased && !this.medicalFile1.IsArchived
            this.hasAllergy = this.medicalFile1.HasAllergy
            this.Allergy =  this.medicalFile1.Allergy

        })
    }



    showMessageWhenDirty(isDirty:boolean){
        if(isDirty){
            Swal.fire({
                title: "הודעת מערכת",
                text: "אתה עומד לעזוב את הדף עם שינויים שלא נשמרו. האם אתה רוצה להמשיך?",
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: "לא",
                confirmButtonText: 'כן',
                allowOutsideClick: false,
                allowEscapeKey: false
              }).then((result) => {
                if (result.isConfirmed) {
                  this.subject.next(true)
                  this.justRout = true
                }else{
                  this.subject.next(false)
                  this.justRout = false
                }
              })
            } else {
              this.subject.next(true)
              this.justRout = true
            }
        }
}