import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { LabCheck } from 'src/app/models/testlabchecks';

@Component({
  selector: 'app-personrl-lab-results',
  templateUrl: './personrl-lab-results.component.html',
  styleUrls: ['./personrl-lab-results.component.css']
})
export class PersonrlLabResultsComponent implements OnInit {
  id:string
  LabCodeResults:LabCheck[]
  @Input() LabCode: LabCheck
  @Output() closeMeasureResultsModal = new EventEmitter<void>();


  constructor( private route: ActivatedRoute,
    private _labService: CaretxMedicalProviderService,
    private _ar: ActivatedRoute,) { }

  ngOnInit(): void {
    this.id = this.route.parent.snapshot.params['id'];
    this.getLastXMeasuresOfMeasureCodeY()
  }

  getLastXMeasuresOfMeasureCodeY(){
    let filter = '(lab_template_id=' + '\'' + this.LabCode.LabTemplateId + '\'' + ')'
    this._labService.getLabCodeResults(this.id, filter).subscribe(
      (data :LabCheck[]) => {
        this.LabCodeResults= []


        data.forEach(labResult => {
          this.LabCodeResults.push(new LabCheck(labResult))
        }) 
      },
      err => {
        console.log("Error", err)
        this.LabCodeResults = []

      }
    )
  }

  closeModal() {
    this.closeMeasureResultsModal.emit()
  }

}
