import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';

@Component({
  selector: 'app-diabetic-foot-test',
  templateUrl: './diabetic-foot-test.component.html',
  styleUrls: ['./diabetic-foot-test.component.css']
})
export class DiabeticFootTestComponent extends CaretexComponent implements OnInit {

  isSubModule:boolean = false
id:string

 
  constructor(  
    private route: ActivatedRoute,
    public medicalFileService: MedicalFileProviderService,) {super(medicalFileService)
 }

  ngOnInit(): void {
    if(this.route.parent != null)
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)

  }






  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }



}
