import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ModalService } from '../../../../../src/app/modal';
import { Diagnoses } from '../../../../../src/app/models/diagnoses';
import { Drug } from '../../../../../src/app/models/drug';
import { DrugInstruction, TreatedDiagnoses } from '../../../../../src/app/models/drugInstruction';
import { DrugSensitivity, PersonelSensitivity } from '../../../../../src/app/models/drugsenstivity';
import { CaretexEventTypes, CartexEvent, TabHistory } from '../../../../../src/app/models/genericTypes';
import { OtherSensitivity } from '../../../../../src/app/models/othersensitivity';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { AccessComponenetType, BridgeEnums, DialogResult, MedicalAdmissionSteps, OptionObject } from '../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxMedicalProviderService } from '../../doctor-services/Medical.data-provider.service';
import { InstrStatus } from '../../../../../src/app/services/enums';
import { DrugUom } from '../../../../../src/app/models/generalhousedsetups';
import { CaretxSetupsProviderService } from '../../../caretx-setups-module/setup-services/setups.data-provider.service';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'app-regularmidicationdetails',
  templateUrl: './regularmidicationdetails.component.html',
  styleUrls: ['./regularmidicationdetails.component.css']
})
export class RegularmidicationdetailsComponent extends CaretexComponent implements OnInit {

  @ViewChild('drugSelectionMsg') drugSelectionMsg: ElementRef;
  @ViewChild('f') mainFormElement: NgForm;
  @ViewChild('uomform') uomormElement: NgForm;
  @Input() Type: AccessComponenetType
  @Input() currentDrugInstruction: DrugInstruction  //= new DrugInstruction()
  @Input() events: Observable<CartexEvent>;
  @Input() IsNewRecord: boolean;

  @Output() updateNextTab = new EventEmitter<string>();
  @Output() newDrugInstruction = new EventEmitter<DrugInstruction>();
  @Output() SaveActionIsConfirmedValid = new EventEmitter<boolean>();
  @Output() selectedDrugInstr = new EventEmitter<number>(); // For Sub Modules
  @Output() DrugInstrchanged = new EventEmitter<boolean>();

  @Output() backToMainList_sub = new EventEmitter<void>();

  private eventsSubscription: Subscription;
  private newDrugInstrEventsSubscription: Subscription;
  private nextTab: string
  private selectedDrug: Drug

  teekId: string
  InstrIsClosed = false
  IsDrugFreqVisible = true
  IsDrugInformationFound = false
  instrTypeSelected = false
  isFixedInstr = false;
  DrugFreqUomToBeDisplayed: string
  DrugPeriodDisplayed: string
  labelToDisplay: string
  drugNameForDisplay: string
  ListOfDrugSensitivity: DrugSensitivity[] = []
  DoseUoms: DrugUom[] = []   //OptionObject[]
  DrugFreqs: OptionObject[]
  DrugFreqUoms: OptionObject[]
  DrugTypes: OptionObject[]
  DrugDosageForms: OptionObject[]
  persoenlSensitivities: PersonelSensitivity
  OtherSensitivity: OtherSensitivity
  modalTarget: string
  isformDirty: boolean
  freqAsNum: number = 0
  hmo: string
  moduleName: string = "הוראת תרופה"
  isClosed: boolean = false
  isNewInstr: boolean = false
  showDiagnosesModal: boolean
  showEmptyTreatedDiagnosesMsg: boolean
  showImportanceMedicalMeasuresModal: boolean
  showLabResultModal: boolean
  isInstrClosed: boolean = false
  freqUomLbl: string = "ימים"
  showDaysOfTheWeek: boolean = false
  AfterStateReady: boolean = false
  public addTagNowRef: (name) => void;

  constructor(
    private _ar: ActivatedRoute,
    private modalService: ModalService,
    private caretxMedicalService: CaretxMedicalProviderService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    public medicalFileService: MedicalFileProviderService,
    private bridgeEnum: BridgeEnums,
    private toastrService: ToasterService,
    public medicalFileServive: MedicalFileProviderService,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    public afs: AngularFirestore,
    public datepipe: DatePipe
  ) {
    super(medicalFileServive)
    bridgeEnum = new BridgeEnums()

    this.caretxSetupsProviderService.getDrugUoms().subscribe((data => {
      this.DoseUoms = data
    }))
    this.DrugFreqs = bridgeEnum.DrugFreqs
    this.DrugFreqUoms = bridgeEnum.DrugFreqUoms
    this.DrugTypes = bridgeEnum.DrugTypes
    this.DrugDosageForms = bridgeEnum.DrugDosageForm
    this.persoenlSensitivities = new PersonelSensitivity()
    this.addTagNowRef = this.CreateNewUom.bind(this);
  }


  model: any = {};
  ngOnInit(): void {
    if (this.route.parent != null) {
      this.teekId = this.route.parent.snapshot.params['id']
    }
    this.isNewInstr = this.IsNewRecord
    this.CheckIfComponentIsDisabled(this.teekId)
    this.showImportanceMedicalMeasuresModal = false
    this.showLabResultModal = false

    this.medicalFileService.openedFile.subscribe(data => {
      this.hmo = data.HMO
    })
    if (this.hmo == undefined) {
      this.medicalFileService.getMedicalFile(this.teekId).subscribe(data => {
        this.hmo = data.HMO
      })
    }
    this.getPersonelSensitivityFromDB()
    if (this.Type == AccessComponenetType.SubModule) {
      this.manageWhencomeFromSubModule()
    } else {
      this.manageWhenComeFromModule()
    }
  }

  ngOnDestroy() {
    if (this.Type == AccessComponenetType.SubModule) {
      if (this.eventsSubscription != null && this.eventsSubscription != undefined)
        this.eventsSubscription.unsubscribe();

      if (this.mainFormElement.dirty)
        this.DrugInstrchanged.emit(true)
    }
  }

  ngAfterViewInit() {
    if (this.Type == AccessComponenetType.SubModule) {

      this.registerForFormChanges()
    }
  }

  manageWhencomeFromSubModule() {
    if (this.currentDrugInstruction.Drug != undefined) {
      this.drugNameForDisplay = this.currentDrugInstruction.buildDrugNameforDisplay()
    }

    this.isFixedInstr = (this.currentDrugInstruction.instructionType == 'F')
    this.isInstrClosed = this.currentDrugInstruction.instrStatus == 'A' || this.currentDrugInstruction.instrStatus == 'S' || this.currentDrugInstruction.instrStatus == 'SD'
    this.SetNumberOfTimeBoxes()
    this.checkFreqTypeSelection(this.currentDrugInstruction.frequencyUom)
    this.calcStopDate()
    this.eventsSubscription = this.events.subscribe((data) => this.confirmUnSavedChanges(data));
    this.newDrugInstrEventsSubscription = this.events.subscribe((data) => this.newDrugInstr(data));
  }

  manageWhenComeFromModule() {
    let instrid = this.route.snapshot.params.instrid
    if (!(instrid == 'new')) {
      this.isNewInstr = false
      this.getDrugInstructionById(instrid)
    }
    else {
      this.currentDrugInstruction = new DrugInstruction(undefined, this.teekId, this.authService.LoggedInTenantID)
      this.isNewInstr = true
    }
  }

  getPersonelSensitivityFromDB() {
    this.caretxMedicalService.getPersoenlSesitivity(this.teekId).subscribe((data: PersonelSensitivity) => {
      this.persoenlSensitivities = new PersonelSensitivity(data)
      this.ListOfDrugSensitivity = this.persoenlSensitivities.DrugSensitivity
      this.OtherSensitivity = this.persoenlSensitivities.OtherSensitivity
    });
  }

  getDrugInstructionById(id: string) {
    let filter = '(id=' + '\'' + this.route.snapshot.params.instrid + '\'' + ')'
    this.caretxMedicalService.getDrugInstrucs(this.teekId, filter).subscribe((data: DrugInstruction[]) => {
      if (data.length == 1) {
        this.currentDrugInstruction = new DrugInstruction(data[0])
        this.selectedDrug = this.currentDrugInstruction.Drug
        this.drugNameForDisplay = this.currentDrugInstruction.buildDrugNameforDisplay()
        this.isFixedInstr = (this.currentDrugInstruction.instructionType == 'F')
        this.isInstrClosed = this.currentDrugInstruction.instrStatus == 'A' || this.currentDrugInstruction.instrStatus == 'S' || this.currentDrugInstruction.instrStatus == 'SD'
        this.showEmptyTreatedDiagnosesMsg = this.currentDrugInstruction.TreatedDiagnoses.length == 0 ? true : false
        this.SetNumberOfTimeBoxes()
        this.checkFreqTypeSelection(this.currentDrugInstruction.frequencyUom)
        this.calcStopDate()
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }

  drugEventHander($event: Drug) {
    this.selectedDrug = $event;
    let filter = '(drug_id=' + '\'' + this.selectedDrug.id + '\'' + ' and (instr_status =\'\A\'\ or instr_status =\'\D\'\ ))'
    this.caretxMedicalService.getDrugInstrucs(this.teekId, filter).subscribe((data: DrugInstruction[]) => {
      if (data.length >= 1) {
        Swal.fire({
          title: "הודעת מערכת",
          text: "קיימת הוראה עבור תרופה שבחרת תהאם אתה רוצה להמשיך?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'כן',
          cancelButtonText: 'לא',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.validatDrugNotSensitive(this.selectedDrug.id)) {
              this.drugSelectionMsg.nativeElement.click()
            }
            else {
              this.handleDrugSelectionEvent()
            }
          }
        })
      }
      else {
        if (this.validatDrugNotSensitive(this.selectedDrug.id)) {
          this.drugSelectionMsg.nativeElement.click()
        }
        else {
          this.handleDrugSelectionEvent()
        }
      }
    })


  }

  showDrugSelectionHandler() {
    //show message 
    Swal.fire({
      title: "הודעת מערכת",
      text: "תרופה שבחרתה נמצאת ברשימת התרופות שיש לחולה רגישות אליהן ! ,רוצה להמשיך?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמשיך',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.handleDrugSelectionEvent()
      }
    })
  }

  handleDrugSelectionEvent() {
    this.currentDrugInstruction.Drug = this.selectedDrug;
    this.currentDrugInstruction.drugId = this.selectedDrug.id
    if (this.currentDrugInstruction.Drug.DrugIndication == "" && this.currentDrugInstruction.Drug.DrugIndication == undefined)
      this.currentDrugInstruction.Drug.DrugIndication = "מידע לא זמין"
    this.drugNameForDisplay = this.currentDrugInstruction.buildDrugNameforDisplay()
    this.IsDrugInformationFound = true
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  checkFreqTypeSelection(id) {
    this.IsDrugFreqVisible = true;
    this.labelToDisplay = "אחת ל"
    this.DrugPeriodDisplayed
    switch (this.currentDrugInstruction.frequencyUom) {
      case "D":
        this.freqUomLbl = "ימים"
        this.showDaysOfTheWeek = false
        //  this.DrugFreqUomToBeDisplayed = "ימים"
        this.DrugPeriodDisplayed = this.DrugFreqUomToBeDisplayed
        break;
      case "P":
        this.showDaysOfTheWeek = false
        this.IsDrugFreqVisible = false;
        this.labelToDisplay = "מקסימום"
        this.DrugFreqUomToBeDisplayed = "ביום"
        this.DrugPeriodDisplayed = "ימים"
        break;
      case "W":
        this.showDaysOfTheWeek = true
        this.freqUomLbl = "שבועות"
        //  this.DrugFreqUomToBeDisplayed = "שבועות"
        this.DrugPeriodDisplayed = this.DrugFreqUomToBeDisplayed
        break;
      case "M":
        this.showDaysOfTheWeek = false
        this.freqUomLbl = "חודשים"
        //  this.DrugFreqUomToBeDisplayed = "חודשים"
        this.DrugPeriodDisplayed = this.DrugFreqUomToBeDisplayed
        break;
      default:
        this.showDaysOfTheWeek = false
        this.freqUomLbl = "ימים"
        //this.DrugFreqUomToBeDisplayed = "ימים"
        this.DrugPeriodDisplayed = this.DrugFreqUomToBeDisplayed
        break;
    }
  }

  onchangeDrugFreqUom($event) {
    this.checkFreqTypeSelection(this.currentDrugInstruction.frequencyUom)
  }

  onchangeDrugType($event, val) {
    this.currentDrugInstruction.instructionType = val
    this.instrTypeSelected = true
    this.isFixedInstr = false;
    switch (val) {
      case "F":
        this.isFixedInstr = true
        break
      default:
        break
    }
    // Send signal to parent to report change
    this.DrugInstrchanged.emit(true)
  }

  commentModalHander($event) {
    let dialogResult: DialogResult = $event
    switch (dialogResult) {
      case DialogResult.OK:
        this.doSaveAction()
        break;
      case DialogResult.Cancel:
        break;
      default:
        break
    }
  }

  submitForm() {
    this.mainFormElement.onSubmit(new Event("", undefined));
  }

  submitUomForm() {
    this.uomormElement.onSubmit(new Event("", undefined));
  }

  CreateNewUom(uomVal) {
    // Create New Uom 
    var newDrugUom = new DrugUom()
    newDrugUom.New = true
    newDrugUom.editMode = true
    newDrugUom.CreatedAt = new Date()
    newDrugUom.UpdatedAt = new Date()
    newDrugUom.TenantID = this.authService.LoggedInTenantID
    newDrugUom.Uom = uomVal

    let uoms: DrugUom[] = []
    uoms.push(newDrugUom)

    this.caretxSetupsProviderService.CreateUpdateDrugUoms(uoms).subscribe({
      next: data => {
        this.getDrugUomsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })

  }

  getDrugUomsFromDB() {
    this.caretxSetupsProviderService.getDrugUoms().subscribe(
      data => {
        this.DoseUoms = data
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      err => {
        this.DoseUoms = []

      }
    )
  }

  ActivateInstr() {
    // Update to Acive 
    this.currentDrugInstruction.instrStatus = InstrStatus.Active
    this.isInstrClosed = true
    if (!this.mainFormElement.valid)
      this.onSaveErrordmsg()
    else {
      if (this.currentDrugInstruction.drugId == null || this.currentDrugInstruction.drugId == undefined)
        this.showAlertMsgOnDrugMissing()
      else {
        // Show Alert Message
        Swal.fire({
          title: "אישור חתימת הוראת תרופה",
          text: "ההוראה תחתם ותשלח לצוות אחים/יות לחתימה!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'חתימה',
          cancelButtonText: 'בטל',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.currentDrugInstruction.instrStatus =  InstrStatus.SignedByDoctor
            this.currentDrugInstruction.SignedByDoctorAt = new Date()
            this.currentDrugInstruction.doctorId = this.authService.LoggedInUser.UID
            this.doSaveAction()

          }
        })
      }
    }
  }

  stopInstr() {
    this.currentDrugInstruction.instrStatus = InstrStatus.Stopped
    if (!this.mainFormElement.valid)
      this.onSaveErrordmsg()
    else {
      if (this.currentDrugInstruction.drugId == null || this.currentDrugInstruction.drugId == undefined)
        this.showAlertMsgOnDrugMissing()
      else {
        this.doSaveAction()
      }
    }
  }

  calcStopDate() {
    if (this.currentDrugInstruction.periodInDays != undefined) {
      let startDate: Date
      startDate = new Date(this.currentDrugInstruction.instrStartDate)
      startDate.setDate(startDate.getDate() + this.currentDrugInstruction.periodInDays);
      let endtDate: Date
      endtDate = startDate
      this.currentDrugInstruction.instrDueDate = endtDate
    }
  }

  backToInstrList() {
    this.router.navigateByUrl("caretexfile/" + this.teekId + "/druginstrs")
  }

  validatDrugNotSensitive(drugId: string): boolean {
    const res = this.ListOfDrugSensitivity.some(answer =>
      (answer.Drug.id == drugId))
    return res;
  }

  showAlertMsgOnDrugMissing() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "אתה צריך לבחור תרופה !",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'סגור',
      allowOutsideClick: false,
      allowEscapeKey: false
    })
  }

  generateDateForFastModal($event) {
    this.showImportanceMedicalMeasuresModal = true
    this.showLabResultModal = true
  }

  closeImportanceMeasureModal() {
    this.showImportanceMedicalMeasuresModal = false
  }

  closeLabResultModal() {
    this.showLabResultModal = false
  }

  SetNumberOfTimeBoxes() {
    switch (this.currentDrugInstruction.frequency) {
      case "x1":
        this.freqAsNum = 1
        break
      case "x2":
        this.freqAsNum = 2
        break
      case "x3":
        this.freqAsNum = 3
        break
      case "x4":
        this.freqAsNum = 4
        break
      case "x5":
        this.freqAsNum = 5
        break
    }
  }

  updateTimeField(field, $event) {
    var newarr = $event.split(":");

    let x1: number
    let x2: number

    switch (field) {
      case "time1":
        x1 = new Date(this.currentDrugInstruction.Time1).setHours(newarr[0])
        this.currentDrugInstruction.Time1 = new Date(x1)
        x2 = new Date(this.currentDrugInstruction.Time1).setMinutes(newarr[1])
        this.currentDrugInstruction.Time1 = new Date(x2)
        break
      case "time2":
        x1 = new Date(this.currentDrugInstruction.Time2).setHours(newarr[0])
        this.currentDrugInstruction.Time2 = new Date(x1)
        x2 = new Date(this.currentDrugInstruction.Time2).setMinutes(newarr[1])
        this.currentDrugInstruction.Time2 = new Date(x2)
        break
      case "time3":
        x1 = new Date(this.currentDrugInstruction.Time3).setHours(newarr[0])
        this.currentDrugInstruction.Time3 = new Date(x1)
        x2 = new Date(this.currentDrugInstruction.Time3).setMinutes(newarr[1])
        this.currentDrugInstruction.Time3 = new Date(x2)
        break
      case "time4":
        x1 = new Date(this.currentDrugInstruction.Time4).setHours(newarr[0])
        this.currentDrugInstruction.Time4 = new Date(x1)
        x2 = new Date(this.currentDrugInstruction.Time4).setMinutes(newarr[1])
        this.currentDrugInstruction.Time4 = new Date(x2)
        break
      case "time5":
        x1 = new Date(this.currentDrugInstruction.Time5).setHours(newarr[0])
        this.currentDrugInstruction.Time5 = new Date(x1)
        x2 = new Date(this.currentDrugInstruction.Time5).setMinutes(newarr[1])
        this.currentDrugInstruction.Time5 = new Date(x2)
        break
      default:
        break
    }
    this.formChanged()
  }

  updateAllergies() {
    if (this.selectedDrug == undefined || this.selectedDrug.DrugCode == "") {
      Swal.fire({
        title: "הודעת מערכת",
        text: "עליך לבחור תרופה קודם !",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'סגור',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    } else {
      let drugExists = false
      for (let i = 0; i < this.persoenlSensitivities.DrugSensitivity.length; i++) {
        if (this.selectedDrug.id == this.persoenlSensitivities.DrugSensitivity[i].DrugId) {
          drugExists = true
          break
        }
      }
      if (drugExists) {
        Swal.fire({
          title: "הודעת מערכת",
          text: "התרופה כבר נמצאת ברשימת ה רגישיות של המטופל",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'סגור',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      } else {
        var newDrugSens = new DrugSensitivity()
        newDrugSens.UserId = this.authService.LoggedInUser.UID
        newDrugSens.RealmID = this.authService.LoggedInTenantID
        newDrugSens.User = this.authService.LoggedInUser
        newDrugSens.User.UID = newDrugSens.UserId
        newDrugSens.PersonelID = this.teekId
        newDrugSens.editMode = newDrugSens.isNew = true;
        newDrugSens.CreatedAt = new Date()
        newDrugSens.Drug = this.selectedDrug
        newDrugSens.DrugId = this.selectedDrug.id
        newDrugSens.New = true
        newDrugSens.Modified = false
        this.persoenlSensitivities.HasSensitivity = "H"
        this.persoenlSensitivities.DrugSensitivity.unshift(newDrugSens)
        this.DoSaveDrugSensitivity()
      }
    }
  }

  checkInteractionWithDrugs() {
    window.open('https://reference.medscape.com/drug-interactionchecker', '_blank');
  }

  handleSelectedDiagnoses($event) {
    this.showDiagnosesModal = false
    let diagnoses: Diagnoses[] = []
    this.currentDrugInstruction.TreatedDiagnoses = []
    diagnoses = $event

    diagnoses.forEach(element => {
      let treatedDiagnoses: TreatedDiagnoses = new TreatedDiagnoses()
      treatedDiagnoses.DiagnosesId = element.ID
      treatedDiagnoses.DiagnosesRecord = element
      treatedDiagnoses.DoctorId = this.authService.LoggedInUser.UID
      treatedDiagnoses.PersonelID = this.teekId
      treatedDiagnoses.TenantID = this.authService.LoggedInTenantID
      treatedDiagnoses.DrugInstrId = this.currentDrugInstruction.ID
      this.currentDrugInstruction.TreatedDiagnoses.push(treatedDiagnoses)

    });

    this.showEmptyTreatedDiagnosesMsg = diagnoses.length > 0 ? false : true
  }
  selectDiagnosesForAlert() {
    this.showDiagnosesModal = true
  }
  /****************** Save Record *********************** */
  onSaveDrugInstr($event) {
    if (this.Type == AccessComponenetType.SubModule) {
      this.saveDrugRecord_Sub()
    } else {
      if (!this.mainFormElement.valid)
        this.onSaveErrordmsg()
      else {
        if (this.currentDrugInstruction.drugId == null || this.currentDrugInstruction.drugId == undefined)
          this.showAlertMsgOnDrugMissing()
        else {
          this.CheckToActivateInstruction()
        }

      }
    }
  }
  doSaveAction() {
    this.currentDrugInstruction.doctorId = this.authService.LoggedInUser.UID
    this.currentDrugInstruction.PersonelID = this.teekId
    this.currentDrugInstruction.RealmID = this.authService.LoggedInTenantID
    this.currentDrugInstruction.instrStartDate = new Date(this.currentDrugInstruction.instrStartDate)
    if (this.currentDrugInstruction.ID == undefined) {
      this.currentDrugInstruction.New = true
      this.caretxMedicalService.createNewDrugInstr(this.currentDrugInstruction).subscribe({
        next: data => {
          this.currentDrugInstruction = new DrugInstruction(data)
          this.mainFormElement.form.markAsPristine()
          this.resetFormChange()
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }

          if (this.Type == AccessComponenetType.Module) {
            this.router.navigateByUrl("caretexfile/" + this.teekId + "/druginstrs/" + this.currentDrugInstruction.ID)
          } else {

            // Update Selected Drug Instruction 
            this.currentDrugInstruction.New = false
            this.currentDrugInstruction.Modified = false
            this.newDrugInstruction.emit(this.currentDrugInstruction)
          }
          this.toastrService.Sucesss("", this.toastrService.successMsg)
          this.isNewInstr = false

        },
        error: error => {
          this.toastrService.Error("", this.toastrService.errorMsg)
        }
      })
    }
    else {
      this.currentDrugInstruction.Modified = true
      if (this.caretxMedicalService) {
        this.caretxMedicalService.updateDrugInstr(this.currentDrugInstruction).subscribe({
          next: data => {
            this.currentDrugInstruction = new DrugInstruction(data)

            this.toastrService.Sucesss("", this.toastrService.successMsg)
            this.mainFormElement.form.markAsPristine()
            this.resetFormChange()
          },
          error: error => {
            this.toastrService.Error("", this.toastrService.errorMsg)
          }
        })
      }

    }
  }
  DoSaveDrugSensitivity() {
    this.caretxMedicalService.createUpdatePersoenlSesitivity(this.teekId, this.persoenlSensitivities).subscribe({
      next: data => {
        this.getPersonelSensitivityFromDB()

        Swal.fire({
          title: "הודעת מערכת",
          text: "התרופה עודכנה ברשימת הריגישיות של המטופל",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'סגור',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }
  onSaveErrordmsg() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "אתה צריך למלאה את כל השדות שצבועות באדום!",
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: 'סגור',
      allowOutsideClick: false,
      allowEscapeKey: false
    })
  }
  validateBeforeSave() {
  }
  confirmUnSavedChanges(data: CartexEvent) {

    if (data.EventType == CaretexEventTypes.Tab || data.EventType == CaretexEventTypes.Save) {
      if ((data.EventData as unknown as TabHistory).CurrentTab == MedicalAdmissionSteps.PermenantDrugDetails) {
        this.nextTab = (data.EventData as unknown as TabHistory).NextTab
        this.DoConfirmUnSavedChanges()
      }
    }
  }
  DoConfirmUnSavedChanges() {
    if (this.currentDrugInstruction.drugId == null || this.currentDrugInstruction.drugId == undefined) {
      this.showAlertMsgOnDrugMissing()
    } else {
      this.mainFormElement.onSubmit(undefined)
      if (!this.mainFormElement.valid)
        this.onSaveErrordmsg()
      else {
        // this.updateNextTab.emit(this.nextTab)
        this.SaveActionIsConfirmedValid.emit(true)
      }
    }
  }
  /****************** New Record *********************** */

  onNewDrugInstr($event) {
    this.currentDrugInstruction.NewInstruction(this.teekId, this.authService.LoggedInTenantID)
  }

  newDrugInstr(eventType: CartexEvent) {
    if (eventType.EventType == CaretexEventTypes.New) {

      // Check if there are unsaved chnages
      if (this.mainFormElement.dirty || (this.currentDrugInstruction.drugId == null || this.currentDrugInstruction.drugId == undefined)) {
        Swal.fire({
          title: "הודעת מערכת",
          text: "הרשומה השתנתה רוצה לשמור שינויים",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'כן,תשמור',
          cancelButtonText: 'בטל',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.DoConfirmUnSavedChanges()
          }
        })
      } else {
        this.drugNameForDisplay = ""
        this.isInstrClosed = false
        this.isNewInstr = true
        this.selectedDrugInstr.emit(undefined)
      }
    }
  }

  cancelNewRecord() {
    this.backToInstrList()
  }

  newDrugInstrRecord() {
    // Check if there are unsaved chnages
    if (this.mainFormElement.dirty || (this.currentDrugInstruction.drugId == null || this.currentDrugInstruction.drugId == undefined)) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "הרשומה השתנתה רוצה לשמור שינויים",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תשמור',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DoConfirmUnSavedChanges()
        }
      })
    } else {
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      }
      this.router.navigateByUrl("caretexfile/" + this.teekId + "/druginstrs/new")
    }
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty == null ? false : this.mainFormElement.dirty
  }

  /*++++++++++++++++++Handling Action when Used as Sub module+++++++++++++++++++++++*/

  newDrugInstr_sub() {
    // Check if current opened Instruction is changed then suggest save first
    if (this.mainFormElement.dirty) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "הרשומה השתנתה רוצה לשמור שינויים",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תשמור',
        cancelButtonText: 'בטל',
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.DoConfirmUnSavedChanges()
        }
      })
    } else {
      this.drugNameForDisplay = ""
      this.isInstrClosed = false
      this.isNewInstr = true
      this.selectedDrugInstr.emit(undefined)
    }
  }

  BackToMainList_Sub() {
    if (this.mainFormElement.dirty) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "הרשומה השתנתה רוצה לשמור שינויים",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תשמור',
        cancelButtonText: 'בטל',
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.saveDrugRecord_Sub()
        }
      })
    } else {
      this.drugNameForDisplay = ""
      this.isInstrClosed = false
      this.backToMainList_sub.emit()
    }
  }

  saveDrugRecord_Sub() {
    // Check  Mandatory Fields and Raise Alert Messages when needed 
    if (!this.mainFormElement.valid)
      this.onSaveErrordmsg()
    else {
      if (this.currentDrugInstruction.drugId == null || this.currentDrugInstruction.drugId == undefined)
        this.showAlertMsgOnDrugMissing()
      else {
        this.CheckToActivateInstruction()
      }
    }
  }

  doSaveAction_Sub() {
    this.currentDrugInstruction.doctorId = this.authService.LoggedInUser.UID
    this.currentDrugInstruction.PersonelID = this.teekId
    this.currentDrugInstruction.RealmID = this.authService.LoggedInTenantID
    this.currentDrugInstruction.instrStartDate = new Date(this.currentDrugInstruction.instrStartDate)
    // MArk it as Admission Origin
    this.currentDrugInstruction.IsFromAdmission = true

    if (this.currentDrugInstruction.ID == undefined) {
      this.currentDrugInstruction.New = true
      this.caretxMedicalService.createNewDrugInstr(this.currentDrugInstruction).subscribe({
        next: data => {
          this.currentDrugInstruction = new DrugInstruction(data)
          this.currentDrugInstruction.New = false
          this.currentDrugInstruction.Modified = false
          // this.currentDrugInstruction.instrStatus = "D"
          this.newDrugInstruction.emit(this.currentDrugInstruction)
          this.toastrService.Sucesss("", this.toastrService.successMsg)
          this.mainFormElement.form.markAsPristine()
          this.isNewInstr = false
          this.prepareSelectedDrugInstr()
          this.resetFormChange()
        },
        error: error => {
          this.toastrService.Error("", this.toastrService.errorMsg)
        }
      })
    }
    else {
      this.currentDrugInstruction.Modified = true
      if (this.caretxMedicalService) {
        this.caretxMedicalService.updateDrugInstr(this.currentDrugInstruction).subscribe({
          next: data => {
            this.toastrService.Sucesss("", this.toastrService.successMsg)
            this.mainFormElement.form.markAsPristine()
            this.resetFormChange()
          },
          error: error => {
            this.toastrService.Error("", this.toastrService.errorMsg)
          }
        })
      }

    }
  }

  CheckToActivateInstruction() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "אתה רוצה להפעיל את ההוראה ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן',
      cancelButtonText: 'לא',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
                Swal.fire({
                  title: "אישור חתימת הוראת תרופה",
                  text: "ההוראה תחתם ותשלח לצוות אחים/יות לחתימה!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'חתימה',
                  cancelButtonText: 'בטל',
                  allowOutsideClick: false,
                  allowEscapeKey: false
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.currentDrugInstruction.instrStatus =  InstrStatus.SignedByDoctor
                    this.currentDrugInstruction.SignedByDoctorAt = new Date()
                    this.currentDrugInstruction.doctorId = this.authService.LoggedInUser.UID
                    if (this.Type == AccessComponenetType.SubModule) { 
                      this.currentDrugInstruction.IsAdmissionInstrActive = true
                      this.doSaveAction_Sub()
                    } else {
                      this.doSaveAction()
                    }
                  }
                })
      } else {
        if (this.Type == AccessComponenetType.SubModule) {
          this.currentDrugInstruction.IsAdmissionInstrActive = false
          this.currentDrugInstruction.instrStatus = 'SP'
          this.doSaveAction_Sub()
        } else {
          this.doSaveAction()
        }
      }
    })
  }

  prepareSelectedDrugInstr() {
    this.selectedDrug = this.currentDrugInstruction.Drug
    this.drugNameForDisplay = this.currentDrugInstruction.buildDrugNameforDisplay()
    this.isFixedInstr = (this.currentDrugInstruction.instructionType == 'F')
    this.isInstrClosed = this.currentDrugInstruction.instrStatus == 'A' || this.currentDrugInstruction.instrStatus == 'S' || this.currentDrugInstruction.instrStatus == 'SD'
    this.showEmptyTreatedDiagnosesMsg = this.currentDrugInstruction.TreatedDiagnoses.length == 0 ? true : false
    this.SetNumberOfTimeBoxes()
    this.checkFreqTypeSelection(this.currentDrugInstruction.frequencyUom)
    this.calcStopDate()
  }

  registerForFormChanges() {
  }

  formChanged() {
    // Send signal to parent to report change
    this.DrugInstrchanged.emit(true)
  }

  resetFormChange() {
    this.DrugInstrchanged.emit(false)
  }

  /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/




}
