import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../../src/app/services/auth.service' //from 'src/app/services/auth.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { Referrer } from '../../../models/generalhousedsetups';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';


@Component({
  selector: 'app-referrersetup',
  templateUrl: './referrersetup.component.html',
  styleUrls: ['./referrersetup.component.css']
})
export class ReferrersetupComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  referrers:Referrer[]  = []
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) { super(medicalFileServive) }

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getReferrersFromDB()
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.referrers.some(row => {
      return row.New || row.Modified
    })
    return  hospitalsLisChanged
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }

  getReferrersFromDB(){

    this.caretxSetupsProviderService.GetReferrers().subscribe(
      data => {
        this.referrers = data
      },
      err => {
        this.referrers = []

      }
    )
  }

  

  addNewReferrer(){
    var newReferrer = new Referrer()
    newReferrer.New = true
    newReferrer.editMode = true
    newReferrer.CreatedAt = new Date()
    newReferrer.UpdatedAt = new Date()
    newReferrer.RealmID = this.authServive.LoggedInTenantID
    this.referrers.unshift(newReferrer)
  }



  deleteRow(i){

    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.referrers[i].New){
          this.caretxSetupsProviderService.DeleteReferrers( this.referrers[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.referrers.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
              this.getReferrersFromDB()
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.referrers.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i){
    this.referrers[i].editMode = true
    this.referrers[i].Modified = true
  }
  cancelEditRow(i){
    this.referrers[i].editMode  = false;
    this.referrers[i].Modified = false
  }

  updateReferrer($event){
     this.caretxSetupsProviderService.CreateReferrers(this.referrers).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getReferrersFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })  
  }

}
