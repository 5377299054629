import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from 'src/app/modal/modal.service';


@Component({
  selector: 'app-drugindecationviewer',
  templateUrl: './drugindecationviewer.component.html',
  styleUrls: ['./drugindecationviewer.component.css']
})
export class DrugindecationviewerComponent implements OnInit {
@Input() drugIndeciation:string
  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}
