import { Component, OnInit } from '@angular/core';

import { MedicalFileProviderService } from '../../../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicationReviewHeader } from 'src/app/models/MedicationsReview';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { share } from 'rxjs/operators';


@Component({
  selector: 'app-patients-medication-reviews',
  templateUrl: './patients-medication-reviews.component.html',
  styleUrls: ['./patients-medication-reviews.component.css']
})
export class PatientsMedicationReviewComponent  extends CaretexComponent  implements OnInit  {
  moduleName:string = "בקרת תרופות"
  personalId:string
  reviewId:string
  medicationReviewList:MedicationReviewHeader[] = []
  title:string
  TabId = "main"
  constructor( 
      private router: Router,
      private caretxSharedlService: CaretxSharedModulesProviderService,
      private toastrService: ToasterService,
      private route: ActivatedRoute,
      public medicalFileService: MedicalFileProviderService,)
   { 
      super(medicalFileService)
  }

  ngOnInit(): void {
    if (this.route.parent != null)
    this.personalId = this.route.snapshot.params['id']
    this.medicalFile$ = this.medicalFileService.getMedicalFile(this.personalId).pipe(share())
    this.getMedicationReviewFromDB("")
    
  }


    // Get All Reviewes 
    getMedicationReviewFromDB(filter: string) {
      this.caretxSharedlService.getPatientMedicationReview(filter, this.personalId, "list").subscribe(
        data => {
          this.medicationReviewList = data

   
        },
  
        err => {
          this.medicationReviewList = []
        }
      )
    }
    



  AddNewMedicationReview(){
    let filter = '(status=\'A\')'
    this.caretxSharedlService.getPatientMedicationReview(filter , this.personalId,  "list").subscribe({
      next: data => {
        if (data.length > 0) {
          let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
          this.toastrService.Error("", errorMsg)
        }
        else {
          this.router.navigateByUrl(this.personalId + "/pmreviewxref/new")
        }
      },
      error: error => {

        Swal.fire({
          title: "הודעת מערכת",
          text:  error.message,
          icon: 'warning',
          confirmButtonText: 'סגור',
          allowOutsideClick: false,
          allowEscapeKey: false
        })



      }
    })
    
  }


  BackToList() {
    this.router.navigate(['medicationreviews']);
  }


  OpenReviewRecord(medicationReview) {
    this.router.navigateByUrl(this.personalId + "/pmreviewxref/" + medicationReview.ID)

  }
  SearchWithFilter($event){

  }

  deleteRow(i){

  }


  CheckIfDocumentIsDirty() {
    return false 
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

}
