import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { Drug } from 'src/app/models/drug';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';

@Component({
  selector: 'app-drugslist',
  templateUrl: './drugslist.component.html',
  styleUrls: ['./drugslist.component.css']
})
export class DrugslistComponent implements OnInit {

  drugs: Drug[] = []
  currentRowIndex: number
  drugCode:string =""
  shortName:string =""
  longName:string =""
  TabId = "main"
  private filterResult: { [key: string]: string } = {};

  constructor( private router: Router,
    private caretxSharedlService: CaretxSharedModulesProviderService, 
    public medicalFileService: MedicalFileProviderService,
    private _title: Title,
    private toastrService: ToasterService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this._title.setTitle('Caretex | אינדיקס תרופות');
   
  }

  addNewDrug(){
    this.router.navigate(['newmedicaldrug']);
  }


  getDrugIndexFromDB(filter:string) { 

    this.caretxSharedlService.getMedicalDrugs(filter).subscribe(
      data => this.drugs = data,
      err => {
        this.drugs = []
      }
    )
  }


  drtillToDrugRecord(drug){
    this.router.navigate(['drugslist/' + drug.id]);
  }




  search(){
    this.setDrugCodeFilter()
    this.setLongNameFilter()
    this.setShortNameFilter()
   let filter = this.buildFilter()
   this.getDrugIndexFromDB(filter) 
  }

  clear(){
    this.drugCode = ""
    this.shortName  = ""
    this.longName  = ""
    this.filterResult = {}
  }



  setDrugCodeFilter() {
    if (this.drugCode != "" ) {
      this.filterResult.drugCodeFilter = "drug_code='"+ this.drugCode  + "'";
    }else{
      this.filterResult.drugCodeFilter =""
    }
    return this;
  }

  setShortNameFilter() {
    if (this.shortName != "" ) {
      this.filterResult.shortNameFilter = "short_trade_name='"+ this.shortName  + "'";
    }else{
      this.filterResult.shortNameFilter =""
    }
    return this;
  }

  setLongNameFilter() {
    if (this.shortName!= "" ) {
      this.filterResult.longNameFilter = "long_trade_name='"+ this.longName  + "'";
    }else{
      this.filterResult.longNameFilter =""
    }
    return this;
  }

 buildFilter() {
    const filters = [
      this.filterResult.drugCodeFilter,
      this.filterResult.shortNameFilter,
      this.filterResult.longNameFilter,
    ].filter(Boolean);
    return filters?.length > 0 ? filters.join(` and `) : '';
  }

}
