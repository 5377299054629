import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseOmdan } from 'src/app/models/omdans';
import { AuthService } from 'src/app/services/auth.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CaretxNursingProviderService } from '../../../nursing-services/Nursing.data-provider.service';

@Component({
  selector: 'app-omdan5list',
  templateUrl: './omdan5list.component.html',
  styleUrls: ['./omdan5list.component.css']
})
export class Omdan5listComponent extends CaretexComponent implements OnInit {

  omdan5Omdanem:BaseOmdan[] =[]
  id: string

  moduleName:string="אפיוני סיכון לנפילה"

  constructor( 
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private authServive: AuthService,
    private caretxNursingProviderService:CaretxNursingProviderService,
    private toastrService: ToasterService,
    private router: Router,
    private route: ActivatedRoute) {super(medicalFileService) }


  ngOnInit(): void {
    this._title.setTitle('Caretx |  אומדני פה');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id) 
    this.getOmdanemFromDB()
  }

  getOmdanemFromDB() {
    let filter:string ="origin <> 'admission'" // Origin is not admission
    this.caretxNursingProviderService.getOmdanem(this.id,filter,"omdan5").subscribe(
      data => this.omdan5Omdanem = data,
      err => {
        console.log("Error", err)
        this.omdan5Omdanem = []
      }
    )
  }


  OnSDrillToOmdan($event,omdan2){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/omdan5/" + omdan2.ID)
  }


  
  AddNewOmdan5($event){
    let filter = '(omdan_status=\'A\'  and origin <> \'admission\')'
    this.caretxNursingProviderService.getOmdanem(this.id,filter,"omdan5").subscribe((data: BaseOmdan[]) => {
      if (data.length > 0){
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
      else{
        this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/omdan5/new")
      }
    }); 
  }

  deleteEvent(i){

  }

  handleDeleteAlerMsg($event){

  }

  generateReport($event,event){
    
  }
  RetrunToList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem")
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }
}
