import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService } from 'src/app/modal/modal.service';
import { Departments } from 'src/app/models/generalhousedsetups';

@Component({
  selector: 'app-house-departments',
  templateUrl: './house-departments.component.html',
  styleUrls: ['./house-departments.component.css']
})
export class HouseDepartmentsComponent implements OnInit {
  hideModal: boolean = true;
  selectedDepartments: Departments[] = []

  @Input() ListOfDepartments: Departments[]
  @Output() departmentEvent = new EventEmitter<Departments[]>();


  constructor(private modalService: ModalService) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.departmentEvent.emit(this.selectedDepartments)
  
    //
    
  
  }

  submitDepartment(department: Departments, $event) {
    this.selectedDepartments.push(department)
    this.departmentEvent.emit(this.selectedDepartments)

  }

  DepartmentSelection(e,department) {

    let isFound  = this.checkIfListContainsDepartment(department.ID)
    if (e.currentTarget.checked) {
        // Check if it is already included in the list
       if (!isFound) {
          this.selectedDepartments.push(department)
       }
    }
    else{
        if (isFound){
          let objIndex = this.selectedDepartments.findIndex((e: Departments) => e.ID == department.ID);
          this.selectedDepartments.splice(objIndex, 1);
        }
    }

  }

  checkIfListContainsDepartment(departmentId:string):boolean {
    const result = this.selectedDepartments.some(x => x.ID === departmentId)
    return result
  }



}
