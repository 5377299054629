import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NurseAdmission } from 'src/app/models/nurseadmission';
import { BrdgServiceResponse } from 'src/app/models/serverresponse';
import { AdmissionsService } from 'src/app/services/admissions.service';
import { NurseAdmissionSteps } from 'src/app/services/enums';

@Component({
  selector: 'app-nursesummary',
  templateUrl: './nursesummary.component.html',
  styleUrls: ['../../nurseadmission.component.css', './nursesummary.component.css']
})
export class NursesummaryComponent implements OnInit {

  serverResponse: BrdgServiceResponse
  NurseSummaryChangeMessage: string


  @Input() personalId: string
  @Input() currentNurseAdmission: NurseAdmission

  @Input() set tabChanged(tabSelected: string) {
    if ((tabSelected as NurseAdmissionSteps) == NurseAdmissionSteps.Summary)
      this.NurseSummaryChangeMessage = this.buildSummaryChangeControlMsg()
  }

  @Output() serverResponseEvent = new EventEmitter<BrdgServiceResponse>();
  @Output() currentNurseAdmissionchange = new EventEmitter<NurseAdmission>();


  constructor(private _ar: ActivatedRoute,
    private _admissionsService: AdmissionsService,
    private router: Router, private route: ActivatedRoute) {
    this.serverResponse = new BrdgServiceResponse()
  }

  ngOnInit(): void {
    this.NurseSummaryChangeMessage = this.buildSummaryChangeControlMsg()
    this.currentNurseAdmission = this._admissionsService.getSharedNurseAdmission()
  }


  buildSummaryChangeControlMsg() {
    this.currentNurseAdmission = this._admissionsService.getSharedNurseAdmission()
    if (this.currentNurseAdmission.ID == undefined ||
      this.currentNurseAdmission.NurseSummaryLastUpdated_at == undefined ||
      this.currentNurseAdmission.NurseSummaryLastUpdated_at.toString() == "0001-01-01T00:00:00Z") return ""
    let datepipe = new DatePipe('en-US')
    let updatedDate = datepipe.transform(this.currentNurseAdmission.NurseSummaryLastUpdated_at, 'dd/MM/yyyy');
    let updatedTime = datepipe.transform(this.currentNurseAdmission.NurseSummaryLastUpdated_at, 'HH:mm');

  //  return "עודכן עי " + this.currentNurseAdmission.NurseSummaryLastUpdatedBy.displayName + " בתאריך " + updatedDate + " בשעה " + updatedTime
  }


  saveNurseSummary() {
    this.serverResponse.resetResponse()
    this._admissionsService.checkNurseAdmissionChangedFields()
    this.currentNurseAdmission = this._admissionsService.getSharedNurseAdmission()
    if (this.currentNurseAdmission.ID == undefined) {
      this.saveFullAdmission()
    }
    else {
      this._admissionsService.updateNurseAdmission(this.currentNurseAdmission, "nursesummary").subscribe({
        next: data => {
          this.currentNurseAdmission = (data as NurseAdmission)
          this._admissionsService.updateSharedData(this.currentNurseAdmission)
          this._admissionsService.resetNurseAdmissionChangedFlags()
          this.NurseSummaryChangeMessage = this.buildSummaryChangeControlMsg()
          this.serverResponse.success()
          this.serverResponseEvent.emit(this.serverResponse)

        },
        error: error => {
          this.serverResponse.failure()
          this.serverResponseEvent.emit(this.serverResponse)
        }
      })

    }


  }

  saveFullAdmission() {

    this._admissionsService.createNewNurseAdmission(this.currentNurseAdmission).subscribe({
      next: data => {
        this.currentNurseAdmission = (data as NurseAdmission)
        this._admissionsService.updateSharedData(this.currentNurseAdmission)
        this._admissionsService.resetNurseAdmissionChangedFlags()
        this.NurseSummaryChangeMessage = this.buildSummaryChangeControlMsg()
        this.serverResponse.success()
        this.serverResponseEvent.emit(this.serverResponse)

      },
      error: error => {
        this.serverResponse.failure()
        this.serverResponseEvent.emit(this.serverResponse)
      }
    })
  }

  NurseSummarytextchnaged($event) {
    this.currentNurseAdmission.nurseSummaryModified = true
    this._admissionsService.updateSharedData(this.currentNurseAdmission)
  }

}
