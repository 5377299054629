import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-medicalplan',
  templateUrl: './medicalplan.component.html',
  styleUrls: ['../../medical-admission.component.css', './medicalplan.component.css']
})
export class MedicalplanComponent implements OnInit {

 
  @Input() MedicalPlanTxt: string
  @Input() IsClosed: boolean
  @Output() planSummaryChangeEvent = new EventEmitter<string>();
  MedicalPlanChangeMessage: string
  @Input() currentActiveStep:number

  zoomInModalData: any;

  constructor(
    private router: Router,
    private _ar: ActivatedRoute,) {

  }


  ngOnInit(): void {
  }


  PlanTextchanged($event){
    this.planSummaryChangeEvent.emit($event)
  }


  zoomInField(title, fieldNgModel, preFilledData) {
    this.zoomInModalData = {title, field: fieldNgModel, preFilled: (preFilledData || '')};
  }



  bindFieldData(responseData) {
    this.MedicalPlanTxt = responseData;
  }

}
