import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, CanDeactivateGuard } from '../shared/guard/auth.guard';
import { AnnualreportComponent } from './annualreport/annualreport.component';
import { AnnualreportslistComponent } from './annualreportslist/annualreportslist.component';
import { FamiliyvisitComponent } from './familiyvisits/familiyvisit/familiyvisit.component';
import { FamiliyvisitsComponent } from './familiyvisits/familiyvisits.component';
import { SocialRegistrationComponent } from './social-registration/social-registration.component';
import { SocialadmissionComponent } from './socialadmission/socialadmission.component';
import { SocialadmissionlistComponent } from './socialadmissionlist/socialadmissionlist.component';
import { SocialanchorComponent } from './socialanchor/socialanchor.component';
import { SocialformsComponent } from './socialforms/socialforms.component';
import { SocialobservationformComponent } from './socialobservationform/socialobservationform.component';
import { SocialreleaseComponent } from './socialrelease/socialrelease.component';
import { SocialreleaselistComponent } from './socialreleaselist/socialreleaselist.component';
import { SocialtrackslistComponent } from './socialtrackslist/socialtrackslist.component';

import { FamilyconversationComponent } from './familyconversations/familyconversation/familyconversation.component';
import { FamilyconversationsComponent } from './familyconversations/familyconversations.component';
import { PocketTrackingComponent } from './pocket-tracking/pocket-tracking.component';
import { PocketTracksListComponent } from './pocket-tracking/pocket-tracks-list/pocket-tracks-list.component';
import { SocialReportForHelpingOldmanComponent } from './social-report-for-helping-oldman/social-report-for-helping-oldman';
import { ExternalsocialadmissionComponent } from './socialadmission/externalsocialadmission/externalsocialadmission.component';


const routes: Routes = [


  { 
    path: 'caretexfile/:id/pocket_track', 
    component: SocialanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: PocketTracksListComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':codeid', 
        component: PocketTrackingComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':codeid/step/:stepid', 
        component: PocketTrackingComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
    ]
  },



  { 
    path: 'caretexfile/:id/socialadmission', 
    component: SocialanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: SocialadmissionlistComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':admissionid', 
        component: SocialadmissionComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':admissionid/:tabid', 
        component: SocialadmissionComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

      {
        path: ':admissionid/step/:tabid', 
        component: SocialadmissionComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

 
    ]
  },

  { 
    path: 'caretexfile/:id/externalsocialadmission', 
    component: SocialanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: SocialadmissionlistComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':admissionid', 
        component: ExternalsocialadmissionComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
    ]
  },
  { 
    path: 'caretexfile/:id/socialhelpreport', 
    component: SocialanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: SocialReportForHelpingOldmanComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':tabid', 
        component: SocialReportForHelpingOldmanComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'step/:tabid', 
        component: SocialReportForHelpingOldmanComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':helpreportid', 
        component: SocialReportForHelpingOldmanComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
 
    ]
  },




  { 
    path: 'caretexfile/:id/socialforms', 
    component: SocialanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: SocialformsComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
     
 
    ]
  },



  { 
    path: 'caretexfile/:id/familyvisits', 
    component: SocialanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: FamiliyvisitsComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':visitid', 
        component: FamiliyvisitComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':visitid/step/:stepid', 
        component: FamiliyvisitComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
 
    ]
  },


  { 
    path: 'caretexfile/:id/family_conversation', 
    component: SocialanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: FamilyconversationsComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':conversationid', 
        component: FamilyconversationComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':conversationid/step/:stepid', 
        component: FamilyconversationComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
 
    ]
  },

  

  { 
    path: 'caretexfile/:id/socialregistration', 
    component: SocialanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'register',
        pathMatch:'full',
      },
      {
        path: 'register', 
        component: SocialRegistrationComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'register/:tabid', 
        component: SocialRegistrationComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'step/:tabid', 
        component: SocialRegistrationComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      }
 
    ]
  },



  
  { 
    path: 'caretexfile/:id/socialtracks', 
    component: SocialanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: SocialtrackslistComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

      {
        path: ':trackid', 
        component: SocialobservationformComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':trackid/step/:stepid',
        component: SocialobservationformComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
    ]
  },

  { 
    path: 'caretexfile/:id/socialrelease', 
    component: SocialanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: SocialreleaselistComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':releaseid', 
        component: SocialreleaseComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':releaseid/step/:stepid',
        component: SocialreleaseComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

 
    ]
  },
  
  { 
    path: 'caretexfile/:id/socialperiodicalreport', 
    component: SocialanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: AnnualreportslistComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':reportid', 
        component: AnnualreportComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':reportid/:tabid', 
        component: AnnualreportComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]

      },

      {
        path: ':reportid/step/:tabid', 
        component: AnnualreportComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]

      },
    


 
    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CaretxSocialModuleRoutingModule { }
