import { Component, OnInit } from '@angular/core';
import { ConsciousnessState } from 'src/app/models/generalhousedsetups';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-consciousness-state',
  templateUrl: './consciousness-state.component.html',
  styleUrls: ['./consciousness-state.component.css']
})
export class ConsciousnessStateComponent extends CaretexComponent implements OnInit {

  TabId:string = "main"

  consciousnessStates:ConsciousnessState[]  = []
  currentRowIndex:number

  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {   super(medicalFileServive)}

  ngOnInit(): void {
    this.getConsciousnessStatesFromDB()
  }


  getConsciousnessStatesFromDB() {
    this.caretxSetupsProviderService.GetConsciousnessState().subscribe(
      data => {
        this.consciousnessStates = data
      },
      err => {
        console.log("Error", err)
        this.consciousnessStates = []

      }
    )
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  
  CheckIfDocumentIsDirty() {
    const eventTypesLisChanged = this.consciousnessStates.some(row => {
      return row.New || row.Modified
    })
    return  eventTypesLisChanged
  }


  BackToList(){
    this.router.navigate(['system_setups']);
  }

  addNewRecord(){
    var newConsciousnessState = new ConsciousnessState()
    newConsciousnessState.New = true
    newConsciousnessState.editMode = true
    newConsciousnessState.CreatedAt = new Date()
    newConsciousnessState.UpdatedAt = new Date()
    newConsciousnessState.RealmID = this.authServive.LoggedInTenantID
    this.consciousnessStates.unshift(newConsciousnessState)
  }

  deleteRow(i){
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.consciousnessStates[i].New){
          this.caretxSetupsProviderService.DeleteConsciousnessState( this.consciousnessStates[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.consciousnessStates.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
              this.getConsciousnessStatesFromDB()
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.consciousnessStates.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i){
    this.consciousnessStates[i].editMode = true
    this.consciousnessStates[i].Modified = true
  }
  cancelEditRow(i){
    this.consciousnessStates[i].editMode  = false;
    this.consciousnessStates[i].Modified = false
  }

  updateEvent($event){
    this.caretxSetupsProviderService.CreateUpdateConsciousnessState (this.consciousnessStates).subscribe({
     next: data => {

       this.toastrService.Sucesss("", this.toastrService.successMsg)
       this.getConsciousnessStatesFromDB()
     },
     error: error => {
       this.toastrService.Error("", this.toastrService.errorMsg)
     }
   })  
 }

}
