import { propertyMap } from './modelmapper'
import { User } from './user'



export class PhysicalExamination {
    @propertyMap('ID')
    public ID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('RealmID')
    public RealmID: string
    @propertyMap('GeneralExamination')
    public GeneralExamination: string
    @propertyMap('GeneralStatus')
    public GeneralStatus: string
    @propertyMap('HeadExamination')
    public HeadExamination: string
    @propertyMap('HeadStatus')
    public HeadStatus: string
    @propertyMap('EyesExamination')
    public EyesExamination: string
    @propertyMap('EyesStatus')
    public EyesStatus: string
    @propertyMap('EarsExamination')
    public EarsExamination: string
    @propertyMap('EarsStatus')
    public EarsStatus: string
    @propertyMap('MouthExamination')
    public MouthExamination: string
    @propertyMap('MouthStatus')
    public MouthStatus: string
    @propertyMap('NeckExamination')
    public NeckExamination: string
    @propertyMap('NeckStatus')
    public NeckStatus: string
    @propertyMap('LemphaExamination')
    public LemphaExamination: string
    @propertyMap('LemphaStatus')
    public LemphaStatus: string
    @propertyMap('ChestExamination')
    public ChestExamination: string
    @propertyMap('ChestStatus')
    public ChestStatus: string
    @propertyMap('HeartExamination')
    public HeartExamination: string
    @propertyMap('HeartStatus')
    public HeartStatus: string
    @propertyMap('BreastsExamination')
    public BreastsExamination: string
    @propertyMap('BreastsStatus')
    public BreastsStatus: string
    @propertyMap('StomechExamination')
    public StomechExamination: string
    @propertyMap('StomechStatus')
    public StomechStatus: string
    @propertyMap('RectalExamination')
    public RectalExamination: string
    @propertyMap('RectalStatus')
    public RectalStatus: string
    @propertyMap('OrologiaExamination')
    public OrologiaExamination: string
    @propertyMap('OrologiaStatus')
    public OrologiaStatus: string
    @propertyMap('LimbsExamination')
    public LimbsExamination: string
    @propertyMap('LimbsStatus')
    public LimbsStatus: string
    @propertyMap('NeuroExamination')
    public NeuroExamination: string
    @propertyMap('NeuroStatus')
    public NeuroStatus: string
    @propertyMap('UserId')
    public UserId: string
    public User: User
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('Closed_at')
    public Closed_at: Date
    @propertyMap('Status')
    public Status: string
    @propertyMap('AdmissionId')
    public AdmissionId: string
    


    constructor(data?: any) {

        if (data != null) {
            this.ID = data.ID   
            this.PersonelID = data.PersonelID
            this.Status = data.Status 
            this.RealmID = data.RealmID
            this.GeneralExamination = data.GeneralExamination
            this.GeneralStatus = data.GeneralStatus
            this.HeadExamination = data.HeadExamination
            this.HeadStatus = data.HeadStatus
            this.EyesExamination = data.EyesExamination
            this.EyesStatus = data.EyesStatus
            this.EarsExamination = data.EarsExamination
            this.EarsStatus = data.EarsStatus
            this.MouthExamination = data.MouthExamination
            this.MouthStatus = data.MouthStatus
            this.NeckExamination = data.NeckExamination
            this.NeckStatus = data.NeckStatus
            this.LemphaExamination = data.LemphaExamination
            this.LemphaStatus = data.LemphaStatus
            this.ChestExamination = data.ChestExamination
            this.ChestStatus = data.ChestStatus
            this.HeartExamination = data.HeartExamination
            this.HeartStatus = data.HeartStatus
            this.BreastsExamination = data.BreastsExamination
            this.BreastsStatus = data.BreastsStatus
            this.StomechExamination = data.StomechExamination
            this.StomechStatus = data.StomechStatus
            this.RectalExamination = data.RectalExamination
            this.RectalStatus = data.RectalStatus
            this.OrologiaExamination = data.OrologiaExamination
            this.OrologiaStatus = data.OrologiaStatus
            this.LimbsExamination = data.LimbsExamination
            this.LimbsStatus = data.LimbsStatus
            this.NeuroExamination = data.NeuroExamination
            this.NeuroStatus = data.NeuroStatus
            this.CreatedAt = data.CreatedAt
            this.UpdatedAt = data.UpdatedAt
            this.Closed_at = data.Closed_at
            this.UserId = data.UserId
            this.User = data.User
            this.AdmissionId = data.AdmissionId
     
        }
        else {
            this.PersonelID = undefined
            this.Status = "A"
            this.GeneralExamination = ""
            this.HeadExamination = ""
            this.EyesExamination = ""
            this.EarsExamination = ""
            this.MouthExamination = ""
            this.NeckExamination = ""
            this.LemphaExamination = ""
            this.ChestExamination = ""
            this.HeartExamination = ""
            this.BreastsExamination = ""
            this.StomechExamination = ""
            this.RectalExamination = ""
            this.OrologiaExamination = ""
            this.LimbsExamination = ""
            this.NeuroExamination = ""
            //this.AdmissionId = ""
            this.UpdatedAt = new Date()
            this.CreatedAt = new Date()
            this.Closed_at = null
        }
    }



}