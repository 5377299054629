import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ModelMapper } from '../../../src/app/models/modelmapper';
import { environment } from '../../../src/environments/environment';
import { Attachment } from '../models/attachments';
import { NextFollowUps } from '../models/customer';
import { Drug, MedicalDiagnoses } from '../models/drug';
import { CaretexMessage } from '../models/notificationMap';
import { MedicalObservationFile } from '../models/Observations';
import { TeekNote } from '../models/Teeknote';
import { User } from '../models/user';
import { PaitentFamily } from '../models/socialregistration';
import { MedicationReviewHeader } from '../models/MedicationsReview';
import { MedicalPrescriptionHeader } from '../models/medical-prescriptions';
import { Hospitalization } from '../models/hospitalization';
import { DrugInstruction } from '../models/drugInstruction';
import { DrugSensitivity, PersonelSensitivity } from '../models/drugsenstivity';



@Injectable()
export class CaretxSharedModulesProviderService {

  constructor(private httpClient: HttpClient) {
  }

  getUsers(filter:string ): Observable<User[]> {
      let filterQuery = "";
      if (filter != null)
          filterQuery = '?filter='+filter
    
      return this.httpClient.get(environment.gateway + '/users' + filterQuery).pipe(
        map((response: any) => {
          return response.map(item => {
           return new ModelMapper(User).map(item)
         })
       }), catchError(this.errorHandler));
  }

  uploadImage(attachment: Attachment) {
      return this.httpClient.post(environment.gateway + '/attachments/' + attachment.PersonelID + '/uploadimage', attachment)
  } 

  uploadFile(attachment: Attachment) {

    const formData: FormData = new FormData();
    return this.httpClient.post(environment.gateway + "/attachments/" + attachment.PersonelID + "/upload", attachment)
} 

  getAttachments(id:string , filter:string): Observable<Attachment[]> {
  let filterQuery = "";
  if (filter != null)
      filterQuery = '?filter='+filter
  return this.httpClient.get(environment.gateway + '/attachments/'+id + filterQuery).pipe(
    map((response: any) => {
      return response.map(item => {
       return new ModelMapper(Attachment).map(item)
     })
   }), catchError(this.errorHandler));
}

downloadFile(personalId:string , file:Attachment){
  return this.httpClient.post(environment.gateway + '/attachments/' + personalId + '/download',file,{
    responseType: "arraybuffer" //tried with 'blob'
  })
}

downloadCaretexFile(fileName:string){
   let file = new Attachment()
   file.FileName = fileName
  return this.httpClient.post(environment.gateway + '/caretexfiles/download' , file ,{
    responseType: "arraybuffer" 
  })
}

downloadImage(personalId: string, fileid:string): Observable<any> {
      return this.httpClient.get(environment.gateway + '/attachments/' + personalId + '/download/'+fileid,{
        responseType: "arraybuffer" 
      })
  }

createNewMedicalDrug(newMedicalDrug: Drug) {
    return this.httpClient.post(environment.gateway + '/medicaldrug',  newMedicalDrug)
  }

getMedicalDrugs(filter:string ) {
    let filterQuery = "";
    if (filter != null)
        filterQuery = '?filter='+filter
  
    return this.httpClient.get(environment.gateway + '/medicaldrug' + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
         return new ModelMapper(Drug).map(item)
       })
     }), catchError(this.errorHandler));
  }

updateMedicalDrug(drug: Drug) {
    return this.httpClient.put(environment.gateway + '/medicaldrug/'+drug.id , drug)
  } 

createNewMedicalObservationFile(newObservationFile: MedicalObservationFile) {
    return this.httpClient.post(environment.gateway + '/newobservationfile/'+newObservationFile.PersonelID , newObservationFile)
  }

deleteNewMedicalObservationFile(observationFile: MedicalObservationFile) {
    return this.httpClient.delete(environment.gateway + '/clients/' + observationFile.PersonelID + '/deleteobservationfile/' + observationFile.Id)
  }

getMessages(toUserId:string ): Observable<CaretexMessage[]> {
    return this.httpClient.get(environment.gateway + '/messages/' + toUserId).pipe(
      map((response: any) => {
        return response.map(item => {
         return new ModelMapper(CaretexMessage).map(item)
       })
     }), catchError(this.errorHandler));
  }

createNewMessage(message: CaretexMessage) {
    return this.httpClient.post(environment.gateway + '/messages', message)
  }

getTeekNotes(id: string , mode:string) {
    const headerDict = {
      'Mode': mode,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.httpClient.get(environment.gateway + '/notes/' + id ,requestOptions);
  }

deleteTeekNote(note: TeekNote, mode:string) {
    const headerDict = {
      'Mode': mode,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.httpClient.delete(environment.gateway + '/notes/' + note.NoteId , requestOptions);
  }

addTeekNotes(note: TeekNote) {
    return this.httpClient.post(environment.gateway + '/notes', note);
  }

uploadProfilePic(fileToUpload) {
    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.httpClient.post(environment.gateway + "/profilepic", formData)

  }

createNewDiagnoses(record: MedicalDiagnoses) {
    return this.httpClient.post(environment.gateway + '/diagnoses', record)
  }

  getNextFollowUps(filter:string): Observable<NextFollowUps[]> {
    let filterQuery = "";
    if (filter != null)
        filterQuery = '?filter='+filter
    return this.httpClient.get(environment.gateway + '/nextfollowups' + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
         return new ModelMapper(NextFollowUps).map(item)
       })
     }), catchError(this.errorHandler));
  }

createFamilyMemebr(record: PaitentFamily) {
    return this.httpClient.post(environment.gateway + '/familymember/'+record.PersonelID, record)
  }

updateFamilyMemebr(record: PaitentFamily) {
    return this.httpClient.put(environment.gateway + '/familymember/'+record.PersonelID, record)
  }

getFamilyMemebrs(id:string ,filter:string): Observable<PaitentFamily[]> {
    let filterQuery = "";
    if (filter != null)
        filterQuery = '?filter='+filter

    return this.httpClient.get(environment.gateway + '/familymember/' + id +filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
         return new ModelMapper(PaitentFamily).map(item)
       })
     }), catchError(this.errorHandler));
  }

createPatientMedicationReview(personelId: string,newMedicationReview: MedicationReviewHeader) {
    return this.httpClient.post(environment.gateway + '/pmreview/'+personelId,  newMedicationReview)
  }

getPatientMedicationReview(filter: string, personelId: string, mode?: string): Observable<MedicationReviewHeader[]> {

    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    /*if (limit != null)
      filterQuery = filterQuery + '&limit=' + limit.toString()*/

      const headerDict = {
        'Mode': mode,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };

    return this.httpClient.get(environment.gateway + '/pmreview/' + personelId + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(MedicationReviewHeader).map(item)
        })

      }), catchError(this.errorHandler));
  }

updatePatientMedicationReview(personelId: string,newMedicationReview: MedicationReviewHeader) {
    return this.httpClient.put(environment.gateway + '/pmreview/'+personelId,  newMedicationReview)
  }

createMedicalPrescription(prescriptionHeader: MedicalPrescriptionHeader) {
    return this.httpClient.post(environment.gateway + '/medicalprescription',  prescriptionHeader)
  }

updateMedicalPrescription(prescriptionHeader: MedicalPrescriptionHeader) {
    return this.httpClient.put(environment.gateway + '/medicalprescription',  prescriptionHeader)
  }

getMedicalPrescription(recordId: string): Observable<MedicalPrescriptionHeader[]> {
    return this.httpClient.get(environment.gateway + '/medicalprescription/' + recordId).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(MedicalPrescriptionHeader).map(item)
        })

      }), catchError(this.errorHandler));
  }

getTotalCardixTasks(startDate: Date, endDate: Date): Observable<number> {
    const headerDict = {
      'startdate': this.dateAsYYYYMMDDHHNNSS(startDate),
      'enddate': this.dateAsYYYYMMDDHHNNSS(endDate)
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.httpClient.get(environment.gateway + '/countcardixtasks', requestOptions).pipe(
      map((response: any) => {
        return response
      }), catchError(this.errorHandler));
  }

getTotalUnapprovedDrugInstructions(): Observable<number> {
    return this.httpClient.get(environment.gateway + '/drugsinstructions_sd_count').pipe(
      map((response: any) => {
        return response
      }), catchError(this.errorHandler));
  }

createHospitalizationOrder(hospitalization: Hospitalization) {
    return this.httpClient.post(environment.gateway + '/hospitalization',  hospitalization)
  }

updateHospitalizationOrder(hospitalization: Hospitalization) {
    return this.httpClient.put(environment.gateway + '/hospitalization/'+hospitalization.ID,  hospitalization)
  }

getHospitalizationOrders(recordId: string ,  mode :string): Observable<Hospitalization[]> {
    const headerDict = {
      'Mode': mode,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };


    if (recordId == "" ) {
      return this.httpClient.get(environment.gateway + '/hospitalization',requestOptions).pipe(
        map((response: any) => {
          return response.map(item => {
            return new ModelMapper(Hospitalization).map(item)
          })
  
        }), catchError(this.errorHandler));
    }else {
      return this.httpClient.get(environment.gateway + '/hospitalization/' + recordId,requestOptions).pipe(
        map((response: any) => {
          return response.map(item => {
            return new ModelMapper(Hospitalization).map(item)
          })
  
        }), catchError(this.errorHandler));
    }

  }



  getMedicationPerUser(startDate: Date, endDate: Date , patients:any[] , drugs:any[],medicineForm:any[],hmo:any[] ): Observable<DrugInstruction[]> {

    let filterQuery = "";
    let startDateTxt  = this.dateAsYYYYMMDDHHNNSS(startDate)
    let endDateTxt  = this.dateAsYYYYMMDDHHNNSS(endDate)
    let ids= []
    let ids2= []
    let ids3= []
    let ids4= []
     
    patients.forEach(element => {
      ids.push("'" + element.PersonelID + "'")
    });

    drugs.forEach(element => {
      ids2.push("'" + element.id + "'")
    });

    medicineForm.forEach(element => {
      ids3.push("'" + element.id + "'")
    });

    hmo.forEach(element => {
      ids4.push("'" + element.id + "'")
    });

    filterQuery = '?ids[]=' + ids + "&fromdate="+startDateTxt +"&todate="+endDateTxt + "&ids2[]="+ids2 + "&ids3[]="+ids3 + "&ids4[]="+ids4 

    return this.httpClient.get(environment.gateway + '/patientsmedication' + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DrugInstruction).map(item)
        })
      }), catchError(this.errorHandler));
  }





  
  getSensitivitiesPerPatient( patients:any[] , departments:any[]): Observable<DrugSensitivity[]> {

    let filterQuery = "";
    let ids= []
    let ids2= []
     
    patients.forEach(element => {
      ids.push("'" + element.PersonelID + "'")
    });
    departments.forEach(element => {
      ids2.push("'" + element.ID + "'")
    });

    filterQuery = '?ids[]=' + ids +  "&ids2[]="+ids2 

    return this.httpClient.get(environment.gateway + '/patientssensitivities' + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DrugSensitivity).map(item)
        })
      }), catchError(this.errorHandler));
  }


/***************************************************************************************** */
dateAsYYYYMMDDHHNNSS(date): string {
  return date.getFullYear()
    + '-' + this.leftpad(date.getMonth() + 1, 2)
    + '-' + this.leftpad(date.getDate(), 2)
    + ' ' + this.leftpad(date.getHours(), 2)
    + ':' + this.leftpad(date.getMinutes(), 2)
    + ':' + this.leftpad(date.getSeconds(), 2);
}

leftpad(val, resultLength = 2, leftpadChar = '0'): string {
  return (String(leftpadChar).repeat(resultLength)
    + String(val)).slice(String(val).length);
}

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    // return of([]);
  }

}

