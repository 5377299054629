import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import jsPDF from 'jspdf';
import { RowInput } from 'jspdf-autotable';
import { CaretxSetupsProviderService } from 'src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { CaretxSharedModulesProviderService } from 'src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ReportCardixTask } from 'src/app/models/Cardix';
import { MedicalFile } from 'src/app/models/customer';
import { Drug } from 'src/app/models/drug';
import { DrugInstruction } from 'src/app/models/drugInstruction';
import { DrugSensitivity, PersonelSensitivity } from 'src/app/models/drugsenstivity';
import { Departments } from 'src/app/models/generalhousedsetups';
import { AuthService } from 'src/app/services/auth.service';
import { BridgeEnums, OptionObject } from 'src/app/services/enums';
import { ReportingService } from 'src/app/services/reporting.service';

type Items1 = {
  DepartmentName: string;
  ID: string
};


@Component({
  selector: 'app-caretex-report1',
  templateUrl: './caretex-report1.component.html',
  styleUrls: ['./caretex-report1.component.scss']
})
export class CaretexReport1Component implements OnInit {
  TabId: string = "main"
  pageType: string = "Reports"
  departments: Departments[] = []
  selectedItems1: Items1[] = [];
  drugs: Drug[] = []
  selectedHMO: string
  patientFilter: string = ""
  showDepMandatoryLbl: boolean = false
  filteredData: MedicalFile[] = [];
  renderedData: MedicalFile[] = [];
  allFiles: MedicalFile[] = [];

  startDate: Date
  endDate: Date
  reportRecords: DrugInstruction[] = []
  reportSensitivityRecords: DrugSensitivity[] = []

  StartTime: Date = new Date()
  EndTime: Date = new Date()
  reportsFilter: boolean
  selectedDosageForm: string
  DrugDosageForms: OptionObject[]
  HMOs: OptionObject[]
  selectedPatient: boolean
  dropdownSettings1 = {};
  selectedItems2 = [];
  dropdownSettings2 = {};
  selectedItems3 = [];
  dropdownSettings3 = {};
  selectedItems4 = [];
  dropdownSettings4 = {};
  selectedItems5 = [];
  dropdownSettings5 = {};


  disableparentwindow: boolean = false
  totalNumOfRecords: number

  reportName: string = ""

  constructor(private caretxSetupService: CaretxSetupsProviderService,
    private authService: AuthService,
    private reportingService: ReportingService,
    private router: Router,
    private route: ActivatedRoute,
    public medicalFileService: MedicalFileProviderService,
    private bridgeEnum: BridgeEnums,
    private caretxSharedlService: CaretxSharedModulesProviderService,
  ) {

    this.caretxSetupService.GetUserAllowedDepartments(this.authService.LoggedInUser.UID).subscribe(data => {
      this.departments = data
      this.selectedItems1 = JSON.parse(localStorage.getItem('user_selected_departments') || '{}');
      if (this.selectedItems1.length == 0) {
        if (this.departments.length > 0) {
          this.departments.forEach(element => {
            this.selectedItems1.push({ "DepartmentName": element.DepartmentName, "ID": element.ID })
          });
        } else {
        }
      }
    })


    this.caretxSharedlService.getMedicalDrugs("").subscribe(
      data => this.drugs = data,
      err => {
        this.drugs = []
      }
    )

    this.DrugDosageForms = bridgeEnum.DrugDosageForm
    this.HMOs = bridgeEnum.Hmos

  }

  OpenSideNavReportsFilter() {
    this.reportsFilter = true
  }

  OpenSideNav() {
    this.selectedPatient = true
  }

  ngOnInit(): void {
    try {
      this.reportName = this.route.snapshot.url[1].path
    } catch (e) {
      this.reportName = "unknown"
    }


    this.loadData2("")
    this.startDate = new Date()
    this.startDate.setHours(0, 0, 0, 0)

    this.endDate = new Date()
    this.endDate.setHours(24, 0, 0, 0)

    this.doInitialization()
    this.setReportControlFlags(this.reportName)
  }



  doInitialization() {
    // Get LabCategories :
    this.dropdownSettings1 = {
      singleSelection: false,
      idField: "ID",
      textField: "DepartmentName",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.dropdownSettings2 = {
      singleSelection: false,
      idField: "id",
      textField: "ShortTradeName",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    }


    this.dropdownSettings4 = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    }

    this.dropdownSettings5 = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    }




    this.dropdownMedicalFilesSettings = {
      singleSelection: false,
      idField: "PersonelID",
      textField: "FullName",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 5,
      allowSearchFilter: true
    }



  }
  loadData2(filter: string) {
    this.medicalFileService.getMedicalFiles(filter);
    this.medicalFileService.dataChange.subscribe((data) => {
      this.filteredData = this.allFiles = data
      this.filteredData.forEach(element => {
        element.FullName = element.FirstName + " " + element.LastName
      });
      this.totalNumOfRecords = this.allFiles.length
    })
  }
  onDepartmentSelect($event) {
    this.filteredData = []
    this.showDepMandatoryLbl = false
    this.prepareFilterToSearchMedicalFiles()
  }
  onDeSelectAllPatient($event) {
    this.patientFilter = ""
  }
  onSelectingAllDepartments(items: any) {
    this.selectedItems1 = items
    this.showDepMandatoryLbl = false
    this.filteredData = this.allFiles
    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));
  }

  prepareFilterToSearchMedicalFiles() {
    localStorage.setItem('user_selected_departments', JSON.stringify(this.selectedItems1));

    if (this.selectedItems1.length > 0) {
      this.allFiles.forEach(file => {
        const found = this.selectedItems1.some(x => x.ID === file.DepartmentId)
        if (found) this.filteredData.push(file)

      });
    }

  }



  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  selectedFile: MedicalFile
  pdfContent: any


  closePdfDocument() {
    this.showGeneratedPdf = false
  }


  convertDayToHebrewName(dayNum: number) {

    switch (dayNum) {
      case 1: return "ראשון"
        break;
      case 2: return "שני"
        break;
      case 3: return "שלישי"
        break
      case 4: return "רביעי"
        break
      case 5: return "חמישי"
        break
      case 6: return "שישי"
        break
      case 7: return "שבת"
        break
    }
  }

  formatDateToMMDDYYY(dateToFormat: Date) {

    let tmpDate = new Date(dateToFormat)
    var dd = tmpDate.getDate();
    var mm = tmpDate.getMonth() + 1;
    var yyyy = tmpDate.getFullYear();
    return (mm < 10 ? '0' + mm.toString() : mm.toString()) + '/' + (dd < 10 ? '0' + dd.toString() : dd.toString()) + '/' + yyyy;
  }

  formatDateToHHmm(dateToFormat: Date) {

    let tmpDate = new Date(dateToFormat)
    var hh = tmpDate.getHours();
    var mm = tmpDate.getMinutes();
    return (hh < 10 ? '0' + hh.toString() : hh.toString()) + ':' + (mm < 10 ? '0' + mm.toString() : mm.toString());
  }

  updateTimeField(field, $event) {
    var newarr = $event.split(":");
    let x1: number
    let x2: number

    switch (field) {
      case "StartTime":
        x1 = new Date(this.startDate).setHours(newarr[0])
        this.StartTime = new Date(x1)
        x2 = new Date(this.StartTime).setMinutes(newarr[1])
        this.StartTime = new Date(x2)
        break
      case "EndTime":
        x1 = new Date(this.endDate).setHours(newarr[0])
        this.EndTime = new Date(x1)
        x2 = new Date(this.EndTime).setMinutes(newarr[1])
        this.EndTime = new Date(x2)
        break
      default:
        break
    }
  }


  onSelectingAllDrugs($event) {
  }

  onDeSelectAllDrugs($event) {
  }

  onDrugSelect($event) {
  }

  onDrugDeSelect($event) {

  }

  OpenOperationPage() {
    this.router.navigate(['reports']);
  }


  onItemDeSelect($event) {
    this.filteredData = []
    if (this.selectedItems1.length == 0) {
      this.filteredData = []
      this.showDepMandatoryLbl = true
    }
    this.prepareFilterToSearchMedicalFiles()
  }

  onDeSelectAll(items: any) {
    this.selectedItems1 = []
    this.filteredData = []
    this.showDepMandatoryLbl = true
    this.prepareFilterToSearchMedicalFiles()
  }



  onDosageFormsSelect($event) {

  }

  onDosageFormDeSelect($event) {

  }

  onSelectingAllDosageForms($event) {

  }

  onDeSelectAllDosageForms($event) {

  }

  onHMOSelect($event) {

  }
  onHMODeSelect($event) {

  }
  onSelectingAllHMos($event) {

  }

  onDeSelectAllHMOs($event) {

  }


  filterPaitents($event) {
    let filter: string = $event.currentTarget.value
    this.filteredData = this.allFiles
    this.allFiles = this.medicalFileService.data
    this.filteredData = this.medicalFileService.data.slice().filter((medicalFile: MedicalFile) => {
      const searchStr = (medicalFile.PersonelID + medicalFile.FirstName + medicalFile.LastName).toLowerCase();
      return searchStr.indexOf(filter.toLowerCase()) !== -1;
    });
  }


  /********************************** Medical Files Filter ************************* */
  selectedMedicalFilesItems = [];
  dropdownMedicalFilesSettings = {};

  onDeSelectAllMedicalFile($event) {

  }
  onSelectingAllMedicalFile($event) {

  }
  onMedicalFileSelect($event) {

  }

  onMedicalFileDeSelect($event) {

  }
  /**************************************** Reports ******************************** */


  showPatients: boolean = false
  showDepartments: boolean = false
  showHMOs: boolean = false
  showToDate: boolean = false
  showDrugs: boolean = false
  showDrugsDosage: boolean = false
  setReportControlFlags(reportName: string) {

    switch (reportName) {
      case "Resident_medicines":
        this.showHMOs = true
        this.showDrugs = true
        this.showPatients = false
        this.showDepartments = true
        this.showDrugsDosage = true
        this.showToDate = true

        break
      case "pain_report":
        this.showHMOs = false
        this.showDrugs = false
        this.showPatients = true
        this.showDepartments = true
        this.showDrugsDosage = false
        this.showToDate = false
        break

    }
  }

  residentMedicinesFilter() {
    this.StartTime = new Date('1900-01-01Z00:00:00:000');
    this.EndTime.setDate((new Date(this.endDate)).getDate())
    this.EndTime.setFullYear((new Date(this.endDate)).getFullYear())
    this.EndTime.setMonth((new Date(this.endDate)).getMonth())


    if (this.selectedDosageForm == undefined) {
      this.selectedDosageForm = ""
    }

    if (this.selectedHMO == undefined) {
      this.selectedHMO = ""
    }

    this.caretxSharedlService.getMedicationPerUser(this.StartTime, this.EndTime, this.selectedItems3, this.selectedItems2, this.selectedItems4, this.selectedItems5).subscribe(
      data => {
        if (data.length) {
          this.reportRecords = data

        } else {
          this.reportRecords = [];
        }
      },
      err => {
        this.reportRecords = []
      }
    )
  }

  SensitivityPerPatientFilter() {
   
    this.caretxSharedlService.getSensitivitiesPerPatient(this.selectedMedicalFilesItems, this.selectedItems1).subscribe(
      data => {
        if (data.length) {
          this.reportSensitivityRecords = data

        } else {
          this.reportSensitivityRecords = [];
        }
      },
      err => {
        this.reportSensitivityRecords = []
      }
    )
}

  AddReportFilter() {

    // Check Mandatory Fields
    if (this.selectedItems1.length == 0) {
      this.showDepMandatoryLbl = true
    } else {
      this.reportsFilter = false


      if (this.reportName == "Resident_medicines") {
        this.residentMedicinesFilter()

      } else if (this.reportName == "pain_report"){
        this.SensitivityPerPatientFilter()
      }
    }





  }

/**************************************Print Report *********************************** */

printReport() {

  switch (this.reportName) {
    case "Resident_medicines":
      this.PrintResidentMedicinesReport()

      break
    case "pain_report":
      this.PrintSensitivityPerPatientReport()
      break

  }

}


PrintResidentMedicinesReport() {

  let StartTime = this.reportingService.formatDateToMMDDYYY(this.startDate) + " " + this.reportingService.formatDateToHHmm(this.startDate)
  let EndTime = this.reportingService.formatDateToMMDDYYY(this.endDate) + " " + this.reportingService.formatDateToHHmm(this.endDate)

  let departmentsTxt = this.selectedItems1.map(x => x.DepartmentName).join(', ');

  let reportTitle = "דו\"\ח תרופות"
  this.showGeneratedPdf = true
  var doc = new jsPDF()
  let lastYPosition: number
  let xPosTitle: number = 118
  lastYPosition = this.reportingService.SetHeaderForCardixGeneric(doc, reportTitle, xPosTitle, departmentsTxt, StartTime, EndTime)
  this.reportingService.setFooter3(doc, this.authService.LoggedInUser, new Date())


  var label = ""
  //lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,10, label, "אבחנות רפואיות:", new User() , this.authService.LoggedInUser , new Date() , new Date(), this.medicalFile1,reportTitle,xPosTitle)

  if (this.reportRecords.length <= 0) {
    lastYPosition = this.reportingService.AddBulletLinesSection2(doc, lastYPosition, "לא נמצאו תוצאות", this.authService.LoggedInUser, new Date(), reportTitle, xPosTitle, departmentsTxt, StartTime, EndTime)
  } else {
    var colHeader1: RowInput[] = [
      [
        this.reportingService.reverseString("תרופה"),
        this.reportingService.reverseString("ת.ז"),
        this.reportingService.reverseString("מטופל"),
      ]]
    var colVals1: RowInput[] = []
    this.reportRecords.forEach(record => {

      let tmpValRow: RowInput =
        [

          this.reportingService.reverseString(record.Drug.ShortTradeName),
          this.reportingService.reverseString(record.PersonDoc.PersonelID),
          this.reportingService.reverseString(record.PersonDoc.FirstName + " " + record.PersonDoc.LastName),
        ]


      colVals1.push(tmpValRow)
    });
    lastYPosition = this.reportingService.AddGenericTableToPDF(doc, lastYPosition, this.authService.LoggedInUser, colHeader1, colVals1, reportTitle, xPosTitle, departmentsTxt, StartTime, EndTime, new Date())

  }

  let file: Blob = doc.output('blob')
  this.pdfContent = URL.createObjectURL(file);
  this.pdfViewer.pdfSrc = file
  this.pdfViewer.refresh();
}


PrintSensitivityPerPatientReport() {
  let StartTime = this.reportingService.formatDateToMMDDYYY(this.startDate) + " " + this.reportingService.formatDateToHHmm(this.startDate)
  let EndTime = this.reportingService.formatDateToMMDDYYY(this.endDate) + " " + this.reportingService.formatDateToHHmm(this.endDate)

  let departmentsTxt = this.selectedItems1.map(x => x.DepartmentName).join(', ');

  let reportTitle = "דו\"\ח רגישויות"
  this.showGeneratedPdf = true
  var doc = new jsPDF()
  let lastYPosition: number
  let xPosTitle: number = 118
  lastYPosition = this.reportingService.SetHeaderForCardixGeneric(doc, reportTitle, xPosTitle, departmentsTxt, StartTime, EndTime)
  this.reportingService.setFooter3(doc, this.authService.LoggedInUser, new Date())


  if (this.reportSensitivityRecords.length <= 0) {
    lastYPosition = this.reportingService.AddBulletLinesSection2(doc, lastYPosition, "לא נמצאו תוצאות", this.authService.LoggedInUser, new Date(), reportTitle, xPosTitle, departmentsTxt, StartTime, EndTime)
  } else {
    var colHeader1: RowInput[] = [
      [
        this.reportingService.reverseString("רגישות ל"),
        this.reportingService.reverseString("ת.ז"),
        this.reportingService.reverseString("מטופל"),
      ]]
    var colVals1: RowInput[] = []
    this.reportSensitivityRecords.forEach(record => {

      let tmpValRow: RowInput =
        [

          this.reportingService.reverseString(record.Drug.ShortTradeName),
          this.reportingService.reverseString(record.PersonDoc.PersonelID),
          this.reportingService.reverseString(record.PersonDoc.FirstName + " " + record.PersonDoc.LastName),
        ]


      colVals1.push(tmpValRow)
    });
    lastYPosition = this.reportingService.AddGenericTableToPDF(doc, lastYPosition, this.authService.LoggedInUser, colHeader1, colVals1, reportTitle, xPosTitle, departmentsTxt, StartTime, EndTime, new Date())

  }

  let file: Blob = doc.output('blob')
  this.pdfContent = URL.createObjectURL(file);
  this.pdfViewer.pdfSrc = file
  this.pdfViewer.refresh();
}


}
