import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { ModalService } from '../../../../../src/app/modal/modal.service';
import { TabHistory } from '../../../../../src/app/models/genericTypes';
import { PhysicalExamination } from '../../../../../src/app/models/physicalexamination';
import { BridgeBuiltInSentenses } from '../../../../../src/app/services/builtinsentenses';
import { BridgeEnums, MedicalAdmissionSteps, OptionObject } from '../../../../../src/app/services/enums';
import { CaretxMedicalProviderService } from '../../../caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { AccessComponenetType } from '../../../services/enums'; 
@Component({
  selector: 'app-physicalexamination',
  templateUrl: './physicalexamination.component.html',
  styleUrls: ['./physicalexamination.component.css']
})
export class PhysicalexaminationComponent implements OnInit, AfterViewInit {

  @ViewChild("generalExaminationtxt") generalExaminationtxt: ElementRef;
  @ViewChild("headExaminationtxt") headExaminationtxt: ElementRef;
  @ViewChild("eyesExaminationtxt") eyesExaminationtxt: ElementRef;
  @ViewChild("earsExaminationtxt") earsExaminationtxt: ElementRef;
  @ViewChild("mouthExaminationtxt") mouthExaminationtxt: ElementRef;
  @ViewChild("neckExaminationtxt") neckExaminationtxt: ElementRef;
  @ViewChild("lemphaExaminationtxt") lemphaExaminationtxt: ElementRef;
  @ViewChild("chestExaminationtxt") chestExaminationtxt: ElementRef;
  @ViewChild("heartExaminationtxt") heartExaminationtxt: ElementRef;
  @ViewChild("breastsExaminationtxt") breastsExaminationtxt: ElementRef;
  @ViewChild("stomechExaminationtxt") stomechExaminationtxt: ElementRef;
  @ViewChild("rectalExaminationtxt") rectalExaminationtxt: ElementRef;
  @ViewChild("orologiaExaminationtxt") orologiaExaminationtxt: ElementRef;
  @ViewChild("limbsExaminationtxt") limbsExaminationtxt: ElementRef;
  @ViewChild("neuroExaminationtxt") neuroExaminationtxt: ElementRef;

  @ViewChild('physexamform1') physexamform1: NgForm;


  GeneralExamination: string[]
  HeadExamination: string[]
  EyesExamination: string[]
  EarsExamination: string[]
  MouthExamination: string[]
  NeckExamination: string[]
  LemphaExamination: string[]
  ChestExamination: string[]
  HeartExamination: string[]
  BreastsExamination: string[]
  StomechExamination: string[]
  RectalExamination: string[]
  OrologiaExamination: string[]
  LimbsExamination: string[]
  NeuroExamination: string[]

  ExaminationStatus: OptionObject[]

  @Input() physicalExamination: PhysicalExamination
  @Input() events: Observable<TabHistory>;
  @Output() updateNextTab = new EventEmitter<string>();
  @Output() PhysicalExaminationHandler = new EventEmitter<PhysicalExamination>();
  @Output() isDirty = new EventEmitter<boolean>();
  private eventsSubscription: Subscription;
  private nextTab: string
  @Input() AccessedComponent: AccessComponenetType
  @Input() IsLocked: boolean = false
  ComponentInitialized: boolean = false
  id: string;
  zoomInModalData: any;
  @Input() currentActiveStep: number
  @Input() stepOrder: number
  @Input() IsDisabled: boolean
  dismissModal:boolean = false
  constructor(
    private bridgeEnum: BridgeEnums,
    private modalService: ModalService,
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,
    private builtInSentenses: BridgeBuiltInSentenses,) {
    bridgeEnum = new BridgeEnums()
    this.ExaminationStatus = bridgeEnum.ExaminationStatus

    this.physicalExamination = new PhysicalExamination()
    builtInSentenses = new BridgeBuiltInSentenses()
    this.GeneralExamination = builtInSentenses.GeneralExamination
    this.HeadExamination = builtInSentenses.HeadExamination
    this.EyesExamination = builtInSentenses.EyesExamination
    this.EarsExamination = builtInSentenses.EarsExamination
    this.MouthExamination = builtInSentenses.MouthExamination
    this.NeckExamination = builtInSentenses.NeckExamination
    this.LemphaExamination = builtInSentenses.LemphaExamination
    this.ChestExamination = builtInSentenses.ChestExamination
    this.HeartExamination = builtInSentenses.HeartExamination
    this.BreastsExamination = builtInSentenses.BreastsExamination
    this.StomechExamination = builtInSentenses.StomechExamination
    this.RectalExamination = builtInSentenses.RectalExamination
    this.OrologiaExamination = builtInSentenses.OrologiaExamination
    this.LimbsExamination = builtInSentenses.LimbsExamination
    this.NeuroExamination = builtInSentenses.NeuroExamination
  }


  ngOnInit(): void {
    if (this.route.parent != null && this.route.parent != undefined) {
      this.id = this.route.parent.snapshot.params['id']
    }
    this.eventsSubscription = this.events.subscribe((data) => this.confirmUnSavedChanges(data));


    // Check If there is an Opened Record 
    if (this.AccessedComponent == AccessComponenetType.SubModule) {
      this.getAllPhysicalCheckFromDB()
    }

  }





  getAllPhysicalCheckFromDB() {
    let status: string = "A"
    let filter = '(status=' + '\'' + status + '\'' + ')'

    this.caretxMedicalProviderService.getExamination(this.id, filter).subscribe(
      data => {
        if (data.length > 0) {
          this.physicalExamination = new PhysicalExamination(data[0])
          this.PhysicalExaminationHandler.emit(this.physicalExamination)
        }
      },
      err => {
        console.log("Error", err)
      }
    )
  }



  builtInSentensesEventHander($event: string) {
    this.physicalExamination.GeneralExamination += $event
  }

  insertGeneralExamSentens($event) {
    this.physicalExamination.GeneralExamination += this.GeneralExamination[0];
    this.isDirty.emit(true)
    this.generalExaminationtxt.nativeElement.focus()
  }

  insertHeadExamSentens($event) {
    this.physicalExamination.HeadExamination += this.HeadExamination[0];
    this.isDirty.emit(true)
    this.headExaminationtxt.nativeElement.focus()
  }

  insertEyesExamSentens($event) {
    this.physicalExamination.EyesExamination += this.EyesExamination[0];
    this.isDirty.emit(true)
    this.eyesExaminationtxt.nativeElement.focus()
  }

  insertEarsExamSentens($event) {
    this.physicalExamination.EarsExamination += this.EarsExamination[0];
    this.isDirty.emit(true)
    this.earsExaminationtxt.nativeElement.focus()
  }

  insertMouthExamSentens($event) {
    this.physicalExamination.MouthExamination += this.MouthExamination[0];
    this.isDirty.emit(true)
    this.mouthExaminationtxt.nativeElement.focus()
  }

  insertNeckExamSentens($event) {
    this.physicalExamination.NeckExamination += this.NeckExamination[0];
    this.isDirty.emit(true)
    this.neckExaminationtxt.nativeElement.focus()
  }

  insertLemphaExamSentens($event) {
    this.physicalExamination.LemphaExamination += this.LemphaExamination[0];
    this.isDirty.emit(true)
    this.lemphaExaminationtxt.nativeElement.focus()
  }

  insertChestExamSentens($event) {
    this.physicalExamination.ChestExamination += this.ChestExamination[0];
    this.isDirty.emit(true)
    this.chestExaminationtxt.nativeElement.focus()

  }

  insertHeartExamSentens($event) {
    this.physicalExamination.HeartExamination += this.HeartExamination[0];
    this.isDirty.emit(true)
    this.heartExaminationtxt.nativeElement.focus()

  }

  insertBreastsExamSentens($event) {
    this.physicalExamination.BreastsExamination += this.BreastsExamination[0];
    this.isDirty.emit(true)
    this.breastsExaminationtxt.nativeElement.focus()

  }

  insertStomechExamSentens($event) {
    this.physicalExamination.StomechExamination += this.StomechExamination[0];

    this.isDirty.emit(true)
    this.stomechExaminationtxt.nativeElement.focus()
  }

  insertRectalExamSentens($event) {
    this.physicalExamination.RectalExamination += this.RectalExamination[0];

    this.isDirty.emit(true)
    this.rectalExaminationtxt.nativeElement.focus()
  }

  insertOrologiaExamSentens($event) {
    this.physicalExamination.OrologiaExamination += this.OrologiaExamination[0];

    this.isDirty.emit(true)
    this.orologiaExaminationtxt.nativeElement.focus()
  }

  insertLimbsExamSentens($event) {
    this.physicalExamination.LimbsExamination += this.LimbsExamination[0];

    this.isDirty.emit(true)
    this.limbsExaminationtxt.nativeElement.focus()
  }

  insertNeuroExamSentens($event) {
    this.physicalExamination.NeuroExamination += this.NeuroExamination[0];
    this.isDirty.emit(true)
    this.neuroExaminationtxt.nativeElement.focus()

  }

  confirmUnSavedChanges(data: TabHistory) {
    if (data.CurrentTab == MedicalAdmissionSteps.PhysicalCheck) {
      this.nextTab = data.NextTab
    }
  }

  ngAfterViewInit() {
    this.ComponentInitialized = true
  }


  inputfieldChanged() {
    this.isDirty.emit(this.physexamform1.dirty)
    this.PhysicalExaminationHandler.emit(this.physicalExamination)
  }

  ClearTextField() {
    Swal.fire({
      title: "הודעת מערכת",
      text: "פעולה זו תנקה את כל השדות , להמשיך ?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonText: 'כן,תמשיך',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.physicalExamination.GeneralExamination = ""
        this.physicalExamination.HeadExamination = ""
        this.physicalExamination.EyesExamination = ""
        this.physicalExamination.EarsExamination = ""
        this.physicalExamination.MouthExamination = ""
        this.physicalExamination.NeckExamination = ""
        this.physicalExamination.LemphaExamination = ""
        this.physicalExamination.ChestExamination = ""
        this.physicalExamination.HeartExamination = ""
        this.physicalExamination.BreastsExamination = ""
        this.physicalExamination.StomechExamination = ""
        this.physicalExamination.RectalExamination = ""
        this.physicalExamination.OrologiaExamination = ""
        this.physicalExamination.LimbsExamination = ""
        this.physicalExamination.NeuroExamination = ""
        this.PhysicalExaminationHandler.emit(this.physicalExamination)
      }
    })


  }


  getPhysicalCheckFromDB() {
    this.caretxMedicalProviderService.getLatestExamination(this.id).subscribe(
      data => {
        this.physicalExamination.GeneralExamination = data[0].GeneralExamination
        this.physicalExamination.HeadExamination = data[0].HeadExamination
        this.physicalExamination.EyesExamination = data[0].EyesExamination
        this.physicalExamination.EarsExamination = data[0].EarsExamination
        this.physicalExamination.MouthExamination = data[0].MouthExamination
        this.physicalExamination.NeckExamination = data[0].NeckExamination
        this.physicalExamination.LemphaExamination = data[0].LemphaExamination
        this.physicalExamination.ChestExamination = data[0].ChestExamination
        this.physicalExamination.HeartExamination = data[0].HeartExamination
        this.physicalExamination.BreastsExamination = data[0].BreastsExamination
        this.physicalExamination.StomechExamination = data[0].StomechExamination
        this.physicalExamination.RectalExamination = data[0].RectalExamination
        this.physicalExamination.OrologiaExamination = data[0].OrologiaExamination
        this.physicalExamination.LimbsExamination = data[0].LimbsExamination
        this.physicalExamination.NeuroExamination = data[0].NeuroExamination

        //this.toastrService.Sucesss("", "")
      },
      err => {
        console.log("Error", err)
        this.toastrService.Error("", err)
      }
    )
  }


  CopyFromLastCheck() {

    Swal.fire({
      title: "הודעת מערכת",
      text: "להעתיק בדיקה גופנית אחרונה ?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: "ביטול",
      confirmButtonText: 'כן,תעתיק',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.getPhysicalCheckFromDB()
      }
    })

  }

  zoomInField(title, fieldNgModel, preFilledData) {
    this.dismissModal = false
    this.zoomInModalData = { title, field: fieldNgModel, preFilled: (preFilledData || '') };
  }

  bindFieldData(responseData) {
    this.physicalExamination[this.zoomInModalData.field] = responseData;
  }

  DismissCtxTextareaModal(){
    this.dismissModal = true
}
}
