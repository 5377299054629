import { Component, OnInit } from '@angular/core';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-nursedailyfoodreport',
  templateUrl: './nursedailyfoodreport.component.html',
  styleUrls: ['./nursedailyfoodreport.component.css']
})
export class NursedailyfoodreportComponent extends CaretexComponent implements OnInit {

  constructor(public medicalFileService: MedicalFileProviderService,) { super(medicalFileService);}

  ngOnInit(): void {
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  
  CheckIfDocumentIsDirty(){
    return false;
  }
  

}
