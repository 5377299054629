
import {Component, Inject, OnInit} from '@angular/core';
import {  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CaretxNursingProviderService } from 'src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { PersonalMeasureCode, PersonalMeasuresHeader } from 'src/app/models/measure';


@Component({
    selector: 'dialog-content-example-dialog',
    templateUrl: 'dialog-content-example-dialog.html',
    styleUrls: ['dialog-content-example-dialog.css']
  })
  export class DialogContentExampleDialog implements OnInit {

    id:string
    measureCodeId : string
    personelMeasuresHeaders :PersonalMeasuresHeader[] 
    personelMeasuresCodes : PersonalMeasureCode[]
    constructor( private route: ActivatedRoute,private caretxNursingProviderService: CaretxNursingProviderService,@Inject(MAT_DIALOG_DATA) public data: any){
    }
        

    ngOnInit(): void {
        this.id = this.data.personelid;
        this.measureCodeId = this.data.measurecode.ID
        this.getLastXMeasuresOfMeasureCodeY()
      }

      getLastXMeasuresOfMeasureCodeY(){
        this.caretxNursingProviderService.getPerosenelMeasuresByCode(this.id, this.measureCodeId).subscribe(
          (data :PersonalMeasuresHeader[]) => {
            this.personelMeasuresHeaders= []
    
    
            data.forEach(header => {
              this.personelMeasuresHeaders.push(new PersonalMeasuresHeader(header))
            }) 
          },
          err => {
            console.log("Error", err)
            this.personelMeasuresHeaders = []
    
          }
        )
      }  

  }
  
  
  