import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard, CanDeactivateGuard } from '../shared/guard/auth.guard';
import { DruginstrListComponent } from './components/druginstructions/druginstr-list/druginstr-list.component';
import { DruginstructionsComponent } from './components/druginstructions/druginstructions.component';
import { HazarddetailsComponent } from './components/hazarddetails/hazarddetails.component';
import { HazardslistComponent } from './components/hazardslist/hazardslist.component';
import { LablandingpageComponent } from './components/Laboratory/laboratory/lablandingpage/lablandingpage.component';
import { NewlaboratorycheckComponent } from './components/Laboratory/laboratory/labortatoryresults/newlaboratorycheck.component';
import { OrderlabchecksComponent } from './components/Laboratory/laboratory/orderlabchecks/orderlabchecks.component';
import { LaboratorycheckslistComponent } from './components/Laboratory/Lists/laboratorycheckslist/laboratorycheckslist.component';
import { LaboratoryrequestslistComponent } from './components/Laboratory/Lists/laboratoryrequestslist/laboratoryrequestslist.component';
import { MedicalAdmissionListComponent } from './components/medical-admission/medical-admission-list/medical-admission-list.component';
import { MedicalAdmissionComponent } from './components/medical-admission/medical-admission.component';
import { MedicalDiagnosesComponent } from './components/medical-diagnoses/medical-diagnoses.component';
import { MedicalReleaseComponent } from './components/medical-release/medical-release.component';
import { MedicalreleaselistComponent } from './components/medical-release/medicalreleaselist/medicalreleaselist.component';
import { MedicalexceptionaleventslistComponent } from './components/medicalexceptionaleventslist/medicalexceptionaleventslist.component';
import { MedicalobservationComponent } from './components/medicalobservation/medicalobservation.component';
import { NursinginstructionsComponent } from './components/nursinginstructions/nursinginstructions.component';
import { PassovernoticeComponent } from './components/passovernotice/passovernotice.component';
import { PhysicalcheckListComponent } from './components/physicalcheck/physicalcheck-list/physicalcheck-list.component';
import { PhysicalcheckComponent } from './components/physicalcheck/physicalcheck.component';
import { SensitivitiesComponent } from './components/sensitivities/sensitivities.component';
import { MedicalroutanchorComponent } from './medicalroutanchor/medicalroutanchor.component';
import { ExceptionaleventComponent } from '../caretx-shared-module/CaretexSharedComponents/exceptionalevent/exceptionalevent.component';
import { MedicalAdmissionLockedComponent } from './components/medical-admission/medical-admission-locked/medical-admission-locked.component';
import { FamilyTalksComponent } from './components/family-talks/family-talks.component';
import { ExpertConsultantsComponent } from './components/expert-consultants/expert-consultants.component';
import { HospitalReferralsComponent } from './components/hospital-referrals/hospital-referrals.component';
import { HospitalReferralDetailsComponent } from './components/hospital-referrals/hospital-referral-details/hospital-referral-details.component';
import { ExpertConsultantDetailsComponent } from './components/expert-consultants/expert-consultant-details/expert-consultant-details.component';
import { FamilyTalkDetailsComponent } from './components/family-talks/family-talk-details/family-talk-details.component';
import { MedicalomdanLandPageComponent } from './components/medicalomdan-land-page/medicalomdan-land-page.component';
import { MiniMentalListComponent } from './components/medicalomdan-land-page/mini-mental-list/mini-mental-list.component';
import { MiniMentalOmdanComponent } from './components/medicalomdan-land-page/mini-mental-omdan/mini-mental-omdan.component';
import { ClockExamListComponent } from './components/medicalomdan-land-page/clock-exam-list/clock-exam-list.component';
import { ClockExamComponent } from './components/medicalomdan-land-page/clock-exam/clock-exam.component';
import { ExpertConsultantLockedComponent } from './components/expert-consultants/expert-consultant-locked/expert-consultant-locked.component';
import { FamilyTalkLockedComponent } from './components/family-talks/family-talk-locked/family-talk-locked.component';
import { ExceptionaleventlockedComponent } from '../caretx-shared-module/CaretexSharedComponents/exceptionaleventlocked/exceptionaleventlocked.component';
import { HospitalReferralDetailsLockedComponent } from './components/hospital-referrals/hospital-referral-details-locked/hospital-referral-details-locked.component';
import { ExternalmedicaladmissionComponent } from './components/medical-admission/externalmedicaladmission/externalmedicaladmission.component';






const routes: Routes = [
  // Medical Routes

  {
    path: 'caretexfile/:id/eventmedical',
    component: MedicalroutanchorComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'list',
        component: MedicalexceptionaleventslistComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':eventid',
        component: ExceptionaleventComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':eventid/locked',
        component: ExceptionaleventlockedComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },



    ]
  },

  { 
    path: 'caretexfile/:id/druginstrs', 
    component: MedicalroutanchorComponent , 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      { 
        path: 'list', 
        component: DruginstrListComponent, 
        canActivate: [AuthGuard] ,
        canDeactivate: [CanDeactivateGuard]
      },
      { 
        path: ':instrid', 
        component: DruginstructionsComponent, 
        canActivate: [AuthGuard] ,
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'new',
        component: DruginstructionsComponent,
        canActivate: [AuthGuard],
        data: { mode: ['newpage'] },
        canDeactivate: [CanDeactivateGuard]
      },
    ]
  
  },

  { 
    path: 'caretexfile/:id/nurseinstrs', 
    component: MedicalroutanchorComponent , 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      { 
        path: 'list', 
        component: NursinginstructionsComponent, 
        canActivate: [AuthGuard] 
      },
      { 
        path: ':instrid', 
        component: NursinginstructionsComponent, 
        canActivate: [AuthGuard] 
      },
    ]
  
  },

  { 
    path: 'caretexfile/:id/passovernotice', 
    component: MedicalroutanchorComponent , 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      { 
        path: 'list', 
        component: PassovernoticeComponent, 
        canActivate: [AuthGuard] 
      },
    ]
  
  },

  { 
    path: 'caretexfile/:id/medicalobservations', 
    component: MedicalroutanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: MedicalobservationComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

      {
        path: 'list/step/:stepid', 
        component: MedicalobservationComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'step/:stepid',
        component: MedicalobservationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'step/:stepid/:measureheaderid',
        component: MedicalobservationComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'new',
        component: MedicalobservationComponent,
        canActivate: [AuthGuard],
        data: { mode: ['newpage'] },
        canDeactivate: [CanDeactivateGuard]
      },
    ]
  },
   
  {
    path: 'caretexfile/:id/medicaladmissions',
    component: MedicalroutanchorComponent ,
    canActivate: [AuthGuard],
    children: [
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: MedicalAdmissionListComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
    
      {
        path: ':admissionid', 
        component: MedicalAdmissionComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':admissionid/step/:tabid', 
        component: MedicalAdmissionComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':admissionid/step/:tabid/:druginstrid', 
        component: MedicalAdmissionComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

      {
        path: 'new',
        component: MedicalAdmissionComponent,
        canActivate: [AuthGuard],
        data: { mode: ['newpage'] },
       canDeactivate: [CanDeactivateGuard],
      },

      {
        path: ':admissionid/locked', 
        component: MedicalAdmissionLockedComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      


    ]
  },


  { 
    path: 'caretexfile/:id/externalmedicaladmission', 
    component: MedicalroutanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: MedicalAdmissionListComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':admissionid', 
        component: ExternalmedicaladmissionComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
    ]
  },


  { 
    path: 'caretexfile/:id/MedicalDiagnoses', 
    component: MedicalroutanchorComponent, 
    canActivate: [AuthGuard],
   // canDeactivate: [CanDeactivateGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: MedicalDiagnosesComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
    ]
  },

  {
    path: 'caretexfile/:id/physicalchecks',
    component: MedicalroutanchorComponent, 
    canActivate: [AuthGuard],
    children: [
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: PhysicalcheckListComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':physicalcheckId',
        component: PhysicalcheckComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
    
      {
        path: 'new',
        component: PhysicalcheckComponent,
        canActivate: [AuthGuard],
        data: { mode: ['newpage'] },
        canDeactivate: [CanDeactivateGuard]
      },

    ]
  },


  {
    path: 'caretexfile/:id/hazards',
    component: MedicalroutanchorComponent,
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: HazardslistComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      { 
        path: ':hazardid', 
        component: HazarddetailsComponent, 
        canActivate: [AuthGuard] 
      },
      { 
        path: 'new', 
        component: HazarddetailsComponent, 
        canActivate: [AuthGuard],
        data: { mode: ['newpage']}
      }
    ]
  },



  { 
    path: 'caretexfile/:id/laboratory', 
    component: MedicalroutanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'landingpage',
        pathMatch:'full',
      },
      { path: 'landingpage', component: LablandingpageComponent, canActivate: [AuthGuard] },
      { path: 'results', component: LaboratorycheckslistComponent, canActivate: [AuthGuard] },
      { path: 'results/:labheaderid', component: NewlaboratorycheckComponent, canActivate: [AuthGuard] },
      { path: 'results/new', component: NewlaboratorycheckComponent,data: { mode: ['newpage'] }, canActivate: [AuthGuard] },
      { path: 'orders', component: LaboratoryrequestslistComponent, canActivate: [AuthGuard] },
      { path: 'orders/:requestid', component: OrderlabchecksComponent, canActivate: [AuthGuard] },
      { path: 'orders/new', component: OrderlabchecksComponent, canActivate: [AuthGuard] },
    ]
  

  },

  { 
    path: 'caretexfile/:id/MedicalRelease', 
    component: MedicalroutanchorComponent, 
    canActivate: [AuthGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: MedicalreleaselistComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: ':releaseid', 
        component: MedicalReleaseComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },

      {
        path: ':releaseid/:tabid', 
        component: MedicalReleaseComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },


      {
        path: 'new',
        component: MedicalReleaseComponent,
        canActivate: [AuthGuard],
        data: { mode: ['newpage'] },
       canDeactivate: [CanDeactivateGuard],
      },
    ]
  },





  { 
    path: 'caretexfile/:id/sensitivities', 
    component: MedicalroutanchorComponent, 
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    children:[
      {
        path: '', 
        redirectTo:'list',
        pathMatch:'full',
      },
      {
        path: 'list', 
        component: SensitivitiesComponent, 
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
    ]
  },




//familytalks

{ 
  path: 'caretexfile/:id/treatmentcoordination', 
  component: MedicalroutanchorComponent, 
  canActivate: [AuthGuard],
  children:[
    {
      path: '', 
      redirectTo:'list',
      pathMatch:'full',
    },
    {
      path: 'list', 
      component: FamilyTalksComponent, 
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':talksid', 
      component: FamilyTalkDetailsComponent, 
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },

    {
      path: ':talksid/locked', 
      component: FamilyTalkLockedComponent, 
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
    

    {
      path: ':talksid/step/:tabid', 
      component: FamilyTalkDetailsComponent, 
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },


    {
      path: 'new',
      component: FamilyTalkDetailsComponent,
      canActivate: [AuthGuard],
      data: { mode: ['newpage'] },
     canDeactivate: [CanDeactivateGuard],
    },
  ]
},

//expert-consultants
{ 
  path: 'caretexfile/:id/consultatory',                     
  component: MedicalroutanchorComponent, 
  canActivate: [AuthGuard],
  children:[
    {
      path: '', 
      redirectTo:'list',
      pathMatch:'full',
    },
    {
      path: 'list', 
      component: ExpertConsultantsComponent, 
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':consultatoryid', 
      component: ExpertConsultantDetailsComponent, 
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':consultatoryid/locked', 
      component: ExpertConsultantLockedComponent, 
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },

    {
      path: 'new',
      component: ExpertConsultantDetailsComponent,
      canActivate: [AuthGuard],
      data: { mode: ['newpage'] },
     canDeactivate: [CanDeactivateGuard],
    },
  ]
},

//hospital-referrals
{ 
  path: 'caretexfile/:id/hospitalreferrals', 
  component: MedicalroutanchorComponent, 
  canActivate: [AuthGuard],
  children:[
    {
      path: '', 
      redirectTo:'list',
      pathMatch:'full',
    },
    {
      path: 'list', 
      component: HospitalReferralsComponent, 
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: ':referralid', 
      component: HospitalReferralDetailsComponent, 
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },

    {
      path: ':referralid/locked', 
      component: HospitalReferralDetailsLockedComponent, 
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },


    {
      path: 'new',
      component: HospitalReferralDetailsComponent,
      canActivate: [AuthGuard],
      data: { mode: ['newpage'] },
     canDeactivate: [CanDeactivateGuard],
    },
  ]
},
{
  path: 'caretexfile/:id/medicalexams',
  component: MedicalroutanchorComponent,
  canActivate: [AuthGuard],
  children: [
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full',
    },
    {
      path: 'list',
      component: MedicalomdanLandPageComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },


    {
      path: 'minimental/list',
      component: MiniMentalListComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: 'minimental/:minimentalid',
      component: MiniMentalOmdanComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
  {
      path: 'omdanclock/list',
      component: ClockExamListComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
    {
      path: 'omdanclock/:clockid',
      component: ClockExamComponent,
      canActivate: [AuthGuard],
      canDeactivate: [CanDeactivateGuard]
    },
   


  ]
},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DoctorsRoutingModule { }
