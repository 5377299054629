import { Injectable } from '@angular/core';


@Injectable()
export class BridgeBuiltInSentenses {
    public GeneralExamination: string[]
    public HeadExamination: string[]
    public EyesExamination: string[]
    public EarsExamination: string[]
    public MouthExamination: string[]
    public NeckExamination: string[]
    public LemphaExamination: string[]
    public ChestExamination: string[]
    public HeartExamination: string[]
    public BreastsExamination: string[]
    public StomechExamination: string[]
    public RectalExamination: string[]
    public OrologiaExamination: string[]
    public LimbsExamination: string[]
    public NeuroExamination: string[]
    public constructor() {
        this.NeuroExamination = [
            'ללא חוסר נוירולוגי גס, ללא קשיון עורף, כוח גס שמור'
        ]
        this.LimbsExamination = [
            'ללא בצקות, ללא סימני צילוליטיס או ארטריטיס, דפקים היקפיים נמושו היטיב'
        ]
        this.OrologiaExamination = [
            'משפט על בדיקה אורולוגית תקינה'
        ]
        this.RectalExamination = [
            'משפט על בדיקה ריקטלית תקינה'
        ]

        this.StomechExamination = [
            'בטן רכה, ללא רגישות, ללא סימני גרוי צפקי, טחול או כבד אינם מוגדלים , ניע תקין',
        ]

        this.BreastsExamination = [
            'משפט על שדיים',
        ]
        this.HeartExamination = [
            'קולות לב סדירים, לא נשמעו אוושות ,ללא שפשוף פריקרדיאלי',
        ]
        this.ChestExamination = [
            'כניסת אוויר שווה וטובה לשתי הריאות, ללא צפצופים או חרחורים',
        ]
        this.LemphaExamination = [
            'משפט על  קשרי לימפה',
        ]
        this.NeckExamination = [
            'ללא סימני גודש בוורידי הצוואר , לא נשמעו אוושות עפ" עורקי התרדמה ,בלוטת המגן נמושה תקינה , בלוטות לימפה לא נמושו בכל התחכות',
        ]

        this.MouthExamination = [
            'משפט על פה ולוע',
        ]

        this.EarsExamination = [
            'משפט על אוזניים',
        ]
        this.EyesExamination = [
            'משפט על עיניים',
        ]

        this.HeadExamination = [
            'ללא סימני חבלה בגלגולת',
        ]

        this.GeneralExamination = [
            'במצב כללי טוב יציב,עירני,משתף פעולה. ללא סימני מצוקה רשימתית ,ללא כחלון ,חיוורון או צהבת',
        
        ]
    }
}


@Injectable()
export class BridgeBuiltInNurseSentenses{
    public BreathingExamination: string[]
    public MobilityExamination: string[]
    public TransferExamination: string[]
    public WashingExamination: string[]

    public ClothingExamination: string[]
    public FeedingExamination: string[]
    public MouthExamination: string[]
    public DiatesExamination: string[]
    public AppetiteExamination: string[]
    public BowelExamination: string[]
    public urineExamination: string[]
    public SkinExamination: string[]
    public EyeExamination: string[]
    public EarsExamination: string[]
    public SleepExamination: string[]
    public PainExamination: string[]
    public ReadWriteExamination: string[]
    public OrintationExamination: string[]

    public EnvAwarnessExamination: string[]

    public constructor() {
        this.EnvAwarnessExamination=[
            'להזין משפט'
        ]

        this.OrintationExamination=[
            'מתמצא במקום ובזמן'
        ]

        this.ReadWriteExamination=[
            'קורא וכובת'
        ]

        this.PainExamination=[
            'משפט על כאב '
        ]

        this.SleepExamination=[
            'משפט על  שינה תקינה'
        ]

        this.EarsExamination=[
            'משפט על  שמיעה תקינה'
        ]

        this.EyeExamination=[
            'משפט על  ראיה תקינה'
        ]


        this.SkinExamination=[
            'משפט על  עור תקינה'
        ]


        this.urineExamination=[
            'משפט על  שתן תקינה'
        ]

        this.BowelExamination=[
            'משפט על פעולת מעיים תקינה'
        ]

        this.AppetiteExamination=[
            'משפט על תאבון תקינה'
        ]

        this.DiatesExamination=[
            'משפט על דיאטה תקינה'
        ]

        this.MouthExamination=[
            'משפט על פה תקינה'
        ]

        this.FeedingExamination=[
            'משפט על האכלה תקינה'
        ]

        this.ClothingExamination=[
            'משפט על הלבשה תקינה'
        ]

        this.BreathingExamination = [
            'משפט על נשימה תקינה'
        ]

        this.MobilityExamination= [
            'משפט על תנועתיות תקינה'
        ]

        this.TransferExamination=[
            'משפט על תעבורה תקינה'
        ]

        this.WashingExamination=[
            'משפט על רחיצה תקינה'
        ]
    }
}