import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { DieticianAssessmentSteps } from '../../../../../src/app/services/enums';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { CaretxSetupsProviderService } from '../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { DietarySupplement, DietaryUomCode, EatingManner, FoodAddition, MealCode, MenuCode, Texture } from '../../../models/generalhousedsetups';
import Swal from 'sweetalert2';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { DieticianDataProviderService } from '../../Dietician-services/dietician.data-provider.service';

import { DieticianRecommendation } from "../../../models/dietaryRecommendations"
import { NgForm } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { TabHistory } from '../../../../../src/app/models/genericTypes';

@Component({
  selector: 'app-dietician-recommendations',
  templateUrl: './dietician-recommendations.component.html',
  styleUrls: ['./dietician-recommendations.component.scss']
})
export class DieticianRecommendationsComponent extends CaretexComponent implements OnInit {
  @Input() IsSubModule:boolean = false
  personalId: string
  @ViewChild('f') mainFormElement: NgForm;
  moduleName: string = "המלצות"
  isRecordClosed: boolean = false
  CurrentStep: DieticianAssessmentSteps = DieticianAssessmentSteps.Problems
  show: string = "settings"
  foodAdditions: FoodAddition[] = []
  supplements: DietarySupplement[] = [];
  menuCollection: MenuCode[] = [];
  mealCollection: MealCode[] = [];
  uomCollection: DietaryUomCode[] = [];
  textureCollection: Texture[] = [];
  EatingMannersCollection: EatingManner[] = [];
  dropdownSettings: any = {};
  FoodAdditionSettings: any = {};
  SupplementsSettings: any = {};
  selectedItems1: any = {};
  changedFoodMenu: any = {};
  selectedSupplement: any = {};
  selectedMeal: any = {};
  selectedFoodAddition: any = {};
  selectedTexture: any = {};
  selectedEatingManner: any = {};
  private eventsSubscription: Subscription;
  @Input() events: Observable<TabHistory>;
  step: number = 0
  _defaultStep = 999999
  showSelectedRow : boolean = false;
  currentRowIndex:Number

 @Input() CurrentRecommendation: DieticianRecommendation = new DieticianRecommendation()
  recommendations: DieticianRecommendation[] = []

  constructor(
    public medicalFileService: MedicalFileProviderService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,
    private caretxDieticianProviderService: DieticianDataProviderService,
    private caretxSetupsProviderService: CaretxSetupsProviderService,) {
    super(medicalFileService);
    this.caretxSetupsProviderService.getFoodAdditions().subscribe((data => {
      this.foodAdditions = data
    }))

    this.caretxSetupsProviderService.getDietarySupplement().subscribe((data => {
      this.supplements = data
    }))

    this.caretxSetupsProviderService.getDietaryMenuCode().subscribe((data => {
      this.menuCollection = data
    }))

    this.caretxSetupsProviderService.getDietaryMealCodes().subscribe((data => {
      this.mealCollection = data
    }))

    this.caretxSetupsProviderService.getDietaryUomCodes().subscribe((data => {
      this.uomCollection = data
    }))

    this.caretxSetupsProviderService.getDietaryTextures().subscribe((data => {
      this.textureCollection = data
    }))

    this.caretxSetupsProviderService.getDietaryEatingManner().subscribe((data => {
      this.EatingMannersCollection = data
    }))

  }



  ngOnInit(): void {
    if (this.route.parent !=null)
    this.personalId = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.personalId)
    if (this.IsSubModule){
     // this.eventsSubscription = this.events.subscribe((data) => this.confirmUnSavedChanges(data));
    }
   

    this.SupplementsSettings = this.FoodAdditionSettings = {
      singleSelection: true,
      idField: "ID",
      textField: "Name",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 2,
      allowSearchFilter: true
    };

    this.dropdownSettings = {
      singleSelection: true,
      idField: "ID",
      textField: "Name",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 2,
      allowSearchFilter: true
    }

    this.getRecommendations()
  }

  getRecommendations() {
    let filter = ""
    this.resetCurrentRecommendation(1)
    this.caretxDieticianProviderService.getDieticianRecommendation(this.personalId, filter, "").subscribe(
      data => this.recommendations = data,
      err => {
        this.recommendations = []
      }
    )
  }

  confirmUnSavedChanges(data){
    this.mainFormElement.onSubmit(undefined);
  }
  submitForm() {
    this.mainFormElement.onSubmit(undefined);
  }

  DoSave() {
    this.caretxDieticianProviderService.createDieticianRecommendation(this.CurrentRecommendation).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.CurrentRecommendation = new DieticianRecommendation(data)
        if (this.CurrentRecommendation.Status == 'C')
          this.isRecordClosed = true

        this.getRecommendations()

      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  DoUpdate() {
    this.caretxDieticianProviderService.updateDieticianRecommendation(this.CurrentRecommendation).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.CurrentRecommendation = new DieticianRecommendation(data)
        if (this.CurrentRecommendation.Status == 'C')
          this.isRecordClosed = true

        this.getRecommendations()

      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  saveDetails() {


    this.CurrentRecommendation.StartDate = new Date(this.CurrentRecommendation.StartDate )
    this.CurrentRecommendation.EndDate = new Date(this.CurrentRecommendation.EndDate )

    if (this.CurrentRecommendation.ID == "00000000-0000-0000-0000-000000000000" || this.CurrentRecommendation.ID == "") {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }




  setStep(index: number) {
    this.step = index;
    if (!this.showSelectedRow ){
        this.mainFormElement.resetForm();
      this.resetCurrentRecommendation(this.step)
    }
    this.showSelectedRow = false
  }



  translateTypeToDescription(type: string) {
    let description: string = ""
    switch (type) {
      case "FeedingWay":
        description = "אופן אכילה"
        break
      case "ChangeTexture":
        description = "שינוי מרקם"
        break
      case "ChangeMenu":
        description = "שינוי תפריט"
        break
      case "nutritionalSupplement":
        description = "תוסף תזונתי"
        break
      case "foodAddition":

        description = "תוספת מזון"
        break
      case "generalRecommendation":

        description = "המלצה כללית"
        break
      default: description = "לא ידוע"
        break



    }
    return description
  }


  resetCurrentRecommendation(step) {
    this.isRecordClosed = false
    this.CurrentRecommendation = new DieticianRecommendation()
    let type = this.convertSteptoRecommendationType(step)
    this.CurrentRecommendation.RecommendationType = type
    this.CurrentRecommendation.CreatedAt = new Date()
    this.CurrentRecommendation.Status = "A"
    this.CurrentRecommendation.Subject = this.translateTypeToDescription(type)
    this.CurrentRecommendation.ApproverId = this.authService.LoggedInUser.UID
    this.CurrentRecommendation.TenantID = this.authService.LoggedInTenantID
    this.CurrentRecommendation.PersonelID = this.personalId
    this.changedFoodMenu = []
    this.selectedSupplement = []
    this.selectedMeal = []
    this.selectedEatingManner = []
    this.selectedTexture = []
    this.selectedFoodAddition = []
  }

  DisplaySelectedRow(record) {
    this.showSelectedRow = true
    this.CurrentRecommendation = record
    this.selectedSupplement = { id: record, Name: record.Name }
    this.selectedSupplement = [this.CurrentRecommendation.DietarySupplement];
    this.selectedFoodAddition = [this.CurrentRecommendation.FoodAddition];
    this.selectedMeal = [this.CurrentRecommendation.MealCode];
    this.selectedTexture = [this.CurrentRecommendation.Texture];
    this.changedFoodMenu = [this.CurrentRecommendation.MenuCode];
    this.selectedEatingManner = [this.CurrentRecommendation.EatingManner];
    this.step = this.convertRecommendationTypeToStepNum(this.CurrentRecommendation.RecommendationType)
    if (this.CurrentRecommendation.Status == 'C' || this.CurrentRecommendation.Status == 'P')
    this.isRecordClosed = true
    else
    this.isRecordClosed = false

  }

  onMenuSelect($event) {
    this.CurrentRecommendation.MenuCodeId = $event.ID
    this.CurrentRecommendation.MenuCode = $event
  }

  onSupplementSelect($event) {
    this.CurrentRecommendation.DietarySupplementId = $event.ID
    this.CurrentRecommendation.DietarySupplement = $event
  }

  onFoodAdditionSelect($event) {
    this.CurrentRecommendation.FoodAdditionId = $event.ID
    this.CurrentRecommendation.FoodAddition = $event
  }

  onMealSelect($event) {
    this.CurrentRecommendation.MealCodeId = $event.ID
    this.CurrentRecommendation.MealCode = $event
  }

  onTextureSelect($event) {
    this.CurrentRecommendation.TextureId = $event.ID
    this.CurrentRecommendation.Texture = $event
  }

  onEatingMannerSelect($event) {
    this.CurrentRecommendation.EatingMannerId = $event.ID
    this.CurrentRecommendation.EatingManner = $event
  }

  generateReport($event, record) {

  }

  deleteRow(i){
    this.currentRowIndex = i
    if ((this.recommendations[i].Status == "A")) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText:"בטל",
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRecord(this.recommendations[i])
        }
      })

    }
  }


  deleteRecord(record: DieticianRecommendation) {
    this.caretxDieticianProviderService.deleteDieticianRecommendation(this.personalId, record.ID).subscribe({
      next: data => {
        this.getRecommendations()
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }


  ApproveRecord() {

    if (this.CurrentRecommendation.ID == "00000000-0000-0000-0000-000000000000") {
      Swal.fire({
        title: "הודעת מערכת",
        text: "צריך לשמור את הרשומה קודם",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'בסדר',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    } else {
      this.CurrentRecommendation.Status = "P"
      this.CurrentRecommendation.ApproverId = this.authService.LoggedInUser.UID
      this.CurrentRecommendation.ApprovalDate = new Date()
      this.saveDetails()
    }

  }

  StopRecord() {
    if (this.CurrentRecommendation.ID == "00000000-0000-0000-0000-000000000000") {
      Swal.fire({
        title: "הודעת מערכת",
        text: "צריך לשמור את הרשומה קודם",
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'בסדר',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    } else {
      this.CurrentRecommendation.Status = "C"
      this.CurrentRecommendation.ApproverId = this.authService.LoggedInUser.UID
      this.CurrentRecommendation.ApprovalDate = new Date()
      this.saveDetails()
    }

  }


convertRecommendationTypeToStepNum(type :string){
      switch(type){
        case "FeedingWay":
            return 1
       
        case "ChangeTexture":
          return 2
        case "ChangeMenu":
          return 3
        case "nutritionalSupplement":
          return 4
        case "foodAddition":
          return 5
        case "generalRecommendation":
          return 6
        default:  return 9999
      }
}


convertSteptoRecommendationType(step :number){
  switch(step){
    case 1:
        return "FeedingWay"
   
    case 2:
      return "ChangeTexture"
    case 3:
      return "ChangeMenu"
    case 4:
      return "nutritionalSupplement"
    case 5:
      return "foodAddition"
    case 6:
      return "generalRecommendation"
    default:  return ""
  }
}


canDeactivate() {
  this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
}

CheckIfDocumentIsDirty() {
  return false
}
}
