import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalObservation, MedicalObservationFile } from '../../../../../src/app/models/Observations';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { MedicalObservationSteps, OptionObject, sideNaveGroups } from '../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { CommonInterface } from '../../../../../src/app/shared/SharedInterface';
import { CaretxMedicalProviderService } from '../../doctor-services/Medical.data-provider.service';
import { CartexEvent, TabHistory } from '../../../../../src/app/models/genericTypes';
import { Subject } from 'rxjs/internal/Subject';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { PagenationObject } from '../../../../../src/app/models/pagenation';
import { AccessComponenetType, OriginModule } from '../../../services/enums';
import { CaretxSharedModulesProviderService } from '../../../../../src/app/caretx-shared-module/sharedmodule.data-provider.services';
import { Attachment } from '../../../../../src/app/models/attachments';
import { AttachmentDialogComponent } from '../../../caretx-shared-module/CaretexModals/attachment-dialog/attachment-dialog.component';
import { jsPDF } from "jspdf";
import { CameraDialogComponent } from '../../../../../src/app/caretx-shared-module/CaretexModals/camera-dialog/camera-dialog.component';
import { DrugInstruction } from 'src/app/models/drugInstruction';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-medicalobservation',
  templateUrl: './medicalobservation.component.html',
  styleUrls: ['./medicalobservation.component.scss']
})
export class MedicalobservationComponent extends CaretexComponent implements OnInit, CommonInterface {
  @ViewChild('myEditor') myEditor: any;
  moduleName: string = "מעקב רפואי"
  FileCategoryList: OptionObject[] = [{ id: 'medicalobsrvfile', name: 'מסמכי מעקב רפואי' },]
  medicationtype: string = "F"
  medicationTitle: string = "תרופות קבועות"

  isLoading: boolean;
  isClosed: boolean = false
  public model = {
    editorData: ''
  };

  @ViewChild('mainform') mainFormElement: NgForm;
  AccessedComponent: AccessComponenetType = AccessComponenetType.SubModule
  public currMedicalObservation: MedicalObservation
  id: string
  followups: MedicalObservation[] = []
  totalNumOfRecords: Number
  pagenationObject: PagenationObject
  showWebcam: boolean = false
  medicalObservationFile: Attachment[] = []
  system: boolean = true;
  pdfContent: any
  personelDocuments: MedicalObservationFile[] = []
  newObservationFile: MedicalObservationFile
  editorChanged: boolean
  observationid: string
  CurrentStep: MedicalObservationSteps = MedicalObservationSteps.Current
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  tabHistory: TabHistory = new TabHistory()
  caretexEvent: CartexEvent = new CartexEvent()
  AdmissionType: string = "MedicalObservation"
  isPhysicalexaminationIsDirty: boolean = false
  isfrequentMeasuresDirty: boolean = false
  Origin: OriginModule = OriginModule.MedicalFollowUp
  followupType: string = "medical"
  LoggedInUser: string
  step: number = 0
  public config2: any
  Type: string = AccessComponenetType.SubModule
  lastMeasureHeaderID:string
  isNewRecord: boolean = false


  constructor(
    private _ar: ActivatedRoute,
    private authServive: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private caretxSharedlService: CaretxSharedModulesProviderService,

    private sharedServices: CaretxSharedModulesProviderService,
    private toastrService: ToasterService,
    private medicalObservationservice: CaretxMedicalProviderService,
    public medicalFileServive: MedicalFileProviderService,
    private route: ActivatedRoute,) {
    super(medicalFileServive)
    this.LoggedInUser = this.authServive.LoggedInUser.UID
    this.newObservationFile = new MedicalObservationFile()
  }
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Medical;

  ngOnInit(): void {
    if (this.route.parent != null)
      this.id = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.id)
    if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
      this.step = Number(this.route.snapshot.params['stepid']);
      if (this.step==6){
        this.lastMeasureHeaderID = this.route.snapshot.params['measureheaderid'];
      }

    }
    // Get Total Number of records :
    this.getTotalNumOfFollowUpsFromDB()
    this.getMedicalObservation("", "new")
    this.getFollowUpListFromDB("1")

    this.config2 = {
      airMode: false,
      tabDisable: true,
      popover: {}, 
      height: '200px',
      uploadImagePath: '/api/upload',
      toolbar: [],
      fontSizes: [
        '8',
        '9',
        '10',
        '11',
        '12',
        '14',
        '18',
        '24',
        '36',
        '44',
        '56',
        '64',
        '76',
        '84',
        '96',
      ],
      fontNames: [
        'Arial',
        'Times New Roman',
        'Inter',
        'Comic Sans MS',
        'Courier New',
        'Roboto',
        'Times',
        'MangCau',
        'BayBuomHep',
        'BaiSau',
        'BaiHoc',
        'CoDien',
        'BucThu',
        'KeChuyen',
        'MayChu',
        'ThoiDai',
        'ThuPhap-Ivy',
        'ThuPhap-ThienAn',
      ],

      codeviewFilter: true,
      codeviewFilterRegex:
        /<\/*(?:applet|b(?:ase|gsound|link)|embed|frame(?:set)?|ilayer|l(?:ayer|ink)|meta|object|s(?:cript|tyle)|t(?:itle|extarea)|xml|.*onmouseover)[^>]*?>/gi,
      codeviewIframeFilter: true,
    };
  }

  getTotalNumOfFollowUpsFromDB() {
    this.medicalObservationservice.getTotalOfMedicalObservations(this.id).subscribe((data: number) => {
      this.pagenationObject = new PagenationObject(data)
    });
  }

  getMedicalObservation(observationid: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + observationid + '\'' + ')'
    this.medicalObservationservice.getMedicalObservations(filter, this.id, mode, "1").subscribe((data: MedicalObservation[]) => {
      if (data.length == 1) {
        this.currMedicalObservation = new MedicalObservation(data[0])
        this.isClosed = this.diff_hours(new Date(), this.currMedicalObservation.CreatedAt) > 24 ? true : false
        this.medicalObservationFile = this.currMedicalObservation.MedicalObservationFiles
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  getFollowUpListFromDB(page: string) {
    this.medicalObservationservice.getMedicalObservations("", this.id, "list", page).subscribe((data: MedicalObservation[]) => {
      if (data.length > 0) {
        this.followups = data
      }
      else {
        this.followups = []
      }
    });
  }

  HandlePagenation($event) {
    this.getFollowUpListFromDB($event)
  }
  refreshHistoryRecords() {
    this.getFollowUpListFromDB("1")
  }

  DoSave() {
    this.currMedicalObservation.LastUpdateById = this.authServive.LoggedInUser.UID
    this.currMedicalObservation.UpdatedAt = new Date()
    this.currMedicalObservation.NextTrackDate = new Date(this.currMedicalObservation.NextTrackDate)
    this.currMedicalObservation.PersonelID = this.id
    this.medicalObservationservice.createNewMedicalObservation(this.currMedicalObservation).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currMedicalObservation = new MedicalObservation(data)
        this.lastMeasureHeaderID = this.currMedicalObservation.PersonalMeasuresHeader.ID
        this.medicalFileService.ResetCachedMedicalFile(this.id)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload'

        if (this.step == 6 ) {
          this.router.navigateByUrl("caretexfile/" + this.id + "/medicalobservations/step/" + this.step + "/" + this.lastMeasureHeaderID )
        }else{
          this.router.navigateByUrl("caretexfile/" + this.id + "/medicalobservations/step/" + this.step)
        }

      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  updateNextTabFromSensitivity($event) {
    this.CurrentStep = ($event as MedicalObservationSteps)
  }

  personelSensitivityChanged($event) {
  }

  ClearTextField() {
    this.currMedicalObservation.RichText = ""
  }

  CopyLastReportedFollowUp() {
    if (this.followups.length > 0) {
      this.currMedicalObservation.RichText = this.followups[0].RichText
    } else {
      Swal.fire({
        title: "הודעת מערכת",
        text: "לא נמצאו דיווחים קודמים",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    }
  }

  AttachFile() {
    const dialogRef = this.dialog.open(AttachmentDialogComponent, {
      data: {
        UserId: this.LoggedInUser,
        FileCategoryList: this.FileCategoryList,
        Id: this.id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Save Diagnoses
      if (result == undefined || result == null || result == "") return
      this.updateRefreshedObservFiles()
    });
  }

  ShowWebcam() {
    //this.showWebcam = true

    const dialogRef = this.dialog.open(CameraDialogComponent, {
      data: {
        Id: this.id,
        FileCategory:this.FileCategoryList[0].id,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateRefreshedObservFiles()
    });

  }

  modalClosed(isClosed) {
    this.showWebcam = false;
  }

  personelMeasuresChanged($event) {
  } 

  diff_hours(dt2, dt1) {
    //Tobe deleted

    var diff = (dt2.getTime() - (new Date(dt1)).getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));

  }

  public onChange() {
    this.editorChanged = true
  }


  pictureCaptureHandler($event) {
    var date = new Date();
    var fileTitle =
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      ("00" + date.getDate()).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);

    var uuid2 = new Date().getTime().toString() + ".jpeg";
    const doc = new jsPDF();
    doc.text("Hello world!", 10, 10);

    doc.addImage($event._imageAsDataUrl, "JPEG", 10, 15, 190, 250, "alias1", 'SLOW');
    doc.addPage()

    doc.addImage($event._imageAsDataUrl, "JPEG", 10, 15, 190, 250, "alias1", 'SLOW');

    var base = doc.output('datauristring');
      doc.save("a4.pdf");


    this.uploadFile($event._imageAsDataUrl, uuid2, fileTitle)
  }

  uploadFile(fileData: any, fileName: string, fileTitle: string) {
    let newObservationFile = new Attachment()
    newObservationFile.CreatedAt = new Date()
    newObservationFile.PersonelID = this.id
    newObservationFile.CreatedById = this.authServive.LoggedInUser.UID
    newObservationFile.FileDataBase64 = fileData
    newObservationFile.FileName = fileName
    newObservationFile.FileCategory = "medicalobsrvfile"
    this.caretxSharedlService.uploadFile(newObservationFile).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })

  }

  onItemDeleted($event) {
    this.medicalObservationservice.deleteNewMedicalObservationFile($event).subscribe({
      next: data => {
        //newFile.FileUrl = data.FileUrl
        this.medicalObservationFile.splice(this.medicalObservationFile.indexOf($event), 1);
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }
  subject = new Subject<boolean>();
  justRout: boolean
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  setStep(index: number) {
    this.step = index;
  }

  unSavedNewlyDiagnoses($event) {
    this.currMedicalObservation.Diagnoses
  }

  deleteDiagnosesLine($event) {
    this.medicalObservationservice.deleteMeidcalAdmissionDiagnoses(this.currMedicalObservation.ID, this.currMedicalObservation.Diagnoses[$event]).subscribe({
      next: data => {
        this.currMedicalObservation.Diagnoses.splice($event, 1);
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }

  CheckIfDocumentIsDirty() {
    return false
  }

  updatePersonalMedicalMeasures($event) {
    this.currMedicalObservation.PersonalMeasuresHeader.PersonalMeasures = $event
  }

  RefreshPersonalMeasuresHeader($event) {
    this.currMedicalObservation.PersonalMeasuresHeader= $event
  }


  frequentMeasuresChanged($event) {
    this.isfrequentMeasuresDirty = $event
  }

  physicalexaminationIsDirty($event) {
    this.isPhysicalexaminationIsDirty = $event
  }

  updatePhysicalExaminationObject($event) {
    this.currMedicalObservation.PhysicalExamination = $event
  }


  updateRefreshedObservFiles() {
    let filter: string = "file_category = 'medicalobsrvfile'"
    this.caretxSharedlService.getAttachments(this.id, filter).subscribe((data: Attachment[]) => {
      if (data.length > 0) {
        this.currMedicalObservation.MedicalObservationFiles = data
      }
      else {
        this.followups = []
      }
    });
  }



  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  isPdfFile:boolean = false
  file223 :string = ""



  downloadFile(file: Attachment) {
    let extension = (file.FileName).split('.').pop();
    let typeOfBlob = "application/octet-stream"
    switch (extension) {
      case "pdf":
        typeOfBlob = "application/pdf"
          this.isPdfFile = true
        break
      case "jpg":
        typeOfBlob = "image/jpg"
        this.isPdfFile = false
        break
      case "jpeg":
        typeOfBlob = "image/jpeg"
        this.isPdfFile = false
        break
      case "png":
        typeOfBlob = "image/png"
        this.isPdfFile = false
        break
      default:
        break
    }



    this.sharedServices.downloadFile(this.id, file)
      .subscribe(data => {
        var file = new Blob([data], { type: typeOfBlob });
        


        this.pdfContent = URL.createObjectURL(file);
        if (this.isPdfFile){
          this.pdfViewer.pdfSrc = file
          this.showGeneratedPdf = true
          this.pdfViewer.refresh();
        }else{
        }


      }, error => {
        console.log(error);
        var errorAsString = "failed to download file"
        try {
          var enc = new TextDecoder("utf-8")
          var text = enc.decode(error.error)
          errorAsString = JSON.parse(text).error
        } catch (e) {

        }
      })
  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }




/*******************************************************Drug Instructions************************************ */

CurrentSelectedDrugInstr: DrugInstruction
drugInstructionChanged: boolean = false

openSelectedDrugInstr($event) {
  //  this.CurrentStep = MedicalAdmissionSteps.PermenantDrugDetails
  this.step = 2.1
  if ($event == null || $event == undefined) {
    // Do nothing for now
    this.CurrentSelectedDrugInstr = new DrugInstruction(undefined, this.id, this.authServive.LoggedInTenantID)
    this.CurrentSelectedDrugInstr.instrStatus = "D"
    this.CurrentSelectedDrugInstr.Doctor = this.authServive.LoggedInUser
    this.CurrentSelectedDrugInstr.doctorId = this.authServive.LoggedInUser.UID
    this.CurrentSelectedDrugInstr.instructionType = "F"
    this.CurrentSelectedDrugInstr.New = true
    this.isNewRecord = true
  } else {
    let index = $event
    this.CurrentSelectedDrugInstr = this.currMedicalObservation.DrugInstructions[index]
    this.currMedicalObservation.DrugInstructions[index].Modified = true
    this.isNewRecord = false
  }
}

AddNewDrugInstruction($event) {
  this.currMedicalObservation.DrugInstructions.push($event)
  this.CurrentSelectedDrugInstr = $event
}

BackToMainDocument() {
  this.router.navigateByUrl("caretexfile/" + this.id + "/medicaladmissions/list")
}

drugInstrDetailsConfirmedSaveRequest($event) {
}

updateNextTabFromDrugInstr($event) {
}

drugInstructionChangedSub($event) {
  this.drugInstructionChanged = $event
}
backToMainDrugsListSub() {
  this.step = 2
}
/*********************************************************************************************************** */

}
