import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { NextFollowUps } from '../../../models/customer';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { FormControl, FormGroup } from '@angular/forms';
import { CaretxSetupsProviderService } from '../../../../app/caretx-setups-module/setup-services/setups.data-provider.service';
import { Departments } from '../../../../../src/app/models/generalhousedsetups';

@Component({
  selector: 'app-followupsofalldepartments',
  templateUrl: './followupsofalldepartments.component.html',
  styleUrls: ['./followupsofalldepartments.component.css']
})
export class FollowupsofalldepartmentsComponent implements OnInit {

  followUps: NextFollowUps[] = []
  departments: Departments[] 
  totalNumOfRecords: Number = 0
  moduleName:string = "מעקבים מתוכננים"

  RecordStatus =
   [
    {id:"social" , desc:"מעקב סוציאלי"},
    {id:"medical" , desc:"מעקב רפואי"},
    {id:"nurse" , desc:"מעקב סיעודי"},
    {id:"dietician" , desc:"מעקב תזונתי"},
    {id:"occupational" , desc:"מעקב ריפוי בעיסוק"} 
  ]
  formData : any[] 
  TabId = "main"
  searchForm = new FormGroup({

    last_name: new FormControl(''),
    follow_up_type: new FormControl(''), //, [Validators.required, Validators.email]),
    first_name: new FormControl(''),
    department_id: new FormControl(''),
    personel_id: new FormControl(''),
  });

  constructor(private caretxSharedlService: CaretxSharedModulesProviderService, 
    public medicalFileService: MedicalFileProviderService,
    private _title: Title,
    private caretxSetupsProviderService: CaretxSetupsProviderService,
    private toastrService: ToasterService,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this._title.setTitle('Caretex | תכנון מעקבים');
    this.caretxSetupsProviderService.GetHouseDepartments().subscribe(data => {
      this.departments = data
      this.formData=  [
        {"formcontrolname":"department_id","label":"מחלקת אשפוז" ,"type":"multi" , "value":JSON.stringify(this.departments) ,"selectid":"ID" , "selectval":"DepartmentName"},
        {"formcontrolname":"follow_up_type","label":"סוג מעקב" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
        {"formcontrolname":"personel_id","label":"תעודת זהות" ,"type":"txtinput" , "value":"" ,"selectid":"" , "selectval":""},
        {"formcontrolname":"first_name","label":"שם פרטי" ,"type":"txtinput" , "value":"" ,"selectid":"" , "selectval":""},
        {"formcontrolname":"last_name","label":"שם משפחה" ,"type":"txtinput" , "value":"" ,"selectid":"" , "selectval":""},
      ]
    })  
    this.getNextFollowupsFromDB("")
  }


  
  getNextFollowupsFromDB(filter:string) { 
    this.caretxSharedlService.getNextFollowUps(filter).subscribe(
      data => {
                    this.followUps = data
                     this.totalNumOfRecords = data.length
                    },
      err => {
        this.followUps = []
      }
    )
  }

  SearchWithFilter($event){
    this.getNextFollowupsFromDB($event)
  }
  drillToFollowUp(followUp){

  }
}
