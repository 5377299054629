import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NurseAdmissionSteps } from '../../../../../../../../src/app/services/enums';
import { DiabeticFootTest, DiabeticFootTestHeader } from '../../../../../../../../src/app/models/DiabeticFootTest';
import { NgForm } from '@angular/forms';
import { AuthService } from '../../../../../../../../src/app/services/auth.service';
import { CaretxNursingProviderService } from '../../../../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../../../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-diabeticfoottestsubmodel',
  templateUrl: './diabeticfoottestsubmodel.component.html',
  styleUrls: ['./diabeticfoottestsubmodel.component.css']
})
export class DiabeticfoottestsubmodelComponent implements OnInit {


  showGeneratedPdf: boolean = false
  step: number
  isRecordClosed: boolean = false


  @Input() IsSubModule: boolean
  @Input() currentDiabeticTest: DiabeticFootTestHeader = new DiabeticFootTestHeader()
  @Input() OmdanId: string
  @Input() ChangesComittedToDB: boolean
  @Output() backToOmdanemListEvent = new EventEmitter<NurseAdmissionSteps>();
  @Output() DiabeticFootTestEvent = new EventEmitter<DiabeticFootTestHeader>()
  @Output() ModuleChanged = new EventEmitter<boolean>();
  @Output() backToOmdanemList = new EventEmitter<void>();

  currentLeftFeetRow: DiabeticFootTest = new DiabeticFootTest()
  currentRightFeetRow: DiabeticFootTest = new DiabeticFootTest()

  modalTarget: string
  omdanName: string = "diabeticfoot"
  id: string
  isomdanClosed: boolean = false;
  omdanScore: number = 0
  scorepercentage: number = 0
  moduleName: string = "מעקב כף רגל סכרתית"
  @ViewChild('mainform') mainFormElement: NgForm;
  NewRecord:boolean = true

  TreatmentOptions = [
    { id: "O", name: "מבוצע", val: 0 },
    { id: "P", name: "מבוצע חלקי", val: 0 },
    { id: "N", name: "לא מבוצע", val: 0 },
  ];
  HistoryOptions = [
    { id: "Y", name: "כן", val: 0 },
    { id: "N", name: "לא", val: 11 },

  ];
  AmputeeOptions = [
    { id: "N", name: "ללא קטיעת רגליים", val: 0 },
    { id: "R", name: "קטוע רגל ימין", val: 11 },
    { id: "L", name: "קטוע רגל שמאל", val: 11 },
    { id: "B", name: "קטוע 2 רגליים", val: 74 }
  ];
  UlcerOptions = [
    { id: "N", name: "O", val: 1 },
    { id: "A", name: "+", val: 2 },
    { id: "B", name: "++", val: 3 },
    { id: "C", name: "+++", val: 4 }
  ];
  ColorOptions = [
    { id: "O", name: "תקין", val: 1 },
    { id: "B", name: "אודם", val: 2 },
    { id: "C", name: "חיוור", val: 3 },
  ];
  DrynessOptions = [
    { id: "O", name: "תקין", val: 1 },
    { id: "B", name: "קל", val: 2 },
    { id: "C", name: "חמור", val: 3 },
  ];
  CracksOptions = [
    { id: "O", name: "תקין", val: 1 },
    { id: "B", name: "בודדים", val: 2 },
    { id: "C", name: "מרובים", val: 3 },
  ];
  NailsOptions = [
    { id: "O", name: "תקין", val: 1 },
    { id: "B", name: "שינויים קלים", val: 2 },
    { id: "C", name: "שינויים מרובים", val: 3 },
  ];
  HygieneOptions = [
    { id: "O", name: "תקין", val: 1 },
    { id: "B", name: "חלקי", val: 2 },
    { id: "C", name: "חמור", val: 3 },
  ];
  HeatOptions = [
    { id: "O", name: "תקין", val: 1 },
    { id: "B", name: "חם", val: 2 },
    { id: "C", name: "קר", val: 3 },
  ];
  BackShinOptions = [
    { id: "O", name: "הולם", val: 1 },
    { id: "B", name: "מוגבר", val: 2 },
    { id: "C", name: "מופחת,חלש", val: 3 },
    { id: "D", name: "חסר,בלתי ניתן למישוש", val: 4 },
  ];
  FeelingOptions = [
    { id: "O", name: "לא רילוונטי", val: 1 },
    { id: "B", name: "יש", val: 2 },
    { id: "C", name: "חלקי", val: 3 },
    { id: "D", name: "חסר", val: 4 },
  ];
  PainIntensityOptions = [
    { id: "O", name: "לא כאב", val: 1 },
    { id: "B", name: "קל", val: 2 },
    { id: "C", name: "בינוני", val: 3 },
    { id: "D", name: "חזק", val: 4 },
  ];
  DeformationsOptions = [
    { id: "O", name: "תקין", val: 1 },
    { id: "B", name: "קל", val: 2 },
    { id: "C", name: "חמור", val: 3 },
  ];

  constructor(
    private authService: AuthService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToasterService,) {

  }

  ngOnInit(): void {
    if (this.route.parent != null)
      this.id = this.route.parent.snapshot.params['id']

    if (this.route.snapshot.params.diabetictestid != "new" && !this.IsSubModule)
      this.getOmdanFromDB(this.route.snapshot.params.diabetictestid)
    else if (this.IsSubModule) {
      if (this.OmdanId == null || this.OmdanId == "" || this.OmdanId == "00000000-0000-0000-0000-000000000000") {
        this.newOmdan("nurseadmission")
      } else {
        this.getOmdanFromDB(this.OmdanId)
      }
    } else {
      this.newOmdan("")
    }

  }

  newOmdan(origin: string) {
    this.currentDiabeticTest = new DiabeticFootTestHeader()
    let dateOfNow: Date = new Date();
    this.NewRecord = true
    this.currentDiabeticTest.CreatedAt = dateOfNow
    this.currentDiabeticTest.UpdatedAt = dateOfNow
    this.currentDiabeticTest.Status = "A"
    this.currentDiabeticTest.PersonelID = this.id
    this.currentDiabeticTest.Origin = origin
    this.currentDiabeticTest.TenantID = this.authService.LoggedInTenantID
    this.currentDiabeticTest.DiabeticFootTests = []
    this.DiabeticFootTestEvent.emit(this.currentDiabeticTest)
  }

  /*********************Retrieve And Save Data section *********************** */

  getOmdanFromDB(omdanId: string) {
    let filter = '(id=' + '\'' + omdanId + '\'' + ')'
    this.caretxNursingProviderService.getDiabeticFootTests(this.id, filter).subscribe((data: DiabeticFootTestHeader[]) => {
      if (data.length == 1) {
        this.currentDiabeticTest = data[0]
        this.NewRecord = false
        this.currentDiabeticTest.DiabeticFootTests.forEach(row => {
          if (row.FootSide == "L") this.currentLeftFeetRow = row
          if (row.FootSide == "R") this.currentRightFeetRow = row
        });
        this.isomdanClosed = (this.currentDiabeticTest.Status == "C")
        this.omdanScore = this.currentDiabeticTest.Score
        this.scorepercentage = Math.round(((this.omdanScore * 100) / 74))
        this.DiabeticFootTestEvent.emit(this.currentDiabeticTest)
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }

  SaveOmdan() {
    if (this.currentDiabeticTest.ID == "" || this.currentDiabeticTest.ID == null || this.currentDiabeticTest.ID == undefined) {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    this.caretxNursingProviderService.CreateDiabeticFootTest(this.currentDiabeticTest).subscribe({
      next: data => {
        this.currentDiabeticTest = (data as DiabeticFootTestHeader)
        this.isomdanClosed = (this.currentDiabeticTest.Status == "C")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.calculateScore()
        if (!this.IsSubModule) {
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/diabetictests/" + this.currentDiabeticTest.ID)
        }
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  DoUpdate() {
    this.caretxNursingProviderService.UpdateDiabeticFootTest(this.currentDiabeticTest).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentDiabeticTest = data as DiabeticFootTestHeader
        if (this.currentDiabeticTest.Status == 'C')
          this.isomdanClosed = true
        this.calculateScore()
        if (!this.IsSubModule) {
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/diabetictests/" + this.currentDiabeticTest.ID)
        }
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  /************************** Data change event ********************************* */

  newDiabeticFootTestRow(side: string) {
    let row : DiabeticFootTest  = new DiabeticFootTest()
    row.TenantID = this.authService.LoggedInTenantID
    row.CreatedAt = new Date()
    row.UpdatedAt = row.CreatedAt
    row.PersonelID = this.id
    row.FootSide = side
    return row
  }

  onChangeAmputee($event) {
    this.currentDiabeticTest.DiabeticFootTests = []
    switch ($event) {
      case "L":
        this.currentDiabeticTest.DiabeticFootTests.push(this.newDiabeticFootTestRow("R"))
        this.currentRightFeetRow = this.currentDiabeticTest.DiabeticFootTests[0]
        break
      case "R":
        this.currentDiabeticTest.DiabeticFootTests.push(this.newDiabeticFootTestRow("L"))
        this.currentLeftFeetRow = this.currentDiabeticTest.DiabeticFootTests[0]
        break
      case "N":
        this.currentDiabeticTest.DiabeticFootTests.push(this.newDiabeticFootTestRow("R"))
        this.currentRightFeetRow = this.currentDiabeticTest.DiabeticFootTests[0]
        this.currentDiabeticTest.DiabeticFootTests.push(this.newDiabeticFootTestRow("L"))
        this.currentLeftFeetRow = this.currentDiabeticTest.DiabeticFootTests[1]
        break
      case "B":

        break
    }
    this.notifyParentModule()
  }

  onChange($event) {
  this.calculateScore()
  
    // Synch To Parent . 
  //  this.notifyParentModule()
  }

  notifyParentModule() {
    this.DiabeticFootTestEvent.emit(this.currentDiabeticTest)
    this.ModuleChanged.emit(this.mainFormElement.dirty == true ? true : false)
  }

  calculateScore() {
    let totalScore = 0

    if (this.currentDiabeticTest.Amputee == "B") return 74
    if (this.currentDiabeticTest.Amputee == "N") totalScore = 0
    if (this.currentDiabeticTest.Amputee == "L") totalScore = 11
    if (this.currentDiabeticTest.Amputee == "R") totalScore = 11
 

    let HistoryOptionVal = this.HistoryOptions.find((element) => {
      return element.id === this.currentDiabeticTest.HasUlcerHistory
    });
    if(HistoryOptionVal != undefined)
       totalScore+=HistoryOptionVal.val

    this.currentDiabeticTest.DiabeticFootTests.forEach(row => {

      let UlcerOptionsVal = this.UlcerOptions.find((option) => {
        return option.id === row.HasEdema
      });
      if(UlcerOptionsVal != undefined)
      totalScore+=UlcerOptionsVal.val

      let ColorOptionsVal = this.ColorOptions.find((option) => {
        return option.id === row.HasColor
      });
      if(ColorOptionsVal != undefined)
      totalScore+=ColorOptionsVal.val

      let DrynessOptionsVal = this.DrynessOptions.find((option) => {
        return option.id === row.Dryness
      });
      if(DrynessOptionsVal != undefined)
      totalScore+=DrynessOptionsVal.val

      let CracksOptionsVal = this.CracksOptions.find((option) => {
        return option.id === row.HasCracks
      });
      if(CracksOptionsVal != undefined)
      totalScore+=CracksOptionsVal.val

      let NailsOptionsVal = this.NailsOptions.find((option) => {
        return option.id === row.NailCondition
      });
      if(NailsOptionsVal != undefined)
      totalScore+=NailsOptionsVal.val

      let HeatOptionsVal = this.HeatOptions.find((option) => {
        return option.id === row.Heat
      });
      if(HeatOptionsVal != undefined)
      totalScore+=HeatOptionsVal.val

      let HygieneOptionsVal = this.HygieneOptions.find((option) => {
        return option.id === row.Hygiene
      });
      if(HygieneOptionsVal != undefined)
      totalScore+=HygieneOptionsVal.val

      let BackShinOptionsVal = this.BackShinOptions.find((option) => {
        return option.id === row.BackShin
      });
      if(BackShinOptionsVal != undefined)
      totalScore+=BackShinOptionsVal.val

      let FeelingOptionsVal = this.FeelingOptions.find((option) => {
        return option.id === row.Feel
      });
      if(FeelingOptionsVal != undefined)
      totalScore+=FeelingOptionsVal.val

      let PainIntensityOptionsVal = this.PainIntensityOptions.find((option) => {
        return option.id === row.PainIntensity
      });
      if(PainIntensityOptionsVal != undefined)
      totalScore+=PainIntensityOptionsVal.val

      let DeformationsOptionsVal = this.DeformationsOptions.find((option) => {
        return option.id === row.Deformations
      });
      if(DeformationsOptionsVal != undefined)
      totalScore+=DeformationsOptionsVal.val
  });
   this.currentDiabeticTest.Score = totalScore
  }

  /**********************************Close Omdan Section ****************************** */
  CloseOmdan() {
    if (!this.checkIfAllQuestionsAnswered()) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "לא עניתה על כל השאלות ?",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    }
    else {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה לנעול מבחן זה ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תנעל',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentDiabeticTest.Status = "C"
          this.currentDiabeticTest.ClosedById = this.authService.LoggedInUser.UID
          this.currentDiabeticTest.ClosedAt = new Date()
          this.SaveOmdan()
        }
      })
    }
  }

  checkIfAllQuestionsAnswered() {
    // Check Left And Right Foot Row
    for (let i = 0; i < this.currentDiabeticTest.DiabeticFootTests.length; i++) {
      if (this.currentDiabeticTest.DiabeticFootTests[i].BackShin == "" || this.currentDiabeticTest.DiabeticFootTests[i].BackShin == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].Dryness == "" || this.currentDiabeticTest.DiabeticFootTests[i].Dryness == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].Feel == "" || this.currentDiabeticTest.DiabeticFootTests[i].Feel == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].HasColor == "" || this.currentDiabeticTest.DiabeticFootTests[i].HasColor == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].HasCracks == "" || this.currentDiabeticTest.DiabeticFootTests[i].HasCracks == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].HasEdema == "" || this.currentDiabeticTest.DiabeticFootTests[i].HasEdema == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].Heat == "" || this.currentDiabeticTest.DiabeticFootTests[i].Heat == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].Hygiene == "" || this.currentDiabeticTest.DiabeticFootTests[i].Hygiene == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].NailCondition == "" || this.currentDiabeticTest.DiabeticFootTests[i].NailCondition == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].PainIntensity == "" || this.currentDiabeticTest.DiabeticFootTests[i].PainIntensity == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp1 == "" || this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp1 == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp2 == "" || this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp2 == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp3 == "" || this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp3 == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp4 == "" || this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp4 == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp5 == "" || this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp5 == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp6 == "" || this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp6 == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp7 == "" || this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp7 == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp8 == "" || this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp8 == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp9 == "" || this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp9 == undefined) return false
      if (this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp10 == "" || this.currentDiabeticTest.DiabeticFootTests[i].PreventiveOp10 == undefined) return false
    }
    return true
  }

  /**********************Return to Main List section ******************************** */
  ReturnToList() {
    if (this.IsSubModule)
      this.backToOmdanemListEvent.emit(NurseAdmissionSteps.Tests)
    else {
      this.router.navigateByUrl("caretexfile/" + this.id + "/omdanem/diabetictests/list")
    }
  }

  BackToMainList_Sub() {
    this.backToOmdanemList.emit()
  }

  /******************************Report******************************************* */
  closePdfDocument() { }

} 
