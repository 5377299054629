import { RouterModule, Routes } from "@angular/router";
import { NgModule } from '@angular/core';

import { AuthGuard } from "./shared/guard/auth.guard";

import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignInVerifyComponent } from './components/sign-in-verify/sign-in-verify.component';
import { CallbackComponent } from './components/callback/callback.component';
import { NurseadmissionComponent } from "./caretx-nusrsing-module/Components/nursingadmissionlist/nurseadmission/nurseadmission.component";

const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent },
  { path: 'verify-sign-in', component: SignInVerifyComponent },

  { path: 'clients/:id/nurseadmission/:admissionid', component: NurseadmissionComponent, canActivate: [AuthGuard] },
  { path: 'clients/:id/newNurseAdmission', component: NurseadmissionComponent, canActivate: [AuthGuard] },

  /* attachments */
  { path: 'callback', component: CallbackComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
