import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ctx-textarea',
  templateUrl: './ctx-textarea.component.html',
  styleUrls: ['./ctx-textarea.component.scss']
})
export class CtxTextareaComponent implements OnInit {
  @Input() data: any = {};
  @Input() IsDisabled: boolean;
  @Output() onTyping =  new EventEmitter();
  @Output() closeModalEvent =  new EventEmitter();

  hideModal:boolean = true;
  constructor() { }

  ngOnInit(): void {

  }

  syncInputValue(value) {
    this.onTyping.emit(value);
  }

  closeModal() {
    this.hideModal = true;
    this.closeModalEvent.emit();
  }

}
