import {Injectable}  from '@angular/core'
declare  var toastr:any


@Injectable()
export class ToasterService{
    public successMsg :string = "נתונים נשמרו בהצלחה"
    public errorMsg :string = "הפעולה נכשלה, שמירת נתונים נכשלה"
    constructor(){
        this.settings()
    }

    Sucesss(title:string ,  message?:string){
        toastr.success(title,message)
    }

    Warnning(title:string ,  message?:string){
        toastr.warnning(title,message)
    }

    Error(title:string ,  message?:string){
        toastr.error(title,message)
    }

    Info(message?:string){
        toastr.info(message)
    }


    settings(){
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-left",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": "300000",
            "hideDuration": "1000",
            "timeOut": "5000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
          }
    }

}