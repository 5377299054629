import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxNursingProviderService } from '../../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { CaretxSetupsProviderService } from '../../../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { NurseExamination } from '../../../../../../src/app/models/nurseexamination';
import { AuthService } from '../../../../../../src/app/services/auth.service';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { CartexEvent } from '../../../../../../src/app/models/genericTypes';

@Component({
  selector: 'app-nursuryphysicalexamination',
  templateUrl: './nursuryphysicalexamination.component.html',
  styleUrls: ['./nursuryphysicalexamination.component.css']
})
export class NursuryphysicalexaminationComponent extends CaretexComponent implements OnInit {

  isCheckClosed: boolean
  currentPhysicalExamination: NurseExamination
  id: string
  eventsSubject: Subject<CartexEvent> = new Subject<CartexEvent>();
  modulePhysicalExaminationchanged: boolean = false
  moduleName: string = "בדיקה גופנית"
  constructor(private caretxSetupService: CaretxSetupsProviderService,
    private authServive: AuthService,
    public medicalFileService: MedicalFileProviderService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToasterService,) { super(medicalFileService) }

  ngOnInit(): void {
    if (this.route.parent != null)
      this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)

    if (this.route.snapshot.params.checkid == 'new') {
      this.currentPhysicalExamination = new NurseExamination()
      this.currentPhysicalExamination.CreatedAt = new Date()
      this.currentPhysicalExamination.Status = "A"
      this.currentPhysicalExamination.PersonelID = this.id
      this.currentPhysicalExamination.RealmID = this.authServive.LoggedInTenantID
    }
    else {
      this.getExaminationById(this.route.snapshot.params.checkid)
    }
  }

  getExaminationById(checkId: string) {
    let filter = '(id=' + '\'' + checkId + '\'' + ')'
    this.caretxNursingProviderService.getAllNurseExaminations(this.id, filter).subscribe((data: NurseExamination[]) => {
      if (data.length == 1) {
        this.currentPhysicalExamination = data[0]
        this.isCheckClosed = (this.currentPhysicalExamination.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }

  TriggerSave($event) {
    this.SaveNursuryCheck()
  }

  SaveNursuryCheck() {
    if (this.currentPhysicalExamination.ID == "" || this.currentPhysicalExamination.ID == null) {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    this.currentPhysicalExamination.PersonelID = this.id

    this.caretxNursingProviderService.createNurseExamination(this.currentPhysicalExamination).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentPhysicalExamination = data as NurseExamination
        if (this.currentPhysicalExamination.Status == "C")
          this.isCheckClosed = true
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/physicalnursecheck/" + this.currentPhysicalExamination.ID)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  DoUpdate() {
    this.caretxNursingProviderService.updateNurseExamination(this.currentPhysicalExamination).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentPhysicalExamination = data as NurseExamination
        if (this.currentPhysicalExamination.Status == "C")
          this.isCheckClosed = true

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/physicalnursecheck/" + this.currentPhysicalExamination.ID)

      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  backToNursuryCheckList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/physicalnursecheck")
  }

  LockNursuryCheck() {
    Swal.fire({
      title: 'לידיעתך',
      text: "האם את/ה רוצה לנעול בדיקה ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תנעל בדיקה',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.currentPhysicalExamination.Status = "C"
        this.currentPhysicalExamination.LockedById = this.authServive.LoggedInUser.UID
        this.currentPhysicalExamination.LockedDate = new Date()
        this.SaveNursuryCheck()
      }
    })
  }

  updatePhysicalCheck($event) {

  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.modulePhysicalExaminationchanged
  }

  ModuledChanged($event) {
    this.modulePhysicalExaminationchanged = $event
  }

}
