import { DatePipe, Time } from '@angular/common';
import { Attachment } from './attachments';
import { Departments, Funder, NursingStatus, Referrer } from './generalhousedsetups';
import { propertyMap } from './modelmapper';
import { MedicalObservationFile } from './Observations';
import { PaitentFamily } from './socialregistration';
import { User } from './user';
import { MedicalAdmission } from './medicaladmission';
import { MedicalRelease } from './MedicalRelease';
import { NurseAdmission } from './nurseadmission';
import { NurseRelease } from './nurserelease';
import { SocialAdmission } from './socialadmission';
import { SocialRelease } from './socialrelease';


export interface TeekIDrugnstruction {

	TeekId: string,
	InstructionId: string,
	DrugId: string,
	Doctor: string,
	TreatmentType: string,
	DateOfInstruction: Date,
	TimeOfInstruction: Time,
	IsForMornning: boolean,
	TimInMornning: Time,
	IsForMidDay: boolean,
	TimInMidDay: Time,
	IsForEvenning: boolean,
	TimInEvenning: Time,
	IsForNight: boolean,
	TimInNight: Time,
	DosageForm: string,
	CancelReason: string,
	Status: string,
	DrugEngName: string,
	DrugHebName: string,
	DrugInstruction: string,
	// Freque
	DrugsToTake: number,
	DrugFreq: number,
	FreqType: string,
	EveryDays: number,
	NumOfDays: number,
	MaxQty: number,
	OnceInXDays: number,
}

export interface TmesaRecord {
	RecordId: number,
	TeekId: string,
	InstructionId: string,
	DrugId: string,
	DrugName: string,
	volume: number
}


export class MedicalFile {
	@propertyMap('PersonelID')
	public PersonelID: string;
	@propertyMap('FirstName')
	public FirstName: string;
	@propertyMap('LastName')
	public LastName: string;
	@propertyMap('Email')
	public Email: string;
	@propertyMap('Phone')
	public Phone: string;
	@propertyMap('Phone2')
	public Phone2: string;
	@propertyMap('JoinedAt')
	public JoinedAt: Date;
	@propertyMap('ReleasedAt')
	public ReleasedAt: Date;
	@propertyMap('BirthDay')
	public BirthDay: Date;
	@propertyMap('Guardian')
	public Guardian: string;
	@propertyMap('GuardianName')
	public GuardianName: String;
	@propertyMap('GuardianPhone')
	public GuardianPhone: string;
	@propertyMap('Address')
	public Address: string;
	@propertyMap('Gender')
	public Gender: string;
	@propertyMap('HMO')
	public HMO: string;
	@propertyMap('GuardianPhone2')
	public GuardianPhone2: string;
	@propertyMap('GuardianAddress')
	public GuardianAddress: string;
	@propertyMap('DepartmentId')
	public DepartmentId: string;
	@propertyMap('DepartmentAcceptanceDate')
	public DepartmentAcceptanceDate: Date;
	@propertyMap('StatusId')
	public StatusId: string;
	@propertyMap('FunderId')
	public FunderId: string;
	@propertyMap('Department')
	public Department: Departments
	@propertyMap('Funder')
	public Funder: Funder
	@propertyMap('Status')
	public Status: NursingStatus
	@propertyMap('LastUpdatedById')
	LastUpdatedById: string
	LastUpdatedBy: User
	@propertyMap('LastUpdated_at')
	LastUpdated_at: Date
	@propertyMap('PersonalStatus')
	PersonalStatus: string
	@propertyMap('NumOfChildren')
	NumOfChildren: number
	@propertyMap('FatherName')
	FatherName: string
	@propertyMap('MotherName')
	MotherName: number
	@propertyMap('OriginContry')
	OriginContry: string
	@propertyMap('MigrationDate')
	MigrationDate: Date
	@propertyMap('IsHazard')
	IsHazard: boolean
	@propertyMap('IsNusrsingReleased')
	IsNusrsingReleased: boolean
	@propertyMap('IsMedicalReleased')
	IsMedicalReleased: boolean
	@propertyMap('IsSocialReleased')
	IsSocialReleased: boolean
	@propertyMap('IsPhysiotherapyReleased')
	IsPhysiotherapyReleased: boolean
	@propertyMap('IsArchived')
	IsArchived: boolean
	@propertyMap('IsOccupationTherapyReleased')
	IsOccupationTherapyReleased: boolean

	@propertyMap('hospoitlizeddate')
	hospoitlizeddate: Date
	@propertyMap('Language')
	Language: string
	@propertyMap('Education')
	Education: string
	@propertyMap('arraivedFrom')
	arraivedFrom: string
	@propertyMap('Prevjob')
	Prevjob: string
	@propertyMap('hodpitlizereason')
	hodpitlizereason: string
	@propertyMap('Family')
    public Family: PaitentFamily[]
	@propertyMap('IsNusrsingAdmissioned')
	IsNusrsingAdmissioned: boolean
	@propertyMap('IsMedicalAdmissioned')
	IsMedicalAdmissioned: boolean
	@propertyMap('IsSocialAdmissioned')
	IsSocialAdmissioned: boolean
	@propertyMap('ImagePath')
	ImagePath: string 

	@propertyMap('HasElevator')
	HasElevator: boolean
	@propertyMap('DepartmentNo')
	DepartmentNo: string 
	@propertyMap('City')
	City: string 
	@propertyMap('Zip')
	Zip: string 
	@propertyMap('Floor')
	Floor: string 
	@propertyMap('NoOfStairs')
	NoOfStairs: number 
	@propertyMap('HouseOwner')
	HouseOwner: string 
	@propertyMap('SupplementaryInsurance')
	SupplementaryInsurance: boolean
	@propertyMap('HolocaustSurvivor')
	HolocaustSurvivor: boolean
	@propertyMap('Religion')
	Religion: string 
	@propertyMap('ReferrerId')
	public ReferrerId: string;
	@propertyMap('Referrer')
	public Referrer: Referrer
	@propertyMap('HospitalName')
	public HospitalName: string
	@propertyMap('Allergy')
	public Allergy: string
	@propertyMap('HasAllergy')
	public HasAllergy: boolean
	@propertyMap('AttachedFiles')
	public AttachedFiles: Attachment[]
	public FullName: string

	@propertyMap('PhotoNotExists')
	public PhotoNotExists: boolean

	@propertyMap('IsHospitalized')
	public IsHospitalized: boolean
	@propertyMap('ReturnDateToHouse')
	public ReturnDateToHouse: boolean

	
	


	constructor(medFielFromJson?: MedicalFile) {

		if (medFielFromJson != null) {
			this.IsHospitalized = medFielFromJson.IsHospitalized
			this.ReturnDateToHouse = medFielFromJson.ReturnDateToHouse
			this.IsNusrsingAdmissioned = medFielFromJson.IsNusrsingAdmissioned
			this.IsMedicalAdmissioned = medFielFromJson.IsMedicalAdmissioned
			this.IsSocialAdmissioned = medFielFromJson.IsSocialAdmissioned
			this.HasElevator = medFielFromJson.HasElevator
			this.Address = medFielFromJson.Address
			this.BirthDay = medFielFromJson.BirthDay
			this.Email = medFielFromJson.Email
			this.FirstName = medFielFromJson.FirstName
			this.Gender = medFielFromJson.Gender
			this.Guardian = medFielFromJson.Guardian
			this.GuardianName = medFielFromJson.GuardianName
			this.GuardianPhone = medFielFromJson.GuardianPhone
			this.HMO = medFielFromJson.HMO
			this.JoinedAt = medFielFromJson.JoinedAt
			this.LastName = medFielFromJson.LastName
			this.PersonelID = medFielFromJson.PersonelID
			this.Phone = medFielFromJson.Phone
			this.Phone2 = medFielFromJson.Phone2
			this.ReleasedAt = medFielFromJson.ReleasedAt
			this.IsHazard = medFielFromJson.IsHazard // need to be chnaged
			this.GuardianPhone2 = medFielFromJson.GuardianPhone2
			this.GuardianAddress = medFielFromJson.GuardianAddress
			this.StatusId = medFielFromJson.StatusId
			this.FunderId = medFielFromJson.FunderId
			this.DepartmentAcceptanceDate = medFielFromJson.DepartmentAcceptanceDate
			this.DepartmentId = medFielFromJson.DepartmentId
			this.Funder = medFielFromJson.Funder
			this.Department = medFielFromJson.Department
			this.Status = medFielFromJson.Status
			this.LastUpdatedById = medFielFromJson.LastUpdatedById
			this.LastUpdatedBy = medFielFromJson.LastUpdatedBy
			this.LastUpdated_at = medFielFromJson.LastUpdated_at
			this.MigrationDate = medFielFromJson.MigrationDate
			this.NumOfChildren = medFielFromJson.NumOfChildren
			this.OriginContry = medFielFromJson.OriginContry
			this.FatherName = medFielFromJson.FatherName
			this.MotherName = medFielFromJson.MotherName
			this.PersonalStatus = medFielFromJson.PersonalStatus
			this.IsArchived = medFielFromJson.IsArchived
			this.IsMedicalReleased = medFielFromJson.IsMedicalReleased
			this.IsNusrsingReleased = medFielFromJson.IsNusrsingReleased
			this.IsSocialReleased = medFielFromJson.IsSocialReleased
			this.hospoitlizeddate = medFielFromJson.hospoitlizeddate
			this.Language = medFielFromJson.Language
			this.Education = medFielFromJson.Education
			this.Prevjob = medFielFromJson.Prevjob
			this.arraivedFrom = medFielFromJson.arraivedFrom
			this.hodpitlizereason = medFielFromJson.hodpitlizereason
			this.Family = medFielFromJson.Family
			this.ImagePath = medFielFromJson.ImagePath
			this.DepartmentNo = medFielFromJson.DepartmentNo
			this.HolocaustSurvivor = medFielFromJson.HolocaustSurvivor
			this.City = medFielFromJson.City
			this.Zip = medFielFromJson.Zip
			this.Floor = medFielFromJson.Floor
			this.NoOfStairs = medFielFromJson.NoOfStairs
			this.HouseOwner = medFielFromJson.HouseOwner
			this.SupplementaryInsurance = medFielFromJson.SupplementaryInsurance
			this.IsOccupationTherapyReleased = medFielFromJson.IsOccupationTherapyReleased
			this.Religion = medFielFromJson.Religion
			this.Referrer = medFielFromJson.Referrer
			this.ReferrerId = medFielFromJson.ReferrerId
			this.HospitalName = medFielFromJson.HospitalName
			this.HasAllergy = medFielFromJson.HasAllergy
			this.Allergy = medFielFromJson.Allergy
			this.AttachedFiles = medFielFromJson.AttachedFiles
			this.FullName = this.FirstName + " " + this.LastName
			this.PhotoNotExists = medFielFromJson.PhotoNotExists
			if (this.PhotoNotExists) {
				this.ImagePath =  "../assets/images/user-avatar.png"
			}
		}

		else {
			this.Department =  new Departments()
			this.Referrer =  new Referrer()
			this.AttachedFiles = []
		}
	}

	calculateAge(): string {

		let date2: any = new Date()
		if (this.BirthDay == undefined || this.BirthDay == null ){
				return "-"
		}
		
		let date1: any = new Date(this.BirthDay)

		const diffInMs = Math.abs(date2 - date1);
		return (diffInMs / (1000 * 60 * 60 * 24) / 365).toPrecision(3);
	}

	buildChangeMsg() {
		if (this.LastUpdatedBy == null || this.LastUpdatedBy == undefined) return ""

		let datepipe = new DatePipe('en-US')
		let updatedDate = datepipe.transform(this.LastUpdated_at, 'dd/MM/yyyy');
		let updatedTime = datepipe.transform(this.LastUpdated_at, 'HH:mm');

		return "עודכן עי " + this.LastUpdatedBy.displayName + " בתאריך " + updatedDate + " בשעה " + updatedTime
	}



}



export class NextFollowUps {
	@propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('RealmID')
    public TenantID: string
    @propertyMap('TenantID')
    public PersonelID: string
	@propertyMap('LastFollowUp')
    public LastFollowUp: Date
	@propertyMap('NextFollowUp')
    public NextFollowUp: Date
	@propertyMap('CompletedFollowUpAt')
    public CompletedFollowUpAt: Date
	@propertyMap('FollowUpType')
    public FollowUpType: string
	@propertyMap('LastFollowUpById')
    public LastFollowUpById: string
	@propertyMap('DepartmentId')
    public DepartmentId: string
	@propertyMap('Department')
    public Department: Departments
	@propertyMap('PersonDoc')
    public PersonDoc: MedicalFile
}


export class ArchiveAlert {
    @propertyMap('RealmID')
    public RealmID: string
    @propertyMap('PersonelID')
    public PersonelID: string
	@propertyMap('AlertTxt')
    public AlertTxt: string
	@propertyMap('RecordId')
    public RecordId: string
	@propertyMap('RecordType')
    public RecordType: string

	@propertyMap('MedicalAdmission')
    public MedicalAdmission: MedicalAdmission
	@propertyMap('MedicalRelease')
    public MedicalRelease: MedicalRelease
	@propertyMap('NurseAdmission')
    public NurseAdmission: NurseAdmission
	@propertyMap('NurseRelease')
    public NurseRelease: NurseRelease
	@propertyMap('SocialAdmission')
    public SocialAdmission: SocialAdmission
	@propertyMap('SocialRelease')
    public SocialRelease: SocialRelease




}


