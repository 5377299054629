import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxSetupsProviderService } from '../../../../src/app/caretx-setups-module/setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from '../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { Departments } from '../../../../src/app/models/generalhousedsetups';
import { SocialRelease } from '../../../../src/app/models/socialrelease';
import { AuthService } from '../../../../src/app/services/auth.service';
import { SocialAdmissionSteps } from '../../../../src/app/services/enums';
import { ToasterService } from '../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSocialProviderService } from '../Social.data-provider.service';
import { CaretexComponent } from '../../../../src/app/shared/CaretexComponent';

@Component({
  selector: 'app-socialrelease',
  templateUrl: './socialrelease.component.html',
  styleUrls: ['./socialrelease.component.css']
})
export class SocialreleaseComponent extends CaretexComponent implements OnInit {
  @ViewChild('mainform') mainFormElement: NgForm;
  //canSocialPagesBeEditted: boolean
  isrecordClosed: boolean = false
  CurrentStep: SocialAdmissionSteps = SocialAdmissionSteps.History
  currentSocialRelease: SocialRelease
  id: string
  Releaseid: string
  departments: Departments[] = []
  moduleName:string="מכתב שחרור"
  step: number = 0


  constructor(
    private _title: Title,
    private router: Router,
    private route: ActivatedRoute,
    public medicalFileService: MedicalFileProviderService,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private caretxSetupsProviderService: CaretxSetupsProviderService) {
      super(medicalFileService)
    this.caretxSetupsProviderService.GetHouseDepartments().subscribe((data => {
      this.departments = data
    }))

  }

  ngOnInit(): void {
    this._title.setTitle('Caretex |שחרור ממחלקת סוציאלית');
    this.id = this.route.parent.snapshot.params['id'];
    this.Releaseid = this.route.snapshot.params.releaseid
    this.CheckIfComponentIsDisabled(this.id) 
    if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
      this.step = Number(this.route.snapshot.params['stepid']); 
    }


    
    this.getSocialReleaseFromDB()
  }

  getSocialReleaseFromDB() {
    let filter = ""
    if (this.Releaseid != 'new')
      filter = '(id=' + '\'' + this.Releaseid + '\'' + ')'
    this.caretxSocialProviderService.getSocialRelease(this.id, filter).subscribe((data: SocialRelease[]) => {
      if (data.length == 1) {
        this.currentSocialRelease = new SocialRelease(data[0])
        if (this.Releaseid == 'new') {
          this.currentSocialRelease.CreatedById = this.authServive.LoggedInUser.UID
          this.currentSocialRelease.CreatedAt = new Date()
        }
        if (this.currentSocialRelease.Status == "C") {
          this.isrecordClosed = true
        }else
        this.isrecordClosed = false
      }
      else if (data.length == 0) {
        let errorMsg = "המערכת לא הצליחה לייצר רשומה חדשה"
        this.toastrService.Error("", errorMsg)
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }



  setStep(index: number) {
    this.step = index;
  }



  BackToList() {
    this.router.navigateByUrl("/caretexfile/" + this.id + "/socialrelease")
  }

  LockDocument() {
    if (this.currentSocialRelease.Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה לנעול רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תנעל',
        allowOutsideClick: false,
        allowEscapeKey: false,       
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DoLock()
        }
      })
    }
  }

  DoLock() {
    this.currentSocialRelease.Status = "C"
    this.currentSocialRelease.ClosedById = this.authServive.LoggedInUser.UID
    this.currentSocialRelease.Closed_at = new Date()
    this.DoUpdate()

  }


  Save() {
    if (this.Releaseid == 'new') {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }

  }

  DoSave() {
    this.currentSocialRelease.UpdatedAt = new Date()
    this.currentSocialRelease.CreatedAt = this.currentSocialRelease.UpdatedAt
    this.currentSocialRelease.CreatedById = this.authServive.LoggedInUser.UID
    this.currentSocialRelease.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.createSocialRelease(this.currentSocialRelease).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentSocialRelease = new SocialRelease(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialrelease/" + this.currentSocialRelease.ID +"/step/" + this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    this.currentSocialRelease.UpdatedAt = new Date()
    this.currentSocialRelease.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.UpdateSocialRelease(this.currentSocialRelease).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentSocialRelease = new SocialRelease(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialrelease/" + this.currentSocialRelease.ID +"/step/" + this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return this.mainFormElement.dirty ==null?false:this.mainFormElement.dirty;
  }
}
