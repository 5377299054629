import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, of, pipe, throwError } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { catchError, share } from 'rxjs/operators';
import { MedicalDiagnoses } from 'src/app/models/drug';
import { Diagnoses } from 'src/app/models/diagnoses';
import { ModelMapper } from 'src/app/models/modelmapper';


@Injectable()
export class MedicalDiagnosesProviderService {
    public  medicalDiagnoses: MedicalDiagnoses[] = []

    constructor(private httpClient: HttpClient) {
        console.log("New Instance is created")
    }


    fetchDiagnoses(): Observable<MedicalDiagnoses[]> {

        if (this.medicalDiagnoses && this.medicalDiagnoses.length) {
            return of(this.medicalDiagnoses).pipe(
                share()
            )
        }
        else {
            this.medicalDiagnoses  = []
            return this.httpClient.get<MedicalDiagnoses[]>(environment.gateway + '/diagnoses')
                .pipe(
                    map((response: any) => {
                        return response.map(item => {
                            return this.mapCachedMedicalDiagnoses(item)
                        })
                    }), catchError(MedicalDiagnosesProviderService.handleError),
                    share());
        }

    }

    private mapCachedMedicalDiagnoses(body: any) {

          //  return new MedicalDiagnoses(body)
       // this.medicalDiagnosesCache[body.id] = new MedicalDiagnoses(body);
        //return this.medicalDiagnosesCache[body.id];

        this.medicalDiagnoses.push(new MedicalDiagnoses(body))
    }


    public  serachDiagnoses(text :string) {
        let  index_occurrence_of_X :number
        let  left :number
        let  right :number

        let searchedTxt = text.toLowerCase().trim()

          index_occurrence_of_X = left = right = this.binarySearch(searchedTxt);

           
          if(index_occurrence_of_X == -1 ) return []
          //Left Side
          while(left-1 >= 0 && this.medicalDiagnoses[left-1].ShortDescription.trim().toLowerCase().startsWith(searchedTxt))
            left--

        // Right Side

        while(right+1 <= this.medicalDiagnoses.length-1 && this.medicalDiagnoses[right + 1].ShortDescription.trim().toLowerCase().startsWith(searchedTxt))
           right++;


        return this.medicalDiagnoses.slice(left , right+1)
    }


    deletePeronelDiagnoses(personelId: string , diagnosesId:string) {
      return this.httpClient.delete(environment.gateway + '/personaldiagnoses/' + personelId + '/diagnoses/'+ diagnosesId)
    }
    
    createPersonelDiagnoses(personelId:string ,newPersonelDiagnoses: Diagnoses[]) {
      return this.httpClient.post(environment.gateway + '/personaldiagnoses/' + personelId , newPersonelDiagnoses)
    }
    

    createPersonelDiagnosesByAdmission(personelId:string,admissionid:string  ,newPersonelDiagnoses: Diagnoses[]) {
      return this.httpClient.post(environment.gateway + '/personaldiagnoses/' + personelId + '/admission/'+ admissionid, newPersonelDiagnoses)
    }
    





    getPersonelDiagnoses(id: string): Observable<Diagnoses[]> {
      return this.httpClient.get(environment.gateway + '/personaldiagnoses/' +  id   ).pipe(
        map((response: any) => {
          return response.map(item => {
            return new ModelMapper(Diagnoses).map(item)
          })
        }), catchError(this.errorHandler));
      }
  


    binarySearch(searchedTxt: string): number {
        let low = 0;
        let high = this.medicalDiagnoses.length - 1;
        while (low <= high) {
          let mid = Math.floor((low + high) / 2);
          mid;
          if (this.medicalDiagnoses[mid].ShortDescription.trim().toLowerCase().startsWith(searchedTxt)) {
            return mid + 1;
          }

        let comparedTxt :string  = this.medicalDiagnoses[mid].ShortDescription.trim().toLowerCase().substring(0,searchedTxt.length)

        //  let searchedTxtHash = this.hashString(searchedTxt)
         // let comparedTxtHash = this.hashString(comparedTxt)
           let compersionResult =  this.compareTwoStrings(searchedTxt,comparedTxt.trim())
          if (compersionResult == 2) {
            low = mid + 1;  
          } else {
            high = mid - 1;
          }
        }
        return -1;
      }

      compareTwoStrings(txt1 :string , txt2:string){
          let txtArray:string[] = [ txt1,txt2]
          txtArray = txtArray.sort()
          if (txtArray[0] === txt1) return 1
          else return 2

      }

      hashString(txt:string){
        let output = '';
        for (let i = 0; i < txt.length; i++) {
          output += txt[i].charCodeAt(0);
        }
        return output
      }

    static handleError(error: any) {
        const _errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(_errMsg);
        return Observable.throw(_errMsg);
    }

    errorHandler(error: HttpErrorResponse) {
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred:', error.error.message);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong,
        console.error(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);
      }
      // return an observable with a user-facing error message
      return throwError(
        'Something bad happened; please try again later.');
      // return of([]);
    }
}


