import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { PhysioAssessment } from 'src/app/models/physiotherapy';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';


import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CaretxPhsioTherapyProviderService } from '../../Physiotherapty.data-provider.service';



@Component({
  selector: 'app-physio-shorten-assessment-list',
  templateUrl: './physio-shorten-assessment-list.component.html',
  styleUrls: ['./physio-shorten-assessment-list.component.css']
})
export class PhysioShortenAssessmentListComponent extends CaretexComponent implements OnInit {
  ListOfAssessments:PhysioAssessment[]
  personalId:string
  totalNumOfRecords:number
  @Output() OpenAssessmentHandler = new EventEmitter<string>();
  constructor(
    private _title: Title,
    private router: Router,
    private _ar: ActivatedRoute,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private authServive: AuthService,
    private phsiotheraptyService: CaretxPhsioTherapyProviderService,
    private route: ActivatedRoute) {
    super(medicalFileService);
  }

  ngOnInit(): void {
    this.personalId = this.route.parent.snapshot.params['id'] 
    this.CheckIfComponentIsDisabled(this.personalId)
    this. getAllRecordsFromDB("")
  }

  
  getAllRecordsFromDB(filter:string){
    this.phsiotheraptyService.getPhysioTherapyAssessment(this.personalId, filter,"list").subscribe(
      data => {this.ListOfAssessments = data
        this.totalNumOfRecords = data.length},
      err => {
        this.ListOfAssessments = []
      }
    )
  }

  OnSDrillToSocialAdmission($event,record){
    this.OpenAssessmentHandler.emit(record.ID)

  }
  generateReport($event,record){

  }
  deleteRow(i){

  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }
}
