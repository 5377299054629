import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { WeightStatus } from '../../../models/generalhousedsetups';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';

@Component({
  selector: 'app-dieticianweightstatus',
  templateUrl: './dieticianweightstatus.component.html',
  styleUrls: ['./dieticianweightstatus.component.css']
})
export class DieticianweightstatusComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  statuses:WeightStatus[]  = []
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {super(medicalFileServive) }

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getStatusesFromDB()
  }
  BackToList(){
    this.router.navigate(['system_setups']);
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const statusesListChanged = this.statuses.some(row => {
      return row.New || row.Modified
    })
    return  statusesListChanged
  }


  getStatusesFromDB(){
    this.caretxSetupsProviderService.GetWeightStatuses(this.authServive.LoggedInTenantID).subscribe(
      data => {
        this.statuses = data
      },
      err => {
        this.statuses = []

      }
    )
  }



  addNewWeightStatus(){
    var newStatus = new WeightStatus()
    newStatus.New = true
    newStatus.editMode = true
    newStatus.CreatedAt = new Date()
    newStatus.UpdatedAt = new Date()
    newStatus.TenantID = this.authServive.LoggedInTenantID
    this.statuses.unshift(newStatus)
  }

  deleteRow(i){
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.statuses[i].New){
          this.caretxSetupsProviderService.DeleteWeightStatuses(this.authServive.LoggedInTenantID, this.statuses[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.statuses.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.statuses.splice(this.currentRowIndex, 1);
      }
    })
  }
  editRow(i){
    this.statuses[i].editMode = true
    this.statuses[i].Modified = true
  }
  cancelEditRow(i){
    this.statuses[i].editMode  = false;
    this.statuses[i].Modified = false
  }

 
  updateWeightStatus($event){

     this.caretxSetupsProviderService.CreateUpdateWeightStatuses(this.authServive.LoggedInTenantID,this.statuses).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getStatusesFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })  


    
  }

}
