import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxNursingProviderService } from '../../../../../../../../src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { MultipleoptionsselectionDialogComponent } from '../../../../../../../../src/app/caretx-shared-module/CaretexModals/multipleoptionsselection-dialog/multipleoptionsselection-dialog.component';
import { MedicalFileProviderService } from '../../../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import {  OmdanPainLevels, OmdanPainLocations, OmdanPainMedication, OmdanPainMitigationFactors, OmdanPainNature, OmdanPainSmptoms, OmdanPainTreatment, VasFpsPainExam } from '../../../../../../../../src/app/models/OmdanPain';
import { MedicalFile } from '../../../../../../../../src/app/models/customer';
import { Drug } from '../../../../../../../../src/app/models/drug';
import { PainLevel, PainLocation, PainMitigatingFactor, PainNature, PainSymptoms, Paintreatment } from '../../../../../../../../src/app/models/generalhousedsetups';
import { AuthService } from '../../../../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-omdan-vas-fps-painmodel',
  templateUrl: './omdan-vas-fps-painmodel.component.html',
  styleUrls: ['./omdan-vas-fps-painmodel.component.css']
})
export class OmdanVasFpsPainmodelComponent extends CaretexComponent  implements OnInit {

  moduleName: string = "אומדן כאב למטופל שמשתף פעולה VAS+FPS"
  isomdanClosed: boolean = false
  @Input() IsSubModule: boolean
  @Input() OmdanId: string
  @Input() PersonalId: string
  @Input() SelectedFile: MedicalFile
  @Input() IsFromCentralizedPage: boolean = false
  @Output() backToCentralPage = new EventEmitter<void>();

  @Output() backToReportPage = new EventEmitter<VasFpsPainExam>();
  @Input() currentPainOmdan: VasFpsPainExam

  @Input() FromCentralPainReportPage: boolean = false
  

  PainSymptomsTxt: string = ""
  PainMitigationTxt: string = ""
  PainLocationTxt: string = ""
  PainLevelsTxt: String = ""
  PainNatureTxt: String = ""
  PaintreatmentTxt: String = ""
  DrugsTxt: String = ""
  constructor(public dialog: MatDialog, 
    private route: ActivatedRoute, public medicalFileService: MedicalFileProviderService,private router: Router, private toastrService: ToasterService,
     private authService: AuthService, private caretxNursingProviderService: CaretxNursingProviderService,)  { super(medicalFileService)}

  ngOnInit(): void {


    if (this.IsFromCentralizedPage) {

      this.setTextAreTexts()
    } else {
      this.OmdanId = this.route.snapshot.params.vasfpsid
      this.PersonalId = this.route.parent.snapshot.params['id'];
    }
    // check ifNew Omdan
    if (!this.FromCentralPainReportPage) {
      if (this.OmdanId == null || this.OmdanId == "" || this.OmdanId == "00000000-0000-0000-0000-000000000000" || this.OmdanId == "new") {
        if (this.IsSubModule) {
          this.newOmdan()
        } else {
          this.newOmdan() // tosupport from regular module
        }
      } else {
        this.getOmdanFromDB(this.OmdanId)
      }
    }


  }




  newOmdan() {
    this.currentPainOmdan = new VasFpsPainExam()
    let dateOfNow: Date = new Date();
    this.currentPainOmdan.CreatedAt = dateOfNow
    this.currentPainOmdan.UpdatedAt = dateOfNow
    this.currentPainOmdan.OmdanDate = dateOfNow
    this.currentPainOmdan.Status = "A"
    this.currentPainOmdan.PersonelID = this.PersonalId
    this.currentPainOmdan.TenantID = this.authService.LoggedInTenantID
  }



  SaveOmdan() {
    this.currentPainOmdan.OmdanDate = new Date(this.currentPainOmdan.OmdanDate)
    if (this.currentPainOmdan.ID == "" || this.currentPainOmdan.ID == null || this.currentPainOmdan.ID == undefined) {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }


  getOmdanFromDB(omdanId: string) {
    let filter = '(id=' + '\'' + omdanId + '\'' + ')'
    this.caretxNursingProviderService.getVasFpsPainOmdanem(this.PersonalId, filter,"record").subscribe((data: VasFpsPainExam[]) => {
      if (data.length == 1) {
        this.currentPainOmdan = new VasFpsPainExam(data[0])
        this.isomdanClosed = (this.currentPainOmdan.Status == "C")
        this.setTextAreTexts()

      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }



  setTextAreTexts() {

    this.PainLevelsTxt = "";
    if (this.currentPainOmdan.PainLevels != null && this.currentPainOmdan.PainLevels != undefined) {
      this.currentPainOmdan.PainLevels.forEach(element => {
        this.PainLevelsTxt += '\u2022 ' + element.PainLevel.PainLevel + "\n"
      });
    }


    this.PainLocationTxt = "";
    if (this.currentPainOmdan.PainLocations != null && this.currentPainOmdan.PainLocations != undefined) {
    this.currentPainOmdan.PainLocations.forEach(element => {
      this.PainLocationTxt += '\u2022 ' + element.PainLocation.PainLocation + "\n"
    });
  }


    this.PainMitigationTxt = "";
    if (this.currentPainOmdan.MitigationsFactors != null && this.currentPainOmdan.MitigationsFactors != undefined) {
    this.currentPainOmdan.MitigationsFactors.forEach(element => {
      this.PainMitigationTxt += '\u2022 ' + element.PainMitigatingFactor.PainMitigatingFactor + "\n"
    });
  }



    this.PainNatureTxt = "";
    if (this.currentPainOmdan.PainNatures != null && this.currentPainOmdan.PainNatures != undefined) {
    this.currentPainOmdan.PainNatures.forEach(element => {
      this.PainNatureTxt += '\u2022 ' + element.PainNature.PainNature + "\n"
    });
  }



    this.PaintreatmentTxt = "";
    if (this.currentPainOmdan.Paintreatments != null && this.currentPainOmdan.Paintreatments != undefined) {
    this.currentPainOmdan.Paintreatments.forEach(element => {
      this.PaintreatmentTxt += '\u2022 ' + element.Paintreatment.Paintreatment + "\n"
    });
  }

    this.DrugsTxt = "";
    if (this.currentPainOmdan.OmdanPainMedications != null && this.currentPainOmdan.OmdanPainMedications != undefined) {
    this.currentPainOmdan.OmdanPainMedications.forEach(element => {
      this.DrugsTxt += '\u2022 ' + element.Drug.ShortTradeName + "\n"
    });
  }


    this.PainSymptomsTxt = "";
    if (this.currentPainOmdan.PainSymptoms != null && this.currentPainOmdan.PainSymptoms != undefined) {
    this.currentPainOmdan.PainSymptoms.forEach(element => {
      this.PainSymptomsTxt += '\u2022 ' + element.PainSymptom.PainSymptoms + "\n"
    });
  }



  }



  DoSave() {

    this.caretxNursingProviderService.CreateVasFpsPainOmdan(this.currentPainOmdan, "").subscribe({
      next: data => {
        this.currentPainOmdan = new VasFpsPainExam(data)
        this.isomdanClosed = (this.currentPainOmdan.Status == "C")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.calculateScore()
        if (!this.IsSubModule) {
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          if (!this.IsFromCentralizedPage ) {
            this.router.onSameUrlNavigation = 'reload';
            if (!this.IsFromCentralizedPage) {
              this.router.navigateByUrl("caretexfile/" + this.PersonalId + "/vasfps/" + this.currentPainOmdan.ID)
            }

          }else {
            this.backToReportPage.emit(this.currentPainOmdan)
          }
        }

      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }


  DoUpdate() {
    this.caretxNursingProviderService.updateVasFpsPainOmdan(this.currentPainOmdan).subscribe({
      next: data => {
        this.currentPainOmdan = new VasFpsPainExam(data)
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        if (this.currentPainOmdan.Status == 'C')
          this.isomdanClosed = true
        this.calculateScore()
        if (!this.IsSubModule) {
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          if (!this.IsFromCentralizedPage) {
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigateByUrl("caretexfile/" + this.PersonalId + "/vasfps/" + this.currentPainOmdan.ID)
          }else {
            this.backToReportPage.emit(this.currentPainOmdan)
          }
        }
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }




  calculateScore() {

  }

  CloseOmdan() {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם את/ה רוצה לנעול מבחן ?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText:"בטל",
        confirmButtonText: 'כן,תנעל'
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentPainOmdan.Status = 'C'
          this.currentPainOmdan.ClosedAt = new Date()
          this.currentPainOmdan.ClosedById= this.authService.LoggedInUser.UID
          this.SaveOmdan()
        }
      })
   
  }

  RetrunToList() {
    if (this.IsSubModule) {

    }
    //this.backToOmdanemListEvent.emit(NurseAdmissionSteps.Tests)
    else {
      this.router.navigateByUrl("caretexfile/" + this.PersonalId + "/vasfps/list")
    }
  }

  OpenMultipleOptionsDialog(Type) {

    let title: string
    switch (Type) {
      case "PainLevels":
        title = "רשימה של רמות כאב"
        break
      case "PainLocation":
        title = "מיקום של כאב"
        break
      case "PainMitigatingFactor":
        title = "גורם מקל / מחמיר"
        break
      case "PainSymptoms":
        title = "סמפטומים נלווים"
        break
      case "PainNature":
        title = "אופי כאב"
        break
      case "Paintreatment":
        title = "סוגי טיפול"
        break

      case "Drugs":
        title = "תרופות"
        break

    }


    const dialogRef = this.dialog.open(MultipleoptionsselectionDialogComponent, {
      data: {
        Type: Type,
        title: title,

      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != "" && result != undefined) {
        switch (Type) {
          case "PainLevels":
            this.PainLevelsTxt = "";
            this.currentPainOmdan.PainLevels = [];
            (result as PainLevel[]).forEach(element => {
              let record: OmdanPainLevels = new OmdanPainLevels()
            
              record.CreatedAt = new Date()
              record.PersonelID = this.PersonalId
              record.TenantID = this.authService.LoggedInTenantID
              record.PainLevelId = element.ID
              record.OmdanPainId = this.currentPainOmdan.ID
              this.currentPainOmdan.PainLevels.push(record)
              this.PainLevelsTxt += '\u2022 ' + element.PainLevel + "\n"
            });
            break
          case "PainLocation":
            this.PainLocationTxt = "";
            this.currentPainOmdan.PainLocations = [];
            (result as PainLocation[]).forEach(element => {
              let record: OmdanPainLocations = new OmdanPainLocations()
             
              record.CreatedAt = new Date()
              record.PersonelID = this.PersonalId
              record.TenantID = this.authService.LoggedInTenantID
              record.PainLocationId = element.ID
              record.OmdanPainId = this.currentPainOmdan.ID
              this.currentPainOmdan.PainLocations.push(record)
              this.PainLocationTxt += '\u2022 ' + element.PainLocation + "\n"
            });

            break
          case "PainMitigatingFactor":
            this.PainMitigationTxt = "";
            this.currentPainOmdan.MitigationsFactors = [];
            (result as PainMitigatingFactor[]).forEach(element => {
              let record: OmdanPainMitigationFactors = new OmdanPainMitigationFactors()
          
              record.CreatedAt = new Date()
              record.PersonelID = this.PersonalId
              record.TenantID = this.authService.LoggedInTenantID
              record.PainMitigatingFactorId = element.ID
              record.OmdanPainId = this.currentPainOmdan.ID
              this.currentPainOmdan.MitigationsFactors.push(record)
              this.PainMitigationTxt += '\u2022 ' + element.PainMitigatingFactor + "\n"
            });

            break
          case "PainSymptoms":
            this.PainSymptomsTxt = "";
            this.currentPainOmdan.PainSymptoms = [];
            (result as PainSymptoms[]).forEach(element => {
              let omdanPainSmptomsRec: OmdanPainSmptoms = new OmdanPainSmptoms()
            
              omdanPainSmptomsRec.CreatedAt = new Date()
              omdanPainSmptomsRec.PersonelID = this.PersonalId
              omdanPainSmptomsRec.TenantID = this.authService.LoggedInTenantID
              omdanPainSmptomsRec.PainSymptomId = element.ID
              omdanPainSmptomsRec.OmdanPainId = this.currentPainOmdan.ID
              this.currentPainOmdan.PainSymptoms.push(omdanPainSmptomsRec)
              this.PainSymptomsTxt += '\u2022 ' + element.PainSymptoms + "\n"
            });
            break


          case "PainNature":
            this.PainNatureTxt = "";
            this.currentPainOmdan.PainNatures = [];
            (result as PainNature[]).forEach(element => {
              let omdanPainNatureRec: OmdanPainNature = new OmdanPainNature()
          
              omdanPainNatureRec.CreatedAt = new Date()
              omdanPainNatureRec.PersonelID = this.PersonalId
              omdanPainNatureRec.TenantID = this.authService.LoggedInTenantID
              omdanPainNatureRec.PainNatureId = element.ID
              omdanPainNatureRec.OmdanPainId = this.currentPainOmdan.ID
              this.currentPainOmdan.PainNatures.push(omdanPainNatureRec)
              this.PainNatureTxt += '\u2022 ' + element.PainNature + "\n"
            });
            break



          case "Paintreatment":
            this.PaintreatmentTxt = "";
            this.currentPainOmdan.Paintreatments = [];
            (result as Paintreatment[]).forEach(element => {
              let record: OmdanPainTreatment = new OmdanPainTreatment()
           
              record.CreatedAt = new Date()
              record.PersonelID = this.PersonalId
              record.TenantID = this.authService.LoggedInTenantID
              record.PaintreatmentId = element.ID
              record.OmdanPainId = this.currentPainOmdan.ID
              this.currentPainOmdan.Paintreatments.push(record)
              this.PaintreatmentTxt += '\u2022 ' + element.Paintreatment + "\n"
            });
            break



          case "Drugs":
            this.DrugsTxt = "";
            this.currentPainOmdan.OmdanPainMedications = [];
            (result as Drug[]).forEach(element => {
              let record: OmdanPainMedication = new OmdanPainMedication()

              record.CreatedAt = new Date()
              record.PersonelID = this.PersonalId
              record.TenantID = this.authService.LoggedInTenantID
              record.DrugId = element.id
              record.OmdanPainId = this.currentPainOmdan.ID
              this.currentPainOmdan.OmdanPainMedications.push(record)
              this.DrugsTxt += '\u2022 ' + element.ShortTradeName + "\n"
            });
            break



        }
      } else {


      }




    });






  }

  BackToMainList_Sub() {

  }

  ReturnToCentralPage() {
    this.backToCentralPage.emit()
    // This flag to do save on close
    if (this.FromCentralPainReportPage) {
        this. SaveOmdan()
    }
   
  }


}
