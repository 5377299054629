import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { sideNaveGroups } from 'src/app/services/enums';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CommonInterface } from 'src/app/shared/SharedInterface';

@Component({
  selector: 'app-medicalroutanchor',
  templateUrl: './medicalroutanchor.component.html',
  styleUrls: ['./medicalroutanchor.component.css']
})
export class MedicalroutanchorComponent extends CaretexComponent implements OnInit,CommonInterface  {
  
  displayNewDrugModal: boolean = false;
  TabId:string = "medicalfiles"

  constructor(public medicalFileServive: MedicalFileProviderService,) {super(medicalFileServive) }
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Medical;
  showSideBar:boolean = false
  ngOnInit(): void {

    //Send event To Side Nav to recheck status 
    
  }


  OpenSideBarActions($event){
    this.showSideBar = $event
  }

  openNewDrugModal() {

    this.displayNewDrugModal = true
  }

  closeNewDrugModal($event){
      if($event){
        this.displayNewDrugModal = false
      }
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty(){
    return false;
  }

}
