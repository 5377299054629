import { User } from 'firebase'
import { propertyMap } from './modelmapper'




// Need Server to send Payload in below structure
export class FamilyVisit{
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID') //
    public PersonelID: string
    @propertyMap('VisitSummary')
    public VisitSummary: string
    @propertyMap('VisitDate')
    public VisitDate: Date
    @propertyMap('VisitTime')
    public VisitTime: Date
    @propertyMap('Contact')
    public Contact: string

    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User
    @propertyMap('Status')
    Status: string
    @propertyMap('ClosedAt')
    public ClosedAt: Date
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User


    
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.PersonelID = data["PersonelID"]
            this.VisitDate = data["VisitDate"]
            this.VisitTime = data["VisitTime"]
            this.VisitSummary = data["VisitSummary"]
            this.Contact = data["Contact"]
            this.ClosedBy = data["ClosedBy"]
            this.ClosedById = data["ClosedById"]
            this.CreatedBy = data["CreatedBy"]
            this.CreatedById = data["CreatedById"]
            this.Status = data["Status"]
            this.ClosedAt = data["ClosedAt"]
        }
        else {
            this.CreatedAt = this.UpdatedAt = new Date()
            this.Status = "A"
        }
    }
}


export class FamilyConversation{
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID') //
    public PersonelID: string
    @propertyMap('TalkSummary')
    public TalkSummary: string
    @propertyMap('TalkDate')
    public TalkDate: Date
    @propertyMap('TalkTime')
    public TalkTime: Date
    @propertyMap('Contact')
    public Contact: string
    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User
    @propertyMap('Status')
    Status: string
    @propertyMap('ClosedAt')
    public ClosedAt: Date
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.PersonelID = data["PersonelID"]
            this.TalkDate = data["TalkDate"]
            this.TalkTime = data["TalkTime"]
            this.TalkSummary = data["TalkSummary"]
            this.Contact = data["Contact"]
            this.ClosedBy = data["ClosedBy"]
            this.ClosedById = data["ClosedById"]
            this.CreatedBy = data["CreatedBy"]
            this.CreatedById = data["CreatedById"]
            this.Status = data["Status"]
            this.ClosedAt = data["ClosedAt"]
        }
        else {
            this.CreatedAt = this.UpdatedAt = new Date()
            this.Status = "A"
        }
    }
}

