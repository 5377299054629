import { InfectionStatus, IsolationType, ResistanceType } from "./generalhousedsetups"
import { propertyMap } from "./modelmapper"
import { User } from "./user"

export class PersonelHazards {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('RealmID')
    public RealmID: string
    @propertyMap('PersonelID')
    public PersonelID: string
    @propertyMap('Comment')
    public Comment: string
    @propertyMap('Status')
    public Status: string
    @propertyMap('Isolate')
    public Isolate: boolean
    @propertyMap('Alert')
    public Alert: boolean
    @propertyMap('HazardId')
    public HazardId: string
    @propertyMap('Hazard')
    public Hazard: Hazard
    @propertyMap('HazardTypeId')
    public HazardTypeId: string
    @propertyMap('HazardType')
    public HazardType: HazardType
    @propertyMap('HazardRisk')
    public HazardRisk: HazardRisk
    @propertyMap('HazardRiskId')
    public HazardRiskId: string
    @propertyMap('ReportedById')
    public ReportedById: string
    @propertyMap('ReportedBy')
    public ReportedBy: User
    @propertyMap('LastUpdatedById')
    public LastUpdatedById: string
    @propertyMap('LastUpdatedBy')
    public LastUpdatedBy: User
    @propertyMap('IsReported')
    public IsReported: string
    @propertyMap('ResistanceTypeId')
    public ResistanceTypeId: string
    @propertyMap('ResistanceType')
    public ResistanceType: ResistanceType
    @propertyMap('ContaminationSourceId')
    public ContaminationSourceId: string
    @propertyMap('IsolationTypeId')
    public IsolationTypeId: string
    @propertyMap('IsolationType')
    public IsolationType: IsolationType
    @propertyMap('AdhesionPlaceId')
    public AdhesionPlaceId: string
    @propertyMap('HospitalRoomId')
    public HospitalRoomId: string
    @propertyMap('PcrTestId')
    public PcrTestId: string
    @propertyMap('HodgeTestId')
    public HodgeTestId: string
    @propertyMap('HospitalizationTeamId')
    public HospitalizationTeamId: string
    @propertyMap('InfectionStatusId')
    public InfectionStatusId: string
    @propertyMap('InfectionStatus')
    public InfectionStatus: InfectionStatus
    @propertyMap('IsolationDenialDate')
    public IsolationDenialDate: Date
    @propertyMap('IsolationRemovalDate')
    public IsolationRemovalDate: Date
    @propertyMap('ReTakenTestDate')
    public ReTakenTestDate: Date
    @propertyMap('FirstTakenTestDate')
    public FirstTakenTestDate: Date
    @propertyMap('IsReTakenTestDateSet')
    public IsReTakenTestDateSet: boolean
    @propertyMap('IsIsolationDenialDateSet')
    public IsIsolationDenialDateSet: boolean
    @propertyMap('IsIsolationRemovalDateSet')
    public IsIsolationRemovalDateSet: boolean

    @propertyMap('RetestRequired')
    public RetestRequired: string


    
    constructor(data?: Object) {

        if (data != undefined && data != null) {

            this.ID = data["ID"]
            this.IsReported = data["IsReported"]
            this.FirstTakenTestDate = data["FirstTakenTestDate"]
            this.IsReTakenTestDateSet = data["IsReTakenTestDateSet"]
            this.IsIsolationDenialDateSet = data["IsIsolationDenialDateSet"]
            this.IsIsolationRemovalDateSet = data["IsIsolationRemovalDateSet"]
            this.ReTakenTestDate = data["ReTakenTestDate"]
            this.IsolationDenialDate = data["IsolationDenialDate"]
            this.IsolationRemovalDate = data["IsolationRemovalDate"]
            this.CreatedAt = data["CreatedAtD"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.RealmID = data["RealmID"]
            this.Comment = data["Comment"]
            this.Status = data["Status"]
            this.HazardId = data["HazardId"]
            this.Hazard = data["Hazard"]
            this.HazardTypeId = data["HazardTypeId"]
            this.HazardType = data["HazardType"]
            this.HazardRiskId = data["HazardRiskId"]
            this.HazardRisk = data["HazardRisk"]
            this.Alert = data["Alert"]
            this.Isolate = data["Isolate"]
            this.ReportedById = data["ReportedById"]
            this.ReportedBy = data["ReportedBy"]
            this.HospitalizationTeamId = data["HospitalizationTeamId"]
            this.InfectionStatusId = data["InfectionStatusId"]
            this.HodgeTestId = data["HodgeTestId"]
            this.PcrTestId = data["PcrTestId"]
            this.HospitalRoomId = data["HospitalRoomId"]
            this.AdhesionPlaceId = data["AdhesionPlaceId"]
            this.IsolationTypeId = data["IsolationTypeId"]
            this.ContaminationSourceId = data["ContaminationSourceId"]
            this.ResistanceTypeId = data["ResistanceTypeId"]
            this.IsReported = data["IsReported"]
            this.RetestRequired = data["RetestRequired"]

        }
        else {
            let currentDate = new Date()
            this.CreatedAt = currentDate
            this.UpdatedAt = currentDate
            this.Comment = ""
            this.Hazard = new Hazard()
            this.HazardRisk =  new HazardRisk()
            this.HazardType =  new HazardType()
        }
    }



}


export class Hazard {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('RealmID')
    public RealmID: string
    @propertyMap('HebreName')
    public HebreName: string
    @propertyMap('EnglishName')
    public EnglishName: string
    @propertyMap('TypeId')
    public TypeId: string
    @propertyMap('Type')
    public Type: HazardType
    @propertyMap('RiskId')
    public RiskId: string
    @propertyMap('Risk')
    public Risk: HazardRisk
    @propertyMap('Isolate')
    public Isolate: boolean
    @propertyMap('AlertColor')
    public AlertColor: string
    public isNew: boolean = false
    public editMode: boolean = false
    public isLocked: boolean = false


}



export class HazardType {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('RealmID')
    public RealmID: string
    @propertyMap('Type')
    public Type: string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode: boolean = false
   
}

export class HazardRisk {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('RealmID')
    public RealmID: string
    @propertyMap('Risk')
    public Risk: string
    @propertyMap('IsGlobal')
    public IsGlobal:boolean
    @propertyMap('Modified')
    public Modified:boolean
    @propertyMap('New')
    public New:boolean
    public editMode: boolean = false
   


}
