import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { share } from 'rxjs/operators';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { MedicalFile } from '../../../../../src/app/models/customer';
import { AuthService } from '../../../../../src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fileinfointitle',
  templateUrl: './fileinfointitle.component.html',
  styleUrls: ['./fileinfointitle.component.css']
})
export class FileinfointitleComponent implements OnInit {

  id : string
  medicalFile$:Observable<MedicalFile>
  @Input() ModuleName :string
  @Input() Value1 :string
  @Input() Value2:string 
  @Input() Closed :boolean
  @Input() IsDrugInstr :boolean
  @Input() DrugInstStat :string

  constructor(private authServive: AuthService,private router: Router,private _ar: ActivatedRoute, private medicalFileService:MedicalFileProviderService,) { }

  ngOnInit(): void {
    this.id =  this._ar.parent.snapshot.params['id']
    this.medicalFile$ = this.medicalFileService.getMedicalFile(this.id).pipe(share()) 
  }

  OpenPersonDetailsPage(){
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה רוצה לעזוב דף זה?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'בטל',
      confirmButtonText: 'כן',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl("/medicalfiles/" +  this.id )
      }
    })
  }

  ShowMedicalFiles(){
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה רוצה לעזוב תיק זה ולחזור לרשימה של התיקים הרפואיים?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'בטל',
      confirmButtonText: 'כן',
      allowOutsideClick: false,
      allowEscapeKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['medfiles']);
      }
    })
  }
}
