import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AuthService } from "./services/auth.service";
import { CaretexInterceptor } from "./services/caretexinterceptor";
import { CallbackComponent } from './components/callback/callback.component';
import { FormsModule } from '@angular/forms';
import { CustomerService } from './services/customer.service';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from "./caretx-custompipes-module/custompipes.module";
import { DrugsService } from './services/drugs.service';
import { BridgeEnums } from './services/enums';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { BridgeBuiltInNurseSentenses, BridgeBuiltInSentenses } from './services/builtinsentenses';
import { AdmissionsService } from './services/admissions.service';
import { DataService } from './services/data.service';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignInVerifyComponent } from './components/sign-in-verify/sign-in-verify.component';
import { AuthGuard, CanDeactivateGuard } from './shared/guard/auth.guard';
import { ToasterService } from './services/toastr.service';
import { ReportingService } from './services/reporting.service';
import { WebcamModule } from 'ngx-webcam';
import { LabsProviderService } from './caretx-doctors-module/doctor-services/laboratory.data-provider.service';
import { CommeonHelperService } from './services/common.data-helper.service';
import { SharedModule } from './caretx-shared-module/shared-module.module';
import { NusrsingModuleModule } from './caretx-nusrsing-module/nusrsing-module.module';
import { DoctorsModule } from './caretx-doctors-module/doctors.module';
import { CaretxSetupsModuleModule } from './caretx-setups-module/caretx-setups-module.module';
import { CartexMedicalfileModuleModule } from './cartex-medicalfile-module/cartex-medicalfile-module.module';
import { ExamslandingpageComponent } from './caretx-nusrsing-module/examslandingpage/examslandingpage.component';
import { UserinfoComponent } from './components/user-management/userinfo/userinfo.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CaretxSocialModuleModule } from './caretx-social-module/caretx-social-module.module';
import { MedicalFileProviderService } from '../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service'
import { CartexPhysiotherapyModuleModule } from './cartex-physiotherapy-module/cartex-physiotherapy-module.module';
import { NgOtpInputModule } from 'ng-otp-input';
import { CaretexDieticianModule } from './caretex-dietician/caretex-dietician.module';
import { CaretexOccupationalTherapyModule} from './caretex-occupational-therapy/caretex-occupational-therapy.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { MatDialogModule } from '@angular/material/dialog';
import { CaretxTaskSchedulerModule } from './caretx-cardix/caretx-cardix.module';

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    PageNotFoundComponent,
    SignInComponent,
    ExamslandingpageComponent,
    UserinfoComponent,
    SignInVerifyComponent,
  


  ],
  imports: [
    AppRoutingModule,
    WebcamModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    PipesModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    SharedModule,
    NusrsingModuleModule,
    DoctorsModule,
    CaretxSocialModuleModule,
    CaretxSetupsModuleModule,
    CartexMedicalfileModuleModule,
    CartexPhysiotherapyModuleModule,
    CaretexDieticianModule,
    CaretexOccupationalTherapyModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgOtpInputModule,
    MatDialogModule,
    CaretxTaskSchedulerModule
  ],
  exports: [
  ],
  providers: [
    CommeonHelperService,
    MedicalFileProviderService,
    AuthGuard,
    CanDeactivateGuard,
    LabsProviderService,
    DataService,
    ToasterService,
    AuthService,
    CustomerService,
    AdmissionsService,
    ReportingService,
    DrugsService,
    BridgeEnums,
    BridgeBuiltInSentenses,
    BridgeBuiltInNurseSentenses, {
      provide: HTTP_INTERCEPTORS,
      useClass: CaretexInterceptor,
      multi: true
    }, DatePipe
   
  
  
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
