import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'convertcodetovalue' })
export class ConvertCodeToValuePipe implements PipeTransform {
  convertedValue: string
  transform(value: any, name: any, value2: any): string {

    switch (name) {
      case "DietaryRecommendation":
        switch (value) {
          case "A":
            this.convertedValue = "המלצה עדיין באישור"
            break;
          case "P":
            this.convertedValue = "המלצה אושרה"
            break;
          case "C":
            this.convertedValue = "המלצה הופסקה"
            break;

          default:
            this.convertedValue = "לא ידוע"
            break
        }
        break



        case "dieticianfollowuptypes":
          switch (value) {
            case "A":
              this.convertedValue = "מעקב שוטף"
              break;
            case "B":
              this.convertedValue = " מעקב חצי שנתי"
              break;
            case "C":
              this.convertedValue = "קבלת דייר חדש"
              break;
            default:
              break
          }
          break



      case "FollowUpType":
        switch (value) {
          case "social":
            this.convertedValue = "מעקב סוציאלי"
            break;
          case "medical":
            this.convertedValue = "מעקב רפואי"
            break;
          case "nurse":
            this.convertedValue = "מעקב סיעודי"
            break;
          case "dietician":
            this.convertedValue = "מעקב תזונתי"
            break;
          case "occupational":
            this.convertedValue = "מעקב ריפוי בעיסוק"
            break;

          default:
            break
        }
        break


      case "MaritalStatus":
        switch (value) {
          case "u":
            this.convertedValue = "רווק/ה"
            break;
          case "m":
            this.convertedValue = "נשוי/נשואה"
            break;
          case "d":
            this.convertedValue = "גרוש/ה"
            break;
          case "f":
            this.convertedValue = "אלמן/ה"
            break;
          default:
            break
        }
        break

      case "BriedgeDate":
        if (value == undefined || value.toString() == "0001-01-01T00:00:00Z")
          this.convertedValue = "-"
        else
          this.convertedValue = value
        break

      case "EducationCodes":
        switch (value) {
          case "ed1":
            this.convertedValue = "ללא השכלה"
            break;
          case "ed2":
            this.convertedValue = "השכלה יסודית"
            break;
          case "ed3":
            this.convertedValue = "השכלה על יסודית"
            break;
          case "ed4":
            this.convertedValue = "אקאדימאי/ת"
            break;
          default:
            this.convertedValue = "לא ידוע"
            break
        }
        break

      case "NurseInstrType":
        switch (value) {
          case "O":
            this.convertedValue = "הוראה חד פעמית"
            break;
          case "R":
            this.convertedValue = "הוראה חוזרת"
            break;

          default:
            break
        }
        break

      case "NurseTask":
        switch (value) {
          case "A":
            this.convertedValue = "הוראה פעילה"
            break;
          case "Y":
            this.convertedValue = "הוראה בוצעה"
            break;
          case "N":
            this.convertedValue = "הוראה שלא בוצעה"
            break;
          default:
            break
        }
        break

      case "NurseInstrStatus":
        switch (value) {
          case "A":
            this.convertedValue = "הוראה פעילה"
            break;
          case "S":
            this.convertedValue = "הוראה שהופסקה"
            break;
          case "C":
            this.convertedValue = "הוראה שבוטלה"
            break;
          default:
            break
        }
        break

      case "AdmissionStatus":
        switch (value) {
          case "A":
            this.convertedValue = "קבלה פתוחה"
            break;
          case "C":
            this.convertedValue = "קבלה נעולה"
            break;
          default:
            break
        }
        break

      case "GenerlaDocumentStatus":
        switch (value) {
          case "A":
            this.convertedValue = "מסמך פתוח"
            break;
          case "C":
            this.convertedValue = "מסמך נעול"
            break;
          default:
            break
        }
        break
      case "UlceDocumentStatus":
        switch (value) {
          case "A":
            this.convertedValue = "דיווח פעיל"
            break;
          case "C":
            this.convertedValue = "דיווח שהסתיים"
            break;
          default:
            break
        }
        break
      case "DiagnosesStatus":
        switch (value) {
          case "A":
            this.convertedValue = "אבחנה פעילה"
            break;
          case "C":
            this.convertedValue = "אבחנה לא פעילה"
            break;
          default:
            break
        }
        break

      case "ReleaseStatus":
        switch (value) {
          case "A":
            this.convertedValue = "שחרור פעיל"
            break;
          case "C":
            this.convertedValue = "שחרור נעול"
            break;
          default:
            break
        }
        break

      case "ObservationStatus":
        switch (value) {
          case true:
            this.convertedValue = "רשומה נעולה"
            break;
          case false:
            this.convertedValue = "רשומה פתוחה"

            break;
          default:
            break
        }
        break

      case "ExceptionEventStatus":
        switch (value) {
          case "A":
            this.convertedValue = "אירוע שעדיין מתוחקר "
            break;
          case "C":
            this.convertedValue = "אירוע שהסתיים"
            break;
          default:
            break
        }
        break

      case "ConvertToPlaintTxt":
        this.convertedValue = value.replace(/<[^>]+>/g, '');
        break

      case "OmdanStatus":
        switch (value) {
          case "A":
            this.convertedValue = "מבחן פתוח"
            break;
          case "C":
            this.convertedValue = "מבחן נעול"
            break;
          default:
            break
        }
        break

      case "PhysicalCheck":
        switch (value) {
          case "A":
            this.convertedValue = "בדיקה פעילה"
            break;
          case "C":
            this.convertedValue = "בדיקה נעולה"
            break;
          default:
            break
        }
        break

      case "DocumentCheck":
        switch (value) {
          case "A":
            this.convertedValue = "רשומה פתוחה"
            break;
          case "C":
            this.convertedValue = "רשומה נעולה"
            break;
          default:
            break
        }
        break

      case "LaboratoryRequest":
        switch (value) {
          case "A":
            this.convertedValue = "פתוחה"
            break;
          case "S":
            this.convertedValue = "נמסר לטיפול אחים/ות"
            break;
          case "D":
            this.convertedValue = "בוצעה"
            break;
        }
        break

      case "LaboratoryChecks":
        switch (value) {
          case "A":
            this.convertedValue = "ביוכימיה"
            break;
          case "B":
            this.convertedValue = "המטולוגיה"
            break;
          case "C":
            this.convertedValue = "שתן כללי"
            break;

          case "D":
            this.convertedValue = "הורמון-אנדו"
            break;
          case "E":
            this.convertedValue = "הורמון-אנדו"
            break;
          case "F":
            this.convertedValue = "ספירת דם"
            break;
          default:
            break
        }
        break

      case "Guardian":
        switch (value) {
          case "Y":
            this.convertedValue = "קיים"
            break;
          case "N":
            this.convertedValue = "לא קיים"
            break;
          default:
            break
        }
        break

      case "PersonalStatus":
        switch (value) {
          case "u":
            this.convertedValue = "רווק/ה"
            break;
          case "m":
            this.convertedValue = "נשוי/נשואה"
            break;
          case "d":
            this.convertedValue = "גרוש/ה"
            break;
          case "f":
            this.convertedValue = "אלמן/ה"
            break;
        }
        break

      case "Gender":
        switch (value.toLowerCase()) {
          case "female":
          case "f":
            this.convertedValue = "נקבה"
            break;
          case "male":
          case "m":
            this.convertedValue = "זכר"
            break;
          default:
            break
        }
        break

      case "HMO":
        switch (value) {

          case "clalit":
            this.convertedValue = "קופת חולים כללית"
            break;
          case "macabi":
            this.convertedValue = "קופת חולים מכבי"
            break;
          case "leumit":
            this.convertedValue = "קופת חולים לאומית"
            break;
          case "meohedet":
            this.convertedValue = "קופת חולים מאוחדת"
            break;
          case "other":
            this.convertedValue = "אחר"
            break;
          default:
            break
        }
        break

      case "TreatmentType":
        switch (value) {
          case "F":
            this.convertedValue = "תרופה קבועה"
            break;
          case "W":
            this.convertedValue = "טיפול זמני"
            break;
          default:
            this.convertedValue = "לא ידוע"
            break;

        }
        break

      case "InstructionStatus":
        switch (value) {
          case "A":
            this.convertedValue = "פעילה"
            break;
          case "C":
            this.convertedValue = "הופסקה/בוטלה"
            break;
          case "D":
            this.convertedValue = "עדיין בהכנה"
            break;
          case "S":
            this.convertedValue = "בהשהייה"
            break;
            case "SD":
              this.convertedValue = "מחכה לחתימת אח/ות"
              break;
          case "SP":
            this.convertedValue = "הוראה מקבלה -לא פעילה"
            break;
          case "SA":
            this.convertedValue = "הוראה מקבלה - פעילה"
            break;
          default:
            this.convertedValue = "לא ידוע"
            break;

        }
        break

      case "UserRole":
        switch (value) {
          case "doctor":
            this.convertedValue = "רופא/ה"
            break;
          case "sdoctor":
            this.convertedValue = "רופא/ה אחראי/ת"
            break;
          case "nurse":
            this.convertedValue = "אח/ות"
            break;
          case "nurse":
            this.convertedValue = "אח/ות אחראי/ת"
            break;
          case "phys":
            this.convertedValue = "פיזוטרפיסת/ית"
            break;
          case "admin":
            this.convertedValue = "מנהל מערכת"
            break;
          case "di":
            this.convertedValue = "דיטאנית"
            break;
          case "sw":
            this.convertedValue = "עובד סוציאלי/ת"
            break;
          default:
            this.convertedValue = ""
            break;

        }
        break

      case "NurseInstFreq":
        switch (value) {
          case "O":
            this.convertedValue = "ביצוע חד פעמי"
            break;
          case "W":
            switch (value2) {
              case "1":
                this.convertedValue = "פעם אחת ביום"
                break;
              case "2":
                this.convertedValue = "פעמיים  ביום"
                break;
              case "3":
                this.convertedValue = "שלוש פעמים ביום"
                break;
              case "4":
                this.convertedValue = "ארבע פעמים ביום"
                break
              case "5":
                this.convertedValue = "חמש פעמים ביום"
                break
              case "6":
                this.convertedValue = "שש פעמים ביום"
                break
              case "7":
                this.convertedValue = "שבע פעמים ביום"
                break
              case "8":
                this.convertedValue = "שמונה פעמים ביום"
                break
              case "9":
                this.convertedValue = "תשע פעמים ביום"
                break
              case "10":
                this.convertedValue = "עשר פעמים ביום"
                break
              case "11":
                this.convertedValue = "אחד עשרה פעמים ביום"
                break
              case "12":
                this.convertedValue = "שתים עשרה פעמים ביום"
                break;
              case "13":
                this.convertedValue = "שלוש עשרה פעמים ביום"
                break
              case "14":
                this.convertedValue = "ארבע עשרה פעמים ביום"
                break
              default:
                break
            }
            break;
          default:
            this.convertedValue = "לא ידוע"
            break;

        }
        break

      case "MeasureValue":
        if (value == undefined || value <= 0)
          this.convertedValue = "-"
        else
          this.convertedValue = value

      case "EmptyValue":
        if (value == undefined || value == '' || value == '0001-01-01T00:00:00Z')
          this.convertedValue = "-"
        else
          this.convertedValue = value
        break

      case "DrugForm":
        switch (value) {
          case "cc":
            this.convertedValue = "cc(ml)"
            break;
          case "mg":
            this.convertedValue = "mg"
            break;
          case "T":
            this.convertedValue = "טבליות"
            break;
          case "D":
            this.convertedValue = "טיפות"
            break;
          case "C":
            this.convertedValue = "משחה/חיצוני"
            break;

          default:
            this.convertedValue = "לא ידוע"
            break;
        }
        break

      case "DrugDosageForm":
        switch (value) {
          case "sc":
            this.convertedValue = "S.C"
            break;
          case "im":
            this.convertedValue = "I.M"
            break;
          case "iv":
            this.convertedValue = "I.V"
            break;
          case "po":
            this.convertedValue = "P.O"
            break;
          case "inh":
            this.convertedValue = "INH"
            break;
          case "inh":
            this.convertedValue = "INH"
            break;
          case "pr":
            this.convertedValue = "P.R"
            break;
          case "sl":
            this.convertedValue = "S.L"
            break;
          case "ic":
            this.convertedValue = "I.C"
            break;
          default:
            this.convertedValue = "לא ידוע"
            break;
        }
        break

      default:
        break

    }
    return this.convertedValue
  }
}



@Pipe({ name: 'convertdatetoformat' })
export class ConvertDateFormatPipe implements PipeTransform {

  constructor(
    public datepipe: DatePipe
  ) {

  }
  convertedValue: string
  transform(value: any, format: any): string {
    if (value == undefined || value.toString() == "0001-01-01T00:00:00Z")
      this.convertedValue = "-"
    else
      this.convertedValue = this.datepipe.transform(value, format)
    return this.convertedValue
  }
}