import { Component, OnInit } from '@angular/core';
import { PainLocation } from 'src/app/models/generalhousedsetups';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pain-location-setup',
  templateUrl: './pain-location-setup.component.html',
  styleUrls: ['./pain-location-setup.component.css']
})
export class PainLocationSetupComponent extends CaretexComponent implements OnInit {

  TabId: string = "main"

  painlocations: PainLocation[] = []

  currentRowIndex: number
  deleteSysMsgId: string
  deleteAlert: string
  alertTitle: string
  constructor(private caretxSetupsProviderService: CaretxSetupsProviderService,
    private router: Router,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {
    super(medicalFileServive)
  }

  ngOnInit(): void {
    this.getPainLocationsFromDB()
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  BackToList() {
    this.router.navigate(['system_setups']);
  }
  
  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.painlocations.some(row => {
      return row.New || row.editMode
    })
    return hospitalsLisChanged
  }

  getPainLocationsFromDB() {
    this.caretxSetupsProviderService.getPainLocation().subscribe(
      data => {
        this.painlocations = data
      },
      err => {
        console.log("Error", err)
        this.painlocations = []

      }
    )
  }

  addNewRecord() {
    var newPainLocation = new PainLocation()
    newPainLocation.New = true
    newPainLocation.editMode = true
    newPainLocation.CreatedAt = new Date()
    newPainLocation.UpdatedAt = new Date()
    newPainLocation.RealmID = this.authServive.LoggedInTenantID
    this.painlocations.unshift(newPainLocation)
  }

  deleteRow(i) {
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false,
      cancelButtonText: 'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.painlocations[i].New) {
          this.caretxSetupsProviderService.deletePainLocation(this.painlocations[this.currentRowIndex]).subscribe({
            next: data => {
              this.painlocations.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
          this.painlocations.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i) {
    this.painlocations[i].editMode = true
    this.painlocations[i].Modified = true
  }

  cancelEditRow(i) {
    this.painlocations[i].editMode = false;
    this.painlocations[i].Modified = false
  }

  updateRecord($event) {
    this.caretxSetupsProviderService.CreateUpdatePainLocation(this.painlocations).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getPainLocationsFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }
}