import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartexReport } from '../../../../../../../src/app/models/personeldocument';
import { LabCheck, LabCheckHeader, LabOrderCategories, LabOrderCheck, LabRequests } from '../../../../../../../src/app/models/testlabchecks';
import { AuthService } from '../../../../../../../src/app/services/auth.service';
import { LabsProviderService } from '../../../../../../../src/app/caretx-doctors-module/doctor-services/laboratory.data-provider.service';
import { ReportingService } from '../../../../../../../src/app/services/reporting.service';
import { ToasterService } from '../../../../../../../src/app/services/toastr.service';
import { CaretxMedicalProviderService } from '../../../../../../../src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import Swal from 'sweetalert2';

import { CaretexComponent } from '../../../../../../../src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from '../../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { NgForm } from '@angular/forms';
import { DocViewerComponent } from '../../../../../../../src/app/caretx-shared-module/CaretexSharedComponents/doc-viewer/doc-viewer.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-orderlabchecks',
  templateUrl: './orderlabchecks.component.html',
  styleUrls: ['./orderlabchecks.component.css']
})
export class OrderlabchecksComponent extends CaretexComponent implements OnInit {
  rotate: string
  rotate2: string
  id: string
  requestId: string
  pulse: boolean = true;
  allLabChecksAndCategories: LabOrderCategories[]
  specificLabTests: LabOrderCheck[]
  labcheckHeaders: LabCheckHeader[] = []
  selectedCategory: LabOrderCategories = new LabOrderCategories()
  LabRequest: LabRequests
  pdfContent: any
  signAlert: string
  alertTitle: string
  signSysMsgId: string
  isReadOnly: boolean = false
  @ViewChild('mainform') mainFormElement: NgForm;
  moduleName: string = "הזמנת בדיקות מעבדה"
  selectedLabOrders: LabOrderCheck[]
  showAllSelectedOnly: boolean = false

  constructor(
    private _labService: LabsProviderService,
    private labservice: CaretxMedicalProviderService,
    private toastrService: ToasterService,
    public medicalFileService: MedicalFileProviderService,
    private caretxMedicalService: CaretxMedicalProviderService,
    private reportingService: ReportingService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private authServive: AuthService,) {
    super(medicalFileService)
    this.selectedCategory.LabOrders = []
    this.signAlert = "לא נבחרו אף בדיקות!"
    this.alertTitle = "התראת מערכת"
  }

  ngOnInit(): void {
    if(this.route.parent !=null)
    this.id = this.route.parent.snapshot.params['id'];
    this.requestId = this.route.snapshot.params.requestid;
    this.CheckIfComponentIsDisabled(this.id)
    this.getRequestByIdFromDB()
    this.doInitialization()
    this.showOnlySelectedLabs()
  }

  getRequestByIdFromDB() {
    this.labservice.getLaboratoryRequestById(this.id, this.requestId).subscribe(data => {
      if (data.RequestStatus != "A" && data.RequestStatus != undefined) {
        this.isReadOnly = true
      }
      this.LabRequest = new LabRequests(data)
      this.allLabChecksAndCategories = this.LabRequest.LabOrderCategories
      if (this.LabRequest.RequestedTests == null || this.LabRequest.RequestedTests == undefined) {
        this.LabRequest.RequestedTests = this.selectedLabOrders = []
        return
      }
      this.selectedLabOrders = this.LabRequest.RequestedTests
      // loop  on  All LabOrderChecks and set isSelected if Found in Selected LabOrderChecks List
      let totalSelectedOrders = this.selectedLabOrders.length;
      if (this.allLabChecksAndCategories != undefined && this.allLabChecksAndCategories.length > 0) {
        for (var k = 0, len = this.allLabChecksAndCategories.length; k < len; k++) {
          let labCategory = this.allLabChecksAndCategories[k]
          for (var i = 0; i < totalSelectedOrders; i++) {
            if (this.selectedLabOrders[i].CategoryId == labCategory.CategoryId) {
              if (labCategory.LabOrders != undefined && labCategory.LabOrders.length > 0) {
                for (var J = 0, len3 = labCategory.LabOrders.length; J < len3; J++) {
                  if (labCategory.LabOrders[J].Name == this.selectedLabOrders[i].Name)
                    labCategory.LabOrders[J].IsSelected = true
                }
              }
            }
          }
        }
      }
    });
  }

  selectLabTest(labtest: LabOrderCheck) {
    if (this.isReadOnly) return
    labtest.IsSelected = true
    this.LabRequest.RequestedTests.push(labtest)
    this.getspecificLabResultsFromDB(labtest.Name)
  }

  showLabchecks(lab: LabOrderCategories) {
    this.pulse = false
    this.specificLabTests = lab.LabOrders
    this.specificLabTests.sort((a, b) => (a.Sequence > b.Sequence) ? 1 : -1)
    this.selectedCategory = lab
    this.rotate = "rotateY(180deg)";
    this.rotate2 = "rotateY(360deg)";
  }

  returnToMainList() {
    this.rotate = "rotateY(0deg)";
    this.rotate2 = "rotateY(180deg)";
    this.pulse = true
  }

  reoveCheckFromRequestedList(labtest: LabOrderCheck) {
    labtest.IsSelected = false
    // If not New Order then need to delete from DB As Well 
    if (this.requestId != undefined && this.requestId != "") {

      let tmpIndex = -1
      let flag = false
      this.LabRequest.RequestedTests.forEach(function (item, index, object) {
        if (item.HMO == labtest.HMO && item.PrimaryCode == labtest.PrimaryCode) {

          tmpIndex = index
          flag = true
          
        }
      });

      if (flag) {
        this.LabRequest.RequestedTests.splice(tmpIndex, 1)
      }

    }

  }

  selectAllchecks() {
    this.selectedCategory.LabOrders.forEach(element => {
      element.IsSelected = true
      this.LabRequest.RequestedTests.push(element)


    })
  }

  getspecificLabResultsFromDB(categoryId: string) {
    let filter = '(category_id=' + '\'' + categoryId + '\'' + ')'
    this.caretxMedicalService.getLaboratoryResultsHeaders(this.id, filter).subscribe(
      data => {
        this.labcheckHeaders = data
      },
      err => {
        console.log("Error", err)
        this.labcheckHeaders = []

      }
    )
  }

  getFirstLabcheckHeader() {
    if (Array.isArray(this.labcheckHeaders) && this.labcheckHeaders.length) {
      return this.labcheckHeaders[0].Labchecks
    }
    else
      return []
  }

  findValueOfGivencode(labcheckHeader: LabCheckHeader, TestName: string) {
    let val: any = ""
    if (labcheckHeader != undefined && labcheckHeader != null) {
      if (labcheckHeader.Labchecks.length) {
        for (let elem of labcheckHeader.Labchecks) {
          if (elem.TestName == TestName) {
            val = elem.LabTemplate.Uom + " " + elem.TestValue
            break
          }
        }
      }
      else
        val = ""
    }
    else {
      val = ""
    }
    return val
  }

  isRed(labcheckHeader: LabCheckHeader, TestName: string) {
    let isRed: Boolean = false
    if (labcheckHeader != undefined && labcheckHeader != null) {
      if (labcheckHeader.Labchecks.length) {
        for (let elem of labcheckHeader.Labchecks) {
          if (elem.TestName == TestName) {
            isRed = (new LabCheck(elem)).isValueInInterval()
            break

          }
        }
      }
    }
    return isRed
  }
  dropdownSettings: any = {};
  selectedItems1 = [];
  step: number = 1
  medicationtype:string=""
  setStep(step) {
    this.step = step
  }
  doInitialization() {
    this.dropdownSettings = {
      singleSelection: true,
      idField: "CategoryId",
      textField: "CategoryDescr",
      selectAllText: "בחר הכל",
      unSelectAllText: "בטל בחירה",
      itemsShowLimit: 2,
      allowSearchFilter: true
    };
  }

  showLabchecks2() {
    this.showAllSelectedOnly = false
    this.selectedItems1.forEach(element => {
      let labCategory: LabOrderCategories = this.findCategoryById(element.CategoryId)
      this.specificLabTests = labCategory.LabOrders
      this.specificLabTests.sort((a, b) => (a.Sequence > b.Sequence) ? 1 : -1)
      this.selectedCategory = labCategory
    });
  }

  findCategoryById(categoryId: string) {
    let labCategory =  new LabOrderCategories 
    this.LabRequest.LabOrderCategories.forEach(category => {
      if (category.CategoryId == categoryId) {
        labCategory = category
      }
    });
    return labCategory
  }

  selectLabCheck(labtest: LabOrderCheck, isSelected: boolean) {
    if (this.isReadOnly) return
    labtest.IsSelected = isSelected

    if (!isSelected) {

      this.LabRequest.RequestedTests.forEach(function (item, index, object) {
        if (item.PrimaryCode == labtest.PrimaryCode) {
          object.splice(index, 1);
        }
      });
    } else {
      this.LabRequest.RequestedTests.push(labtest)
    }


  }


  showOnlySelectedLabs() {
    this.showAllSelectedOnly = true
  }



  generateReport() {
    var careTexReport = new CartexReport()
    careTexReport.PersonelID = this.id
    careTexReport.ReportName = "LabRequest"
    careTexReport.ReportType = "laboratory"
    careTexReport.ReportParam = "3"
    careTexReport.GeneratedBy = this.authServive.LoggedInUser
    careTexReport.GeneratedOn = new Date()
    const dialogRef = this.dialog.open(DocViewerComponent, {
      data: {
        Type:'CR',
        PdfReport: careTexReport
      
      }
    });
  }

  BackToList() {
    this.router.navigateByUrl("caretexfile/" + this.id + "/laboratory/orders")
  }

  ApproveRequest($event) {
    Swal.fire({
      title: "הודעת מערכת",
      text: "בקשת מעבדה תשוחרר לביצוע, להמשיך?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמשיך',
      allowEscapeKey: false,
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        this.LabRequest.RequestStatus = "S"
        this.Save(null)
      }
    })
  }

  Save($event) {
    // Loop Over LabCheck Orders

    if (Array.isArray(this.allLabChecksAndCategories) && this.allLabChecksAndCategories.length) {


      if (!Array.isArray(this.LabRequest.RequestedTests) || this.LabRequest.RequestedTests.length <= 0) {
        Swal.fire({
          title: "הודעת מערכת",
          text: "לא בחרתה אף מעבדה ",
          icon: 'warning',
          allowOutsideClick: false,
          allowEscapeKey: false

        })
      } else {


        if (this.requestId != "new" && this.requestId != undefined) {
          this.LabRequest.UpdatedAt = new Date()
          this.ActionUpdate()
        }

        else {
          this.LabRequest.RequestedById = this.authServive.LoggedInUser.UID
          this.LabRequest.UpdatedAt = this.LabRequest.CreatedAt = new Date()
          if (this.LabRequest.RequestStatus == "" || this.LabRequest.RequestStatus == null || this.LabRequest.RequestStatus == undefined) {
            this.LabRequest.RequestStatus = "A"
          }
          this.ActionSave()
        }

      }

    }

  }

  ActionSave() {
    // Make the call to server

    if (Array.isArray(this.LabRequest.RequestedTests) && this.allLabChecksAndCategories.length) {
      this._labService.saveApprovedLabTests(this.id, this.LabRequest).subscribe({
        next: data => {

          this.toastrService.Sucesss("", this.toastrService.successMsg)
          this.mainFormElement.reset(this.mainFormElement.value);
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigateByUrl("/caretexfile/" + this.id + "/laboratory/orders/" + data["ID"])
        },
        error: error => {
          this.toastrService.Error("", this.toastrService.errorMsg)
        }
      })
    }
  }

  ActionUpdate() {
    // Make the call to server

    if (Array.isArray(this.LabRequest.RequestedTests) && this.allLabChecksAndCategories.length) {
      this._labService.updateApprovedLabTests(this.id, this.LabRequest).subscribe({
        next: data => {
          this.toastrService.Sucesss("", this.toastrService.successMsg)
          this.mainFormElement.reset(this.mainFormElement.value);
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          }
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigateByUrl("/caretexfile/" + this.id + "/laboratory/orders/" + this.LabRequest.ID)
        },
        error: error => {
          this.toastrService.Error("", this.toastrService.errorMsg)
        }
      })
    }
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty(){
    return false;
  }


  
}
