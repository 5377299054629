import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { CaretxNursingProviderService } from '../../../../caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../../../services/toastr.service';
import { NgForm } from '@angular/forms';
import { BaseOmdan, OmdanPossibleAnswer, OmdanQuestionAnswer, OmdanQuestions } from '../../../../models/omdans';
import Swal from 'sweetalert2';
import { CaretexComponent } from '../../../../../../src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from '../../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import jsPDF from 'jspdf';
import { MedicalFile } from '../../../../../../src/app/models/customer';
import { ReportingService } from '../../../../../../src/app/services/reporting.service';
import autoTable, { RowInput } from 'jspdf-autotable';

@Component({
  selector: 'app-mini-mental-omdan',
  templateUrl: './mini-mental-omdan.component.html',
  styleUrls: ['./mini-mental-omdan.component.css']
})
export class MiniMentalOmdanComponent extends CaretexComponent implements OnInit {

  modalTarget: string
  omdanName: string = "minimental"
  questions: OmdanQuestions[] = []
  id: string
  isomdanClosed: boolean = false;
  omdanScore: number = 0
  scorepercentage: number = 0
  omdanBase: BaseOmdan
  OmdanId: string
  moduleName: string = "מבחן MINI-MENTAL"
  @ViewChild('mainform') mainFormElement: NgForm;
  selectedFile: MedicalFile
  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  pdfContent: any


  public i: number = 1
  public grp2: number = 2
  public grp3: number = 3
  public grp4: number = 4
  public grp5: number = 5
  public grp6: number = 6
  public grp7: number = 7
  constructor(
    private authService: AuthService,
    public medicalFileService: MedicalFileProviderService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private route: ActivatedRoute,
    public reportingService: ReportingService,
    private router: Router,
    private toastrService: ToasterService,) {
      super(medicalFileService)

  }

  ngOnInit(): void {
    if (this.route.parent != null)
      this.id = this.route.parent.snapshot.params['id']
    this.CheckIfComponentIsDisabled(this.id)
    if (this.route.snapshot.params.minimentalid != "new")
      this.getOmdanFromDB(this.route.snapshot.params.minimentalid)
    else {
      this.newOmdan("list")
    }
  }



  newOmdan(origin: string) {
    this.omdanBase = new BaseOmdan()
    let dateOfNow: Date = new Date();
    this.omdanBase.CreatedAt = dateOfNow
    this.omdanBase.UpdatedAt = dateOfNow
    this.omdanBase.OmdanChangesComittedToDB = false
    this.omdanBase.OmdanStatus = "A"
    this.omdanBase.PersonelID = this.id
    this.omdanBase.Origin = origin
    this.omdanBase.RealmID = this.authService.LoggedInTenantID
    this.getOmdanQuestions()

  }


  getOmdanFromDB(omdanId: string) {
    let filter = '(id=' + '\'' + omdanId + '\'' + ')'
    this.caretxNursingProviderService.getOmdanem(this.id, filter, "minimental").subscribe((data: BaseOmdan[]) => {
      if (data.length == 1) {
        this.omdanBase = data[0]
        this.isomdanClosed = (this.omdanBase.OmdanStatus == "C")
        this.omdanScore = this.omdanBase.Score
        this.scorepercentage = ((this.omdanScore * 100) / 30)

      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }

  getOmdanQuestions() {
    let filter = '(omdan_type=' + '\'' + this.omdanName + '\'' + ')'
    this.caretxNursingProviderService.getOmdanQuestions(this.id, filter).subscribe((data: OmdanQuestions[]) => {
      this.questions = data
      this.generateSetOfQuestionsAnswers()
    });
  }


  SaveOmdan() {
    if (this.omdanBase.ID == "" || this.omdanBase.ID == null || this.omdanBase.ID == undefined) {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {

    this.caretxNursingProviderService.CreateOmdan(this.omdanBase, "minimental").subscribe({
      next: data => {
        this.omdanBase = (data as BaseOmdan)
        this.isomdanClosed = (this.omdanBase.OmdanStatus == "C")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams/minimental/" + this.omdanBase.ID)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  DoUpdate() {
    this.caretxNursingProviderService.UpdateOmdan(this.omdanBase, "minimental").subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.omdanBase = data as BaseOmdan
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams/minimental/" + this.omdanBase.ID)
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }


  generateSetOfQuestionsAnswers() {
    let index: number = 0
    this.omdanBase.OmdanQuestionAnswers = []
    this.questions.forEach(element => {

      var obj = new OmdanQuestionAnswer()
      obj.Question = element
      obj.QuestionId = element.ID
      obj.CreatedAt = new Date()
      obj.UpdatedAt = new Date()
      obj.Answer = new OmdanPossibleAnswer()
      this.omdanBase.OmdanQuestionAnswers.push(obj);
      ++index;
    });
  }

  onChange($event, questionAnswer) {

    questionAnswer.Question.PossibleAnswers.forEach(possibleAnswer => {
      if (possibleAnswer.ID == $event) {
        questionAnswer.Answer = possibleAnswer
        questionAnswer.AnswerId = possibleAnswer.ID
      }
    });
    this.calculateScore()
  }




  calculateScore() {

    if (this.omdanBase != undefined && this.omdanBase.OmdanQuestionAnswers != undefined) {
      this.omdanScore = 0;
      this.omdanBase.OmdanQuestionAnswers.forEach(answer => {
        if (answer.Answer.AnswerValue != undefined && answer.Answer.AnswerValue != null)
          this.omdanScore += answer.Answer.AnswerValue
      });
      this.omdanBase.Score = this.omdanScore
      this.scorepercentage = ((this.omdanScore * 100) / 30)
    }
  }

  CloseOmdan() {

    if (this.checkIfAllQuestionsAnswered()) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "לא עניתה על כל השאלות ?",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false
      })
    }
    else {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה לנעול מבחן זה ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תנעל',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.omdanBase.OmdanStatus = "C"
          this.omdanBase.LockedById = this.authService.LoggedInUser.UID
          this.omdanBase.LockedDate = new Date()
          this.SaveOmdan()
        }
      })
    }
  }

  // Check if Omdan questions are answered 

  checkIfAllQuestionsAnswered() {
    const res = this.omdanBase.OmdanQuestionAnswers.some(answer =>
      (answer.AnswerId == undefined || answer.AnswerId == null || answer.AnswerId == '00000000-0000-0000-0000-000000000000'))
    return res;
  }

  RetrunToList() {

    this.router.navigateByUrl("caretexfile/" + this.id + "/medicalexams/minimental/list")

  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false;
  }


  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/


  openPDFViewer() {
    let reportTitle = "MINI-MENTAL סיכום מבחן "

    this.showGeneratedPdf = true
    this.selectedFile = this.medicalFile1
    var doc = new jsPDF()
    let lastYPosition: number
    let xPosTitle: number = 135

    lastYPosition = this.reportingService.SetHeader(doc, this.medicalFile1, reportTitle, xPosTitle)
    this.reportingService.setFooter2(doc, this.omdanBase.LockedBy, this.authService.LoggedInUser, this.omdanBase.LockedDate, new Date())



    // Array 1  0,1,2,3,4,5,6,7,8,9
    let firstTableRows: RowInput[] = []
    for (let i = 0; i <= 9; i++) {
      let tmpRowInput: RowInput = []
      let answer = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Answer.Answer)
      let question = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Question.Question)
      tmpRowInput = [answer, question]
      firstTableRows.push(tmpRowInput)
    }


    // Array 2 10 - 12
    let secondTableRows: RowInput[] = []
    for (let i = 10; i <= 12; i++) {
      let tmpRowInput: RowInput = []
      let answer = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Answer.Answer)
      let question = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Question.Question)
      tmpRowInput = [answer, question]
      secondTableRows.push(tmpRowInput)
    }

    // Array 3 13,14,15,16,17
    let thirdTableRows: RowInput[] = []
    for (let i = 13; i <= 17; i++) {
      let tmpRowInput: RowInput = []
      let answer = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Answer.Answer)
      let question = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Question.Question)
      tmpRowInput = [answer, question]
      thirdTableRows.push(tmpRowInput)
    }

    // Array 4 18,19,20
    let fourthTableRows: RowInput[] = []
    for (let i = 18; i <= 20; i++) {
      let tmpRowInput: RowInput = []
      let answer = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Answer.Answer)
      let question = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Question.Question)
      tmpRowInput = [answer, question]
      fourthTableRows.push(tmpRowInput)
    }

    // Array 5 21,22,23
    let fifthTableRows: RowInput[] = []
    for (let i = 21; i <= 23; i++) {
      let tmpRowInput: RowInput = []
      let answer = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Answer.Answer)
      let question = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Question.Question)
      tmpRowInput = [answer, question]
      fifthTableRows.push(tmpRowInput)
    }

    // Array 6 24,25,26,27,28,29
    let sixthTableRows: RowInput[] = []
    for (let i = 24; i <= 29; i++) {
      let tmpRowInput: RowInput = []
      let answer = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Answer.Answer)
      let question = this.reportingService.reverseString(this.omdanBase.OmdanQuestionAnswers[i].Question.Question)
      tmpRowInput = [answer, question]
      sixthTableRows.push(tmpRowInput)
    }


   


    doc.setDrawColor(0);
    doc.setFillColor(0, 0, 0);
    doc.rect(10, 33, 190, 12, 'D'); //Fill and Border
    doc.setFontSize(9);
   
    doc.setFont("NotoSansHebrew-SemiBold");
    doc.text(this.reportingService.reverseString("ציון(נק)"), 190, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
    doc.setFont("NotoSansHebrew-Regular");
    doc.text(this.reportingService.reverseString(this.omdanScore.toString()), 175, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });

    doc.setFont("NotoSansHebrew-SemiBold");
    doc.text(this.reportingService.reverseString("ציון מקסימאלי"), 155, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
    doc.setFont("NotoSansHebrew-Regular");
    doc.text(this.reportingService.reverseString('03'), 130, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });

   
    autoTable(doc, {
      styles: {
        halign: "right",
        font: 'NotoSansHebrew-Regular',
        fontStyle: 'normal',
        fontSize: 9
      }, 
      willDrawPage: () => {
        this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle),
        this.reportingService.setFooter2(doc, this.omdanBase.LockedBy, this.authService.LoggedInUser,this.omdanBase.LockedDate,new Date())
        this.resultRect(doc)
      },
      margin: { bottom: 27, top: 36 },
      startY: 50,
      head: [['', this.reportingService.reverseString('התמצאות')]],
      body: firstTableRows
    });
 
    autoTable(doc, {
      styles: {
        halign: "right",
        font: 'NotoSansHebrew-Regular',
        fontStyle: 'normal',
        fontSize: 9
      },
      willDrawPage: () => {
        this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle),
        this.reportingService.setFooter2(doc, this.omdanBase.LockedBy, this.authService.LoggedInUser,this.omdanBase.LockedDate,new Date())
        this.resultRect(doc)
      },
      margin: { bottom: 27, top: 31 },
      head: [['', this.reportingService.reverseString('זכרון מיידי')]],
      body: secondTableRows
    });

    autoTable(doc, {
      styles: {
        halign: "right",
        font: 'NotoSansHebrew-Regular',
        fontStyle: 'normal',
        fontSize: 9
      },
      willDrawPage: () => {
        this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle),
        this.reportingService.setFooter2(doc, this.omdanBase.LockedBy, this.authService.LoggedInUser,this.omdanBase.LockedDate,new Date())
        this.resultRect(doc)
      },
      margin: { bottom: 27, top: 31 },
      head: [['', this.reportingService.reverseString('ריכוז וחישוב')]],
      body: thirdTableRows
    });

    autoTable(doc, {
      styles: {
        halign: "right",
        font: 'NotoSansHebrew-Regular',
        fontStyle: 'normal',
        fontSize: 9
      },
      willDrawPage: () => {
        this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle),
        this.reportingService.setFooter2(doc, this.omdanBase.LockedBy, this.authService.LoggedInUser,this.omdanBase.LockedDate,new Date())
        this.resultRect(doc)
      },
      margin: { bottom: 27, top: 31 },
      head: [['', this.reportingService.reverseString('זכרון')]],
      body: fourthTableRows
    });

    autoTable(doc, {
      styles: {
        halign: "right",
        font: 'NotoSansHebrew-Regular',
        fontStyle: 'normal',
        fontSize: 9
      },
      willDrawPage: () => {
        this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle),
        this.reportingService.setFooter2(doc, this.omdanBase.LockedBy, this.authService.LoggedInUser,this.omdanBase.LockedDate,new Date())
        this.resultRect(doc)
      },
      margin: { bottom: 27, top: 50 },
      head: [['', this.reportingService.reverseString('שפה')]],
      body: fifthTableRows
    });

    autoTable(doc, {
      styles: {
        halign: "right",
        font: 'NotoSansHebrew-Regular',
        fontStyle: 'normal',
        fontSize: 9

      },
      willDrawPage: () => {
        this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle),
        this.reportingService.setFooter2(doc, this.omdanBase.LockedBy, this.authService.LoggedInUser,this.omdanBase.LockedDate,new Date())
        this.resultRect(doc)
      },
      margin: { bottom: 27, top: 31 },
      head: [['', this.reportingService.reverseString('תן לחולה דף נייר ריק ואמור קח את הנייר ביד שמאל')]],
      body: sixthTableRows
    });


    let file: Blob = doc.output('blob')
    this.pdfContent = URL.createObjectURL(file);
    this.pdfViewer.pdfSrc = file
    this.pdfViewer.refresh();

  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }


  resultRect(doc:jsPDF){
    
    doc.setDrawColor(0);
    doc.setFillColor(0, 0, 0);
    doc.rect(10, 33, 190, 12, 'D'); //Fill and Border
    doc.setFontSize(9);
   
    doc.setFont("NotoSansHebrew-SemiBold");
    doc.text(this.reportingService.reverseString("ציון(נק)"), 190, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
    doc.setFont("NotoSansHebrew-Regular");
    doc.text(this.reportingService.reverseString(this.omdanScore.toString()), 175, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });

    doc.setFont("NotoSansHebrew-SemiBold");
    doc.text(this.reportingService.reverseString("ציון מקסימאלי"), 155, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });
    doc.setFont("NotoSansHebrew-Regular");
    doc.text(this.reportingService.reverseString('03'), 130, 39, { align: 'right', isSymmetricSwapping: true, isInputVisual: true, isOutputVisual: false, isInputRtl: true, isOutputRtl: true });

  }


}
