import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxNursingProviderService } from 'src/app/caretx-nusrsing-module/nursing-services/Nursing.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { VasFpsPainExam } from 'src/app/models/OmdanPain';
import { AuthService } from 'src/app/services/auth.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-omdan-vas-fps-pain-list',
  templateUrl: './omdan-vas-fps-pain-list.component.html',
  styleUrls: ['./omdan-vas-fps-pain-list.component.css']
})
export class OmdanVasFpsPainListComponent extends CaretexComponent implements OnInit {

  vasfpsOmdanem:VasFpsPainExam[] =[]
  id: string
  moduleName:string="אומדני כאב למטופל שמשתף פעולה VAS+FPS"
  currentRowIndex:number
  constructor( 
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private authServive: AuthService,
    private caretxNursingProviderService:CaretxNursingProviderService,
    private toastrService: ToasterService,
    private router: Router,
    private route: ActivatedRoute) { super(medicalFileService)}


  ngOnInit(): void {
    this._title.setTitle('Caretx |  רשימת מבחני כאב');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
    this.getOmdanemFromDB()
  }

  getOmdanemFromDB() {
    this.caretxNursingProviderService.getVasFpsPainOmdanem(this.id,"","").subscribe(
      data => this.vasfpsOmdanem = data,
      err => {
        console.log("Error", err)
        this.vasfpsOmdanem = []
      }
    )
  }


  OnSDrillToOmdan($event,omdan2){
    this.router.navigateByUrl("caretexfile/" + this.id + "/vasfps/" + omdan2.ID)
  }


  
  AddNewNorton($event){
    let filter = '(omdan_status=\'A\' )'
    this.caretxNursingProviderService.getVasFpsPainOmdanem(this.id,filter,"").subscribe((data: VasFpsPainExam[]) => {
      if (data.length > 0){
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
      else{
        this.router.navigateByUrl("caretexfile/" + this.id + "/vasfps/new")
      }
    }); 
  }

  deleteEvent(i){
    this.currentRowIndex = i
    if (this.vasfpsOmdanem[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRecord(this.vasfpsOmdanem[this.currentRowIndex])
        }
      })
    }
  }

  deleteRecord( vasfpsOmdanem:VasFpsPainExam){

    this.caretxNursingProviderService.deleteVasFpsPainOmdan(this.id, vasfpsOmdanem.ID).subscribe({
      next: data => {
        this.getOmdanemFromDB()
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error.error.message)
      }
    })
  }

  generateReport($event,event){
    
  }

  RetrunToList(){
    this.router.navigateByUrl("caretexfile/" + this.id + "/vasfps")
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }

  
}
