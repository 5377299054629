import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PersonalMeasuresHeader } from 'src/app/models/measure';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxNursingProviderService } from '../../nursing-services/Nursing.data-provider.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-personalmeasureslist',
  templateUrl: './personalmeasureslist.component.html',
  styleUrls: ['./personalmeasureslist.component.css']
})
export class PersonalmeasureslistComponent extends CaretexComponent implements OnInit {

  personMeasureHeaders :PersonalMeasuresHeader[] = []
  deleteSysMsgId: string
  deleteAlert: string
  alertTitle: string
  id: string
  currentRowIndex:number
  moduleName:string ="מדדים חיוניים"
  formData : any[] 
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0
  RecordStatus = [{id:"A" , desc:"רשומה פתוחה"},{id:"C" , desc:"רשומה נעולה"}]

  
  constructor(  private _title: Title,
    private route: ActivatedRoute,
    private caretxNursingProviderService:CaretxNursingProviderService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,
    private router: Router,
    private _ar: ActivatedRoute,) {super(medicalFileServive) }

  ngOnInit(): void {
    this._title.setTitle('Caretex |  מדדים');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
    this.getPersonalMeasureHeadersFromDB("") 
    this.formData=  [
      {"formcontrolname":"status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
      {"formcontrolname":"created_at_from","label":"מתאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"created_at_to","label":"עד תאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_from","label":"תאריך נעילה מ" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_to","label":"תאריך נעילה עד" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},

    ]
  }

  getPersonalMeasureHeadersFromDB(filter:string) {
    this.caretxNursingProviderService.getAllPersonalMeasureHeaders(this.id ,"all","","list",filter).subscribe(
      data => {
        this.personMeasureHeaders = data
        this.totalNumOfRecords = data.length
      },
      err => {
        console.log("Error", err)
        this.personMeasureHeaders = []

      }
    )
  }

  SearchWithFilter($event) {
    this.getPersonalMeasureHeadersFromDB($event)
  }

  AddNewMeasure($event){  
    this.router.navigateByUrl("caretexfile/" + this.id + "/personelmeasures/new")
  }

  OnSDrillToeventDetails($event,document){ 
    this.router.navigateByUrl("caretexfile/" + this.id + "/personelmeasures/" + document.ID)
  }

  generateReport($event,event){
  }

  deleteMeasure(i) {
    this.currentRowIndex = i
    if (this.personMeasureHeaders[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false, 
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRecord(this.personMeasureHeaders[this.currentRowIndex])
        }
      })
    }
  }

  deleteRecord(record: PersonalMeasuresHeader) {
    let filter = '(id=' + '\'' + record.ID + '\'' + ')'
    this.caretxNursingProviderService.deletePersonelMeasure(record).subscribe({
      next: data => {
        this.getPersonalMeasureHeadersFromDB("")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error.error.error)
      }
    })
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }
}
