import { propertyMap } from './modelmapper'

// Need Server to send Payload in below structure
export class NotificationMap {
   
    @propertyMap('ID')
    ID: string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('SourceUserID')
    SourceUserID: string
    @propertyMap('TargetUserID')
    TargetUserID: string
    @propertyMap('TargetRole')
    TargetRole: string
    @propertyMap('ShortDescription')
    ShortDescription: string
    @propertyMap('LongDescription')
    LongDescription: string
    @propertyMap('URL')
    URL: string
    @propertyMap('TimeCreated')
    TimeCreated: Date
    @propertyMap('TimeClosed')
    TimeClosed: Date
    @propertyMap('IsOpened')
    IsOpened: boolean
    @propertyMap('IsSeen')
    IsSeen: boolean
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('Type')
    Type: string

    @propertyMap('ActionToDo')
    ActionToDo: string

    @propertyMap('GneratedBy')
    GneratedBy: string



    TimeTxt: string 

    constructor(data?: Object) {

        if (data != undefined && data != null) {
           
            this.ID = data["ID"] 
            this.SourceUserID = data["SourceUserID"]
            this.TargetUserID = data["TargetUserID"] // Logged in user
            this.TargetRole = data["TargetRole"] 
            this.ShortDescription = data["ShortDescription"]
            this.LongDescription = data["LongDescription"]
            this.URL = data["URL"]
            this.IsOpened = data["IsOpened"]
            this.IsSeen = data["IsSeen"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.ActionToDo = data["ActionToDo"]
            this.GneratedBy = data["GneratedBy"]
            this.Type = data["Type"]

            this.TimeTxt = this.calculateNotificationAge()
        }
    }

 

    calculateNotificationAge()  {
        var currentTime = new Date();
        var creationTime = new Date(this.CreatedAt);
        var duration = currentTime.valueOf() - creationTime.valueOf();
        let txt:string = ""

        var diffrenceInMin = duration / (1000 * 60)

        if (diffrenceInMin <= 59) txt =  Math.ceil(diffrenceInMin) + " דקות עברו"

        if (diffrenceInMin > 59 && diffrenceInMin <= 1440) txt =  Math.ceil((diffrenceInMin/60 )) + " שעות עברו"
        if  (diffrenceInMin > 1440)  txt =  Math.ceil((diffrenceInMin/(60 * 24) )) + " ימים עברו"

        return txt
    }
  
}

export class CaretexMessage {
   
    @propertyMap('ID')
    ID: string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('TenantID')
    public TenantID:String
    @propertyMap('Message')
    public Message:string
    @propertyMap('FromUserId')
    public FromUserId:string
    @propertyMap('ToUserId')
    public ToUserId:string

    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"] 
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"] // Logged in user
            this.Message = data["Message"] 
            this.FromUserId = data["FromUserId"]
            this.ToUserId = data["ToUserId"]
            this.TenantID = data["TenantID"]
        }
    }
}