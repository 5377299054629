import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CartexMedicalfileModuleRoutingModule } from './cartex-medicalfile-module-routing.module';
import { SharedModule } from '../caretx-shared-module/shared-module.module';
import { MedicalFileProviderService } from './medicalfile-services/medicalfile.data-provider.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { PipesModule } from '../caretx-custompipes-module/custompipes.module';
import { ModalModule } from '../modal';
import { MedicalfileComponent } from './components/medicalfile/medicalfile.component';
import { CustomersComponent } from './components/medicalfiles-list/customer.component';
import { PersonDetailsEditComponent } from './components/person-details-edit/person-details-edit.component';
import { PersonelDocumentsComponent } from './components/personel-documents/personel-documents.component';
import { ArchivedfilesListComponent } from './components/archivedfiles-list/archivedfiles-list.component';

import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MedicalfileLockComponent } from './components/medicalfile-lock/medicalfile-lock.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [
    MedicalfileComponent,
    CustomersComponent,
    PersonDetailsEditComponent,
    PersonelDocumentsComponent,
    ArchivedfilesListComponent,
    MedicalfileLockComponent


  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    PipesModule,
    ReactiveFormsModule,
    ModalModule,
		MatListModule,
		MatSidenavModule,
		MatButtonModule,
		MatIconModule,
    CartexMedicalfileModuleRoutingModule,
    SharedModule,

    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatSortModule,
    NgSelectModule,
    NgMultiSelectDropDownModule
  ],
  providers:[MedicalFileProviderService]
})
export class CartexMedicalfileModuleModule { }
