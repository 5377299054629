import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { Diagnoses } from '../../../../../src/app/models/diagnoses';
import { DiagnosesTypes } from '../../../../../src/app/models/generalhousedsetups';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { DataService } from '../../../../../src/app/services/data.service';
import { sideNaveGroups } from '../../../../../src/app/services/enums';
import { ReportingService } from '../../../../../src/app/services/reporting.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { CommonInterface } from '../../../../../src/app/shared/SharedInterface';
import { MedicalDiagnosesProviderService } from '../../doctor-services/medical-diagnoses.data-provider.service';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';

import { MedicalFile } from '../../../models/customer';
import jsPDF from 'jspdf';
import { RowInput } from 'jspdf-autotable';
import { User } from '../../../../../src/app/models/user';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-medical-diagnoses',
  templateUrl: './medical-diagnoses.component.html',
  styleUrls: ['./medical-diagnoses.component.css']
})
export class MedicalDiagnosesComponent extends CaretexComponent implements OnInit, CommonInterface {
  personelId: string
  DiagnosesList: Diagnoses[]
  DiagnosesTypes: DiagnosesTypes[] = []
  // admissionId: string
  currentRowIndex: number
  showModal: boolean = false
  currentRowNumClickedModal: number
  alertTitle: string
  deleteAlert: string
  deleteSysMsgId: string
  moduleName: string = "אבחנות רפואיות"
  subject = new Subject<boolean>();
  justRout: boolean
  showGeneratedPdf: boolean = false
  @ViewChild('pdfViewer') pdfViewer;
  selectedFile: MedicalFile
  pdfContent: any

  constructor(
    private _title: Title,
    private authService: AuthService,
    private toastrService: ToasterService,
    private reportingService: ReportingService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public medicalFileServive: MedicalFileProviderService,
    private medicalDiagnosesService: MedicalDiagnosesProviderService,
    private dataservice1: DataService,
  ) {
    super(medicalFileServive)
    this.DiagnosesTypes = this.dataservice1.DiagnosesTypes

  }
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Medical;

  ngOnInit(): void {
    this._title.setTitle('Caretex |אבחנות רפואיות');
    if(this.route.parent != null)
    this.personelId = this.route.parent.snapshot.params['id']
    this.alertTitle = "הודעת מערכת"
    this.CheckIfComponentIsDisabled(this.personelId)
    this.getPersonalDiagnosesFromDB()
  }

  getPersonalDiagnosesFromDB() {
    this.medicalDiagnosesService.getPersonelDiagnoses(this.personelId).subscribe(
      data => {
        this.DiagnosesList = data
      },
      err => {
        console.log("Error", err)
        this.DiagnosesList = []

      }
    )
  }

  unSavedNewlyDiagnoses($event) {
  }


  saveDiagnose() {
    this.medicalDiagnosesService.createPersonelDiagnoses(this.personelId, this.DiagnosesList).subscribe({
      next: data => {
        this.getPersonalDiagnosesFromDB()
      },
      error: error => {
      }
    })
  }

  StopDiagnoses($event) {
    let diagnosesList: Diagnoses[] = []
    $event.Modified = true
    diagnosesList.unshift($event)
    this.medicalDiagnosesService.createPersonelDiagnoses(this.personelId, diagnosesList).subscribe({
      next: data => {
        this.getPersonalDiagnosesFromDB()
      },
      error: error => {
      }
    })
  } 

  // Generat Personel Diagnoses Report

  openPDFViewer() {
    let reportTitle = "סיכום אבחנות רפואיות"
    this.showGeneratedPdf = true
    this.selectedFile = this.medicalFile1
    var doc = new jsPDF()
    let lastYPosition: number
    let xPosTitle:number = 118
    lastYPosition = this.reportingService.SetHeader(doc, this.medicalFile1,reportTitle,xPosTitle)
    this.reportingService.setFooter2(doc, new User() , this.authService.LoggedInUser , new Date() , new Date())

 
    var label = ""
    lastYPosition = this.reportingService.AddHeaderSection2(doc, lastYPosition+8,10, label, "אבחנות רפואיות:", new User() , this.authService.LoggedInUser , new Date() , new Date(), this.medicalFile1,reportTitle,xPosTitle)

    if (this.DiagnosesList.length <= 0) {
      lastYPosition = this.reportingService.AddBulletLinesSection(doc, lastYPosition, "לא נמצאו אבחנות פעילות", new User() , this.authService.LoggedInUser , new Date() , new Date(), this.medicalFile1,reportTitle,xPosTitle)
    } else {
      var colHeader1: RowInput[] = [[ this.reportingService.reverseString("סוג"),this.reportingService.reverseString("אבחנה")]]
      var colVals1: RowInput[] = []
      this.DiagnosesList.forEach(medcalDiagnoses => {
        let tmpValRow: RowInput = [this.reportingService.reverseString(medcalDiagnoses.Type),this.reportingService.reverseString(medcalDiagnoses.MedicalDiagnoses.ShortDescription)]
        colVals1.push(tmpValRow)
      });
      lastYPosition = this.reportingService.AddTableToPDF(doc, lastYPosition, 50, this.medicalFile1, "" + "", "", colHeader1, colVals1,reportTitle,xPosTitle)
    }

    let file: Blob = doc.output('blob')
    this.pdfContent = URL.createObjectURL(file);
    this.pdfViewer.pdfSrc = file
    this.pdfViewer.refresh();
  }

  closePdfDocument() {
    this.showGeneratedPdf = false
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }


  CheckIfDocumentIsDirty() {
    const drugSensitivities = this.DiagnosesList.some(row => {
      return row.New || row.Modified
    })
    return drugSensitivities
  }

  deleteDiagnosesLine($event){
    
  }

}
