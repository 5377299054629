import { User } from 'firebase'
import { MedicalFile } from './customer'
import { FamilyRelations, SocialProvider } from './generalhousedsetups'
import { propertyMap } from './modelmapper'
import { Attachment } from './attachments'




// Need Server to send Payload in below structure
export class SocialAdmission {
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID') //
    public PersonelID: string
    @propertyMap('AdmissionDoneWith')
    public AdmissionDoneWith: string
    @propertyMap('FamilyRelation')//
    public FamilyRelation: FamilyRelations//
    @propertyMap('FamilyRelationId')//
    public FamilyRelationId: String
    @propertyMap('HasGardian')
    public HasGardian: boolean//
    @propertyMap('GurdianComments')
    public GurdianComments: String//
    @propertyMap('Employee')
    public Employee: boolean //
    @propertyMap('IncomeOp1')
    public IncomeOp1: boolean
    @propertyMap('IncomeOp2')
    public IncomeOp2: boolean
    @propertyMap('IncomeOp3')
    public IncomeOp3: boolean
    @propertyMap('IncomeOp4')
    public IncomeOp4: boolean
    @propertyMap('IncomeOp5')
    public IncomeOp5: boolean
    @propertyMap('IncomeOp6')
    public IncomeOp6: boolean//
    @propertyMap('IncomeSources')//
    public IncomeSources: string
    @propertyMap('AllowancesOp1')
    public AllowancesOp1: boolean
    @propertyMap('AllowancesOp2')
    public AllowancesOp2: boolean
    @propertyMap('AllowancesOp3')
    public AllowancesOp3: boolean
    @propertyMap('AllowancesOp4')
    public AllowancesOp4: boolean
    @propertyMap('AllowancesOp5')
    public AllowancesOp5: boolean
    @propertyMap('AllowancesOp6')
    public AllowancesOp6: boolean
    @propertyMap('Allowances')
    public Allowances: string//
    @propertyMap('FunctioningOp1')
    public FunctioningOp1: boolean
    @propertyMap('FunctioningOp2')
    public FunctioningOp2: boolean
    @propertyMap('FunctioningOp3')
    public FunctioningOp3: boolean
    @propertyMap('FunctioningOp4')
    public FunctioningOp4: boolean
    @propertyMap('FunctioningOp5')
    public FunctioningOp5: boolean
    @propertyMap('Physicalfunctioning')
    public Physicalfunctioning: string//
    @propertyMap('CognitiveOp')
    public CognitiveOp: string///////////////////////////
    @propertyMap('Cognitivereview')
    public Cognitivereview: string
    @propertyMap('EmptionOp1')
    public EmptionOp1: boolean
    @propertyMap('EmptionOp2')
    public EmptionOp2: boolean
    @propertyMap('EmptionOp3')
    public EmptionOp3: boolean
    @propertyMap('EmptionOp4')
    public EmptionOp4: boolean
    @propertyMap('EmptionOp5')
    public EmptionOp5: boolean
    @propertyMap('EmptionOp6')
    public EmptionOp6: boolean
    @propertyMap('Emotionalstate')
    public Emotionalstate: string //
    @propertyMap('BehavioureOp')
    public BehavioureOp: string
    @propertyMap('Behaviore')
    public Behaviore: string
    @propertyMap('CommunicationOp')
    public CommunicationOp: string
    @propertyMap('Communication')
    public Communication: string
    @propertyMap('Cognitivefunctioning')
    public Cognitivefunctioning: string
    @propertyMap('SocialProvider')
    public SocialProvider: SocialProvider
    @propertyMap('SocialProviderId')
    public SocialProviderId: String
    @propertyMap('StatusAtAdmission')
    public StatusAtAdmission: string
    @propertyMap('Background')
    public Background: string
    @propertyMap('HousingArrangement')
    public HousingArrangement: string
    @propertyMap('Floor')
    public Floor: string
    @propertyMap('IsElevator')
    public IsElevator: boolean
    @propertyMap('NumOfStairs')
    public NumOfStairs: Number
    @propertyMap('OwnerOfDepartment')
    public OwnerOfDepartment: string
    @propertyMap('AdmissionReson1')
    public AdmissionReson1: string
    @propertyMap('AdmissionReson2')
    public AdmissionReson2: string
    @propertyMap('Socialvisit')
    public Socialvisit: string
    @propertyMap('Socialpersoncontact')
    public Socialpersoncontact: string
    @propertyMap('Socialpersoncontact2')
    public Socialpersoncontact2: string
    @propertyMap('HelperTools')
    public HelperTools: string
    @propertyMap('Holocaustsurvivor')
    public Holocaustsurvivor: boolean
    @propertyMap('Howpassedholocuaust')
    public Howpassedholocuaust: string
    @propertyMap('Significantevents')
    public Significantevents: string
    @propertyMap('Summary')
    public Summary: string
    @propertyMap('Plan')
    public Plan: string
    @propertyMap('Nursinglaw')
    public Nursinglaw: boolean
    @propertyMap('Numofnursinghours')
    public Numofnursinghours: Number
    @propertyMap('PersonDoc')
    public PersonDoc: MedicalFile
    @propertyMap('FamilyRelationships')
    public FamilyRelationships: string
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User
    @propertyMap('Status')
    Status: string
    @propertyMap('Closed_at')
    public Closed_at: Date
    @propertyMap('RiskFactors1')
    public RiskFactors1: boolean
    @propertyMap('RiskFactors2')
    public RiskFactors2: boolean
    @propertyMap('RiskFactors3')
    public RiskFactors3: boolean
    @propertyMap('RiskFactors4')
    public RiskFactors4: boolean
    @propertyMap('RiskFactors5')
    public RiskFactors5: boolean
    @propertyMap('RiskFactors6')
    public RiskFactors6: boolean
    @propertyMap('IsExternalAdmission')
    public IsExternalAdmission: boolean
    @propertyMap('PdfFile')
    public PdfFile: Attachment
    @propertyMap('SpecialProblems')
    public SpecialProblems: string
    @propertyMap('PersonalRequests')
    public PersonalRequests: string
    @propertyMap('Hobbies')
    public Hobbies: string
    @propertyMap('Independent')
    public Independent: boolean //
    @propertyMap('PersonalNeeds')
    public PersonalNeeds: string //
    @propertyMap('Caregiver')
    public Caregiver: boolean //


    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.Independent = data["Independent"]
            this.PersonalNeeds = data["PersonalNeeds"]
            this.Caregiver = data["Caregiver"]
            this.SpecialProblems = data["SpecialProblems"]
            this.PersonalRequests = data["PersonalRequests"]
            this.Hobbies = data["Hobbies"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.PersonelID = data["PersonelID"]
            this.AdmissionDoneWith = data["AdmissionDoneWith"]
            this.FamilyRelation = data["FamilyRelation"]
            this.FamilyRelationId = data["FamilyRelationId"]
            this.HasGardian = data["HasGardian"]
            this.GurdianComments = data["GurdianComments"]
            this.Employee = data["Employee"]
            this.IncomeOp1 = data["IncomeOp1"]
            this.IncomeOp2 = data["IncomeOp2"]
            this.IncomeOp3 = data["IncomeOp3"]
            this.IncomeOp4 = data["IncomeOp4"]
            this.IncomeOp5 = data["IncomeOp5"]
            this.IncomeOp6 = data["IncomeOp6"]
            this.IncomeSources = data["IncomeSources"]
            this.AllowancesOp1 = data["AllowancesOp1"]
            this.AllowancesOp2 = data["AllowancesOp2"]
            this.AllowancesOp3 = data["AllowancesOp3"]
            this.AllowancesOp4 = data["AllowancesOp4"]
            this.AllowancesOp5 = data["AllowancesOp5"]
            this.AllowancesOp6 = data["AllowancesOp6"]
            this.Allowances = data["Allowances"]
            this.FunctioningOp1 = data["FunctioningOp1"]
            this.FunctioningOp2 = data["FunctioningOp2"]
            this.FunctioningOp3 = data["FunctioningOp3"]
            this.FunctioningOp4 = data["FunctioningOp4"]
            this.FunctioningOp5 = data["FunctioningOp5"]
            this.Physicalfunctioning = data["Physicalfunctioning"]
            this.CognitiveOp = data["CognitiveOp"]
            this.Cognitivereview = data["Cognitivereview"]
            this.EmptionOp1 = data["EmptionOp1"]
            this.EmptionOp2 = data["EmptionOp2"]
            this.EmptionOp3 = data["EmptionOp3"]
            this.EmptionOp4 = data["EmptionOp4"]
            this.EmptionOp5 = data["EmptionOp5"]
            this.Emotionalstate = data["Emotionalstate"]
            this.BehavioureOp = data["BehavioureOp"]
            this.Behaviore = data["Behaviore"]
            this.CommunicationOp = data["CommunicationOp"]
            this.Communication = data["Communication"]
            this.Cognitivefunctioning = data["Cognitivefunctioning"]
            this.SocialProvider = data["SocialProvider"]
            this.SocialProviderId = data["SocialProviderId"]
            this.Background = data["Background"]
            this.HousingArrangement = data["HousingArrangement"]
            this.StatusAtAdmission = data["StatusAtAdmission"]
            this.Floor = data["Floor"]
            this.IsElevator = data["IsElevator"]
            this.NumOfStairs = data["NumOfStairs"]
            this.OwnerOfDepartment = data["OwnerOfDepartment"]
            this.AdmissionReson1 = data["AdmissionReson1"]
            this.AdmissionReson2 = data["AdmissionReson2"]
            this.Socialvisit = data["Socialvisit"]
            this.Socialpersoncontact = data["Socialpersoncontact"]
            this.Socialpersoncontact2 = data["Socialpersoncontact2"]
            this.HelperTools = data["HelperTools"]
            this.Holocaustsurvivor = data["Holocaustsurvivor"]
            this.Howpassedholocuaust = data["Howpassedholocuaust"]
            this.Significantevents = data["Significantevents"]
            this.Summary = data["Summary"]
            this.Plan = data["Plan"]
            this.Nursinglaw = data["Nursinglaw"]
            this.Numofnursinghours= data["Numofnursinghours"]
            this.PersonDoc = data["PersonDoc"]
            this.FamilyRelationships = data["FamilyRelationships"] 
            this.ClosedBy = data["ClosedBy"]
            this.ClosedById = data["ClosedById"]
            this.CreatedBy = data["CreatedBy"]
            this.CreatedById = data["CreatedById"]
            this.Status = data["Status"]
            this.Closed_at = data["Closed_at"]
            this.RiskFactors1 = data["RiskFactors1"]
            this.RiskFactors2 = data["RiskFactors2"]
            this.RiskFactors3 = data["RiskFactors3"]
            this.RiskFactors4 = data["RiskFactors4"]
            this.RiskFactors5 = data["RiskFactors5"]
            this.RiskFactors6 = data["RiskFactors6"]
            this.IsExternalAdmission = data["IsExternalAdmission"]
            this.PdfFile = data["PdfFile"]
        }
        else {
            this.CreatedAt = this.UpdatedAt = new Date()
            this.AdmissionDoneWith = ""
            this.StatusAtAdmission = ""
            this.Background = ""
            this.Status = "A"
        }
    }
}



