import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { WorkingShift } from '../../../models/generalhousedsetups';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../services/toastr.service';
import { CaretexComponent } from '../../../shared/CaretexComponent';

@Component({
  selector: 'app-workingshiftssetup',
  templateUrl: './workingshiftssetup.component.html',
  styleUrls: ['./workingshiftssetup.component.css']
})
export class WorkingshiftssetupComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  shifts:WorkingShift[]  = []
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {   super(medicalFileServive)}

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getWorkShiftsFromDB()
  }


  getWorkShiftsFromDB() {
    this.caretxSetupsProviderService.GetWorkShifts().subscribe(
      data => {
        this.shifts = data
      },
      err => {
        console.log("Error", err)
        this.shifts = []

      }
    )
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  
  CheckIfDocumentIsDirty() {
    const eventTypesLisChanged = this.shifts.some(row => {
      return row.New || row.Modified
    })
    return  eventTypesLisChanged
  }

  updateEvent($event){

  }

  BackToList(){
    this.router.navigate(['system_setups']);
  }

  addNewShift(){
    var newWorkingShift = new WorkingShift()
    newWorkingShift.New = true
    newWorkingShift.editMode = true
    newWorkingShift.CreatedAt = new Date()
    newWorkingShift.UpdatedAt = new Date()
    newWorkingShift.RealmID = this.authServive.LoggedInTenantID
    this.shifts.unshift(newWorkingShift)
  }

  deleteRow(i){
    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.shifts[i].New){
          this.caretxSetupsProviderService.DeleteWorkShift( this.shifts[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.shifts.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
              this.getWorkShiftsFromDB()
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.shifts.splice(this.currentRowIndex, 1);
      }
    })
  }

  editRow(i){
    this.shifts[i].editMode = true
    this.shifts[i].Modified = true
  }
  cancelEditRow(i){
    this.shifts[i].editMode  = false;
    this.shifts[i].Modified = false
  }

  updateShift($event){
    this.caretxSetupsProviderService.CreateUpdateWorkShift(this.shifts).subscribe({
     next: data => {

       this.toastrService.Sucesss("", this.toastrService.successMsg)
       this.getWorkShiftsFromDB()
     },
     error: error => {
       this.toastrService.Error("", this.toastrService.errorMsg)
     }
   })  
 }

}
