import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { TabHistory } from 'src/app/models/genericTypes';
import { NurseCognitiveTest, NurseExamination } from 'src/app/models/nurseexamination';
import { NurseRelease } from 'src/app/models/nurserelease';

import { NurseReleaseSteps } from 'src/app/services/enums';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxNursingProviderService } from '../../nursing-services/Nursing.data-provider.service';
import { NurseAdmission } from 'src/app/models/nurseadmission';
import { ToasterService } from 'src/app/services/toastr.service';


@Component({
  selector: 'app-nursingrelease',
  templateUrl: './nursingrelease.component.html',
  styleUrls: ['./nursingrelease.component.css']
})
export class NursingreleaseComponent extends CaretexComponent  implements OnInit {

  isrecordClosed:boolean
  personalId:string
  Releaseid:string
  moduleName:string = "שחרור סיעודי"
  CurrentStep: NurseReleaseSteps = NurseReleaseSteps.BackGround
  eventsSubject: Subject<TabHistory> = new Subject<TabHistory>();
  medicationTitle: string = "תרופות קבועות"
  medicationtype: string = "F"
  zoomInModalData: any;
  dismissModal:boolean = false
  LastNursingAdmission: NurseAdmission
  currentNurseRelease :NurseRelease = new NurseRelease()

  public config: any  = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    uploadImagePath: '/api/upload',
    toolbar: [
        ['misc', [ 'undo', 'redo']],
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
        ['fontsize', ['fontname', 'fontsize', 'color']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  }

  
  constructor(
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private route: ActivatedRoute) {super(medicalFileService)  }

  ngOnInit(): void {

    this.personalId = this.route.parent.snapshot.params['id'];
    this.Releaseid = this.route.snapshot.params.releaseid
    this.CheckIfComponentIsDisabled(this.personalId)


    if (this.Releaseid == 'new') {
      this.getNurseRelease(this.personalId, this.Releaseid, "new")
    }
    else {
      this.getNurseRelease(this.personalId, this.Releaseid, "retreive")
    }



  }


  getNurseRelease(id: string, releaseid: string, mode: string) {
    let filter = null
    if (mode == "new") {
      filter = '(id=' + '\'' + releaseid + '\'' + ')'
    }
    this.caretxNursingProviderService.getNurseRelease(id, filter, mode).subscribe((data: NurseRelease[]) => {
      if (data.length == 1) {
        this.currentNurseRelease = new NurseRelease(data[0])
        this.isrecordClosed = (this.currentNurseRelease.Status == "C")
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }



  selectTab(tabId: string) {
    this.CurrentStep = (tabId as NurseReleaseSteps)
  }

  submitForm(){
    
  }
  BackToList(){

  }

  SaveRelease(){

  }

  LockRelease(){

  }

  updatePhysicalCheck($event){
    this.currentNurseRelease.PhysicalExamination = $event
  }

  updateNextTabFromDiagnoses($event){

  }

  unSavedNewlyDiagnoses($event){

  }

  updateNextTabFromSensitivity($event){
    
  }


  openSelectedDrugInstr($event) {

  }

  personelSensitivityChanged($event) {
    
  }

/*********************** */

  newMainComplaint() {
    if (this.currentNurseRelease.HosptilizedReason != "") {
      Swal.fire({
        title: 'הודעת מערכת',
        text: "האם אתה רוצה למחוק שדה סיבת קבלה ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק'
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentNurseRelease.HosptilizedReason = ""
        }
      })
    }
    else {
      this.currentNurseRelease.HosptilizedReason = ""
    }

  }

  copyMainComplaint() {
    if (this.currentNurseRelease.HosptilizedReason != "") {
      Swal.fire({
        title: 'לידיעתך',
        text: "פעולת העתקה תחליף את הטקסט בשדה סיבת הקבלה ,רוצה להמשיך?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמשיך',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.copyTextFromAdmission("maincomplient")
        }
      })
    }
    else {
      this.copyTextFromAdmission("maincomplient")
    }
  }

  newCurrentDiesese(){
    if (this.currentNurseRelease.CurrentDiseases != "") {
      Swal.fire({
        title: 'הודעת מערכת',
        text: "האם אתה רוצה למחוק שדה מחלה נוכחית?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק'
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentNurseRelease.CurrentDiseases = ""
        }
      })
    }
    else {
      this.currentNurseRelease.CurrentDiseases = ""
    }
  }

  copyCurrentDiesese() {
    if (this.currentNurseRelease.CurrentDiseases != "") {

      Swal.fire({
        title: 'לידיעתך',
        text: "פעולת העתקה תחליף את הטקסט בשדה מחלה נוכחית ,רוצה להמשיך?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמשיך',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.copyTextFromAdmission("currentdeiseses")
        }
      })
    }
    else {
      this.copyTextFromAdmission("currentdeiseses")
    }
  }

  newBackgroundDiesese(){
    if (this.currentNurseRelease.PreviouseDiseases != "") {
      Swal.fire({
        title: 'הודעת מערכת',
        text: "האם אתה רוצה למחוק שדה מחלות רקע?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.currentNurseRelease.PreviouseDiseases = ""
        }
      })
    }
    else {
      this.currentNurseRelease.PreviouseDiseases = ""
    }

  }

  copyBackgroundDiesese() {
    if (this.currentNurseRelease.PreviouseDiseases != "") {
      Swal.fire({
        title: 'לידיעתך',
        text: "פעולת העתקה תחליף את הטקסט בשדה מחלות רקע ,רוצה להמשיך?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמשיך',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.copyTextFromAdmission("backgrounddeiseses")
        }
      })
    }
    else {
      this.copyTextFromAdmission("backgrounddeiseses")
    }
  }

  bindFieldData(responseData) {
    this.currentNurseRelease[this.zoomInModalData.field] = responseData;

  }

  DismissCtxTextareaModal(){
    this.dismissModal = true
}

copyTextFromAdmission(targetField: string) {
  let status = "C"
  let filter = '(status=' + '\'' + status + '\'' + ')'
  if (this.currentNurseRelease.LastMedicalAdmission == undefined || this.currentNurseRelease.LastMedicalAdmission == null) {
    this.caretxNursingProviderService.getNurseAdmissions( this.personalId, filter, "list").subscribe((data: NurseAdmission[]) => {
      if (data.length == 1) {
        this.LastNursingAdmission = new NurseAdmission(data[0])
        switch (targetField) {
          case "maincomplient":
            this.currentNurseRelease.HosptilizedReason = this.currentNurseRelease.LastMedicalAdmission.MainComplaint
            break
          case "currentdeiseses":
            this.currentNurseRelease.CurrentDiseases = this.currentNurseRelease.LastMedicalAdmission.CurrentDisease
            break
          case "backgrounddeiseses":
            this.currentNurseRelease.PreviouseDiseases = this.currentNurseRelease.LastMedicalAdmission.OldComplaint
          default:
            break
        }
      }
      else {
        let errorMsg = "העתקה נכשלה"
        this.toastrService.Error("", errorMsg)
      }
    });
  }
  else {
    switch (targetField) {
      case "maincomplient":
        this.currentNurseRelease.HosptilizedReason = this.currentNurseRelease.LastMedicalAdmission.MainComplaint
        break
      case "currentdeiseses":
        this.currentNurseRelease.CurrentDiseases = this.currentNurseRelease.LastMedicalAdmission.CurrentDisease
        break
      case "backgrounddeiseses":
        this.currentNurseRelease.PreviouseDiseases = this.currentNurseRelease.LastMedicalAdmission.OldComplaint
      default:
        break
    }
  }

}


/**********************Deactivate********************** */
canDeactivate() {
  this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
}


CheckIfDocumentIsDirty() {
return false
}

/************************************************ */
ClearTextField() {

}

CopyLastReportedFollowUp() {

}
}
