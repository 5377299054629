import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NurseTask } from 'src/app/models/nurseinstruction';
import { AuthService } from 'src/app/services/auth.service';
import { NurseTasksEnum } from 'src/app/services/enums';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxNursingProviderService } from '../../nursing-services/Nursing.data-provider.service';

@Component({
  selector: 'app-nursetasks',
  templateUrl: './nursetasks.component.html',
  styleUrls: ['./nursetasks.component.css']
})
export class NursetasksComponent extends CaretexComponent implements OnInit {

  moduleName: string = "הוראות לצוות אחים/יות"
  nurseTasks: NurseTask[] = []
  archivedNurseTasks: NurseTask[] = []
  personelid: string
  modalTarget = ""
  CurrentStep: string = NurseTasksEnum.Active
  selectedResolution: string = "D"
  FromDate: Date
  ToDate: Date
  instrStatus: string = "Y"

  @Input() PersonalId: string
  @Input() IsFromCentralizedPage: boolean = false
  

  Resolutions: { id: string; val: string }[] = [
    { id: "D", val: "היום" },
    { id: "W", val: "השבוע" },
    { id: "M", val: "החודש" },
    { id: "T", val: "לפי תאריך" },
  ];

  inststatuses: { id: string; val: string }[] = [
    { id: "Y", val: "בוצעה" },
    { id: "N", val: "לא בוצעה" },
  ];



  dateDisabled: boolean = true

  constructor(public medicalFileService: MedicalFileProviderService, private toastrService: ToasterService,
    private router: Router,
    private authServive: AuthService,
    private caretxNursingProviderService: CaretxNursingProviderService,
    private _title: Title,
    private route: ActivatedRoute) { super(medicalFileService) }

  ngOnInit(): void {
    this._title.setTitle('Caretx | הוראות לצוות אחים/יות');
    if (!this.IsFromCentralizedPage) {
      this.personelid = this.route.parent.snapshot.params['id']
    }else {
      this.personelid = this.PersonalId
    }

    this.CheckIfComponentIsDisabled(this.personelid)
    let filter = '?filter=D' + '&' + "staus=A"
    this.getNurseTasksForTeekFromDB(filter)
  }

  selectTab($event) {
    this.CurrentStep = ($event as NurseTasksEnum)
    this.selectedResolution = "D"
    this.instrStatus = "Y"
  }

  getNurseTasksForTeekFromDB(filter: string) {
    this.caretxNursingProviderService.getNurseTasks(this.personelid, filter, "").subscribe(
      data => {
        this.nurseTasks = data
      },
      err => {
        this.nurseTasks = []
      }
    )
  }

  resolutionCalchanged($event) {
    let val = $event.currentTarget.value
    this.selectedResolution = val
    if (val == "T") this.dateDisabled = false
    else {
      this.dateDisabled = true
      this.ToDate = this.FromDate = null
    }

  }

  UpdateFromDate($event) {
    this.FromDate = $event.currentTarget.value
  }

  UpdateToDate($event) {
    this.ToDate = $event.currentTarget.value
  }

  clear() {
    this.ToDate = this.FromDate = null
  }

  createFilter(status) {
    let filter = ""
    switch (this.selectedResolution) {
      case "D":
        filter = '?filter=D' + '&' + "staus=" + status
        break
      case "W":
        filter = '?filter=W' + '&' + "staus=" + status
        break
      case "M":
        filter = '?filter=M' + '&' + "staus=" + status
        break

      case "T":
        const d = new Date(this.ToDate.toString());
        d.setDate(d.getDate() + 1);

        filter = '?filter=T' + '&' + 'fromdate=' + this.FromDate + '&' + 'todate=' + d.toISOString().split('T')[0] + '&' + "staus=" + status
        break
      default:
        filter = '?filter=D' + '&' + "staus=" + status
        break

    }

    return filter
  }

  search(status) {

    if (this.selectedResolution == "T" && (this.FromDate == null || this.ToDate == null)) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "שדה אחד או יותר של תאריך חסר !",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false

      })
    } else if (this.selectedResolution == "T" && (this.ToDate < this.FromDate)) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "שדה עד תאריך לא יכול להיות לפני שדה מתאריך",
        icon: 'warning',

      })
    } else {
      let filter = this.createFilter(status)

      this.caretxNursingProviderService.getNurseTasks(this.personelid, filter, "").subscribe(
        data => {
          this.nurseTasks = data
        },
        err => {
          this.nurseTasks = []
        }
      )
    }




  }

  markTaskAsDone(nurseTask) {
    if (this.CheckIfCanBeUpdated(nurseTask)) {
      nurseTask.Status = "Y"
      nurseTask.UpdatedAt = new Date()
      nurseTask.AssignedToId = this.authServive.LoggedInUser.UID
      this.DoUpdate(nurseTask)
    }

  }

  async markTaskAsNotDone(nurseTask: NurseTask) {

    if (this.CheckIfCanBeUpdated(nurseTask)) {
      nurseTask.Status = "N"
      nurseTask.UpdatedAt = new Date()
      nurseTask.AssignedToId = this.authServive.LoggedInUser.UID


      const { value: text } = await Swal.fire({
        input: 'textarea',
        inputLabel: 'סיבה לאי ביצוע הוראה',
        inputPlaceholder: 'הקלד את הסיבה כאן...',
        inputAttributes: {
          'aria-label': 'הקלד את הסיבה כאן'
        },
        showCancelButton: true
      })

      if (text) {
        nurseTask.ResolveComment = text
        this.DoUpdate(nurseTask)
      }
    }


  }

  CheckIfCanBeUpdated(nurseTask: NurseTask) {

    let dateofnow: Date = new Date()
    const msBetweenDates = dateofnow.getTime() - (new Date(nurseTask.DueDate)).getTime();
    let diffInMinutes: number = msBetweenDates / (1000 * 60)
    if (diffInMinutes < -30) {
      Swal.fire({
        title: 'לידיעתך',
        text: "עדיין לא הגיע הזמן לבצע משימה זאת !",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false

      }).then((result) => {
        if (result.isConfirmed) {
          return false
        }
      })
      // passed 2 hours from due date then prevent user from executing
    } else if (diffInMinutes > 120) {
      Swal.fire({
        title: 'לידיעתך',
        text: "המשימה הזאת עברה את תאריך/זמן ההגשה ",
        icon: 'warning',
        allowOutsideClick: false,
        allowEscapeKey: false

      }).then((result) => {
        if (result.isConfirmed) {
          return false
        }
      })
    }else{
      return true
    }


  }

  DoUpdate(nurseTask: NurseTask) {
    this.caretxNursingProviderService.updateNurseTask(nurseTask).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        let filter = this.createFilter('A')
        this.getNurseTasksForTeekFromDB(filter)
      },
      error: error => {
        this.toastrService.Error("", error.error.error)
      }
    })
  }

  searchArchived() {
    if (this.selectedResolution == "T" && (this.FromDate == null || this.ToDate == null)) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "שדה אחד או יותר של תאריך חסר !",
        icon: 'warning',

      })
    } else if (this.selectedResolution == "T" && (this.ToDate < this.FromDate)) {
      Swal.fire({
        title: "הודעת מערכת",
        text: "שדה עד תאריך לא יכול להיות לפני שדה מתאריך",
        icon: 'warning',

      })
    } else {
      let filter = this.createFilter(this.instrStatus)

      this.caretxNursingProviderService.getNurseTasks(this.personelid, filter, "").subscribe(
        data => {
          this.archivedNurseTasks = data
        },
        err => {
          this.archivedNurseTasks = []
        }
      )
    }
  }

  instrStatuschanged($event) {
    this.instrStatus = $event.currentTarget.value
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty(){
    return false;
  }
  
}
