
import { MedicalFile } from './customer'
import { propertyMap } from './modelmapper'
import { User } from './user'




// Need Server to send Payload in below structure
export class SocialTrack{
    @propertyMap('ID')
    public ID: string
    @propertyMap('CreatedAt')
    public CreatedAt: Date
    @propertyMap('UpdatedAt')
    public UpdatedAt: Date

    @propertyMap('NextTrackDate')
    public NextTrackDate: Date

    
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('PersonelID') //
    public PersonelID: string
    @propertyMap('TreatmentPlan')
    public TreatmentPlan: string
    @propertyMap('FunctioningStatus')
    public FunctioningStatus: string
    @propertyMap('FamilyRelation')
    public FamilyRelation: string
    @propertyMap('SocialComm')
    public SocialComm: string
    @propertyMap('Releatiowithcrew')
    public Releatiowithcrew: string
    @propertyMap('Dailyoperation')
    public Dailyoperation: string
    @propertyMap('Specialrequests')
    public Specialrequests: string
    @propertyMap('PersonDoc')
    public PersonDoc: MedicalFile
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User
    @propertyMap('CreatedById')
    public CreatedById: string

    public CreatedBy: User
    @propertyMap('Status')
    Status: string
    @propertyMap('Closed_at')
    public Closed_at: Date
    @propertyMap('SpecialProblems')
    public SpecialProblems: string



    
    constructor(data?: Object) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.PersonelID = data["PersonelID"]
            this.TreatmentPlan = data["TreatmentPlan"]
            this.FunctioningStatus = data["FunctioningStatus"]
            this.FamilyRelation = data["FamilyRelation"]
            this.SocialComm = data["SocialComm"]
            this.Releatiowithcrew = data["Releatiowithcrew"]
            this.Dailyoperation = data["Dailyoperation"]
            this.Specialrequests = data["Specialrequests"]
            this.PersonDoc = data["PersonDoc"]
            this.ClosedBy = data["ClosedBy"]
            this.ClosedById = data["ClosedById"]
            this.CreatedBy = data["CreatedBy"]
            this.CreatedById = data["CreatedById"]
            this.Status = data["Status"]
            this.Closed_at = data["Closed_at"]
            this.SpecialProblems = data["SpecialProblems"]
            this.NextTrackDate = data["NextTrackDate"]
        }
        else {
            this.CreatedAt = this.UpdatedAt = new Date()
    
            this.Status = "A"
        }
    }
}



