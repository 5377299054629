import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CaretxMedicalProviderService } from 'src/app/caretx-doctors-module/doctor-services/Medical.data-provider.service';
import { PhysicalExamination } from 'src/app/models/physicalexamination';
import { sideNaveGroups } from 'src/app/services/enums';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CommonInterface } from 'src/app/shared/SharedInterface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-physicalcheck-list',
  templateUrl: './physicalcheck-list.component.html',
  styleUrls: ['./physicalcheck-list.component.css']
})
export class PhysicalcheckListComponent extends CaretexComponent implements OnInit,CommonInterface  {
  id: string
  currentRowIndex: number
  physicalExaminations: PhysicalExamination[]
  moduleName:string = "בדיקות גופניות"
  constructor(
    private router: Router,
    private _title: Title, 
    private route: ActivatedRoute,
    private caretxMedicalProviderService: CaretxMedicalProviderService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,
  ) {super(medicalFileServive) }
  SideNaveGroup: sideNaveGroups = sideNaveGroups.Medical;

  ngOnInit(): void {
    this._title.setTitle('Caretex | בדיקות גופניות');
    this.id = this.route.parent.snapshot.params['id'] 
    this.CheckIfComponentIsDisabled(this.id)
    this.getPhysicalChecksFromDB()
  }

  getPhysicalChecksFromDB() {
    this.caretxMedicalProviderService.getExamination(this.id,null).subscribe(
      data => this.physicalExaminations = data,
      err => {
        this.physicalExaminations = []
      }
    )
  }

  deleteRow(i){ 
    this.currentRowIndex = i
    if (this.physicalExaminations[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletePhysicalcheck(this.physicalExaminations[this.currentRowIndex])
        }
      })
    }
  }

  deletePhysicalcheck(physicalCheck: PhysicalExamination) {
    let filter = '(id=' + '\'' + physicalCheck.ID + '\'' + ')'
    this.caretxMedicalProviderService.deleteExamination(this.id , filter).subscribe({
      next: data => {
        this.getPhysicalChecksFromDB()
        this.toastrService.Sucesss("", "הרשומה נמחקה")
      },
      error: error => {
        Swal.fire({
          title: "הודעת מערכת",
          text: error.error.error,
          icon: 'warning',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      }
    })
  }

  OnSDrillToPhysicalCheck($event,physicalExamination){
    this.caretxMedicalProviderService.SetSelectedPhysicalCheckRecord = physicalExamination
    this.router.navigateByUrl("caretexfile/" + this.id + "/physicalchecks/" + physicalExamination.ID)
  }
  generateReport($event,physicalExamination){ 
  }
  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }
  CheckIfDocumentIsDirty(){
    return false;
  }
  AddNewPhysicalCheck($event){
    let filter = '(status=\'A\')'
    this.caretxMedicalProviderService.getExamination( this.id,filter).subscribe((data: PhysicalExamination[]) => {
      if (data.length > 0){
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
      else{
        this.router.navigateByUrl("caretexfile/" + this.id + "/physicalchecks/new")
      }
    });
  }
}
