import { propertyMap } from './modelmapper'
import { User } from './user'

export class TeekNote {
  
    @propertyMap('NoteId')
    public NoteId:number
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('LastUpdate_at')
    public LastUpdate_at:Date
    @propertyMap('IsFileNote')
    public IsFileNote:boolean
    @propertyMap('Created_at')
    public Created_at:Date
    @propertyMap('NoteText')
    public NoteText:string
    @propertyMap('Status')
    public Status:string
    @propertyMap('UserId')
    public UserId:string
    public User:User

 

    constructor(isFileNote:boolean, uid:string , id:string) {
        this.NoteText = ""
        this.Created_at = new Date()
        this.LastUpdate_at = new Date()
        this.UserId = uid
        this.Status = "A"
        this.IsFileNote = isFileNote
        this.PersonelID = id
    }


}