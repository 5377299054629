

import { propertyMap } from './modelmapper'
import { User } from './user'




export class Consultatory {
    @propertyMap('ID')
    public ID:string
    @propertyMap('PersonelID')
    public PersonelID:string
    @propertyMap('CreatedAt')
    public CreatedAt:Date
    @propertyMap('UpdatedAt')
    public UpdatedAt:Date
    @propertyMap('TenantID')
    public TenantID: string
    @propertyMap('Status')
    public Status: string
    @propertyMap('Purpose')
    public Purpose: string
    @propertyMap('ConsultantName')
    public ConsultantName: string
    @propertyMap('Role')
    public Role: string
    @propertyMap('DueDate')
    public DueDate: Date
    @propertyMap('LeavingTime')
    public LeavingTime: Date
    @propertyMap('ReturnTime')
    public ReturnTime: Date
    @propertyMap('Report')
    public Report: string
    @propertyMap('TasksToDo')
    public TasksToDo: string
    @propertyMap('ClosedById')
    public ClosedById: string
    @propertyMap('ClosedBy')
    public ClosedBy: User
    @propertyMap('ClosedAt')
    public ClosedAt:Date
    
    constructor(data?: any) {

        if (data != null) {
            this.ID = data.ID   
            this.PersonelID = data.PersonelID
            this.CreatedAt = data.CreatedAt
            this.UpdatedAt = data.UpdatedAt 
            this.Status = data.Status
            this.TenantID = data.TenantID
            this.Purpose = data.Purpose
            this.ConsultantName = data.ConsultantName
            this.Role = data.Role
            this.DueDate = data.DueDate
            this.LeavingTime = data.LeavingTime
            this.ReturnTime = data.ReturnTime
            this.ClosedById = data.ClosedById
            this.ClosedBy = data.ClosedBy
            this.ClosedAt = data.ClosedAt
            this.Report = data.Report
            this.TasksToDo = data.TasksToDo

        }
        else {
            this.Status = "A"

        }
    }



}