import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { share } from 'rxjs/internal/operators/share';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service'
import { MedicalFile } from '../../../../../src/app/models/customer';
import { sideNaveGroups } from '../../../../../src/app/services/enums';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss']
})

export class SideNavbarComponent implements OnInit {
  @Input() SideNaveGroup: sideNaveGroups
  id: string;
  medicalFile$: Observable<MedicalFile>
  collapsed = false;
  screenWidth = 0;
  navData = []

  constructor(
    private medicalFileService: MedicalFileProviderService,
    private router: Router,
    private route: ActivatedRoute,) {
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.medicalFile$ = this.medicalFileService.getMedicalFile(this.id).pipe(share())

    this.medicalFile$.subscribe({
      next: data => {
        this.navData = [
          {
            routeLink: '#',
            icon: 'menu-medicine.svg',
            label: 'רפואה',
            items: [
              { routerLink: '/caretexfile/' + this.id + '/medicaladmissions', label: 'קבלה רפואית'  , isEnabled:true},
              { routerLink: '/caretexfile/' + this.id + '/medicalobservations', label: 'מעקב רפואי' , isEnabled:data.IsMedicalAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/druginstrs', label: 'הוראת תרופה' , isEnabled:true},
              { routerLink: '/caretexfile/' + this.id + '/nurseinstrs', label: 'הוראה לאחות', isEnabled:true},
              { routerLink: '/caretexfile/' + this.id + '/MedicalDiagnoses', label: 'אבחנות רפואיות' , isEnabled:data.IsMedicalAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/sensitivities', label: 'רגישות' , isEnabled:data.IsMedicalAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/physicalchecks', label: 'בדיקות גופניות', isEnabled:data.IsMedicalAdmissioned },
              { routerLink: '/caretexfile/' + this.id + '/treatmentcoordination', label: 'טופס שיחות עם המשפחה', isEnabled:data.IsMedicalAdmissioned },
              { routerLink: '/caretexfile/' + this.id + '/consultatory', label: 'דו"ח יועצים /מומחים', isEnabled:data.IsMedicalAdmissioned },
              { routerLink: '/caretexfile/' + this.id + '/hospitalreferrals', label: 'הפניות', isEnabled:data.IsMedicalAdmissioned },
              { routerLink: '/caretexfile/' + this.id + '/medicalexams', label: 'מבחנים רפואיים', isEnabled:data.IsMedicalAdmissioned },
              { routerLink: '/caretexfile/' + this.id + '/hazards', label: 'זיהומים', isEnabled:data.IsMedicalAdmissioned },
              { routerLink: '/caretexfile/' + this.id + '/eventmedical', label: 'דווח אירוע חריג' , isEnabled:true},
              { routerLink: '/caretexfile/' + this.id + '/MedicalRelease', label: 'שחרור רפואי' , isEnabled:data.IsMedicalAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/laboratory', label: 'מעבדה', isEnabled:data.IsMedicalAdmissioned },
              { routerLink: '/caretexfile/' + this.id + '/passovernotice', label: 'דו"ח פטירה' , isEnabled:data.IsMedicalAdmissioned},
             
            ]
          },
          {
            routeLink: '#',
            icon: 'menu-nursing.svg',
            label: 'סיעוד',
            items: [
              { routerLink: '/caretexfile/' + this.id + '/nurseadmission', label: 'קבלה סיעודית' , isEnabled:true},
              { routerLink: '/caretexfile/' + this.id + '/nurseingfollowup', label: 'מעקב סיעודי' , isEnabled:data.IsNusrsingAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/nurseingmedication', label: 'פנקס תרופות' , isEnabled:data.IsNusrsingAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/vaccines', label: 'פנקס חיסונים' , isEnabled:data.IsNusrsingAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/nursetasks', label: 'הוראות לצוות אחים/יות' , isEnabled:data.IsNusrsingAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/personelmeasures', label: 'מדדים חיוניים' , isEnabled:true},
              { routerLink: '/caretexfile/' + this.id + '/bedsores', label: 'פצעי לחץ' , isEnabled:data.IsNusrsingAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/physicalnursecheck', label: 'הערכה גופנית' , isEnabled:data.IsNusrsingAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/cognitivetest', label: 'הערכה קוגניטיבית' , isEnabled:data.IsNusrsingAdmissioned},

              { routerLink: '/caretexfile/' + this.id + '/nursefamilymeeting', label: 'שיחה עם משפחה' , isEnabled:data.IsNusrsingAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/nursefamilyvisit', label: 'ביקורי משפחה' , isEnabled:data.IsNusrsingAdmissioned},


              { routerLink: '/caretexfile/' + this.id + '/eventnursing', label: 'דווח אירוע חריג' , isEnabled:true},
              { routerLink: '/caretexfile/' + this.id + '/nurselaborders', label: 'בדיקות מעבדה' , isEnabled:data.IsNusrsingAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/omdanem', label: 'אומדנים' , isEnabled:data.IsNusrsingAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/nursefoodreport', label: 'דיווח צריכת מזון יומית' , isEnabled:data.IsNusrsingAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/nurserelease', label: 'שחרור סיעודי' , isEnabled:data.IsMedicalAdmissioned},

            ]
          },
          {
            routeLink: '#',
            icon: 'menu-network.svg',
            label: 'עבודה סוציאלית',
            items: [
              { routerLink: '/caretexfile/' + this.id + '/socialadmission', label: 'קבלה סוציאלית' , isEnabled:true},
              { routerLink: '/caretexfile/' + this.id + '/socialtracks', label: 'מעקב סוציאלי שוטף' , isEnabled:data.IsSocialAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/family_conversation', label: 'שיחות עם בני משפחה' , isEnabled:data.IsSocialAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/familyvisits', label: 'ביקורי משפחה' , isEnabled:data.IsSocialAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/pocket_track', label: 'מעקב דמי כיס' , isEnabled:data.IsSocialAdmissioned},
   

              { routerLink: '/caretexfile/' + this.id + '/socialregistration', label: 'טופס רישום' , isEnabled:data.IsSocialAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/socialhelpreport', label: 'דו"ח מתן שירותים לזקן' , isEnabled:data.IsSocialAdmissioned},
     
              { routerLink: '/caretexfile/' + this.id + '/socialrelease', label: 'מכתב שחרור' , isEnabled:data.IsSocialAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/socialperiodicalreport', label: 'דו"ח תקופתי' , isEnabled:data.IsSocialAdmissioned},
              { routerLink: '/caretexfile/' + this.id + '/socialforms', label: 'טפסים' , isEnabled:true},
            ]
          },
          {
            routeLink: '#',
            icon: 'menu-physio.svg',
            label: 'פיזוטרפיה',
            items: [
              { routerLink: '/caretexfile/' + this.id + '/physiotherapyfollowup', label: 'מעקבי פיזוטרפיה', isEnabled:true },
              { routerLink: '/caretexfile/' + this.id + '/phsiotherapyassessment', label: 'הערכת פיזותרפיה - מקוצר', isEnabled:true },
              { routerLink: '/caretexfile/' + this.id + '/physicaldiagnose', label: 'אבחון פיזיקאלי (סיעודי מורכב)', isEnabled:true },
              { routerLink: '/caretexfile/' + this.id + '/physicalreport', label: 'אבחון פיזיקאלי (תשוש נפש)', isEnabled:true },
            ]
          },
         {
            routeLink: '#',
            icon: 'menu-diet.svg',
            label: 'דיאטנית',
            items: [
              { routerLink: '/caretexfile/' + this.id + '/dieticianfollowup', label: 'מעקב תזונתי', isEnabled:true },
              { routerLink: '/caretexfile/' + this.id + '/dieticianassessment', label: 'הערכה תזונתית' , isEnabled:true},
              { routerLink: '/caretexfile/' + this.id + '/yearlyweightreport', label: 'דו"ח משקל שנתי' , isEnabled:true},
              { routerLink: '/caretexfile/' + this.id + '/dieticianrecommendation', label: 'המלצות דאיטאניות' , isEnabled:true},
              { routerLink: '/caretexfile/' + this.id + '/dailyfoodreport', label: 'דו"ח צריכת מזון יומית' , isEnabled:true},
            ]
          },

          
          {
            routeLink: '#',
            icon: 'menu-diet.svg',
            label: 'ריפוי בעיסוק',
            items: [
              { routerLink: '/caretexfile/' + this.id + '/occupationaltherapyfollowup', label: 'מעקב ריפוי בעיסוק', isEnabled:true },

              { routerLink: '/caretexfile/' + this.id + '/occupationaltherapyassessment', label: 'הערכת רפוי בעיסוק למטופל', isEnabled:true },

            ]
          },


          
        ]
      }
    })



  }

  checkActive(item, parent = null) {
    if (parent) {
      return item.some(e => window.location.pathname.includes(e.routerLink.substring(e.routerLink.lastIndexOf('/') + 1)));
    }
    return window.location.pathname.includes(item.substring(item.lastIndexOf('/') + 1));
  }

}
