import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Hospital } from 'src/app/models/generalhousedsetups';
import { AuthService } from 'src/app/services/auth.service';
import { DialogResult } from 'src/app/services/enums';
import { ToasterService } from 'src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';

@Component({
  selector: 'app-hospitals',
  templateUrl: './hospitals.component.html',
  styleUrls: ['./hospitals.component.css']
})
export class HospitalsComponent extends CaretexComponent implements OnInit {
  TabId:string = "main"
  hospitals:Hospital[]  = []
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private authServive: AuthService,
    private router: Router,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {  super(medicalFileServive)}

  ngOnInit(): void {
    this.alertTitle = "הודעת מערכת"
    this.getHospitalssFromDB()
  }


  getHospitalssFromDB(){
    this.caretxSetupsProviderService.GetHospitals(this.authServive.LoggedInTenantID).subscribe(
      data => {
        this.hospitals = data
      },
      err => {
        console.log("Error", err)
        this.hospitals = []

      }
    )
  }

  BackToList(){
    this.router.navigate(['system_setups']);
  }

  addNewHospital(){
    var newHospital = new Hospital()
    
    newHospital.New = true
    newHospital.editMode = true
    newHospital.CreatedAt = new Date()
    newHospital.UpdatedAt = new Date()
    newHospital.RealmID = this.authServive.LoggedInTenantID
    this.hospitals.unshift(newHospital)
  }


  deleteRow(i){

    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.hospitals[i].New){
          this.caretxSetupsProviderService.DeleteHospital(this.authServive.LoggedInTenantID, this.hospitals[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.hospitals.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.hospitals.splice(this.currentRowIndex, 1);
      }
    })
  }


 
  editRow(i){
    this.hospitals[i].editMode = true
    this.hospitals[i].Modified = true
  }

  cancelEditRow(i){
    this.hospitals[i].editMode  = false;
    this.hospitals[i].Modified = false;
  }




  updateHospital($event){

     this.caretxSetupsProviderService.CreateUpdateHospital(this.authServive.LoggedInTenantID,this.hospitals).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getHospitalssFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })  


    
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.hospitals.some(row => {
      return row.New || row.editMode
    })
    return  hospitalsLisChanged
  }

}
