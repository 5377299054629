import { MealType } from '../services/enums';
import { propertyMap } from './modelmapper'
import { User } from './user'

export interface IMealComponent {
    name: string;
    consumption: string;
    size: number;
}

export class DailyMealReport {
    @propertyMap('ID')
    ID: string
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('Status')
    Status: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('Meals')
    Meals: Meal[]
    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User
    @propertyMap('ClosedAt')
    ClosedAt: Date
    @propertyMap('ClosedById')
    public ClosedById: string
    public ClosedBy: User

    constructor(data?: any) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.Status = data["Status"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.CreatedById = data["CreatedById"]
            this.CreatedBy = data["CreatedBy"]
            this.ClosedAt = data["ClosedAt"]
            this.ClosedById = data["ClosedById"]
            this.ClosedBy = data["ClosedBy"]
    
            // Loop On Meals 
            this.Meals=[]
            data["Meals"].forEach(meal => {
                this.Meals.push(new Meal(meal))
            });
    
        }
    }
}

export class MealComponent {
    @propertyMap('ID')
    ID: Number
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('ComponentName')
    ComponentName: string
    @propertyMap('ComponentSize')
    ComponentSize: number
    @propertyMap('Consumption')
    Consumption: string
    @propertyMap('Order')
    Order: Number

    constructor(data?: any) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.ComponentName = data["ComponentName"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.ComponentSize = data["ComponentSize"]
            this.Consumption = data["Consumption"]
            this.Order = data["Order"]
        }
    }

}

export class Meal {
    @propertyMap('ID')
    ID: Number
    @propertyMap('PersonelID')
    PersonelID: string
    @propertyMap('TenantID')
    TenantID: string
    @propertyMap('CreatedAt')
    CreatedAt: Date
    @propertyMap('UpdatedAt')
    UpdatedAt: Date
    @propertyMap('Type')
    Type: string
    @propertyMap('Components')
    Components: MealComponent[] = []
    @propertyMap('CreatedById')
    public CreatedById: string
    public CreatedBy: User

    constructor(data?: any, type?: MealType,personelId?:string , tenantID?:string) {
        if (data != undefined && data != null) {
            this.ID = data["ID"]
            this.PersonelID = data["PersonelID"]
            this.TenantID = data["TenantID"]
            this.CreatedAt = data["CreatedAt"]
            this.UpdatedAt = data["UpdatedAt"]
            this.Type = data["Type"]
            this.CreatedById = data["CreatedById"]
            this.CreatedBy = data["CreatedBy"]
           
            
            this.Components=[]
            data["Components"].forEach(component => {
                this.Components.push(new MealComponent(component))
            });

        } else {

            this.PersonelID = personelId
            this.TenantID = tenantID

            if (type == MealType.Morning)
                this.prepareBreakfastMeal()
            if (type == MealType.Noon)
                this.prepareNoonMeal()
            if (type == MealType.Evening)
                this.prepareEveningsMeal()
            if (type == MealType.Intermediate)
                this.prepareBreakfastMeal()
        }

    }

    prepareBreakfastMeal() {
        let components: { [order: number]: IMealComponent } = {
            1: { name: "לחם", consumption: "", size: 0 },
            2: { name: "שתיה", consumption: "", size: 0 },
            3: { name: "גבינה לבנה", consumption: "", size: 0 },
            4: { name: "אשל/שמנת", consumption: "", size: 0 },
            5: { name: "דיסה", consumption: "", size: 0 },
            6: { name: "ירקות", consumption: "", size: 0 },
            7: { name: "ביצה", consumption: "", size: 0 },
            8: { name: "אחר", consumption: "", size: 0 },

        };
       
        this.Type = MealType.Morning
        for (let [key, value] of Object.entries(components)) {
            let comp1 = new MealComponent()
            comp1.CreatedAt = new Date()
            comp1.UpdatedAt = comp1.CreatedAt
            comp1.PersonelID = this.PersonelID
            comp1.TenantID = this.TenantID
            comp1.ComponentName = components[key].name
            comp1.ComponentSize = components[key].size
            comp1.Consumption = components[key].consumption
            this.Components.push(comp1)
        }
    }
    prepareNoonMeal() {
        let components: { [order: number]: IMealComponent } = {
            1: { name: "לחם", consumption: "", size: 0 },
            2: { name: "שתיה", consumption: "", size: 0 },
            3: { name: "מרק", consumption: "", size: 0 },
            4: { name: "מנה עיקרית", consumption: "", size: 0 },
            5: { name: "תוספת", consumption: "", size: 0 },
            6: { name: "תוספת", consumption: "", size: 0 },
            7: { name: "מנה אחרונה", consumption: "", size: 0 },
            8: { name: "אחר", consumption: "", size: 0 },

        };
        this.Type = MealType.Noon
        for (let [key, value] of Object.entries(components)) {
            let comp1 = new MealComponent()
            comp1.CreatedAt = new Date()
            comp1.UpdatedAt = comp1.CreatedAt
            comp1.PersonelID = this.PersonelID
            comp1.TenantID = this.TenantID
            comp1.ComponentName = components[key].name
            comp1.ComponentSize = components[key].size
            comp1.Consumption = components[key].consumption
            this.Components.push(comp1)
        }
    }
    prepareEveningsMeal() {
        let components: { [order: number]: IMealComponent } = {
            1: { name: "לחם", consumption: "", size: 0 },
            2: { name: "שתיה", consumption: "", size: 0 },
            3: { name: "גבינה לבנה", consumption: "", size: 0 },
            4: { name: "אשל", consumption: "", size: 0 },
            5: { name: "דיסה", consumption: "", size: 0 },
            6: { name: "ירקות", consumption: "", size: 0 },
            7: { name: "תוספת 1", consumption: "", size: 0 },
            8: { name: "תוספת 2", consumption: "", size: 0 },

        };
        this.Type = MealType.Evening
        for (let [key, value] of Object.entries(components)) {
            let comp1 = new MealComponent()
            comp1.CreatedAt = new Date()
            comp1.UpdatedAt = comp1.CreatedAt
            // comp1.Order = key
            comp1.ComponentName = components[key].name
            comp1.PersonelID = this.PersonelID
            comp1.TenantID = this.TenantID
            comp1.ComponentSize = components[key].size 
            comp1.Consumption = components[key].consumption
            this.Components.push(comp1)
        }
    }

}

