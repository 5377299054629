import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Diagnoses } from '../../../../src/app/models/diagnoses';
import { DrugInstruction } from '../../../../src/app/models/drugInstruction';
import { DrugSensitivity, PersonelSensitivity } from '../../../../src/app/models/drugsenstivity';
import { Consultatory } from '../../../../src/app/models/expertconsulatant';
import {  MedicalTreatmentCoordination } from '../../../../src/app/models/familytalk';
import { PersonelHazards } from '../../../../src/app/models/hazard';
import { HospitalReferral } from '../../../../src/app/models/hospitalreferrals';
import { PersonalMeasures } from '../../../../src/app/models/measure';
import { MedicalAdmission } from '../../../../src/app/models/medicaladmission';
import { MedicalRelease } from '../../../../src/app/models/MedicalRelease';
import { ModelMapper } from '../../../../src/app/models/modelmapper';
import { Notice } from '../../../../src/app/models/notice';
import { NurseInstruction } from '../../../../src/app/models/nurseinstruction';
import { MedicalObservation, MedicalObservationFile } from '../../../../src/app/models/Observations';
import { PhysicalExamination } from '../../../../src/app/models/physicalexamination';
import { LabCheck, LabCheckHeader, LabOrderCategories, LabRequests, LabTemplate } from '../../../../src/app/models/testlabchecks';
import { AuthService } from '../../../../src/app/services/auth.service';
import { CaretexOperation } from '../../../../src/app/services/enums';
import { environment } from '../../../../src/environments/environment';
import { BMIMeasure } from 'src/app/models/DieticianFollowup';


@Injectable()
export class CaretxMedicalProviderService {

  selectedPhysicalExamination: PhysicalExamination

  constructor(private httpClient: HttpClient, private authServive: AuthService,) {
  }

  public set SetSelectedPhysicalCheckRecord(physicalCheck: PhysicalExamination) {
    this.selectedPhysicalExamination = physicalCheck
  }



  getExamination(id: string, filter: string): Observable<PhysicalExamination[]> {

    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
   /* if (limit != null)
      filterQuery = filterQuery + '&limit=' + limit.toString()*/

      return this.httpClient.get(environment.gateway + '/examinations/' + id + filterQuery).pipe(
        map((response: any) => {
          return response.map(item => {
            return new ModelMapper(PhysicalExamination).map(item)
          })
        }), catchError(this.errorHandler));
  }


  getLatestExamination(id: string): Observable<PhysicalExamination[]> {
      return this.httpClient.get(environment.gateway + '/examinations/' + id +'/latest').pipe(
        map((response: any) => {
          return response.map(item => {
            return new ModelMapper(PhysicalExamination).map(item)
          })
        }), catchError(this.errorHandler));
  }




  createNewExamination(physicalExamination: PhysicalExamination) {
    return this.httpClient.post(environment.gateway + '/examinations/' + physicalExamination.PersonelID, physicalExamination)
  }


  updateExamination(physicalExamination: PhysicalExamination) {
    return this.httpClient.put(environment.gateway + '/examinations/' + physicalExamination.PersonelID, physicalExamination)
  }


  deleteExamination(personelId: string, filter: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/examinations/' + personelId + filterQuery)
  }

  getMedicalAdmissions(filter: string, personelId: string, limit?: number , mode?: string): Observable<MedicalAdmission[]> {

    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    if (limit != null)
      filterQuery = filterQuery + '&limit=' + limit.toString()

      const headerDict = {
        'Mode': mode==undefined?"":mode,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };

    return this.httpClient.get(environment.gateway + '/medicaladmissions/' + personelId + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(MedicalAdmission).map(item)
        })

      }), catchError(this.errorHandler));
  }



  createNewMedicalAdmission(newMedicalAdmission: MedicalAdmission) {
    return this.httpClient.post(environment.gateway + '/medicaladmission/' + newMedicalAdmission.PersonelID, newMedicalAdmission)
  }
  updateMeidcalAdmission(medicalAdmission: MedicalAdmission) {
    return this.httpClient.put(environment.gateway + '/medicaladmission/' + medicalAdmission.PersonelID, medicalAdmission)
  }
  deleteAdmission(id: string, filter: string) {

    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/medicaladmission/' + id + filterQuery)
  }
  deleteMeidcalAdmissionDrugsensitivity(PersdonelId: string, sensitivity: DrugSensitivity) {

    let filter = ""
    let filterQuery = "";
    if (sensitivity != null) {
      filter = '(id=' + '\'' + sensitivity.ID + '\'' + ')'
      filterQuery = '?filter=' + filter
    }

    return this.httpClient.delete(environment.gateway + '/medicaladmission/' + PersdonelId + '/Sensitivity/' +
      filterQuery)
  }


  // Drug Instructions


  

  getDrugInstrsForApproval(): Observable<DrugInstruction[]> {

    return this.httpClient.get(environment.gateway + '/drugsinstructions_sd' ).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DrugInstruction).map(item)
        })
      }), catchError(this.errorHandler));
  }
  getDrugInstrucs(personelId: string, filter: string): Observable<DrugInstruction[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/drugsinstructions/' + personelId + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DrugInstruction).map(item)
        })
      }), catchError(this.errorHandler));
  }
  createNewDrugInstr(newDrugInstr: DrugInstruction) {
    let currentCaretexModule: string = "DruginstructionsComponent"
    if (this.authServive.isOperationAllowed(CaretexOperation.Create, currentCaretexModule))
      return this.httpClient.post(environment.gateway + '/drugsinstructions/' + newDrugInstr.PersonelID, newDrugInstr)
  }
  updateDrugInstr(drugInstr: DrugInstruction) {
  /*  let currentCaretexModule: string = "DruginstructionsComponent"
    if (this.authServive.isOperationAllowed(CaretexOperation.Update, currentCaretexModule))
      return this.httpClient.put(environment.gateway + '/drugsinstructions/' + drugInstr.PersonelID, drugInstr) */

    return this.httpClient.put(environment.gateway + '/drugsinstructions/' + drugInstr.PersonelID, drugInstr)
  }

  batchUpdateDrugInstr(PersonelID :string , drugInstrs: DrugInstruction[]) {

  
      return this.httpClient.put(environment.gateway + '/batchdrugsinstructions/' + PersonelID, drugInstrs)
    }

  



  deletetDrugInstr(personelId: string, filter: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/drugsinstructions/' + personelId + filterQuery)
  }

  //Drug Sensitivity

 deletetDrugSensitivities(personelId: string, filter: string) {
  let filterQuery = "";
  if (filter != null)
    filterQuery = '?filter=' + filter
  return this.httpClient.delete(environment.gateway + '/drugsensitivities/' + personelId + filterQuery)
}



  getDrugSensitivities(id: string, filter: string): Observable<DrugSensitivity[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/drugsensitivities/' + id + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(DrugSensitivity).map(item)
        })
      }), catchError(this.errorHandler));
  }

  createUpdateDrugSesitivities(persoenelId: string, drugSensitivities: DrugSensitivity[]) {
    return this.httpClient.post(environment.gateway + '/drugsensitivities/' + persoenelId, drugSensitivities)
  }

  createUpdatePersoenlSesitivity(persoenelId: string, personSensitivity: PersonelSensitivity) {
    return this.httpClient.post(environment.gateway + '/personelsensitivities/' + persoenelId, personSensitivity)
  }
  getPersoenlSesitivity(id: string): Observable<PersonelSensitivity> {

    return this.httpClient.get(environment.gateway + '/personelsensitivities/' + id).pipe(
      map((response: any) => {
        return new ModelMapper(PersonelSensitivity).map(response)
      }), catchError(this.errorHandler));
  }



  // Medical Measures
//  authorized.GET("/personmeasures/:id/code/:code", handlers.GetMeasuresByText)

  getPerosnelMeasuresByCodeText(PersonelID: string , code:string): Observable<PersonalMeasures> {
    return this.httpClient.get(environment.gateway + '/personmeasures/' + PersonelID +"/code/"+code).pipe(

      map((response: any) => {
        return new ModelMapper(PersonalMeasures).map(response)
      }), catchError(this.errorHandler));
  }
  getPerosenelMeasuresById(PersonelID: string): Observable<PersonalMeasures[]> {
    return this.httpClient.get(environment.gateway + '/personelmeasures/' + PersonelID).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PersonalMeasures).map(item)
        })
      }), catchError(this.errorHandler));
  }
  getLatestPerosenelMeasuresById(PersonelID: string): Observable<PersonalMeasures[]> {
    return this.httpClient.get(environment.gateway + '/Recentpersonelmeasures/' + PersonelID).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PersonalMeasures).map(item)
        })
      }), catchError(this.errorHandler));
  }
  getPerosenelMeasuresByCategoryId(PersonelID: string , categoryId: string): Observable<PersonalMeasures> {
    return this.httpClient.get(environment.gateway + '/measuresbycategory/' + PersonelID +'/category/'+categoryId).pipe(
      map((response: any) => {
        return new ModelMapper(PersonelSensitivity).map(response)
      }), catchError(this.errorHandler)); 
  }

  //Personel Hazards
  createNewPersonelHazard(newPersonelHazard: PersonelHazards) {
    return this.httpClient.post(environment.gateway + '/personelhazard/' + newPersonelHazard.PersonelID, newPersonelHazard)
  }
  getPersonelHazard(id: string, filter: string): Observable<PersonelHazards[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/personelhazard/' + id + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(PersonelHazards).map(item)
        })
      }), catchError(this.errorHandler));
  }
  updatePersonelHazard(personelHazard: PersonelHazards) {
    return this.httpClient.put(environment.gateway + '/personelhazard/' + personelHazard.PersonelID, personelHazard)
  }
  deletePersonelHazard(id: string, filter: string) {

    let currentCaretexModule: string = "HazardslistComponent"
    if (this.authServive.isOperationAllowed(CaretexOperation.Delete, currentCaretexModule)) {
      let filterQuery = "";
      if (filter != null)
        filterQuery = '?filter=' + filter
      return this.httpClient.delete(environment.gateway + '/personelhazard/' + id + filterQuery)
    }

  }


  createNewMedicalRelease(newMedicalRelease: MedicalRelease) {
    return this.httpClient.post(environment.gateway + '/medicalrelease/' + newMedicalRelease.PersonelId, newMedicalRelease)
  }
  getMedicalRelease(id: string, filter: string, mode: string): Observable<MedicalRelease[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

    const headerDict = {
      'Mode': mode,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };


    return this.httpClient.get(environment.gateway + '/medicalrelease/' + id + filterQuery, requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(MedicalRelease).map(item)
        })
      }), catchError(this.errorHandler));
  }
  updateMedicalRelease(medicalRelease: MedicalRelease) {
    return this.httpClient.put(environment.gateway + '/medicalrelease/' + medicalRelease.PersonelId, medicalRelease)
  }
  deleteMedicalRelease(id: string, filter: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/medicalrelease/' + id + filterQuery)
  }




  // Medical Observation
  deleteObservation(id: string, observationId: Number) {
   // let currentCaretexModule: string = "MedicaltrackComponent"
   // if (this.authServive.isOperationAllowed(CaretexOperation.Delete, currentCaretexModule))
      return this.httpClient.delete(environment.gateway + '/clients/' + id + '/medicalobservation/' + observationId)
  }
  createNewMedicalObservationFile(newObservationFile: MedicalObservationFile) {
    return this.httpClient.post(environment.gateway + '/clients/' + newObservationFile.PersonelID + '/newobservationfile', newObservationFile)
  }
  deleteNewMedicalObservationFile(observationFile: MedicalObservationFile) {
    return this.httpClient.delete(environment.gateway + '/clients/' + observationFile.PersonelID + '/deleteobservationfile/' + observationFile.Id)
  }
  createNewMedicalObservation(newMedicalObservation: MedicalObservation) {
   // let currentCaretexModule: string = "MedicalobservationComponent"
   // if (this.authServive.isOperationAllowed(CaretexOperation.Create, currentCaretexModule))
      return this.httpClient.post(environment.gateway + '/medicalobservations/' + newMedicalObservation.PersonelID , newMedicalObservation)
  }
  updateMedicalObservation(medicalObservation: MedicalObservation) {
  //  let currentCaretexModule: string = "MedicalobservationComponent"
  //  if (this.authServive.isOperationAllowed(CaretexOperation.Update, currentCaretexModule))
      return this.httpClient.put(environment.gateway + '/medicalobservations/' + medicalObservation.PersonelID , medicalObservation)
  }

  getMedicalObservations(filter: string, PersonelID: string, mode:string,page:string): Observable<MedicalObservation[]> {

    let filterQuery = "";
    if (filter != null && filter !="")
      filterQuery = '?filter=' + filter
      const headerDict = {
        'Mode': mode,
        'Page':page,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
    return this.httpClient.get(environment.gateway + '/medicalobservations/' + PersonelID + filterQuery,requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(MedicalObservation).map(item)
        })

      }), catchError(this.errorHandler));
  }
  getTotalOfMedicalObservations(PersonelID: string): Observable<number> {
    return this.httpClient.get(environment.gateway + '/medicalobservations/' + PersonelID +'/count').pipe(
      map((response: any) => {
        return response
      }), catchError(this.errorHandler));
  }


  CreateLabResults(labcheck: LabCheckHeader) {
    return this.httpClient.post(environment.gateway + '/laboratoryresults/' + labcheck.PersonelID, labcheck)
  }
  UpdateLabResults(labcheck: LabCheckHeader) {
    return this.httpClient.put(environment.gateway + '/laboratoryresults/' + labcheck.PersonelID, labcheck)
  }
  getLaboratoryCheckTemplate(hmo: string, filter: string): Observable<LabTemplate[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

    return this.httpClient.get(environment.gateway + '/medlabtemplates/' + hmo + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(LabTemplate).map(item)
        })
      }), catchError(this.errorHandler));
  }
  getLabCodeResults(id: string, filter: string): Observable<LabCheck[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/labcoderesults/' + id + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(LabCheck).map(item)
        })
      }), catchError(this.errorHandler));
  }
  getLaboratoryResultsHeaders(id: string, filter: string): Observable<LabCheckHeader[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/laboratoryresults/' + id + filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(LabCheckHeader).map(item)
        })
      }), catchError(this.errorHandler));
  }
  deleteLaboratoryResult(id: string, filter: string) {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.delete(environment.gateway + '/laboratoryresults/' + id + filterQuery)
  }
  getLaboratoryRequests(id: string): Observable<LabRequests[]> {

    return this.httpClient.get(environment.gateway + '/laboratory/' + id + '/requests').pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(LabRequests).map(item)
        })
      }), catchError(this.errorHandler));
  }



  
  getLaboratoryRequests2(id: string,filter:string): Observable<LabRequests[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter
    return this.httpClient.get(environment.gateway + '/laboratory/' + id + '/requests'+ filterQuery).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(LabRequests).map(item)
        })
      }), catchError(this.errorHandler));
  }
  getLaboratoryOrderChecks(hmo: string): Observable<LabOrderCategories[]> {
    return this.httpClient.get(environment.gateway + '/labcategories/' + hmo).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(LabOrderCategories).map(item)
        })
      }), catchError(this.errorHandler));
  }
  getLaboratoryRequestById(id: string, requestId: string): Observable<LabRequests> {
    return this.httpClient.get(environment.gateway + '/laboratory/' + id + '/requests/' + requestId).pipe(
      map((response: any) => {
        return new ModelMapper(LabRequests).map(response)
      }), catchError(this.errorHandler));
  }
  deleteLaboratoryRequestById(id: string, requestId: string) {
    return this.httpClient.delete(environment.gateway + '/laboratory/' + id + '/requests/' + requestId)
  }
  updateLaboratoryRequestById(personelId: string, labRequest: LabRequests) {
    return this.httpClient.put(environment.gateway + '/laboratory/' + personelId + '/requests/' + labRequest.ID, labRequest)
  }
  getNotice(id: string): Observable<Notice[]> {
    return this.httpClient.get(environment.gateway + '/passovernotice/' + id).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(Notice).map(item)
        })
      }), catchError(this.errorHandler));
  }
  createNotice(notice: Notice) {
    return this.httpClient.post(environment.gateway + '/passovernotice/' + notice.PersonelID, notice)
  }
  updateNotice(notice: Notice) {
    return this.httpClient.put(environment.gateway + '/passovernotice/' + notice.PersonelID, notice)
  }
  deleteMeidcalAdmissionDiagnoses(admissionId, diagnoses: Diagnoses) {
    return this.httpClient.delete(environment.gateway + '/personaldiagnoses/' + 
    diagnoses.PersonelID + '/diagnoses/'+diagnoses.ID)
  }


  createNewNurseInstructions(personelid:string ,newNurseInstructions: NurseInstruction) {
    return this.httpClient.post(environment.gateway + '/nurseinstruction/' + personelid , newNurseInstructions)
  }
  getNurseInstructions(id: string, filter: string, mode: string): Observable<NurseInstruction[]> {
    let filterQuery = "";
    if (filter != null)
      filterQuery = '?filter=' + filter

    const headerDict = {
      'Mode': mode,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };


    return this.httpClient.get(environment.gateway + '/nurseinstruction/' + id + filterQuery, requestOptions).pipe(
      map((response: any) => {
        return response.map(item => {
          return new ModelMapper(NurseInstruction).map(item)
        })
      }), catchError(this.errorHandler));
  }



    // Drug Instructions
    getFamilyTalks(personelId: string, filter: string,mode:string): Observable<MedicalTreatmentCoordination[]> {
      let filterQuery = "";
      if (filter != null)
        filterQuery = '?filter=' + filter

        const headerDict = {
          'Mode': mode,
        }

        const requestOptions = {
          headers: new HttpHeaders(headerDict),
        };
  

      return this.httpClient.get(environment.gateway + '/treatmentcoordination/' + personelId + filterQuery,requestOptions).pipe(
        map((response: any) => {
          return response.map(item => {
            return new ModelMapper(MedicalTreatmentCoordination).map(item)
          })
        }), catchError(this.errorHandler));
    }
    createNewFamilyTalk(record: MedicalTreatmentCoordination) {
        return this.httpClient.post(environment.gateway + '/treatmentcoordination/' + record.PersonelID, record)
    }
    updateFamilyTalk(record: MedicalTreatmentCoordination) {

        return this.httpClient.put(environment.gateway + '/treatmentcoordination/' + record.PersonelID, record)
    }
    deleteFamilyTalk(personelId: string, recordid: string) {

      return this.httpClient.delete(environment.gateway + '/treatmentcoordination/' + personelId +"/record/" + recordid)
    }


 // Consultant & experts
 getConsultatory(personelId: string, filter: string,mode:string): Observable<Consultatory[]> {
  let filterQuery = "";
  if (filter != null)
    filterQuery = '?filter=' + filter

    const headerDict = {
      'Mode': mode,
    }

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };


  return this.httpClient.get(environment.gateway + '/consultatory/' + personelId + filterQuery,requestOptions).pipe(
    map((response: any) => {
      return response.map(item => {
        return new ModelMapper(Consultatory).map(item)
      })
    }), catchError(this.errorHandler));
}
createNewConsultatory(record: Consultatory) {
    return this.httpClient.post(environment.gateway + '/consultatory/' + record.PersonelID, record)
}
updateConsultatory(record: Consultatory) {

    return this.httpClient.put(environment.gateway + '/consultatory/' + record.PersonelID, record)
}
deleteConsultatory(personelId: string, recordid: string) {

  return this.httpClient.delete(environment.gateway + '/consultatory/' + personelId +"/record/" + recordid)
}


createNewReferral(referralRecord: HospitalReferral) {
  return this.httpClient.post(environment.gateway + '/medicalreferral/' + referralRecord.PersonelID, referralRecord)
}

updateReferral(referralRecord: HospitalReferral) {
  return this.httpClient.put(environment.gateway + '/medicalreferral/' + referralRecord.PersonelID, referralRecord)
}

deleteReferral(personelId: string, referralId: string) {
  return this.httpClient.delete(environment.gateway + '/medicalreferral/' + personelId +"/record/"+referralId)
}

getReferrals(filter: string, personelId: string, limit?: number , mode?: string): Observable<HospitalReferral[]> {

  let filterQuery = "";
  if (filter != null)
    filterQuery = '?filter=' + filter
  if (limit != null)
    filterQuery = filterQuery + '&limit=' + limit.toString()

    const headerDict = {
      'Mode': mode==undefined?"":mode,
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

  return this.httpClient.get(environment.gateway + '/medicalreferral/' + personelId + filterQuery,requestOptions).pipe(
    map((response: any) => {
      return response.map(item => {
        return new ModelMapper(HospitalReferral).map(item)
      })

    }), catchError(this.errorHandler));
}



createNewBMIMeasure(id:string,bmiMeasure: BMIMeasure) {
  return this.httpClient.post(environment.gateway + '/bmimeasures/' + id, bmiMeasure)
}
getBMIMeasure(personelId: string, filter: string): Observable<BMIMeasure[]> {
  let filterQuery = "";
  if (filter != null)
    filterQuery = '?filter=' + filter

  return this.httpClient.get(environment.gateway + '/bmimeasures/' + personelId + filterQuery).pipe(
    map((response: any) => {
      return response.map(item => {
        return new ModelMapper(Consultatory).map(item)
      })
    }), catchError(this.errorHandler));
}
  
/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/





GetLatestMedicalLabResultsByCategory(id: string, filter: string, mode: string): Observable<LabCheckHeader> {
  let filterQuery = "";
  if (filter != null)
    filterQuery = '?filter=' + filter

  const headerDict = {
    'Mode': mode,
  }
  const requestOptions = {
    headers: new HttpHeaders(headerDict),
  };
  return this.httpClient.get(environment.gateway + '/medlabresults/' + id +'/latest'+filterQuery,requestOptions).pipe(
    map((response: any) => {
      return new ModelMapper(PersonelSensitivity).map(response)
    }), catchError(this.errorHandler)); 

}
generateReport(personalId: string, careTextReport: MedicalObservationFile): Observable<any> {
  return this.httpClient.post(environment.gateway + '/clients/' + personalId + '/getobservationfile',careTextReport,{
    responseType: "arraybuffer" //tried with 'blob'
  })
}


  /***************************************************************************************** */

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError(error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      return throwError(error.error);
    }
    // return an observable with a user-facing error message


    // return of([]);
  }

}