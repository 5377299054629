import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanDeactivate, UrlTree } from '@angular/router';
import { AuthService } from "../../services/auth.service";
import { Observable, Subject } from 'rxjs';
import { ToasterService } from 'src/app/services/toastr.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    private toastrService: ToasterService,
    public router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let page = next.component["name"]

    //After logging out ,prevent the user to go back using back button  
    if (this.authService.isLoggedIn !== true) {
      this.router.navigate(['sign-in'])
    }
    return true;
    if (page != "LandingpageComponent" && page != "LablandingpageComponent" && page != "SetupslandingpageComponent") {
      let modeArray = next.data.mode as Array<string>;
      if (modeArray != null) {
        if (modeArray[0].toLowerCase() == "newpage") {
          const result = this.authService.LoggedInUser.UserPermessions.some(elem => {
            return (page == elem.CaretexComponent.ComponentId && elem.Create)
          })
          if (!result) {
            this.toastrService.Error("", "אין לך הרשאה ליצור רשומה חדשה.")
            return false;
          }
          return true
        }
        // setupd for admin role only 
        if (modeArray[0].toLowerCase() == "setup") {
          if (this.authService.LoggedInUser.role == "admin") return true
          else {
            this.toastrService.Error("", "אין לך הרשאה ליצור רשומה חדשה.")
            return false;
          }
        }

      }
      const result = this.authService.LoggedInUser.UserPermessions.some(elem => {
        return (page == elem.CaretexComponent.ComponentId && elem.NavigateTo)
      })
      if (!result) {
        this.toastrService.Error("", "אין לך הרשאה לפתוח את הדף הזה.")
        return false;
      }
    }
    return true;
  }

}


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
  subject: Subject<boolean>;
  justRout:boolean

}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {


  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    let forcelogout =  JSON.parse(localStorage.getItem('forcelogout'));
    if (forcelogout) return true
    let subject = new Subject<boolean>();
    component.canDeactivate();
     if (component.justRout) return true
  
    subject = component.subject;
    return subject.asObservable();
  }



  /* canDeactivate(component: CanComponentDeactivate) {
     return component.canDeactivate ? component.canDeactivate() : true;
   }*/

}


