import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialPeriodReport } from '../../../../src/app/models/socialPeriodReport';
import { MedicalFileProviderService } from '../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from '../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxSocialProviderService } from '../Social.data-provider.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-annualreportslist' ,
  templateUrl: './annualreportslist.component.html',
  styleUrls: ['./annualreportslist.component.css']
})
export class AnnualreportslistComponent extends CaretexComponent implements OnInit {

  id: string
  canSocailPagesBeEditted: boolean
  currentRowIndex: number
  socialPeriodReports: SocialPeriodReport[] = []
  moduleName: string = "היסטורית דוחות סוציאלית תקופתית"
  formData: any[]
  searchForm = new FormGroup({
    created_at_from: new FormControl(''),
    created_at_to: new FormControl(''),
    status: new FormControl(''), //, [Validators.required, Validators.email]),
    closed_at_from: new FormControl(''),
    closed_at_to: new FormControl(''),
  });
  totalNumOfRecords: Number = 0

  RecordStatus = [{ id: "A", desc: "רשומה פתוחה" }, { id: "C", desc: "רשומה נעולה" }]


  constructor(private router: Router,
    public medicalFileService: MedicalFileProviderService,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private _title: Title,
    private toastrService: ToasterService,
    private route: ActivatedRoute) { super(medicalFileService) }

  ngOnInit(): void {
    this._title.setTitle('Caretx | דוחות תקופתיים');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id)
    this.getSocialPeriodReporsFromDB("all")
    this.formData = [
      { "formcontrolname": "status", "label": "סטטוס רשימה", "type": "multi", "value": JSON.stringify(this.RecordStatus), "selectid": "id", "selectval": "desc" },
      { "formcontrolname": "created_at_from", "label": "מתאריך", "type": "date", "value": "", "selectid": "", "selectval": "" },
      { "formcontrolname": "created_at_to", "label": "עד תאריך", "type": "date", "value": "", "selectid": "", "selectval": "" },
      { "formcontrolname": "closed_at_from", "label": "תאריך נעילה מ", "type": "date", "value": "", "selectid": "", "selectval": "" },
      { "formcontrolname": "closed_at_to", "label": "תאריך נעילה עד", "type": "date", "value": "", "selectid": "", "selectval": "" },

    ]
  }


  getSocialPeriodReporsFromDB(filter: string) {
    this.caretxSocialProviderService.getSocialAnnual(this.id, filter).subscribe(
      data => {
        this.socialPeriodReports = data
        this.totalNumOfRecords = data.length
      },
      err => {
        this.socialPeriodReports = []
      }
    )
  }

  SearchWithFilter($event) {
    this.getSocialPeriodReporsFromDB($event)
  }

  AddNewReport($event) {
    let filter = '(status=\'A\')'
    this.caretxSocialProviderService.getSocialAnnual(this.id, filter).subscribe((data: SocialPeriodReport[]) => {

      if (data.length == 0)
        this.router.navigateByUrl("caretexfile/" + this.id + "/socialperiodicalreport/new")
      else {
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }

    });

  }

  deleteRow(i) {
    this.currentRowIndex = i
    if (this.socialPeriodReports[i].Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תמחק',
        allowOutsideClick: false,
        allowEscapeKey: false,
        cancelButtonText: 'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteRecord(this.socialPeriodReports[this.currentRowIndex])
        }
      })
    }
  }


  deleteRecord(annual: SocialPeriodReport) {
    let filter = '(id=' + '\'' + annual.ID + '\'' + ')'
    this.caretxSocialProviderService.deleteSocialAnnual(filter, this.id).subscribe({
      next: data => {
        this.getSocialPeriodReporsFromDB("all")
        this.toastrService.Sucesss("", this.toastrService.successMsg)
      },
      error: error => {
        this.toastrService.Error("", error)
      }
    })
  }


  OnSDrillTosSocialPeriodReport($event, socialPeriodReport) {
    this.router.navigateByUrl("caretexfile/" + this.id + "/socialperiodicalreport/" + socialPeriodReport.ID)
  }


  generateReport($event, socialAdmission) {

  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false;
  }
}
