import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { DialogResult } from 'src/app/services/enums';


@Component({
  selector: 'app-systemalert',
  templateUrl: './systemalert.component.html',
  styleUrls: ['./systemalert.component.css']
})
export class SystemAlertComponent implements OnInit {


  hideModal: boolean = true;


  @Input() title: string
  @Input() alertBody: string
  @Input() alertId: string
  @Input() associatedTo: string
  @Input() YesBtn: boolean
  @Input() NoBtn: boolean
  @Input() OkBtn: boolean
  @Input() CancelBtn: boolean
  @Input() IsWarnningAlert: boolean = false
  //@Input()  Buttons:boolean[] = [false,false,false,false] // Yes;No;OK,cancel


  @Output() dialogResultEvent = new EventEmitter<DialogResult>();
  constructor() { }

  ngOnInit(): void {

  }

  submitCommentModal(dialogResult: DialogResult) {

    this.dialogResultEvent.emit(dialogResult)
    this.hideModal = true;
  }


  public get dialogResults(): typeof DialogResult {
    return DialogResult;
  }

  getbackgroundColor() {
    if (this.IsWarnningAlert)
      return "red"
    else
      return "#0194a4"
  }
}
