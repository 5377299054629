
import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Attachment } from '../../../../../src/app/models/attachments';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { OptionObject } from '../../../../../src/app/services/enums';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import {
  MAT_DIALOG_DATA,

  MatDialogRef,

} from '@angular/material/dialog'

import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.css'],

})
export class AttachmentDialogComponent implements OnInit {

  attachedFile: Attachment = new Attachment()
  fileName: string
  fileTitle: string
  DocToUpload: any = null;
  //@Output() refreshListOfAttachments = new EventEmitter<Attachment[]>();
  Id: string
  Ownerid: string
  UserId: string
  FileCategoryList: OptionObject[]
  @ViewChild('f') mainFormElement: NgForm;
  newFileName: string


  constructor(private toastrService: ToasterService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private dialogRef: MatDialogRef<AttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private caretxSharedlService: CaretxSharedModulesProviderService,) { dialogRef.disableClose = true; }

  ngOnInit(): void {
    this.UserId = this.data.UserId;
    this.FileCategoryList = this.data.FileCategoryList;
    this.Id = this.data.Id;
    if (this.FileCategoryList != null && this.FileCategoryList != undefined && this.FileCategoryList.length == 1) {
      this.attachedFile.FileCategory = this.FileCategoryList[0].id
    }
  }
  openFile() {
    (document.querySelector('input[id="doc-input"]') as HTMLElement).click()
  }

  addAttachment() {
  }

  prepareDocumentForUpload(files: FileList) {
    this.fileName = ""
    this.DocToUpload = null
    this.fileTitle = ""
    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.fileName = file["name"]
      this.DocToUpload = reader.result
      this.fileTitle = file["name"]
    };
  }

  uploadFile(fileData: any, fileName: string, fileTitle: string) {
    this.attachedFile.CreatedAt = new Date()
    this.attachedFile.UpdatedAt = this.attachedFile.CreatedAt
    this.attachedFile.PersonelID = this.Id
    this.attachedFile.FileDataBase64 = fileData
    this.attachedFile.Description = ""
    this.attachedFile.FileName = fileName
    this.attachedFile.Uploaded_at = new Date()
    this.attachedFile.CreatedById = this.authService.LoggedInUser.UID
    this.attachedFile.CreatedBy = this.authService.LoggedInUser
    this.attachedFile.Uploaded_at = new Date()
    this.caretxSharedlService.uploadFile(this.attachedFile).subscribe({
      next: data => {
        //  this.refreshListOfAttachments.emit(data as Attachment[])
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.dialogRef.close(data as Attachment[]);
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })
  }

  doUpload() {


    let extenstion: string = this.fileName.split('.').pop();

    this.uploadFile(this.DocToUpload, this.newFileName == null ? this.fileName : this.newFileName + "." + extenstion, this.fileTitle)
  }

  diff_hours(dt2, dt1) {
    var diff = (dt2.getTime() - (new Date(dt1)).getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
  }

  submit() {
    this.doUpload()
  }

  onCloseDialog() {
    this.dialogRef.close()
  }
}
