import { propertyMap } from './modelmapper'




// Need Server to send Payload in below structure
export class Tenant {

    @propertyMap('tenantID')
    tenantID: string
    @propertyMap('name')
    name: string
    @propertyMap('address')
    address: string
    @propertyMap('email')
    email: string
    @propertyMap('iconPath')
    iconPath: string 
    @propertyMap('Phone')
    Phone: string
    @propertyMap('Fax')
    Fax: string
    @propertyMap('PrimaryDoctor')
    PrimaryDoctor: string
    @propertyMap('PrimaryNurse')
    PrimaryNurse: string
    @propertyMap('HouseManager')
    HouseManager: string


   /*
    @propertyMap('id')
    id: string
    @propertyMap('TenantName')
    Name: string
    @propertyMap('Address')
    Address: string
    @propertyMap('email')
    email: string
    */

    constructor() {
     
    }






  
}