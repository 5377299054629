import { Component, Inject, Input, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { Attachment } from '../../../../../src/app/models/attachments';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { CaretxSharedModulesProviderService } from '../../sharedmodule.data-provider.services';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-camera-dialog',
  templateUrl: './camera-dialog.component.html',
  styleUrls: ['./camera-dialog.component.css']
})
export class CameraDialogComponent implements OnInit {

  Id:string ;
  FileCategory :string
  Origin:string
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public ImagesTaken: string[] = [];
  webImages:WebcamImage[] = []
  showCam:boolean = true
  currPicture:string
  public videoOptions: MediaTrackConstraints = {
   
  };
  public errors: WebcamInitError[] = [];
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  
  constructor( private authService: AuthService,      private toastrService: ToasterService,private caretxSharedlService: CaretxSharedModulesProviderService,private dialogRef: MatDialogRef<CameraDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { dialogRef.disableClose = true;}

  ngOnInit(): void {
    this.Id = this.data.Id;
    this.FileCategory = this.data.FileCategory;
    this.Origin = this.data.Origin
    WebcamUtil.getAvailableVideoInputs()
    
    .then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });
  }

  public triggerSnapshot(): void {
    this.showCam = true
    this.trigger.next();
  }
  public handleImage(webcamImage: WebcamImage): void {

    this.webImages.push(webcamImage)
    this.currPicture = webcamImage.imageAsDataUrl
    this.showCam = false

  }
 
  public showNextWebcam(directionOrDeviceId: boolean|string): void {

    this.nextWebcam.next(directionOrDeviceId);
  }
  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  ActivateCam() {
    this.showCam = true
  }
  
 public cameraWasSwitched(deviceId: string): void {
  this.deviceId = deviceId;
}

public get triggerObservable(): Observable<void> {
  return this.trigger.asObservable();
}

public get nextWebcamObservable(): Observable<boolean|string> {
  return this.nextWebcam.asObservable();
}

GeneratePdf() {

  var date = new Date();
  var fileTitle =
    ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
    ("00" + date.getDate()).slice(-2) + "/" +
    date.getFullYear() + " " +
    ("00" + date.getHours()).slice(-2) + ":" +
    ("00" + date.getMinutes()).slice(-2) + ":" +
    ("00" + date.getSeconds()).slice(-2);

  var uuid2 = new Date().getTime().toString() + ".pdf";
  const doc = new jsPDF();

  doc.setFontSize(15);
  doc.setFont('Lato-Regular', 'normal');
  doc.setLineHeightFactor(2)
  doc.text("screenshots taken on "+fileTitle +" by user:" + this.authService.LoggedInUser.displayName, 10, 10);
 for(let i=0; i<= this.webImages.length-1; i++){
    doc.addImage(this.webImages[i].imageAsDataUrl, "JPEG", 10, 15, 190, 250, "picture".concat((i+1).toString()), 'FAST');
   if ( i < this.webImages.length-1) {
    doc.addPage()
   }


  }
  var base = doc.output('datauristring');
  this.AttachPdfFile(base ,uuid2 )
    //doc.save("a4.pdf");
}


AttachPdfFile(fileData,fileName){
  let attachedFile: Attachment = new Attachment()
  attachedFile.CreatedAt = new Date()
  attachedFile.UpdatedAt = attachedFile.CreatedAt
  attachedFile.PersonelID = this.Id
  attachedFile.FileDataBase64 = fileData
  attachedFile.FileCategory = this.FileCategory
  attachedFile.Description = ""
  attachedFile.FileName = fileName
  attachedFile.Uploaded_at = new Date()
  attachedFile.CreatedById = this.authService.LoggedInUser.UID
  attachedFile.CreatedBy = this.authService.LoggedInUser
  attachedFile.Uploaded_at = new Date()
  attachedFile.LinkedTo = this.Origin
  this.caretxSharedlService.uploadFile(attachedFile).subscribe({
    next: data => {
    //  this.refreshListOfAttachments.emit(data as Attachment[])
      this.toastrService.Sucesss("", this.toastrService.successMsg)
      this.dialogRef.close();
    },
    error: error => {
      this.toastrService.Error("", this.toastrService.errorMsg)
    }
  })


}

submit(){

}
}
