import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { FamilyVisit } from '../../../../../src/app/models/socialfamilyvisits';

import { AuthService } from '../../../../../src/app/services/auth.service';

import { ToasterService } from '../../../../../src/app/services/toastr.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import Swal from 'sweetalert2';
import { CaretxSocialProviderService } from '../../Social.data-provider.service';

@Component({
  selector: 'app-familiyvisit',
  templateUrl: './familiyvisit.component.html',
  styleUrls: ['./familiyvisit.component.css']
})
export class FamiliyvisitComponent extends CaretexComponent implements OnInit {
  isrecordClosed: boolean
  currentFamilyVisit: FamilyVisit = new FamilyVisit()
  id: string
  visitId: string
  step: number = 0

  @ViewChild('mainform') mainFormElement: NgForm;

  moduleName: string = "ביקורי משפחה"
  constructor(private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private router: Router,
    private caretxSocialProviderService: CaretxSocialProviderService,
    private route: ActivatedRoute,
    private toastrService: ToasterService,
    private authServive: AuthService) {
    super(medicalFileService)
  }

  ngOnInit(): void {
    this._title.setTitle('Caretex |טופס רישום סוציאלי');
    this.id = this.route.parent.snapshot.params['id'];
    this.visitId = this.route.snapshot.params.visitid
    this.CheckIfComponentIsDisabled(this.id)

    if (this.route.snapshot.params['stepid'] != "" && this.route.snapshot.params['stepid'] != undefined) {
      this.step = Number(this.route.snapshot.params['stepid']);
    }


    if (this.route.snapshot.params.visitid == 'new') {
      this.getVisitFromDB(this.id, this.route.snapshot.params.visitid, "new")

    } else {
      this.getVisitFromDB(this.id, this.route.snapshot.params.visitid, "")
 
    }
  }



  getVisitFromDB(id: string, visitid: string, mode: string) {
    let filter = ""
    if (mode != "new")
      filter = '(id=' + '\'' + visitid + '\'' + ')'

    this.caretxSocialProviderService.getFamilyVisits(this.id, filter, mode).subscribe((data: FamilyVisit[]) => {
      if (data.length == 1) {
        this.currentFamilyVisit = new FamilyVisit(data[0])

        if (this.visitId == 'new') {
          this.currentFamilyVisit.PersonelID = this.id
          this.currentFamilyVisit.Status = "A"
          this.currentFamilyVisit.CreatedById = this.authServive.LoggedInUser.UID
        }
        if (this.currentFamilyVisit.Status == "C") {
          this.isrecordClosed = true

        } else
          this.isrecordClosed = false
      }
      else if (data.length == 0) {
        let errorMsg = "המערכת לא הצליחה לייצר רשומה חדשה"
        this.toastrService.Error("", errorMsg)
      }
      else {
        let errorMsg = "נמצאו  יותר מרשומה אחת עם אותו מזהה"
        this.toastrService.Error("", errorMsg)
      }

    });
  }


  setStep(index: number) {
    this.step = index;
  }

  BackToList() {
    this.router.navigateByUrl("/caretexfile/" + this.id + "/familyvisits")
  }

  LockDocument() {
    if (this.currentFamilyVisit.Status == 'A') {
      Swal.fire({
        title: "הודעת מערכת",
        text: "האם אתה בטוח שאתה רוצה למעול רשימה זו?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'כן,תנעל',
        allowOutsideClick: false,
        allowEscapeKey: false,       
        cancelButtonText:'לא'
      }).then((result) => {
        if (result.isConfirmed) {
          this.DoLock()
        }
      })
    }
  }

  DoLock() {
    this.currentFamilyVisit.Status = "C"
    this.currentFamilyVisit.ClosedById = this.authServive.LoggedInUser.UID
    this.currentFamilyVisit.ClosedAt = new Date()
    this.DoUpdate()

  }

  Save() {
    this.currentFamilyVisit.VisitDate = new Date(this.currentFamilyVisit.VisitDate)
    // convert Time to datetime

    let yy: number = new Date().getFullYear()
    let mm: number = new Date().getMonth()
    let dd: number = new Date().getDate()

    let Hours:number =  Number(this.currentFamilyVisit.VisitTime.toString().split(':')[0])
    let Minutes:number =  Number(this.currentFamilyVisit.VisitTime.toString().split(':')[1])
    let time : Date = new Date(yy, mm, dd, Hours, Minutes, 0)
    this.currentFamilyVisit.VisitTime = time

    if (this.visitId == 'new') {
      this.DoSave()
    }
    else {
      this.DoUpdate()
    }
  }

  DoSave() {
    this.currentFamilyVisit.UpdatedAt = new Date()
    this.currentFamilyVisit.CreatedAt = this.currentFamilyVisit.UpdatedAt
    this.currentFamilyVisit.CreatedById = this.authServive.LoggedInUser.UID
    this.currentFamilyVisit.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.createFamilyVisit(this.currentFamilyVisit).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentFamilyVisit = new FamilyVisit(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/familyvisits/" + this.currentFamilyVisit.ID+"/step/"+this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  DoUpdate() {
    this.currentFamilyVisit.UpdatedAt = new Date()
    this.currentFamilyVisit.TenantID = this.authServive.LoggedInTenantID
    this.caretxSocialProviderService.UpdateFamilyVisit(this.currentFamilyVisit).subscribe({
      next: data => {
        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.currentFamilyVisit = new FamilyVisit(data)
        this.mainFormElement.reset(this.mainFormElement.value);
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigateByUrl("caretexfile/" + this.id + "/familyvisits/" + this.currentFamilyVisit.ID+"/step/"+this.step)
      },
      error: error => {
        this.toastrService.Error("", error.error)
      }
    })
  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return  this.mainFormElement.dirty
  }


}
