import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NursePhysicalCheck } from 'src/app/models/nursingphysicalstatuses';

@Component({
  selector: 'app-showdefaulttext-dialog',
  templateUrl: './showdefaulttext-dialog.component.html',
  styleUrls: ['./showdefaulttext-dialog.component.css']
})
export class ShowdefaulttextDialogComponent implements OnInit {

  title:string
  concatenatedStatusTxt:string
  defaultTexts:string[] = []
  statusTxt:string[] = []
  selectedList:string[] = []
  selectedNursePhysicalCheck : NursePhysicalCheck
  constructor(private dialogRef: MatDialogRef<ShowdefaulttextDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {   dialogRef.disableClose = true;}


  ngOnInit(): void {
    this.title = this.data.title;
    this.selectedNursePhysicalCheck = this.data.selectedNursePhysicalCheck
    this.concatenatedStatusTxt = this.data.concatenatedStatusTxt;
    this.splitStatusTxtForSearch()

    this.GetDefaultTexts() 
  }



  splitStatusTxtForSearch() {
    this.statusTxt = this.concatenatedStatusTxt.split("\n")
  }

  GetDefaultTexts() {
    this.selectedNursePhysicalCheck.Statuses.forEach(status => {
      if (this.statusTxt.includes(status.Status)) {
        status.DefaultTexts.forEach(defaultTxt => {
            this.defaultTexts.push(defaultTxt.DefaultText)
        });
      }
    });
  }


  submit(){
    this.dialogRef.close(this.selectedList)

  }


  onSelectDefaultText($event , index) {
    this.selectedList.push(this.defaultTexts[index])
    this.dialogRef.close(this.selectedList)

  }

}
