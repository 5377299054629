import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BedSoreType } from '../../../../../src/app/models/generalhousedsetups';
import { AuthService } from '../../../../../src/app/services/auth.service';
import { ToasterService } from '../../../../../src/app/services/toastr.service';
import Swal from 'sweetalert2';
import { CaretxSetupsProviderService } from '../../setup-services/setups.data-provider.service';
import { CaretexComponent } from '../../../../../src/app/shared/CaretexComponent';
import { MedicalFileProviderService } from '../../../../../src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';

@Component({
  selector: 'app-bed-sores-settings',
  templateUrl: './bed-sores-settings.component.html',
  styleUrls: ['./bed-sores-settings.component.css']
})
export class BedSoresSettingsComponent extends CaretexComponent implements OnInit {

  bedsores:BedSoreType[]  = []
  TabId:string = "main"
  currentRowIndex:number
  deleteSysMsgId:string
  deleteAlert:string
  alertTitle: string
  constructor(  private caretxSetupsProviderService:CaretxSetupsProviderService,
    private router: Router,
    private authServive: AuthService,
    public medicalFileServive: MedicalFileProviderService,
    private toastrService: ToasterService,) {
      super(medicalFileServive)
     }

  ngOnInit(): void {
    this.getBedSoreTypesFromDB()
  }


  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    const hospitalsLisChanged = this.bedsores.some(row => {
      return row.New || row.editMode
    })
    return  hospitalsLisChanged
  }


  getBedSoreTypesFromDB(){
    this.caretxSetupsProviderService.GetBedSoreTypes(this.authServive.LoggedInTenantID).subscribe(
      data => {
        this.bedsores = data
      },
      err => {
        console.log("Error", err)
        this.bedsores = []

      }
    )
  }



  addNewSoreType(){
    var newBedSoreType = new BedSoreType()
    newBedSoreType.New = true
    newBedSoreType.editMode = true
    newBedSoreType.CreatedAt = new Date()
    newBedSoreType.UpdatedAt = new Date()
    newBedSoreType.RealmID = this.authServive.LoggedInTenantID
    this.bedsores.unshift(newBedSoreType)
  }

  deleteRow(i){

    this.currentRowIndex = i
    Swal.fire({
      title: "הודעת מערכת",
      text: "האם אתה בטוח שאתה רוצה למחוק רשימה זו?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'כן,תמחק',
      allowOutsideClick: false,
      allowEscapeKey: false, 
      cancelButtonText:'לא'
    }).then((result) => {
      if (result.isConfirmed) {
        if (!this.bedsores[i].New){
          this.caretxSetupsProviderService.DeleteBedSoreType(this.authServive.LoggedInTenantID, this.bedsores[this.currentRowIndex].ID).subscribe({
            next: data => {
              this.bedsores.splice(this.currentRowIndex, 1);
              this.toastrService.Sucesss("", this.toastrService.successMsg)
            },
            error: error => {
              this.toastrService.Error("", error)
            }
          })
        }
        else
        this.bedsores.splice(this.currentRowIndex, 1);
      }
    })
  }



  editRow(i){
    this.bedsores[i].editMode = true
    this.bedsores[i].Modified = true
  }

  cancelEditRow(i){
    this.bedsores[i].editMode  = false;
    this.bedsores[i].Modified = false
  }



  updateNursingStatus($event){
     this.caretxSetupsProviderService.CreateBedSoreTypes(this.authServive.LoggedInTenantID,this.bedsores).subscribe({
      next: data => {

        this.toastrService.Sucesss("", this.toastrService.successMsg)
        this.getBedSoreTypesFromDB()
      },
      error: error => {
        this.toastrService.Error("", this.toastrService.errorMsg)
      }
    })  


    
  }

  BackToList(){
    this.router.navigate(['system_setups']);
  }

}
