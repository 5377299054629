import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { PhysicalDiagnoses, PhysicalDiagnoses2 } from 'src/app/models/physiotherapy';
import { MedicalFileProviderService } from 'src/app/cartex-medicalfile-module/medicalfile-services/medicalfile.data-provider.service';
import { ToasterService } from 'src/app/services/toastr.service';
import { CaretexComponent } from 'src/app/shared/CaretexComponent';
import { CaretxPhsioTherapyProviderService } from '../Physiotherapty.data-provider.service';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-physicaldiagnoses2list',
  templateUrl: './physicaldiagnoses2list.component.html',
  styleUrls: ['./physicaldiagnoses2list.component.css']
})
export class Physicaldiagnoses2listComponent extends CaretexComponent implements OnInit  {
  id:string
  canPhsioTherapyPagesBeEditted: boolean
  physicalDiagnoses: PhysicalDiagnoses2[] = []
  currentRowIndex:number
  moduleName:string="אבחנות פיזיקאליות"
  totalNumOfRecords: Number = 0
RecordStatus = [{id:"A" , desc:"רשומה פתוחה"},{id:"C" , desc:"רשומה נעולה"}]
searchForm = new FormGroup({
  created_at_from: new FormControl(''),
  created_at_to: new FormControl(''),
  status: new FormControl(''), //, [Validators.required, Validators.email]),
  closed_at_from: new FormControl(''),
  closed_at_to: new FormControl(''),

});
formData : any[] 


  constructor(private router: Router,
    private phsiotheraptyService :CaretxPhsioTherapyProviderService,
    private _title: Title,
    public medicalFileService: MedicalFileProviderService,
    private toastrService: ToasterService,
    private route: ActivatedRoute) { super(medicalFileService)}

  ngOnInit(): void {
    this._title.setTitle('Caretx | אבחנות פיזיקאליות');
    this.id = this.route.parent.snapshot.params['id'];
    this.CheckIfComponentIsDisabled(this.id) 
    this.getAllRecordsFromDB("all")
    this.formData=  [
      {"formcontrolname":"status","label":"סטטוס רשימה" ,"type":"multi" , "value":JSON.stringify(this.RecordStatus) ,"selectid":"id" , "selectval":"desc"},
      {"formcontrolname":"created_at_from","label":"מתאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"created_at_to","label":"עד תאריך" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_from","label":"תאריך נעילה מ" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},
      {"formcontrolname":"closed_at_to","label":"תאריך נעילה עד" ,"type":"date" , "value":"" ,"selectid":"" , "selectval":""},

    ]
    
  }


  getAllRecordsFromDB(filter:string){
    this.phsiotheraptyService.getPhysicaldiagnosis2(this.id, filter,"list").subscribe(
      data => {this.physicalDiagnoses = data
        this.totalNumOfRecords = data.length},
      err => {
        this.physicalDiagnoses = []
      }
    )
  }

  SearchWithFilter($event) {
    this.getAllRecordsFromDB($event)
  }

  AddNewRecord($event){

    let filter = '(status=\'A\')'
    this.phsiotheraptyService.getPhysicaldiagnosis2(this.id, filter,"list").subscribe((data: PhysicalDiagnoses2[]) => {
      if (data.length == 0)
      this.router.navigateByUrl("caretexfile/" + this.id + "/physicalreport/new")
      else {
        let errorMsg = "נמצאו רשומות ישנות שעדיין לא נעולות , צריך לסגור אותם קודם"
        this.toastrService.Error("", errorMsg)
      }
    });



  }

  deleteRow(i){

  }

  OnSDrillToSocialAdmission($event,physicaldiagnose){
    this.router.navigateByUrl("caretexfile/" + this.id + "/physicalreport/" + physicaldiagnose.ID)
  }

  generateReport($event,record){

  }

  canDeactivate() {
    this.showMessageWhenDirty(this.CheckIfDocumentIsDirty())
  }

  CheckIfDocumentIsDirty() {
    return false
  }

}
